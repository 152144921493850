<template>
    <table class="nested-json-table">
      <!-- <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead> -->
      <tbody>
        <!-- If data is an object, iterate its keys -->
        <template v-if="isObject(data)">
          <tr v-for="(value, key) in data" :key="key">
            <td class="key">{{ key }}</td>
            <td class="value">
              <!-- If the value is an object or array, render recursively -->
              <NestedJsonTable v-if="isObject(value) || isArray(value)" :data="value" />
              <span v-else>{{ value }}</span>
            </td>
          </tr>
        </template>
  
        <!-- If data is an array, iterate its items -->
        <template v-else-if="isArray(data)">
          <tr v-for="(item, index) in data" :key="index">
            <!-- <td class="key">{{ index }}</td> -->
            <td class="value">
              <NestedJsonTable v-if="isObject(item) || isArray(item)" :data="item" />
              <span v-else>{{ item }}</span>
            </td>
          </tr>
        </template>
  
        <!-- For primitive data types, just show the value -->
        <template v-else>
          <tr>
            <td class="key"></td>
            <td class="value">{{ data }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </template>
  
  <script>
  export default {
    name: 'NestedJsonTable',
    props: {
      data: {
        type: [Object, Array, String, Number, Boolean],
        required: true
      }
    },
    methods: {
      isObject(value) {
        return value && typeof value === 'object' && !Array.isArray(value);
      },
      isArray(value) {
        return Array.isArray(value);
      }
    }
  };
  </script>
  
  <style scoped>
  .nested-json-table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1em;
    font-family: Arial, sans-serif;
    font-size: 0.8rem;
  }
  .nested-json-table th,
  .nested-json-table td {
    border: 1px solid #ddd;
    padding: 4px 8px;
    vertical-align: top;
  }
  .nested-json-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  .key {
    font-weight: bold;
    width: 25%;
  }
  .value {
    width: 75%;
  }
  </style>
  