<template>
  <div class="p-4">
    <h1 class="text-3xl font-extrabold mb-4">File Difference Viewer</h1>

    <!-- FileDiffViewer placed here -->
    <diffcommitviewer 
      v-if="oldFileContent && newFileContent"
      :oldContent="oldFileContent" 
      :newContent="newFileContent" 
      :fileName="fileName" 
    />
  </div>
</template>

<script>
import axios from 'axios';
import diffcommitviewer from './diffcommitviewer.vue';

export default {
  components: {
    diffcommitviewer
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL || 'http://localhost:8000',  // Update if needed
      oldFileContent: '',
      newFileContent: '',
      fileName: '',

      // Example input (can be made dynamic)
      repoId: 'e1964dcc-b390-4b0a-83c4-d9502bd6eb0b',
      filePath: 'Programmes/demopython.py',
      baseCommitId: '4c51e2a9a680eb938dd767721a2bfe7ca3c6a24d',
      targetCommitId: 'ef120838cb45508936a7a1e2f4bda5bfde75afd0'
    };
  },
  mounted() {
   // this.getcommitmetadata();
    this.fetchFileVersions();
  },
  methods: {
    async fetchFileContent(commitId) {
      const url = `${this.baseurl}/stats-python/gitdiff/getfilecontentfromcommit`;

      const payload = {
        repo_id: this.repoId,
        file_path: this.filePath,
        commit_id: commitId
      };

      try {
        const response = await axios.post(url, payload);
        return response.data;  // File content string
      } catch (error) {
        console.error('Error fetching file content:', error);
        return '';
      }
    },

    async fetchFileVersions() {
      const oldContent = await this.fetchFileContent(this.baseCommitId);
      const newContent = await this.fetchFileContent(this.targetCommitId);

      this.oldFileContent = oldContent;
      this.newFileContent = newContent;

      // Extract file name from path
      this.fileName = this.filePath.split('/').pop();
    }

    // async getcommitmetadata() {
    //   const response = await axios.get(`/commitmetadata/${commitId}`);
    //   commitData.value = response.data;
    // }
  }
};
</script>
