<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>{{ $t("navigations.SDTM to ADaM Conversion") }}</h2>
        </div>
    </div>
    <div class="wrapper wrapper-content">
        <div class="ibox-content mb-1 px-3 py-2">
            <div class="row">
                <div class="col-4">
                    <label class="fs-7"
                    >{{ $t("navigations.select adam file")
                    }}<sup><i class="fa fa-asterisk required"></i></sup
                    ></label>
                    <select
                    class="form-select py-1"
                    id="adamFile"
                    name="adamFile"
                    v-model="fileId"
                    >
                    <option
                        v-for="file in files"
                        :key="file._id"
                        :value="file._id"
                    >
                        {{ file.ADaMFileType }}
                    </option>
                    </select>                 
                </div>
                <div class="col-3 align-self-end px-0">
                    <button type="button" class="save_btn  mr-0 py-1 position-relative" :disabled="showLoading == true"
                        @click="uploadDocument()" :class="{ 'disabled-btn-cls': showLoading == true }">
                        <div class="toc-loader position-absolute"
                        :class="{ 'opacity-0': showLoading == false, 'opacity-1': showLoading == true }">
                        <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
                        </div>
                        <div :class="{ 'opacity-1': showLoading == false, 'opacity-0': showLoading == true }">{{
                        $t("navigations.generate") }}</div>
                    </button>
              
                </div>
                <div class="errorClass" v-if="validations[0] == true">
                        {{ $t("navigations.please select a file") }}
                    </div>
            </div>
        </div>

    </div>
</template>
<script src="./sdtm_conversion.js"></script>
<style>
.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.loading-btn-img {
  display: block;
  mix-blend-mode: lighten;
}


.toc-loader {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.fs-7{
    font-size:0.9rem;
}
</style>