	/* eslint-disable */
  import axios from "axios";
  import store from "@/store";
  import VPagination from "@hennge/vue3-pagination";
  import Multiselect from "@vueform/multiselect";
  import customModal  from "../customModal/customModal.vue";
  import customContainer from "../customContainer/customContainer";  
  import moment from "moment";
  import { isElementAccessExpression } from "typescript";
import { toHandlers } from "vue";
  export default {
    name: "tocgenerate",
    components: {
      customModal,
      customContainer,
      Multiselect,
      VPagination
    },
    data() {
      return {
        headingId:"",
        editType:"",
        editpopup:false,
        Addheadnodeafterpopup:false,
        Addheadnodebeforepopup:false,
        editheadpopup:false,
        initialLoader:true,
        loading:true,
        newheadnode:"",
        addpopup:false,
        studyId: "",
        currentheadindex:"",
        studyref:"",
        foldername: "Adam_set",
        baseUrl: process.env.VUE_APP_Service_URL,
        showTree: false,
        displayProgramModal:false,
        showSidePopup: [[[[false]]]],
        showHeaderSidePopup:[false],
        loadingBlock:[false],
        treeData: [],
        treeModal:[],
        programList:[],
        showLoading: false,
        tocId: "fc240456-d1c3-4610-a6d3-1370b9feab9e",
        mapValue:[],
        idSet:[],
        tocid:"",
        resultData: [],
        getCode:[],
        resultViewData: "",
        displayModal: false,
        headerValues: {},
        loadingSaveBlock:false,
        loadingAssignBlock:false,
        openAssignModal:false,
        openSettings:false,
        mapProgrammePopup :false,
        displayProgramComparison:false,
        initialRunLoader:false,
        setProgram:false,
        compareProgram:false,
        displayRun:false,
        displayOutput:false,
        isReady:false,
        selectedChildren:[],
        childrenChecked: {},
        subchildrenChecked:{},
        clickedTab: "",
        refreshFlags: [],
        files: [],
        userList:[],
        htmlContent: {},
        refreshFlags: {
          general: true,
          validation: false,
          dependencies: false,
          calculations: false
        },
        headingname:"",
        headindex:"",
        activeTab: null,
        currentheadingid:"",
        subindexedit:"",
        reportnamedit:"",
        headingtext:"",
        reportid:"",
        subindex:"",
        headingid:"",
        subheadingid:"",
        newreportname:"",
        currentheadname:"",
        newheadname:"",
        editreportid:"",
        tocHeading:"",
        editheadingid:"",
        tocid:"",
        selectedReportName:"",
        editindex:"Subindex",
        addindex:"subreport",
        editheadingname:"Reportname:",
        subreport:"",      
        mapId:"",
        modalChildLabels:{},
        rootChecked: {},
        childrenChecked: {}, 
        object1:{},
        object2:{},
        iframeRefs: {} ,
        modalTocId:"",
        activeTab: null,
        programme: [],
        values:[],
        usersValues:[],
        reportType:[],
        checkedNodes: JSON.parse(localStorage.getItem("checkedNodes")) || {}, 
        status:"",
        changedStatus:"",
        statusReportId:"",
        qcProgrammer:"",
        programmer:"",
        mapData: {
          StudyId: "",
          StudyName: "",
          StudyRef: "",
          TocId: "",
          ReportId: "",
          ReportName: "",
          Version: "",
          TocDescription: "",
          DeveloperName: "",
          QCName: "",
          Status: "",
          MacroId: ""
        },
        statusOptionsDropdown:[],
        statusOptions:[
          {label:"In Development",value:"In Development"},
          {label:"Ready For Validation",value:"Ready For Validation"},
          {label:"In Development",value:"In Development"},
          {label:"Validated",value:"Validated"},
          {label:"In Development",value:"In Development"},
        ],
        statusOptions1:[
          {label:"In Development",value:"In Development"},
        ],
        statusOptions2:[
          {label:"Ready For Validation",value:"Ready For Validation"},
        ],
        statusOptions3:[
          {label:"In Development",value:"In Development"},
          {label:"Validated",value:"Validated"},
        ],
        statusOptions4:[
          {label:"In Development",value:"In Development"},
        ],
        options:[
          {label:"Table",value:"Table"},
          {label:"Listing",value:"Listing"},
          {label:"Graph",value:"Graph"}
        ],
        selectedFilters: {
          "reportType":this.options, // Example selection
          "author":this.usersValues
        },
        usersList:[],
        displayStatusModal:false,
        showFilter:false,
        showFilterBtn:false,
        assignedValue:false,
        collapseAll: false, // Controls all tree nodes
        collapseSectionsAll: false, // Controls only the sections (programs branch)
        collapsedNodes: {}, // Track individual node collapse state
        collapsedSections: {}, // Track section collapse state
        showGenerateBtn:false,
        initialTreeLoader:false,
        expandAll:false,
        expandSections:false,
        collapseAll: false,
        selectedVersionTrueId:[],
        defaultdate:"",
        programmerCount:"",
        developmentCount:"",
        selectedReportSourceId:"",
        readyForValidation:"",
        validated:"",
        currentOutput:"",
        oldOutput:"",
        noOutput:"",
        lastStatus:"",
        activeBadge:null,
        headerNames:[],
        openMultipleRun:false,
        entryUpdateModal:false,
        selectedPrograms:[],
        selectedProgramsId:[],
        childLabels:{},
        selectedHeadingId:"",
        oldReportName:"",
        newReportName:"",
        selectedUpdateOption:"",
        openCopyMoveModal:false,
        selectedModal:false,
        openRunStatusModal:false,
        selectedProgramId:"",
        selectedProgramId:"",
        defaultDate:"",
        defaultTime:"",
        selectedBranchType:"",
        selectedValidationType:[],
        listProgramRunStatus:[],
        showStatusLoading:[],
        statusComments:"",
        pageNumber:1,
        currentPage:1,
        pageSize:10,
        totalPages:1,
        showClearLoading:false
      };
      
    },
    watch: {
      showButton: {
        immediate: true, // Run the watcher immediately on load
        handler() {
          this.isReady = true;
        }
      }
    },
    computed: {
      showButton() {
        return store.getters.getTocGenrateBtn;
      },
      formattedUsersList() {
        return this.usersList.map(user => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`
        }));
      },
      comparisonData() {
        if (!this.object1 || !this.object2) return {}; // Prevent errors if objects are not loaded
        // Remove keys named "_id" from both objects
  
        const excludeKeys = new Set(["_id", "ReportId", "SourceId","StudyId","TocId","ProgramId","IsSelectedVersion"]);
  
        const filterKeys = (obj) => {
            return Object.fromEntries(Object.entries(obj).filter(([key]) => !excludeKeys.has(key)));
        };
  
        const filteredObject1 = filterKeys(this.object1);
        const filteredObject2 = filterKeys(this.object2);
        let keys = new Set([...Object.keys(filteredObject1), ...Object.keys(filteredObject2)]);
        let result = {};
        keys.forEach((key) => {
            let value1 = this.object1[key] !==undefined ? this.object1[key] : "N/A";
            let value2 = this.object2[key] !==undefined ? this.object2[key] : "N/A";
    
            let diff = false;
    
            if (Array.isArray(value1) && Array.isArray(value2)) {
                if (value1.every(item => typeof item !== "object") && value2.every(item => typeof item !== "object")) {
                    // **Case 1: Arrays of Primitives (Strings, Numbers)**
                    diff = JSON.stringify([...value1].sort()) !== JSON.stringify([...value2].sort());
                } else {
                    // **Case 2: Arrays of Objects - Deep comparison**
                    diff = JSON.stringify(value1) !== JSON.stringify(value2);
                }
            } else if (typeof value1 === "object" && typeof value2 === "object" && value1 !== null && value2 !== null) {
                // **Case 3: Objects as values - Deep comparison**
                diff = JSON.stringify(value1) !== JSON.stringify(value2);
            } else {
                // **Case 4: Normal primitive values**
                diff = value1 !== value2;
            }
    
            result[key] = { obj1: value1, obj2: value2, diff };
        });
    
        return result;
      },
      // filteredTreeData() {
      //   if (!this.values.length) return this.treeData; // Show all if no filters
      
      //   let filtered = {};
      
      //   Object.keys(this.treeData).forEach((key) => {
      //     let node = this.treeData[key];
      
      //     // Filter reports based on multiple selected filter properties
      //     let filteredReports = node.Reports.filter((report) => {
      //         let reportValue = this.getReportStatus("reportType", report.Reportid); // Fetch dynamic property
      //           return this.values.includes(reportValue);
              
      //       });
      //       if (filteredReports.length > 0) {
      //         filtered[key] = { ...node, Reports: filteredReports };
      //       }
      //     });
      
      //     // Add nodes with matching reports
      
      //   return filtered;
      // }
      
    },
    async mounted() {
      this.expandAll=true,
      this.expandSections=true,
      this.collapseAll= true,
      this.initialTreeLoader=true
      this.initialLoader=false
      this.showSidePopup[0][0][0]=false
      this.defaultDate = localStorage.getItem("dateformat");
      this.defaultTime=localStorage.getItem("timeformat")
      this.token = store.getters.getIdToken;
      this.headerValue = {
        headers: {
          Authorization: "Bearer " + this.token,
          "Content-Type": "application/json",
        },
      }
      this.studyId = store.getters.getStudyIs;
      const cachedTreeData = store.getters.getTreeData;
      if (cachedTreeData && cachedTreeData.length > 0) {
        this.treeData = cachedTreeData;
        this.treeModal=cachedTreeData
        this.showTree = true;

  
      }

      await this.getstudyinfo();
      await this.fetchToC('','');
      await this.getUsersList()
      if(this.treeData.length!=0){
       this.headingList= this.treeData.map(header => ({
          ...header,
          label: `${header.Headingname}`,
          value: `${header.Headingname}`
        }));
        // await this.getMappings()
        await this.getCounts()
        this.getOutputCounts()
      }
   
      window.addEventListener("studyIdChanged", async () => {
        this.studyId = store.getters.getStudyIs;
        this.showGenerateBtn=false
        await this.fetchToC('','');
        await this.getstudyinfo();
        if(this.treeData.length!=0){
          // await this.getMappings()
          await this.getCounts()
          this.getOutputCounts()
        }
      });
    },
    methods: {    
      filter(data,type){
          if (type=='time' && data) {
            return moment(data).format(this.defaultTime);
          };
          if (type=='date' && data) {
            return moment(data).format(this.defaultDate);
          }
        
      },
      async closePopover(id) {
        document.getElementById(id)?document.getElementById(id).hidePopover():null
      },
      expandCollapse(type) {
        const states = {
          expand: { expandAll: true, expandSections: true },
          collapse: { expandAll: false, expandSections: false },
          sections: { expandAll: true, expandSections: false }
        };
      
        if (states[type]) {
          Object.assign(this, states[type]);
        }
      },
    
      valuesChanged(index) {
        let options = this.dropdownoptions;
        for (let i = 0; i < this.fieldsConsider.length; i++) {
          if (i !== index) {
            options = options.filter(
              (option) =>
                !this.fieldsConsider[i].considerableFields.some(
                  (selected) => selected.optionname === option.optionname
                )
            );
          }
        }
        return options;
      },    
      saveCheckedState() {
        localStorage.setItem("checkedNodes", JSON.stringify(this.checkedNodes));
      },
      filterDate(data) {
        if(data){
          return moment(data).format(this.defaultDate)
        }
      
      },
      filterTime(data) {
        if(data){
          return moment(data).format("HH:mm:ss");
        }
      
      },
      getBadgeClass(status,output,type) {
        if(type=='status'){
          const badgeClasses = {
            "No Output": output == false ? "custom-badge badge-black rounded-radius":'',
            "No Program": "custom-badge badge-black status-b-radius",
            "Old Output": output == false ? "custom-badge badge-danger rounded-radius":'',
            "Ready For Validation": "custom-badge badge-danger status-b-radius",
            "In Development": "custom-badge badge-yellow status-b-radius",
            "Current Output": "custom-badge badge-orange rounded-radius status-b-radius",
            "Validated": "custom-badge badge-grn status-b-radius"
          };
          return badgeClasses[status] || "badge badge-default"; // Default class if status is unknown
        }
        else{
          const badgeClasses = {
          "No Output": output == false ? "custom-badge badge-black rounded-radius mr-1": output == true ? "custom-badge badge-danger rounded-radius mr-1":'',
          "No Program": "custom-badge badge-black",
          "Old Output": output == false ? "custom-badge badge-danger rounded-radius mr-1":output == true ? "custom-badge badge-danger rounded-radius mr-1":'',
          "Ready For Validation":output == false ? "custom-badge badge-black rounded-radius mr-1":output == true ? "custom-badge badge-danger rounded-radius mr-1":'',
          "In Development": output == false ? "custom-badge badge-black rounded-radius mr-1":output == true ? "custom-badge badge-danger rounded-radius mr-1":'',
          "Current Output": "custom-badge badge-orange rounded-radius",
          "Validated": output == false ? "custom-badge badge-black rounded-radius mr-1":output == true ? "custom-badge badge-danger rounded-radius mr-1":'',
          }
          return badgeClasses[status] || "badge badge-default"; // Default class if status is unknown
        }
       
        
      },
      async getstudyinfo() {
        const idtoken = store.getters.getIdToken;
            await axios
          .get(
            `${this.baseUrl}/management/study/getbyid/${store.getters.getStudyIs}?api-version=1.0`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.studyInfo = res.data;
            this.studyref = res.data.studyRef
          })
          .catch((err) => {
            console.log(err);
          });
      },
      async getUsersList() {
        const idtoken = store.getters.getIdToken;
            await axios
          .get(
            `${this.baseUrl}/account-core/user/getstudybasedusers?studyid=${store.getters.getStudyIs}&api-version=1.0`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.usersList = res.data.entities;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      closemodalforcreate(){
         this.addpopup=false;
      },
      goto(childlabels,type) {
        if(type=='create'){
          childlabels["tocid"] = this.tocid
          localStorage.setItem("Reportname",childlabels.Reportname)
          localStorage.setItem("Reportid",childlabels.Reportid)
          localStorage.setItem("TocId",childlabels.tocid)
          this.$router.push(`/stats/${childlabels.Reportid}/0`)
        }
       else{
        this.$router.push(`/stats/${childlabels.Reportid}/1`)
       }
       this.getCounts();
       this.getOutputCounts()
      },
      async changeStatus(childlabels,value,id){
        await this.closePopover(id)
        this.selectedReportSourceId=value.SourceId
        this.displayStatusModal=true
        if(value.Status=='No Program')
        {
          this.statusOptionsDropdown=this.statusOptions1
        }
        if(value.Status=='In Development')
        {
          this.statusOptionsDropdown=this.statusOptions2
        }
        if(value.Status=='Ready For Validation')
        {
          this.statusOptionsDropdown=this.statusOptions3
        }
        if(value.Status=='Validated')
          {
            this.statusOptionsDropdown=this.statusOptions4
          }
        this.statusReportId=childlabels.Reportid
        this.selectedReportName=childlabels.Reportname
        this.selectedProgramId=value._id
        this.statusComments=value.Comments
        this.selectedValidationType=value.ValidationType
        this.status=value.Status
      },
      async saveData() {
        this.loadingSaveBlock = true
        const idtoken = store.getters.getIdToken;
        if(this.changedStatus==''){
          alert(this.$t("navigations.please choose a status"))
          this.loadingSaveBlock = false
        }
        else{
          await axios
          .put(
            `${this.baseUrl}/stats-python/programme/updatestatus`,
            {
              "Status": this.changedStatus,
              "ProgramId": this.selectedProgramId,
              "Comments": this.statusComments,
              "ValidationType":this.selectedValidationType ? this.selectedValidationType : []
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if(res.data.status=='success'){
              alert(this.$t('navigations.status saved successfully'))
              const header = this.treeData.find(header => 
                header.Reports.some(report => report.Reportid === this.statusReportId)
              );
              if (header) {
                const report = header.Reports.find(report => report.Reportid === this.statusReportId);
                if (report) {
                  const program = report.Programms.find(program => program.SourceId === this.selectedReportSourceId);
                  if (program) {
                    program.Status = this.changedStatus;
                  }
                }
              }
              this.fetchToC()
             
            } 
            if(res.data.status=='error'){
              alert(this.$t('navigations.status change failed'))
              
            } 
            this.loadingSaveBlock=false           
            this.displayStatusModal=false
            this.changedStatus=""
            this.statusComments=""
            this.selectedValidationType=[]
          })
          .catch((err) => {
            this.loadingSaveBlock = false
            this.displayStatusModal=false
          });
        }
    
        },
      async addreport(headingid,id) {
        await this.closePopover(id)
        this.addpopup = true;
        this.headingid = headingid;
        if (!reportName || reportName.trim() === "") {
          //alert("Please enter a valid report name.");
          return;
        }
        console.log(`Adding report: ${reportName}`);
        
      },
      
      async Editheading(headingid,headingname,id) {
        await this.closePopover(id)
        this.editheadpopup = true;
        this.editType= "Heading";
        this.headingname=headingname;
        this.headingid = headingid;
  
      },
      async assignProgrammer(){
        this.loadingAssignBlock = true
        const idtoken = store.getters.getIdToken;
        await axios
          .put(
            `${this.baseUrl}/stats-python/programme/update-qc-developer-info`,
            {
              "ReportId": this.selectedChildren,
              "DeveloperName": this.programmer,
              "QCName": this.qcProgrammer
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async(res) => {
            this.loadingAssignBlock=false
            this.assignedValue=true
            alert(this.$t('navigations.programer assigned successfully'))
            this.openAssignModal=false
            this.qcProgrammer=""
            this.programmer=""
          
          })
          .catch((err) => {
            this.loadingAssignBlock = false
          });
          if(this.assignedValue==true){
            await this.fetchToC()
          }
      },
      async runMultiplePrograms(){
        this.loadingAssignBlock = true
        const idtoken = store.getters.getIdToken;
        await axios
          .post(
            `${this.baseUrl}/stats-python/programme/runmultipleprogram`,
            {
              "ProgramIds":this.selectedProgramsId
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async(res) => {
            this.loadingAssignBlock=false
            this.assignedValue=true
            alert(this.$t('navigations.programer assigned successfully'))
            this.closeRunModal('run')
          })
          .catch((err) => {
            this.loadingAssignBlock = false
          });
          if(this.assignedValue==true){
            await this.fetchToC()
          }
      },
      closeModal(type){
        if(type=='validation'){
          this.displayStatusModal=false
          this.status=""
          this.changedStatus=""
        }
        if(type=='assign'){
          this.openAssignModal=false
        }     
      
      },
      async generateToC(studyId, foldername) {
        this.headingList =[]
        this.showLoading = true;
        try {
          const response = await axios.post(
            `${this.baseUrl}/stats-python/toc/generatetoc`,
            {
              study_id: studyId,
              input_folder: foldername,
              studyref: this.studyInfo.studyRef
            },this.headerValue
          );
          this.treeData = response.data.toc_data.toc_data || [];
          this.treeModal = response.data.toc_data.toc_data || [];
          this.showGenerateBtn=false
          this.showFilterBtn=true
          this.showTree = true;
          this.showLoading = false;
          this.tocid= response.data.toc_data._id
          if(this.treeData.length!=0){
            this.fetchToC()
            this.getCounts()
            this.getOutputCounts()
            this.tocHeading=this.$t("navigations.analysis tree")
            this.headingList= this.treeData.map(header => ({
              ...header,
              label: `${header.Headingname}`,
              value: `${header.Headingname}`
            }));
          }
          
        } catch (error) {
          this.showTree = false;
          this.showLoading=false
          throw error;
        }
      },
      async fetchToC(status,type) {
        if(status!=''){
          if (this.lastStatus === status) {
            status = "";
          } 
          if (this.activeBadge === status) {
            this.activeBadge = null; 
          } else {
            this.activeBadge = status;
          }
        }
        
        this.lastStatus = status; 
        const idtoken = store.getters.getIdToken;
         await axios.post(
            `${this.baseUrl}/stats-python/toc/gettocwithprogram`,            
            {
                "StudyId": this.studyId,
                "TocId": "",
                "HeadingName": this.headerNames,
                "HasOutput":status=='No Output'? false : status=='Current Output' || status=='Old Output'? true : null,
                "IsSelectedVersion":status=='Current Output'? true : status=='Old Output'? false : null,                
                "ReportId": "",
                "ReportName": "",
                "Version": "",
                "TocDescription": "",
                "ReportType": this.reportType,
                "DeveloperName": this.usersValues,
                "QCName":[],
                "Status": status!='No Output' && status!='Current Output' && status!='Old Output'?status:"",
                "ProgramId": "",
                "ProgramName": [],
                "HasProgram": null
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res)=>{
            this.tocid = res.data.result._id
            if (res.data.result.toc_data) {
              this.tocid =  res.data.result._id;
              this.treeData = res.data.result.toc_data || [];
              this.treeModal=res.data.result.toc_data || [];
              this.showTree = true;
              this.tocHeading = this.$t("navigations.analysis tree")
    
              this.showLoading = false;
              this.showFilterBtn=true
              this.initialTreeLoader=false             
              store.dispatch("setTocGenerateBtn", true);
            } else {
              console.warn("Study ID does not exist or is invalid.");
              this.tocHeading = this.$t("navigations.toc generate")
              if(type==''){
                this.showFilter=false
                this.showFilterBtn=false
                this.initialTreeLoader=false
                this.showGenerateBtn=true
              }
              this.treeData = [];
              this.treeModal=[]
              this.showTree = false;
            }
            if(this.treeData.length==0 && type==''){
              this.showFilterBtn=false
              this.showGenerateBtn=true
            }
          
          })
         .catch((error)=> {
          this.tocHeading = this.$t("navigations.toc generate")
          this.initialTreeLoader=false
          console.error("Error in ID check API call:", error);
          this.treeData = [];
          this.treeModal=[]
          if(type==''){
          this.showLoading=false
          this.showFilter=false
          this.showFilterBtn=false
          this.initialTreeLoader=false
          this.showGenerateBtn=true
          }
          this.showTree = false;
        })
      },
      async getprograms(){
        const idtoken = store.getters.getIdToken;
        axios
           .get(
             `${this.baseUrl}/stats-python/programme/getprogrammes?PageNumber=1&PageSize=1000&StudyId=${this.studyId}&IsSelectedVersion=true`,
             {
               headers: {
                 Authorization: "Bearer " + idtoken,
                 "Content-Type": "application/json",
               },
             }
           )
           .then((res) => {
             this.mapValue=res.data.Data
           })
           .catch((err) => {
             console.log(err)
           });
      },
      toggleSidePopup(index, vIndex, innerIndex) {
        console.log("Before Toggle:", JSON.stringify(this.showSidePopup));
    
        // Ensure index exists and is an object
        if (!this.showSidePopup[index]) {
            this.showSidePopup = { ...this.showSidePopup, [index]: {} };
        }
    
        // Ensure vIndex exists and is an object
        if (!this.showSidePopup[index][vIndex] || typeof this.showSidePopup[index][vIndex] !== "object") {
            this.showSidePopup[index] = { ...this.showSidePopup[index], [vIndex]: {} };
        }
    
        // Toggle the innerIndex state
        this.showSidePopup[index][vIndex] = {
            ...this.showSidePopup[index][vIndex],
            [innerIndex]: !this.showSidePopup[index][vIndex][innerIndex]
        };
    
        // Force Vue to detect changes
        this.showSidePopup = { ...this.showSidePopup };
    
        console.log("After Toggle:", JSON.stringify(this.showSidePopup));
    },
    
      closeSidePopup(index, vIndex,innerIndex) {
          if (this.showSidePopup[index] && this.showSidePopup[index][vIndex]) {
            this.showSidePopup[index][vIndex] = false;
        }
        if (this.showSidePopup[index] && this.showSidePopup[index][vIndex] && this.showSidePopup[index][vIndex][innerIndex]) {
          this.showSidePopup[index][vIndex][innerIndex] = false;
      }
      },
      async runBtn(type,reportid,name,programId,id) {
        await this.closePopover(id)
        this.selectedReportName=name
        this.loader=true
        this.initialRunLoader=true
        await this.runToc(type,reportid,programId)
        await this.$nextTick();
        if(type=='run'){
          if(this.files!=null || this.files!=undefined){
            this.files.forEach((eachItem,index) => {
              this.loadIframeContent(`${eachItem.Page}-${index}`, eachItem.Value)
           })
          }     
      
        }
        if(type=='output')
        {
          if(this.files!=null || this.files!=undefined){
            this.files.forEach((eachItem,index) => {
              if(eachItem.HasOutput!=false)
              {
                eachItem.Output.forEach((outputItem,innerIndex) => {
                  this.loadIframeContent(`${index}-${outputItem.Page}-${innerIndex}`, outputItem.Value)
                })
              }
             
           })
          }     
        }
      },
      async deleteOutput(reportid,programId,id){
        await this.closePopover(id)
        if(confirm(this.$t("navigations.are you sure you want to delete this output")))
        {
          const idtoken = store.getters.getIdToken;
            await axios.delete(
                `${this.baseUrl}/stats-python/programme/deleteoutput?reportid=${reportid}&programid=${programId}`,
                {
                  headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                  },
                }
              ).then((res)=>{
              alert(this.$t("navigations.output deleted successfully"))
              this.getCounts();
              this.getOutputCounts()
              this.fetchToC()
            }).catch ((err)=>{
              console.error("Error fetching files:", err.response);
            })
          } 
        
      },
      closeReportModal(type){
        this.refreshFlags=[]
        this.files=[]
        this[`display${type.charAt(0).toUpperCase() + type.slice(1)}`] = false;
      },
      async runToc(type, reportid,programId) {
        this.files = [];
        const idtoken = store.getters.getIdToken;
  
        if (type === "run") {
          try {
            const res = await axios.get(
              `${this.baseUrl}/stats-python/programme/runprogramewithid/${programId}`,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            );
            alert(this.$t("navigations.program has run successfully"))
            this.getCounts();
            this.getOutputCounts()
            this.files = res.data;
            
            if (this.files && this.files.length > 0) {
              this.files.forEach((eachItem) => {
                if (typeof eachItem.Value === "string" && /^[0-9A-Fa-f]+$/.test(eachItem.Value)) {
                  eachItem.Value = this.hexToUtf8(eachItem.Value);
                }
              });
              this.displayRun = true;
            }
            this.loader=false
            this.initialRunLoader = false;
            await this.fetchToC()
          } catch (err) {
            this.initialRunLoader = false;
            this.displayRun = false;
            this.loader=false
            if (err.response && err.response.data && err.response.data.details)  {
              alert(err.response.data.details === "No details found for this ID"
                ? err.response.data.details
                : "Input should be a valid string"
              );
            }
            console.error("Error fetching files:", err.response);
          }
        } 
        else if (type === "output") {
          this.displayOutput = true;
          try {
            const res = await axios.get(
              `${this.baseUrl}/stats-python/programme/getprogrammes?PageNumber=1&PageSize=10&SortBy=CreatedAt&SortOrder=desc&ReportId=${reportid}`,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            res.data.Data.forEach((item) => {
              if (item.Output !== null && item.Output !== undefined) {
                this.files.push({
                  Version: item.Version,
                  Output: item.Output,
                });
              }
            });
            this.files.forEach((eachItem) => {
              eachItem.Output.forEach((outputItem) => {
                if (typeof outputItem.Value === "string" && /^[0-9A-Fa-f]+$/.test(outputItem.Value)) {
                  outputItem.Value = this.hexToUtf8(outputItem.Value);
                }
              });
            });
            this.files=  this.files.slice().reverse()
            if (this.files.length > 0) {
              this.refreshFlags[0] = true;
            }
            this.loader=false
            this.initialRunLoader = false;
          } catch (err) {
            this.initialRunLoader = false;
            this.displayOutput = false;
            this.loader=false
            if (err.response && err.response.data && err.response.data.details) {
              alert(err.response.data.details === "No details found for this ID"
                ? err.response.data.details
                : "Input should be a valid string"
              );
            }
      
            console.error("Error fetching files:", err.response);
          }
        }
        console.log("Value of loader is",this.loader)
      },    
      // Map functions starts
      async openMap(childlabels,id) {
        await this.closePopover(id)
        this.modalChildLabels=childlabels
        this.modalTocId=this.tocid
        this.mapId=childlabels.Reportid
        this.mapProgrammePopup = true;
        await this.getProgram();
        await this.getstudyinfo();
      },
      async mapProgramme(pgm,index) {
        
        this.loadingBlock[index]=true
        this.studyId = store.getters.getStudyIs;
        const idtoken = store.getters.getIdToken;
        this.mapData.MacroId = pgm._id;
        this.mapData.StudyId = this.studyId;
        this.mapData.ReportId = this.modalChildLabels.Reportid;
        this.mapData.ReportName = this.modalChildLabels.Reportname;
        this.mapData.Version = "1.0";
        this.mapData.Status = "In Development";
        this.mapData.StudyName = this.studyInfo.studyName;
        this.mapData.StudyRef = this.studyInfo.studyRef;
        this.mapData.TocId = this.modalTocId;
         await axios.post(
                `${this.baseUrl}/stats-python/programme/mapprogram`, this.mapData,
                {
                    headers: {
                        Authorization: "Bearer " + idtoken,
                        "Content-Type": "application/json",
                    },
                }
            ).then((res)=>{
                this.loadingBlock[index]=false
                this.files = res.data;
                alert(this.$t('navigations.program mapped succesfully'));
                this.fetchToC()
                this.getCounts();
                this.getOutputCounts();
                this.mapProgrammePopup=false
            }).catch((err)=>{
                this.loadingBlock[index]=false
                if(err.response && err.response.data.details){
                    alert(err.response.data.details)
                }
                this.mapProgrammePopup=false
                
        })
      },
      async copyMoveReport(childlabels,headingId,program,id,type,branchType)
      {
        console.log("branch type is",branchType)
        await this.closePopover(id)
        this.childLabels=childlabels
        this.oldReportName=childlabels.Reportname
        this.selectedHeadingId=headingId
        this.selectedProgramId=program
        this.selectedBranchType=branchType
        if(type=='copy'){
          this.selectedModal='copy'
        }
        else{
          this.selectedModal='move'
        }
        this.openCopyMoveModal=true
      },
      async copyMove(childlabels,headingId,value,branchType){
        const idtoken = store.getters.getIdToken;
        if(this.selectedModal=='move'){
         if(confirm(`Are you sure to ${this.selectedModal} ${this.selectedBranchType=='report' ? this.childLabels.Reportname : this.selectedProgramId.ProgramName} ${this.selectedBranchType=='report' ? 'before' : 'inside'} ${branchType=='heading' ? childlabels : branchType=='report' ? childlabels.Reportname : value.ProgramName}`))
         {
          await axios.post(
            `${this.baseUrl}/stats-python/toc/movesection`,
            {
              "tocid": this.tocid,
              "sourceheadingid": this.selectedHeadingId,
              "sourcereportid": this.childLabels.Reportid,
              "destinationheadingid": headingId,
              "destinationreportid": childlabels.Reportid,
              "position": "before",
              "programid": this.selectedProgramId._id
            },
            {
                headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                },
          })
          .then((res)=>{
            alert(`Successfully moved ${this.selectedBranchType=='report' ? this.childLabels.Reportname : this.selectedProgramId.ProgramName} ${this.selectedBranchType=='report' ? 'before' : 'inside'} ${branchType=='heading' ? childlabels : branchType=='report' ? childlabels.Reportname : value.ProgramName}`)    
            this.openCopyMoveModal=false
            this.fetchToC()
          })
          .catch((err)=>{
              if(err.response && err.response.data.details){
                  alert(err.response.data.details)
              } 
              
          })
         }
         
        }
        else{
          if(confirm(`Are you sure to ${this.selectedModal} ${this.selectedBranchType=='report' ? this.childLabels.Reportname : this.selectedProgramId.ProgramName} ${this.selectedBranchType=='report' ? 'before' : 'inside'} ${branchType=='heading' ? childlabels : branchType=='report' ? childlabels.Reportname : value.ProgramName}`))
          {
            await axios.post(
              `${this.baseUrl}/stats-python/toc/copysection`,
              {
                "tocid": this.tocid,
                "sourceheadingid": this.selectedHeadingId,
                "sourcereportid": this.childLabels.Reportid,
                "destinationheadingid": headingId,
                "destination_reportid": childlabels.Reportid,
                "position": "before",
                "programid": this.selectedProgramId._id
              },
              {
                  headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                  },
            })
            .then((res)=>{
              alert(`Successfully moved ${this.selectedBranchType=='report' ? this.childLabels.Reportname : this.selectedProgramId.ProgramName} ${this.selectedBranchType=='report' ? 'before' : 'inside'} ${branchType=='heading' ? childlabels : branchType=='report' ? childlabels.Reportname : value.ProgramName}`)       
              this.openCopyMoveModal=false
              this.fetchToC()
            })
            .catch((err)=>{
                if(err.response && err.response.data.details){
                    alert(err.response.data.details)
                } 
                
            })
          }
         
        }
      
      },
      closeCopyMoveModal(){
        this.oldReportName=""
        this.selectedHeadingId=""
        this.selectedModal=""
        this.openCopyMoveModal=false
      },
      async openEntryUpdate(childlabels,headingId,id,type)
      {
        this.newReportName=""
        await this.closePopover(id)
        this.childLabels=childlabels
        this.oldReportName=childlabels.Reportname
        this.selectedHeadingId=headingId
        const idtoken = store.getters.getIdToken;
        if(type=='update' || type=='add')
        {
          this.selectedUpdateOption=type
          this.entryUpdateModal=true
        }
        else{
          await axios.delete(
              `${this.baseUrl}/stats-python/toc/deletesubheading?tocid=${this.tocid}&headingid=${this.selectedHeadingId}&reportid=${this.childLabels.Reportid}&subindex=${childlabels.Subindex}`, 
              {
                  headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                  },
            })
            .then((res)=>{
              alert(this.$t("navigations.report deleted successfully"))         
              this.fetchToC()
            })
            .catch((err)=>{
                if(err.response && err.response.data.details){
                    alert(err.response.data.details)
                } 
                
            })
          
        }

       
      },
      async openProgramRunStatusModal(){
        this.openRunStatusModal=true
        this.listProgramRunStatus=[]
        await this.getRunStatus()
      },
      async getRunStatus(){
        this.loading=true
        this.showStatusLoading=[]
        const idtoken = store.getters.getIdToken;
        await axios.get(
          `${this.baseUrl}/stats-python/programme/getprogramlog?PageNumber=${this.currentPage}&PageSize=${this.pageSize}&SortBy=CreatedAt&SortOrder=desc&StudyId=${this.studyId}&HasOutput=true`, 
          {
              headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
              },
          })
          .then((res)=>{
            this.totalPages = Math.ceil(res.data.Total / 10);
            this.listProgramRunStatus=res.data.Data
            if(this.listProgramRunStatus!=null || this.listProgramRunStatus.length!=0){
              this.listProgramRunStatus.forEach((item)=>{
                this.showStatusLoading.push(false)
              })
            }
            this.loading=false  
          })
          .catch((err)=>{
            this.loading=false  
            this.showStatusLoading=[]
            console.log(err)
              
          })
      },
      async loadPage(page) {
        this.currentPage = page;
        this.pageNumber = page;
        this.searchData();
     },
      async clearTable(){
        this.showClearLoading=true
        const idtoken = store.getters.getIdToken;
        await axios.delete(
          `${this.baseUrl}/stats-python/programme/deleteprogramlog`, 
          {
              headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
              },
          })
          .then((res)=>{
            this.listProgramRunStatus=res.data     
            this.showClearLoading=false
            alert(this.$t("navigations.table cleared successfully"))
          })
          .catch((err)=>{
            this.showClearLoading=false
            console.log(err)
              
          })
      
      },

      async closeProgramRunStatusModal(){
        this.openRunStatusModal=false
      },
      async saveEntry(type){
        this.loadingAssignBlock=true
        const idtoken = store.getters.getIdToken;
        if(type=="update"){
          await axios.put(
            `${this.baseUrl}/stats-python/toc/updatesubheading`, 
            {
              "tocid": this.tocid,
              "headingid": this.selectedHeadingId,
              "subheadingid": this.childLabels ? this.childLabels.Reportid:"",
              "newsubheadnewname": this.newReportName,
            },
            {
                headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                },
            })
            .then((res)=>{
              alert(this.$t("navigations.report name updated successfully"))         
              this.fetchToC()
              this.loadingAssignBlock=false
              this.entryUpdateModal=false
            })
            .catch((err)=>{
              this.loadingAssignBlock=false
                if(err.response && err.response.data.details){
                    alert(err.response.data.details)
                } 
                
            })
        }
        if(type=="add"){
          await axios.post(
            `${this.baseUrl}/stats-python/toc/addsubheading`, 
            {
              "tocid": this.tocid,
              "headingid": this.selectedHeadingId,
              "subheadnewname": this.newReportName,
            },
            {
                headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                },
            })
            .then((res)=>{
              alert(this.$t("navigations.report name added successfully"))         
              this.fetchToC()
              this.loadingAssignBlock=false
              this.entryUpdateModal=false
            })
            .catch((err)=>{
              this.loadingAssignBlock=false
                if(err.response && err.response.data.details){
                    alert(err.response.data.details)
                } 
                
            })
        }
      
      },
      closeEntryUpdateModal(){
        this.entryUpdateModal=false
      },
      async lockEntry(childlabels,headingId,programId,id,type,branchType){
      const idtoken = store.getters.getIdToken;
      this.initialRunLoader=true
        await this.closePopover(id)
        await axios.put(
          `${this.baseUrl}/stats-python/toc/lockunlock`, 
          {
            "TocId": this.tocid,
            "HeadingId":headingId ? headingId : "",
            "ReportId": childlabels ? childlabels.Reportid : "",
            "ProgramId":programId ? programId : "",
            "Lock": type=='lock'? true : false,
          },
          {
              headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
              },
          })
          .then((res)=>{
            if(res.data.result.message=="No matching TocId, HeadingId, or ReportId found")
            {
              alert(this.$t("navigations.no matching id found"))
            }
            else{
              if(type=='lock'){
                alert(this.$t("navigations.locked successfully"))
              }
              else{
                alert(this.$t("navigations.unlocked successfully"))
              }
              this.fetchToC()
            }             
            this.initialRunLoader=false
           
          })
          .catch((err)=>{
            this.initialRunLoader=false
              if(err.response && err.response.data.details){
                  alert(err.response.data.details)
              } 
              
            })
      },
      async getstudyinfo() {
        const idtoken = store.getters.getIdToken;
        await axios
            .get(
                `${this.baseUrl}/management/study/getbyid/${store.getters.getStudyIs}?api-version=1.0`,
                {
                    headers: {
                        Authorization: "Bearer " + idtoken,
                        "Content-Type": "application/json",
                    },
                }
            )
            .then((res) => {
                this.studyInfo = res.data;
            })
            .catch((err) => {
                console.log(err);
            });
      },
      async getProgram() {
          const idtoken = store.getters.getIdToken;
          try {
              const res = await axios.get(
                  `${this.baseUrl}/stats-python/macro/macros`,
                  {
                      headers: {
                          Authorization: "Bearer " + idtoken,
                          "Content-Type": "application/json",
                      },
                  }
              );      
              this.programme = res.data;
              this.loading=false
              if(this.programme.length!=0 || this.programme!=null){
                  this.loading=false
                  this.programme.forEach((item,index)=>{
                      this.loadingBlock[index]=false
                  })
              }
              
          } catch (err) {
              this.loading=false
              console.error("Error fetching files:", err);
          }
      },

    // Map functions ends
    // Program history starts
      async programHistory(childlabels,id){ 
        await this.closePopover(id)
        this.displayProgramModal=true
        if(childlabels.Reportid && childlabels.Reportid!=null){
          const idtoken = store.getters.getIdToken;
          await axios
          .get(
            `${this.baseUrl}/stats-python/programme/getprogrammes?PageNumber=1&PageSize=10&StudyId=${this.studyId}&ReportId=${childlabels.Reportid}`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.loading=false
            this.programList=res.data.Data
            this.selectedVersionTrueId= this.programList.filter(
              (selectedChild) => selectedChild.IsSelectedVersion == true
            );
          })
          .catch((err) => {
            this.loading=false
            console.log(err)
  
          });
        }
      },
      async mapSubFun(type,id){
        this.setProgram=true
        if(type==='set program')
        {        
          if(confirm(this.$t("navigations.are you sure you want to set this is as current program?"))){
            const idtoken = store.getters.getIdToken;
            await axios
            .get(
              `${this.baseUrl}/stats-python/programme/setselectedversion/${id}`,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              alert(this.$t('navigations.set as current program'))
              this.displayProgramModal=false
              this.setProgram=false
            })
            .catch((err) => {
              this.setProgram=false
              alert("Network Issue")
              this.displayProgramModal=false
              console.log(err)
            });
          }
          else{
            this.setProgram=false
          }
        }
  
      },
      async toggleComparison(id) {
        this.displayProgramComparison = true;
        this.compareProgram=true
        this.loading=true
        await this.getcomparisons(id)
        await this.getcomparisons()
        if(this.object1 && this.object2){
          this.loading=false
          this.compareProgram=false
        }
      },
      async getcomparisons(id){
        const idtoken = store.getters.getIdToken;
        const Id = id || this.selectedVersionTrueId[0]._id;
        if (!Id) return;
        await axios
        .get(
          `${this.baseUrl}/stats-python/programme/getprogrambyid/${Id}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (id) { this.object1 = res.data; }
          else { this.object2 = res.data; }
  
         
        })
        .catch((err) => {
          alert("Network Issue")
          console.log(err)
        });
       
      },
      closeSub(){
        this.displayProgramComparison=false
        this.getCode=[]
      },
      
    // Program history ends
      hexToUtf8(hexString) {      
        hexString = hexString.replace(/\s+/g, '');      
        let utf8String = '';
        for (let i = 0; i < hexString.length; i += 2) {
          const hexCode = hexString.substr(i, 2);
          utf8String += String.fromCharCode(parseInt(hexCode, 16));
        }
  
        return utf8String;
      },
      loadIframeContent(page, value) {
        const iframe = document.getElementById(page);
        const doc = iframe.contentDocument || iframe.contentWindow.document;
        doc.open();
        doc.write(value);
        doc.close();
      },
      resizeIframe(fileIndex, pageIndex) {
        const iframeKey = `${fileIndex}-${pageIndex}`;
        const iframe = this.iframeRefs[iframeKey];
      
        if (iframe && iframe.contentWindow) {
          try {
            // Ensure iframe content is accessible
            iframe.style.height = iframe.contentWindow.document.body.scrollHeight + "px";
          } catch (error) {
            console.error(`Cannot access iframe content for ${iframeKey}:`, error);
          }
        }
      },
      
      adjustIframeWidth(event,page) {
        const iframe = document.getElementById(page);
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
  
        if (iframeDocument && iframeDocument.body) {
  
          const contentWidth = iframeDocument.body.scrollWidth;
          if(contentWidth>300){
            iframe.style.width = `${contentWidth}px`;
          }
          
        }
      },
      
      // async getMappings() {
      //   if(this.assignedValue==true){
      //     this.openAssignModal=false
      //   }
      //   this.initialLoader=true
      //   this.mapValue=[]
      //   this.idSet=[]
      //   const idtoken = store.getters.getIdToken;
      //   await axios
      //     .get(
      //       `${this.baseUrl}/stats-python/programme/getprogrammes?PageNumber=1&PageSize=1000&StudyId=${this.studyId}&IsSelectedVersion=true`,
      //       {
      //         headers: {
      //           Authorization: "Bearer " + idtoken,
      //           "Content-Type": "application/json",
      //         },
      //       }
      //     )
      //     .then((res) => {
      //       this.mapValue=res.data.Data
      //       this.mapValue.forEach((value)=>{
      //         this.idSet.push(value.ReportId)
      //       })
      //       this.initialLoader=false
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //       this.initialLoader=false
      //     });
  
      // },
      async getCounts(){
        const idtoken = store.getters.getIdToken;
        await axios
        .get(
          `${this.baseUrl}/stats-python/programme/countofprograms?StudyId=${this.studyId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if(res.data && res.data.length!=0){
            const statusCounts = res.data.reduce((acc, item) => {
              if (item.status === 'No Program') acc.noProgram = item.count;
              if (item.status === 'In Development') acc.inDevelopment = item.count;
              if (item.status === 'Ready For Validation') acc.readyForValidation = item.count;
              if (item.status === 'Validated') acc.validated = item.count;
              return acc;
            }, {});
            this.programmerCount = statusCounts.noProgram || 0;
            this.developmentCount = statusCounts.inDevelopment || 0;
            this.readyForValidation = statusCounts.readyForValidation || 0;
            this.validated=statusCounts.validated || 0;
        
          }
          else{
            this.programmerCount =  0;
            this.developmentCount =  0;
            this.readyForValidation =  0;
            this.validated= 0;
          }
        })
        .catch((err) => {
          console.log(err)
        });
      },
      async getOutputCounts(){
        const idtoken = store.getters.getIdToken;
        await axios
        .get(
          `${this.baseUrl}/stats-python/programme/countofoutputs?StudyId=${this.studyId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if(res.data && res.data.length!=0){
            const statusCounts = { noOutput: 0, oldOutput: 0, currentOutput: 0 };
            res.data.forEach((item) => {
              if (item.outputType) {
                statusCounts[item.outputType] = item.count || 0; // Ensure a number is assigned
              }
            });
            this.oldOutput = statusCounts["old output"] ? statusCounts["old output"] : 0;
            this.currentOutput = statusCounts["current output"] ? statusCounts["current output"] : 0;
            this.noOutput = statusCounts["no output"] ? statusCounts["no output"] : 0;
            console.log("value is",statusCounts,this.oldOutput,this.currentOutput,this.noOutput)
          }
          else{
            this.oldOutput = 0;
            this.currentOutput = 0;
            this.noOutput = 0;
      
          }
        })
        .catch((err) => {
          console.log(err)
        });
      },
     
      async Editsubheading(headingid,subheadingid, subheadingname){
        console.log("subheadingid",subheadingid)
        this.reportnamedit = subheadingname;
        this.editType ='Subheading'
        this.headingid = headingid;
        this.subheadingid=subheadingid;
        this.editpopup =true;
      },
      async Addheadnodeafter(headingId,id) {
        await this.closePopover(id)
        this.headingId=headingId;
        this.Addheadnodeafterpopup=true;
      },
      async closeaftermodal(){
        this.Addheadnodeafterpopup=false;
      },
      async Saveheadnodeafter() {
        await axios
        .post(`${this.baseUrl}/stats-python/toc/addheadnodeafter`,  
              {
                tocid: this.tocid, 
                currentheadingid: this.headingId, 
                newheadname: this.newheadnode,
        
              },this.headerValue)
            .then((res) => {
              console.log("Import study...", res);
              this.fetchToC();
            })
            .catch((err) => {
              console.log("err",err);
                  })
                  this.newheadnode="";
                  this.Addheadnodeafterpopup=false;
      },  
      async Addheadnodebefore(headingId,id) {
        await this.closePopover(id)
        this.headingId=headingId;
        this.Addheadnodebeforepopup=true;
      },
      async closebeforemodal(){
        this.Addheadnodebeforepopup=false;
      },
      async Saveheadnodebefore() {
        
        await axios
            .post(`${this.baseUrl}/stats-python/toc/addheadnodebefore`, 
              {
                tocid: this.tocid, 
                currentheadingid: this.headingId, 
                newheadname: this.newheadnode, 
        
              },this.headerValue)
            .then((res) => {
              console.log("Import study...", res);
              this.fetchToC();
            })
            .catch((err) => {
              console.log("err",err);
                  })
                  this.newheadnode="";
                  this.Addheadnodebeforepopup=false;
      },
  
      async DeleteReport(headingid, reportid, subindex) {
       
        if (confirm(this.$t("Are you sure you want to delete this item?"))) {
          try {
           
            const url = `${this.baseUrl}/stats-python/toc/deletesubheading?tocid=${this.tocid}&headingid=${headingid}&reportid=${reportid}&subindex=${subindex}`;
      
            console.log("Delete URL:", url);
   
            const response = await axios.delete(url,this.headerValue);
  
            console.log("Item successfully deleted:", response.data);
            
            
            alert(this.$t("Deleted successfully"));
            this.fetchToC();
          } catch (err) {
            console.error("Error while deleting the item:", err);
            alert(this.$t("navigations.deletion failed"));
          }
        }
      },  
      async DeleteHeading(headingid,headindex,id) {
        await this.closePopover(id)
        
        if (confirm(this.$t("Are you sure you want to delete this item?"))) {
          try {
          
            const url = `${this.baseUrl}/stats-python/toc/deleteheadnode?tocid=${this.tocid}&headingid=${headingid}&headindex=${headindex}`;
      
            console.log("Delete URL:", url);
      
            
            const response = await axios.delete(url,this.headerValue);
      
            console.log("Item successfully deleted:", response.data);
            
            
            alert(this.$t("navigations.deleted successfully"));
            this.fetchToC();
          } catch (err) {
            console.error("Error while deleting the item:", err);
            alert(this.$t("navigations.deletion failed"));
          }
        }
      },  
      async closemodal(){
            this.newheading="";
            this.newreportname="";
            this.editpopup = false;
            this.editheadpopup=false; 
      },
      toggleChildren(nodeIndices,index, rootId) {
        // Check if the rootId is checked
        const isChecked = this.rootChecked[rootId];
      
        // Iterate over all the node indices provided
        nodeIndices.Reports.forEach((child,nodeIndex) => {
            const childId = `treeModal-${index}-${nodeIndex}`;  
             
            
            if (isChecked) {
              // Mark child as checked
              this.childrenChecked[childId] = true;
            
              // Mark all subchildren as checked
              child.Programms.forEach((_, subindex) => {
                const subchildId = `treeModal-${index}-${nodeIndex}-${subindex}`;
                this.subchildrenChecked[subchildId] = true;
              

              });
            
              // Add the child ID to selectedChildren if not already present
              if (!this.selectedChildren.includes(child.Reportid)) {
                this.selectedChildren.push(child);
              }
              
              this.selectedPrograms.push(
                {
                  nodeIndex:index,
                  reportIndex:nodeIndex,
                  subIndex: child.Subindex,
                  report:child.Reportid,                 
                  programs:child.Programms
                })
            
            } else {
              // Unmark child and subchildren
              delete this.childrenChecked[childId];
            
              child.Programms.forEach((_, subindex) => {
                const subchildId = `treeModal-${index}-${nodeIndex}-${subindex}`;
                delete this.subchildrenChecked[subchildId];
              });
             
              // Remove the child ID from selectedChildren
              this.selectedChildren = this.selectedChildren.filter(id => id !== child.Reportid);
              this.selectedPrograms = this.selectedPrograms.filter(item => item.report!=child.Reportid);
            }
        });
        this.selectedProgramsId=[...new Set(this.selectedPrograms
          .flatMap(obj => Array.isArray(obj.programs) ? obj.programs.map(program => program._id) : [])// Flatten nested arrays
          .filter(id => id !== null) // Remove null values
      )];
      console.log("Selected program ids are",this.selectedProgramsId)
      },
      toggleChild(childIndices,nodeIndex, childIndex, isChecked) {
        const childId = `treeModal-${nodeIndex}-${childIndex}`;
        const child = this.treeModal[Object.keys(this.treeModal)[nodeIndex]].Reports[childIndex];
        if (isChecked) {
          // Add the child to childrenChecked and the selectedChildren list
          this.childrenChecked[childId] = true;
          if (!this.selectedChildren.includes(child.Reportid)) {
              this.selectedChildren.push(child.Reportid);
              childIndices.forEach((child,subindex)=>{
                const subchildId = `treeModal-${nodeIndex}-${childIndex}-${subindex}`;
                this.subchildrenChecked[subchildId]=true
              })
              // Check if child.Reportid already exists in selectedPrograms
            let existingProgram = this.selectedPrograms.find(item => item.report === child.Reportid);

            if (existingProgram) {
                // Merge existing programs while ensuring uniqueness
                existingProgram.programs = [...new Set([...existingProgram.programs, ...child.Programms])];
            } else {
                // Add a new entry if it doesn't exist
                this.selectedPrograms.push({
                    nodeIndex: nodeIndex,
                    reportIndex: childIndex,
                    subIndex: child.Subindex,
                    report: child.Reportid,
                    programs: child.Programms || [] // Ensure it's always an array
                });
            }        
          
          }
        } else {
          // Remove the child from childrenChecked and the selectedChildren list
          delete this.childrenChecked[childId];
          childIndices.forEach((child,subindex)=>{
            const subchildId = `treeModal-${nodeIndex}-${childIndex}-${subindex}`;
            delete this.subchildrenChecked[subchildId]
          })
          this.selectedPrograms = this.selectedPrograms.filter(item => item.report!=child.Reportid);
          this.selectedChildren = this.selectedChildren.filter(
            (selectedChild) => selectedChild !== child.Reportid
          );
        }
        this.selectedProgramsId=[...new Set(this.selectedPrograms
          .flatMap(obj => Array.isArray(obj.programs) ? obj.programs.map(program => program._id) : []) // Flatten nested arrays
          .filter(id => id !== null) // Remove null values
      )];
        console.log("Selected program ids are",this.selectedProgramsId)
      },
      toggleSubChild(nodeIndex, childIndex,subindex, isChecked,version,programsValue) {
        const child = this.treeModal[Object.keys(this.treeModal)[nodeIndex]].Reports[childIndex];     
        const subchildId = `treeModal-${nodeIndex}-${childIndex}-${subindex}`;
        if (isChecked) {
          // Add the child to childrenChecked and the selectedChildren list
              this.subchildrenChecked[subchildId]=true
              let found=false
              this.selectedPrograms = this.selectedPrograms.map(item => {
                if (item.report === child.Reportid) {
                  found =true
                    return {
                        ...item,
                        programs: item.programs ? [...item.programs,programsValue] : [programsValue] // Append new programs to existing ones
                    };
                }
                return item;
              });
              
              // If no existing report found, add a new entry
              if (!found) {
                  this.selectedPrograms.push({
                      nodeIndex: nodeIndex,
                      reportIndex: childIndex,
                      subIndex: child.Subindex,
                      report: child.Reportid,
                      programs: [programsValue], // Ensure new reference
                  });
              }
          } else {
            delete this.subchildrenChecked[subchildId]
            this.selectedPrograms = this.selectedPrograms.map(item => {
              if (item.report === child.Reportid) {
             
                  return {
                      ...item,
                      programs: item.programs.filter(program => program.Version !== version) // Correct filtering
                  };
              }
              return item;
          }).filter(item => item.programs.length > 0); // Remove empty reports
            
        }
        this.selectedProgramsId=[...new Set(this.selectedPrograms
          .flatMap(obj => Array.isArray(obj.programs) ? obj.programs.map(program => program._id) : []) // Ensure obj.programs is an array // Flatten nested arrays
          .filter(id => id !== null) // Remove null values
      )];
      },
      deleteProgram(index,nodeIndex,subindex,version){
        const child = this.treeModal[Object.keys(this.treeModal)[index]].Reports[nodeIndex];     
        delete this.subchildrenChecked[`treeModal-${index}-${nodeIndex}-${subindex}`]
        this.selectedPrograms = this.selectedPrograms.map(item => {
          if (item.report === child.Reportid) {
              return {
                  ...item,
                  programs: item.programs.filter(program => program.Version !== version) // Correct filtering
              };
          }
          return item;
      }).filter(item => item.programs.length > 0); 
      this.selectedProgramsId=[...new Set(this.selectedPrograms
        .flatMap(obj => obj.programs.map(program => program._id)) // Flatten nested arrays
        .filter(id => id !== null) // Remove null values
    )];
      },
      async openModal(type){
        if(type=='assign'){
          this.openSettings=false
          this.openAssignModal=true
          await this.getUserList();
        }
        else{
          this.openMultipleRun=true
        }
      },
      async closeRunModal(type){
        if(type=='assign'){
          this.openAssignModal=false
        }
        else{
          this.openMultipleRun=false
          await this.getCounts()
          await this.getOutputCounts()
          await this.fetchToC()
        }
        this.rootChecked = this.childrenChecked = this.selectedChildren =this.subchildrenChecked = this.selectedPrograms= [];
      },
      async createsave() {
        try {
      
          const response = await axios.post(`${this.baseUrl}/stats-python/toc/addsubheading`, {
            tocid: this.tocid,
            headingid: this.headingid,
            subheadnewname: this.subreport,
          },this.headerValue);
          
          if (response) {
            console.log("Created successfully:", response);
            this.fetchToC();
          } else {
            console.error("Failed to create subheading:", response);
          }
        } 
        catch (error) {
          console.error("Error during API call:", error);
        }
        this.addpopup = false;
        console.log("editpopup",this.editpopup);
      }, 
  
      async onsave(){
        console.log('ToC ID:', this.tocid)
        if(this.editType=='Heading'){
          try {
            const response = await fetch(`${this.baseUrl}/stats-python/toc/updateheadnode`, {
              method: 'PUT',
              headers: this.headerValue.headers,
              body: JSON.stringify({
                tocid: this.tocid,
                headingid: this.headingid,
                newheadname:this.newheading
              }),
            });
        
            if (response.ok) {
              const data = await response.json();
              console.log('API call successful:', data);
              this.fetchToC();
              // Handle successful response here if needed
            } else {
              console.error('Failed to update heading:', response.statusText);
            }
          } catch (error) {
            console.error('Error during API call:', error);
          }
          this.editheadpopup =false;
          this.newheading= "";
           
        }
        else if(this.editType=='Subheading')
        {
          try {
            const response = await fetch(`${this.baseUrl}/stats-python/toc/updatesubheading`, {
              method: 'PUT',
              headers: this.headerValue.headers,
              body: JSON.stringify({
                tocid: this.tocid,
                headingid: this.headingid,
                subheadingid: this.subheadingid,
                newsubheadnewname:this.newReportName
              }),
            });
        
            if (response.ok) {
              const data = await response.json();
              console.log('API call successful:', data);
              // Handle successful response here if needed
              this.fetchToC();
            } else {
              console.error('Failed to update heading:', response.statusText);
            }
          } catch (error) {
            console.error('Error during API call:', error);
          }
        }
        this.editpopup =false;
        this.newreportname="";
      },
      handleTabClick(fileIndex) {
        // ✅ Reset all refreshFlags and activate only the selected tab
        this.refreshFlags = this.files.map((_, i) => i === fileIndex);
      },
      async getUserList()
      {
        const idtoken = store.getters.getIdToken;
        await axios
        .get(
          `${this.baseUrl}/account-core/user/userlist`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userList=(res.data.results || []).sort((a, b) => {
            if (a.fullName < b.fullName) return -1;
            if (a.fullName > b.fullName) return 1;
            return 0;
          });
        })
        .catch((err)=>{
          console.log(err)
        })
      },
      getCurrentFileId() {
        const currentFile = this.files.find(
          (file) => file.file === this.activeTab
        );
        return currentFile ? currentFile.Id : "";
      },
    }
  }
  