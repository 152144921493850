/* eslint-disable */
// import jwtInterceptor from "../Shared/jwtInterceptor";
import axios from "axios";
import baseinput from "../form_elements/baseinput";
import basedate from "../form_elements/basedate";
import baseselect from "../form_elements/baseselect";
import basecheckbox from "../form_elements/basecheckbox";
import baseradiogroup from "../form_elements/consentradiogroup"
import * as formService from "./consentformservice";
import store from "../../../store/index";
import VPagination from "@hennge/vue3-pagination";
import { watch } from "vue";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import QRCode from 'qrcode'
const idtoken = store.getters.getIdToken;
export default {
  name: "newtemplate",
  async beforeMount() {
    // this.baseurl= process.env.VUE_APP_service_URL
    await this.getformtemplate();
    await this.retrievevisitinfo();
    await this.languagelist();
    await this.getallroles()
    await this.getuserIp();
  },
  props: {
    schedulesId: {
      type: String,
      default: ''
    },
    isPatientVisit: {
      type: Boolean,
      default: false
    },
    templateid: {
      type: String,
      default: ''
    },
    stdyid: {
      type: String,
      default: ''
    },
    visitids: {
      type: String,
      default: ''
    },
    issavefun: {
      type: Boolean,
      default: true
    },
    requiredvalidate: {
      type: Boolean,
      default: true
    },
    requiredsubmit: {
      type: Boolean,
      default: true
    },
    requiredlanguage: {
      type: Boolean,
      default: true
    },
    requiredrole: {
      type: Boolean,
      default: false
    },
    linkpatientId: {
      type: String,
      default: ''
    },
    linksiteId: {
      type: String,
      default: ''
    },
    selectedpageno: {
      type: Number,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    }

  },
  components: {
    selectedFile: "",
    baseinput,
    baseselect,
    basecheckbox,
    baseradiogroup,
    basedate,
    QRCode,
    VPagination
  },
  data() {
    return {
      firstsave: true,
      qrcodetxt: {},
      fileid: "",
      filedata: "",
      selectedprivilege: "",
      selectedfield: "",
      selectedrole: "",
      fieldprivilegepopup: false,
      visitname: "",
      islanenglish: 'hr',
      fullPage: true,
      laguageselected: "",
      templist: "",
      language: [],
      roles: [],
      selectedLanguage: "",
      displayLanguage: false,
      newTempID: "",
      baseurl: process.env.VUE_APP_Service_URL,
      saveid: "",
      visitno: "",
      iPaddress: "",
      groups: {},
      errormsg: {},
      formdata: {},
      sendData: {
        sheduleID: "",
        formId: "",
        templateId: "",
        language: [],
        code: "",
        studyId: "",
        folderId: "",
        siteId: "",
        subjectId: "",
        visitId: "",
        visitName: "",
        totalPages: 0,
        version: "",
        formStatus: "New",
        formName: "",
        isDeleted: false,
        pages: [],
        sourceID: "",
        subjectId: "",
        siteId: "",
        dataSource: "Web Browser"
      },
      errorclasslist: {},
      requiredclasslist: {},
      isvalid: [],
      bindarray: {
        page1: { siteCode: "test value", subjectNo: "" },
        page2: "",
        page3: "",
      },
      pageno: 1,
      totalpages: 0,
      uploads: ["photo", "signature", "file"],
      azuredata: {
        DocumentId: "",
        DocumentTypeName: "",
        DocumentTitle: "",
        DocumentDescription: "",
        Document: "",
        OrganizationId: 2,
      },
      inputElements: [
        "color",
        "datetime-local",
        "email",
        "file",
        "hidden",
        "month",
        "number",
        "password",
        "range",
        "search",
        "tel",
        "text",
        "time",
        "url",
        "week",
        "boxed",
        "textbox",
        "boxed",
        "file",
      ],
      htmlarray: [],
      sampledata: "",
      nextButtonclicked: false
    };
  },
  mounted() {
    this.pageno = this.selectedpageno;
    console.log("Paitent id", this.linkpatientId, "Site id is", this.linksiteId)
    console.log(" html array is ", this.htmlarray)
  },
  methods:
  {
    async qrcodefun(fieldname, dataurl) {
      try {
        console.log(await QRCode.toDataURL(dataurl));
        this.qrcodetxt[fieldname] = await QRCode.toDataURL(dataurl);
      } catch (err) {
        console.error(err)
      }
    },
    async pagechanges() {
      // alert("page changes" + store.getters.getselectedpageno);
      this.pageno = store.getters.getselectedpageno;
    },
    async archive(formId, subId) {

      if (confirm("Are you sure you want to archive this form?")) {
        await axios.put(
          `${this.baseurl}/forms/forms/archive`, {
          createdUserEmail: "string",
          createdUserId: "string",
          createdAt: "2022-11-01T05:24:16.754Z",
          lastModifiedUserEmail: "string",
          lastModifiedUserId: "string",
          lastModifiedAt: "2022-11-01T05:24:16.754Z",
          formId: formId,
          subjectId: subId
        },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log("archive status", res);
          })
          .catch((err) => {
            console.log(err);
            alert("Patient schedule not found!")
          });
      }
    },
    async helloButton(event) {
      this.selectedFile = "";
      this.filedata = "";
      console.log("Hello attribute");
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.filedata = e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
        this.selectedFile = input.files[0];
        console.log(this.selectedFile);
      }
      await this.submitFile();

    },
    async submitFile() {
      var formData = new FormData();
      formData.append("Document", this.selectedFile);
      formData.append("DocumentTypeName", "site");
      formData.append("DocumentTitle", "");
      formData.append(
        "DocumentDescription",
        ""
      );
      formData.append("OrganizationId", "");
      console.log("final data", this.azuredata);

      await axios
        .post(`${this.baseurl}/dms/document/create
`, formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          console.log("retreieved data", response);
          this.fileid = `${response.data.data[0]},${response.data.data[1]}`
          console.log("retreieved imageid", this.fileid);
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
    async closemodallll() {
      this.selectedprivilege = "";
      console.log("close modal");
      document.body.style.overflowY = "auto";
      this.fieldprivilegepopup = false;
    },

    async assignprivilege(fieldname) {
      if (this.requiredrole) {
        if (this.selectedrole == "" || this.selectedrole == null || this.selectedrole == "All") {
          alert("Please select a role");
        }
        else {
          await this.reloadprivilege(fieldname);
          this.selectedfield = fieldname;
          document.body.style.overflowY = "hidden";
          this.fieldprivilegepopup = true;
        }
      }
    },
    async reloadprivilege(fieldname) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/forms/forms/getfieldprivilege?RoleId=${this.selectedrole}&TemplateId=${this.templateid}&FieldName=${fieldname}`
          ,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          console.log("Role Listttttttttt", res.data.privilage);
          this.selectedprivilege = res.data.privilage;
        })
        .catch((err) => {
          console.log("Have error After listed");
          console.log(err);
        });
    },
    async savefieldprivilege() {
      if (this.selectedprivilege == "" || this.selectedprivilege == null) {
        alert("Please select a privilege");
      }
      else {
        // alert("templateid is "+ this.templateid + "roleId is " + this.selectedrole + "fieldname is " + this.selectedfield);
        const idtoken = store.getters.getIdToken;
        await axios
          .post(`${this.baseurl}/forms/forms/addoneprivilage`,
            {
              "fieldName": this.selectedfield,
              "privilage": this.selectedprivilege,
              "roleId": this.selectedrole,
              "templateId": this.templateid

            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            alert("Privilege added successfully");
            this.closemodallll();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async getallroles() {
      console.log("screens are listed");
      await axios
        .get(
          `${this.baseurl}/account-core/roles/search?status=ACTIVE`
        )
        .then((res) => {
          this.roles = res.data.data;
          console.log("Role Listttttttttt", this.roles);
        })
        .catch((err) => {
          console.log("Have error After listed");
          console.log(err);
        });
    },
    // User IP address
    async getuserIp() {
      await axios.get(`https://api.ipify.org?format=json`).then((res) => {
        this.iPaddress = res.data.ip;
      });
    },
    //ends here

    async testmsgfun() {
      console.log("inside test function");
    },
    //retreive visit info
    async retrievevisitinfo() {
      console.log("inside retrievevisitinfo function");
      if (this.visitids == null || this.visitids == " ") {
        const visitdetails = store.getters.getvisitdetails;
        this.visitname = visitdetails.Visitname;
        this.visitno = visitdetails.Visitno;
        this.sendData.subjectId = visitdetails.Subject_id;
        this.sendData.siteId = visitdetails.Siteid;
        this.sendData.sheduleID = visitdetails.sheduleId;
      }
      else {
        if (this.issavefun) {
          const idtoken = store.getters.getIdToken;
          await axios
            .get(
              `${this.baseurl}/management/visit/getbyid/${this.visitids}`,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              if (res.data === "No Visit Found") {
                console.log("No Visit Found");
              }
              console.log("visitname is " + res.data.visitFormName);
              console.log("visitname is " + res.data.visitNo);
              this.visitname = res.data.visitFormName;
              this.visitno = res.data.visitNo;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    toggleLanguage() {
      this.displayLanguage = !this.displayLanguage;

    },
    selectLanguage(val) {
      this.displayLanguage = !this.displayLanguage;
      this.selectedLanguage = val;
      console.log("Language is " + this.selectedLanguage);
      this.translatelabel(val);

    },
    save() {
      this.translatelabel.push({
        id: "string",
        language: "string",
        code: "string"

      });

    },
    languagelist: async function () {
      console.log("in language list fun");
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/forms/template/languages`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.language = res.data;
          console.log("language list is");
          // console.log(res.data);
          //  console.log(this.language);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    translatelabel: async function (language) {
      // console.log(this.laguageselected);
      if (confirm(`${this.$t("navigations.are you sure you want to convert this form to")} ${this.laguageselected.language}`)) {
        const loader = this.$loading.show({
          container: this.$refs.formContainer,
        });
        const idtoken = store.getters.getIdToken;
        console.log("language : " + language + " TemplateId :" + this.templateid);
        await axios
          .get(`${this.baseurl}/forms/template/translate?id=${this.templateid}&language=${language}`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }


          )
          .then((res) => {
            loader.hide();
            console.log("Transilted id is:");
            console.log(res.data);
            this.getformtemplateUpdated(res.data)
            this.displayLanguage = !this.displayLanguage;
            //TODO
          })
          .catch((err) => {
            loader.hide();
            console.log(err);
          });
      } else {
        console.log("cancelled language change request");
      }
    },
    gettemplatelist: async function () {
      console.log("htmlarray", this.htmlarray);
      if (this.htmlarray[0]) {
        let tempname = this.htmlarray[0].templateName;
        await axios
          .get(`${this.baseurl}/forms/template/gettemplatebyname?TemplateName=${tempname}`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            // console.log(res.data);
            this.templist = res.data;
            console.log(this.templist);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    getselectedtemplate: async function (tempid) {
      console.log("getselectedtemplate.................")
      const loader = this.$loading.show({
        container: this.$refs.formContainer,
      });
      const idtoken = store.getters.getIdToken;
      console.log("in get form function");
      await axios
        .get(
          `${this.baseurl}/forms/template/get/${tempid}?api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          loader.hide();
          // console.log(res.data);
          this.htmlarray[0] = res.data;
          this.islanenglish = res.data.languageCode;
          console.log(this.htmlarray[0]);
        })
        .catch((err) => {
          loader.hide();
          console.log(err);
        });

      await this.gettemplatelist();
      await this.bindData();
    },
    getformtemplate: async function () {
      console.log("getformtemplate.................")
      const idtoken = store.getters.getIdToken;
      console.log("in get form functionsss", this.templateid);
      await axios
        .get(
          `${this.baseurl}/forms/template/get/${this.templateid}?api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          this.htmlarray[0] = res.data;
          this.islanenglish = res.data.languageCode;
        })
        .catch((err) => {
          console.log(err);

        });
      await this.gettemplatelist();
      await this.bindData();
    },
    getformtemplateUpdated: async function (newtempid) {
      console.log("getformtemplateUpdated.................")
      const idtoken = store.getters.getIdToken;
      console.log("in get form function");
      await axios
        .get(
          `${this.baseurl}/forms/template/get/${newtempid}?api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log(res.data);
          this.htmlarray[0] = res.data;
          console.log(this.htmlarray[0]);
        })
        .catch((err) => {
          console.log(err);
        });

      this.bindData();
    },
    isRequired: function (value, compid) {
      if (value == "") {
        if (value == "" && (compid == "patientid" || compid == "siteID")) {
          window.scrollTo(0, 0,)
        }
        this.errormsg[compid] = "Please enter a value";
        this.isvalid.push("req");
        this.errorclasslist[compid] = "form-control errorlist";
        this.requiredclasslist[compid] = "required";
      } else {
        this.errormsg[compid] = "";
        this.requiredclasslist[compid] = "";
        // this.isvalid.pop();
      }
    },
    isnumber: function (value, compid) {
      if (isNaN(value) || value == "") {
        this.errormsg[compid] = "Please enter a number";
        this.isvalid.push("numb");
        this.errorclasslist[compid] = "form-control errorlist"
      } else {
        // this.isvalid.pop();
      }
    },
    isalphaneumeric: function (value, compid) {
      if (value.match(/^[0-9a-zA-Z]+$/)) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Please enter Alphanumeric";
        this.isvalid.push("alph");
        this.errorclasslist[compid] = "form-control errorlist"
      }
    },
    lengthrange: function (value, compid, minleng, maxleng) {
      if (value.length >= minleng && value.length <= maxleng) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Number of characters should be between " + minleng + " and " + maxleng;
        this.isvalid.push("minlength");
        this.errorclasslist[compid] = "form-control errorlist"
      }
    },
    valuerange: function (value, compid, minval, maxval) {
      let intval = parseInt(value);
      if (intval <= maxval && intval >= minval) {
        // this.isvalid.pop();
      } else {
        if (compid == "siteID") {
          window.scrollTo(0, 0)
        }
        this.errormsg[compid] = "Please enter a value between " + minval + " and " + maxval;
        this.isvalid.push("maxval");
        this.errorclasslist[compid] = "form-control errorlist"
      }
    },
    isemail: function (value, compid) {
      if (value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Invalid email address!";
        this.isvalid.push("mail");
        this.errorclasslist[compid] = "form-control errorlist"
      }
    },
    bindData: async function () {
      this.htmlarray[0].pages.forEach((item) => {
        this.groups[item.pageNo] = [];
        this.formdata[`pageNo${item.pageNo}`] = {};
        item.field.forEach((subitem) => {
          if (!this.groups[item.pageNo].includes(parseInt(subitem.groupOrderNo))) {
            this.groups[item.pageNo].push(parseInt(subitem.groupOrderNo));
          }
          this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = "";
          if (subitem.validation != "") {
            subitem.validation.forEach((valid) => {
              if (valid == "Required") {
                this.requiredclasslist[subitem.fieldName] = "required";

              }
            });
          }
        });
      });
      this.totalpages = this.htmlarray[0].pages.length;
      // console.log(this.groups);
    },
    testfun: function () {
      this.isvalid = [];
      if (this.requiredvalidate) { this.validate(); }
      if (this.isvalid.length == 0) {
        this.nextButtonclicked = true
        this.$emit("saveClicked")
        if (this.totalpages == 1) {
          this.savefun();
          alert("Form saved successfully.")

        }
        else {
          this.editFun();
          alert("Form saved successfully.")

        }


      }
    },

    reviewAndSubmit: async function () {
      this.isvalid = [];
      if (this.requiredvalidate) { this.validate(); }

      if (this.isvalid.length == 0) {

        if (this.totalpages == 1) {
          await this.savefun();
          alert("Form submitted successfully")
        }
        else {
          this.editFun();
          // alert("Form submitted successfully.")
          alert(this.$t('navigations.form submitted successfully.'))

        }
      }
      formService.isreviewstatus(this.saveid);
    },
    validatebyfield(fieldname, validation, pgn) {
      this.errorclasslist[fieldname] = "form-control";
      // alert(`value changes page no is ${this.pageno} and fieldname is ${fieldname} and validations are ${validation[0]} value is ${ this.formdata[`pageNo${pgn}`]}`);
      validation.forEach((valid) => {
        if (valid == "Required") {
          this.isRequired(
            this.formdata[`pageNo${pgn}`][fieldname],
            fieldname
          );
        }
        if (valid == "Number" && this.formdata[`pageNo${pgn}`][fieldname] != "") {
          this.isnumber(
            this.formdata[`pageNo${pgn}`][fieldname],
            fieldname
          );
        }
        if (valid == "Alphanumeric" && this.formdata[`pageNo${pgn}`][fieldname] != "") {
          this.isalphaneumeric(
            this.formdata[`pageNo${pgn}`][fieldname],
            fieldname
          );
        }
        if (valid == "email" && this.formdata[`pageNo${pgn}`][fieldname] != "") {
          this.isemail(
            this.formdata[`pageNo${pgn}`][fieldname],
            fieldname
          );
        }
        if (valid.includes("lengthrange") && this.formdata[`pageNo${pgn}`][fieldname] != "") {
          let regExp = /\(([^)]+)\)/;
          let matches = regExp.exec(valid);
          const myArr = matches[1].split(",");
          let minrange = parseInt(myArr[0]);
          let maxrange = parseInt(myArr[1]);
          this.lengthrange(
            this.formdata[`pageNo${pgn}`][fieldname],
            fieldname,
            minrange,
            maxrange
          );

        }

        if (valid.includes("valuerange") && this.formdata[`pageNo${pgn}`][fieldname] != "") {
          console.log("inside valuerange functiuon");
          let regExp = /\(([^)]+)\)/;
          let matches = regExp.exec(valid);
          const myArr = matches[1].split(",");
          console.log("myArr is " + myArr);
          let minval = parseInt(myArr[0]);
          let maxval = parseInt(myArr[1]);
          this.valuerange(
            this.formdata[`pageNo${pgn}`][fieldname],
            fieldname,
            minval,
            maxval
          );

        }
      });
    },
    validate: function () {
      this.htmlarray[0].pages.forEach((item) => {
        let pagenumber = `pageNo${item.pageNo}`;
        if (item.pageNo == this.pageno) {
          item.field.forEach((subitem) => {
            this.errorclasslist[subitem.fieldName] = "form-control";
            if (subitem.validation != "") {
              subitem.validation.forEach((valid) => {
                if (valid == "Required") {
                  this.isRequired(
                    this.formdata[pagenumber][subitem.fieldName],
                    subitem.fieldName
                  );
                }
                if (valid == "Number" && this.formdata[pagenumber][subitem.fieldName] != "") {
                  this.isnumber(
                    this.formdata[pagenumber][subitem.fieldName],
                    subitem.fieldName
                  );
                }
                if (valid == "Alphanumeric" && this.formdata[pagenumber][subitem.fieldName] != "") {
                  this.isalphaneumeric(
                    this.formdata[pagenumber][subitem.fieldName],
                    subitem.fieldName
                  );
                }
                if (valid == "email" && this.formdata[pagenumber][subitem.fieldName] != "") {
                  this.isemail(
                    this.formdata[pagenumber][subitem.fieldName],
                    subitem.fieldName
                  );
                }
                if (valid.includes("lengthrange") && this.formdata[pagenumber][subitem.fieldName] != "") {
                  let regExp = /\(([^)]+)\)/;
                  let matches = regExp.exec(valid);
                  const myArr = matches[1].split(",");
                  let minrange = parseInt(myArr[0]);
                  let maxrange = parseInt(myArr[1]);
                  this.lengthrange(
                    this.formdata[pagenumber][subitem.fieldName],
                    subitem.fieldName,
                    minrange,
                    maxrange
                  );

                }

                if (valid.includes("valuerange") && this.formdata[pagenumber][subitem.fieldName] != "") {
                  console.log("inside valuerange functiuon");
                  let regExp = /\(([^)]+)\)/;
                  let matches = regExp.exec(valid);
                  const myArr = matches[1].split(",");
                  console.log("myArr is " + myArr);
                  let minval = parseInt(myArr[0]);
                  let maxval = parseInt(myArr[1]);
                  this.valuerange(
                    this.formdata[pagenumber][subitem.fieldName],
                    subitem.fieldName,
                    minval,
                    maxval
                  );

                }
              });
            }
          });
        }
      });
    },

    // save and edit starts

    savefun: async function () {
      this.sendData.totalPages = this.htmlarray[0].totalPages
      this.sendData.templateId = this.htmlarray[0].templateId;
      this.sendData.studyId = this.stdyid;
      this.sendData.folderId = (this.htmlarray[0].folderId ? this.htmlarray[0].folderId : "");
      this.sendData.sheduleID = (this.htmlarray[0].sheduleID ? this.htmlarray[0].sheduleID : "");
      this.sendData.visitNo = this.visitno;
      this.sendData.sourceID = this.iPaddress;
      this.sendData.dataSource = "Web Browser";
      this.sendData.formName = this.visitname;
      this.sendData.visitId = this.visitids;
      this.sendData.sheduleID = this.schedulesId;
      this.sendData.version = this.htmlarray[0].version;
      this.sendData.visitName = this.visitname;
      this.sendData.subjectId = this.linkpatientId
      this.sendData.siteId = this.linksiteId
      let createdpage = {};
      createdpage.pageName = this.htmlarray[0].pages[0].pageName;
      createdpage.pageNo = this.htmlarray[0].pages[0].pageNo;
      createdpage.pageStatus = this.htmlarray[0].pages[0].status;
      createdpage.field = [];
      this.htmlarray[0].pages[0].field.forEach((subitem) => {
        let val;
        let itemstatus;
        if (subitem.type == 'checkbox') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName].toString();
          itemstatus = "Not Validated";
        }
        else if (subitem.type == 'header' || subitem.type == 'subHeader') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
          itemstatus = "";
        }
        else if (subitem.type == 'uploadfile') {
          val = this.fileid;
          itemstatus = "";
        }
        else if (subitem.type == 'year') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName].toString();
          itemstatus = "";
        }
        else {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
          itemstatus = "Not Validated";
        }
        createdpage.field.push({
          fieldName: subitem.fieldName,
          label: subitem.label,
          type: subitem.type,
          value: val,
          status: itemstatus,
          validation: subitem.validation,
          groupOrderNo: subitem.groupOrderNo,
          validatedBy: "",
          validatedAt: "",
          comment: "",
          required: subitem.required,
          options: subitem.options,
          imagePath: subitem.imagePath,
        });
      },


      );
      this.sendData.pages.push(createdpage);
      this.saveid = await formService.apicall(this.sendData);
      if (this.nextButtonclicked) {
        formService.isreviewstatus(this.saveid);
        this.nextButtonclicked = false
      }
      localStorage.setItem("saveid", this.saveid);
      this.$emit('child-event')
    },
    editFun: function () {
      const pgn = this.pageno - 1;
      let createdpage = {};
      createdpage.formId = this.saveid;
      createdpage.isArchived = true;
      createdpage.sourceID = this.iPaddress;
      createdpage.dataSource = "Web Browser";
      createdpage.pages = [];
      createdpage.pages[0] = {};
      createdpage.pages[0].pageName = this.htmlarray[0].pages[pgn].pageName;
      createdpage.pages[0].pageNo = this.htmlarray[0].pages[pgn].pageNo;
      createdpage.pages[0].pageStatus = this.htmlarray[0].pages[pgn].status;
      createdpage.pages[0].field = [];
      this.htmlarray[0].pages[pgn].field.forEach((subitem) => {
        let val;
        let itemstatus;
        if (subitem.type == 'checkbox') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName].toString();
          itemstatus = "Not Validated";
        }
        else if (subitem.type == 'header' || subitem.type == 'subHeader') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
          itemstatus = "";
        }
        else if (subitem.type == 'uploadfile') {
          val = this.fileid;
          itemstatus = "";
        }
        else {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
          itemstatus = "Not Validated";
        }
        createdpage.pages[0].field.push({
          fieldName: subitem.fieldName,
          label: subitem.label,
          type: subitem.type,
          value: val,
          status: itemstatus,
          validation: subitem.validation,
          groupOrderNo: subitem.groupOrderNo,
          validatedBy: "",
          validatedAt: "",
          comment: "",
          required: subitem.required,
          options: subitem.options,
          imagePath: subitem.imagePath,
          audit: [
            {
              oldValue: "",
              newValue: "",
              validatedBy: "",
              validatedDate: "",
              validatedTime: "",
              status: "Not validated",
              comment: "",
            },
          ],
        });
      });

      if (store.getters.getsourceUrl === true || store.getters.getsourceUrl === 'true') {
        this.editdata(createdpage, this.nextButtonclicked, this.saveid);
        if (this.nextButtonclicked) {

          this.nextButtonclicked = false
          // this.$router.push("/listform")
        }

      }

    },
    async editdata(data, isnextreview, saveid) {
      const idtoken = store.getters.getIdToken;
      console.log(data);
      await axios
        .put(`${this.baseurl}/forms/forms/formpagesave?api-version=1.0`, data,
          //.put(`https://localhost:5002/forms/formpagesave?api-version=1.0`, data,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
        .then(() => {
          console.log("success");
          if (isnextreview) {
            this.isreviewstatus(saveid);
          }
        })
        .catch(console.log);
    },
    async isreviewstatus(formId) {
      const idtoken = store.getters.getIdToken;
      await axios.put(`${this.baseurl}/forms/forms/isreviewstatus`, { "formId": formId },
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("review status changed", res);
        })
        .catch((err) => console.log("error in review status change", err));
    },

    // save and edit ends

    nextfun: function () {
      this.isvalid = [];
      if (this.requiredvalidate && (store.getters.getsourceUrl === true || store.getters.getsourceUrl === 'true')) {
        this.validate();
      }
      if (this.isvalid.length == 0) {
        let totalpg = parseInt(this.totalpages);
        if (this.pageno < totalpg) {
          if (this.issavefun) {
            if (this.pageno == 1 && this.firstsave) {
              this.savefun();
              this.firstsave = false;
            } else {
              this.editFun();
            }
          }
          this.pageno++;
          // }
        }
      }
    },
    previousFun: function () {
      if (this.pageno > 1) {
        console.log("previous page");
        this.pageno--;
      }
    },
    incrementcount: function () {
      this.totalpages++;
      console.log("increment" + this.totalpages);
    },
  },
  watch: {
    pageno(newpageno, oldpageno) {
      if (newpageno >= 1) {
        window.scrollTo(0, 0);
      }
    }
  }
};
