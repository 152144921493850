<template>
    <div class="import_forms_modal__wrapper">
      <div class="import_forms_modal__container mt-5">
        <div class="modal__title d-flex justify-content-between align-items-center">
          <h4 class="ml-lg-2 py-1">
            {{ $t("navigations.study lock") }}
          </h4>
          <div
            class="cursor-pointer px-2 py-1"        
          >
            <i class="fa fa-times" title="Close" @click.prevent="closeModal" ></i>
          </div>
        </div>
        <div class="modal-body">
            <div class="form-group row my-lg-2">
              
                <label class="d-flex align-items-center" :for="isLocked"> <span class="mr-2">{{ $t("navigations.lock study") }}</span>
                <input
                    type="checkbox"
                    id="isLocked"
                    v-model="isLocked"
                    class="mr-2"
               />         </label>
               <label>{{ $t("navigations.select modules to lock") }}</label>
              <div class="d-flex ">
                <div class="mr-2" v-for="(isSelected, moduleName) in moduleList" :key="moduleName">
                  <input
                    type="checkbox"
                    :id="moduleName"
                    v-model="selectedModules[moduleName]"
                    :disabled = !isLocked
                    class="mr-1"
                  />
                  <label :for="moduleName" class="ms-1">{{ moduleName }}</label>
                </div>
              
              </div>
              <div class="text-center">
                    <button
                        class="save_btn py-1 px-4" 
                        type="button"
                        @click.prevent="lockFun()"
                      >{{ $t("navigations.lock") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../store/index";
import { useI18n } from "vue-i18n";

export default {
  name: "lockStudy",
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });
    return { t, locale };
  },
  components: {
  },
  props: {
    studyId : String,
  },
  data() {
    return {
      baseurl: `${process.env.VUE_APP_Service_URL}/management/study/`,
      moduleList : {"Visits" : false, "Sites": false, "Subjects": false, "Schedules": false, "Forms": false },
      selectedModules : {},
      headerValue: "",
      isLocked : false,
      studyItem : {},
    };
  },
  async mounted() {
    this.token = store.getters.getIdToken;
    this.headerValue = {
      headers: {
        Authorization: "Bearer " + this.token,
        "Content-Type": "application/json",
      },
    }
    this.getStudy();
  },
  methods: {
    async closeModal() {
      this.$emit("closeModal");
    },
    async getStudy(){
      await axios.get(`${this.baseurl}getbyid/${this.studyId}`,this.headerValue)
        .then((res) => {
          this.studyItem = res.data;
          this.selectedModules = this.studyItem.lockedItems;
          this.isLocked = this.studyItem.isLocked;
            console.log("get study", res.data); 
        }).catch(err => {
            console.log("error in get study", err);
        })
    },
    async lockFun() {
      console.log("Locked Modules:", this.selectedModules);
      await axios.put(`${this.baseurl}studylock?studyId=${this.studyId}&isLocked=${this.isLocked}`,this.selectedModules, this.headerValue
        ).then((res)=>{
          console.log("lock study", res.data);
          alert(this.$t('navigations.the lock details have been updated successfully'));
          this.$emit("closeModal");
        }).catch(err => {
          console.log("error in lock study...", err);
        });
    },
  },
};

</script>

<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
@import "../../assets/savepages.css";

.import_forms_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.import_forms_modal__container {
  position: fixed;
  width: min(400px, 90%);
  border-radius: 4px;
  background: #fff;
}
.modal__title {
  background-color: var(--pop-up-background);
  color: white;
}

.cancel_btn {
  padding: 0.43rem 0.4rem;
  border-radius: 0.25rem;
}
.custom-popup {
  text-align: center;
}

.simple-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 33px; /* Adjust the padding to increase the size */
  width: 29%; /* Adjust the width as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 4000; /* Increase the z-index value if needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.simple-wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.multiselect-template-theme {
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
  --ms-radius: 0;
  --ms-line-height: 0.8;
  --ms-option-font-size: 0.8rem;
  --ms-font-size: 0.8rem;
}
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  visibility: hidden;
  width: 60px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: -18%; /* Position the tooltip above the icon */
  left: -375%;
  margin-left: -30px; /* Use half of the width to center-align */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip-wrapper:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
}
</style>