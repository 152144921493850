/* eslint-disable */
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
import jwt_decode from "jwt-decode";
import store from "../../store/index";
import auth0 from "auth0-js";
import VueApexCharts from 'vue3-apexcharts';
import { ref, computed, onBeforeMount } from "vue";


export default {
    name: "landingpage",
    components: {
        apexchart: VueApexCharts
    },
    setup() {
        let valnow = ref("");
        const router = useRouter();
        const profileBlock = ref(false);
        let baseurl = ref(`${process.env.VUE_APP_Service_URL}`);
        let currentTheme = ref(localStorage.getItem("theme-color"));
        let webAuth = new auth0.WebAuth({
            domain: "dev-datamatica.eu.auth0.com",
            clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
        });

        onBeforeMount(async () => {
            store.dispatch("setNavbar", false);
            document.getElementById("page-wrapper").style.width = "100%";
            if (currentTheme.value == null) {
                localStorage.setItem("theme-color", "theme-default");
                currentTheme.value = localStorage.getItem("theme-color");
            }
        });

        router.beforeEach((to, from, next) => {
            if (to.name != "profileSettings") {
                store.dispatch("setNavbar", true);
                document.getElementById("page-wrapper").style.removeProperty("width");
            } else {
                document.getElementById("page-wrapper").style.width = "100%";
            }
            next();
        });

        let usersdetails = computed(() => {
            valnow.value = store.getters.getStudyIs;
            return jwt_decode(store.getters.getIdToken);
        });

        function displayProfile() {
            const profile = document.querySelector(".profile-block");
            const userProfile = document.querySelector(".tool-tip");
            profile.style.display = profile.style.display === "block" ? "none" : "block";
            userProfile.style.display = userProfile.style.display === "block" ? "none" : "block";
        }

        async function logout() {
            await logoutaudit();
            localStorage.clear();
            webAuth.logout({
                returnTo: `${process.env.VUE_APP_logout_URL}`,
                clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
            });
        }

        async function logoutaudit() {
            const token = store.getters.getIdToken;
            const emailId = await jwt_decode(token).email;
            await axios.post(
                `${baseurl.value}/account-core/user/saveloginaudit`,
                {
                    userEmail: emailId,
                    logdetails: [{ action: "Log Out", date: new Date().toISOString(), time: new Date().toISOString() }],
                },
                { headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" } }
            );
        }

        function setstatus(screen) {
            store.dispatch("setCollapse", false);
            store.dispatch("setDefaultMenu", screen);
        }

        function expandSidebar() {
            let x = document.getElementsByClassName("theme-menu")[0];
            x.style.display = x.style.display === "block" ? "none" : "block";
        }

        function switchTheme(theme) {
            localStorage.setItem("theme-color", theme);
            currentTheme.value = localStorage.getItem("theme-color");
        }

        return {
            usersdetails,
            displayProfile,
            valnow,
            profileBlock,
            logout,
            setstatus,
            switchTheme,
            expandSidebar
        };
    },
    data() {
        return {
            integration: {
                system: 'SAP CRM',
                apiUrl: '',
                authType: 'OAuth 2.0',
                apiKey: '',
                username: '',
                password: ''
            },
            mapping: {
                contactId: 'contact_id',
                accountName: 'account_name',
                email: 'email',
                phone: 'phone_number',
                dealStage: 'deal_stage'
            },
            autoSync: true,
            lastSync: '2023-10-05 14:30:00',
            contactGrowthOptions: {
                chart: { type: 'line' },
                xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May'] },
                title: { text: 'Contact Growth' }
            },
            contactGrowthSeries: [{ name: 'Contacts', data: [45, 89, 120, 150, 210] }],
            dealStageOptions: {
                chart: { type: 'donut' },
                labels: ['Prospecting', 'Qualification', 'Negotiation', 'Closed Won', 'Lost'],
                title: { text: 'Deal Stages' }
            },
            dealStageSeries: [44, 55, 41, 17, 15],
            interactions: [
                { id: 1, date: '2023-10-05', contact: 'John Smith', type: 'Meeting', outcome: 'Positive', details: 'Discussed contract renewal' },
                { id: 2, date: '2023-10-04', contact: 'Acme Corp', type: 'Email', outcome: 'Pending', details: 'Sent proposal document' }
            ]
        };
    },
    methods: {
        interactionTypeClass(type) {
            return {
                'bg-primary': type === 'Meeting',
                'bg-success': type === 'Email',
                'bg-warning': type === 'Call',
                'bg-info': type === 'Demo'
            }[type] || 'bg-secondary';
        }
    }
};
