<template>
  <div class="stat-card-white" ref="captureArea">
    <div class="stat-header-white">
   
      <span v-if="fromPage=='descriptiveStatistics' || fromPage=='demographicsScreen'">
       {{ $t("navigations.statistical summary") }}
      </span>
      
      <div class="d-flex" v-if="!loader">
        <select class="graph-select py-0" v-model="selectedPercentageAttribute" id="attribute-select" @change="updateChart">
          <option v-for="option in attributeOptions" :key="option" :value="option">{{ option.toUpperCase() }}</option>
        </select>
        <div v-if="!loader" class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false,exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
     
      <div v-if="!loader && enrolledornot && fromPage=='patientScreening' || fromPage== 'patientRetention'" class="controls mr-2">
        <label class="mb-0">
          <input type="checkbox" v-model="aiPredictionTrue" @change="updateChart"> {{ labelOne }}
        </label>
        <label class="mb-0">
          <input type="checkbox" v-model="aiPredictionFalse" @change="updateChart"> {{  labelTwo }}
        </label>
        <div v-if="!loader" class="expand-block px-1 mb-1 cursor-pointer" @click.prevent="expandBlock=true">
          <img src="../../assets//img/expand.png" alt="Expand block">
        </div>
        <div class="text-center" v-on:clickout="showVisitPopup = false">
          <div
            class="position-relative cursor-pointer biggerfont ml-2"
            @click.prevent="showVisitPopup = !showVisitPopup"
          >
            <i class="fa fa-ellipsis-v"></i>
          </div>
          <div class="plan-side-popup" ref="excludeThis" v-if="showVisitPopup">
            <div @click.prevent="showVisitPopup = false,exporttoexcell()">
              Export to Excel
            </div>
            <div @click.prevent="exporttopdf()">
              Export to PDF
            </div>
          </div>
        </div>
      </div>
      
      <!-- <div v-if="!loader" class="expand-block px-1 mb-1 cursor-pointer">
        <img src="../../assets//img/more.png" alt="More block">
      </div> -->
    </div>
    <div class="stat-body-white">
      <div v-if="loader" class="loader"></div>
      <div v-if="attribute && !loader" class="pgbarwidth">
        <div v-for="(percentage, key) in percentages" :key="key" class="attribute-bar">
          <span class="col-md-4">{{ getMappedValue(key, attribute) }}</span>
          <div class="progress-bar">
            <div class="progress" :style="{ width: percentage + '%' }"></div>
          </div>
          <span class="col-md-4">{{ percentage.toFixed(2) }}%</span>
        </div>
      </div>
          <div class="average-container align-items-center" v-if="(fromPage == 'descriptiveStatistics' && !loader) || (fromPage == 'demographicsScreen' && !loader)">
        <div class="average-stats d-flex pt-0" :class="{'overflow-block':averagesItem.length > 8}">
          <div class="stat-item" v-for="(averageItem, key) in averagesItem" :key="key">
            <h3>{{ key.charAt(0).toUpperCase() + key.slice(1) }}</h3> 
            <h4 >{{ averageItem!=null ? averageItem.toFixed(2) : 0}}</h4> 
          </div>
        </div>
      </div> 
    </div>
  </div>
  <enlargerpopup v-if="expandBlock==true" @closeModal="expandBlock=false">
    <div v-if="attribute && !loader  && (fromPage=='patientScreening' || fromPage== 'patientRetention')" class="pgbarwidth">
      <div v-for="(percentage, key) in percentages" :key="key" class="attribute-bar">
        <span>{{ getMappedValue(key, attribute) }}</span>
        <div class="progress-bar">
          <div class="progress" :style="{ width: percentage + '%' }"></div>
        </div>
        <span>{{ percentage.toFixed(2) }}%</span>
      </div>
    </div>
    <div class="average-container align-items-center" v-if="fromPage == ('descriptiveStatistics' && !loader) || ('demographicsScreen' && !loader)">
        <div class="average-stats pt-0" :class="{'overflow-block':averagesItem.length > 8}">
          <div class="stat-item" v-for="(averageItem, key) in averagesItem" :key="key">
            <h3>{{ key.charAt(0).toUpperCase() + key.slice(1) }}</h3> 
            <h4 >{{ averageItem!=null ? averageItem.toFixed(2) : 0}}</h4> 
          </div>
        </div>
      </div> 
  </enlargerpopup>
</template>

<script>
import axios from "axios";
import store from "../../store/index";
import enlargerpopup from "../AiCharts/popupfiles/enlarge_popup.vue"
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
export default {
  name: 'AttributePercentageBar',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    templateId: {
      type: String,
      default: ''
    },
    fromPage: {
      type: String,
      default: ""
    },
    enrolledornot: {
      type: Boolean,
      default: true
    },
    labelOne:{
      type: String,
      default:"Acceptable"
    },
    labelTwo:{
      type: String,
      default:"Rejected"
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  components:{
    enlargerpopup
  },
  data() {
    return {
      showVisitPopup: false,
      idtoken: "",
      baseApi: process.env.VUE_APP_Service_URL,
      baseurl: `${process.env.VUE_APP_Service_URL}/forms-ml/`,
      mappings: {},
      attribute: '',
      percentages: {},
      averages: [],
      averagesItem:[],
      selectedPercentageAttribute: '',
      tableName:'',
      attributeOptions: [],
      averageFieldOptions: [],
      selectedAverageFields: ['', '', ''],
      aiPredictionTrue: true,
      aiPredictionFalse: true,
      expandBlock:false
    };
  },
  watch: {
    data: {
      handler() {
        this.loadAttributes();
        this.updateAverages();
      },
      deep: true,
    },
  },
  async beforeMount() {
    this.idtoken = store.getters.getIdToken;
    if (this.fromPage == 'patientScreening') {
      await this.createmapping();
      this.updateAverages();
      this.loadAttributes();
    }
    if (this.fromPage == 'descriptiveStatistics' || this.fromPage=='demographicsScreen') {
      await this.updateAverages();
      if (this.attributeOptions.length > 0) {
        this.selectedPercentageAttribute = this.attributeOptions[0];
      }
    }
    if(this.fromPage == 'patientRetention'){
      this.loadAttributes();
      this.updateAverages();
    }


  },
  methods: {
    async exporttoexcell() {
      this.$emit("exporttoexcell");
    },
    exporttopdf() {
      // Create a temporary container
      const excludeElement = this.$refs.excludeThis;
      excludeElement.style.display = 'none';
      const pdfContainer = document.createElement('div');

      const captureArea = this.$refs.captureArea.cloneNode(true);
      // Append cloned elements to the container
      pdfContainer.appendChild(captureArea);
      
      // Optionally style the container for PDF formatting
     
      pdfContainer.style.width = '100%';

      pdfContainer.style.display = 'block';
      // Set the options for html2pdf
      if(this.fromPage=='studySummary')
      {
        let filename = "Statistical Summary";
        const opt = {
          margin: 0,
          filename: `${filename}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
        };
      
        // Convert the temporary container to PDF
        html2pdf().set(opt).from(pdfContainer).save();
      } 
      else{
        let filename = "Statistical Summary"
        const opt = {
          margin: 0,
          filename: `${filename}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'pt', format: 'a4', orientation: 'landscape' }
        };
      
        // Convert the temporary container to PDF
        html2pdf().set(opt).from(pdfContainer).save();
      }

    },
    async createmapping() {
      try {
        const response = await axios.get(
          `${this.baseApi}/forms/templatedesign/getpagesbyid?formId=${this.templateId}&version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data) {
          const pages = response.data.pages;
          pages.forEach(page => {
            page.field.forEach(field => {
              if (field.options && field.options.length > 0) {
                this.mappings[field.fieldName] = field.options.reduce((map, option) => {
                  map[option.codevalue] = option.optionname;
                  return map;
                }, {});
              }
            });
          });
        }
      } catch (error) {
        console.log("Error creating mappings", error);
      }
    },
    loadAttributes() {
      if (this.fromPage == 'patientScreening'|| this.fromPage== 'patientRetention') {
        const sampleData = this.data && this.data.length > 0 ? this.data[0].features : {};
        this.attributeOptions = Object.keys(sampleData).filter(key => {
          const uniqueValues = new Set(this.data.map(item => item.features[key])).size;
          return uniqueValues < 5;
        });

        if (this.attributeOptions.length > 0) {
          this.selectedPercentageAttribute = this.attributeOptions[0];
        }

        this.findAttribute();
        this.loadAverageFieldOptions();
      }

    },
    loadAverageFieldOptions() {
      const sampleData = this.data && this.data.length > 0 ? this.data[0].features : {};
      const numericFields = Object.keys(sampleData).filter(key => {
        return typeof sampleData[key] === 'number' && new Set(this.data.map(item => item.features[key])).size > 6;
      });
      this.averageFieldOptions = numericFields;
      this.selectedAverageFields[0] = this.averageFieldOptions[0];
      this.selectedAverageFields[1] = this.averageFieldOptions[1];
      this.selectedAverageFields[2] = this.averageFieldOptions[2];
    },
    findAttribute() {
      if (!this.selectedPercentageAttribute) {
        this.attribute = '';
        return;
      }

      this.attribute = this.selectedPercentageAttribute;
      this.calculatePercentages();
    },
    validateData(data) {
      return data.filter(d => {
        const aiPrediction = d.features.AIPrediction;
        if ((aiPrediction && this.aiPredictionTrue) || (!aiPrediction && this.aiPredictionFalse)) {
          return d.features[this.attribute] !== undefined;
        }
        return false;
      });
    },
    calculatePercentages() {
      const filteredData = this.validateData(this.data);
      const counts = filteredData.reduce((acc, item) => {
        const value = item.features[this.attribute];
        acc[value] = (acc[value] || 0) + 1;
        return acc;
      }, {});
      const total = filteredData.length;
      this.percentages = Object.keys(counts).reduce((acc, key) => {
        acc[key] = (counts[key] / total) * 100;
        return acc;
      }, {});
    },
    async updateAverages() {
      if (this.fromPage == 'patientScreening'|| this.fromPage== 'patientRetention') {
        const filteredData = this.validateData(this.data);
      this.averages = this.selectedAverageFields.map(field => {
        if (field) {
          const total = filteredData.reduce((sum, item) => sum + item.features[field], 0);
          return total / filteredData.length;
        }
        return 0;
      });
      }
      if(this.fromPage == 'descriptiveStatistics'){
        this.tableName='SampleTable'
      }
      if(this.fromPage == 'demographicsScreen'){
        this.tableName='DM'
      }
      if (this.fromPage == 'descriptiveStatistics' || this.fromPage=='demographicsScreen') {
        await axios
          .post(`${this.baseurl}report/getdescriptiveanalysis?table_name=${this.tableName}`,
          {
            "ignored_features": [
              "_id","Participant ID"
            ],
            "filter_condtions": {}
          }
          )
          .then((res) => {
            this.averages = res.data.NumericStats;
            this.attributeOptions = Object.keys(this.averages)
           
            this.averagesItem=[]
            this.averagesItem =  Object.keys(this.averages[this.selectedPercentageAttribute])
                .reduce((obj, key) => {
                  obj[key] = this.averages[this.selectedPercentageAttribute][key];
                  return obj;
                }, {});
            
          })
          .catch((err) => {
            console.log(err);
          });

      }
    },
    getMappedValue(value, key) {
      return this.mappings[key] ? this.mappings[key][value] || value : value;
    },
    updateChart() {
      if(this.fromPage=='patientScreening'|| this.fromPage== 'patientRetention')
      {
        this.findAttribute();
        this.updateAverages();
      }
      if(this.fromPage=='descriptiveStatistics' || this.fromPage=='demographicsScreen')
      {
        this.updateAverages();
      }
  
    }
  },
};
</script>

<style scoped>
@import "../AiCharts/charts.css";
.attribute-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.attribute-bar span {
  width: 100px;
}

.progress-bar {
  flex: 1;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
  margin: 0 10px;
}

.progress {
  background-color: #00aaff;
  height: 100%;
  transition: width 0.3s;
}



.average-title {
  margin-bottom: 5px;
  font-size: 1.2em;
  color: #888;
}


.pgbarwidth {
  width: 100%;
  max-width: 400px;
  /* border: 1px solid #f1f1f1;
    padding: 12px 5px; */
}


.percentminheight {
  height: auto;
  min-height: 190px;
}

.average-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
}
.average-container h5 {
  font-size: 1rem;
}

.overflow-block {
  width: 100%;
  padding:8px 8px;
  overflow-x: scroll;
  background-color: #edf9ff;
}

/* Styling the scrollbar */
.overflow-block::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* Styling the scrollbar track */
.overflow-block::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the track */
}

/* Styling the scrollbar thumb */
.overflow-block::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners for the thumb */
}

/* Styling the scrollbar thumb on hover */
.overflow-block::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

/* For Firefox (scrollbar pseudo-class) */
.overflow-block {
  scrollbar-width: thin; /* "auto" or "thin" */
  scrollbar-color: #888 #f1f1f1; /* thumb color and track color */
}

.controls {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.percentageheightmin{
  min-height:190px;
}
</style>
