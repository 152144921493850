<template>
        <!-- Navbar Section -->
        <div class="row border-bottom white-bg">
        <nav class="navbar navbar-static-top" role="navigation">
          <div class="landing-nav-header"><img src="../../assets/img/IDSLogo.png" alt="Logo" />
          </div >
                <ul class="d-flex justify-content-end align-items-center flex-grow-1 py-0 px-2 my-0" role="list">
                    <!-- <li>
                        <a href="login.html">
                            <i class="fa fa-sign-out"></i> Log out
                        </a>
                    </li> -->
                    <li
                      class="px-2 px-md-0 user-profile cursor-pointer"
                      aria-label="User profile"
                      tabindex="0"
                    >
                      <a
                        class="user-block px-0"
                        @click.prevent="displayProfile()"
                        data-toggle="tooltip"
                        data-placement="top"
                      >
                        <img
                          :src="usersdetails.picture"
                          class="img-fluid profile-pic"
                          alt="User Profile Image"
                        />
                        <div class="tool-tip">
                          {{ $t("navigations.user profile") }}
                        </div>
                      </a>
                      <div class="profile-block animate fadeIn text-start">
                        <div
                          class="login-user-details d-flex justify-content-start align-items-center"
                        >
                          <div class="login-user-details--image">
                            <img
                              class="profile-pic me-2"
                              :src="usersdetails.picture"
                              alt="User Image"
                            />
                          </div>
                          <div class="login-user-details--details d-flex flex-column">
                            <span
                              ><b>{{ usersdetails.given_name }}</b></span
                            >
                            <span>{{ usersdetails.name }}</span>
                          </div>
                        </div>
                        <div class="user-details d-flex flex-column">
                          <div
                            class="theme-change-block d-flex justify-content-start align-items-center"
                            @click.prevent="$router.push('/profileSettings')"
                          >
                            <img
                              class="me-2"
                              src="../../assets/img/user_profile.png"
                              alt="User Profile"
                            />
                            <span>{{ $t("navigations.profile") }}</span>
                        </div>
                          <div
                            class="theme-change-block d-flex justify-content-start align-items-center"
                            @click="expandSidebar()"
                          >
                            <img
                              class="me-2"
                              src="../../assets/img/user_themes.png"
                              alt="User Themes"
                            />
                            <span class="theme-change-block">{{
                              $t("navigations.themes")
                            }}</span>
                            <div class="theme-menu animated fadeIn">
                              <div class="title">Themes</div>
                              <div
                                class="setings-item default-skin"
                                @click="switchTheme('theme-default')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Default </a>
                                </span>
                              </div>
                              <!-- <div class="setings-item blue-skin" @click="themeBlack()">
                                    <span class="skin-name ">
                                      <a href="#" class="s-skin-1">
                                        Black light
                                      </a>
                                    </span>
                                  </div> -->
                              <div
                                class="setings-item teal-skin"
                                @click="switchTheme('theme-green')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Teal Green </a>
                                </span>
                              </div>
                              <div
                                class="setings-item dusty-skin"
                                @click="switchTheme('theme-violet')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Dusty Lavender </a>
                                </span>
                              </div>
                              <div
                                class="setings-item ocean-skin"
                                @click="switchTheme('theme-ocian')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Ocean Green </a>
                                </span>
                              </div>
                              <div
                                class="setings-item stormy-skin"
                                @click="switchTheme('theme-stormy')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Stormy Cloud </a>
                                </span>
                              </div>
                              <div
                                class="setings-item pastel-skin"
                                @click="switchTheme('theme-pastel')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Pastel Russet </a>
                                </span>
                              </div>
                            </div>
                          </div>
                          <span class="dropdown-divider"></span>
                          <div
                            class="theme-change-block d-flex justify-content-start align-items-center"
                            @click.prevent="logout"
                          >
                            <img
                              class="me-2"
                              src="../../assets/img/logout.png"
                              alt="Logout"
                            />
                            <span>{{ $t("navigations.logout") }}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                </ul>
        </nav>
        </div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-12 align-self-center go-back d-flex justify-content-between">
          <h2 class="mt-0 ">Customer Relationship Management</h2>
          <h2 class="mt-0 "> <router-link to="/home" class="nav-link p-0" @click.prevent="close">Go to Home</router-link></h2>
      </div>
    </div>
    <div class="wrapper wrapper-content animated fadeInRight px-0">
        <div class="container-fluid crm-integration">
        <div class="row">
            <!-- Left Configuration Panel -->
            <div class="col-md-3 px-1">
            <!-- Connection Settings -->
            <div class="card mb-2">
                <div class="card-header bg-primary text-white">
                <i class="bi bi-people-fill"></i> CRM Connection
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label class="form-label">CRM System</label>
                        <select class="form-select" v-model="integration.system">
                        <option>SAP CRM</option>
                        <option>Salesforce</option>
                        <option>HubSpot</option>
                        <option>Microsoft Dynamics</option>
                        </select>
                    </div>
        
                    <div class="mb-3">
                        <label class="form-label">API Endpoint URL</label>
                        <input type="url" class="form-control" v-model="integration.apiUrl">
                    </div>
        
                    <div class="mb-3">
                        <label class="form-label">Authentication Type</label>
                        <select class="form-select" v-model="integration.authType">
                        <option>OAuth 2.0</option>
                        <option>API Key</option>
                        <option>Basic Auth</option>
                        </select>
                    </div>
        
                    <div class="mb-3" v-if="integration.authType === 'API Key'">
                        <label class="form-label">API Key</label>
                        <input type="password" class="form-control" v-model="integration.apiKey">
                    </div>
        
                    <div class="mb-3" v-if="integration.authType === 'Basic Auth'">
                        <label class="form-label">Username</label>
                        <input type="text" class="form-control" v-model="integration.username">
                        <label class="form-label mt-2">Password</label>
                        <input type="password" class="form-control" v-model="integration.password">
                    </div>
                    <button class="btn btn-success w-100">Connect</button>
                </div>
            </div>
    
            <!-- Data Mapping -->
            <div class="card mb-2">
                <div class="card-header bg-primary text-white">
                <i class="bi bi-diagram-3"></i> CRM Field Mapping
                </div>
                <div class="card-body">
                <div class="mb-3">
                    <label class="form-label">Contact ID Field</label>
                    <input type="text" class="form-control" v-model="mapping.contactId">
                </div>
    
                <div class="mb-3">
                    <label class="form-label">Account Name Field</label>
                    <input type="text" class="form-control" v-model="mapping.accountName">
                </div>
    
                <div class="mb-3">
                    <label class="form-label">Email Field</label>
                    <input type="text" class="form-control" v-model="mapping.email">
                </div>
    
                <div class="mb-3">
                    <label class="form-label">Phone Field</label>
                    <input type="text" class="form-control" v-model="mapping.phone">
                </div>
    
                <div class="mb-3">
                    <label class="form-label">Deal Stage Field</label>
                    <input type="text" class="form-control" v-model="mapping.dealStage">
                </div>
    
                <button class="btn btn-success w-100 mt-3">
                    <i class="bi bi-check-circle"></i> Save CRM Mapping
                </button>
                </div>
            </div>
            </div>
    
            <!-- Right Dashboard -->
            <div class="col-md-9 px-1">
            <!-- Sync Controls -->
            <div class="card mb-2">
                <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                    <button class="btn btn-primary">
                        <i class="bi bi-arrow-repeat"></i> Sync Contacts
                    </button>
                    <div class="form-check form-switch ms-3 d-inline-block">
                        <input class="form-check-input" type="checkbox" v-model="autoSync">
                        <label class="form-check-label">Auto Sync</label>
                    </div>
                    </div>
                    <div class="text-muted">
                    Last Sync: {{ lastSync }}
                    </div>
                </div>
                </div>
            </div>
    
            <!-- CRM Dashboard -->
            <div class="card mb-2">
                <div class="card-header">
                <i class="bi bi-bar-chart"></i> Customer Insights
                </div>
                <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                    <apexchart 
                        type="line" 
                        height="250"
                        :options="contactGrowthOptions"
                        :series="contactGrowthSeries"
                    ></apexchart>
                    </div>
                    <div class="col-md-6">
                    <apexchart 
                        type="donut" 
                        height="250"
                        :options="dealStageOptions"
                        :series="dealStageSeries"
                    ></apexchart>
                    </div>
                </div>
                </div>
            </div>
    
            <!-- Interaction History -->
            <div class="card mb-2">
                <div class="card-header">
                <i class="bi bi-clock-history"></i> Recent Interactions
                </div>
                <div class="card-body">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Contact</th>
                        <th>Type</th>
                        <th>Outcome</th>
                        <th>Details</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="interaction in interactions" :key="interaction.id">
                        <td>{{ interaction.date }}</td>
                        <td>{{ interaction.contact }}</td>
                        <td>
                        <span class="badge" :class="interactionTypeClass(interaction.type)">
                            {{ interaction.type }}
                        </span>
                        </td>
                        <td>{{ interaction.outcome }}</td>
                        <td>{{ interaction.details }}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
  </template>
  
  <script src="./crm.js"></script>
  <style scoped>
    .crm-integration .card {
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    }

    .interaction-badge {
    min-width: 80px;
    text-align: center;
    }

    .table-hover tbody tr:hover {
    background-color: rgba(13, 110, 253, 0.05);
    }
  </style>