<template>
  <div class="wrapper wrapper-content">
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5>{{ $t("navigations.form list") }}</h5>
      </div>
      <div class="ibox-content">
         <div v-if="FormList.length === 0" class="no-data-message text-center">
          <p>No data available.</p>
        </div>
        <div v-else>
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th class="text-center"> Form Id</th>
                <th class="wrap-text">{{ $t("navigations.form name") }} <sup><i class="fa fa-asterisk imp"></i></sup></th>
                <th>{{ $t("navigations.language") }}</th>
                <th class="text-center">Edit</th>
                  <th class="text-center">Delete</th>
              </tr>            
            </thead>      
            <tbody>
              
              <tr v-for="(study, index) in FormList" :key="study.studyId">
                <td class="text-center">{{ study.formId }}</td>
                <td class="wrap-text" v-if="editIndex !== index">{{ study.formName }}</td>
                <td v-else>
                  <input
                    v-model="editableRow.formName"
                    class="form-control"
                    type="text"
                     @input="clearFieldError('formName')"
                  />
                   <div v-if="validationErrors.formName" class="text-danger">
                      {{ validationErrors.formName }}
                    </div>
                </td>
               <td v-if="editIndex !== index">{{ study.language.join(', ') }}</td>
                <td v-else>
                  <input
                    v-model="editableRow.language"
                    class="form-control"
                    type="text"
                  />
                </td>
                <td class="text-center">
                  <template v-if="editIndex !== index">
                    <i
                      class="fa fa-edit edit-delete"
                       v-if="protocol.status !== 'Generated'"
                      @click.prevent="editRow(index, study)"
                    ></i>
                  </template>
                  <!-- //../../../assets/img/video_requests.png -->
                  <template v-else>
                    <!-- Update icon -->
                    <i
                      class="fa fa-check action-icon text-success"
                      @click="updateRow(study.id,study.studyId)"
                      title="Update"
                    ></i>
                   
                    <i
                      class="fa fa-times action-icon text-danger"
                      @click="cancelEdit"
                      title="Cancel"
                    ></i>
                  </template>
                </td>
                  <td class="text-center">
                  <i class="fa fa-trash edit-delete"  v-if="protocol.status !== 'Generated'" style="color: #a13b7c" @click="deleteRow(study.id)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Pagination  -->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  ">
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination v-model="currentPage" :pages="totalPages" :range-size="0" active-color="#618bd7"
                    @update:modelValue="loadPage" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.action-icon {
  cursor: pointer;
  font-size: 18px;
  margin: 0 5px;
}

.action-icon:hover {
  opacity: 0.8;
}

.text-success {
  color: #28a745; 
}
.wrap-text {
  max-width:50ch;
  word-wrap: break-word;
  white-space: normal;
}

.text-danger {
  color: #dc3545;
}</style>
<script src="./FormManagement.js"></script>
