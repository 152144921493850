/* eslint-disable */
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
import jwt_decode from "jwt-decode";
import store from "../../store/index";
import auth0 from "auth0-js";
import VueApexCharts from 'vue3-apexcharts';
import { ref, computed, onBeforeMount } from "vue";

export default {
    name: "landingpage",
    components: {
        apexchart: VueApexCharts,
    },
    setup() {
        const router = useRouter();
        const isConnected = ref(false);
        const connectionError = ref("");
        const isLoading = ref(false);
        const formData = ref({
            url: "",
            username: "",
            password: "",
            authType: "OAuth 2.0"
        });

        // Chart configurations
        const transactionOptions = ref({
            chart: {
                type: 'line',
                height: 350,
                zoom: { enabled: false }
            },
            dataLabels: { enabled: false },
            stroke: { curve: 'smooth', width: 2 },
            colors: ['#1a73e8'],
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
            },
            title: { text: 'Monthly Transactions', align: 'left' }
        });

        const transactionSeries = ref([{
            name: "Transactions",
            data: [65, 79, 120, 81, 156, 155]
        }]);

        const inventoryOptions = ref({
            chart: { type: 'bar', height: 350 },
            plotOptions: {
                bar: { horizontal: false, columnWidth: '55%' }
            },
            dataLabels: { enabled: false },
            colors: ['#1a73e8'],
            xaxis: { categories: ['Drug A', 'Drug B', 'Drug C', 'Drug D'] },
            title: { text: 'Current Stock Levels', align: 'left' }
        });

        const inventorySeries = ref([{
            name: "Stock",
            data: [65, 59, 80, 41]
        }]);

        let valnow = ref("");
        const profileBlock = ref(false);
        let baseurl = ref(`${process.env.VUE_APP_Service_URL}`);
        let currentTheme = ref(localStorage.getItem("theme-color"));
        let webAuth = new auth0.WebAuth({
            domain: "dev-datamatica.eu.auth0.com",
            clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
        });

        onBeforeMount(async () => {
            store.dispatch("setNavbar", false);
            document.getElementById("page-wrapper").style.width = "100%";
            if (currentTheme.value == null) {
                localStorage.setItem("theme-color", "theme-default");
                currentTheme.value = localStorage.getItem("theme-color");
            }
        });

        router.beforeEach((to, from, next) => {
            if (to.name != 'profileSettings') {
                store.dispatch("setNavbar", true);
                document.getElementById("page-wrapper").style.removeProperty("width");
            } else {
                document.getElementById("page-wrapper").style.width = "100%";
            }
            next();
        });

        let usersdetails = computed(function () {
            valnow.value = store.getters.getStudyIs;
            return jwt_decode(store.getters.getIdToken);
        });

        async function connectToSAP() {
            try {
                isLoading.value = true;
                connectionError.value = "";
                
                // Add actual SAP connection logic here
                if (formData.value.url && formData.value.username && formData.value.password) {
                    // Mock successful connection
                    isConnected.value = true;
                    store.dispatch("setSAPConnection", true);
                } else {
                    throw new Error("Please fill all required fields");
                }
            } catch (error) {
                console.error("Connection failed:", error);
                connectionError.value = error.message || "Connection failed. Please check credentials.";
                isConnected.value = false;
            } finally {
                isLoading.value = false;
            }
        }

        function displayProfile() {
            const profile = document.querySelector(".profile-block");
            const userProfile = document.querySelector(".tool-tip");
            if (profile.style.display == "block") {
                profile.style.display = "none";
            } else {
                profile.style.display = "block";
            }
            if (userProfile.style.display == "block") {
                userProfile.style.display = "none";
            }
        }

        async function logout() {
            await logoutaudit();
            let a = localStorage.clear();
            let b = webAuth.logout({
                returnTo: `${process.env.VUE_APP_logout_URL}`,
                clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
            });
            return a && b;
        }

        async function logoutaudit() {
            const token = store.getters.getIdToken;
            const emailId = await jwt_decode(token).email;
            await axios.post(
                `${baseurl.value}/account-core/user/saveloginaudit`,
                {
                    userEmail: emailId,
                    logdetails: [{
                        action: "Log Out",
                        date: "2022-08-30T09:10:42.634Z",
                        time: "2022-08-30T09:10:42.634Z",
                    }],
                },
                {
                    headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "application/json",
                    },
                }
            ).catch((err) => {
                console.log(err);
            });
        }

        function setstatus(screen) {
            store.dispatch("setCollapse", false);
            switch (screen) {
                case 'AI/Ml':
                    store.dispatch("setDefaultMenu", "AI/Ml");
                    break;
                case 'Analytical':
                    store.dispatch("setDefaultMenu", "Analytical");
                    break;
                case 'clinical':
                    store.dispatch("setDefaultMenu", "Clinical");
                    break;
                case 'stats':
                    store.dispatch("setDefaultMenu", "Stats");
                    break;
                default:
                    console.warn("Invalid screen type:", screen);
                    break;
            }
        }

        async function expandSidebar() {
            let x = document.getElementsByClassName("theme-menu")[0];
            x.style.display = x.style.display === "block" ? "none" : "block";
        }

        function switchTheme(theme) {
            localStorage.setItem("theme-color", theme);
            currentTheme.value = localStorage.getItem("theme-color");
        }

        return {
            usersdetails,
            displayProfile,
            valnow,
            profileBlock,
            logout,
            setstatus,
            switchTheme,
            expandSidebar,
            isConnected,
            connectionError,
            isLoading,
            formData,
            connectToSAP,
            transactionOptions,
            transactionSeries,
            inventoryOptions,
            inventorySeries
        };
    }
};