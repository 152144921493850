<template>
    <div class="iframe-wrapper">
        <div class="iframe-container" v-if="subContainer==false" :class="{'modal-container-xl':containerxl==true,'modal-container-md':containermd==true,'modal-container-sm':containersm==true}">
            <div class="titleclass d-flex justify-content-between py-2 px-3">
            <h4 class="my-0"> <slot name="title">Default Title</slot></h4>
            <div class="close-link cursor-pointer" @click.prevent="closeModal()">
                <i class="fa fa-times" ></i>
            </div>
        </div> 
            <div class="iframe-body  p-4" :class="{'body-xl':bodyxl==true,'body-md':bodymd==true}">
                <slot name="body">Nothing to display</slot>
            </div>
            <div class="iframe-footer p-4" v-if="footerShow==true">
                <slot name="footer" ></slot>
            </div>
        </div>
        <div class="iframe-container" v-else>
            <div class="titleclass d-flex justify-content-between py-2 px-3">
            <h4 class="my-0"> <slot name="subContainerTitle">Default Title</slot></h4>
            <div class="close-link cursor-pointer" @click.prevent="closeSubModal()">
                <i class="fa fa-times" ></i>
            </div>
        </div> 
            <div class="iframe-body  p-4" :class="{'width-height':bodyxl==true,'height-300':bodymd==true}">
                <slot name="subContainerBody">Nothing to display</slot>
            </div>
            <div class="iframe-footer py-3" v-if="footerShow==true">
                <slot name="subContainerFooter" ></slot>
            </div>
        </div>
    </div>
</template>
<script src="./customModal.js"></script>
  <style scoped>
  @import "../../assets/css/style.css";
  .iframe-wrapper {
  position: fixed;
  display: flex;
  justify-content:center;
  align-items: center;
  inset: 0;
  background: rgb(0 0 0 / 30%);
  transition: cubic-bezier;
  overflow: hidden;
  z-index: 10000;
}

.iframe-container {

  background: #fff;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}


.body-xl {
  height: 500px;
  overflow: auto;
}

.body-md{
  height:calc(100vh - 11rem);
  overflow-y: scroll;
}

/* Container styles  */

.modal-container-sm{
    width:min(500px,90%)
}
.modal-container-md
{
    width: min(800px, 90%);
}
.modal-container-xl{
    width: min(900px, 90%);
}

/* Container styles  */
  </style>
  