/* eslint-disable */
import config from "../../../../config";
import baseinput from "../form_elements/baseinput";
import baseselect from "../form_elements/baseselect";
import basedate from "../form_elements/basedate";
import basecheckbox from "../form_elements/basecheckbox";
import consentradiogroup from "../form_elements/consentradiogroup"
import axios from "axios";
import moment from "moment";
import * as showformService from "./consentformservice";
import store from "../../../store/index";
import jwt_decode from "jwt-decode";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import html2canvas from "html2canvas";
import signaturePopUp from "../../../components/signaturePopUp/signaturePopUp.vue"
import QRCode from 'qrcode'
import jsPDF from "jspdf";
export default {
  name: "newtemplate",
  components: {
    baseinput,
    baseselect,
    basecheckbox,
    consentradiogroup,
    basedate,
    signaturePopUp,
    QRCode,
    VPagination
  },
  props:{
    isPatientVisit: {
      type: Boolean,
      default: false
    },
      formsesid:{
          type: String,
          default: ''
      },
      esignvisitId:{
        type: String,
        default: ''
      },
      esignsiteId:{
      type: String,
      default: ''
      },
      esignsubjectId:{
      type: String,
      default: ''
      },
      esignstudyId:{
      type: String,
      default: ''
      },
      closedoption:{
        type: String,
        default: ''
    },
      isvalidate:{
        type: Boolean,
        default: false
      },
      issavefun:{
        type: Boolean,
        default: true
      },
      isfieldvalidate:{
        type: Boolean,
        default: false
      },
      isqueryfield:{
        type: Boolean,
        default: true
      },
      requiredsubmit: {
        type: Boolean,
        default: true
      },
      patientLoginId:{
      type: String,
        default: ''
    }
    },
   async beforeMount() {
    await this.setformid();
    await this.geteditSignStatus()
    this.defaultdate = localStorage.getItem("dateformat");
    this.formsid = this.formsesid.trim();
    this.fieldName = store.getters.getFieldName;
    await this.getById();
    await this.getPageName();
    await this.getFormHeader();
    await this.patientId(this.forms[0].subjectId);
    await this.FormOpenQueryCount();
    await this.FormCloseQueryCount();
    await this.getuserIp();
  },
  mounted(){
    window.addEventListener("FormDigitalSignature", (event) => {
      if (event.detail.storage == true) {
        this.geteditSignStatus()
      }
    });
    
  },
  data() {
    return {
      qrcodetxt:{},
      loggedinUser:"",
      signedby: "",
      signtime: "",
      signdate: "",
      signatureformId: "",
      signatuestatus: "",
      signStatus: "",
      signpopUp: false,
      iPaddress:"",
      isPatientValue:false,
      defaultdate: "",
      fieldName :"",
      pagesavefieldnames: [],
      showdiv:false,
      modelinputclass: "form-control",
      modelradioclass: "col-md-10 my-1",
      testvar:"",
      groups: {},
      audit: [],
      formsid: "",
      modelelvalidation: "",
      modelcomments: "",
      modelpgno: "",
      modelelement: "",
      modelname: "hello",
      modelvalue: "mvalue",
      modeltype: "mtype",
      receivedData: [],
      envvar: process.env.NODE_ENV,
      baseurl: process.env.VUE_APP_Service_URL,
      saveid: "",
      errormsg: {},
      errorclasslist: {},
      forms: [],
      isVerified:"",
      patientsId:"",
      sitesId:"",
      formName:"",
      pageName:[],
      formHeader:"",
      visits:[],
      pages:[],
      selectedValue:"",
      formdata: {},
      sndData: {
        formId: "string",
        pages: [],
        dataSource:"",
        sourceID:"",
      },
      isvalid: [],
      bindarray: {
        page1: { siteCode: "test value", subjectNo: "" },
        page2: "",
        page3: "",
      },
      pageno: 1,
      totalpages: 0,
      uploads: ["photo", "signature", "file"],
      inputElements: [
        "color",
        "datetime-local",
        "email",
        "file",
        "hidden",
        "image",
        "month",
        "number",
        "password",
        "range",
        "search",
        "tel",
        "text",
        "time",
        "url",
        "week",
        "boxed",
        "textbox",
        "boxed",
        "file",
      ],
      htmlarray: [],
      openQueryCount : 0,
      closeQueryCount : 0,
      Querydetails : "",
      formOpenQueryCount :0,
      formClosedQueryCount :0,

      azuredata: {
        DocumentTypeName: "",
        DocumentTitle: "",
        DocumentDescription: "",
        PrimaryReferenceId :"",
        PrimaryReferenceType :"",
        SecondaryReferenceId :"",
        SecondaryReferenceType:"",
        OrganizationId:0,
        Document: ""
      },
      documentId:"",
      filename :[],
      docValidations:true,
      currentdocId:"",
      loaddata:false,
      documentName:"",
      Document:"",
      documentById:"",
      documentlist:[],
      documentVersionId: [],
      isReview:"",
      
    };
  },
  methods: {
   async unsignfunction(){
    if(this.signStatus == `Signed`){
      this.$emit('child-event')
    }
    },
    
    async qrcodefun(fieldname,dataurl){
      try {
        console.log(await QRCode.toDataURL(dataurl));
        this.qrcodetxt[fieldname] = await QRCode.toDataURL(dataurl);
      } catch (err) {
        console.error(err)
      }
          },
    async pagechanges(){
      this.pageno = store.getters.getselectedpageno;
          },
    async geteditSignStatus() {
      const idtoken = store.getters.getIdToken;
      await axios.get(
        `${this.baseurl}/account-core/user/getsigneddetails?patientLoginId=${this.patientLoginId}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          }
        })
        .then((res) => {
          console.log("Form sign is", res);
          this.signdate = res.data.signedDate;
          this.signtime = moment(this.signdate).format("hh:mm a")
          this.signStatus = res.data.status
          console.log("Form sign status is", this.signStatus);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async CloseModelCall() {
      this.signpopUp = false;
    },
     // User IP address
     async getuserIp() {   
      await axios.get(`https://api.ipify.org?format=json`).then((res) => {this.iPaddress = res.data.ip;}); 
    },
    //ends here
      async handleClickSignIn() {
          try {
              const googleUser = await this.$gAuth.signIn();
              if (!googleUser) {
                  return null;
              }
              //console.log("googleUser", googleUser);
              //this.user = googleUser.getBasicProfile().getEmail();
             // console.log("getId", this.user);
            //  console.log("getBasicProfile", googleUser.getBasicProfile());
              console.log("getAuthResponse", googleUser.getAuthResponse().access_token);
              console.log("getAuthResponse----", googleUser.getAuthResponse().expires_at);
             // console.log("getAuthResponse", this.$gAuth.instance.currentUser.get().getAuthResponse());
             await this.getHealthData(googleUser.getAuthResponse().access_token)
          } catch (error) {
              //on fail do something
              console.error(error);
              return null;
          }
      },
      async getHealthData(g_token) {

          // ------------ to get current date for google fit -------
          // var start = new Date();
          // start.setHours(0,0,0,0);
          // start.setDate(start.getDate()-1);
          //
          // var end = new Date();
          // end.setHours(23,59,59,999);
          // end.setDate(end.getDate()-1);
            //-------------------end ----------------------------
          // let data = {
          //     "aggregateBy": [{
          //         "dataSourceId": "derived:com.google.step_count.delta:com.google.android.gms:estimated_steps"
          //     }],
          //     "bucketByTime": {"durationMillis": 86400000}, // This is 24 hours
          //     "startTimeMillis": 1546210381932,   // Start time
          //     "endTimeMillis": 1547210381932  // End Time
          // }
          await axios.post(
              `https://www.googleapis.com/fitness/v1/users/me/dataset:aggregate`, {
                  "aggregateBy": [{
                      "dataSourceId": "derived:com.google.step_count.delta:com.google.android.gms:estimated_steps",
                  },
                      {
                          "dataSourceId": "derived:com.google.weight:com.google.android.gms:merge_weight",
                      },
                      {
                          "dataSourceId": "derived:com.google.height:com.google.android.gms:merge_height",
                      },
                      {
                          "dataSourceId": "derived:com.google.calories.expended:com.google.android.gms:merge_calories_expended",
                      },
                      {
                          "dataSourceId": "derived:com.google.heart_minutes:com.google.android.gms:merge_heart_minutes",
                      },
                      // {
                      //     "dataSourceId": "derived:com.google.distance.delta:com.google.android.gms:merge_distance_delta",
                      // },
                  ],
                  "bucketByTime": {"durationMillis": 86400000},
                 // "startTimeMillis": start.getTime(),
                 // "endTimeMillis":  end.getTime()
                 "startTimeMillis": config.googlefitvalues.startTimeMillis,   // Start time
                 "endTimeMillis": config.googlefitvalues.endTimeMillis  // End Time
              },
              {
                  headers: {
                      Authorization: "Bearer " + g_token,
                      "Content-Type": "application/json",
                  },
              }
          )
              .then(async (res) => {

                  console.log("healthInfo", res.data);

                  await this.updateFitnessData(res.data.bucket[0].dataset);


                  // let healthData = {
                  //     "formId":this.formsesid,
                  //     "updateFieldDTO": [
                  //         {
                  //             "fieldName": "string",
                  //             "value": "string",
                  //             "status": "string",
                  //             "comment": "string"
                  //         }
                  //     ]
                  // }

                  // let arr = [];
                  // for (let i = 0; i < res.data.bucket[0].dataset.length; i++) {
                  //     arr.push({
                  //         fieldName: i.dataSourceId,
                  //         value: true,
                  //         status: true,
                  //         comment: "string"
                  //     });
                  // }
                  //this.updateFitnessData(re);
              })
              .catch((err) => {
                  console.log(err);
              });
      },
      async updateFitnessData(data1){
          const arr = [];

          for (let i=0; i < data1.length; i++)
          {
             // console.log("i",data1[i])
             // console.log("00000000000000",data1[i].point.length)
              for (let j=0; j < data1[i].point.length; j++)
              {
                  console.log("j",data1[i].point[j])
                      for (let k=0; k < data1[i].point[j].value.length; k++)
                      {
                         // console.log("k",data1[i].point[j].value[k])
                         // console.log("step count",data1[i].point[j].value[k].intVal);
                          //console.log("calory",data1[i].point[j].value[k].fpVal);
                          const mm = {
                              fieldName: data1[i].point[j].dataTypeName,
                              value: data1[i].point[j].value[k].intVal != null ? data1[i].point[j].value[k].intVal.toString() : data1[i].point[j].value[k].fpVal.toString(),
                              status: null,
                              comment: null
                      }
                            arr.push(mm)
                         // console.log("array",arr)
                      }
              }
          }
          const idtoken = store.getters.getIdToken;

          await axios.put(
              `${this.baseurl}/forms/forms/updatefeildfromgoogle`,{
              // `https://localhost:5001/forms/updatefeildfromgoogle`,{

                  formId: this.formsid,
                  //pageNo:null,
                  updateFieldDTO: arr
              },
              {
                  headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                  },
              })
              .then((res) => {
                  console.log("Form updated with google fit data",res);
              })
              .catch((err) => {
                  console.log(err);
              });
      },
      async printFacture() {
      let pdffilename = this.sitesId + "_" + this.patientsId + "_" + this.formName;
      pdffilename = pdffilename.replace(/ +/g, "");
      this.showdiv=true;
      if(this.showdiv==true){
      console.log("for pdf");
      let elementbyclassname = document.getElementsByClassName('getdfcontentss');
      let doc = new jsPDF('p', 'mm');
      //foeeatch statement for paging pdf starts
      let countforlastpage = 1;
      elementbyclassname.forEach(async element => {
    // console.log(document.getElementsByClassName('getdfcontentss'));
      // let element = document.getElementById("getdfcontentss");
      console.log("element is",element);
     await html2canvas(element).then(canvas => {
      console.log("for pdf2");
      let imgData = canvas.toDataURL('image/png');

let imgWidth = 210;
let pageHeight = 295;
let imgHeight = canvas.height * imgWidth / canvas.width;
let heightLeft = imgHeight;
let position = 10;
doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
heightLeft -= pageHeight;
while (heightLeft >= 0) {
  position = (heightLeft - imgHeight) + 10;
  doc.addPage();
  doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;
}
      //test pdf save ends
      });
      if(countforlastpage == elementbyclassname.length)
      {
      doc.save( pdffilename + '.pdf');
      }
      else{
        doc.addPage();
        countforlastpage++;
      }
    });
    // foeeatch statement for paging pdf ends

    }
      },
    filter(date) {
      if(date){
        return moment(date).format(this.defaultdate)
      }
            console.log("Reverse is")
            console.log(date)
    },
    async onclosed() {
      await this.$router.push(`/${this.closedoption}`);
    },
    async getById(){
      const idtoken = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/forms/forms/getconsnetform/${this.formsid}?api-version=1`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        this.forms[0]=res.data
        this.formName=res.data.formName
        this.isReview = this.forms[0].isReview
     })
     .catch((err) => {
       console.log(err);
     });
    },
    async FormOpenQueryCount(){
      this.StudyId = store.getters.getStudyIs;

       const idtoken = store.getters.getIdToken;
        await axios.get(`${this.baseurl}/forms/query/getquerycount?StudyId=${this.StudyId}&FormId=${this.formsid}&status=Open`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
        ).then((res) => {
          this.formOpenQueryCount = res.data
        })
        .catch((err) => console.log(err));

    },
    async FormCloseQueryCount(){
      this.StudyId = store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;
        await axios.get(`${this.baseurl}/forms/query/getquerycount?StudyId=${this.StudyId}&FormId=${this.formsid}&status=Closed`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
        ).then((res) => {
          this.formClosedQueryCount = res.data
        })
        .catch((err) => console.log(err));
},
    async FormQueryDetails(status){
        const idtoken = store.getters.getIdToken;
        const currentstudy = store.getters.getStudyIs;
        console.log("In Form Query Details " ,currentstudy,this.formsid,status);
        await axios
                .get(`${this.baseurl}/forms/query/getquerydetails?StudyId=${currentstudy}&FormId=${this.formsid}&status=${status}`,
                            {
                                headers: {
                                    Authorization: "Bearer " + idtoken,
                                    "Content-Type": "application/json",
                                },
                            }
                )
                .then((res) =>
                {
                    this.Querydetails = res.data;
                    console.log(res.data);

                })
                .catch(err => {
                    console.log(err);
                })
        const modalWrapperQuery = document.querySelector('.modal__wrapper__Two');
        modalWrapperQuery.classList.add('active');
    },
    async FieldCloseQueryCount(){
      console.log("in field Query Count closed");
      this.StudyId = store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;
        await axios.get(`${this.baseurl}/forms/query/getquerycount?StudyId=${this.StudyId}&FormId=${this.formsid}&status=Closed&PageNo=${this.modelpgno}&FieldName=${this.modelname}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
        ).then((res) => {
          this.closeQueryCount = res.data
        })
        .catch((err) => console.log(err));
    },
    async FieldOpenQueryCount(){
      this.StudyId = store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;
      console.log("In field Open Query Count " ,this.StudyId,this.formsid,this.modelpgno,this.modelname);
        await axios.get(`${this.baseurl}/forms/query/getquerycount?StudyId=${this.StudyId}&FormId=${this.formsid}&status=Open&PageNo=${this.modelpgno}&FieldName=${this.modelname}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
        ).then((res) => {
          this.openQueryCount = res.data
        })
        .catch((err) => console.log(err));
    },
    async FieldQueryDetails(status){
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      console.log("In field Query Details " ,currentstudy,this.formsid,status,this.modelname);
      await axios
              .get(`${this.baseurl}/forms/query/getquerydetails?StudyId=${currentstudy}&FormId=${this.formsid}&status=${status}&PageNo=${this.modelpgno}&FieldName=${this.modelname}`,
                          {
                              headers: {
                                  Authorization: "Bearer " + idtoken,
                                  "Content-Type": "application/json",
                              },
                          }
              )
              .then((res) =>
              {
                  this.Querydetails = res.data;
                  console.log(res.data);

              })
              .catch(err => {
                  console.log(err);
              })
      const modalWrapperQuery = document.querySelector('.modal__wrapper__Two');
      modalWrapperQuery.classList.add('active');
    },
    closeQueryModal: function() {
  const modalWrapperQuery = document.querySelector('.modal__wrapper__Two');
  modalWrapperQuery.classList.remove('active');
    },

    //Form Document Upload 
    UploadDocumentModal(){
      const modalWrapper = document.querySelector('.modal-wrapper-upload');
      modalWrapper.classList.add('active');
    },
    CloseUploadModal(){
    const modalWrapper = document.querySelector('.modal-wrapper-upload');
    modalWrapper.classList.remove('active');
    },
    ViewDocumentModal(){
    this.getDocuments();
    const modalWrapper = document.querySelector('.modal-wrapper-view');
    modalWrapper.classList.add('active');
    // alert(formId);
    },
    ClosemodalView(){
    const modalWrapper = document.querySelector('.modal-wrapper-view');
    modalWrapper.classList.remove('active');
    },
    async previewFiles(event) {
    this.azuredata.Document = event.target.files[0];
    console.log("upload file is", this.azuredata.Document.name);
    },
    async CreateDocumentDMS(){
    this.azuredata.DocumentTypeName = "Form"
    var formData = new FormData();

    console.log("form data", formData);
    formData.append("Document", this.azuredata.Document);
    formData.append("DocumentTypeName", this.azuredata.DocumentTypeName);
    formData.append("DocumentTitle", this.azuredata.DocumentTitle);
    formData.append("DocumentDescription", this.azuredata.DocumentDescription);
    formData.append("OrganizationId", this.azuredata.OrganizationId);

    await this.validateDocumentIsNull();

      if(this.docValidations == true){
      await axios
      .post(`${this.baseurl}/dms/document/create`, formData, {
        headers: {
          Authorization: "Bearer " + this.idToken,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("retreieved data", response);
        this.documentId = response.data.data[0];
        this.documentVersionId = [];
        this.documentVersionId = response.data.data[1];
        this.CreateDocumentEIDSA();
      })
      .catch((err) => {
        console.log("error", err);
      });
      }
    },
    async CreateDocumentEIDSA(){
    const idtoken = store.getters.getIdToken;
    const storageCode = store.getters.getStorageCode;
    await axios
    .post(
      `${this.baseurl}/management/document/createdocument`,
      {
        documentReferenceId: this.documentId,
        documentTypeId: this.formsid,
        typeValue:storageCode,
        documentType: "form",
        documentName: this.azuredata.Document.name,
        documentDescription:this.azuredata.DocumentDescription,
        folderId: "",
        folderName: "",
        documentVersions: [
          {
            documentVersionId: this.documentVersionId,
                versionNumber: "1",
                versionDescription: "",
                status: "",
                fileName: "",
                uploadedBy: "",
                uploadedOn: "2022-07-06T02:45:17.946Z",
                uploadedUserId: "",
                approveList: []
              }
            ]
          },
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log("retreieve data", response);
      alert(this.$t("navigations.document created successfully"));
      window.location.reload();      
    })
    .catch((err) => {
      console.log("error", err);
      console.log(err.data);
    });
    },
    async validateDocumentIsNull() {
    if (this.azuredata.Document != "") {this.docValidations= true;}

    else {this.docValidations = false;}
    },
    async getDocuments(){
      const idtoken = store.getters.getIdToken;
    await axios
    .get(
      ` ${this.baseurl}/management/document/listdocument?documentTypeId=` +this.formsid ,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log(" get doc result", res.data);
      this.documentlist = res.data.results;
    })
    .catch((err) => {
      console.log("error..", err);
    });
    },
    async getDocumentById(docId) {
    this.currentdocId = docId;
    const idtoken = store.getters.getIdToken;
    await axios
      .get(
        `${this.baseurl}/management/document/getdocumentbyid?id=` + docId,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        this.Document = res.data;
        this.loaddata = true;
        this.documentName = this.Document.documentName;
      });
    },
    async deleteAzureDocument(documentId){
    console.log("docid is ", documentId);
    await this.getDocumentById(documentId);
    console.log("document is ", this.Document);
    if (
      confirm(
        this.$t('navigations.are you sure you want to delete this document?')
      )
    ) {
      await axios
        .delete(
          `${this.baseurl}/dms/document/deletedocument?id=${this.Document.documentReferenceId}`,
          this.headerValues
        )
        .then((response) => {
          console.log(response.data);
          alert(this.$t('navigations.document deleted successfully'));
          this.deleteDocument(documentId);
        })
        .catch((err) => {
          console.log("nope", err);
        });
    }
    },
    async deleteDocument(docId) {
    const idtoken = store.getters.getIdToken;
    console;
    await axios
      .delete(`${this.baseurl}/management/document/delete?Id=` + docId, {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
    },
    async downloadfile(docId) {
    const idtoken = store.getters.getIdToken;
    await this.getDocumentById(docId);
    await this.getdmsdocument(docId);
    const fileName =
      this.Document.documentVersions[
        this.Document.documentVersions.length - 1
      ].documentVersionId;
    var a = document.createElement("a");
    a.href = `${this.baseurl}/dms/document/file/${this.Document.documentReferenceId}?versionid=${fileName}`
    a.download = "FILENAME";
    a.click();
    },
    async viewDocument(docId) {
      // this.ShowDocName = false;
      await this.getDocumentById(docId);
      await this.getdmsdocument(docId);
      const versionId =
        this.Document.documentVersions[
          this.Document.documentVersions.length - 1
        ].documentVersionId;

        const filename =
        this.Document.documentVersions[
          this.Document.documentVersions.length - 1
        ].fileName;
      this.$router.push(
        `/documentview/${this.Document.documentReferenceId}/${filename}/${versionId}`
      );
    },
    async getdmsdocument() {
    console.log("dms document", this.Document);
    await axios
      .get(
        `${this.baseurl}/dms/document/getallfiles/${this.Document.documentReferenceId}`,
        {
          headers: {
            Authorization: "Bearer " + this.idToken,
            "Content-Type": "application/json",
            responseType: "blob",
          },
        }
      )
      .then((res) => {
        this.documentById = res.data;
        console.log("Docdownld............", this.documentById);
      })
      .catch((err) => {
        console.log(err);
      });
    },
    // End of  Form Document


    async getPageName(){
      const idtoken = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/forms/forms/getallpages?FormId=${this.formsid}&api-version=1`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        this.pageName=res.data
     })
     .catch((err) => {
       console.log(err);
     });
    },
    async getPages(){
      console.log("in getPage function.")
      this.pages=[];
      this.htmlarray[0].pages.forEach(page => {
        console.log("page is ", page.pageName);
         var x = { 
          pageName : page.pageName,
          pageNumber : page.pageNo
        };
        this.pages.push(x);
      });
    console.log("Page names are",this.pages);
    },
    async getFormHeader(){
      const idtoken = store.getters.getIdToken;
      console.log("formid in getformheadder fun is " + this.formsid);
      await axios.get(`${this.baseurl}/forms/forms/getformname?formID=${this.formsid}&api-version=1`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        this.formHeader=res.data
        console.log("Form header is",this.formHeader);
     })
     .catch((err) => {
       console.log(err);
     });
    },
    async patientId(patientId){
      const idtoken = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/management/patient/getpatientbyid/${patientId}?api-version=1`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        this.patientsId=res.data.subjectId
        console.log("Patient is",this.patientsId);
     })
     .catch((err) => {
       console.log(err);
     });
    },
    async siteId(siteId){
      const idtoken = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/management/site/getbyid?id=${siteId}&api-version=1`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        this.sitesId=res.data.siteCode
     })
     .catch((err) => {
       console.log(err);
     });
    },
    async verifyform(){
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      console.log(`formId: ${this.formsid},
      verifiedBy: ${usermail},
      verifiedAt: ${new Date()}`);
      await axios
      .put(
        `${this.baseurl}/forms/forms/verifyformstatus`,
          {
            "formId": this.formsid,
            "verifiedBy": usermail,
            "verifiedAt": new Date()
          },
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
      .then((res) => {
         console.log(res);
         alert(this.$t('navigations.form verified successfully'));
         this.receivedData[0].formStatus = 'Verified';
         this.forms[0].verifiedBy=usermail
         this.forms[0].verifiedAt=new Date()
         this.isVerified='Verified'
      })
      .catch((err) => {
        console.log(err);
      });
    },
    async setformid(){
      this.isPatientValue=this.isPatientVisit
      this.formsid = this.formsesid.trim();
      await this.getdata();
    },
    async testvarfun(field, value, pgn){
      if(this.isfieldvalidate){
        console.log(this.isfieldvalidate);
        this.openModal(field, value, pgn);
      }
    },
    gotoraiseQuary(queryType){
        this.xyz=this.formsid+"modelNameIs_"+this.modelname;
        this.$router.push(`/raiseQuery/${this.xyz}/${queryType}/${this.pageno}`);

    },
    getformtemplate: async function(tempid) {
      const idtoken = store.getters.getIdToken;
      console.log("templateid is "+ tempid);
      await axios
      .get(
        `${this.baseurl}/forms/template/get/${tempid}?api-version=1.0`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
      .then((res) => {
        this.htmlarray[0] = res.data;
        this.bindstatus();
        this.getPages();
      })
      .catch((err) => {
        console.log(err);
      });
      this.bindData();
    },
    bindstatus() {
      console.log("recived data", this.receivedData[0].pages[0].field[1].status)
      console.log("html array is ", this.htmlarray[0].pages[0].field)
      this.receivedData[0].pages.forEach((eachpages) => {
        let index = this.receivedData[0].pages.indexOf(eachpages);
        var i = 0;
        eachpages.field.forEach((item) => {
          this.htmlarray[0].pages[index].field[i].privilege = item.privilage;
          // this.htmlarray[0].pages[index].field[i].privilege = "View";
          i++;
        });
      });
      console.log("HTML ARRAY ", this.htmlarray);
    },
    isRequired: function(value, compid) {
      if (value == "") {
        this.errormsg[compid] = "please enter a value";
        this.isvalid.push("req");
        this.errorclasslist[compid] = "form-control errorlist"
      } else {
        // this.isvalid.pop();
      }
    },
getdata: async function() {
  this.receivedData[0] = await showformService.getdatafromapi(this.formsid);
  console.log("recived data result is " , this.receivedData[0]);
  this.isVerified=this.receivedData[0].formStatus
  this.getformtemplate(this.receivedData[0].templateId);
  let mapformvariables = {
    "createdUserEmail": this.receivedData[0].createdUserEmail,
    "createdUserId": this.receivedData[0].createdUserId,
    "createdAt": this.receivedData[0].createdAt,
    "lastModifiedUserEmail": this.receivedData[0].lastModifiedUserEmail,
    "lastModifiedUserId": this.receivedData[0].lastModifiedUserId,
    "lastModifiedAt": this.receivedData[0].lastModifiedAt
  };
  store.dispatch("setmapform", mapformvariables);
},
    isnumber: function(value, compid) {
      if (isNaN(value) || value == "") {
        this.errormsg[compid] = "Please enter a number";
        this.isvalid.push("numb");
      } else {
        // this.isvalid.pop();
      }
    },
    isalphaneumeric: function(value, compid) {
      if (value.match(/^[0-9a-z]+$/)) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Please enter Alphanumeric";
        this.isvalid.push("alph");
      }
    },
    lengthrange: function(value, compid, minleng, maxleng) {
      if (value.length >= minleng && value.length <= maxleng) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Number of characters should be between " + minleng + " and " + maxleng ;
        this.isvalid.push("minlength");
      }
    },
    valuerange: function(value, compid, minval, maxval) {
      if(/^\d+$/.test(value)){
      //let intval = Integer.parseInt(value);
      let intval = parseInt(value);
      if (intval <= maxval && intval >= minval) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Please enter a value between " + minval + " and "+ maxval;
        this.isvalid.push("maxval");
      }
    }
    else {
      this.errormsg[compid] = "Please enter a value between " + minval + " and "+ maxval;
      this.isvalid.push("maxval");
    }
    },
    isemail: function(value, compid) {
      if (value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Invalid email address!";
        this.isvalid.push("mail");
      }
    },
    bindData: function() {
      this.totalpages = this.htmlarray[0].pages.length;
      this.htmlarray[0].pages.forEach((item) => {
        this.groups[item.pageNo] = [];
        this.formdata[`pageNo${item.pageNo}`] = {};
        item.field.forEach((subitem) => {
          if(!this.groups[item.pageNo].includes(parseInt(subitem.groupOrderNo))){
              this.groups[item.pageNo].push(parseInt(subitem.groupOrderNo));
          }
              this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = null;
          });
      });
      this.bindValue();
    },
    bindValue: function() {
      this.receivedData[0].pages.forEach((item) => {
        item.field.forEach((subitem) => {
          if(subitem.type == 'date' && subitem.value){
            const dates = subitem.value.slice(0,4);
            if(dates.includes("-") || dates.includes("/")){
              if(subitem.value.slice(3,5).includes("-") || subitem.value.slice(3,5).includes("/") ){
                  console.log(` is it right ${subitem.value.slice(5,9)}-0${subitem.value.slice(3,4)}-${subitem.value.slice(0,2)}`);
                  this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = `${subitem.value.slice(5,9)}-0${subitem.value.slice(3,4)}-${subitem.value.slice(0,2)}`;
              }
              else{
            this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = `${subitem.value.slice(6,10)}-${subitem.value.slice(3,5)}-${subitem.value.slice(0,2)}`;
              }
          }
            else{
            this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.value;
            }
          }
          else{
            this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.value;
          }
        });
      });
    },
    validate: function() {
      this.htmlarray[0].pages.forEach((item) => {
        let pagenumber = `pageNo${item.pageNo}`;
        if (item.pageNo == this.pageno) {
            item.field.forEach((subitem) => {
              this.errorclasslist[subitem.id] = "form-control";
              if (subitem.validation != "") {
                subitem.validation.forEach((valid) => {
                  if (valid == "Required") {
                    this.isRequired(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName
                    );
                  }
                  if (valid == "Number" && this.formdata[pagenumber][subitem.fieldName] != "") {
                    this.isnumber(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName
                    );
                  }
                  if (valid == "Alphanumeric" && this.formdata[pagenumber][subitem.fieldName] != "") {
                    this.isalphaneumeric(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName
                    );
                  }
                  if (valid == "email" && this.formdata[pagenumber][subitem.fieldName] != "") {
                    this.isemail(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName
                    );
                  }
                  if (valid.includes("lengthrange") && this.formdata[pagenumber][subitem.fieldName] != ""){
                    let regExp = /\(([^)]+)\)/;
                    let matches = regExp.exec(valid);
                    const myArr = matches[1].split(",");
                    let minrange = parseInt(myArr[0]);
                    let maxrange = parseInt(myArr[1]);
                    this.lengthrange(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName,
                      minrange,
                      maxrange
                    );

                  }

                  if (valid.includes("valuerange") && this.formdata[pagenumber][subitem.fieldName] != ""){
                    console.log("inside valuerange functiuon");
                    let regExp = /\(([^)]+)\)/;
                    let matches = regExp.exec(valid);
                    const myArr = matches[1].split(",");
                    console.log("myArr is "+ myArr);
                    let minval = parseInt(myArr[0]);
                    let maxval = parseInt(myArr[1]);
                    this.valuerange(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName,
                      minval,
                      maxval
                    );

                  }
                });
              }
            });
        }
      });
    },
// save and edit starts

async updateFun() {
  if(this.signStatus == null || this.signStatus == ''){

  this.sndData.formId = this.receivedData[0].formId,
  this.sndData.dataSource = "Web Browser",
  this.sndData.sourceID = this.iPaddress,

  this.htmlarray[0].pages.forEach((eachpages) => {
  let createdpage = {};
  createdpage.pageName = eachpages.pageName;
  createdpage.pageNo = eachpages.pageNo;
  createdpage.pageStatus = eachpages.status;
  createdpage.field = [];
  eachpages.field.forEach((subitem) => {
      let val;
        val = (this.formdata[`pageNo${eachpages.pageNo}`][subitem.fieldName] != null) ? (this.formdata[`pageNo${eachpages.pageNo}`][subitem.fieldName].toString()) : null;
      createdpage.field.push({
        fieldName: subitem.fieldName,
        label: subitem.label,
        type: subitem.type,
        value: val,
        status: "Not validated",
        validation: subitem.validation,
        groupOrderNo: subitem.groupOrderNo,
        validatedBy: "",
        validatedAt: "",
        comment: "",
        required: subitem.required,
        options: subitem.options,
        imagePath: subitem.imagePath,
      });
    });
    this.sndData.pages.push(createdpage);
});

  await showformService.updateform(this.sndData);
  this.pageno++;
  await this.resetdata();
}
else{
  this.pageno++;
}
},
async savefun(){  
  await this.updateFun();
  alert("Form saved successfully");
  this.reviewAndSave();
  this.$emit('saveClicked')
},
async editFun(){
  const pgn = this.pageno - 1;
  let createdpage = {};
  createdpage.formId = this.formsid;
  createdpage.isArchived = true;
  createdpage.sourceID = this.iPaddress;
  createdpage.dataSource = "Web Browser";
  createdpage.pages = [];
  createdpage.pages[0] = {};
  createdpage.pages[0].pageName = this.htmlarray[0].pages[pgn].pageName;
  createdpage.pages[0].pageNo = this.htmlarray[0].pages[pgn].pageNo;
  createdpage.pages[0].pageStatus = this.htmlarray[0].pages[pgn].status;
  createdpage.pages[0].field = [];
  this.htmlarray[0].pages[pgn].field.forEach((subitem) => {
    let val;
    if (subitem.type == 'checkbox') {
      val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName].toString();
    }
    else {
      val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
    }
    createdpage.pages[0].field.push({
      fieldName: subitem.fieldName,
      label: subitem.label,
      type: subitem.type,
      value: val,
      status: "Not validated",
      validation: subitem.validation,
      groupOrderNo: subitem.groupOrderNo,
      validatedBy: "",
      validatedAt: "",
      comment: "",
      required: subitem.required,
      options: subitem.options,
      imagePath: subitem.imagePath,
      audit: [
        {
          oldValue: "",
          newValue: "",
          validatedBy: "",
          validatedDate: "2021-08-26T03:39:38.382Z",
          validatedTime: "2021-08-26T03:39:38.382Z",
          status: true,
          comment: "",
        },
      ],
    });
  });
  // console.log(JSON.stringify(createdpage));
  showformService.editdata(createdpage);
  this.pageno++;
},
async reviewAndSave(){
  const idtoken = store.getters.getIdToken;
  await axios.put(`${this.baseurl}/forms/forms/isreviewstatus`, { "formId": this.receivedData[0].formId},
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => console.log("review status changed", res))
      .catch((err) => console.log("error in review status change" , err));
        await this.updateFun();
      },
async resetdata(){
  this.sndData = {
    "formId": "string",
    "pages": [],
    "dataSource":"string",
    "sourceID":"string"
  }
},

// save and edit ends

async editedenevt(){
 alert(changed);
},
    onpagechange: function (pageNumber){
      console.log(pageNumber,"page number is ", this.pageno);
      console.log("Selected value is",this.pageNumber)
      console.log("Page no is",this.pageno)
      this.pageno = pageNumber;
    },
    async onpagesave(htmlarray){
      this.htmlarray[0].pages.forEach((item) => {
    
      console.log("HTML array value is",htmlarray)
      this.validate();
      //console.log("HTML array value ERROR is",this.isvalid)
  
      
      this.htmlarray[0].pages.forEach((item) => {
       // let pagenumber = `pageNo${item.pageNo}`;
        if (item.pageNo == this.pageno) {
            item.field.forEach((subitem) => {
          
            if(subitem.fieldName.includes("header"))
            {}else{
              this.pagesavefieldnames.push(subitem.fieldName);
            }
              
            });
            let showalert=false;
            if(this.isvalid.length > 0)
            {
              showalert=true;
              console.log("Show confirm alert on page save")
            }
            this.pagesave(showalert);
          }
        });

      });

     
    },
    async pagesave(showalert){
      if(showalert == true)
      {
        alert("Some of the fields in the page contains invalid data. Validation Failed")
        //if(confirm("Some of the fields in the page contains invalid data. Are you sure you want to validate this page?")){
        //  await this.pagesaveapicall();
        //}
       
      }
      else{
        await this.pagesaveapicall();
      }
    },
    async pagesaveapicall(){
   
    console.log(this.pageno);
    console.log(this.formsid);
    console.log("3rd",this.pagesavefieldnames);
    let data = {
      "formId": this.formsid,
      "pageno": this.pageno,
      "dataSource": "Web Browser",
      "sourceID": this.iPaddress,
      "fieldnames": this.pagesavefieldnames
        
    }

    const idtoken = store.getters.getIdToken;
    await axios
    .put(`${this.baseurl}/forms/forms/updatefieldsfrompagesave`, data ,
    //.put(`https://localhost:5002/forms/updatefieldsfrompagesave`, data ,
    {
      headers: {
        Authorization: "Bearer " + idtoken,
        "Content-Type": "application/json",
      }
    })
        .then((res) => {
          alert(this.$t('navigations.page validated successfully'));
        })
        .catch((err) => console.log(err));
       
    },

    async alertfun(){
      console.log("hello test");
    },
    previousFun: function() {
      if (this.pageno > 1) {
        console.log("previous page");
        this.pageno--;
      }
    },
    incrementcount: function() {
      this.totalpages++;
      console.log("increment" + this.totalpages);
    },
  },
  watch:{
    pageno(newpageno, oldpageno)
    {
      if (newpageno>=1) {
        window.scrollTo(0,0);
      }
    }
  }
};
