/* eslint-disable */
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
import jwt_decode from "jwt-decode";
import store from "../../store/index";
import auth0 from "auth0-js";
import VueApexCharts from 'vue3-apexcharts';
import { ref, computed, onBeforeMount, onMounted } from "vue";
import { Loader } from '@googlemaps/js-api-loader';

export default {
    name: "inventoryTracking",
    components: {
        apexchart: VueApexCharts,
    },
    setup() {
        const router = useRouter();
        const trackingNumber = ref('');
        const trackingResult = ref(null);
        const activeShipment = ref(null);
        const mapContainer = ref(null);
        const map = ref(null);
        const isConnected = ref(false);
        const connectionError = ref("");
        const isLoading = ref(false);
        const formData = ref({
            url: "",
            username: "",
            password: "",
            authType: "OAuth 2.0"
        });

        onMounted(() => {
            const loader = new Loader({
              apiKey: 'YOUR_ACTUAL_GOOGLE_MAPS_API_KEY', // Get from Google Cloud Console
              version: 'weekly',
              libraries: ['places']
            });
          
            loader.load().then(() => {
              map.value = new google.maps.Map(mapContainer.value, {
                center: { lat: 51.5074, lng: -0.1278 },
                zoom: 12
              });
            }).catch(error => {
              console.error('Google Maps failed to load:', error);
            });
          });

        // Main Stock Levels Chart
    const mainStockChartOptions = {
        chart: {
          type: 'bar',
          toolbar: { show: false }
        },
        plotOptions: {
          bar: {
            distributed: true,
            dataLabels: { position: 'top' }
          }
        },
        xaxis: {
          categories: [
            'Investigational Product X',
            'Placebo Comparator',
            'Blood Collection Kits',
            'Temperature Loggers',
            'Patient Diary Kits',
            'Emergency Unblinding Kits'
          ]
        },
        colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'],
        dataLabels: {
          formatter: function(val) {
            return val + " units";
          }
        }
      };
      const mainStockSeries = [{
        name: "Current Stock",
        data: [245, 180, 89, 156, 65, 342]
      }];
      
       // Critical Items Radial Chart
    const criticalItemsChartOptions = {
        chart: {
          type: 'radialBar',
          height: 350
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: { fontSize: '22px' },
              value: { fontSize: '16px' },
              total: {
                show: true,
                label: 'Critical Items',
                formatter: function(w) {
                  return '3/8'
                }
              }
            }
          }
        },
        labels: ['IMP Stock', 'Placebo', 'Unblinding Kits'],
      };
  
      const criticalItemsSeries = [67, 84, 45];
        // Integration and Mapping Configurations
        const integration = ref({
            system: 'SAP EWM',
            apiUrl: '',
            authType: 'OAuth 2.0',
            apiKey: '',
            username: '',
            password: ''
        });
        
        const mapping = ref({
            ndcNumber: 'product_ndc',
            protocolId: 'protocol_number',
            siteId: 'clinical_site_code',
            kitCount: 'randomization_kit_count',
            batchNumber: 'manufacturing_batch',
            expiryDate: 'expiration_date'
          });
            const validateNDC = (ndc) => /^\d{5}-\d{4}-\d{2}$/.test(ndc);
            const validateProtocolID = (id) => /^PRO-\d{4}-\d{3}$/.test(id);
            const validateSiteID = (id) => /^SITE-[A-Z]{2}-\d{3}$/.test(id);
          
          // Example test data
          const testData = {
            ndcNumber: '12345-6789-01',
            protocolId: 'PRO-2301-001',
            siteId: 'SITE-US-001',
            kitCount: 'KIT-2301-100',
            batchNumber: 'BATCH-2301A',
            expiryDate: '2024-06-30'
          };

        const movementHistory = ref([
            {
              type: 'Shipment Received',
              product: 'Investigational Product X',
              quantity: 500,
              batch: 'IPX-2301',
              location: 'Central Depot → Site 101',
              timestamp: '2023-10-05T14:30:00Z',
              temperatureLog: '2-8°C maintained',
              protocolVersion: 'v2.1'
            },
            {
              type: 'Patient Dispensing',
              product: 'Blinded Study Drug',
              quantity: -30,
              batch: 'BSD-2309',
              location: 'Site 101 → Patient 0452',
              timestamp: '2023-10-04T09:15:00Z',
              patientID: 'PT-0452',
              visit: 'Week 12'
            },
            {
              type: 'Return/Destruction',
              product: 'Expired Lab Kits',
              quantity: -45,
              batch: 'LK-2203',
              location: 'Site 101 → Destruction Facility',
              timestamp: '2023-10-03T16:20:00Z',
              reason: 'Expired 2023-09-30',
              destructionCert: 'DEC-23003'
            },
            {
              type: 'Emergency Resupply',
              product: 'Biopsy Kits',
              quantity: 100,
              batch: 'BK-2308',
              location: 'Central Depot → Site 203',
              timestamp: '2023-10-02T07:45:00Z',
              reason: 'Site stockout',
              temperatureAlert: '1h >8°C during transit'
            }
          ]);
          const clinicalInventory = ref([
            {
              material: 'Investigational Product X',
              batch: 'IPX-2301',
              quantity: 245,
              expiry: '2024-06-30',
              storage: '2-8°C Refrigerated',
              irbApproved: true
            },
            {
              material: 'Placebo Comparator',
              batch: 'PLC-2302',
              quantity: 180,
              expiry: '2024-07-15',
              storage: 'Ambient',
              irbApproved: true
            },
            {
              material: 'Emergency Unblinding Kits',
              batch: 'EUK-2305',
              quantity: 12,
              expiry: '2025-01-01',
              storage: 'Secured Cabinet',
              irbApproved: false
            }
          ]);
          const formatKey = (key) => {
            return key
              .replace(/([A-Z])/g, ' $1')
              .replace(/_/g, ' ')
              .replace(/^./, str => str.toUpperCase());
          };

        const autoSync = ref(true);
        const lastSync = ref('2023-10-05 14:30:00');

        // Sync Logs
        const syncLogs = ref([
            {
                timestamp: '2023-10-05 14:30:00',
                type: 'Full Sync',
                status: 'Success',
                statusClass: 'bg-success',
                details: '1,240 items synchronized'
            },
            {
                timestamp: '2023-10-05 12:15:00',
                type: 'Stock Update',
                status: 'Failed',
                statusClass: 'bg-danger',
                details: 'API timeout error'
            }
        ]);

        const errorMessage = ref(null);

        // Chart configurations
        const transactionOptions = ref({
            chart: { type: 'line', height: 350, zoom: { enabled: false } },
            dataLabels: { enabled: false },
            stroke: { curve: 'smooth', width: 2 },
            colors: ['#1a73e8'],
            xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'] },
            title: { text: 'Monthly Transactions', align: 'left' }
        });

        const transactionSeries = ref([{ name: "Transactions", data: [65, 79, 120, 81, 156, 155] }]);

        const inventoryOptions = ref({
            chart: { type: 'bar', height: 350 },
            plotOptions: { bar: { horizontal: false, columnWidth: '55%' } },
            dataLabels: { enabled: false },
            colors: ['#1a73e8'],
            xaxis: { categories: ['Drug A', 'Drug B', 'Drug C', 'Drug D'] },
            title: { text: 'Current Stock Levels', align: 'left' }
        });

        const inventorySeries = ref([{ name: "Stock", data: [65, 59, 80, 41] }]);

        const chartOptions = ref({
            chart: { type: 'bar' },
            xaxis: {
                categories: [
                'Investigational Product X',
                'Placebo Comparator',
                'Lab Kits (Blood Collection)',
                'Temperature Loggers',
                'Biopsy Kits',
                'Patient Diary Kits'
                ],
                title: {
                text: 'Clinical Trial Materials'
                }
            },
            yaxis: {
                title: {
                text: 'Units in Stock'
                }
            },
            colors: ['#1a73e8'],
            plotOptions: {
                bar: {
                dataLabels: {
                    position: 'top'
                }
                }
            }
            });

            const chartData = ref([{
            name: 'Current Stock',
            data: [245, 180, 89, 156, 65, 342]
            }]);


        let valnow = ref("");
        const profileBlock = ref(false);
        let baseurl = ref(`${process.env.VUE_APP_Service_URL}`);
        let currentTheme = ref(localStorage.getItem("theme-color"));
        let webAuth = new auth0.WebAuth({
            domain: "dev-datamatica.eu.auth0.com",
            clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
        });
        const loader = new Loader({
            apiKey: 'YOUR_GOOGLE_MAPS_API_KEY',
            version: 'weekly',
        });

        loader.load().then(() => {
            new google.maps.Map(mapContainer.value, {
                center: { lat: 51.5074, lng: -0.1278 },
                zoom: 12,
            });
        });

        const trackShipment = async () => {
            try {
              if (!trackingNumber.value) {
                throw new Error('Please enter a tracking number');
              }
              
              const response = await axios.get(
                `https://6624f2c53e17a3ac846e3b2d.mockapi.io/shipments/${trackingNumber.value}`
              );
              
              if (response.data.error) {
                throw new Error(response.data.error);
              }
          
              trackingResult.value = response.data;
              activeShipment.value = response.data;
              errorMessage.value = null;
          
            } catch (error) {
              console.error('Tracking error:', error);
              errorMessage.value = error.message;
              trackingResult.value = null;
              activeShipment.value = null;
            }
          };
        const statusClass = (status) => ({
            'In Transit': 'bg-warning',
            'Delivered': 'bg-success',
            'Delayed': 'bg-danger',
            'Processing': 'bg-info'
        }[status] || 'bg-secondary');

        const formatDate = (dateString) => new Date(dateString).toLocaleDateString();
        const formatDateTime = (dateString) => new Date(dateString).toLocaleString();

        onBeforeMount(async () => {
            store.dispatch("setNavbar", false);
            document.getElementById("page-wrapper").style.width = "100%";
            if (currentTheme.value == null) {
                localStorage.setItem("theme-color", "theme-default");
                currentTheme.value = localStorage.getItem("theme-color");
            }
        });

        router.beforeEach((to, from, next) => {
            if (to.name != 'profileSettings') {
                store.dispatch("setNavbar", true);
                document.getElementById("page-wrapper").style.removeProperty("width");
            } else {
                document.getElementById("page-wrapper").style.width = "100%";
            }
            next();
        });

        let usersdetails = computed(() => {
            valnow.value = store.getters.getStudyIs;
            return jwt_decode(store.getters.getIdToken);
        });

        async function connectToSAP() {
            try {
                isLoading.value = true;
                connectionError.value = "";
                
                if (formData.value.url && formData.value.username && formData.value.password) {
                    isConnected.value = true;
                    store.dispatch("setSAPConnection", true);
                } else {
                    throw new Error("Please fill all required fields");
                }
            } catch (error) {
                console.error("Connection failed:", error);
                connectionError.value = error.message || "Connection failed. Please check credentials.";
                isConnected.value = false;
            } finally {
                isLoading.value = false;
            }
        }

        function displayProfile() {
            const profile = document.querySelector(".profile-block");
            const userProfile = document.querySelector(".tool-tip");
            profile.style.display = profile.style.display === "block" ? "none" : "block";
            userProfile.style.display = userProfile.style.display === "block" ? "none" : "block";
        }

        async function logout() {
            await logoutaudit();
            localStorage.clear();
            webAuth.logout({
                returnTo: `${process.env.VUE_APP_logout_URL}`,
                clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
            });
        }

        async function logoutaudit() {
            const token = store.getters.getIdToken;
            const emailId = jwt_decode(token).email;
            await axios.post(`${baseurl.value}/account-core/user/saveloginaudit`, {
                userEmail: emailId,
                logdetails: [{
                    action: "Log Out",
                    date: new Date().toISOString(),
                    time: new Date().toISOString(),
                }],
            }, {
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json",
                },
            }).catch(console.log);
        }

        function setstatus(screen) {
            store.dispatch("setCollapse", false);
            const validScreens = ['AI/Ml', 'Analytical', 'Clinical', 'Stats'];
            if (validScreens.includes(screen)) {
                store.dispatch("setDefaultMenu", screen);
            } else {
                console.warn("Invalid screen type:", screen);
            }
        }

        function expandSidebar() {
            let x = document.getElementsByClassName("theme-menu")[0];
            x.style.display = x.style.display === "block" ? "none" : "block";
        }

        function switchTheme(theme) {
            localStorage.setItem("theme-color", theme);
            currentTheme.value = localStorage.getItem("theme-color");
        }

        return {
            trackingNumber,
            trackingResult,
            activeShipment,
            mapContainer,
            trackShipment,
            statusClass,
            formatDate,
            formatDateTime,
            usersdetails,
            displayProfile,
            valnow,
            profileBlock,
            logout,
            setstatus,
            switchTheme,
            expandSidebar,
            isConnected,
            connectionError,
            isLoading,
            formData,
            connectToSAP,
            transactionOptions,
            transactionSeries,
            inventoryOptions,
            inventorySeries,
            integration,
            mapping,
            autoSync,
            lastSync,
            syncLogs,
            clinicalInventory,
            movementHistory,
            errorMessage,
            chartOptions,
            chartData,
        };
    }
};
