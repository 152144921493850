<template>
  <div>
    <!-- <h2 class="text-xl font-bold mb-4">File Difference Viewer</h2> -->
    <div ref="diffContainer" class="diff-container"></div>
  </div>
</template>

<script>
import * as Diff2Html from 'diff2html';
import 'diff2html/bundles/css/diff2html.min.css';

import { createPatch } from 'diff'; 
console.log("Diff2Html:", Diff2Html);


export default {
  name: 'diffcommitviewer',
  props: {
    oldContent: {
      type: String,
      required: true
    },
    newContent: {
      type: String,
      required: true
    },
    fileName: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.renderDiff();
  },
  methods: {
    renderDiff() {
      try{
        const cleanOld = this.oldContent.replace(/^\uFEFF/, '');
        const cleanNew = this.newContent.replace(/^\uFEFF/, '');
        // Generate unified diff format from old and new content
        const diffText = createPatch(this.fileName, cleanOld, cleanNew); 
        const html = Diff2Html.html(diffText, { drawFileList: false, matching: 'lines', outputFormat: 'side-by-side', fileContentToggle: true });
        console.log("html data is",html);
        this.$refs.diffContainer.innerHTML = html;
      
      }
      catch(e){
        console.error("Error rendering diff:",e);
      }
    },
   
  }
};
</script>

<style scoped>
.diff-container {
  font-family: monospace;
}


</style>
