<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ "Data Standards" }}</h2>
    </div>
  </div>

  <div>
    <div class="wrapper wrapper-content">
      <div class="ibox-content mb-1 px-3 py-2">
        <div class="form-group row mb-1">
          <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
            <span
              type="button"
              class="col-sm-12 col-lg-2 save_btn my-1 my-lg-0 mx-lg-1 align-self-end"
              @click="opensdtmModal()"
            >
              Create SDTM
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div class="wrapper wrapper-content">
      <div class="ibox-content mb-1 px-3 py-2">
        <div class="form-group row mb-1">
          <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
            <div class="form-group mb-lg-0 w-100 px-1 pl-lg-0">
              <label class="mb-0">{{ $t("navigations.from") }}</label>
              <input
                type="date"
                class="form-control"
                v-model="search.fromDate"
              />
            </div>
            <div class="form-group mb-lg-0 w-100 px-1 pl-lg-0">
              <label class="mb-0"> {{ $t("navigations.to") }}</label>
              <input type="date" class="form-control" v-model="search.toDate" />
            </div>
            <span
              type="button"
              class="col-sm-12 col-lg-2 save_btn my-1 my-lg-0 mx-lg-1 align-self-end"
              @click="SearchDate()"
            >
              {{ $t("navigations.search") }}
            </span>
          </div>
        </div>
      </div>

      <div class="ibox-body">
        <div class="ibox-title style_2 d-flex justify-content-between">
          <h5 class="mt-1">{{ "Audit List" }}</h5>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th>
                    <div class="d-flex flex-row justify-content-between">
                      {{ "Interim Version" }}
                    </div>
                  </th>
                  <th
                    class="sort_block"
                    @click="SortSelected('dateCutoff', 4)"
                    id="4"
                  >
                    <div class="d-flex flex-row justify-content-between">
                      {{ "Cut-off Date" }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </div>
                  </th>
                  <th>
                    <div class="d-flex flex-row justify-content-between">
                      {{ "Comments" }}
                    </div>
                  </th>
                  <th class="text-center">SDTM</th>
                  <th class="text-center">Convert to ADAM</th>
                  <th class="text-center">ADAM</th>
                  <th class="text-center">Add to Dashboard</th>
                  <!-- <th class="text-center" >Import to Data Analysis</th> -->
                  <th class="text-center">Audit</th>
                  <th class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(audit, index) in auditList"
                  :key="audit.id"
                  :value="audit.id"
                >
                  <td>{{ audit.interimVersion }}</td>
                  <td>{{ filter(audit.dateCutoff) }}</td>
                  <td>{{ audit.comments }}</td>
                  <td class="text-center">{{ audit.sdtM_Status }}</td>

                  <td class="text-center">
                    <i
                      v-if="
                        audit.sdtM_Status === 'Success' &&
                          audit.adaM_Status !== 'Success'
                      "
                      class="fa fa-arrow-circle-right position-relative cursor-pointer icon-hover"
                      @click="ConvertToAdam(audit)"
                    ></i>
                  </td>
                  <td class="text-center">{{ audit.adaM_Status }}</td>
                  <td class="text-center">
                    <i
                      v-if="audit.importSdtmStatus != 'Imported'"
                      class="fa fa-arrow-circle-down position-relative cursor-pointer icon-hover"
                      @click="ImportToSDTM(audit)"
                    ></i>
                  </td>
                  <!-- <td class="text-center" >
                    <i v-if="audit.adaM_Status == 'Conversion successful'"
                      class="fa fa-arrow-circle-down position-relative cursor-pointer icon-hover"
                      @click="ImportToDataAnalysis(audit)"
                    ></i>
                  </td> -->
                  <td class="text-center">
                    <i
                      class="fa fa-eye edit-delete"
                      @click="openModelaudit(audit)"
                    ></i>
                  </td>

                  <td
                    class="text-center"
                    v-on:clickout="showSidePopup[index] = false"
                  >
                    <div
                      class="position-relative cursor-pointer"
                      @click.prevent="
                        showSidePopup[index] = !showSidePopup[index]
                      "
                    >
                      <i class="fa fa-ellipsis-v"></i>
                    </div>

                    <div class="plan-side-popup" v-if="showSidePopup[index]">
                      <div
                        @click.prevent="
                          downloadFile(
                            'json',
                            audit.sdtmFilePath,
                            audit.sdtmFilePath
                          )
                        "
                      >
                        {{ "Download SDTM as JSON" }}
                      </div>
                      <div
                        @click.prevent="
                          downloadFile(
                            'csv',
                            audit.sdtmFilePath,
                            audit.sdtmFilePath
                          )
                        "
                      >
                        {{ "Download SDTM as CSV" }}
                      </div>
                      <div @click.prevent="downloadADam(audit)" v-if=" audit.adaM_Status=='Success'">
                        {{ "Download ADaM" }}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!------ pagination -------->
            <div class="row mx-0">
              <div class="col-lg-12 mx-0 px-0">
                <div
                  class="dataTables_paginate paging_simple_numbers pagination float-right"
                >
                  <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                    <v-pagination
                      v-model="currentPage"
                      :pages="totalItems"
                      :range-size="1"
                      active-color="#618bd7"
                      @update:modelValue="loadPage"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!---Pagination ends-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Audit trail details modal starts -->
  <div v-if="auditpopup == true" class="audit__details__modal__wrapper">
    <div class="audit__details__modal__container">
      <div
        class="audit__details__modal__title d-flex justify-content-between py-2 px-3"
      >
        <h4 class="my-0">{{ $t("navigations.audit details") }}</h4>
        <div class="cursor-pointer">
          <i class="fa fa-times" @click.prevent="closemodallll()"></i>
        </div>
      </div>
      <!-- Popup -->
      <div
        class="audit__details__modal__content p-3"
        :class="{ heightBig: changeHeight }"
      >
        <div class="list-group">
          <div class="list-group-item">
            <strong>SDTM Created By:</strong> {{ auditData.exportedBy }}
          </div>
          <div class="list-group-item">
            <strong>SDTM Created At:</strong> {{ filter(auditData.exportedDate) }}
          </div>
          <div class="list-group-item">
            <strong>SDTM Conversion Status:</strong> {{ auditData.sdtM_Status }}
          </div>
          <div class="list-group-item">
            <strong>ADaM Created At:</strong> {{ filter(auditData.adaM_CreatedAt) }}
          </div>
          <div class="list-group-item">
            <strong>ADaM Created By:</strong> {{ auditData.adaM_CreateBy }}
          </div>
          <div class="list-group-item">
            <strong>ADaM Conversion Status:</strong> {{ auditData.adaM_Status }}
          </div>
          <div class="list-group-item">
            <strong>SDTM Imported Status:</strong>
            {{ auditData.importSdtmStatus }}
          </div>
          <div class="list-group-item">
            <strong>SDTM Imported By:</strong> {{ auditData.importedBy }}
          </div>
          <div class="list-group-item">
            <strong>SDTM Imported At:</strong> <template v-if="auditData.importedDate!=null && auditData.importedDate!='Invalid Date'">{{ filter(auditData.importedDate) }}</template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- popup for adam mapper -->
  <customModal
    v-if="openMapperModal == true"
    :containersm="true"
    @closeModal="adamclosemodal()"
  >
    <template #title>
      {{ $t("navigations.select mapper") }}
    </template>
    <template #body>
      <div class="row justify-content-center">
        <div class="col-8">
          <label class="fs-7"
            >{{ $t("navigations.select adam file")
            }}<sup><i class="fa fa-asterisk required"></i></sup
          ></label>
          <select
            class="form-select py-1"
            id="adamFile"
            name="adamFile"
            v-model="fileId"
          >
            <option v-for="file in files" :key="file._id" :value="file._id">
              {{ file.ADaMFileType }}
            </option>
          </select>
          
        </div>
        <div class="col-3 align-self-end px-0">
          <button
            type="button"
            class="save_btn w-100 mr-0 py-1 position-relative"
            :disabled="showLoading == true"
            @click="GenerateAdam(fileId)"
            :class="{ 'disabled-btn-cls': showLoading == true }"
          >
            <div
              class="toc-loader position-absolute"
              :class="{
                'opacity-0': showLoading == false,
                'opacity-1': showLoading == true,
              }"
            >
              <img
                src="../../assets/img/loading.gif"
                width="15"
                class="loading-btn-img"
                alt="Waiting for login"
              />
            </div>
            <div
              :class="{
                'opacity-1': showLoading == false,
                'opacity-0': showLoading == true,
              }"
            >
              {{ $t("navigations.generate") }}
            </div>
          </button>
        </div>        
        <div class="col-8"> <div class="errorClass" v-if="errorMapper[0]==true">{{ $t('navigations.please select a mapper') }}</div></div>
        <div class="col-3"  v-if="errorMapper[0]==true"></div>
      </div>
    </template>
  </customModal>
  <!--  popup -->

  <!-- sdtm create popup -->
  <customModal
    v-if="openSDTmModal == true"
    :containersm="true"
    @closeModal="sdtmclosemodal()"
  >
    <template #title>
      Interim Data Set
    </template>
    <template #body>
      <div class="row justify-content-center">
        <div class="col-lg-12 my-0">
          <label class="fs-7">
            Interim Version
            <sup><i class="fa fa-asterisk required"></i></sup>
          </label>
          <input
            type="text"
            class="form-control py-1"
            v-model="intrimData.sdtmVersion"
            @input="clearError('sdtmVersion')"
          />
          <span v-if="errors.sdtmVersion" class="text-danger">{{
            errors.sdtmVersion
          }}</span>
           <span v-if="errors.sdtmVersionDuplicate" class="text-danger">{{
            errors.sdtmVersionDuplicate
          }}</span>
        </div>

        <div class="col-lg-12 my-0">
          <label class="fs-7">
            Comment
          </label>
          <input
            type="text"
            class="form-control py-1"
            v-model="intrimData.SdtmComments"
          />
        </div>

        <div class="col-lg-12 my-0">
          <label class="fs-7">
            Date
            <sup><i class="fa fa-asterisk required"></i></sup>
          </label>
          <input
            type="date"
            class="form-control py-1"
            v-model="intrimData.selectedDate"
            @input="clearError('selectedDate')"
          />
          <span v-if="errors.selectedDate" class="text-danger">{{
            errors.selectedDate
          }}</span>
        </div>

        <div class="col-lg-12 mt-4 d-flex justify-content-center">
          <button
            type="button"
            class="save_btn py-1 px-4"
            :disabled="showLoading"
            @click="validateAndCreateSDTM()"
            :class="{ 'disabled-btn-cls': showLoading }"
          >
            <div
              class="toc-loader position-absolute"
              :class="{ 'opacity-0': !showLoading, 'opacity-1': showLoading }"
            >
              <img
                src="../../assets/img/loading.gif"
                width="15"
                class="loading-btn-img"
                alt="Loading..."
              />
            </div>
            <div
              :class="{ 'opacity-1': !showLoading, 'opacity-0': showLoading }"
            >
              {{ $t("navigations.create") }}
            </div>
          </button>
        </div>

        <div class="errorClass" v-if="validations[0]">
          {{ $t("navigations.please fill all fields") }}
        </div>
      </div>
    </template>
  </customModal>
</template>
<script src="./DataStandards.js"></script>

<style>
@import "../../assets/savepages.css";
.plan-side-popup {
  position: absolute;
  text-align: left;
  right: 56px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(150px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.plan-side-popup > div {
  cursor: pointer;
  padding: 0.5rem;
}

.plan-side-popup > div:hover {
  background-color: #e4eaf9;
}

.plan-side-popup > div:hover {
  background-color: #e4eaf9;
}

.icon-hover {
  font-size: 16px;
  transition: transform 0.2s ease;
}

.icon-hover:hover {
  transform: scale(1.1);
}
.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.disabled-btn-cls {
  opacity: 0.6;
  pointer-events: none;
}
</style>
