import axios from "axios";
import store from "../../store/index";
import { ref } from "vue";
import { useRouter } from "vue-router";
import auth0 from "auth0-js";
import jwt_decode from "jwt-decode";
import { useI18n } from "vue-i18n";
import { SUPPORT_LOCALES } from "@/i18n";
import { getCurrentInstance } from 'vue';
import { router } from "@/router";
var webAuth = new auth0.WebAuth({
  domain: "dev-datamatica.eu.auth0.com",
  clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
  scope: "openid profile email offline_access",
  responseType: "token id_token",
  useRefreshTokens: true,
});
export default {
  name: "profileSettings",
  setup() {
    let header = ref({});
    let profile = ref({});
    let validations = ref([
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
    let router = useRouter();
    let errors = ref([]);
    let currentpassword = ref("");
    let confirmpassword = ref("");
    let newpassword = ref("");
    const vm = getCurrentInstance().proxy; 

    async function setprofile(profiledta, header) {
      header.value = header;
      profile.value = profiledta;
      console.log("Profile data", profiledta);
    }
    async function executepassword(authResult) {
      if (profile.value.dateOfBirth === "") {
        profile.value.dateOfBirth = null;
      }
      const idtoken = authResult.idToken;

      await axios
        .put(
          //`https://localhost:5001/user/update`,
          `${process.env.VUE_APP_Service_URL}/account-core/user/` + "update",
          {
            ItemId: profile.value.id,
            userName: profile.value.userName,
            firstName: profile.value.firstName,
            lastName: profile.value.lastName,
            dateOfBirth: profile.value.dateOfBirth,
            active: profile.value.active,
            emailIdPrimary: profile.value.emailIdPrimary,
            emailIdSecondary: profile.value.emailIdSecondary,
            phoneNumber: profile.value.phoneNumber,
            addressLineOne: profile.value.addressLineOne,
            addressLineTwo: profile.value.addressLineTwo,
            country: profile.value.country,
            userType: profile.value.userType,
            role: profile.value.role,
            userId: profile.value.userId,
            password: newpassword.value,
            isVerified: true,
            isPersonalInfoVerified: true,
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            }
          },
        )
        .then((res) => {
          console.log(res);
          alert(vm.$t('navigations.password updated successfully'));
          router.go();
        })
        .catch((err) => {
          console.log(err);
          alert(vm.$t('navigations.this password has previously been used please use a new password'));
        });
    }
   
    async function login(useremail, currentpass, newpass, confirmedpass) {
      newpassword.value = newpass;
      confirmpassword.value = confirmedpass;
      currentpassword.value = currentpass;
      await webAuth.client.login(
        {
          realm: "Username-Password-Authentication",
          username: useremail,
          password: currentpass,
        },
        async function (err, authResult) {
          if (err) {
            console.log(err.description);
            if (
              err.description ==
              "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it."
            ) {
               alert(err.description);
            } else if (err.description === "Wrong email or password.") {
              alert(vm.$t('navigations.invalid current password'));
            }
          } else {
            console.log("correct password", authResult);
            await ChangePasswordClick(authResult);
          }
        }
      );
    }
    async function ChangePasswordClick(authResult) {
      await validateallpassword();
      if (validations.value.includes(true)) {
        errors.value = validations.value;
      } else {
        executepassword(authResult);
      }
    }
    async function validateallpassword() {
      Validatepassword(newpassword.value);
      Validateconfirmpassword(confirmpassword.value);
      Validatematchpasswords(newpassword.value, confirmpassword.value);
    }
    async function Validatepassword(passwd) {
      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if (passwd == "" || passwd == "" || passwd == null) {
        validations.value[6] = true;
        validations.value[8] = true;
      } else if (
        passwd.length < 8 ||
        !reLow.test(passwd) ||
        !reNum.test(passwd) ||
        !spcl.test(passwd) ||
        !reUpp.test(passwd)
      ) {
        validations.value[8] = true;
        validations.value[6] = false;
      } else {
        validations.value[6] = false;
        validations.value[8] = false;
      }
    }
    async function Validateconfirmpassword(passwd) {
      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if (passwd == "" || passwd == "" || passwd == null) {
        validations.value[7] = true;
        validations.value[9] = true;
      } else if (
        passwd.length < 8 ||
        !reLow.test(passwd) ||
        !reNum.test(passwd) ||
        !spcl.test(passwd) ||
        !reUpp.test(passwd)
      ) {
        validations.value[9] = true;
        validations.value[7] = false;
      } else {
        validations.value[7] = false;
        validations.value[9] = false;
      }
    }
    async function Validatematchpasswords(passwd, confirmpaswd) {
      if (
        validations.value[6] == false &&
        validations.value[8] == false &&
        validations.value[7] == false &&
        validations.value[9] == false
      ) {
        console.log("validate paswds");
        if (passwd == confirmpaswd) {
          validations.value[10] = false;
          console.log(" paswds ok");
        } else {
          validations.value[10] = true;
          console.log("paswds mismatch");
        }
      } else {
        console.log("final condtn paswds");
        validations.value[10] = false;
      }
    }
    return {
      login,
      ChangePasswordClick,
      validateallpassword,
      validations,
      Validatematchpasswords,
      Validateconfirmpassword,
      errors,
      currentpassword,
      confirmpassword,
      newpassword,
      setprofile,
      profile,
      header,
      executepassword,
    };
  },
  data() {
    return {
      defaultdate: "",
      languages: [
        { language: "English", code: "en" },
        { language: "Italian", code: "it" },
        { language: "Lithuanian", code: "lt" },
        { language: "Polish", code: "pl" },
        { language: "Portuguese (Portugal)", code: "pt-pt" },
        { language: "Romanian", code: "ro" },
        { language: "French", code: "fr" },
        { language: "German", code: "de" },
        { language: "Danish", code: "da" },
        { language: "Dutch", code: "nl" },
        { language: "Turkish", code: "tr" },
        { language: "Swedish", code: "sv" },
        { language: "Spanish", code: "es" },
        { language: "Hebrew", code: "he" },
        { language: "Czech", code: "cs" },
        { language: "Croatian", code: "hr" },
        { language: "Hungarian", code: "hu" },
      ],
      currentLocale: "",
      supportLocales: SUPPORT_LOCALES,
      baseapi: process.env.VUE_APP_Service_URL,
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/study/`,
      usersettingsBaseUrl: `${process.env.VUE_APP_Service_URL}/account-core/usersettings/`,
      baseurl: process.env.VUE_APP_Service_URL,
      baseAPI: `${process.env.VUE_APP_Service_URL}/account-core/user/`,
      studyList: [],
      currentStudy: "",
      Headers: "",
      idToken: "",
      isNew: false,
      userId: "",
      defaultdateFormat: "",
      defaultTimeFormat: "",
      defaultlanguage: "",
      defaultGraphType: "",
      langList: "",
      dateList: "",
      collapsed: false,
      isUpdate: false,
      signStatus: "",
      ProfileData: {
        id: "",
        userName: "",
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        active: true,
        emailIdPrimary: "",
        emailIdSecondary: "",
        phoneNumber: "",
        addressLineOne: "",
        addressLineTwo: "",
        country: "",
        userId: "",
        password: "",
        userType: "",
        role: "",
      },
      countries: [],
      changepassword: {
        newpassword: "",
        confirmpassword: "",
        currentpassword: "",
      },
      isCurrentPasswordVisible: false, // Track current password visibility
      isNewPasswordVisible: false,     // Track new password visibility
      isConfirmPasswordVisible: false  ,
      isPasswordVisible:false,
      isOlPasswordVisible:false,
      confirmPassword: "",
      confirmSignPassword: "",
      currentvalidation: [false],
      showBlock: [true, false, false, false],
      signErrors: [false, false, false],
      errors: [false],
      showPopup: false,
      showCloseButton:false,
      signPassword: "",
      errorMessages: [false, false, false],
      value: {
        createdUserEmail: "vipinv@datamatica.uk",
        createdUserId: "auth0|61f28ddadaa5cc006ace40ba",
        createdAt: "2021-10-27T09:30:08.205Z",
        lastModifiedUserEmail: "vipinv@datamatica.uk",
        lastModifiedUserId: "auth0|61f28ddadaa5cc006ace40ba",
        lastModifiedAt: "2022-02-02T03:18:19.026Z",
        userID: "auth0|61f28ddadaa5cc006ace40ba",
        userEmail: "vipinv@datamatica.uk",
        studyID: "ee36418d-c121-4fd7-b332-ffccc6b690ff",
        createdDate: "2021-10-27T09:30:08.205Z",
      },
      errr: [],
    };
  },
  async mounted() {
    this.idToken = store.getters.getIdToken;
    this.userId = this.jwtDecrypt(this.idToken).sub;
    this.value.userEmail = this.jwtDecrypt(this.idToken).name;
    this.value.createdUserEmail = this.jwtDecrypt(this.idToken).name;
    this.value.lastModifiedUserEmail = this.jwtDecrypt(this.idToken).name;
    this.Headers = {
      headers: {
        Authorization: "Bearer " + this.idToken,
        "Content-Type": "application/json",
      },
    };
    this.userId = this.jwtDecrypt(this.idToken).sub;
    await this.getAndSetProfileData();
    await this.getsignstatus();
    await this.getUserSettings();
    await this.getAndSetData();
    await this.setCurrentStudy();
    await this.getlanguagelist();
    await this.getdateformatlist();
    await this.getCountries();
  },
  
  created() {
    // Initialize `showCloseButton` based on the saved state in localStorage
    const savedState = localStorage.getItem('showCloseButton');
    if (savedState === 'true') {
      this.showCloseButton = true; // Set state to true if saved in localStorage
    } else {
      this.showCloseButton = false; // Default to false if nothing is in localStorage
    }
  },
  beforeRouteEnter(to, from, next) {
    const enterState=localStorage.getItem('showCloseButton')
    if (from.name === 'landingPage') {
      localStorage.setItem('showCloseButton', 'true');
    } 
    if(enterState==true || enterState=='true')
    {
      store.dispatch("setNavbar",false)
      document.querySelector("#page-wrapper").style.width="100%"
    }

    next((vm) => {
      const savedState = localStorage.getItem('showCloseButton');
      if (savedState === 'true') {
        vm.showCloseButton = true;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if(to.name!=='profileSettings'){      
      document.querySelector("#page-wrapper").style.removeProperty("width");
      localStorage.removeItem('showCloseButton');
      next();
    }
  },
  methods: {
    clearFunction() {
      this.validations = [false, false, false, false, false, false, false, false, false, false, false,false]
      this.currentvalidation = [false]
      this.signErrors = [false, false, false]
      this.errorMessages = [false, false, false]
      this.errors =[false]

    },
    collapse() {
      this.collapsed = !this.collapsed;
      const collapseIcon = document.querySelector(".collapse-icon");
      if (!collapseIcon.style.transform)
        collapseIcon.style.transform = "rotate(180deg)";
      else if (collapseIcon.style.transform == "rotate(180deg)") {
        collapseIcon.style.transform = "rotate(0deg)";
      } else if (collapseIcon.style.transform == "rotate(0deg)") {
        collapseIcon.style.transform = "rotate(180deg)";
      }
    },
    async languagechange(val) {
      localStorage.setItem("lang", val);
      this.$router.push({
        name: "profileSettings",
        params: { locale: val },
      });
      this.defaultlanguage = val;
    },
    async updateDefLanguage(defaultlanguage) {
      await axios
        .put(
          this.usersettingsBaseUrl + "updatelocallanguage",
          {
            userId: this.ProfileData.emailIdPrimary,
            localLanguage: this.defaultlanguage,
          },
          this.Headers
        )
        .then((res) => {
          console.log(res);
          this.languagechange(defaultlanguage);
          this.getUserSettings();
          alert(this.$t('navigations.default language updated successfully'));

        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAndSetData() {
      console.log(this.Headers);
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      await axios
        .get(`${this.baseapi}/management/study/getallstudy?user=${usermail}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("study list");
          console.log(res.data);
          this.studyList = res.data;
          this.setCurrentStudy();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async setCurrentStudy() {
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      await axios
        .get(this.baseUrl + "getdefaultstudy?id=" + usermail, {
          headers: {
            Authorization: "Bearer " + this.idToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.currentStudy = res.data.studyID;
          this.CreateDefStudy();
        })
        .catch((err) => {
          console.log(err.response.data.errors.DomainValidations[0]);
          if(err.response.data.errors.DomainValidations[0] === "Study ID not exists.")
          {
            this.CreateDefStudy();
          }
        });
    },
    togglePasswordVisibility(field) { // Method to toggle password visibility for each field
      if (field === 'current') {
        this.isCurrentPasswordVisible = !this.isCurrentPasswordVisible;
      } else if (field === 'new') {
        this.isNewPasswordVisible = !this.isNewPasswordVisible;
      } else if (field === 'confirm') {
        this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
      }
    },
    toggleSignPasswordVisibility(field) {
      if (field === 'password') {
        this.isPasswordVisible = !this.isPasswordVisible;
      } else if (field === 'confirmPassword') {
        this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
      }
      else if (field === 'olpassword'){
        this.isOlPasswordVisible = !this.isOlPasswordVisible;
      }
    },
    async CreateDefStudy() {
      this.setVAlue();
      await axios
        .post(this.baseUrl + "createdefaultstudy", this.value, {
          headers: {
            Authorization: "Bearer " + this.idToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async CreateDefLanguage() {
      await axios
        .post(
          this.usersettingsBaseUrl + "create",
          {
            userId: this.ProfileData.emailIdPrimary,
            localLanguage: "en",
            dateFormat: "d/MM/yyyy",
          },
          this.Headers
        )
        .then((res) => {
          console.log(res);
          console.log("to create")
          this.getUserSettings();
        })
        .catch((err) => {
          console.log(err);
        });
      this.languagechange("en");
      this.currentLocale = "en";
    },
    async getlanguagelist() {
      await axios
        .get(this.usersettingsBaseUrl + "languagelist", this.Headers)
        .then((res) => {
          console.log("language list", res.data);
          this.langList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getdateformatlist() {
      await axios
        .get(this.usersettingsBaseUrl + "dateformatlist", this.Headers)
        .then((res) => {
          console.log("dateformat list", res.data);
          this.dateList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getUserSettings() {
      await axios
        .get(
          this.usersettingsBaseUrl + "getbyid?userId=" +
          this.ProfileData.emailIdPrimary,
          this.Headers
        )
        .then((res) => {
          if (res.data == "No data found") {
            this.CreateDefLanguage();
          }
          else {

            this.defaultlanguage = res.data.localLanguage;
            this.defaultdateFormat = res.data.dateFormat;
            this.defaultTimeFormat = res.data.timeFormat;
            this.defaultGraphType = res.data.graphType;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateDefDateFormat() {
      if (this.defaultdateFormat == "") {
        this.defaultdateFormat = "MM/DD/YYYY"
      }
      localStorage.setItem("dateformat", this.defaultdateFormat);
      await axios
        .put(
          this.usersettingsBaseUrl + "updatedateformat",
          {
            userId: this.ProfileData.emailIdPrimary,
            dateFormat: this.defaultdateFormat,
          },
          this.Headers
        )
        .then((res) => {
          console.log(res);
          alert(this.$t('navigations.default date format updated successfully'));
        })
        .catch((err) => {
          console.log(err);
        });
      this.getUserSettings();
    },
    async updateTimeFormat() {
      if (this.defaultTimeFormat == "") {
        this.defaultTimeFormat = "h:mm A"
      }
      localStorage.setItem("timeformat", this.defaultTimeFormat);
      await axios
        .put(
          this.usersettingsBaseUrl + "updatetimeformat",
          {
            userId: this.ProfileData.emailIdPrimary,
            timeFormat: this.defaultTimeFormat,
          },
          this.Headers
        )
        .then((res) => {
          console.log(res);
          alert(this.$t('navigations.default time format updated successfully'));
        })
        .catch((err) => {
          console.log(err);
        });
      this.getUserSettings();
    },
    async updateGraphType(){
      localStorage.setItem("graphType", this.defaultGraphType);
      await axios.put(`${this.usersettingsBaseUrl}updategraphtype`,
        {
          userId: this.ProfileData.emailIdPrimary,
          graphType: this.defaultGraphType,
        },this.Headers
      )
      .then((res) => {
        console.log(res);
        alert(this.$t('navigations.default graph type updated successfully'));
      })
      .catch((err) => {
        console.log(err);
      });
      this.getUserSettings();
    },
    jwtDecrypt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    async getsignstatus() {
      await axios.get(this.baseAPI + "getsignstatus", this.Headers)
        .then((res) => {
          this.signStatus = res.data;
        })
        .catch(err =>
          console.log("Error in getting sign status ", err));

    },
    async isUpdateClicked() {
      this.isUpdate = true;
      this.oldPassword = "";
      this.signPassword = "";
      this.confirmSignPassword = "";
    },
    async UpdateClicked() {
      this.UpdateDefStudy();
    },
    async UpdateDefStudy() {
      this.setVAlue();
      await axios
        .post(this.baseUrl + "updatedefaultstudy", this.value, {
          headers: {
            Authorization: "Bearer " + this.idToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          alert(this.$t('navigations.default study updated successfully'));
          store.dispatch("setStudyID", this.value.studyID);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setVAlue() {
      this.value.userID = this.userId;
      this.value.createdUserId = this.userId;
      this.value.lastModifiedUserId = this.userId;
      if (this.currentStudy == "") {
        this.value.studyID = null;
      } else {
        this.value.studyID = this.currentStudy;
      }

    },
    ClearValue() {
      this.value.userID = "";
      this.value.userEmail = ""; //?
      this.value.studyID = "";
    },
    async UpdateSign() {
      if (this.oldPassword == null || this.oldPassword.trim() == "") {
        this.errors[0] = true;
      }
      await this.validateUpdate();
      console.log("signErrors.......", this.signErrors)
      if (!this.signErrors.includes(true) && confirm(this.$t('navigations.are you sure you want to update password'))) {
        await axios.get(`${this.baseAPI}updatesign?newPassword=${this.signPassword}&oldPassword=${this.oldPassword}`, this.Headers)
          .then((res) => {
            alert(this.$t('navigations.password updated successfully'));
            console.log("password updated succesfully", res)
            this.cancelUpdatesign();
          })
          .catch(err => {
            if (err.response.data.detail == "New password cannot be the same as old password.") {
              console.log("REACHED IF")
              alert(this.$t('navigations.new password cannot be the same as old password'));
            }
            else {
              console.log("REACHED ELSE")
              alert(this.$t('navigations.old password is incorrect'));
            }
          }
          );
      }
    },
    async cancelUpdatesign() {
      this.signErrors = [false, false, false]
      // this.errorMessages = "";
      this.isUpdate = false;
      this.oldPassword = "";
      this.signPassword = "";
      this.confirmSignPassword = "";
    },
    async comparePasswords(password, conformPassword) {

      if (password != conformPassword && conformPassword != "") {
        this.signErrors[1] = true;
        this.errorMessages[1] = "Passwords do not match ";
      }
      else if( password != conformPassword && conformPassword == "") {
        this.signErrors[1] = true;
        this.errorMessages[1] = "Please enter the confirm password ";
      }
      else {
        this.signErrors[1] = false;
        //this.errorMessages[1] = "Passwords do not match";
      }
    },
    async validatePassword(value, index) {
      const validationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      value = this.trimtext(value);
      if (value.match(validationRegex)) {
        this.signErrors[index] = false;
      } else {
        this.signErrors[index] = true;
        this.errorMessages[index] = "The password must contain at least 8 characters, one uppercase,one lowecase ,one numeric and one special character; if left empty, please amend.";
      }
    },
    // --- Validations ---
    async validate() {
      this.signPassword = this.trimtext(this.signPassword);
      this.confirmSignPassword = this.trimtext(this.confirmSignPassword);
      this.validatePassword(this.signPassword, 0);
      this.validatePassword(this.confirmSignPassword, 1);
      if (this.signErrors[0] == false) {
        this.comparePasswords(this.signPassword, this.confirmSignPassword);
      }
    },
    async validateUpdate() {
      await this.validate();
    },
    trimtext(value) {
      value = value.replace(/^\s+|\s+$/gm, "");
      return value;
    },
    isGood(password) {
      console.log("is good");
      var password_strength = document.getElementById("passwordStrength");
      //TextBox left blank.
      if (password.length == 0) {
        password_strength.innerHTML = "";
        return;
      }

      //Regular Expressions.
      var regex = [];
      regex.push("[A-Z]"); //Uppercase Alphabet.
      regex.push("[a-z]"); //Lowercase Alphabet.
      regex.push("[0-9]"); //Digit.
      regex.push("[$@$!%*#?&]"); //Special Character.

      var passed = 0;

      //Validate for each Regular Expression.
      for (var i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
          passed++;
        }
      }
      //Display status.
      var strength = "";
    
      switch (passed) {
        case 0:
        case 1:
        case 2:
          strength = `<div class='progress-bar bg-danger' style='width: 40%; height: 15px;' >${this.$t('navigations.weak')}</div>`;
          break;
        case 3:
          strength = `<div class='progress-bar bg-warning' style='width: 60%; height: 15px;'>${this.$t('navigations.medium')}</div>`;
          break;
        case 4:
          strength = `<div class='progress-bar bg-success' style='width: 100%; height: 15px;'>${this.$t('navigations.strong')}</div>`;
          break;
      }
      password_strength.innerHTML = strength;
    },
    async getAndSetProfileData() {
      await axios
        .get(
          this.baseAPI + "getuserwithauthid?id=" + this.userId,
          this.Headers
        )
        .then((res) => {
          res.data[0].dateOfBirth =
            res.data[0].dateOfBirth === null
              ? ""
              : res.data[0].dateOfBirth.slice(0, 10);
          this.ProfileData = res.data[0];
          this.firstName = this.ProfileData.firstName
          this.lastName = this.ProfileData.lastName
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async UpdateUser() {
      this.validateAll();
      if (this.validations.includes(true)) {
        alert(this.$t('navigations.one or more validation error occured'));
      } else {
        this.updateProfileData();
      }
    },
    async createSign() {
      await this.validate();
      if (!this.signErrors.includes(true) && confirm("Are you sure want to save this password for your Digital Signature?")) {
        await axios.get(this.baseurl + "/account-core/user/electronicsign?password=" + this.signPassword, this.Headers)
          .then((res) => {
            alert(this.$t('navigations.digital signature password created successfully'));
            this.getsignstatus();
            this.getDefaultSign();
            this.getInitials();
          })
          .catch(err => console.log(err)
          );
      }
    },
    async updateProfileData() {
      if (this.ProfileData.dateOfBirth === "") {
        this.ProfileData.dateOfBirth = null;
      }
      await axios
        .put(
          this.baseAPI + "update",
          {
            ItemId: this.ProfileData.id,
            userName: this.ProfileData.userName,
            firstName: this.ProfileData.firstName,
            lastName: this.ProfileData.lastName,
            dateOfBirth: this.ProfileData.dateOfBirth,
            active: this.ProfileData.active,
            emailIdPrimary: this.ProfileData.emailIdPrimary,
            emailIdSecondary: this.ProfileData.emailIdSecondary,
            phoneNumber: this.ProfileData.phoneNumber,
            addressLineOne: this.ProfileData.addressLineOne,
            addressLineTwo: this.ProfileData.addressLineTwo,
            country: this.ProfileData.country,
            userType: this.ProfileData.userType,
            role: this.ProfileData.role,
            userId: this.ProfileData.userId,
            isVerified: true,
            isPersonalInfoVerified: true,
          },
          this.Headers
        )
        .then((res) => {
          console.log(res);
          alert(this.$t('navigations.user profile updated successfully'));
        })
        .catch((err) => {
          console.log("errorrrrrrrrrrrrrrr...", err.response.data.detail)
          if(err.response.data.detail == "Username already taken !"){
            //alert(err.response.data.detail)
            alert(this.$t('navigations.username already taken'));
          }
          else if(err.response.data.messages[0].includes("The user already exists.")){
            alert(this.$t('navigations.user already exists with same email id'));
          }
          else{
            alert(this.$t("navigations.something went wrong"));
          }
        });
    },
    async clear() {
      this.changepassword = {
        newpassword: "",
        confirmpassword: "",
        currentpassword: "",
      };
    },
    async checkpasswords() {
      if (this.changepassword.currentpassword == "" || this.changepassword.currentpassword == null) {
        console.log("No password");
        this.currentvalidation[0] = true;
        if (this.currentvalidation.includes(true)) {
          this.errr = this.currentvalidation;
        }
      }
      else {
        this.currentvalidation[0] = false;
        this.setprofile(this.ProfileData, this.Headers);
        this.login(
          this.ProfileData.emailIdPrimary,
          this.changepassword.currentpassword,
          this.changepassword.newpassword,
          this.changepassword.confirmpassword
        );
      }
    },

    // validations 
    async validateAll() {
      console.log("Hello");
      //FirstName
      this.Validatename(this.ProfileData.firstName, 0);
      //LastName
      this.Validatename(this.ProfileData.lastName, 1);
      this.ProfileData.userName = this.ProfileData.userName == null ? this.ProfileData.userName : this.ProfileData.userName.replace(/ +/g, ' ').trim();
      this.ValidateUserName(this.ProfileData.userName, 11)
      //Dob
      this.validDob(this.ProfileData.dateOfBirth);
      //Phone Number
      this.validPhone(this.ProfileData.phoneNumber);
      //SecondaryEmail
      this.ValidateEmail(this.ProfileData.emailIdSecondary);
    },
    async Validatename(name, errindex) {
      console.log(" in validate name", name, "+", errindex);
      if (name == "") {
        this.validations[errindex] = true;
      } else if (!/^[a-zA-Z\s]*$/g.test(name)) {
        this.validations[errindex] = true;
      } else {
        this.validations[errindex] = false;
      }
    },
    async ValidateUserName(name, errindex) {
      if (name == null) {
        this.validations[errindex] = true;
      }
      else if(name.length < 5 || name.length > 10){
        this.validations[errindex] = true;
      }
      else {
        this.validations[errindex] = false;
      }
    },
    async ValidateEmail(mail) {
      if (mail != "" && mail != null) {
        if (mail == this.ProfileData.EmailIdPrimary) {
          this.validations[3] = true;
        } else if (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
          this.validations[3] = false;
        } else {
          this.validations[3] = true;
        }
      } else {
        this.validations[3] = false;
      }
    },
    async validDob(dob) {
      console.log("in validate dob", dob)
      let myDate = new Date(dob);
      let today = new Date();
      if (dob == "") {
        this.validations[4] = false;
      } else if (myDate > today) {
        this.validations[4] = true;
      } else {
        this.validations[4] = false;
      }
    },
    async validPhone(phno) {
      if (phno == "" || phno == null) {
        this.validations[5] = false;
      } else if (phno > 999999999 && phno < 10000000000) {
        this.validations[5] = false;
      } else {
        this.validations[5] = true;
      }
    },
    async getCountries() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/management/patient/getallcountry`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.countries = res.data;
          console.log("countriesss",this.countries)
        })
        .catch((err) => {
          console.log(err);
        });
    },

  },
}