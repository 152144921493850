/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import jwt_decode from "jwt-decode";
import customModal from "../customModal/customModal.vue";
import { createNoSubstitutionTemplateLiteral } from "typescript";
export default {
  name: "dataStandard",
  components: {
    VPagination,
    customModal,
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    this.idToken = store.getters.getIdToken;
    this.Headers = {
      headers: {
        Authorization: "Bearer " + this.idToken,
        "Content-Type": "application/json",
      },
    };
    window.addEventListener("studyIdChanged", (event) => {
      this.rollsprivilages = store.getters.getRolesprivilegeData;
      this.listAudit();
      this.setToDefault();
    });
    this.rollsprivilages = await store.getters.getRolesprivilegeData;
    await this.listAllStudy();
    await this.listAudit();
  },

  data() {
    return {
      baseUrl: process.env.VUE_APP_Service_URL,
      baseAPI: process.env.VUE_APP_Service_URL + "/management",
      baseDataAPI: process.env.VUE_APP_Service_URL + "/dataservices",
      fileStorageToken:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJUeXBlIjoiU3RhdGljIiwiT3JnYW5pc2F0aW9uIjoiRGF0YW1hdGljYSIsImV4cCI6NDg5ODEzMDI2OH0._ousxF4A5Sh-aR85FkwcxcaqkPPDiAeeW3lS-WDlfZg",
      idToken: "",
      Headers: {},
      currentStudy: store.getters.getStudyIs,
      siteList: [],
      subjectList: [],
      visitList: [],
      files: [],
      studyList: [],
      aduitList: [],
      auditList: [],
      uploadedFile: null,
      validations: [false],
      selectedSite: "",
      selectedSubject: "",
      selsectedVisit: "",
      fileName: "",
      selectedStudy: "",
      fileId: "",
      disablePatient: true,
      rollsprivilages: "",
      pageNumber: 1,
      pageIndex: 1,
      pageSize: 10,
      currentPage: 1,
      SortProperty: "createdAt",
      SortOrder: 0,
      auditData: {},
      search: {
        fromDate: "",
        toDate: "",
      },
      showSidePopup: {},
      auditpopup: false,
      openMapperModal: false,
      openSDTmModal: false,
      showLoading: false,
      errors: {},
      adamdata: "",
      formData: {
        createdUserEmail: "",
        createdUserId: "",
        createdAt: "2025-02-27T05:20:58.540Z",
        lastModifiedUserEmail: "",
        lastModifiedUserId: "",
        lastModifiedAt: "2025-02-27T05:20:58.540Z",
        auditId: "",
        fileName: "",
        fileType: "",
        studyId: "",
        studyRef: "",
        domain: "",
      },
      intrimData: {
        interimVersion: "",
        dateCutoff: "",
        comments: "2025-02-27T05:20:58.540Z",
      },
      errorMapper:[false]
    };
  },
  computed: {
    versionList() {
      return this.auditList
        .map(field => field.interimVersion)
    },
  },
  methods: {
    async getFiles() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseUrl}/forms-ml/sdtmtoadam/getadamtypes`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.files = res.data;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async validateNull() {
      if (
        this.fileId != "" &&
        this.fileId !== null &&
        this.fileId !== undefined
      ) {
        this.validations[0] = false;
      } else {
        this.validations[0] = true;
      }
    },
    formatTime(timestamp) {
      return moment(timestamp).format("h:mm A");
    },

    async listAllStudy() {
      const usermail = jwt_decode(this.idToken).email;
      await axios
        .get(
          `${this.baseAPI}/study/getallstudy?user=${usermail}&api-version=1.0`,
          this.Headers
        )
        .then((res) => {
          this.studyList = res.data;
          console.log("Study list...", res);
        })
        .catch((err) => console.log("error in gettting Visit list", err));
    },

    async listAudit() {
      const idtoken = store.getters.getIdToken;
      try {
        var isDes = this.SortOrder == 0 ? true : false;
        console.log("IS Descending is ", isDes);

        this.selectedStudy = store.getters.getStudyIs;

        const response = await axios.get(
          `${this.baseUrl}/forms/cdisc/listauditsdtm?StudyId=${this.selectedStudy}&pageindex=${this.pageIndex}&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}&pagesize=${this.pageSize}`,
          {
            headers: {
              Authorization: `Bearer ${idtoken}`,
              "Content-Type": "application/json",
            },
          }
        );

        this.auditList = response.data.entities;
        this.totalItems = Math.ceil(response.data.totalCount / this.pageSize);
      } catch (error) {
        console.error("Error fetching audit list:", error);
      }
    },

    async SiteChanged() {
      this.selectedSubject = "";
      await this.listSubjects();
      this.disablePatient = false;
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged");
      if (sortProp !== "dateCutoff") return;

      const selectedElement = document.getElementById(id);
      if (!selectedElement) return;

      this.sortDesc = !this.sortDesc;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.SortProperty = sortProp;
      selectedElement.classList.toggle("sortAsc", this.SortOrder === 1);
      selectedElement.classList.toggle("sortDesc", this.SortOrder === 0);

      this.pageIndex = 1;
      this.currentPage = 1;
      this.listAudit();
    },

    validateAndCreateSDTM() {
      this.showLoading=true
      this.errors = {};
      if(this.versionList.includes(this.intrimData.sdtmVersion))
      {
        this.showLoading=false
        this.errors.sdtmVersionDuplicate = "Interim Version already exist.";
      }
      if (!this.intrimData.sdtmVersion) {
        this.showLoading=false
        this.errors.sdtmVersion = "Interim Version is required.";
      }
      if (!this.intrimData.selectedDate) {
        this.showLoading=false
        this.errors.selectedDate = "Date is required.";
      }
      console.log("length is", Object.keys(this.errors).length);
      if (Object.keys(this.errors).length === 0) {
        this.CreateSDTM();
      }
    },
    clearError(field) {
      if (this.errors[field]) {
        this.errors[field] = "";
      }
    },

    async CreateSDTM() {
      this.selectedStudy = store.getters.getStudyIs;
      console.log("Selected Study:", this.selectedStudy);
      this.idToken = store.getters.getIdToken;
      await axios
        .post(
          `${this.baseUrl}/forms/cdisc/maprawtosdtm`,
          {
            studyId: this.selectedStudy,
            fileName: "",
            domain: "",
            standardType: "",
            version: "",
            subjectId: "",
            templateId: "",
            interimVersion: this.intrimData.sdtmVersion,
            dateCutoff: this.intrimData.selectedDate,
            comments: this.intrimData.SdtmComments,
          },
          {
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("API Response:", res.data);
          alert("SDTM created successfully");
          this.sdtmclosemodal();
          this.listAudit();
          this.showLoading=false
        })
        .catch((err) => {
          console.error("Error in CreateSDTM:", err);
          alert("SDTM mapper file not found");
          this.listAudit();
          this.showLoading=false
        });
    },
    async ImportToDataAnalysis(audit) {
      this.selectedStudy = store.getters.getStudyIs;
      this.formData = {
        createdUserEmail: "",
        createdUserId: "",
        createdAt: "2025-02-27T05:20:58.540Z",
        lastModifiedUserEmail: "",
        lastModifiedUserId: "",
        lastModifiedAt: "2025-02-27T05:20:58.540Z",
        auditId: audit.id,
        fileName: audit.adaM_FIleName,
        fileType: "ADAM",
        studyId: audit.studyId,
        studyRef: audit.studyRef,
        active: true,
        domain: audit.domain,
      };

      //this.idToken = store.getters.getIdToken;

      try {
        const res = await axios.post(
          `${this.baseUrl}/forms/cdisc/dataimportforanalysis`,
          this.formData,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("API Response:", res.data);
        alert("ADAM Data imported successfully");
        this.listAudit(); 
      } catch (err) {
        console.error("Error:", err);
      }
    },
    async ImportToSDTM(audit) {
      console.log("Audit:", audit);
      const domains = audit.sdtM_FileNames.map(file => file.replace(".csv", ""));
      const formattedFilePath = `/${audit.sdtmFilePath}/CSV`;

      this.formData = {
        createdUserEmail: "",
        createdUserId: "",
        createdAt: "2025-02-27T05:20:58.540Z",
        lastModifiedUserEmail: "",
        lastModifiedUserId: "",
        lastModifiedAt: "2025-02-27T05:20:58.540Z",
        auditId: audit.id,
        fileNames: audit.sdtM_FileNames,
        fileType: "",
        studyId: audit.studyId,
        studyRef: audit.studyRef,
        interimVersion: audit.interimVersion,
        filePath:formattedFilePath,
        active: true,
        domain: domains,
      };

      //this.idToken = store.getters.getIdToken;

      try {
        const res = await axios.post(
          `${this.baseUrl}/forms/cdisc/dataimportforanalysis`,
          this.formData,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("API Response:", res.data);
        alert("SDTM Data imported successfully");
        this.listAudit();
      } catch (err) {
        console.error("Error:", err);
      }
    },
    async downloadFile(format, filename, directory) {
      this.idToken = store.getters.getIdToken;
      if (format === "json") {
        try {
          const response = await axios.get(
            `${
              this.baseDataAPI
            }/centraldatahub/downloadfilezip?directory=/${directory}/JSON`,
            {
              headers: {
                Authorization: "Bearer " + this.fileStorageToken,
              },
              responseType: "blob",
            }
          );

          const blob = new Blob([response.data], { type: "application/json" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", filename+".zip");
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);

          console.log("File download started:", filename);
        } catch (error) {
          console.error("Error downloading the file:", error);
        }
      } else if (format === "csv") {
        try {
          const response = await axios.get(
            `${
              this.baseDataAPI
            }/centraldatahub/downloadfilezip?directory=/${directory}/CSV`,
            {
              headers: {
                Authorization: "Bearer " + this.fileStorageToken,
              },
              responseType: "blob",
            }
          );

          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", filename+".zip");
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);

          console.log("File download started:", filename);
        } catch (error) {
          console.error("Error downloading the file:", error);
        }
      }
    },
    togglePopup(index) {
      this.showSidePopup = {
        ...this.showSidePopup,
        [index]: !this.showSidePopup[index],
      };
    },
    async setToDefault() {
      this.disablePatient = true;
      this.selsctedSite = "";
      this.selectedSubject = "";
      this.selsectedVisit = "";
      this.selectedStudy = "";
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },
    loadPage: function(page) {
      this.currentPage = page;
      this.pageIndex = page;
      this.listAudit();
    },
    async openModelaudit(audit) {
      console.log("audit", audit);
      this.selectedStudy = store.getters.getStudyIs;
      console.log("studyyyyyyyyy", this.selectedStudy);
      audit.exportedDate = moment(audit.exportedDate).format("YYYY-MM-DD");
      audit.CreatedAt = moment(audit.CreatedAt).format("YYYY-MM-DD");
      audit.importedDate = moment(audit.importedDate).format("YYYY-MM-DD");
      this.auditData = audit;
      this.auditpopup = true;
    },
    async closemodallll() {
      this.auditpopup = false;
    },
    async ConvertToAdam(auditdata) {
      this.adamdata = auditdata;
      await this.getFiles();
      this.openMapperModal = true;
      console.log("Converting SDTM to ADaM...", this.openMapperModal);
    },
    async opensdtmModal(auditdata) {
      this.openSDTmModal = true;
      console.log("Converting SDTM to ADaM...", this.openSDTmModal);
    },
    async downloadADam(auditDetails) {
      this.idToken = store.getters.getIdToken;
      var filename = auditDetails.adaM_FIleName;
      var directory = auditDetails.adam_FilePath;
    
      console.log("this.idToken", filename);
    
      try {
        const response = await axios.get(
          `${this.baseDataAPI}/centraldatahub/download/${filename}?directory=${directory}`,
          {
            headers: {
              Authorization: "Bearer " + this.fileStorageToken,
            },
            responseType: "blob",
          }
        );
    
        console.log("Response received:", response);
    
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
    
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      } catch (err) {
        console.log("adam error ", err.response);
        if (err.response && err.response.status == 404) {
          alert("File not found");
        } else {
          alert("Error downloading file");
        }
      }
    },    
    async GenerateAdam(id) {
      this.showLoading=true
      this.idToken = store.getters.getIdToken;
      var filename = [];
      filename.push(this.adamdata.sdtM_FileName + ".csv");
      var studyRef = this.adamdata.studyRef;
      var auditId = this.adamdata.id;
      if(id==''){
        this.errorMapper=[true]
        this.showLoading=false
      }else{
         await axios
        .post(
          `${this.baseUrl}/forms-ml/sdtmtoadam/processsdtmfiles/?mapping_id=${id}&StudyRef=${studyRef}&AuditId=${auditId}&sdtmfolder=${this.adamdata.sdtmFilePath}`,
          this.adamdata.sdtM_FileNames,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.errorMapper=[false]
          this.showLoading=false
          alert("ADaM conversion successful");
          this.adamclosemodal();
          this.listAudit();
        })
        .catch((err) => {
          this.errorMapper=[false]
          this.showLoading=false
          console.error("Error in ConvertToAdam:", err);
          alert("Error converting SDTM to ADaM");
          this.adamclosemodal();
          this.listAudit();
        });
      }
     
    },
    async adamclosemodal() {
      this.fileId = "";
      this.openMapperModal = false;
    },
    sdtmclosemodal() {
      this.intrimData.sdtmVersion = "";
      this.intrimData.SdtmComments = "";
      this.intrimData.selectedDate = "";
      this.openSDTmModal = false;
    },
  },
};
