/* eslint-disable */
import axios from "axios";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import store from "@/store";
export default {
	name: "uploadstudyprotocol",
    components: {
        VPagination,
    },
    data() {
        return {
            baseurl: process.env.VUE_APP_Service_URL,
            // Data hub code starts
            dataTOken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJUeXBlIjoiQXBwbGljYXRpb24iLCJPcmdhbmlzYXRpb24iOiJTdHVkeUltcG9ydCIsIkFwcE5hbWUiOiJEYXRhRXh0cmF0IiwiZXhwIjo0ODg4NTU0MDk5fQ.MzdW7sv6VevOJkyRDIeziSrChlDJf3sr9WEfGRJXaFA",
            showupload: false,
            showLoading: false,
            studyFile: "",
            studyFiles: [],
            dataInput: "",
            showValidation: false,
            idToken: "",
            protocolDetails:  {
                fileName: "",
                status: "",
                documentRefId: "",
                documentVersion: "",
                uploadResultRefId: "",
                uploadResultVersion: ""
            },
            azuredata: {
                DocumentId: "",
                DocumentVersionId: "",
                DocumentTypeName: "",
                DocumentTitle: "",
                DocumentDescription: "",
                Document: "",
                OrganizationId: "",
            },
            searchData: {
                fileName: "",
                uploadedBy: "",
                fromDate: "",
                toDate: "",
                status: "",
                pageindex: 1,
                pageSize: 10,
                SortProperty: "CreatedAt",
                sortorder: 1
            },
            sortorder:1,
            SortProperty:"CreatedAt",
            protocolList: [],
            totalCount: 0,
            showUploadModal: false,
            defaultTime: "",
            defaultdate: "",
            currentPage: 1,
            loadNextPage: false,
            totalPages: 1,
            selectedcolumnobj: {},
            showFilter: false,
            dropdownOpen: false,
        };
    },
   async mounted(){
        this.idToken = store.getters.getIdToken;
        this.defaultTime = localStorage.getItem("timeformat");
        this.defaultdate = localStorage.getItem("dateformat");
        await this.ListProtocol();
        await this.initializeColumns()
    },
    methods: {
        initializeColumns() {
            if (this.protocolList.length > 0) {
              const allowedKeys = [
                "fileName",
                "uploadedBy",
                "uploadedDate",
                "status"
             ]
              this.getColumns = Object.keys(this.protocolList[0])
                .filter(column => allowedKeys.includes(column));
              const fieldLabels = {
                fileName: "File Name",
                uploadedBy: "Uploaded By",
                uploadedDate: "Uploaded Date",
                status: "Status"
              };
      
              const readableData = allowedKeys.map(key => fieldLabels[key] || key);
      
              this.getColumns = readableData
              this.selectedColumns = [...this.getColumns];
              this.selectedcolumnobj = this.selectedColumns.reduce((obj, key) => {
                obj[key] = true;
                return obj;
              }, {});
            }
        },
        SortSelected(sortProp, id) {
            console.log("sortproperty",sortProp);
            const selectedElement = document.getElementById(id);
            const selectedElementID = document.getElementById(id).id;
            if (sortProp != null && id == selectedElementID) console.log(this.sortorder);
            {
              if (this.sortorder == 0) {
                if (selectedElement.classList.contains("sortAsc")) {
                  selectedElement.classList.remove("sortAsc");
                  selectedElement.classList.add("sortDesc");
                } else selectedElement.classList.add("sortDesc");
              } else {
                if (selectedElement.classList.contains("sortDesc")) {
                  selectedElement.classList.remove("sortDesc");
                  selectedElement.classList.add("sortAsc");
                } else selectedElement.classList.add("sortAsc");
              }
            }
            this.searchData.pageIndex = 1;
            this.currentPage = 1;
            this.SortProperty = sortProp;
            this.sortorder = this.sortorder == 0 ? 1 : 0;
            this.ListProtocol();
        },
        async previewFiles(event) {
            this.showValidation = false
            this.studyFiles = Array.from(event.target.files);
            console.log("Files selected:", this.studyFiles);

        },
        async studyDataExport() {
            if (this.studyFiles.length == 0) {
                this.showValidation = true
            }
            else {
                this.showLoading = true;
                this.showValidation = true;
                await this.uploadData();
            }
        },
        async uploadData() {
            this.showValidation = false;
            const formData = new FormData();
            this.studyFiles.forEach((file) => {
                formData.append("file", file);
            });
           
            try {
                const response = await axios.post(
                    `${this.baseurl}/forms-ml/studyprotocol/studyprotocoltoexcel`, formData,
                    
                        
                    
                );
                this.dataInput = response.headers['xlid'];
                
                const jsonData = JSON.stringify(response.data, null, 2); // Pretty-print JSON
                const blob = new Blob([jsonData], { type: 'application/json' });

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);

                const downloadName = this.studyFiles[0].name.replace(/\.pdf$/, '');  
                link.download = `${downloadName}.json`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                console.log("data is",response.data)
                await this.CreateDocumentDMS();
                await this.dataService();
            } catch (err) {
                console.log("Error in file upload...", err);
                this.protocolDetails.status = "Upload failed in datalake"
                this.CreateProtocolDetails();
            }
        },
        async CreateDocumentDMS(){
            this.azuredata.DocumentDescription ="This is the uploaded study protocol file saved to DMS."
            const dmsFormData = new FormData();
          
            [...this.studyFiles].forEach((file) => {
                dmsFormData.append("Document", file);
            });

            dmsFormData.append("DocumentTypeName", "STUDY_PROTOCOL");
            dmsFormData.append("DocumentTitle", this.azuredata.DocumentTitle);
            dmsFormData.append("DocumentDescription", this.azuredata.DocumentDescription);
            dmsFormData.append("OrganizationId", this.azuredata.OrganizationId);

            await axios
              .post(`${this.baseurl}/dms/document/create`, dmsFormData, {
                headers: {
                  Authorization: "Bearer " + this.idToken,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                console.log("retreieved data", response);
                this.protocolDetails.documentRefId = response.data.data[0];
                this.protocolDetails.documentVersion = response.data.data[1];
                this.documentVersionId = response.data.data[1];
              })
              .catch((err) => {
                console.log("error", err);
                this.protocolDetails.status = "Upload failed in DMS"
                this.CreateProtocolDetails();
              });
             
        },
        async webHook() {
            try {
                const response = await axios.get(
                    `https://hook.eu2.make.com/yd2s588drfanqvqf79d1ekttiovsr5u2?FileName=${this.studyFiles[0].name}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.dataTOken}`
                        },
                    }
                );
                this.dataInput = response.data;//From New API
                await this.dataService();
            } catch (err) {
                console.log("protocolDetails", this.protocolDetails);
                this.protocolDetails.fileName  = this.studyFiles[0].name;
                this.protocolDetails.status = "Data conversion failed";
                await this.CreateProtocolDetails();
                this.ListProtocol();
                this.showLoading = false;
                this.studyFiles = [];
                console.log("Error in file upload...", err);
                
            }
        },
        // async webHook(){
        //     this.dataInput = "eb1a61ca85284ff3bf2b543773009385.xlsx";
        //     await this.dataService();
        // },
        async dataService() {
            try {
                const url = `${this.baseurl}/dataservices/centraldatahub/download/${this.dataInput}`;
                const response = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${this.dataTOken}`,
                        Accept: '*/*'
                    },
                    responseType: 'blob'
                });
 
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                const downloadName = this.studyFiles[0].name.replace(/\.pdf$/, '')
                link.download = `${downloadName}.xlsx`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                const downloadedFile = new File([blob], `${downloadName}.xlsx`, { type: response.headers['content-type'] });
                await this.saveDownloadedFileToDMS(downloadedFile);
                await this.CreateProtocolDetails(downloadedFile);
                this.showLoading = false;
                this.studyFiles = [];
                this.$emit("closeModal")
            } catch (error) {
                console.error('Error downloading the file:', error);
            }
        },
        async saveDownloadedFileToDMS(file) {
            const formData = new FormData();
            formData.append("Document", file);
            formData.append("DocumentTypeName", "STUDY_PROTOCOL_RESPONSE");
            formData.append("DocumentTitle", file.name);
            formData.append("DocumentDescription", "This is the processed file saved to DMS.");
            formData.append("OrganizationId", this.azuredata.OrganizationId);
       
            try {
                const response = await axios.post(
                    `${this.baseurl}/dms/document/create`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${this.idToken}`,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
       
                console.log("Processed file saved to DMS:", response);
                this.protocolDetails.uploadResultRefId = response.data.data[0];
                this.protocolDetails.uploadResultVersion = response.data.data[1];
                this.protocolDetails.fileName  = this.studyFiles[0].name;
                this.protocolDetails.status = "Success";
                // this.CreateDataScrap(file,this.protocolDetails.uploadResultRefId);
            } catch (error) {
                console.error("Error saving the processed file to DMS:", error);
                this.protocolDetails.status = "Response upload failed"
                this.CreateProtocolDetails();
            }
        },
        async CreateProtocolDetails(downloadfile){
       
            await axios.post(
                `${this.baseurl}/management/document/createstudyprotocol`, this.protocolDetails,
                {
                    headers: {
                      Authorization: "Bearer " + this.idToken,
                      "Content-Type": "application/json",
                    },
                  }
            ).then((res) => {
               
               
                this.CreateDataScrap(downloadfile,res.data);
                this.ListProtocol();
                this.closeModal();
                this.showLoading = false;
                this.studyFiles = [];
                alert("File uploaded successfully.")
            }).catch(err => {
                    console.log("error in creating study protocol", err);
                    alert("Somthing went wrong");
                    this.showLoading = false;
            })
        },
        async CreateDataScrap(file,refId){        
            console.log("filedetails",file,refId)  
            const formData = new FormData();
            if (Array.isArray(file)) {
                file.forEach(f => formData.append("File", f));
              } else {
                formData.append("File", file);
              }
            try {
                const response = await axios.post(
                    `${this.baseurl}/management/studyprotocol/importprotocol?protocolRefId=${refId}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${this.idToken}`,
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
       
                console.log("Processed file saved", response);
               
            } catch (error) {
                console.error("Error saving data", error);
            }

        },
          
        async ListProtocol(){
            await axios.get(`${this.baseurl}/management/document/liststudyprotocol?fileName=${this.searchData.fileName }&status=${this.searchData.status}&uploadedBy=${this.searchData.uploadedBy}&fromDate=${this.searchData.fromDate}&toDate=${this.searchData.toDate}&pageindex=${this.searchData.pageindex}&pagesize=${this.searchData.pageSize}&SortProperty=${this.SortProperty}&SortOrder=${this.sortorder}`,
                {
                    headers: {
                      Authorization: "Bearer " + this.idToken,
                      "Content-Type": "application/json",
                    },
                }
            ).then((res) =>{
                this.protocolList = res.data.results;
                this.totalCount = res.data.totalCount;
                this.totalPages = parseInt(res.data.totalCount /this.searchData.pageSize);
                console.log("list protocol...", res.data);
            }).catch(err => console.log("error in listing protocol...", err))
        },
        downloadFile(RefeId, versionId){
            var a = document.createElement("a");
            a.href = `${this.baseurl}/dms/document/file/${RefeId}?versionid=${versionId}`;
            a.download = "FILENAME";
            a.click();
        },
        async openUploadModal(){
            this.showUploadModal = true;
        },
        async closeModal(){
            this.showUploadModal = false;
            this.showValidation = false;
            this.showLoading = false;
            this.studyFiles = [];
        },
        filterDate(data) {
            if (data) {
              return moment(data).format(this.defaultdate);
            }
        },
        filterTime(data) {
            if (data) {
              return moment(data).format(this.defaultTime );
            }
        },
        async loadPage(page) {
            this.currentPage = page;
            this.searchData.pageindex = page;
            this.loadNextPage = true
            await this.ListProtocol();
        },
        goToViewProtocol(id) {
            this.$router.push({path: `/StudyProtocolMng`,query: { id }});
        },
        async deleteProtocol(id){
            if(confirm("Are you sure you want to delete the protocol details and the corresponding study details?")){
                console.log("hi....");
                await axios.delete(`${this.baseurl}/management/studyprotocol/deletedastudy?protocolId=${id}`,
                    {
                        headers: {
                          Authorization: "Bearer " + this.idToken,
                          "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    alert("Protocol and associated study details deleted successfully");
                    this.ListProtocol();
                    console.log(res)
                })
                .catch(err => {
                    console.log("error in deleting protocol details")
                })
            }
        }
    },
    
}