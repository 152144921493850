<template>
  <div>
    <div class="row">
        <div class="col-md-6 d-flex">
        <button @click="adddata" class="btn-sm btn-outline-secondary ml-2 py-1 px-2 my-2 ">Add Data</button>
        </div>

      </div>
    <!-- Cytoscape container -->
    <div id="cy"></div>
<!-- Popup Details Modal -->
<div v-if="adddatapopup" class="modal-wrapper is-visible">
         <div class="modal-container">
          <div class="titleclass">
            <h4 class="px-3 mt-1">Add Data</h4>
            <div class="closebutton mt-1">
              <a style="color: white" class="close-link">
                <i class="fa fa-times" @click.prevent="closeadddata"></i>
              </a>
            </div>
          </div>
          <div class="modelbody p-0">
            <div class="mb-3 p-2">
              <div class="form-group">
                <div class="mb-3 text-center">
  <label for="graphDataInput" class="form-label d-block text-start">
    Add graph data in JSON format
  </label>
  <textarea v-model="enteredgraphdata" class="form-control" id="graphDataInput" rows="7"></textarea>
  <div class="d-flex justify-content-center mt-2">
    <button class="btn btn-primary" @click="graphdataentered()">OK</button>
  </div>
</div>
              </div>
            </div>
          </div>
        </div>
        </div>
  </div>
</template>
<script>
import axios from "axios";
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre"; // Import the Dagre extension
import store from "@/store";
import klay from "cytoscape-klay";

cytoscape.use(dagre); // Register Dagre with Cytoscape
cytoscape.use(klay);

export default {
name: "NetworkXGraph",
data() {
  return {
    cy: null,
    authToken: store.getters.getIdToken,
    rootId: null,
    templatedetails: [],
    // Default layout is "dagre"
    baseurl: process.env.VUE_APP_Service_URL,
    graphdata: {
                "directed": true,
                "multigraph": false,
                "graph": {},
                "nodes": [
                    {
                        "label": "newtestgraph",
                        "properties": {
                            "new prop name": "new prop value"
                        },
                        "id": "67bd5834fd1fbb25438eb7d1"
                    },
                    {
                        "label": "new node",
                        "properties": {},
                        "id": "6b8b44c3-180b-467f-a75a-19a89313fb2a"
                    }
                ],
                "links": [
                    {
                        "label": "new node",
                        "source": "67bd5834fd1fbb25438eb7d1",
                        "target": "6b8b44c3-180b-467f-a75a-19a89313fb2a"
                    }
                ]
              },
    adddatapopup: false,
    enteredgraphdata: "",
  };
},
async mounted() {
  this.loadGraph();
  },
watch: {
  graphdata: {
      handler() {
        console.log("graphdata",this.graphdata);
        this.loadGraph();
      },
      immediate: true,
      deep: true,
    },
  },

methods: {
  async adddata(){
      this.adddatapopup = true;
  },
  async closeadddata(){
    this.adddatapopup = false;
  },
  async graphdataentered() {
  try {
    let stringtojson = JSON.parse(this.enteredgraphdata); // Parse the JSON string
    this.graphdata = stringtojson; // Assign it to the graph data
  } catch (error) {
    alert("Please enter value in JSON format");
  }
  this.closeadddata();
},
async loadGraph() {
  const elements = this.convertNodeLinkToCytoscape(this.graphdata);
  this.rootId =
    (this.graphdata.graphdata && this.graphdata.graphdata._id) ||
    (elements[0] && elements[0].data.id);

  this.cy = cytoscape({
    container: document.getElementById("cy"),
    elements: elements,
    layout: this.getLayoutConfig(),
    style: [
      {
        selector: "node",
        style: {
          shape: "rectangle", 
          label: "data(label)",
          "background-color": "data(color)",
          "text-valign": "center",
          "text-halign": "center",
          "text-wrap": "wrap",
          "text-max-width": "80px",
          width: 100,
          height: 40,
          "font-size": "14px",
        },
      },
      {
        selector: "edge",
        style: {
          "line-color": "#999",
          "target-arrow-color": "#999",
          "target-arrow-shape": "triangle",
          "curve-style": "bezier",
        },
      },
    ],
  });

  // Your existing event listeners here...
  // e.g., for node tap events

  // Run the main layout
  this.cy.layout(this.getLayoutConfig()).run();
  // Apply sequential positioning for same-type parent-child nodes.
  this.applySequentialPositioning();
},
  async isValidCssColor(strColor) {
    const s = new Option().style;
    s.color = strColor;
    // If the browser recognized the color, it will normalize it to a valid string.
    return s.color === strColor.toLowerCase();
  },
  /**
   * Convert NetworkX node-link JSON into Cytoscape elements.
   */
   convertNodeLinkToCytoscape(data) {
  const elements = [];
  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  if (data.nodes && data.links) {
    data.nodes.forEach((node) => {
      const expanded = node.expanded || false;
      let collapsible = node.collapsible || false;
      let color = "#0074D9"; // default color
      if (
        node.properties &&
        node.properties.Color &&
        this.isValidCssColor(node.properties.Color)
      ) {
        color = node.properties.Color;
      }
      if (uuidRegex.test(node.id)) {
        collapsible = false;
      }
      // Get the type from either the top-level or from node.properties.Type
      const nodeType = node.type || (node.properties && node.properties.Type) || null;
      elements.push({
        data: {
          id: node.id,
          label: node.label || node.data || node.id,
          collapsible: collapsible,
          expanded: expanded,
          type: nodeType,
          color: color,
          ...node,
        },
      });
    });
    data.links.forEach((link) => {
      const linkId = link.id || `${link.source}-${link.target}`;
      elements.push({
        data: {
          id: linkId,
          source: link.source,
          target: link.target,
          label: link.label || "",
        },
      });
    });
  } else {
    // If the backend returns a different structure, handle that here.
    return data;
  }
  return elements;
},




  // Collapse the selected node by recursively removing all descendant nodes.
  collapseNode() {
    if (!this.selectedNode) return;

    const removeDescendants = (node) => {
      const children = node.outgoers("node");
      children.forEach((child) => {
        removeDescendants(child);
        child.remove();
      });
    };

    removeDescendants(this.selectedNode);
    this.selectedNode.data("expanded", false);

    // Re-run the layout using the current selected layout.
    this.cy.layout(this.getLayoutConfig()).run();
    this.hidePopupMenu();
  },

  // --- NEW METHOD: Sort all subnodes at every level ---
  sortAllNodes() {
    if (!this.cy || !this.rootId) {
      console.warn("Cytoscape or rootId not set yet.");
      return;
    }
  
    // Example: using breadthfirst layout for sorting; you can adjust as needed.
    this.cy.layout({
      name: "breadthfirst",
      roots: [this.rootId],
      directed: false,
      fit: true,
      circle: false,
      spacingFactor: 1.2,
      padding: 30,
      sort: (a, b) => {
        const aLabel = String(a.data("label")).toLowerCase();
        const bLabel = String(b.data("label")).toLowerCase();
        if (!isNaN(aLabel) && !isNaN(bLabel)) {
          return parseFloat(aLabel) - parseFloat(bLabel);
        }
        return aLabel.localeCompare(bLabel);
      },
      animate: true,
      animationDuration: 500
    }).run();
    
    this.hidePopupMenu();
  },



  // --- NEW HELPER: Return layout configuration based on selected layout ---
  getLayoutConfig() {
    let layoutConfig = {};
    switch (this.selectedLayout) {
      case "dagre":
        layoutConfig = {
          name: "dagre",
          rankDir: "TB",  // Top to Bottom
          nodeSep: 50,
          rankSep: 100,
          edgeSep: 10,
          ranker: "longest-path",
          animate: true,
          animationDuration: 500,
        };
        break;
      case "breadthfirst":
        layoutConfig = {
          name: "breadthfirst",
          roots: [this.rootId],
          directed: false,
          fit: true,
          circle: false,
          spacingFactor: 1.2,
          padding: 30,
          animate: true,
          animationDuration: 500,
        };
        break;
        case "grid":
  layoutConfig = {
    name: "grid",
    rows: Math.ceil(Math.sqrt(this.cy ? this.cy.elements().length : 1)), // Calculate rows based on the number of elements
    animate: true,
    animationDuration: 500,
  };
  break;
      case "circle":
        layoutConfig = {
          name: "circle",
          animate: true,
          animationDuration: 500,
        };
        break;
      case "cose":
        layoutConfig = {
          name: "cose",
          animate: true,
          animationDuration: 500,
        };
        break;
        case "klay":
  layoutConfig = {
    name: "klay",
    nodeDimensionsIncludeLabels: true, // Ensure labels are considered for node dimensions
    fit: true,
    padding: 20,
    klay: {
      direction: "DOWN", // Can change direction to "RIGHT", "LEFT", etc. for sequential layout
      spacing: 40, // Adjust spacing for better separation of nodes
    },
    animate: true,
    animationDuration: 500,
  };
  break;
      default:
        layoutConfig = {
          name: "dagre",
          rankDir: "TB",
          nodeSep: 50,
          rankSep: 100,
          edgeSep: 10,
          ranker: "longest-path",
          animate: true,
          animationDuration: 500,
        };
        break;
    }
    return layoutConfig;
  },
  applySequentialPositioning() {
  // Iterate over each edge and check for same-type relationship.
  this.cy.edges().forEach(edge => {
    const sourceNode = edge.source();
    const targetNode = edge.target();
    // Retrieve type from data; it may be set directly or via properties.
    const sourceType = sourceNode.data('type') || (sourceNode.data('properties') && sourceNode.data('properties').Type);
    const targetType = targetNode.data('type') || (targetNode.data('properties') && targetNode.data('properties').Type);
    if (sourceType && targetType && sourceType === targetType) {
      // Example: position the target node to the right of the source node.
      const sourcePos = sourceNode.position();
      // You can adjust the offset (here, 150) as needed.
      targetNode.position({
        x: sourcePos.x + 150,
        y: sourcePos.y
      });
    }
  });
},
  // --- NEW METHOD: Apply the current layout to the graph ---
  applyLayout() {
  if (this.cy) {
    this.cy.layout(this.getLayoutConfig()).run();
    if (this.selectedLayout === "klay") {
      this.cy.nodes().style("shape", "rectangle"); // Ensure that klay layout uses rectangular nodes
    } else {
      this.cy.nodes().style("shape", "rectangle"); // Set rectangle shape for grid and other layouts
    }
  }
},
},
};

</script>
<style scoped>
@import "../../assets/css/style.css";

#cy {
width: 100%;
height: 600px;
border: 1px solid #ddd;
}

.popup-menu {
background: #fff;
border: 1px solid #ccc;
border-radius: 3px;
padding: 5px;
box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.popup-menu ul {
list-style: none;
padding: 0;
margin: 0;
}

.popup-menu li {
padding: 3px 8px;
cursor: pointer;
}

.popup-menu li:hover {
background: #f0f0f0;
}

.modal-wrapper {
position: fixed;
left: 0;
top: 0;
background: rgba(0, 0, 0, 0.5);
height: 100%;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
opacity: 1;
pointer-events: auto;
overflow: hidden;
z-index: 9999;
}

.modal-container {
background: #fff;
width: 80%;
max-width: 600px;
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
transition: all 0.3s ease-in-out;
}

.is-visible {
opacity: 1;
pointer-events: auto;
}

.table {
width: 100%;
border-collapse: collapse;
text-align: left;
}

.table th,
.table td {
padding: 8px;
border: 1px solid #ddd;
}

.table th {
background-color: #f4f4f9;
font-weight: bold;
}

.table-striped tbody tr:nth-child(odd) {
background-color: #f9f9f9;
}
</style>

