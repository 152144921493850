<template>
  <div class="wrapper wrapper-content">
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5>{{ $t("navigations.visit list") }}</h5>
      </div>
      <div class="ibox-content">
        <div v-if="visits.length === 0" class="no-data-message text-center">
          <p>No data available.</p>
        </div>
        <div v-else>
          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>
                    Visit No <sup><i class="fa fa-asterisk imp"></i></sup>
                  </th>
                  <th>
                    Visit Name <sup><i class="fa fa-asterisk imp"></i></sup>
                  </th>
                  <th>
                    Visit Type <sup><i class="fa fa-asterisk imp"></i></sup>
                  </th>
                  <th>Visit Notes</th>
                  <th>Form Names</th>
                  <th class="text-center sticky-column">Edit</th>
                  <th class="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(visit, index) in visits" :key="visit.visitId">
                  <td v-if="editIndex !== index">{{ visit.visitNumber }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.visitNumber"
                      class="form-control"
                      type="text"
                      @input="clearFieldError('visitNumber')"
                    />
                    <div
                      v-if="validationErrors.visitNumber"
                      class="text-danger"
                    >
                      {{ validationErrors.visitNumber }}
                    </div>
                  </td>
                  <td v-if="editIndex !== index">{{ visit.visitName }}</td>
                  <td v-else>
                    <input
                      v-model="editableRow.visitName"
                      class="form-control"
                      type="text"
                      @input="clearFieldError('visitName')"
                    />
                    <div v-if="validationErrors.visitName" class="text-danger">
                      {{ validationErrors.visitName }}
                    </div>
                  </td>
                  <td v-if="editIndex !== index">{{ visit.visitType }}</td>
                  <td v-else>
                    <div>
                      <select
                        class="form-select"
                        v-model="editableRow.visitType"
                        @input="clearFieldError('visitType')"
                        :class="{ 'is-invalid': validationErrors.visitType }"
                      >
                        <option disabled value="">{{
                          $t("navigations.select")
                        }}</option>
                        <option
                          v-for="option in visitList"
                          :key="option.typename"
                          :value="option.typename"
                        >
                          {{ option.typename }}
                        </option>
                      </select>
                      <span
                        v-if="validationErrors.visitType"
                        class="errormsg text-danger"
                      >
                        {{ validationErrors.visitType }}
                      </span>
                    </div>
                  </td>

                  <td v-if="editIndex !== index">
                    <div class="wrap-text">{{ visit.visitNotes }}</div>
                  </td>
                  <td v-else>
                    <textarea
                      v-model="editableRow.visitNotes"
                      class="form-control"
                      rows="3"
                    ></textarea>
                  </td>
                  <td v-if="editIndex !== index">
                    <div 
                      class="wrap-text" 
                      v-html="Array.isArray(visit.formName) 
                        ? visit.formName.map((name, i) => `${name}${i !== visit.formName.length - 1 ? ',' : ''}<br>`).join('') 
                        : visit.formName">
                    </div>
                  </td>
                  <td v-else>
                    <textarea
                      v-model="editableRow.formName"
                      class="form-control"
                      rows="3"
                    />
                  </td>
                  <td class="text-center">
                    <template v-if="editIndex !== index">
                      <i
                        class="fa fa-edit edit-delete"
                        v-if="protocol.status !== 'Generated'"
                        @click.prevent="editRow(index, visit)"
                      ></i>
                    </template>
                    <template v-else>
                      <i
                        class="fa fa-check action-icon text-success"
                        @click="updateRow(visit.id, visit.studyId)"
                        title="Update"
                      ></i>
                      <i
                        class="fa fa-times action-icon text-danger"
                        @click="cancelEdit"
                        title="Cancel"
                      ></i>
                    </template>
                  </td>
                  <td class="text-center">
                    <i
                      class="fa fa-trash edit-delete"
                     v-if="protocol.status !== 'Generated'"
                      style="color: #a13b7c"
                      @click="deleteRow(visit.id)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Pagination  -->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  "
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="currentPage"
                    :pages="totalPages"
                    :range-size="0"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.action-icon {
  cursor: pointer;
  font-size: 18px;
  margin: 0 5px;
}

.action-icon:hover {
  opacity: 0.8;
}

.text-success {
  color: #28a745;
}

.text-danger {
  color: #dc3545;
}
.wrap-text {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 300px;
  overflow: hidden;
}

textarea.form-control {
  resize: vertical;
  max-height: 150px;
}
.sticky-column {
  position: sticky;
  right: 0;
  background-color: #fff;
  z-index: 10;
  border-left: 1px solid #dee2e6;
}
</style>
<script src="./VisitManagement.js"></script>
