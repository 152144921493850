<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-6 align-self-center">
      <h2>{{ tocHeading }}</h2>
    </div>
    <div class="col-6 text-right">
      <button v-if="showFilterBtn" class="py-1 px-3 position-relative settings-block" :class="{'tooltip-hidden':openSettings==true}"
        @click="openSettings=true"><i class="fa fa-cog settings-icon"></i>
        <div class="settings-tooltip">{{ $t('navigations.show more options') }}</div>
      </button>
      <div class="toc-side-popup right-50" v-if="openSettings==true"  v-on:clickout="openSettings=false">
        <div @click.prevent="openModal('assign',childlabels)">
          {{ $t("navigations.assign programmers") }}
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper wrapper-content">
  <div class="ibox-content">
    <div class="row mb-4" :class="{'justify-content-end':showFilterBtn}">
      <div class="col-12 col-md-5 d-flex flex-wrap align-self-start"  >
        <button v-if="showFilterBtn"   @click="expandCollapse('expand')" type="button" class="save_btn d-flex align-items-center py-2 px-3 mr-2" 
       :class="{ 'disabled-btn-cls': showLoading == true }">
          <i class="fa fa-long-arrow-down pr-2"></i>
          <div :class="{ 'opacity-1': showLoading == false, 'opacity-0': showLoading == true }">{{
          $t("navigations.expand all") }}</div>
        </button>
        <button v-if="showFilterBtn" type="button" class="save_btn  py-2  px-3 mr-2 d-flex align-items-center"
        @click="expandCollapse('collapse')" :class="{ 'disabled-btn-cls': showLoading == true }">
          <i class="fa fa-long-arrow-up pr-2"></i>
          <div :class="{ 'opacity-1': showLoading == false , 'opacity-0': showLoading == true }">{{
          $t("navigations.collapse all") }}</div>
        </button>
        <button v-if="showFilterBtn"  type="button" class="save_btn  py-2  px-3 d-flex align-items-center"
        @click="expandCollapse('sections')" :class="{ 'disabled-btn-cls': showLoading == true }">
          <i class="fa fa-align-justify pr-2"></i>
          <div :class="{ 'opacity-1': showLoading == false , 'opacity-0': showLoading == true }">{{
          $t("navigations.show sections") }}</div>
        </button>
        <button v-if="showGenerateBtn==true" type="button" class="save_btn py-2 px-4 position-relative" :disabled="showLoading == true"
       @click="generateToC(studyId, foldername)" :class="{ 'disabled-btn-cls': showLoading == true }">
            <div class="toc-loader position-absolute"
            :class="{ 'opacity-0': showLoading == false, 'opacity-1': showLoading == true }">
            <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
            </div>
            <div :class="{ 'opacity-1': showLoading == false, 'opacity-0': showLoading == true }">{{
            $t("navigations.generate toc") }}</div>
        </button>
      </div>
      <div class="col-12 col-md-7 d-flex flex-wrap justify-content-start"   v-if="showFilterBtn">
        <div class="d-flex flex-wrap">
          <button class="toc-badge badge-black cursor-pointer py-2 px-3 mr-2" role="button" 
          :class="{ 'opacity-05': activeBadge && activeBadge == 'No Program' }" @click.prevent="fetchToC('No Program')">{{ $t('navigations.no program') }} : <span class="bold">{{ programmerCount }}</span></button>
          <button class="toc-badge badge-yellow cursor-pointer py-2 px-3 mr-2" role="button"  :class="{ 'opacity-05': activeBadge && activeBadge == 'In Development' }"  @click.prevent="fetchToC('In Development')">{{ $t('navigations.in development') }} : <span class="bold">{{ developmentCount }}</span></button>
          <button class="toc-badge badge-danger cursor-pointer py-2 px-3 mr-2" role="button"   :class="{ 'opacity-05': activeBadge && activeBadge == 'Ready For Validation' }" @click.prevent="fetchToC('Ready For Validation')">{{  $t('navigations.ready for validation')}} : <span class="bold">{{ readyForValidation }}</span></button>
          <button class="toc-badge badge-grn cursor-pointer py-2 px-3 mr-2" role="button"   :class="{ 'opacity-05': activeBadge && activeBadge == 'Validated' }" @click.prevent="fetchToC('Validated')">{{ $t('navigations.validated') }} : <span class="bold">{{ validated }}</span></button>
        </div>
        <div class="d-flex flex-wrap">
          <button class="toc-badge badge-black rounded-radius cursor-pointer py-2 px-3 mt-2 mr-2" role="button"   :class="{ 'opacity-05': activeBadge && activeBadge == 'No Output' }" @click.prevent="fetchToC('No Output')">{{ $t('navigations.no output') }} : <span class="bold">{{ noOutput }}</span></button>
          <button class="toc-badge badge-danger rounded-radius cursor-pointer py-2 px-3 mt-2 mr-2" role="button"   :class="{ 'opacity-05': activeBadge && activeBadge == 'Old Output' }" @click.prevent="fetchToC('Old Output')">{{ $t('navigations.old output') }} : <span class="bold">{{ oldOutput }}</span></button>
          <button class="toc-badge badge-grn rounded-radius cursor-pointer py-2 px-3 mt-2" role="button"   :class="{ 'opacity-05': activeBadge && activeBadge == 'Current Output' }" @click.prevent="fetchToC('Current Output')">{{ $t('navigations.current output') }} : <span class="bold">{{ currentOutput }}</span></button>       
        </div>
      </div>      
    </div>
     <div class="d-flex justify-content-between align-items-center" v-if="showFilterBtn" >  
      <div class="d-flex flex-wrap">
        <button type="button" class="save_btn  py-2 px-4 mr-2 position-relative"
        @click.prevent="openModal('run')" :class="{ 'disabled-btn-cls': showLoading == true }">
              <div class="toc-loader position-absolute"
              :class="{ 'opacity-0': showLoading == false, 'opacity-1': showLoading == true }">
              <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
              </div>
              <div :class="{ 'opacity-1': showLoading == false, 'opacity-0': showLoading == true }">{{
              $t("navigations.run programs") }}</div>
          </button>
          <button type="button" @click.prevent="openProgramRunStatusModal()" class="save_btn  py-2  px-4 position-relative"
          :class="{ 'disabled-btn-cls': showLoading == true }">
                <div class="toc-loader position-absolute"
                :class="{ 'opacity-0': showLoading == false, 'opacity-1': showLoading == true }">
                <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
                </div>
                <div :class="{ 'opacity-1': showLoading == false, 'opacity-0': showLoading == true }">{{
                $t("navigations.program run status") }}</div>
          </button>
        </div>
    
      <div class="text-end" @click.prevent="showFilter = !showFilter">
          <span class="position-relative cursor-pointer px-2"><img class="cursor-pointer"  src="../../assets/img/filter.png" width="17" height="17" alt="filter table" />
            <span class="tool-tip">{{ $t("navigations.filter") }}</span></span>            
        </div> 
     </div>
         <!-- Filter section starts -->
    <div v-if="showFilter" class="my-3">
      <div class="row justify-content-end">
        <div class="col-4">  <label for="sectionFilterSection">{{ $t('navigations.filter by programmers') }}</label>
          <Multiselect id="sectionFilterSection" mode="tags" class="multiselect-theme"  v-model="usersValues"  :label="'fullName'"  :valueProp="'fullName'" @update:modelValue="fetchToC('','filters')"
          :options="formattedUsersList" :hideSelected="false" :closeOnSelect="false" >
            <template v-slot:option="{ option }" >
                {{ option.fullName }}
            </template>
          </Multiselect>
        </div>
        <div class="col-4">  <label for="sectionFilterSection">{{ $t('navigations.filter by section') }}</label>
          <Multiselect id="sectionFilterSection" mode="tags" class="multiselect-theme"  v-model="headerNames" :label="'label'"  @update:modelValue="fetchToC('','filters')"
          :options="headingList" :hideSelected="false" :closeOnSelect="false" >
            <template v-slot:option="{ option }" >
                  {{ option.label }}
              </template>
          </Multiselect>
        </div>
        <div class="col-4">  <label for="sectionFilterReport">{{ $t('navigations.filter by report type') }}</label>
          <Multiselect id="sectionFilterReport" mode="tags" class="multiselect-theme"  v-model="reportType" 
          :options="options" :hideSelected="false" :closeOnSelect="false" @update:modelValue="fetchToC('','filters')"/>
        </div>
      </div>
    </div>
    <!-- Filter section ends -->

    <!-- Tree View -->
     <div class="text-center" v-if="initialTreeLoader">
      <img src="../../assets/img/loading.gif" width="15"  alt="Waiting for login" />
     </div>

    <div v-if="showTree && !initialTreeLoader" class="tree-container">
      <ul class="tree">
        <!-- Top-Level Nodes -->
        <li v-for="(node, index) in Object.keys(treeData)" :key="index">
            <input type="checkbox" :id="index" :checked="expandAll==true"/>
            <label class="tree_label mr-3" :for="index"><span :class="{'highlighted-background p-1':treeData[node].Headinglock==true}" >{{ treeData[node].Headingindex }} {{ treeData[node].Headingname }}<i class="fa fa-lock px-2" v-if="treeData[node].Headinglock==true"></i></span></label>

          <!-- Action Buttons for Top-Level Nodes -->
          <button :popovertarget="`headerMenu-${index}`" :anchor="`headerMenu-${index}`" class="ellipsis-btn py-2 px-3">
                    <i class="fa fa-ellipsis-v"></i>
                  </button>
            <!-- <span
            v-if="showFilterBtn"
              class="position-relative level-menu cursor-pointer py-2 px-3"
              @click.prevent="toggleSidePopup(index)"
              v-on:clickout="closeSidePopup(index)"
            >
              <i class="fa fa-ellipsis-v"></i>
            </span> -->
            <div
             :id="`headerMenu-${index}`" popover class="popup-menu"
            >
            <button  :popovertarget="`manageHeaderentry-${node}`" class="w-100 d-flex justify-content-between align-items-center">
              {{ $t("navigations.manage entry") }}
              <i class="fa fa-angle-right fs-6"></i>
            </button>
            <div :id="`manageHeaderentry-${node}`" popover class="popup-submenu">
             
              <div v-if="treeData[node].Headinglock!=true" class="popup-menu-block w-100" @click.prevent="Addheadnodeafter(treeData[node].Headingid,`headerMenu-${index}`)">
              {{ $t("navigations.add headnode after") }}
            </div>

            <div v-if="treeData[node].Headinglock!=true" class="popup-menu-block w-100" @click.prevent="Addheadnodebefore(treeData[node].Headingid,`headerMenu-${index}`)">
              {{ $t("navigations.add headnode before") }}
            </div>
              <div v-if="treeData[node].Headinglock!=true" class="popup-menu-block w-100"
                @click="Editheading(treeData[node].Headingid,treeData[node].Headingname,`headerMenu-${index}`)"
              >
                {{ $t("navigations.edit") }}
              </div>
              <div v-if="treeData[node].Headinglock!=true" class="popup-menu-block w-100" @click.prevent="DeleteHeading(treeData[node].Headingid,treeData[node].Headingindex,`headerMenu-${index}`)">
                {{ $t("navigations.delete") }}
              </div>
              <div v-if="treeData[node].Headinglock!=true" class="popup-menu-block w-100" @click.prevent="lockEntry('',treeData[node].Headingid,'',`headerMenu-${index}`,'lock','header')">
                {{ $t("navigations.lock entry") }}
              </div> 
              <div v-if="treeData[node].Headinglock==true" class="popup-menu-block w-100" @click.prevent="lockEntry('',treeData[node].Headingid,'',`headerMenu-${index}`,'unlock','header')">
                {{ $t("navigations.unlock entry") }}
              </div>
            </div>  
            <div v-if="treeData[node].Headinglock!=true" class="popup-menu-block w-100" @click.prevent="openEntryUpdate('',treeData[node].Headingid,`headerMenu-${index}`,'add')">
                {{ $t("navigations.add report") }}
              </div>
          </div>
          <!-- Reports for Each Node -->
          <ul v-if="treeData[node].Reports.length != 0">
            <li
              v-for="(childlabels,reportIndex) in treeData[node].Reports"
              :key="childlabels.Reportid || reportIndex"
            >
            <label class="tree_label mr-3" :for="childlabels.Reportid"><span :class="{'highlighted-background p-1':childlabels.Reportlock==true}">{{ childlabels.Subindex }} {{childlabels.Reportname}}<i class="fa fa-lock px-2" v-if="childlabels.Reportlock==true"></i></span></label>
            <button :popovertarget="`submenu-${node}-${reportIndex}`" :anchor="`submenu-${node}-${reportIndex}`" class="ellipsis-btn py-2 px-3">
                    <i class="fa fa-ellipsis-v"></i>
                  </button>
                  <div :id="`submenu-${node}-${reportIndex}`" popover class="popup-menu">     
                    <button v-if="childlabels.Reportlock==false || childlabels.Reportlock==null" :popovertarget="`manageReportEntry-${node}-${reportIndex}`" class="w-100 d-flex justify-content-between align-items-center">
                      {{ $t("navigations.manage entry") }}
                      <i class="fa fa-angle-right fs-6"></i>
                    </button>
                    <button v-if="(childlabels.Reportlock==false || childlabels.Reportlock==null) && (childlabels.Programms!=null && childlabels.Programms.length !== 0)" :popovertarget="`manageReportOutput-${node}-${reportIndex}`" class="w-100 d-flex justify-content-between align-items-center">
                      {{childlabels.Programs}}
                      {{ $t("navigations.manage output") }}
                      <i class="fa fa-angle-right fs-6"></i>
                    </button>
                    <div :id="`manageReportEntry-${node}-${reportIndex}`" popover class="popup-submenu">
                      <div class="popup-menu-block w-100" @click.prevent="copyMoveReport(childlabels,treeData[node].Headingid,'',`submenu-${node}-${reportIndex}`,'copy','report')">
                        {{ $t("navigations.copy report") }}
                      </div>  
                      <div class="popup-menu-block w-100" @click.prevent="copyMoveReport(childlabels,treeData[node].Headingid,'',`submenu-${node}-${reportIndex}`,'move','report')">
                        {{ $t("navigations.move report") }}
                      </div>  
                      <div class="popup-menu-block w-100" @click.prevent="openEntryUpdate(childlabels,treeData[node].Headingid,`submenu-${node}-${reportIndex}`,'update')">
                        {{ $t("navigations.update report") }}
                      </div>  
                      <div class="popup-menu-block w-100" @click.prevent="openEntryUpdate(childlabels,treeData[node].Headingid,`submenu-${node}-${reportIndex}`,'delete')">
                        {{ $t("navigations.delete report") }}
                      </div>  
                    </div>
                    <div v-if="childlabels.Reportlock==false && (!childlabels.Programms || childlabels.Programms.length === 0)" class="popup-menu-block w-100"  @click.prevent="goto(childlabels,'create')">
                      {{ $t("navigations.create program") }}
                    </div>
                    <div  v-if="childlabels.Reportlock==false" class="popup-menu-block w-100" @click.prevent="lockEntry(childlabels,treeData[node].Headingid,'',`submenu-${node}-${reportIndex}`,'lock','report')">
                        {{ $t("navigations.lock entry") }}
                    </div> 
                    <div v-if="childlabels.Reportlock==true" class="popup-menu-block w-100" @click.prevent="lockEntry(childlabels,treeData[node].Headingid,'',`submenu-${node}-${reportIndex}`,'unlock','report')">
                        {{ $t("navigations.unlock entry") }}
                    </div>   
                    <button v-if="childlabels.Reportlock==false || childlabels.Reportlock==null" class="w-100 text-left" @click.prevent="openMap(childlabels,`submenu-${node}-${reportIndex}`)">
                      {{ $t("navigations.map macro") }}
                    </button>
                    <div :id="`manageReportOutput-${node}-${reportIndex }`" popover class="popup-submenu" v-if="childlabels.Reportlock==false">  
                      <div @click.prevent="deleteOutput(childlabels.Reportid,'',`submenu-${node}-${reportIndex}`)">
                        {{ $t("navigations.delete output") }}
                      </div>
                    </div>
                  </div>
            <input type="checkbox" :id="childlabels.Reportid"  :checked="expandSections==true"/>
              <ul>                   
                  <li v-for="(value,innerIndex) in childlabels.Programms" :key="value.SourceId" >     
                    <label :for="value.SourceId" 
                    class="tree_label">
                    <span
                         v-if="value.Status!='No Program'">
                         <span :class="getBadgeClass(value.Status,value.HasOutput,'output')" ></span>
                        <span :class="getBadgeClass(value.Status,value.HasOutput,'status')" ></span>
                      </span> <span :class="{'highlighted-background p-1':value.ProgramLock==true,'px-1':value.ProgramLock==true}"><span v-if="value.Status!=null && value.ReportType!=null">{{ value.ReportType[0] }}  </span> {{ childlabels.Subindex }}<span>.</span>{{value.Version}} {{ value.ProgramName }}
                    <span v-if="value.ProgramLock==true"><i class="fa fa-lock"></i></span>
                    <input type="checkbox" :id="value.SourceId">                  
                      <!-- Status and Developer Information -->
                    <template v-if="value.Status!=null">

                      <span v-if="value.DeveloperName!='string' && value.DeveloperName!='' && value.DeveloperName!=null"> <strong>{{ $t("navigations.primary programmer") }}</strong> - {{ value.DeveloperName }}</span>
                    <span class="px-1" v-if="value.QCName!='string' && value.QCName!='' && value.QCName!=null">|</span> <span v-if="value.QCName!='string' && value.QCName!='' && value.QCName!=null"> <strong>{{ $t("navigations.qc programmer") }}</strong> - {{ value.QCName }}</span>
                    </template>
                  </span>
                      </label>
                        <!-- Action Button for Sub-Level Nodes -->
                  <button :popovertarget="`submenu-${node}-${reportIndex}-${innerIndex}`" :anchor="`submenu-${node}-${reportIndex}-${innerIndex}`" class="ellipsis-btn py-2 px-3">
                    <i class="fa fa-ellipsis-v"></i>
                  </button>

                  <!-- Popover for Sub-Level Actions -->
                  <div :id="`submenu-${node}-${reportIndex}-${innerIndex}`" popover class="popup-menu">     
                    <button v-if="childlabels.Reportlock==false" :popovertarget="`manageEntry-${node}-${reportIndex}-${innerIndex}`" class="w-100 d-flex justify-content-between align-items-center">
                      {{ $t("navigations.manage entry") }}
                      <i class="fa fa-angle-right fs-6"></i>
                    </button>
                    <!-- Nested Popover for Managing Programs -->
                    <button v-if="childlabels.Reportlock==false" :popovertarget="`manageProgram-${node}-${reportIndex}-${innerIndex}`" class="w-100 d-flex justify-content-between align-items-center">
                      {{ $t("navigations.manage program") }}
                      <i class="fa fa-angle-right fs-6"></i>
                    </button>
                    <div :id="`manageEntry-${node}-${reportIndex}-${innerIndex}`" popover class="popup-submenu">
                      <div v-if="value.ProgramLock==false || value.ProgramLock==null" class="popup-menu-block w-100" @click.prevent="lockEntry(childlabels,treeData[node].Headingid,value._id,`submenu-${node}-${reportIndex}-${innerIndex}`,'lock')">
                        {{ $t("navigations.lock program") }}
                      </div>  
                      <div v-if="value.ProgramLock==false || value.ProgramLock==null" class="popup-menu-block w-100" @click.prevent="copyMoveReport(childlabels,treeData[node].Headingid,value,`submenu-${node}-${reportIndex}-${innerIndex}`,'copy','program')">
                        {{ $t("navigations.copy program") }}
                      </div>  
                      <div v-if="value.ProgramLock==false || value.ProgramLock==null" class="popup-menu-block w-100" @click.prevent="copyMoveReport(childlabels,treeData[node].Headingid,value,`submenu-${node}-${reportIndex}-${innerIndex}`,'move','program')">
                        {{ $t("navigations.move program") }}
                      </div>                       
                      <div v-if="value.ProgramLock==true" class="popup-menu-block w-100" @click.prevent="lockEntry(childlabels,treeData[node].Headingid,value._id,`submenu-${node}-${reportIndex}-${innerIndex}`,'lock')">
                        {{ $t("navigations.unlock entry") }}
                      </div>  
                    </div>
                    <div :id="`manageProgram-${node}-${reportIndex}-${innerIndex}`" popover class="popup-submenu">
                      <div class="popup-menu-block w-100"  v-if="value.HasProgram == false || value.HasProgram == null" @click.prevent="goto(childlabels,'create')">
                        {{ $t("navigations.create program") }}
                      </div>
                      <div class="popup-menu-block w-100" v-if="value.HasProgram == true" @click.prevent="goto(childlabels, 'edit')">{{ $t("navigations.edit program") }}</div>
                      <div class="popup-menu-block w-100" @click.prevent="programHistory(childlabels, `submenu-${node}-${reportIndex}-${innerIndex}`)">
                        {{ $t("navigations.program history") }}
                      </div>  
                      <div class="popup-menu-block w-100" @click.prevent="changeStatus(childlabels,value,`submenu-${node}-${reportIndex}-${innerIndex}`)">
                          {{ $t("navigations.change validation status") }}
                        </div>    
                        <div class="popup-menu-block w-100" v-if="value.Status !== 'No Program'" @click.prevent="runBtn('run',childlabels.Reportid,childlabels.Reportname,value._id,`submenu-${node}-${reportIndex}-${innerIndex}`)">
                          {{ $t("navigations.run") }}
                        </div>
                    </div>
                    <button v-if="value.HasOutput == true" :popovertarget="`manageOutput-${node}-${reportIndex}-${innerIndex}`" class="w-100 w-100 d-flex justify-content-between align-items-center">
                      {{ $t("navigations.manage output") }}
                      <i class="fa fa-angle-right fs-6" ></i>
                    </button>
                    <div :id="`manageOutput-${node}-${reportIndex }-${innerIndex}`" popover class="popup-submenu" v-if="value.HasOutput == true">  
                      <div v-if="value.HasOutput == true" @click.prevent="runBtn('output',childlabels.Reportid,childlabels.Reportname,value._id,`submenu-${node}-${reportIndex}-${innerIndex}`)">
                        {{ $t("navigations.view output") }}
                      </div>
                      <div v-if="value.HasOutput == true" @click.prevent="deleteOutput(childlabels.Reportid,value._id,`submenu-${node}-${reportIndex}-${innerIndex}`)">
                        {{ $t("navigations.delete output") }}
                      </div>
                    </div>
                  </div>
                      <!-- <span
                    v-if="showFilterBtn"
                      class="position-relative sub-level-menu cursor-pointer float-right p-2"
                      @click.prevent="toggleSidePopup(index,vIndex, programIndex)"
                      v-on:clickout="closeSidePopup(index,vIndex,programIndex)"
                    >
                      <i class="fa fa-ellipsis-v"></i>
                          </span>
                      
                      <div
                        class="toc-side-popup-sub"
                        v-if="showSidePopup[index] && showSidePopup[index][vIndex] && showSidePopup[index][vIndex][programIndex]"
                      >
                        <div  v-if="value.HasProgram == false" @click.prevent="goto(childlabels,'create')">
                          {{ $t("navigations.create program") }}
                        </div>
                        <div @click.prevent="changeStatus(childlabels, treeData._id)">
                          {{ $t("navigations.change validation status") }}
                        </div>    
                        <div v-if="value.HasProgram == true" @click.prevent="goto(childlabels,'edit')">
                          {{ $t("navigations.edit program") }}
                        </div>
                        <div @click.prevent="Editsubheading(treeData[node].Headingid,childlabels.Reportid, childlabels.Reportname)">
                          {{ $t("navigations.edit") }}
                        </div>
                        <div @click.prevent="DeleteReport(treeData[node].Headingid,childlabels.Reportid,childlabels.Subindex)">
                          {{ $t("navigations.delete") }}
                        </div>
                        <div @click.prevent="openMap(childlabels, treeData._id)">
                          {{ $t("navigations.map macro") }}
                        </div>
                        <div @click.prevent="programHistory(childlabels, treeData._id)">
                          {{ $t("navigations.program history") }}
                        </div>  
                        <div v-if="value.Status !== 'No Program'" @click.prevent="runBtn('run',childlabels.Reportid,childlabels.Reportname)">
                          {{ $t("navigations.run") }}
                        </div>
                        <div v-if="value.HasOutput == true" @click.prevent="runBtn('output',childlabels.Reportid,childlabels.Reportname)">
                          {{ $t("navigations.view output") }}
                        </div>
                      </div> -->
                  </li>
                   <!-- Action Buttons for Sub-Level Nodes -->
              
              </ul>
             
            </li>
          </ul>
        </li>
      </ul>
    </div>
   
  </div>
</div>

  <!-- Query Open model starts -->
 <teleport to="#app">
  <div class="add_page_modal_wrapper" v-if="editpopup">
    <div class="option_modal__containers">
      <div
        class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
      >
        <h4 class="my-0">Edit Report</h4>
          <a class="close-link">
            <i
              class="fa fa-times"
              @click.prevent="closemodal()"
            ></i>
          </a>
      </div>
      
      <div
        class="form-group d-flex flex-column col-lg-10 mx-auto maxheightdesigncomplete pt-3"
      >

       <div>
        <label for="reportname" class="mt-2 mb-0">Current Name:</label>
        <input
        class="form-control"
          type="text"
          id="reportname"
          v-model="reportnamedit"
          placeholder="Edit Report Name"
          readonly
        />
      </div>
       <div>
        <label for="reportname" class="mt-2 mb-0">New Name</label>
        <input
        class="form-control"
          type="text"
          id="reportname"
          v-model="newreportname"
          placeholder="Edit Report Name"
        />
      </div>
        <div class="d-flex justify-content-center mt-2">
          <button
            type="button"
            class="btn btn-primary px-4 py-0"
            @click="onsave()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
   <div class="add_page_modal_wrapper" v-if="Addheadnodeafterpopup">
    <div class="option_modal__containers">
      <div
        class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
      >
        <h4 class="my-0">Add Headnode</h4>
          <a class="close-link">
            <i
              class="fa fa-times"
              @click.prevent="closeaftermodal()"
            ></i>
          </a>
      </div>
      
      <div
        class="form-group d-flex flex-column col-lg-10 mx-auto maxheightdesigncomplete pt-3"
      >

       <div>
        <label for="reportname" class="mt-2 mb-0">New Header Name</label>
        <input
        class="form-control"
          type="text"
          id="reportname"
          v-model="newheadnode"
          placeholder="New Heading Name"
        />
      </div>
        <div class="d-flex justify-content-center mt-2">
          <button
            type="button"
            class="btn btn-primary px-4 py-0"
            @click="Saveheadnodeafter()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
   <div class="add_page_modal_wrapper" v-if="Addheadnodebeforepopup">
    <div class="option_modal__containers">
      <div
        class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
      >
        <h4 class="my-0">Add Headnode</h4>
          <a class="close-link">
            <i
              class="fa fa-times"
              @click.prevent="closebeforemodal()"
            ></i>
          </a>
      </div>
      
      <div
        class="form-group d-flex flex-column col-lg-10 mx-auto maxheightdesigncomplete pt-3"
      >

       <div>
        <label for="reportname" class="mt-2 mb-0">New Header Name</label>
        <input
        class="form-control"
          type="text"
          id="reportname"
          v-model="newheadnode"
          placeholder="New Heading Name"
        />
      </div>
        <div class="d-flex justify-content-center mt-2">
          <button
            type="button"
            class="btn btn-primary px-4 py-0"
            @click="Saveheadnodebefore()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="add_page_modal_wrapper" v-if="editheadpopup">
    <div class="option_modal__containers">
      <div
        class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
      >
        <h4 class="my-0">Edit Heading</h4>
          <a class="close-link">
            <i
              class="fa fa-times"
              @click.prevent="closemodal()"
            ></i>
          </a>
      </div>
      
      <div
        class="form-group d-flex flex-column col-lg-10 mx-auto maxheightdesigncomplete pt-3"
      >

       <div>
        <label for="reportname" class="mt-2 mb-0">Current Name:</label>
        <input
        class="form-control"
          type="text"
          id="reportname"
          v-model="headingname"
          placeholder="Edit Heading Name"
          readonly
        />
      </div>
       <div>
        <label for="reportname" class="mt-2 mb-0">New Name</label>
        <input
        class="form-control"
          type="text"
          id="reportname"
          v-model="newheading"
          placeholder="New Heading Name"
        />
      </div>
        <div class="d-flex justify-content-center mt-2">
          <button
            type="button"
            class="btn btn-primary px-4 py-0"
            @click="onsave()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</teleport>
<teleport to="#app">
  <div class="add_page_modal_wrapper" v-if="addpopup">
    <div class="option_modal__containers">
      <div
        class="add-block-title d-flex justify-content-between align-items-center px-3 py-2"
      >
        <h4 class="my-0">Add Report</h4>
          <a class="close-link">
            <i
              class="fa fa-times"
              @click.prevent="closemodalforcreate()"
            ></i>
          </a>
      </div>
      
      <div
        class="form-group d-flex flex-column col-lg-10 mx-auto maxheightdesigncomplete pt-3"
      >
       <div>
        <label for="subreport">{{addindex}}:</label>
        <input
        class="form-control"
          type="text"
          id="subreport"
          v-model="subreport"
          placeholder="Add Report"
        />
      </div>
        <div class="d-flex justify-content-center mt-2">
          <button
            type="button"
            class="btn btn-primary px-4 py-0"
            @click="createsave()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</teleport>
<div class="blank-wrapper" v-if="initialLoader==true || initialRunLoader==true ">
  <div class="text-center">
    <h2 v-if="initialLoader==true">{{ $t('navigations.please wait while fetching programs') }}</h2>
    <h2 v-else>{{ $t('navigations.please wait') }}</h2>
    <img src="../../assets/img/loading.gif" width="15"  alt="Waiting for login" />
  </div>
</div>
  <!-- Map program starts -->
  <customModal v-if="mapProgrammePopup"  :containermd=true :bodymd="programme.length>7 ? true : false"  @closeModal="mapProgrammePopup=false">
    <template #title>
      {{ $t("navigations.map macro") }}
    </template>
    <template #body>
      <div class="text-center" v-if="loading==true">
          <img src="../../assets/img/loading.gif" width="15" class="" alt="Waiting for login" />
        </div>
        <div>            
          <table class="table shoping-cart-table">
            <tbody>
          <tr v-for="(pgmList,index) in programme" :key="pgmList._id">
            <td>
              <h4>
                {{ pgmList.ProgramName }}
              </h4>
            </td>
            <td class="text-right">
            <button @click="mapProgramme(pgmList,index)" class="btn-xs btn-outline rounded position-relative px-4">
              <div class="toc-loader position-absolute"
              :class="{ 'opacity-0': loadingBlock[index] == false, 'opacity-1': loadingBlock[index] == true }">
              <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
            </div>
            <div :class="{ 'opacity-1': loadingBlock[index] == false, 'opacity-0': loadingBlock[index] == true }">{{
              $t("navigations.map") }}</div> </button>
            </td>
          </tr>
        
        </tbody>
          </table>  
      </div>
    </template>
  </customModal>
  <!-- Map program ends -->
  <!-- Report edit starts -->
  <customModal v-if="entryUpdateModal"  :containersm=true  @closeModal="closeEntryUpdateModal">
    <template #title>
      {{ $t("navigations.report update") }}
    </template>
    <template #body>
      <div>           
        <div v-if="selectedUpdateOption=='update'">
        <label for="oldReportName" class="mt-2 mb-0">{{ $t('navigations.current name') }}</label>
        <input
        class="form-control"
          type="text"
          id="oldReportName"
          v-model="oldReportName"
          placeholder="Edit Report Name"
          readonly
        />
      </div>
       <div>
        <label for="newReportName" class="mt-2 mb-0">{{ $t('navigations.new name') }}</label>
        <input
        class="form-control"
          type="text"
          id="newReportName"
          v-model="newReportName"
          placeholder="Edit Report Name"
        />
      </div>
        <div class="d-flex justify-content-center mt-2">
          <button type="button" v-if="selectedUpdateOption=='update'" class="save_btn px-5 position-relative" :disabled="loadingAssignBlock == true"
              @click.prevent="saveEntry('update')" :class="{ 'disabled-btn-cls': loadingAssignBlock == true }">
              <div class="toc-loader position-absolute"
              :class="{ 'opacity-0': loadingAssignBlock == false, 'opacity-1': loadingAssignBlock == true }">
              <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
              </div>
              <div :class="{ 'opacity-1': loadingAssignBlock == false, 'opacity-0': loadingAssignBlock == true }">{{
              $t("navigations.save") }}</div>
          </button>
          <button type="button" v-else class="save_btn px-5 position-relative" :disabled="loadingAssignBlock == true"
              @click.prevent="saveEntry('add')" :class="{ 'disabled-btn-cls': loadingAssignBlock == true }">
              <div class="toc-loader position-absolute"
              :class="{ 'opacity-0': loadingAssignBlock == false, 'opacity-1': loadingAssignBlock == true }">
              <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
              </div>
              <div :class="{ 'opacity-1': loadingAssignBlock == false, 'opacity-0': loadingAssignBlock == true }">{{
              $t("navigations.save") }}</div>
          </button>
        </div>
      </div> 
      
    </template>
  </customModal>
  <!-- Report edit ends -->
  <!-- Program run status starts -->
  <customModal v-if="openRunStatusModal==true" :bodymd="listProgramRunStatus.length>6 ? true : false" :containerxl=true  @closeModal="closeProgramRunStatusModal">
    <template #title>
      {{ $t("navigations.program run status") }} - {{ studyInfo.studyName }}
    </template>
    <template #body>
      <div class="text-center" v-if="loading==true">
        <img src="../../assets/img/loading.gif" width="15" class="" alt="Waiting for login" />
      </div>
      <template v-else>
        <div class="text-center py-5" v-if="listProgramRunStatus == null ||  listProgramRunStatus.message || (listProgramRunStatus.result && listProgramRunStatus.result.message)">
          <h4>{{ $t('navigations.no records available') }}</h4>
        </div>
        <template  v-else>
          <table class="table table-striped" >
            <thead>
              <tr>
                <th>{{ $t('navigations.program name') }}</th>
                <th>{{ $t('navigations.entry title') }}</th>
                <!-- <th>{{ $t('navigations.program run status') }}</th> -->
                <th>{{ $t('navigations.submitted') }}</th>
                <!-- <th>{{ $t('navigations.last status update') }}</th> -->
                <th>{{ $t('navigations.comment') }}</th>
                <th class="text-center">{{ $t('navigations.actions') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,index) in listProgramRunStatus" :key="index">
                <td>{{ item.ProgramName }}</td>
                <td>{{ item.ReportName }}</td>
                <td>{{ filter(item.CreatedAt,'date') }} {{ filter(item.CreatedAt,'time') }}</td>
                <td>{{ item.Comments }}</td>
                <td class="text-center">  <button type="button" class="save_btn  py-2 px-4 mr-2 position-relative"
                   :class="{ 'disabled-btn-cls': showStatusLoading[index] == true }">
                      <div class="toc-loader position-absolute"
                      :class="{ 'opacity-0': showStatusLoading[index] == false, 'opacity-1': showStatusLoading[index] == true }">
                      <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
                      </div>
                      <div :class="{ 'opacity-1': showStatusLoading[index] == false, 'opacity-0': showStatusLoading[index] == true }">{{
                      $t("navigations.skip") }}</div>
                  </button>
                </td>               
              </tr>
            </tbody>         
        </table>
        <div class="row mx-0 mt-2">
            <div class="col-lg-12 d-flex justify-content-lg-end  mx-0 px-0">
                <v-pagination
                    v-model="currentPage"
                    :pages="totalPages"
                    :range-size="1"
                    active-color="#618bd7"
                  
                  /> 
            </div>             
          </div>
        <div class="d-flex">
          <button type="button" @click.prevent="clearTable()" class="save_btn py-2 px-4 mr-2 position-relative"
          :class="{ 'disabled-btn-cls': showClearLoading == true }">
            <div class="toc-loader position-absolute"
            :class="{ 'opacity-0': showClearLoading == false, 'opacity-1': showClearLoading == true }">
            <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
            </div>
            <div :class="{ 'opacity-1': showClearLoading == false, 'opacity-0': showClearLoading == true }">{{
            $t("navigations.clear table") }}</div>
          </button>
          <button type="button"  class="skip-all-bg py-2 px-4 mr-2 position-relative"
            :class="{ 'disabled-btn-cls': showClearLoading == true }">
              <div class="toc-loader position-absolute"
              :class="{ 'opacity-0': showClearLoading == false, 'opacity-1': showClearLoading == true }">
              <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
              </div>
              <div :class="{ 'opacity-1': showClearLoading == false, 'opacity-0': showClearLoading == true }">{{
              $t("navigations.skip all") }}</div>
          </button>
        </div>
       
        </template>

      </template>
     
    </template>
  </customModal>
  <!-- Program run status ends -->
  <!-- Report starts -->
  <customModal v-if="displayRun==true"  :containermd=true :bodyxl="true" @closeModal="closeReportModal('run')">
    <template #title>
      {{ $t("navigations.reports") }} - {{ selectedReportName }}
    </template>
    <template #body>
      <div class="d-flex flex-column">
        <template v-for="(page, index) in files" :key="index">
          <iframe class="iframe-block" :id="`${page.Page}-${index}`" style="border: none;">
          </iframe>
        </template>
      </div>     
    </template>
  </customModal>
  <!-- Report ends -->
  <!--Validation starts -->
  <customModal v-if="displayStatusModal == true"   :containermd=true @closeModal="displayStatusModal=false">
    <template #title>
      {{ $t("navigations.validation status") }} - {{ selectedReportName }}
    </template>
    <template #body>
      <div class="d-flex">
          <div>{{ $t('navigations.change validation status from') }}</div>
          <div class="w-25 px-2">
            <select v-model="status" class="study-select">
              <option v-for="option in statusOptions" :key="option">
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="px-2">
            <div>{{ $t('navigations.to') }}</div>
          </div>
          <div class="w-25 px-2">
            <select v-model="changedStatus" class="study-select">
              {{statusOptionsDropdown}}
              <option v-for="option in statusOptionsDropdown" :key="option">
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="mt-3">
          <textarea placeholder="Comments" v-model="statusComments" name="comments" id="comments"></textarea>
        </div>
        <div class="mt-3" v-if="status=='Ready For Validation'">
          <div class="row">
            <div class="col-3"><input type="checkbox" class="mr-1" name="" value="Independent Programming" id="independentProgramming" v-model="selectedValidationType"><label for="independentProgramming">{{ $t('navigations.independent programming') }}</label></div>
            <div class="col-3"><input type="checkbox" class="mr-1" name="" value="Manual Code Checking" id="manualCodeChecking" v-model="selectedValidationType"><label for="manualCodeChecking">{{ $t('navigations.manual code checking') }}</label></div>
            <div class="col-3"><input type="checkbox" class="mr-1" name="" value="Manual Output Checking" id="manualOutputChecking" v-model="selectedValidationType"><label for="manualOutputChecking">{{ $t('navigations.manual output checking') }}</label></div>
            <div class="col-3"><input type="checkbox" class="mr-1" name="" value="Program Review" id="programReview" v-model="selectedValidationType"><label for="programReview">{{ $t('navigations.program review') }}</label></div>
          </div>
        </div>
        <div class="mt-3 text-center">
          <button type="button" class="save_btn px-5 position-relative" :disabled="loadingSaveBlock == true"
            @click.prevent="saveData()" :class="{ 'disabled-btn-cls': loadingSaveBlock == true }">
            <div class="toc-loader position-absolute"
              :class="{ 'opacity-0': loadingSaveBlock == false, 'opacity-1': loadingSaveBlock == true }">
              <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
            </div>
            <div :class="{ 'opacity-1': loadingSaveBlock == false, 'opacity-0': loadingSaveBlock == true }">{{
              $t("navigations.save") }}</div>
          </button>
        </div>
    </template>
  </customModal>
  <!-- Validation ends -->
  <!--Assign programmers starts -->
  <customModal v-if="openAssignModal == true" :containerxl=true :bodymd="true" :footerShow="true" @closeModal="closeRunModal('assign')">
    <template #title>
      {{ $t("navigations.assign programmers") }}
    </template>
    <template #body>
      <ul class="modal-tree pl-2">
          <li v-for="(node, index) in Object.keys(treeModal)" :key="`treeModal-${index}`">
            {{ treeModal._id }}
            <input class="mr-2" type="checkbox" :id="`treeModal-${index}`" v-model="rootChecked[`treeModal-${index}`]"
              @change="toggleChildren(treeModal[node], index,`treeModal-${index}`)" />
            <label class="modal_tree_label" :for="`treeModal-${index}`">{{ treeModal[node].Headingindex }} {{ treeModal[node].Headingname }}</label>
            <ul v-if="treeModal[node].Reports.length != 0">
              <li v-for="childlabels, vindex in treeModal[node].Reports" :key="`treeModal-${index}-${vindex}`">
                <input class="mr-2" type="checkbox" @change="toggleChild(treeModal[node].Reports,index, vindex, $event.target.checked)" :id="`treeModal-${index}-${vindex}`"
                  v-model="childrenChecked[`treeModal-${index}-${vindex}`]" />
                <label class="modal_tree_label" :for="`treeModal-${index}-${vindex}`">{{ childlabels.Subindex }} {{
                  childlabels.Reportname }} </label>
                 <ul class="pl-3">            
                  <li v-for="(value,subindex) in childlabels.Programms" :key="value.ReportId" >
                    <input class="mr-2" type="checkbox" :id="`treeModal-${index}-${vindex}-${subindex}`"
                    v-model="subchildrenChecked[`treeModal-${index}-${vindex}-${subindex}`]" />        
                    <label :for="`treeModal-${index}-${vindex}-${subindex}`"
                    class="modal_tree_label px-1"><span
                         v-if="value.Status!='No Program'">
                         <span :class="getBadgeClass(value.Status,value.HasOutput,'output')" ></span>
                        <span :class="getBadgeClass(value.Status,value.HasOutput,'status')" class="mr-1"></span>
                      </span><span v-if="value.Status && value.ReportType!=null">{{ value.ReportType[0] }}  </span> {{ childlabels.Subindex }}<span>.</span>{{value.Version}} {{ value.ProgramName }}
                   
                      <!-- Status and Developer Information -->
                    <template v-if="value.Status!=null">
                      <span class="ml-2" v-if="value.DeveloperName!='string' && value.DeveloperName!='' && value.DeveloperName!=null"> <strong>{{ $t("navigations.primary programmer") }}</strong> - {{ value.DeveloperName }}</span>
                    <span class="px-1" v-if="value.QCName!='string' && value.QCName!='' && value.QCName!=null">|</span> <span v-if="value.QCName!='string' && value.QCName!='' && value.QCName!=null"> <strong>{{ $t("navigations.qc programmer") }}</strong> - {{ value.QCName }}</span>
                    </template>
                
                      </label>
                     
                  </li>
              
              </ul>
              </li>
            </ul>
          </li>
      </ul>
    </template>
    <template #footer >
      <div class="row">
                      <div class="col-6">
                          <label for="programmer">{{ $t('navigations.select primary programmer') }}</label>
                          <select v-model="programmer" class="study-select" id="programmer">
                          <option value="">{{ $t('navigations.none') }}</option>
                          <option :value="user.fullName" v-for="user in userList" :key="user.id">
                              {{ user.fullName}}
                          </option>
                          </select>
                      </div>
                      <div class="col-6">
                          <label for="qcprogrammer">{{ $t('navigations.select qc programmer') }}</label>
                          <select v-model="qcProgrammer" class="study-select" id="programmer">
                          <option value="">{{ $t('navigations.none') }}</option>
                          <option :value="user.fullName" v-for="user in userList" :key="user.id">
                              {{ user.fullName }}
                          </option>
                          </select>
                      </div>
                      <div class="col-12 mt-3 text-center">
                      <button type="button" class="save_btn px-5 position-relative" :disabled="loadingAssignBlock == true || (programmer=='' && qcProgrammer=='')"
                          @click.prevent="assignProgrammer()" :class="{ 'disabled-btn-cls': loadingAssignBlock == true }">
                          <div class="toc-loader position-absolute"
                          :class="{ 'opacity-0': loadingAssignBlock == false, 'opacity-1': loadingAssignBlock == true }">
                          <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
                          </div>
                          <div :class="{ 'opacity-1': loadingAssignBlock == false, 'opacity-0': loadingAssignBlock == true }">{{
                          $t("navigations.save") }}</div>
                      </button>
                      </div>
      </div>      
    </template>
  </customModal>
  <!--Assign programmers ends -->
  <!--Copy Move section starts -->
    <customModal v-if="openCopyMoveModal == true" :containerxl=true :bodymd="true" @closeModal="closeCopyMoveModal()">
    <template #title>
      {{ $t("navigations.select") }}&nbsp;{{ selectedModal }}&nbsp;{{ $t("navigations.location") }}
    </template>
    <template #body>
      <ul class="tree pl-2">
          <li v-for="(node, index) in Object.keys(treeModal)" :key="`treeCopyModal-${index}`">
            {{ treeModal._id }}
            <input class="mr-2" type="checkbox" :id="`treeCopyModal-${index}`" disabled :checked="openCopyMoveModal == true" 
               />
            <label class="tree_label" :for="`treeCopyModal-${index}`" @click.prevent="copyMove(treeModal[node].Headingname,treeData[node].Headingid,'','heading')">{{ treeModal[node].Headingindex }} {{ treeModal[node].Headingname }}</label>
            <ul v-if="treeModal[node].Reports.length != 0">
              <li v-for="childlabels, vindex in treeModal[node].Reports" :key="`treeCopyModal-${index}-${vindex}`">
                <input class="mr-2" type="checkbox" :checked="openCopyMoveModal == true" disabled :id="`treeCopyModal-${index}-${vindex}`"
                  />
                <label class="tree_label"  @click.prevent="copyMove(childlabels,treeData[node].Headingid,'','report')"  :for="`treeCopyModal-${index}-${vindex}`">{{ childlabels.Subindex }} {{
                  childlabels.Reportname }} </label>
                 <ul class="pl-3">            
                  <li v-for="(value,subindex) in childlabels.Programms" :key="value.ReportId" >
                    <input class="mr-2" type="checkbox" :checked="openCopyMoveModal == true" disabled :id="`treeCopyModal-${index}-${vindex}-${subindex}`"
                    />        
                    <label :for="`treeCopyModal-${index}-${vindex}-${subindex}`" @click.prevent="copyMove(childlabels,treeData[node].Headingid,value,'program')"
                    class="tree_label px-1"><span
                         v-if="value.Status!='No Program'">
                         <span :class="getBadgeClass(value.Status,value.HasOutput,'output')" ></span>
                        <span :class="getBadgeClass(value.Status,value.HasOutput,'status')" class="mr-1"></span>
                      </span><span v-if="value.Status && value.ReportType!=null">{{ value.ReportType[0] }}  </span> {{ childlabels.Subindex }}<span>.</span>{{value.Version}} {{ value.ProgramName }}
                   
                      <!-- Status and Developer Information -->
                    <template v-if="value.Status!=null">
                      <span class="ml-2" v-if="value.DeveloperName!='string' && value.DeveloperName!='' && value.DeveloperName!=null"> <strong>{{ $t("navigations.primary programmer") }}</strong> - {{ value.DeveloperName }}</span>
                    <span class="px-1" v-if="value.QCName!='string' && value.QCName!='' && value.QCName!=null">|</span> <span v-if="value.QCName!='string' && value.QCName!='' && value.QCName!=null"> <strong>{{ $t("navigations.qc programmer") }}</strong> - {{ value.QCName }}</span>
                    </template>
                
                      </label>
                     
                  </li>
              
              </ul>
              </li>
            </ul>
          </li>
      </ul>
    </template>
  </customModal>
  <!--Copy Move section ends -->
  <!--Run multiple programs starts -->
  <customModal v-if="openMultipleRun == true" :containerxl=true :bodymd="true" :footerShow="true" @closeModal="closeRunModal('run')">
    <template #title>
      {{ $t("navigations.custom program run") }}
    </template>
    <template #body>
      <div class="row">
        <div class="col-6">
          <h6>{{ $t('navigations.analysis tree') }}</h6>
          <div class="height-450 mt-4">
            <ul class="modal-tree pl-2">
          <li v-for="(node, index) in Object.keys(treeModal)" :key="`treeModal-${index}`">
            {{ treeModal._id }}
            <input class="mr-2" type="checkbox" :id="`treeModal-${index}`" v-model="rootChecked[`treeModal-${index}`]"
              @change="toggleChildren(treeModal[node], index,`treeModal-${index}`)" />
            <label class="modal_tree_label" :for="`treeModal-${index}`">{{ treeModal[node].Headingindex }} {{ node }}</label>
            <ul v-if="treeModal[node].Reports.length != 0">
              <li v-for="(childlabels, vindex) in treeModal[node].Reports" :key="`treeModal-${index}-${vindex}`">
                <input class="mr-2" type="checkbox" @change="toggleChild(childlabels.Programms,index, vindex, $event.target.checked,)" :id="`treeModal-${index}-${vindex}`"
                  v-model="childrenChecked[`treeModal-${index}-${vindex}`]" />
                <label class="modal_tree_label" :for="`treeModal-${index}-${vindex}`">{{ childlabels.Subindex }} {{
                  childlabels.Reportname }} </label>
                 <ul class="pl-3">            
                  <li v-for="(value,subindex) in childlabels.Programms" :key="value.ReportId" >
                    <input class="mr-2" type="checkbox" :id="`treeModal-${index}-${vindex}-${subindex}`"
                    v-model="subchildrenChecked[`treeModal-${index}-${vindex}-${subindex}`]" @change="toggleSubChild(index, vindex, subindex,$event.target.checked,value.Version,value)" />        
                    <label :for="`treeModal-${index}-${vindex}-${subindex}`"
                    class="modal_tree_label px-1"><span v-if="value.Status && value.ReportType!=null">{{ value.ReportType[0] }}  </span> {{ childlabels.Subindex }}<span>.</span>{{value.Version}} <span>{{ value.ProgramName }}
                    </span>
                    </label>
                     
                  </li>
              
              </ul>
              </li>
            </ul>
          </li>
        </ul>
          </div>
        </div>
        <div class="col-6">
          <h6>{{ $t('navigations.selected entries') }}</h6>
          <div class="mt-4" :class="{'height-450':selectedPrograms.length>10}">
            <div class="pl-2">
              <div v-for="childlabels in selectedPrograms" :key="childlabels.report">
                
                <div class="d-flex" v-for="(value,subindex) in childlabels.programs" :key="value._id" >
                  <div role="button" class="cursor-pointer mr-2" @click.prevent="deleteProgram(childlabels.nodeIndex,childlabels.reportIndex,subindex,value.Version)">
                    <img class="delete-icon mb-1" src="../../assets/img/red_minus.svg" alt="remove program">  
                  </div>                    
                  <label :for="`selectedProgram-${subindex}`"
                  ><span v-if="value.Status && value.ReportType!=null">{{ value.ReportType[0] }}  </span> {{ childlabels.subIndex }}<span>.</span>{{value.Version}} <span v-if="value.ProgramName">{{ value.ProgramName }}</span>

                  </label>
                </div>
              </div>
            </div>
          </div>           
        </div>
      </div>     
    </template>
    <template #footer >
      <div class="col-12 text-center">
        <button type="button" class="save_btn px-5 position-relative" :disabled="loadingAssignBlock == true"
            @click.prevent="runMultiplePrograms()" :class="{ 'disabled-btn-cls': loadingAssignBlock == true }">
            <div class="toc-loader position-absolute"
            :class="{ 'opacity-0': loadingAssignBlock == false, 'opacity-1': loadingAssignBlock == true }">
            <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
            </div>
            <div :class="{ 'opacity-1': loadingAssignBlock == false, 'opacity-0': loadingAssignBlock == true }">{{
            $t("navigations.run") }}</div>
        </button>
      </div>   
    </template>
  </customModal>
  <!--Run multiple programs ends -->
  <!-- Validation ends -->
  <!--Programs History starts -->
  <customModal v-if="displayProgramModal == true"   :containermd=true :bodymd=true   @closeModal="displayProgramModal=false">
    <template #title>
      {{ $t("navigations.program history") }} - <template v-if="programList && programList[0]">
        {{ programList[0].ReportName }}
      </template>
    </template>
    <template #body>
      <div class="text-center" v-if="loading==true">
        <img src="../../assets/img/loading.gif" width="15" class="" alt="Waiting for login" />
      </div>
      <template v-else>
        <div v-if="mapValue==null && mapValue.length==0">
        <h3>{{ $t('navigations.no programs available') }}</h3>
      </div>
      <div v-else>            
        <table class="table shoping-cart-table">
          <thead>
             <th>{{ $t('navigations.datetime') }}</th>
             <th>{{ $t('navigations.developer name') }}</th>
             <th>{{ $t('navigations.status') }}</th>
             <th>{{ $t('navigations.version') }}</th>
             <th class="text-center">{{ $t('navigations.actions') }}</th>
          </thead>
          <tbody>
            <tr v-for="value in programList" :key="value._id">         
              <td>
                  {{ filterDate(value.CreatedAt)}} <br><span class="mt-1">{{ filterTime(value.CreatedAt)}}</span>
              </td>
              <td>
                {{ value.DeveloperName }}
              </td>
              <td>
                <span
                  class="px-2"
                  v-if="value.Status == 'No Output' || value.Status == 'No Program'"
                >
                  <span class="badge badge-black">{{ value.Status }}</span> 
                </span>
                <span
                  class="px-2"
                  v-if="value.Status == 'Old Output' || value.Status == 'Ready For Validation'"
                >
                  <span class="badge badge-danger">{{ value.Status }}</span>
                </span>
                <span
                  class="px-2"
                  v-if="value.Status == 'In Development'"
                >
                  <span class="badge badge-yellow">{{ value.Status }}</span>
                </span>
                <span
                  class="px-2"
                  v-if="value.Status == 'Current Output'"
                >
                  <span class="badge badge-orange">{{ value.Status }}</span>
                </span>
                <span
                  class="px-2"
                  v-if="value.Status == 'Validated'"
                >
                  <span class="badge badge-grn">{{ value.Status }}</span>
                </span>
              </td>
              <td>
                {{ value.Version }}
              </td>
              <td class="d-flex justify-content-center">
                <div class="px-2 position-relative" :class="{'tooltip-hidden':setProgram==true}">
                  <span @click="mapSubFun('set program',value._id)">
                  <i class="fa fa-check-square-o edit-delete">
                  </i></span>
                  <div class="set-program-tooltip">{{ $t('navigations.set as current program') }}</div>
                </div>
                <div class="px-2 position-relative" :class="{'tooltip-hidden':compareProgram==true}">
                  <span @click="toggleComparison(value._id)">
                    <i class="fa fa-exchange edit-delete"
                      >
                    </i>
                  </span>
                  <div class="compare-program-tooltip">{{ $t('navigations.compare this with current program') }}</div>
                </div>
              </td>
            </tr>
          
          </tbody>
        </table>  
      </div>
      </template>
   
    </template>
  </customModal>
   <!--Programs History ends -->
  <!--Programs comparison starts -->
  <customModal v-if="displayProgramComparison" :containermd=true  :subContainer="true"  :bodyxl="true" @closeSubModal="closeSub()">
    <template #subContainerTitle>
      {{ $t("navigations.program comparison") }} 
    </template>
    <template #subContainerBody>
      <div class="text-center" v-if="loading==true">
        <img src="../../assets/img/loading.gif" width="15" class="" alt="Waiting for login" />
      </div>
      <div class="table-responsive height-450" v-else>
        <table class="table table-striped table-bordered comparison-table">
        <thead>
          <tr>
            <th>Field</th>
            <th>Version : {{ object1.Version }}</th>
            <th>Version : {{ object2.Version }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, key) in comparisonData" :key="key">
            <td>{{ key }}</td>
            <td>
              <template v-if="Array.isArray(value.obj1)">
                <pre>{{ JSON.stringify(value.obj1, null, 2) }}</pre>
              </template>
              <template v-else>
                {{ value.obj1 }}
              </template>
            </td>
            <td :class="{'highlight':value.diff}">
              <template v-if="Array.isArray(value.obj2)">
                <pre>{{ JSON.stringify(value.obj2, null, 2) }}</pre>
              </template>
              <template v-else>
                {{ value.obj2 }}
              </template>
            </td>
          </tr>
        </tbody>
        </table>
      </div>
     
    </template>
  </customModal>
   <!--Programs comparison ends -->
  <!--View output starts -->
  <customModal v-if="displayOutput==true" :bodyxl="loader==true ? false:true"  :containermd="true" @closeModal="closeReportModal('output')">
    <template #title>
      {{ $t("navigations.view output") }} - {{ selectedReportName }}
    </template>
    <template #body>
      <div class="text-center" v-if="loader==true">
        <img src="../../assets/img/loading.gif" width="15" class="" alt="Waiting for login" />
      </div>
      <div v-else>
          <!-- textbox starts -->
        <ul class="modal-nav modal-nav-tabs" role="tablist">
          <li v-for="(file,fileIndex) in files" :key="fileIndex">
            <a
                href="#tab-1"
                class="modal-nav-link "
                :class="{
                    'active-modal-tab': refreshFlags[fileIndex] === true,
                  }"
                data-toggle="tab"
                @click.prevent="handleTabClick(fileIndex)"
              >
              {{$t('navigations.version')}} - {{file.Version }}
            </a>
          </li>
        </ul>
        <div class="ibox-tab-content" >
          <template v-for="(file,fileIndex) in  files" :key="fileIndex">
            <div role="tabpanel" v-show="refreshFlags[fileIndex]">
            <div class="d-flex flex-column">
              <iframe class="iframe-block" v-for="(page, pageIndex) in file.Output" :id="`${fileIndex}-${page.Page}-${pageIndex}`" :key="`${fileIndex}-${page.Page}`">
          
              </iframe>
            </div>     
          </div>
          </template>
        
        </div>
      </div>
    </template>
  </customModal>
  <!--View output  ends -->
  <!-- modal ends -->
</template>
<script src="./tocgenerate.js"></script>
<style scoped>
@import "./tree.css";
@import "../../assets/css/style.css";
.highlight {
  background-color: #ffeb3b;
}
.comparison-table{
  table-layout: fixed;
  width:100%
}
.comparison-table tbody tr td:first-child,
.comparison-table thead tr th:first-child {
  background-color: hsl(0, 0%, 100%);
  position: sticky;
  left: -1px;

}
.comparison-table tbody tr td:nth-child(2),
.comparison-table tbody tr td:nth-child(3) {
  width:40%;
  white-space: break-spaces
}
.modal-tree input:checked~ul {
  display: block;
}

:checked ~ label.tree_label:before { content: url("../../assets/images/folder-close.png"); }

.modal-tree .tree_label:after {
  display: none;
}

label.modal_tree_label:before {
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 1em 0 -2em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  text-align: center;
  line-height: .8em;
  }
.linkstyle {
  cursor: pointer;
}

.settings-block{
  font-size: 1rem;
  color:hsl(0, 0%, 50%);
  border:none;
  transition: transform 0.5s ease-in-out, background-color 0.5s ease-in-out ;
}
.settings-block .settings-icon{
  transform-origin: center center; 
}
.settings-block:hover .settings-icon{
  animation: rotate90 0.8s linear;
}
@keyframes rotate90 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}


/* .position-relative:hover .settings-icon
{
  transform:rotate(360deg);
} */
.skip-all-bg{
  text-align: center;
  border-color: hsl(187, 100%, 38%);
  color: white !important;
  padding: 0.3rem 0.4rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  background-color: hsl(187, 100%, 38%)
}

.position-relative .settings-tooltip{
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 35px;
  top: -10px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .settings-tooltip,
.position-relative:focus-within .settings-tooltip,
.position-relative:focus-visible .settings-tooltip{

  visibility: visible;
  transition: visibility 30ms ease-in;
}
.position-relative.tooltip-hidden .settings-tooltip {
  display:none !important;
}

.position-relative .set-program-tooltip{
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 35px;
  top: -10px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .set-program-tooltip,
.position-relative:focus-within .set-program-tooltip,
.position-relative:focus-visible .set-program-tooltip{

  visibility: visible;
  transition: visibility 30ms ease-in;
}
.position-relative.tooltip-hidden .set-program-tooltip {
  display:none !important;
}
.position-relative .compare-program-tooltip{
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 35px;
  top: -10px;
  font-size: 0.8rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}

.position-relative:hover .compare-program-tooltip,
.position-relative:focus-within .compare-program-tooltip,
.position-relative:focus-visible .compare-program-tooltip{

  visibility: visible;
  transition: visibility 30ms ease-in;
}
.position-relative.tooltip-hidden .compare-program-tooltip {
  display:none !important;
}
.linkstyle {
  cursor: pointer;
}
.add-block-title {
  color: #ffffff;
  background-color: #768DD3;
}
.option_modal__containers {
  width: min(600px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  min-height: 150px;
  position: fixed;
  margin-top: 100px;
  max-height: 400px;
}
.add_page_modal_wrapper,
.modal__wrapper__component {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  height: 100pc;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}
.sub-level-menu:hover,
.level-menu:hover
{
  background-color: rgba(0,0,0, 0.06);;
}

.toc-side-popup {
  position: absolute;
  text-align: left;
  top: 6px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: #fff;
  width: min(150px,90%);
  box-shadow: 3px 3px 3px hsla(0,0%,62.7%,.5);
  z-index: 200;
}

.toc-side-popup>div {
  cursor: pointer;
  padding: 0.5rem;
}

.toc-side-popup>div:hover {
  background-color: #e4eaf9;
}

.float-right .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 15px;
  top: -15px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}

.plan-side-popup>div {
  cursor: pointer;
  padding: 0.5rem;
}

.plan-side-popup>div:hover {
  background-color: #e4eaf9;
}

.horizondalscroll {
  overflow-y: auto;
  max-height: 500px;
}

.modal__title {
  background-color: #618bd7;
  color: #fff;
}
.toc-side-popup-sub {
  position: absolute;
  text-align: left;
  right: 45px;
  top: 13px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: fit-content;
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.toc-side-popup-sub > div {
  cursor: pointer;
  padding: 0.6rem 0.8rem;
}

.toc-side-popup-sub > div:hover {
  background-color: #e4eaf9;
}

.badge-grn {
  background-color: #1ab394;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.left-220{
  left:220px;
}

.right-50{
  right:50px
}
.delete-icon{
  width:1em;
}
.badge-black{
 background-color: rgb(10, 10, 10);
}
.toc-badge{
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size:0.7rem;
  color:#fff;
  border-radius: 0.25rem;
  text-shadow: none;
  white-space: nowrap;
}
.rounded-radius{
  border-radius: 30px;
}
.blank-wrapper{
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 30%);
  color:rgb(255, 255, 255);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
  /* pointer-events: none; */
}
.height-450{
  height:450px;
  overflow-y: scroll;
}
.highlighted-background{
  background-color: hsl(0, 0%, 93%);
}
.iframe-block {
  width: 100%;
  height: 500px
}
.iframe-block-visible{
  overflow: visible;
  /* height:100vh;
  overflow:visible;
  max-height: 100%; */
}
.multiselect-theme {
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
  --ms-tag-color: #000;
  --ms-tag-font-size: 0.7rem;
  --ms-tag-bg: rgb(210, 210, 210);
  
}

.disabled-btn-cls {
  opacity: 0.6;
  pointer-events: none;
}

.custom-badge{
  display: inline-block;
  width: 13px;
  height: 13px;
 
  line-height: 28px;
  position: relative;
  top: 1px;
}
.status-b-radius{
  border-radius: 3px;
}

/* Popver style starts; */
.ellipsis-btn{
  anchor-name:--manage-btn;
  border:0;
}

.ellipsis-btn:hover{
  background-color: rgba(234, 234, 234, 1)
}
.popup-menu, .popup-submenu {
  position:absolute;
  inset: auto;
  text-align: left;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}
.popup-menu{
  position-try-fallbacks: --bottom;
  top:anchor(bottom);
  right:anchor(right);
  width:160px;
}
.popup-submenu{
  top:anchor(bottom);
  right:anchor(left);
  width:fit-content;
  margin-inline-end: 5px;
}

.popup-menu> div, .popup-submenu > div {
  cursor: pointer;
  padding: 0.6rem 0.8rem;
}
.popup-menu> button{
 border:0;
 font-size:0.8rem;
 font-weight:400;
 color:rgba(0,0,0);
 padding: 0.6rem 0.8rem;
}
.popup-menu> .popup-menu-block:hover, .popup-submenu> div:hover,.popup-menu> button:hover {
  background-color: #e4eaf9;
}
[popover]:popover-open {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  animation: fadeIn 0.2s ease-in-out;
}

.nested-popover-btn {
  display: block;
  margin-top: 5px;
  background: #f1f1f1;
  border: none;
  padding: 5px;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}

@position-try --bottom{
  inset:auto;
  bottom:anchor(top);
  right:anchor(left)
}
/* Popver style ends */
/* Tab CSS starts*/
.modal-nav.modal-nav-tabs li
{
  background: none;
  border: none;
}
.modal-nav-tabs>li>a
{
  display: inline-block;
  color: #6d6d6d;
  font-weight: 700;

}
.modal-nav-tabs .modal-nav-link {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 5px 20px;
}
.modal-nav-tabs .modal-nav-link:hover, .modal-nav-tabs .modal-nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.modal-nav-tabs .modal-nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.modal-nav-tabs .modal-nav-link.active,
.modal-nav-tabs .nav-item.show .modal-nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.ibox-tab-content{
  color: inherit;
  padding: 10px;
  border-color: #eeeeee;
  -o-border-image: none;
  border-image: none;
  border-top-style: solid;
  border-top-width: 1px;
}
/* Tab CSS ends */
</style>