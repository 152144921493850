/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import { EventBus } from '../ToCgenerate/tocgenerate.vue';
export default {
  name: "stats",
  components: {
    Multiselect,
  },
  props:{
    id:{
      type:String,
      default:""
    },
    editoption:{
      type:String,
      default:""
    }
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      programName: "",
      rowFieldName: "",
      groupByField: "",
      returnType: "",
      orientation: "",
      pageGroup: "",
      reportName:"",
      reportId:"",
      developerName:"",
      qcName:"",
      status:"",
      programId:"",
      tocId:"",
      rowFilterFields: [],
      columnFilters: [],
      data: [],
      loadingBlock: false,
      loadingSaveBlock:false,
      rowFilterValidations: [],
      dataPages: [],
      numericOperations:[],
      numericOperationValues:[
        {label:"count",value:"count"},
         {label:"mean",value:"mean"},
        {label:"std",value:"std"},
        {label:"min",value:"min"},
        {label:"max",value:"max"}
      ],
      calculationsFilterValidations: [],
      selectedFields: [""],
      rowFilters: [{
        Operator: "",
        ColumnName: "",
        Value: ""
      }
      ],
      calculations: {
      },
      columnFieldNames: [],
      groupByFilter: [],
      chartType: "",
      studyInfo:{},
      editData:{},
      reportType: "",
      graphData: "",
      clickedTab: "",
      footnotes: "",
      displayModal: false,
      showCreate:false,
      setMacro:false,
      counter: 1,
      programName:"",
      tableTitle: "",
      tableSubtitle: "",
      tableGroupBy: [],
      tableRowFilters:[{
        Operator: "",
        ColumnName: "",
        Value: ""
      }],
      tableColumnFieldNames:[],
      tableRowFieldNames:[],
      tableColumnFilters:[],
      tablePageGroup:"",
      tableReturnType:"",
      tableChartType:"",
      tableReportType:"",
      tableOrientation:"",
      validations: [false, false, false, false, false, false, false, false, false, false, false, false, false],
      fileName: "",
      fileList: ["LocalStorage/adsl.xlsx", "LocalStorage/adae.xlsx"],
      graphList:[]
    }
  },
  created() {
    const savedState = localStorage.getItem('showTocCreate');
    const savedMacro = localStorage.getItem('saveMacro');
    if (savedState == 'true' || savedState == true) {
      this.showCreate = true; // Set state to true if saved in localStorage
    } else {
      this.showCreate = false; // Default to false if nothing is in localStorage
    }
    if (savedMacro == 'true' || savedMacro == true) {
      this.setMacro = true; // Set state to true if saved in localStorage
    } else {
      this.setMacro = false; // Default to false if nothing is in localStorage
    }
  },
  async mounted() {
  
    this.reportName=localStorage.getItem("Reportname")
    this.reportId=localStorage.getItem("Reportid")
    this.tocId=localStorage.getItem("TocId")
    await this.getstudyinfo()
    const key = "frequency_fields"; // This can be dynamic
    const value = {
      group_by: [],
      frequency_column: [],
      subject_column: []
    };

    // Add the key-value pair to the object
    this.calculations[key] = value;
    if(this.editoption=='1'){
       await this.getData()
    }
    else{
      this.status="In Development"
    }
  },

  beforeRouteEnter(to, from, next) {
    if (from.name == 'tocgenerate') {
      localStorage.setItem('showTocCreate', 'true');
    } 
    else if(from.name=='Macro_Management')
    {
      localStorage.setItem('saveMacro',true)
    }
    next((vm) => {
      const savedState = localStorage.getItem('showTocCreate');
      if (savedState == 'true' || savedState == true) {
        vm.showCreate = true;
      }
    });
  },
  beforeRouteLeave(to, from, next) {
      localStorage.removeItem('showTocCreate');
      localStorage.removeItem('Reportname');
      localStorage.removeItem('Reportid');
      localStorage.removeItem('TocId');
      localStorage.removeItem('saveMacro');
      next();
  },
  methods: {
    async getFields() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/stats-python/general/fields?fileName=${this.fileName}&threshold=10`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.rowFilterFields = [...res.data.categorical, ...res.data.numerical].sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;  
            return 0;            
          });
          this.columnFilters = [...res.data.categorical, ...res.data.numerical].sort((a, b) => {
            if (a < b) return -1; 
            if (a > b) return 1;  
            return 0;             
          });
          this.groupByFilter = res.data.categorical.sort((a, b) => {
            if (a < b) return -1; 
            if (a > b) return 1; 
            return 0;
          });
          console.log(this.rowFilterFields);

        })
        .catch((err) => {
          console.log(err);

        });

    },
    // checkFields(){
    //   if(this.rowFilterFields.length==0 && this.columnFilters.length==0 && this.groupByFilter.length==0){
    //     alert(this.$t("navigations.please select a file"))
    //   }
    // },
    // async getFiles() {
    //   const idtoken = store.getters.getIdToken;
    //   await axios
    //     .get(
    //       `${this.baseurl}/stats-python/general/fields?file=${this.fileName}&threshold=10`,
    //       {
    //         headers: {
    //           Authorization: "Bearer " + idtoken,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       this.fileList=res.data

    //     })
    //     .catch((err) => {
    //       console.log(err);

    //     });

    // },   
    async addRow(type, index) {
      if (type == 'row') {
        this.rowFilters.push(
          {
            Operator: "",
            ColumnName: "",
            Value: ""
          }
        )
      }
      else if (type == 'calculations') {
        // Create a unique key like 'frequency_fields1', 'frequency_fields2', etc.
        const key = `frequency_fields`;
        // Add the key with the default object structure
        this.calculations[key] = {
          group_by: [],
          frequency_column: [],
          subject_column: []
        }
      }
      // else if (type == 'footnotes'){
      //   this.footnotes.push(
      //     ""
      //   )
      // }
      else {
        this.rowTableFilters.push(
          {
            Operator: "",
            ColumnName: "",
            Value: ""
          }
        )
      }
    },
    async deleteFilter(type, index) {
      if (type == 'row') {
        this.rowFilters.splice(index, 1);
      }
      else if (type == 'calculations') {
        delete this.calculations[index];
        this.counter--
      }
      // else if (type == 'footnotes'){
      //   this.footnotes.splice(index)
      // }
      else
      {
        this.rowTableFilters.splice(index, 1);
      }

    },
    valueChange(){
      if(this.reportType!='Chart' && this.validations[5]==true){
        this.validations[5]=false
      }
      if(this.reportType=='Graph'){
        
        this.getGraphList()
      }
    },
    async getGraphList()
    {
      const idtoken = store.getters.getIdToken;
      await axios
      .get(
        `${this.baseurl}/stats-python/macro/graphs`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        this.graphList = res.data;
        console.log("graph list is",this.graphList)
      })
      .catch((err) => {
        console.log(err);
      });
    },
    async setValidations() {
      this.rowFilterValidations[0] = [false, false, false]
      // if(this.reportType=='Graph'){
      //   if (this.rowFilters.length > 0) {
      //     this.rowFilters.forEach((item, index) => {
      //       this.rowFilterValidations[index] = [false, false, false]
      //       if (item.ColumnName == "" || item.ColumnName == undefined) {
      //         this.rowFilterValidations[index][0] = true
      //       }
      //       if (item.Operator == "" || item.Operator == undefined) {
      //         this.rowFilterValidations[index][1] = true
  
      //       }
      //       if (item.Value == "" || item.Value == undefined) {
      //         this.rowFilterValidations[index][2] = true
      //         this.loadingBlock = false
      //       }
      //     })
      //   }
      // }
    
      // if (this.calculations.length > 0) {
      //   this.calculations.forEach((item, index) => {
      //     this.calculationsFilterValidations[index] = [false, false, false]
      //     if (item.group_by == "" || item.group_by == undefined) {
      //       this.calculationsFilterValidations[index][0] = true
      //     }
      //     if (item.frequency_column == "" || item.frequency_column == undefined) {
      //       this.calculationsFilterValidations[index][1] = true

      //     }
      //     if (item.subject_column == "" || item.subject_column == undefined) {
      //       this.calculationsFilterValidations[index][2] = true
      //       this.loadingBlock = false
      //     }
      //   })
      // }
      const fields = [
        { value: this.programName, index: 0 },
        { value: this.fileName, index: 1 },
        { value: this.returnType, index: 2 },
        { value: this.orientation, index: 3 },
        { value: this.reportType, index: 4 },
        { value: this.chartType, index: 5 },
        { value: this.status, index: 6 },
      ];

      fields.forEach(({ value, index }) => {
        if (index === 5 && this.reportType === 'Graph') {
          this.validations[5] = !value;
        } else if (index !== 5) {
          if (this.setMacro && index !== 6) {
            this.validations[index] = !value;
          } else if(this.setMacro==false) {
            this.validations[index] = !value;
          }
        }        
      });
      if (this.validations.includes(true) || this.rowFilterValidations.some((error) => error.includes(true)) || this.calculationsFilterValidations.some((error) => error.includes(true))) {
        this.loadingSaveBlock = false
        this.loadingBlock = false
      }
    },
    async saveMacro(){ 
      const idtoken = store.getters.getIdToken;
      if(this.editoption=='0'){
        await axios
        .post(
          `${this.baseurl}/stats-python/macro/create`,
          {
            "ProgramName": this.programName,
            "FileName": this.fileName,
            "ReportType": this.reportType,
            "SaveTo": "", // Add this field if needed
            "ChartType": this.chartType,
            "RowFilters": this.rowFilters,
            "ColumnFilters": this.columnFieldNames,
            "GroupBy": this.groupByField,
            "IncludeTotal": true,
            "NumericOperations": this.numericOperations,
            "PageGroup": this.pageGroup,
            "ReturnType": this.returnType,
            "Orientation": this.orientation,
            "Projection": "", // Add this field if needed
            "Sorting": {}, // Add this field if needed
            "Table": this.reportType == 'Table' ? [
              {
                "ProgramName": this.programName,
                "RowFields": this.tableRowFieldNames,
                "ColumnFields": this.tableColumnFieldNames,
                "ColumnFilters": this.columnFieldNames,
                "RowFilters": this.rowFilters,
                "GroupBy": this.groupByField,
                "IncludeTotal": true,
                "PageGroup": this.pageGroup,
                "ReturnType": this.returnType,
                "ReportType": this.reportType,
                "Orientation": this.orientation,
                "Title": this.tableTitle,
                "Subtitle": this.tableSubtitle,
                "Footnote": this.footnotes
              }
            ] : [],
            "StudyRef": this.studyInfo.studyRef,
            "TocDescription": "" // Add this field if needed
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSaveBlock = false
          if (this.editoption == '0') {
            alert(this.$t('navigations.macro created successfully'))
            this.$router.push('/Macro_Management')
          }
          if (this.editoption == '1') {
            alert(this.$t('navigations.macro updated successfully'))
          }
        })
        .catch((err) => {
          this.loadingSaveBlock = false
        });
      }
      else if(this.editoption=='1'){
        await axios
        .put(
         `${this.baseurl}/stats-python/macro/update`,
          
            {
              "Id": this.id,
              "ProgramName": this.programName,
              "FileName": this.fileName,
              "ReportType": this.reportType,
              "SaveTo": "",
              "ChartType": this.chartType,
              "RowFilters": this.rowFilters,
              "ColumnFilters": this.columnFieldNames,
              "GroupBy": this.groupByField,
              "IncludeTotal": true,
              "NumericOperations": this.numericOperations,
              "PageGroup": this.pageGroup,
              "ReturnType": this.returnType,
              "Orientation": this.orientation,
              "Projection": "",
              "Sorting": {
                "additionalProp1": "",
                "additionalProp2": "",
                "additionalProp3": ""
              },
              "Table":this.reportType=='Table' ? [
                {
                  "ProgramName": this.programName,
                  "RowFields": this.tableRowFieldNames,
                  "ColumnFields":this.tableColumnFieldNames,
                  "IncludeTotal": true,
                  "Title": this.tableTitle,
                  "Subtitle": this.tableSubtitle,
                  "Footnote": this.footnotes
                }
              ] : [],
              "StudyRef": this.studyInfo.studyRef,
              "TocDescription": ""
            }
         ,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.loadingSaveBlock = false
          if (this.editoption == '0') {
            alert(this.$t('navigations.macro created successfully'))
            this.$router.push('/Macro_Management')
          }
          if (this.editoption == '1') {
            alert(this.$t('navigations.macro updated successfully'))
            this.$router.push('/Macro_Management')
          }
        })
        .catch((err) => {
          this.loadingSaveBlock = false
        });
      }      
    },
    async saveData() {
      this.loadingSaveBlock = true
      await this.setValidations() 
      if (!this.validations.includes(true) && !this.rowFilterValidations.some((error) => error.includes(true)) && !this.calculationsFilterValidations.some((error) => error.includes(true)))
      {
        const idtoken = store.getters.getIdToken;
        await this.updateArray()
        if(this.setMacro==true || this.setMacro=='true')
          {
              this.saveMacro()          
          }
        else{
          await axios
          .post(
            `${this.baseurl}/stats-python/programme/createprogram`,
            {
              "StudyId":this.studyInfo.studyId,
              "StudyName": this.studyInfo.studyName,
              "StudyRef": this.studyInfo.studyRef,
              "TocId": this.tocId,
              "ReportId": this.reportId,
              "ReportName": this.reportName,
              "TocDescription": "",
              "DeveloperName": this.developerName,
              "QCName":this.qcName,
              "Status": this.status,
              "MacroId": "",
              "SourceId": this.editoption=='1'? this.editData._id:"",
              "IsSelectedVersion": true,
              "ProgramName": this.programName,
              "FileName": this.fileName,
              "RowFilters": this.rowFilters,
              "ColumnFilters":
                this.columnFieldNames
              ,
              "ReportType": this.reportType,
              "SaveTo":"",
              "ChartType": this.chartType,
              "GroupBy": this.groupByField,
              "IncludeTotal": true,
              "NumericOperations": this.numericOperations,
              "PageGroup": this.pageGroup,
              "ReturnType": this.returnType,
              "Orientation": this.orientation,
              "Projection": "",
              "Sorting": {
              },
              "Table": this.reportType=='Table' ? [
                {  
                  "ProgramName": this.programName,
                  "RowFields": this.tableRowFieldNames,
                  "ColumnFields":this.tableColumnFieldNames,
                  "IncludeTotal": true,
                  "Title": this.tableTitle,
                  "Subtitle": this.tableSubtitle,
                  "Footnote":this.footnotes,
                  "CreatedTime": "2025-01-09T12:00:00Z"
                }
              ]:[]
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.loadingSaveBlock=false
            if(this.editoption=='0'){
              alert(this.$t('navigations.program created successfully'))
            } 
            if(this.editoption=='1'){
              alert(this.$t('navigations.program updated successfully'))
            }
            this.$router.push(`/tocgenerate`)
          })
          .catch((err) => {
            this.loadingSaveBlock = false
          }); 
        }
  
      }
    
    },
    async getGraphData() {
      this.loadingBlock = true
      await this.setValidations()
      if (!this.validations.includes(true) && !this.rowFilterValidations.some((error) => error.includes(true)) && !this.calculationsFilterValidations.some((error) => error.includes(true)))
        {
      const idtoken = store.getters.getIdToken;
      await this.updateArray()
      await axios
        .post(
          `${this.baseurl}/stats-python/macro/view`,
          {

            "ProgramName": this.programName,
            "FileName": this.fileName,
            "RowFilters": this.rowFilters,
            "ColumnFilters":
              this.columnFieldNames
            ,
            "ReportType": this.reportType,
            "GroupBy": this.groupByField,
            "IncludeTotal": true,
            "NumericOperations":this.numericOperations,
            "PageGroup": this.pageGroup,
            "ReturnType": this.returnType,
            "Orientation": this.orientation,
            "Projection": "",
            "ChartType":this.chartType,
            "Sorting": {
            },
            "Table": this.reportType=='Table' ? [
              {  
                "ProgramName": this.programName,
                "RowFields": this.tableRowFieldNames,
                "ColumnFields":this.tableColumnFieldNames,
                "IncludeTotal": true,
                "Title": this.tableTitle,
                "Subtitle": this.tableSubtitle,
                "Footnote":this.footnotes,
                "CreatedTime": "2025-01-09T12:00:00Z"
              }
            ]:[]
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.dataPages = res.data;
          this.dataPages.forEach((eachitem) => {
            eachitem.Value = this.hexToUtf8(eachitem.Value);
          })
          this.displayModal = true
          this.loadingBlock = false

        })
        .catch((err) => {
          this.loadingBlock = false
          this.validations = [false, false, false, false, false, false, false]

        });
      }
    },
    async exBtnClicked() {
      await this.getGraphData()
      await this.$nextTick();
      this.dataPages.forEach((eachitem,index) => {
         this.loadIframeContent(`${eachitem.Page}-${index}`, eachitem.Value)
      })
    },

    async getstudyinfo() {
      const idtoken = store.getters.getIdToken;
          await axios
        .get(
          `${this.baseurl}/management/study/getbyid/${store.getters.getStudyIs}?api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.studyInfo = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      },
    async getData(){
      const idtoken = store.getters.getIdToken;
      if(this.setMacro == true && this.editoption=='1'){
        await axios
        .get(
          `${this.baseurl}/stats-python/macro/macro/${this.id}`,
          
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          console.log("data ",res.data)
          this.editData=res.data
          this.studyInfo.studyId=this.editData.studyId!=''?this.editData.StudyId:''
          this.studyInfo.studyName=this.editData.StudyName!=''?this.editData.StudyName:''
          this.studyInfo.studyRef=this.editData.StudyRef!=''?this.editData.StudyRef:''
          this.tocId=this.editData.TocId!=''?this.editData.TocId:''
          this.reportId=this.editData.ReportId!=''?this.editData.ReportId:''
          this.reportName=this.editData.ReportName!=''?this.editData.ReportName:''
          this.developerName=this.editData.DeveloperName!=''?this.editData.DeveloperName:''
          this.qcName=this.editData.QCName!=''?this.editData.QCName:''
          this.status=this.editData.Status!=''?this.editData.Status:''
          this.programId=this.editData.ProgramId!=''?this.editData.ProgramId:''
          this.programName=this.editData.ProgramName!=''?this.editData.ProgramName:''
          this.fileName=this.editData.FileName!=''?this.editData.FileName:''
          this.rowFilters=this.editData.RowFilters!=''?this.editData.RowFilters:[]
          this.columnFieldNames=this.editData.ColumnFilters!=''?this.editData.ColumnFilters:[]
          this.reportType=this.editData.ReportType!=''?this.editData.ReportType:''
          this.groupByField=this.editData.GroupBy!=''?this.editData.GroupBy:''
          this.chartType=this.editData.ChartType!=''?this.editData.ChartType:''
          // this.editData.IncludeTotal
          this.numericOperations=this.editData.NumericOperations!=''?this.editData.NumericOperations:[]
          this.groupByField=this.editData.PageGroup!=''?this.editData.PageGroup:''
          this.returnType=this.editData.ReturnType!=''?this.editData.ReturnType:''
          this.orientation=this.editData.Orientation!=''?this.editData.Orientation:''
          if(this.fileName!=''){
            await this.getFields()
          }
          // if(this.editData.reportType=='Table'){
          //     this.programName=this.editData.ProgramName
          //     this.tableRowFieldNames=this.editData.
          //     this.tableColumnFieldNames=this.editData.
          //     this.columnTableFilters=this.editData.
          //     this.rowTableFilters=this.editData.
          //     this.tableGroupBy=this.editData.
          //     this.tablePageGroup=this.editData.
          //     this.returnType=this.editData.
          //     this.reportType=this.editData.
          //     this.orientation=this.editData.
          //     this.tableTitle=this.editData.
          //     this.tableSubtitle=this.editData.
          //     this.footnotes=this.editData.
          // }

        })
        .catch((err) => {
          this.loadingSaveBlock = false
        })
      }
      else if(this.setMacro == false && this.editoption=='1'){
        await axios
        .get(
          `${this.baseurl}/stats-python/programme/getprogrambyreportid/${this.id}`,
          
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          this.editData=res.data
          this.studyInfo.studyId=this.editData.studyId!=''?this.editData.StudyId:''
          this.studyInfo.studyName=this.editData.StudyName!=''?this.editData.StudyName:''
          this.studyInfo.studyRef=this.editData.StudyRef!=''?this.editData.StudyRef:''
          this.tocId=this.editData.TocId!=''?this.editData.TocId:''
          this.reportId=this.editData.ReportId!=''?this.editData.ReportId:''
          this.reportName=this.editData.ReportName!=''?this.editData.ReportName:''
          this.developerName=this.editData.DeveloperName!=''?this.editData.DeveloperName:''
          this.qcName=this.editData.QCName!=''?this.editData.QCName:''
          this.status=this.editData.Status!=''?this.editData.Status:''
          this.programId=this.editData.ProgramId!=''?this.editData.ProgramId:''
          this.programName=this.editData.ProgramName!=''?this.editData.ProgramName:''
          this.fileName=this.editData.FileName!=''?this.editData.FileName:''
          this.rowFilters=this.editData.RowFilters!=''?this.editData.RowFilters:[]
          this.columnFieldNames=this.editData.ColumnFilters!=''?this.editData.ColumnFilters:[]
          this.reportType=this.editData.ReportType!=''?this.editData.ReportType:''
          this.groupByField=this.editData.GroupBy!=''?this.editData.GroupBy:''
           this.chartType=this.editData.ChartType!=''?this.editData.ChartType:''
          // this.editData.IncludeTotal
          this.numericOperations=this.editData.NumericOperations!=''?this.editData.NumericOperations:[]
          this.groupByField=this.editData.PageGroup!=''?this.editData.PageGroup:''
          this.returnType=this.editData.ReturnType!=''?this.editData.ReturnType:''
          this.orientation=this.editData.Orientation!=''?this.editData.Orientation:''
          if(this.fileName!=''){
            await this.getFields()
          }
          // if(this.editData.reportType=='Table'){
          //     this.programName=this.editData.ProgramName
          //     this.tableRowFieldNames=this.editData.
          //     this.tableColumnFieldNames=this.editData.
          //     this.columnTableFilters=this.editData.
          //     this.rowTableFilters=this.editData.
          //     this.tableGroupBy=this.editData.
          //     this.tablePageGroup=this.editData.
          //     this.returnType=this.editData.
          //     this.reportType=this.editData.
          //     this.orientation=this.editData.
          //     this.tableTitle=this.editData.
          //     this.tableSubtitle=this.editData.
          //     this.footnotes=this.editData.
          // }

        })
        .catch((err) => {
          this.loadingSaveBlock = false
        })
      }
   
    },
    adjustIframeWidth(event,page) {
      const iframe = document.getElementById(page);
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

      if (iframeDocument && iframeDocument.body) {
        // Get the content's width
        const contentWidth = iframeDocument.body.scrollWidth;
        if(contentWidth>300){
          iframe.style.width = `${contentWidth}px`;
        }
        
      }
    },
    loadIframeContent(page, value) {
      const iframe = document.getElementById(page);
      const doc = iframe.contentDocument || iframe.contentWindow.document;
      doc.open();
      doc.write(value);
      doc.close();
    },
    hexToUtf8(hexString) {
      // Ensure the hex string is properly formatted by removing spaces and any extra characters
      hexString = hexString.replace(/\s+/g, '');

      // Convert each pair of hex digits to a character and join them into a string
      let utf8String = '';
      for (let i = 0; i < hexString.length; i += 2) {
        const hexCode = hexString.substr(i, 2);
        utf8String += String.fromCharCode(parseInt(hexCode, 16));
      }

      return utf8String;
    },
    async updateArray() {
      if (this.rowFilters.length > 0) {
        this.rowFilters.forEach((item) => {
          if (item.Value) {
            if (!isNaN(item.Value)) {
              if (item.Value && String(item.Value).trim() !== '') {
                item.Value = parseFloat(item.Value); // Parse to number
              }
            } else {
              item.Value = item.Value.toString(); // Keep as string
            }
          }
        })
      }
    },
    closeScreen(){
      if(this.setMacro==false){
        this.$router.push(`/tocgenerate`)
      }
      else{
        this.$router.push('/Macro_Management')
      }
      
    }
   
  }
}
