<template>
    <div class="study_profile_modal__wrapper">
      <div class="study_profile_modal__container">
        <div class="modal__title d-flex justify-content-between py-2 px-3">
          <h4 class="my-0">
            View Form
          </h4>

          <div
            class="tooltip-wrapper pointer"
            style="float: right; cursor: pointer"
          >
            <i class="fa fa-times" @click.prevent="closeModal"></i>
            <span class="custom-tooltip">{{ $t('navigations.close') }}</span>
          </div>
        </div>
        <div class="ibox-content p-0">
          <div class="modal-body setheightandwidth pb-0">
            <showForm :formsesid="formid" :detaileddescription="false" :isfieldvalidate=false :isqueryfield=false :requiredsubmit='false' closedoption='displayform' />
          </div>
        </div>
      </div>
    </div>
  
  </template>
  <script>
  import { useI18n } from "vue-i18n";
  export default {
    setup() {
      const { t, locale } = useI18n({ useScope: "global" });
      return { t, locale };
    },
    props: {
        formid: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        baseurl: process.env.VUE_APP_Service_URL,

      };
    },

    methods: {

      async closeModal() {
        this.$emit("closeformModal");
      },
    
    },
  };
  </script>
  
  <style scoped>
  @import "../../assets/style.css";
  @import "../../assets/formpage.css";
  @import "../../assets/savepages.css";
  .setheightandwidth{
    height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .study_profile_modal__wrapper {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 3000;
  }
  .study_profile_modal__container {
    position: fixed;
    width: min(60%, 90%);
    border-radius: 4px;
    background: #fff;
    overflow-x: hidden;
  }
  .modal__title {
    background-color: var(--pop-up-background);
    color: white;
  }
  
  .cancel_btn {
    padding: 0.43rem 0.4rem;
    border-radius: 0.25rem;
  }
  .import-popup {
    position: relative;
  }
  .import-popup .tool-tip {
    visibility: hidden;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background-color: #616161;
    color: #fff;
    text-align: center;
    border-radius: 0.3em;
    padding: 0.3rem 0.8rem;
    right: 0;
    top: -40px;
    font-size: 0.8rem;
    position: absolute;
    z-index: 1;
    transition: visibility 75ms ease-in;
  }
  
  .import-popup:hover .tool-tip,
  .import-popup:focus .tool-tip {
    visibility: visible;
    -webkit-transition: visibility 30ms ease-in;
    transition: visibility 30ms ease-in;
  }
  .custom-popup {
    text-align: center;
  }
  
  .simple-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 33px; /* Adjust the padding to increase the size */
    width: 29%; /* Adjust the width as needed */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 4000; /* Increase the z-index value if needed */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .simple-wrapper {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 3000;
  }
  .multiselect-template-theme {
    --ms-option-bg-selected-pointed: rgb(210, 210, 210);
    --ms-option-bg-selected: rgb(210, 210, 210);
    --ms-option-color-selected: #000;
    --ms-option-color-selected-pointed: #000;
    --ms-radius: 0;
    --ms-line-height: 0.8;
    --ms-option-font-size: 0.8rem;
    --ms-font-size: 0.8rem;
  }
  .tooltip-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .custom-tooltip {
    visibility: hidden;
    width: 60px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: -18%; /* Position the tooltip above the icon */
    left: -375%;
    margin-left: -30px; /* Use half of the width to center-align */
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
  }
  
  .tooltip-wrapper:hover .custom-tooltip {
    visibility: visible;
    opacity: 1;
  }
  </style>