import axios from "axios";
  import store from "../../store/index";
  import moment from "moment";
  import VPagination from "@hennge/vue3-pagination";
  import "@hennge/vue3-pagination/dist/vue3-pagination.css";

  export default {
    name: "Macro_Management",
    components: {
      VPagination,
    },
    data() {
      return {
       
        baseurl: process.env.VUE_APP_Service_URL,
       
  
        enableNext: true,
        enablePrevious: true,
  
        currentPageSize: 0,
        currentPageNumber: 1,
       
        totalIteems: 0,
        totalPages: 0,
  
        searchData: {
         searchMacroName:"",
          pageSize: 0,
          pageNumber: 0,
        },
  
        requestData: {
          pageSize: 0,
          pageNumber: 0,
          
        },
        
        sortDesc: "",
        SortOrder: 0,
        SortProperty: "firstName",
        MacroList: [],
      };
    },
    async mounted() {
      this.defaultdate = localStorage.getItem("dateformat");
      await this.GetMacroList();    
      this.filter();
    },
    methods: {
      async gotTo(type,id){
        if(type=='stats'){
          this.$router.push(`/stats/0/0`)
        }
        else if(type=='delete'){
          const idtoken = store.getters.getIdToken;
          if(confirm(this.$t('navigations.are you sure you want to delete this macro')))
          await axios
            .delete(
              `${this.baseurl}/stats-python/macro/deletemacro/${id}`,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              console.log(res);
              alert(this.$t('navigations.macro deleted succesfully'))
              this.GetMacroList();    
            })
            .catch((err) => {
              alert("Network error")
              console.log(err);
            });
        }
        else {
          this.$router.push(`/stats/${id}/1`)
        }
      },
      filter(data) {
          if (data) {
            return moment(data).format(this.defaultdate);
          }
          console.log("Reverse is");
          console.log(data);
        },
      // async searchAndGetData() {
      //   const idtoken = store.getters.getIdToken;
      //   if (this.activeValue === "TRUE") {
      //     this.requestData.searchActive = true;
      //   } else if (this.activeValue === "FALSE") {
      //     this.requestData.searchActive = false;
      //   } else {
      //     this.requestData.searchActive = null;
      //   }
      //   if (this.lockedValue === "TRUE") {
      //     this.requestData.searchLocked = true;
      //   } else if (this.lockedValue === "FALSE") {
      //     this.requestData.searchLocked = false;
      //   } else {
      //     this.requestData.searchLocked = null;
      //   }
      //   if (this.requestData.searchRole === "All") {
      //     this.requestData.searchRole = "";
      //   }
      //   console.log(this.requestData);
      //   console.log("users listed");
      //   await axios
      //     .get(
      //       `${this.baseurl}/account-core/user/userlist?Key=${this.requestData.searchEmail}&Role=${this.requestData.searchRole}&pageindex=${this.requestData.pageNumber}&pagesize=10&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}`,
      //       {
      //         headers: {
      //           Authorization: "Bearer " + idtoken,
      //           "Content-Type": "application/json",
      //         },
      //       }
      //     )
      //     .then((res) => {
      //       console.log(res);
      //       if (res.data.data === "No User Found.") {
      //         alert("No Users Were Found.");
      //         this.userList = [];
      //         this.enableNext = false;
      //       } else {
      //         console.log("users data");
      //         this.userList = res.data.results;
      //         console.log(res.data.results);
      //         this.totalIteems = res.data.totalCount;
      //         this.totalPages =
      //           Math.trunc(this.totalIteems / 10) < 1
      //             ? 1
      //             : this.totalIteems / 10 > Math.trunc(this.totalIteems / 10)
      //             ? Math.trunc(this.totalIteems / 10) + 1
      //             : Math.trunc(this.totalIteems / 10);
      //         this.enableNext = this.currentPageNumber !== this.totalPages;
      //         this.enablePrevious = this.currentPageNumber !== 1;
      //       }
      //       this.totalIteems = res.data.totalCount;
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.enableNext = false;
      //       this.enablePrevious = false;
      //     });
      // },


    async GetMacroList() {
        const idtoken = store.getters.getIdToken;
        await axios
          .get(
            `${this.baseurl}/stats-python/macro/macros`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.MacroList = res.data;
          })
          .catch((err) => {
            console.log(err);
            this.enableNext = false;
            this.enablePrevious = false;
          });
    },
    SearchMacroByName() {
      const idtoken = store.getters.getIdToken;
      axios
        .get(
          `${this.baseurl}/stats-python/macro/macros?Name=${this.searchData.searchMacroName}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.MacroList = res.data;
          console.log("Searched MacroList:", this.MacroList);
        })
        .catch((err) => {
          console.error("Search Macro error:", err);
        });
    }
    
    },
  };