<template>
    <div>
      <!-- Cytoscape container -->
      <div ref="chartContainerBlock" id="cy"></div>
  
      <!-- Popup menu for node actions -->
      <div
        v-if="showNodePopup"
        :style="popupStyle"
        class="popup-menu"
        @mouseleave="hidePopupMenu"
      >
        <ul>
          <li v-if="menuitems.includes('expandNode')" @click="expandNode">Expand Node</li>
          <li v-if="menuitems.includes('collapseNode')" @click="collapseNode">Collapse Node</li>
          <li v-if="menuitems.includes('addSubNode')" @click="addSubNode">Add Node</li>
         <!-- <li @click="addArray">Add Array</li>
          <li @click="addArrayelements">Add Array elements</li>
          <li @click="addproperties">Add properties</li> -->
          <li v-if="menuitems.includes('addNewVisit')" @click="addNewVisit">Add New Visit</li>
          <li v-if="menuitems.includes('deleteNode')" @click="deleteNode">Delete Node</li>
          <li v-if="menuitems.includes('showdetails')" @click="showdetails">Show Details</li>
          <li v-if="menuitems.includes('showproperties')" @click="showproperties">Show Details</li>
          <!-- <li v-if="menuitems.includes('showformmodel')" @click="showformmodel">Show Form</li> -->
          <li
  v-if="menuitems.includes('showformmodel') && selectedNode && selectedNode._private && selectedNode._private.data && selectedNode._private.data.properties && selectedNode._private.data.properties.FormId && selectedNode._private.data.properties.Type === 'Form' && selectedpatientid!=''"
  @click="showformmodel"
>
  Show Form
</li>
<li
  v-if="menuitems.includes('gotosubjectgraph') && selectedNode && selectedNode._private && selectedNode._private.data && selectedNode._private.data.properties && selectedNode._private.data.properties.SubjectId "
  @click="showsubjectgraph"
>
  Go To Subject
</li>
<li
  v-if="menuitems.includes('gotositegraph') && selectedNode && selectedNode._private && selectedNode._private.data && selectedNode._private.data.properties && selectedNode._private.data.properties.SiteId "
  @click="showsitegraph"
>
  Go To Site
</li>
<li
  v-if="menuitems.includes('changevisitstatus') && selectedNode && selectedNode._private && selectedNode._private.data && selectedNode._private.data.properties && selectedNode._private.data.properties.Type === 'Visit'  && selectedpatientid!=''"
  @click="changevisitstatusfun"
>
  Change Status
</li>
          
        </ul>
      </div>
  
      <!-- Add Arrayelement Modal -->
      <div v-if="arrayelementspopup" class="modal-wrapper is-visible">
        <div class="modal-container">
          <div class="titleclass">
            <h4 class="px-3 mt-1">Add Array Element</h4>
            <div class="closebutton mt-1">
              <a style="color: white" class="close-link">
                <i class="fa fa-times" @click.prevent="closeaddarrayelementspopup"></i>
              </a>
            </div>
          </div>
          <div class="modelbody p-0">
            <div class="mb-3 p-2">
              <div class="form-group">
                <div class="d-flex flex-column flex-lg-row">
                  <div class="col-lg-12 my-2">
                    <label for="propname">New array element name</label>
                    <input type="text" class="form-control" v-model="arrayelementname" id="propname" />
                  </div>
                </div>
                <div class="d-flex justify-content-center my-3">
                  <button class="save_btn py-1 px-4">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Add Array Modal -->
      <div v-if="addarraypopup" class="modal-wrapper is-visible">
        <div class="modal-container">
          <div class="titleclass">
            <h4 class="px-3 mt-1">Add Array</h4>
            <div class="closebutton mt-1">
              <a style="color: white" class="close-link">
                <i class="fa fa-times" @click.prevent="closeaddarraypopup"></i>
              </a>
            </div>
          </div>
          <div class="modelbody p-0">
            <div class="mb-3 p-2">
              <div class="form-group">
                <div class="d-flex flex-column flex-lg-row">
                  <div class="col-lg-12 my-2">
                    <label for="propname">New Node name</label>
                    <input type="text" class="form-control" v-model="subnodearrayname" id="propname" />
                  </div>
                </div>
                <div class="d-flex justify-content-center my-3">
                  <button class="save_btn py-1 px-4">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Add node Modal -->
      <div v-if="addnodepopup" class="modal-wrapper is-visible">
        <div class="modal-container">
          <div class="titleclass">
            <h4 class="px-3 mt-1">Add Node</h4>
            <div class="closebutton mt-1">
              <a style="color: white" class="close-link">
                <i class="fa fa-times" @click.prevent="closeaddnodepopup"></i>
              </a>
            </div>
          </div>
          <div class="modelbody p-0">
            <div class="mb-3 p-2">
              <div class="form-group">
                <div class="d-flex flex-column flex-lg-row">
                  <div class="col-lg-12 my-2">
                    <label for="propname">New Node name</label>
                    <input type="text" class="form-control" v-model="subnodename" id="propname" />
                  </div>
                </div>
                <div class="d-flex justify-content-center my-3">
                  <button class="save_btn py-1 px-4" @click.prevent="addsubnodecall()">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- create graph Modal -->
      <div v-if="creategraphpopup" class="modal-wrapper is-visible">
        <div class="modal-container">
          <div class="titleclass">
            <h4 class="px-3 mt-1">Create Graph</h4>
            <div class="closebutton mt-1">
              <a style="color: white" class="close-link">
                <i class="fa fa-times" @click.prevent="closecreategraph"></i>
              </a>
            </div>
          </div>
          <div class="modelbody p-0">
            <div class="mb-3 p-2">
              <div class="form-group">
                <div class="d-flex flex-column flex-lg-row">
                  <div class="col-lg-12 my-2">
                    <label for="propname">Graph name</label>
                    <input type="text" class="form-control" v-model="newgraphname" id="propname" />
                  </div>
                </div>
                <div class="d-flex justify-content-center my-3">
                  <button class="save_btn py-1 px-4">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Popup Details Modal -->
      <div v-if="detailspopup" class="modal-wrapper is-visible">
        <div class="modal-container">
          <div class="titleclass">
            <h4 class="px-3 mt-1">Details</h4>
            <div class="closebutton mt-1">
              <a style="color: white" class="close-link">
                <i class="fa fa-times" @click.prevent="closedetailsModal"></i>
              </a>
            </div>
          </div>
          <div class="modelbody p-0">
            <div class="mb-3 p-2">
              <div class="form-group">
                <div class="d-flex flex-column flex-lg-row">
                  <div class="col-lg-12 my-2">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Key</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(value, key) in nodeproperties.data" :key="key">
                          <td>{{ key }}</td>
                          <td>{{ value }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="d-flex justify-content-center my-3">
                  <button class="save_btn py-1 px-4" @click="closedetailsModal">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Popup Add properties Modal -->
      <div v-if="addpropertiespopup" class="modal-wrapper is-visible">
        <div class="modal-container">
          <div class="titleclass">
            <h4 class="px-3 mt-1">Add properties</h4>
            <div class="closebutton mt-1">
              <a style="color: white" class="close-link">
                <i class="fa fa-times" @click.prevent="cloaseaddproperties"></i>
              </a>
            </div>
          </div>
          <div class="modelbody p-0">
            <div class="mb-3 p-2">
              <div class="form-group">
                <div class="d-flex flex-column flex-lg-row">
                  <div class="col-lg-12 my-2">
                    <div class="form-group">
                      <label for="propname">Property name</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="propname"
                        id="propname"
                      />
                    </div>
                    <div class="form-group">
                      <label for="propvalue">Property value</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="propvalue"
                        id="propvalue"
                      />
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center my-3">
                  <button class="save_btn py-1 px-4">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  <script>
  import axios from "axios";
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre"; // Import the Dagre extension
import store from "@/store";
import 'qtip2/dist/jquery.qtip.css';
import 'qtip2';
import klay from "cytoscape-klay";
import qtip from "cytoscape-qtip";
cytoscape.use(qtip);
cytoscape.use(dagre); // Register Dagre with Cytoscape
cytoscape.use(klay);

export default {
  name: "NetworkXGraph",
  props: {
    selectedgraphid: {
      type: String,
      default: ""
    },
    selectedpatientid: {
      type: String,
      default: ""
    },
    graphdata: {
      type: Object,
      default: () => ({}),
    },
    selectedLayout: {
      type: String,
      default: "cose"
    },
    menuitems: {
    type: Array,
    default: () => ["expandNode", "collapseNode", "deleteNode", "showdetails"]
  },
  collapsedtypes: {
    type: Array,
    default: () => []
  },
  executeParentFunction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      cy: null,
      authToken: store.getters.getIdToken,
      rootId: null,
      templatedetails: [],
      // Default layout is "dagre"
      baseurl: process.env.VUE_APP_Service_URL,
      // For popup menu handling:
      showNodePopup: false,
      popupPosition: { x: 0, y: 0 },
      selectedNode: null,
      nodeproperties: null,
      detailspopup: false,
      addpropertiespopup: false,
      propname: "",
      propvalue: "",
      creategraphpopup: false,
      newgraphname: "",
      addnodepopup: false,
      subnodename: "",
      addarraypopup: false,
      subnodearrayname: "",
      arrayelementspopup: false,
      arrayelementname: "",
      mouseX: 0,
      mouseY: 0,
      selectedorientation:"TB",
      graphshapes:{
        "Rectangle": "rectangle",
        "Ellipse": "ellipse",
        "Diamond": "diamond",
        "Octagon": "octagon",
        "Round-rectangle": "round-rectangle",
        "Round-tag": "round-tag"
      },
      unwantedproperties:["Color", "FormId", "SiteId", "SubjectId", "Shapes"]
    };
  },
  computed: {
    popupStyle() {
      return {
        position: "absolute",
        top: this.mouseY-5 + "px",
        left: this.mouseX+10 + "px",
        zIndex: 9999,
        background: "#fff",
        border: "1px solid #ccc",
        padding: "5px",
      };
    },
  },
  watch: {
    graphdata: {
        handler() {
          console.log("graphdata",this.graphdata);
          this.loadGraph();
        },
        immediate: true,
        deep: true,
      },
      selectedLayout() {
        this.loadGraph();
        this.applyLayout();
      },
    },
    beforeUnmount() {
      window.removeEventListener("mousemove", this.updateMousePosition);
    },
  async mounted() {
    window.addEventListener("mousemove", this.updateMousePosition);
  },
  methods: {
    selectorientationfun(neworientation){
      this.selectedorientation = neworientation;
      this.applyLayout();
    },
    zoomIn() {
    if (this.cy) {
      const currentZoom = this.cy.zoom();
      // Increase zoom by 20%
      this.cy.zoom(currentZoom * 1.2);
    }
  },
  zoomOut() {
    if (this.cy) {
      const currentZoom = this.cy.zoom();
      // Decrease zoom by 20%
      this.cy.zoom(currentZoom * 0.8);
    }
  },
    updateMousePosition(event) {
      // Get the bounding rectangle of the component
      const rect = this.$refs.chartContainerBlock.getBoundingClientRect();
      // Calculate the mouse X and Y positions relative to the component
      if(!this.showNodePopup){
      this.mouseX = event.clientX - rect.left;
      this.mouseY = event.clientY - rect.top;
      }
    },
    async addNewVisit(){
      if(this.selectedNode._private&&this.selectedNode._private.data&&this.selectedNode._private.data.properties){
      let currentvisitdata = {...this.selectedNode._private.data};
      this.$emit('uncheduledvisit', currentvisitdata);
    }
    },
    async deleteNode() {
      this.$emit('deleteNode', this.selectedNode);
    },
    async showdetails() {
      this.$emit('showdetails', this.selectedNode);
    },
    async changevisitstatusfun(){
      if (
    this.selectedNode._private &&
    this.selectedNode._private.data &&
    this.selectedNode._private.data.properties
  ) {
    let properties = { ...this.selectedNode._private.data.properties };
    console.log("properties",properties);
    let changeStatus = {
        "visitNo" : properties.VisitNo,
        "status" : properties.VisitStatus
      };
      console.log("changeStatus",changeStatus);
    this.$emit("changevisitstatusmodel", changeStatus);
  }
    },
    async showsubjectgraph(){
      if (
    this.selectedNode._private &&
    this.selectedNode._private.data &&
    this.selectedNode._private.data.properties
  ) {
    let properties = { ...this.selectedNode._private.data.properties };
    // Use a safe call to check for "Color"
    if (Object.prototype.hasOwnProperty.call(properties, "Color")) {
      delete properties.Color;
    }
    this.$emit("showsubjectgraph", properties);
  }
    },
    async showsitegraph(){
      if (
    this.selectedNode._private &&
    this.selectedNode._private.data &&
    this.selectedNode._private.data.properties
  ) {
    let properties = { ...this.selectedNode._private.data.properties };
    // Use a safe call to check for "Color"
    if (Object.prototype.hasOwnProperty.call(properties, "Color")) {
      delete properties.Color;
    }
    this.$emit("showsitegraph", properties);
  }
  },
    async showformmodel(){
      if (
    this.selectedNode._private &&
    this.selectedNode._private.data &&
    this.selectedNode._private.data.properties
  ) {
    let properties = { ...this.selectedNode._private.data.properties };
    // Use a safe call to check for "Color"
    if (Object.prototype.hasOwnProperty.call(properties, "Color")) {
      delete properties.Color;
    }
    this.$emit("showformmodel", properties);
  }
    },
    async showproperties() {
  if (
    this.selectedNode._private &&
    this.selectedNode._private.data &&
    this.selectedNode._private.data.properties
  ) {
    let properties = { ...this.selectedNode._private.data.properties };
    // Use a safe call to check for "Color"
    this.unwantedproperties.forEach((eachunwantedproperties)=>{
      if (Object.prototype.hasOwnProperty.call(properties, eachunwantedproperties)) {
      delete properties[eachunwantedproperties];
    }
    })
    this.$emit("showproperties", properties);
  }
},
computeNodeShape(node) {
    if (node.properties) {
      if (node.properties.Shapes) {
        return node.properties.Shapes;
      }
          return "ellipse";

    }
    return "ellipse";
  },
        async expandNode() {
  this.showNodePopup = false;

  if (this.selectedNode.data('expanded')) {
    return;
  }

  const selectedId = this.selectedNode.id();

  // Find child links and nodes
  const childLinks = this.graphdata.links.filter(link => link.source === selectedId);
  const childNodeIds = childLinks.map(link => link.target);
  const childNodes = this.graphdata.nodes.filter(node => childNodeIds.includes(node.id));

  // Prepare new elements to add
  const newElements = [];
  childNodes.forEach(node => {
    if (this.cy.getElementById(node.id).empty()) {
      let color = "#0074D9"; // default color
      if (node.properties && node.properties.Color && this.isValidCssColor(node.properties.Color)) {
        color = node.properties.Color;
      }
      newElements.push({
        data: {
          id: node.id,
          label: node.label || node.data || node.id,
          ...node,
          shape: this.computeNodeShape(node),
          color: color,
        },
      });
    }
  });
  childLinks.forEach(link => {
    const linkId = link.id || `${link.source}-${link.target}`;
    if (this.cy.getElementById(linkId).empty()) {
      newElements.push({
        data: {
          id: linkId,
          source: link.source,
          target: link.target,
          label: link.label || "",
        },
      });
    }
  });

  // Add the new elements
  this.cy.add(newElements);
  this.selectedNode.data('expanded', true);

  // Re-run layout
  this.applyLayout();

  // Reinitialize tooltips on any new nodes
  this.initializeTooltips();
},
hideAllTooltips() {
  if (this.cy) {
    this.cy.nodes().forEach((node) => {
      const api = node.qtip('api');
      if (api) {
        // To just hide:
        api.destroy();
        // Or, if you want to remove them completely, use:
        // api.destroy();
      }
    });
  }
},
initializeTooltips() {
  this.cy.nodes().forEach((node) => {
    if (!node.scratch('qtipInitialized')) {
      // Build tooltip text: start with the node label.
      let tooltipText = node.data('label');

      node.qtip({
        content: { text: tooltipText },
        show: { event: 'mouseover' },
        // Update the hide event to also include "click" so it hides on mouse click.
        hide: { event: 'mouseout click' },
        position: {
          my: 'top center',
          at: 'bottom center'
        },
        style: {
          classes: 'qtip-bootstrap',
          tip: {
            width: 16,
            height: 8
          }
        }
      });
      node.scratch('qtipInitialized', true);
    }
  });
},
    async addsubnodecall(){
      this.$emit('newnodecreate', this.subnodename, this.selectedNode);
      this.closeaddnodepopup();
    },
    async createGraph() {
      this.creategraphpopup = true;
    },
    async closecreategraph() {
      this.creategraphpopup = false;
    },

    async addproperties() {
      this.addpropertiespopup = true;
    },
    async cloaseaddproperties() {
      this.addpropertiespopup = false;
    },


    closedetailsModal() {
      this.detailspopup = false;
    },
    async loadGraph() {
    this.hideAllTooltips();
  const elements = this.convertNodeLinkToCytoscape(this.graphdata);
  this.rootId =
    (this.graphdata.graphdata && this.graphdata.graphdata._id) ||
    (elements[0] && elements[0].data.id);

  this.cy = cytoscape({
    container: document.getElementById("cy"),
    elements: elements,
    // layout: this.getLayoutConfig(),
    layout: { name: "preset" }, // Do nothing initially
    wheelSensitivity: 0.2,
    style: [
      {
        selector: "node",
        style: {
          // shape: "ellipse",
          shape: "data(shape)",
          label: "data(label)",
          "background-color": "data(color)",
          "text-valign": "center",
          "text-halign": "center",
          "color": "black",  
          // "text-outline-color": "black",  
          // "text-outline-width": "1.5px",     
          "text-wrap": "wrap",
          "text-max-width": "80px",
          width: 120,
          height: 120,
          "font-size": "16px",
        },
      },

      {
        selector: "edge",
        style: {
          "line-color": "#999",
          "target-arrow-color": "#999",
          "target-arrow-shape": "triangle",
          "curve-style": "bezier",
        },
      },
    ],
  });
  this.$nextTick(() => {
  this.applyLayout(); // Apply the actual layout now that everything is ready
});
  // Initialize tooltips on all nodes.
  this.initializeTooltips();

  // Other event handlers…
  this.cy.on("tap", "node", (event) => {
    const node = event.target;
    this.selectedNode = node;
    const clickEvent = event.originalEvent;
    this.popupPosition = {
      x: clickEvent.clientX + 10,
      y: clickEvent.clientY + 10,
    };
    this.showNodePopup = true;
  });

  this.cy.on("tap", (event) => {
    if (event.target === this.cy) {
      this.showNodePopup = false;
    }
  });

  this.cy.on("zoom pan", () => {
    if (this.showNodePopup) {
      this.showNodePopup = false;
    }
  });
},
    async isValidCssColor(strColor) {
      const s = new Option().style;
      s.color = strColor;
      // If the browser recognized the color, it will normalize it to a valid string.
      return s.color === strColor.toLowerCase();
    },
    /**
     * Convert NetworkX node-link JSON into Cytoscape elements.
     */
     convertNodeLinkToCytoscape(data) {
  // Check that both nodes and links exist
  if (!data || !data.nodes || !data.links) {
    console.error("Invalid graph data provided", data);
    return [];
  }

  const elements = [];
  const hiddenNodes = new Set();
  const collapsedTypes = this.collapsedtypes; // e.g., ["Visit"]

  // Build a lookup for nodes by id.
  const nodeMap = {};
  data.nodes.forEach(node => {
    nodeMap[node.id] = node;
  });

  // Mark nodes hidden if the source node is of a collapsed type.
  data.links.forEach(link => {
    const sourceNode = nodeMap[link.source];
    if (!sourceNode) return; // Ensure the node exists
    const sourceType = sourceNode.type || (sourceNode.properties && sourceNode.properties.Type);
    if (sourceType && collapsedTypes.includes(sourceType)) {
      hiddenNodes.add(link.target);
    }
  });

  // Process nodes that should be visible.
  data.nodes.forEach((node) => {
    const nodeType = node.type || (node.properties && node.properties.Type);
    // Skip the node if hidden by a collapsed parent (unless it itself is collapsed).
    if (hiddenNodes.has(node.id) && !(nodeType && collapsedTypes.includes(nodeType))) {
      return;
    }
    // Set expanded state based on collapsed types.
    let expanded = node.expanded || false;
    if (nodeType && collapsedTypes.includes(nodeType)) {
      expanded = false;
    }
    let collapsible = node.collapsible || false;
    let color = "#0074D9"; // default color
    if (node.properties && node.properties.Color && this.isValidCssColor(node.properties.Color)) {
      color = node.properties.Color;
    }
    // If the node id is a UUID, make it non-collapsible.
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    if (uuidRegex.test(node.id)) {
      collapsible = false;
    }
    // Build the node label and append Status if available.
    let nodeLabel = node.label || node.data || node.id;
    if (node.properties && node.properties.Status) {
      nodeLabel += " - " + node.properties.Status;
    }
    if (node.properties && node.properties.VisitStatus) {
      nodeLabel += " - " + node.properties.VisitStatus;
    }
    
    // Use the spread operator first then override the label.
    elements.push({
      data: {
        id: node.id,
        collapsible: collapsible,
        expanded: expanded,
        type: node.properties ? node.properties.Type : "",
        shape: node.properties ? node.properties.Shapes : "ellipse",
        color: color,
        ...node,
        label: nodeLabel  // This ensures our computed label (with Status) remains
      },
    });
  });

  // Process links if both the source and target nodes exist and are visible.
  data.links.forEach((link) => {
    const sourceNode = nodeMap[link.source];
    const targetNode = nodeMap[link.target];
    if (!sourceNode || !targetNode) return;
    const sourceType = sourceNode.type || (sourceNode.properties && sourceNode.properties.Type);
    const targetType = targetNode.type || (targetNode.properties && targetNode.properties.Type);
    const sourceHidden = hiddenNodes.has(link.source) && !(sourceType && collapsedTypes.includes(sourceType));
    const targetHidden = hiddenNodes.has(link.target) && !(targetType && collapsedTypes.includes(targetType));
    if (sourceHidden || targetHidden) return;
    const linkId = link.id || `${link.source}-${link.target}`;
    elements.push({
      data: {
        id: linkId,
        source: link.source,
        target: link.target,
        label: link.label || "",
      },
    });
  });

  return elements;
},

    // Hide the popup menu.
    hidePopupMenu() {
      this.showNodePopup = false;
    },


    // Collapse the selected node by recursively removing all descendant nodes.
    collapseNode() {
  if (!this.selectedNode) return;

  const removeDescendants = (node) => {
    const children = node.outgoers("node");
    children.forEach((child) => {
      // Check if the child node's type is in collapsedtypes.
      const childType = child.data("type") || (child.data("properties") && child.data("properties").Type);
      if (childType && this.collapsedtypes.includes(childType)) {
        // Skip removal: this node should always be visible.
        return;
      }
      // Recursively remove descendant nodes.
      removeDescendants(child);
      child.remove();
    });
  };

  removeDescendants(this.selectedNode);
  this.selectedNode.data("expanded", false);

  // Re-run the layout using the current layout.
  this.cy.layout(this.getLayoutConfig()).run();
  this.hidePopupMenu();
},

    // --- NEW METHOD: Sort all subnodes at every level ---
    sortAllNodes() {
      if (!this.cy || !this.rootId) {
        console.warn("Cytoscape or rootId not set yet.");
        return;
      }
    
      // Example: using breadthfirst layout for sorting; you can adjust as needed.
      this.cy.layout({
        name: "breadthfirst",
        roots: [this.rootId],
        directed: false,
        fit: true,
        circle: false,
        spacingFactor: 1.2,
        padding: 30,
        sort: (a, b) => {
          const aLabel = String(a.data("label")).toLowerCase();
          const bLabel = String(b.data("label")).toLowerCase();
          if (!isNaN(aLabel) && !isNaN(bLabel)) {
            return parseFloat(aLabel) - parseFloat(bLabel);
          }
          return aLabel.localeCompare(bLabel);
        },
        animate: true,
        animationDuration: 500
      }).run();
      
      this.hidePopupMenu();
    },

    addArrayelements() {
      this.arrayelementspopup = true;
    },
    closeaddarrayelementspopup() {
      this.arrayelementspopup = false;
    },

    addArray() {
      this.addarraypopup = true;
      this.hidePopupMenu();
    },
    closeaddarraypopup() {
      this.addarraypopup = false;
    },

    addSubNode() {
      this.addnodepopup = true;
      this.hidePopupMenu();
    },
    closeaddnodepopup() {
      this.addnodepopup = false;
    },

    // --- NEW HELPER: Return layout configuration based on selected layout ---
    getLayoutConfig() {
  let layoutConfig = {};
  const orientation = this.selectedorientation; // e.g., "TB" or "LR"
  
  switch (this.selectedLayout) {
    case "dagre":
      layoutConfig = {
        name: "dagre",
        rankDir: orientation,
        nodeSep: 50,
        rankSep: 100,
        edgeSep: 10,
        ranker: "longest-path",
        animate: true,
        animationDuration: 500,
      };
      break;
    case "breadthfirst":
      layoutConfig = {
        name: "breadthfirst",
        roots: [this.rootId],
        rankDir: orientation,
        directed: false,
        fit: true,
        circle: false,
        spacingFactor: 1.2,
        padding: 30,
        animate: true,
        animationDuration: 500,
      };
      break;
      case "grid":
        layoutConfig = {
          name: "grid",
          // Only count nodes (not edges)
          rows: Math.ceil(Math.sqrt(this.cy ? this.cy.nodes().length : 1)),
          animate: true,
          animationDuration: 500,
        };
        break;
      case "circle":
          layoutConfig = {
            name: "circle",
            animate: true,
            animationDuration: 500,
          };
          break;
          case "concentric":
          layoutConfig = {
            name: "concentric",
            animate: true,
            animationDuration: 500,
          };
          break;
        case "cose":
          layoutConfig = {
            name: "cose",
            animate: true,
            animationDuration: 500,
          };
          break;
          case "klay":
      {
      let klayDirection = "DOWN";
      if (this.selectedorientation === "LR") {
        klayDirection = "RIGHT";
      }
      layoutConfig = {
        name: "klay",
        nodeDimensionsIncludeLabels: true, // so the layout considers label size
        fit: true,
        padding: 20,
        klay: {
          direction: klayDirection,
          spacing: 40,
        },
        animate: true,
        animationDuration: 500,
      };
    }
      break;
    default:
      layoutConfig = {
        name: "dagre",
        rankDir: orientation,
        nodeSep: 50,
        rankSep: 100,
        edgeSep: 10,
        ranker: "longest-path",
        animate: true,
        animationDuration: 500,
      };
      break;
  }
  return layoutConfig;
},
    getContrastYIQ(hexcolor) {
  // Remove the '#' if it exists.
  hexcolor = hexcolor.replace("#", "");
  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  // Compute brightness using the YIQ formula.
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
},
    // --- NEW METHOD: Apply the current layout to the graph ---
    applyLayout() {
      if (this.cy) {
        this.cy.layout(this.getLayoutConfig()).run();
        // if (this.selectedLayout === "klay") {
        //   this.cy.nodes().style("shape", "rectangle");
        // } else {
        //   // For all other layouts, use the default circle (ellipse) shape.
        //   this.cy.nodes().style("shape", "ellipse");
        // }
      }
    },
  },
};

</script>
<style scoped>
@import "../../assets/css/style.css";

#cy {
  width: 100%;
  height: 600px;
  border: 1px solid #ddd;
}

.popup-menu {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.popup-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup-menu li {
  padding: 3px 8px;
  cursor: pointer;
}

.popup-menu li:hover {
  background: #f0f0f0;
}

.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  pointer-events: auto;
  overflow: hidden;
  z-index: 9999;
}

.modal-container {
  background: #fff;
  width: 80%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.is-visible {
  opacity: 1;
  pointer-events: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.table th,
.table td {
  padding: 8px;
  border: 1px solid #ddd;
}

.table th {
  background-color: #f4f4f9;
  font-weight: bold;
}

.table-striped tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}
</style>

  