import { type } from "jquery";
import store from "../../store/index";
import axios from "axios";

export default {
    name: "ChangescheduleStatusPopup",
    props: { 
        changeStatus: {type: Object}

    },
    data() {
    return{
        idToken : "",
        headerValues:"",
        baseapi: process.env.VUE_APP_Service_URL,
        baseUrl: `${process.env.VUE_APP_Service_URL}/management/patientschedule/`,
        statusesList: [],
    };
    },
    async mounted(){
        this.idToken = store.getters.getIdToken;
        this.headerValues = {
            headers: {
                Authorization: "Bearer " + this.idToken,
                "Content-Type": "application/json",
            },
        };
        await this.listScheduleStatuses();
    },
    methods: {
        async closeChangeScheduleModal(){
            this.$emit("closeModal");
        },
        async listScheduleStatuses(){
            await axios.get(`${this.baseapi}/management/statusmaster/getall?StatusType=Visit`, this.headerValues
            ).then((res) => {
              console.log(" visit schedule statuses are ", res.data);
              this.statusesList = res.data;
            }).catch(err => console.log("error getting schedule statuses"))
        },
        async changeScheduleStatus(){
            if (!this.changeStatus.status) {
              alert(this.$t('navigations.please select a status'))
              return;
            }
            await axios.put(`${this.baseUrl}updateschedulestatus`, this.changeStatus, this.headerValues
                ).then((res) => {
                    console.log("Updated schedule status")
                    alert("Status updated successfully")
                    this.$emit("closeModal")
                }).catch( err => console.log("error updating schedule sttus", err));
        },
        closemodal(){
            this.$emit("closeModal")
        }
        
    },
}