<template>
    <!-- Navbar Section -->
    <div class="row border-bottom white-bg">
    <nav class="navbar navbar-static-top" role="navigation">
      <div class="landing-nav-header"><img src="../../assets/img/IDSLogo.png" alt="Logo" />
      </div >
            <ul class="d-flex justify-content-end align-items-center flex-grow-1 py-0 px-2 my-0" role="list">
                <!-- <li>
                    <a href="login.html">
                        <i class="fa fa-sign-out"></i> Log out
                    </a>
                </li> -->
                <li
                  class="px-2 px-md-0 user-profile cursor-pointer"
                  aria-label="User profile"
                  tabindex="0"
                >
                  <a
                    class="user-block px-0"
                    @click.prevent="displayProfile()"
                    data-toggle="tooltip"
                    data-placement="top"
                  >
                    <img
                      :src="usersdetails.picture"
                      class="img-fluid profile-pic"
                      alt="User Profile Image"
                    />
                    <div class="tool-tip">
                      {{ $t("navigations.user profile") }}
                    </div>
                  </a>
                  <div class="profile-block animate fadeIn text-start">
                    <div
                      class="login-user-details d-flex justify-content-start align-items-center"
                    >
                      <div class="login-user-details--image">
                        <img
                          class="profile-pic me-2"
                          :src="usersdetails.picture"
                          alt="User Image"
                        />
                      </div>
                      <div class="login-user-details--details d-flex flex-column">
                        <span
                          ><b>{{ usersdetails.given_name }}</b></span
                        >
                        <span>{{ usersdetails.name }}</span>
                      </div>
                    </div>
                    <div class="user-details d-flex flex-column">
                      <div
                        class="theme-change-block d-flex justify-content-start align-items-center"
                        @click.prevent="$router.push('/profileSettings')"
                      >
                        <img
                          class="me-2"
                          src="../../assets/img/user_profile.png"
                          alt="User Profile"
                        />
                        <span>{{ $t("navigations.profile") }}</span>
                    </div>
                      <div
                        class="theme-change-block d-flex justify-content-start align-items-center"
                        @click="expandSidebar()"
                      >
                        <img
                          class="me-2"
                          src="../../assets/img/user_themes.png"
                          alt="User Themes"
                        />
                        <span class="theme-change-block">{{
                          $t("navigations.themes")
                        }}</span>
                        <div class="theme-menu animated fadeIn">
                          <div class="title">Themes</div>
                          <div
                            class="setings-item default-skin"
                            @click="switchTheme('theme-default')"
                          >
                            <span class="skin-name">
                              <a href="#" class="md-skin"> Default </a>
                            </span>
                          </div>
                          <!-- <div class="setings-item blue-skin" @click="themeBlack()">
                                <span class="skin-name ">
                                  <a href="#" class="s-skin-1">
                                    Black light
                                  </a>
                                </span>
                              </div> -->
                          <div
                            class="setings-item teal-skin"
                            @click="switchTheme('theme-green')"
                          >
                            <span class="skin-name">
                              <a href="#" class="md-skin"> Teal Green </a>
                            </span>
                          </div>
                          <div
                            class="setings-item dusty-skin"
                            @click="switchTheme('theme-violet')"
                          >
                            <span class="skin-name">
                              <a href="#" class="md-skin"> Dusty Lavender </a>
                            </span>
                          </div>
                          <div
                            class="setings-item ocean-skin"
                            @click="switchTheme('theme-ocian')"
                          >
                            <span class="skin-name">
                              <a href="#" class="md-skin"> Ocean Green </a>
                            </span>
                          </div>
                          <div
                            class="setings-item stormy-skin"
                            @click="switchTheme('theme-stormy')"
                          >
                            <span class="skin-name">
                              <a href="#" class="md-skin"> Stormy Cloud </a>
                            </span>
                          </div>
                          <div
                            class="setings-item pastel-skin"
                            @click="switchTheme('theme-pastel')"
                          >
                            <span class="skin-name">
                              <a href="#" class="md-skin"> Pastel Russet </a>
                            </span>
                          </div>
                        </div>
                      </div>
                      <span class="dropdown-divider"></span>
                      <div
                        class="theme-change-block d-flex justify-content-start align-items-center"
                        @click.prevent="logout"
                      >
                        <img
                          class="me-2"
                          src="../../assets/img/logout.png"
                          alt="Logout"
                        />
                        <span>{{ $t("navigations.logout") }}</span>
                      </div>
                    </div>
                  </div>
                </li>
            </ul>
    </nav>
    </div>
<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12 align-self-center go-back d-flex justify-content-between">
      <h2 class="mt-0 text-mute">FHIR Data View</h2>
      <h2 class="mt-0"> <router-link to="/home" class="nav-link p-0 text-primary"><i class="fa fa fa-home"></i> Go to Home</router-link></h2>
  </div>
</div>
    <!-- Main Content -->
    <div class="wrapper wrapper-content animated fadeInRight px-0">
      <div class="container-fluid h-100">
        <div class="row g-0 h-100">
          <!-- Left Tree Panel -->
          <div class="col-md-4 p-1">
            <div class="card mb-4">
              <div class="card-header d-flex justify-content-between align-items-center">
                <h6 class="mb-0">FHIR Resource Explorer</h6>
              
                <div class="d-flex align-items-center">
                  <div
                    class="position-relative align-self-center mb-1 mr-2"
                    aria-label="Upload subject file"
                    tabindex="0"
                  >
                    <input
                      class="d-none"
                      accept=".xls,.xlsx"
                      id="InPutFile"
                      type="file"
                    />
                    <img
                      width="13"
                      height="13"
                      class="cursor-pointer"
                      src="../../assets/img/upload.png"
                      alt="Upload site"
                      @click.prevent="openUploadModal=true"
                    />
                    <div class="tool-tip">{{ $t('navigations.data upload') }}</div>
                </div>
                  <i class="fa fa-info-circle text-muted" title="Explore FHIR resources by study, site, and patient"></i>
                </div>
                
              </div>
              <div class="card-body p-3 h-100 d-flex flex-column">
                <!-- Search Bar -->
                <div class="mb-2">
                  <input type="search" class="form-control" placeholder="Search FHIR resources..." />
                  
                </div>
                <!-- Tree Structure -->
                <div v-if="reserchstudy.identifier" class="tree-container overflow-auto flex-grow-1">
                  <div class="tree-node">
                    <div class="d-flex align-items-center justify-content-between" @click="toggleFolder(reserchstudy)">
                      <div>
                        <i :class="['fa me-2', expandedFolders.includes(reserchstudy.id) ? 'fa-folder-open text-warning' : 'fa-folder text-warning']"></i>
                        {{ reserchstudy.identifier[0].value }}
                      </div>
                      <span class="badge bg-secondary">1 sites</span>
                    </div>

                    <!-- Site Level -->
                    <div v-if="expandedFolders.includes(reserchstudy.id)" class="ps-3">
                      <div v-for="site in researchlocations.entry" :key="site.resource.id" class="tree-node">
                        <div class="d-flex align-items-center justify-content-between" @click="toggleLocationFolder(site)">
                          <div>
                            <i class="fa fa-hospital-o text-primary me-2"></i>
                            {{ site.resource.identifier[0].value }}
                          </div>
                          <span class="badge bg-secondary">1 patients</span>
                        </div>

                        <!-- Patient Level -->
                        <div v-if="expandedFolders.includes(site.resource.id)" class="ps-3">
                          <div v-for="patient in site.resource.extension" :key="patient" class="tree-node">
                            <div class="d-flex align-items-center justify-content-between" @click="togglepatientFolder(patient)">
                              <div>
                                <i class="fa fa-user-o text-primary me-2"></i>
                                {{ patient.valueReference.display }}
                              </div>
                              <span class="badge bg-secondary">12 resources</span>
                            </div>

                            <!-- patient Resource Subfolder -->
                            <div v-if="expandedpatient == patient.valueReference.display" class="ps-3">
                              <div v-for="resource in patientsubmenus" :key="resource.id" class="tree-node">
                                <div class="d-flex align-items-center justify-content-between" @click="toggledocumentFolder(resource, patient.valueReference.reference)">
                                  <div>
                                    <i :class="['fa me-2', expandeddocument.includes(resource.id) ? 'fa-folder-open-o text-warning' : 'fa-folder-o text-warning']"></i>
                                    {{ resource.name }}
                                  </div>
                                  
                                  <span class="badge bg-secondary">1 docs</span>
                                </div>

                                <!-- Documents -->
                                <div v-if="expandeddocument.includes(resource.id)" class="ps-3">
                                  <template v-if="resource.id != 'observation'">                                 
                                  <div v-for="doc in submenudatas[resource.id]" :key="doc.id" class="tree-node" @click="selectDocument(doc)">
                                    <div class="d-flex align-items-center" v-if="resource.id == 'patient'">
                                      <i class="fa fa-file-code-o text-primary me-2"></i>
                                      {{ doc.resourceType }}
                                    </div>
                                    <div class="d-flex align-items-center" v-else-if="resource.id == 'encounter'">
                                      <i class="fa fa-file-code-o text-primary me-2"></i>
                                      {{ doc.resource.type[0].text }}
                                    </div>
                                    <div class="d-flex align-items-center" v-else-if="resource.id == 'medicationAdministration'">
                                      <i class="fa fa-file-code-o text-primary me-2"></i>
                                      {{ doc.resource.medicationReference.display }}
                                    </div>
                                    <div class="d-flex align-items-center" v-else-if="resource.id == 'adverseEvent'">
                                      <i class="fa fa-file-code-o text-primary me-2"></i>
                                      {{ doc.resource.event.coding[0].display }}
                                    </div>
                                    <div class="d-flex align-items-center" v-else-if="resource.id == 'procedure'">
                                      <i class="fa fa-file-code-o text-primary me-2"></i>
                                      {{ doc.resource.code.coding[0].display }}
                                    </div>
                                    <div class="d-flex align-items-center" v-else-if="resource.id == 'devices'">
                                      <span v-if="doc.resource.resourceType='Device'">
                                      <i class="fa fa-file-code-o text-primary me-2"></i>
                                      {{ doc.resource.identifier[0].value}}
                                    </span>
                                  </div>
                                    <div class="d-flex align-items-center" v-else-if="resource.id == 'diagnostic'">
                                      <i class="fa fa-file-code-o text-primary me-2"></i>
                                      {{ doc.resource.code.text }}
                                    </div>
                                    
                                  </div>
                                  </template>
                                  <template v-if="resource.id == 'observation'">
                                    <template v-if="showCategoryLoading">
                                      <div class="text-center" >
                                        <img src="../../assets/img/loading.gif" width="15"  alt="" />
                                      </div>
                                    </template>
                                    <template v-else>
                                      <div v-for="docType in categoryFlags" :key="docType.id" class="tree-node">        
                                        <div @click.prevent="docType.flag = !docType.flag">
                                          <span >
                                            <i class="fa fa-file-code-o text-primary me-2"></i>
                                            {{ docType.category }} 
                                          </span>                                         
                                        </div>                                                                    
                                        <div class="row" v-if="docType.flag == true">
                                          <div class="d-flex pt-2 pl-4" v-for="(value, index) in docType.code" :key="index" @click.prevent="selectDocument(docType.document[index])">
                                              <span class="mr-1">{{ value }}</span>
                                              <span v-if="docType.effectiveDateTime[index]">
                                                ({{ filterTimeFormat(docType.effectiveDateTime[index]) }})
                                              </span>
                                            </div>
                                        </div>
                                          
                               
                                      </div>
                                    </template>                                   
                                  </template>
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Right Content Area -->
          <div class="col-md-8 p-1 h-100 d-flex flex-column">
            <div class="card mb-2 patient-summary">
                <div class="card-header">
                <h6 class="mb-0">Patient Summary</h6>
                </div>
                <div class="card-body">
                <div class="row">
                    <!-- Basic Information Column -->
                    <div class="col-md-4 border-end">
                    <h6>Basic Info</h6>
                    <dl class="row mb-0">
                        <dt class="col-sm-5">Patient ID</dt>
                        <dd class="col-sm-7">{{ patientsummaryinfo.basicinfo.PatientID }}</dd>
                        <dt class="col-sm-5">Name</dt>
                        <dd class="col-sm-7">{{ maskedName(patientsummaryinfo.basicinfo.Name) }}</dd>
                        <dt class="col-sm-5">DoB</dt>
                        <dd class="col-sm-7">{{ patientsummaryinfo.basicinfo.DoB }}</dd>
                        <dt class="col-sm-5">Gender</dt>
                        <dd class="col-sm-7">{{ patientsummaryinfo.basicinfo.Gender }}</dd>
                    </dl>
                    </div>
                    <!-- Trial Information Column start-->
                    <div class="col-md-4 border-end">
                    <h6>Trial Info</h6>
                    <dl class="row">
                        <dt class="col-sm-5">Trial ID</dt>
                        <dd class="col-sm-7">{{ patientsummaryinfo.basicinfo.PatientID }}</dd>
                        <dt class="col-sm-5">Enrolment</dt>
                        <dd class="col-sm-7">{{ patientsummaryinfo.trialinfo.Enrollment }}</dd>
                        <dt class="col-sm-5">Study Arm</dt>
                        <dd class="col-sm-7">{{ patientsummaryinfo.trialinfo.StudyArm }}</dd>
                        <dt class="col-sm-5">Consent</dt>
                        <dd class="col-sm-7">
                        <!-- <span :class="`badge bg-${currentPatient.consentStatus === 'Signed' ? 'success' : 'danger'}`">
                            {{ currentPatient.consentStatus }}
                        </span> -->
                        </dd>
                    </dl>
                    </div>
                    <!-- Trial Information Column end-->
                    <!-- Medical Information Column start -->
                    <div class="col-md-4">
                      <h6>Medical Info</h6>
                      <dl class="row">
                          <dt class="col-sm-5">Allergies</dt>
                          <dd class="col-sm-7">
                          <!-- <span v-for="allergy in currentPatient.allergies" 
                                  :key="allergy" 
                                  class="badge bg-danger me-1">
                              {{ allergy }}
                          </span> -->
                          </dd>
                          <dt class="col-sm-5">Current Meds</dt>
                          <dd class="col-sm-7">{{ patientsummaryinfo. medicalinfo.CurrentMeds }}</dd>
                          <dt class="col-sm-5">Primary Care</dt>
                          <dd class="col-sm-7">{{ patientsummaryinfo. medicalinfo.PrimaryCare }}</dd>
                      </dl>
                    </div>
                    <!-- Medical Information Column end -->
                </div>
                </div>
            </div>
            <!-- Document Preview -->
            <div class="card h-100 mb-2">
              <div class="card-header d-flex justify-content-between align-items-center">
                <h6 class="mb-0">Document Preview</h6>
                    <i class="fa fa-times" @click="selectedDocument = null"></i>
              </div>
              <div class="card-body">
                <div v-if="selectedDocument">
                  <NestedJsonTable :data="selectedDocument" />
                  <!-- <pre class="document-preview">{{ selectedDocument.content }}</pre> -->
                </div>
                <div v-else class="text-muted text-center">
                  <i class="fa fa-file-alt fa-3x mb-2"></i>
                  <p>Select a document to preview</p>
                </div>
              </div>
            </div>
            <div class="card mb-4">
            <!-- Tabs Navigation -->
                <ul class="nav nav-tabs d-flex mb-3">
                    <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab#1"  @click="handleTabClick('overview')" :class="{'active':refreshFlags['overview'] === true}" >Overview</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab#2" @click="handleTabClick('visits')" :class="{'active':refreshFlags['visits'] === true}"  >Visits</a>
                    </li>
                    <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab#3" @click="handleTabClick('documents')" :class="{'active':refreshFlags['documents'] === true}"  >Documents</a>
                    </li>
                </ul>

                <!-- Tab Content -->
                <div class="tab-content flex-grow-1 px-2">
                    <!-- Overview Tab -->
                    <div class="fade" :class="{'show' : refreshFlags['overview']==true}" v-if="refreshFlags['overview']">
                    <div class="row g-3 pb-3">
                        
                        <!-- Device Info Start-->
                        <div class="col-md-12">
                          <div class="card h-100">
                              <div class="card-header d-flex justify-content-between">
                              <h6 class="mb-0">Vital Signs</h6>
                              </div>
                              <div class="card-body p-0">
                                  <div class="text-center" v-if="loading==true">
                                    <img src="../../assets/img/loading.gif" width="15" class="" alt="Waiting for login" />
                                  </div>
                                  <div v-else class="table-responsive">
                                    <table class="table table-hover mb-0" v-if="vitalSignsList.length!=0">
                                      <thead class="bg-light">
                                        <tr>
                                          <th>Measurement</th>
                                          <th>Value</th>
                                          <th>Unit</th>
                                          <th>Date-Time</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="(vital, index) in vitalSignsList" :key="index">
                                          <td>{{ vital.code }}</td>
                                          <td>{{ vital.value }}</td>
                                          <td>{{ vital.unit }}</td>
                                          <td>{{ vital.effectiveDateTime }}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                          </div>
                        </div>
                        <!-- Device Info End-->
                         <!-- Audit Trail Start-->
                        <!-- <div class="col-md-12">
                          <div class="card h-100">
                              <div class="card-header d-flex justify-content-between">
                              <h6 class="mb-0">Device Information</h6>
                              </div>
                              <div class="card-body p-0">
                                <div class="table-responsive">
                                  <table class="table table-hover mb-0">
                                    <thead class="bg-light">
                                      <tr>
                                        <th>Device Name</th>
                                        <th>Model</th>
                                        <th>Last Sync</th>
                                        <th>Battery</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(device, index) in currentPatient.devices" :key="index">
                                        <td>{{ device.name }}</td>
                                        <td>{{ device.model }}</td>
                                        <td>{{ device.lastSync }}</td>
                                        <td class="align-items-center">
                                          <div class="progress">
                                            <div class="progress-bar" 
                                                :class="{'bg-success': device.battery >= 50, 'bg-warning': device.battery < 50}"
                                                :style="{ width: device.battery + '%' }">
                                              {{ device.battery }}%
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                          </div>
                        </div> -->
                        <!-- Audit Trail End-->
                         <!-- Vital Signs Card Start-->
                        <!-- <div class="col-md-12">
                          <div class="card h-100">
                              <div class="card-header">
                              <h6 class="mb-0">Audit Trail</h6>
                              </div>
                              <div class="card-body p-0">
                                <div class="table-responsive">
                                  <table class="table table-hover mb-0">
                                    <thead class="bg-light">
                                      <tr>
                                        <th>Event</th>
                                        <th>User</th>
                                        <th>Timestamp</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(audit, index) in currentPatient.auditTrails" :key="index">
                                        <td>{{ audit.event }}</td>
                                        <td>{{ audit.user }}</td>
                                        <td>{{ audit.timestamp }}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                          </div>
                        </div> -->
                        <!-- Vital Signs Card End-->

                    </div>
                    </div>

                    <!-- Visits Tab -->
                    <div class="fade" :class="{'show' : refreshFlags['visits']==true}"  v-if="refreshFlags['visits']">

                    </div>

                    <!-- Documents Tab -->
                    <div class="fade" :class="{'show' : refreshFlags['documents']==true}" v-if="refreshFlags['documents']">
                      <!-- Existing document preview component -->
                      <div class="card h-100">

                      </div>
                    </div>
                </div>
            </div>   


          </div>
        </div>
      </div>
    </div>
    <customModal
    v-if="openUploadModal==true"
    :containersm="true"
    @closeModal="closeUploadFileModal()"
  >
    <template #title>
      {{ $t("navigations.data upload") }}
    </template>
    <template #body>
      <div class="row justify-content-center">
        <div class="input-group">
          <input
            accept=".json"
            id="InPutFile"
            type="file"
            class="form-control my-2 my-lg-0"
            @change="previewFiles"
            :disabled="showLoading"
          />
          <span class="input-group-append">
            <button type="button"  @click="uploadFile()"  class="upload-file-btn save_btn d-flex align-items-center py-1 px-3 mr-2" 
              :class="{ 'disabled-btn-cls': showLoading == true }">
              <div class="toc-loader position-absolute"
              :class="{ 'opacity-0': showLoading == false, 'opacity-1': showLoading == true }">
              <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
              </div>
              <div :class="{ 'opacity-1': showLoading == false, 'opacity-0': showLoading == true }">{{
              $t("navigations.upload") }}</div>
             </button>
          </span>
        </div>     
        <div class="errorClass" v-if="showValidation==true">{{ $t('navigations.please select a file') }}</div>
      </div>
    </template>
  </customModal>
</template>

<script src="./fhirViewer.js"></script>

<style scoped>
.tree-container {
  height: calc(100vh - 150px);
}

.tree-node {
  cursor: pointer;
  padding-left: 8px;
  padding-top: 8px;
  border-radius: 4px;
  margin: 2px 0;
}

.tree-node:hover {
  background-color: #f8f9fa;
}

.badge {
    font-size: 0.5rem;
    font-weight: 600;
    color: #555555;
    background-color: #dadada !important;
}
pre {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 4px;
  white-space: pre-wrap;
  font-family: monospace;
}

.card {
  border-radius: 6px !important;
  border: 1px solid #dee2e6;
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  border-radius: 6px 6px 0px 0px !important;
}
.card-header h6{
    font-size: .8rem !important;
    font-weight: 600 !important;
    color: #007bff;
}
.document-preview {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 4px;
  white-space: pre-wrap;
  font-family: monospace;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
.timeline {
  border-left: 2px solid #dee2e6;
  padding-left: 1rem;
}

.timeline-event {
  margin-bottom: 1rem;
}

.timeline-date {
  font-weight: 500;
  color: #6c757d;
}

.timeline-content {
  padding-left: 1rem;
}

.patient-summary dl dt {
  font-weight: 500;
  color: #6c757d;
}

.patient-summary dl dd {
  margin-bottom: 0.1rem;
}

.vital-card {
  transition: transform 0.2s;
}

.vital-card:hover {
  transform: translateY(-2px);
}

.lab-result-abnormal {
  border-left: 3px solid #dc3545;
}
.patient-summary h6{
    font-size: .8rem !important;
    font-weight: 600;
    color: #007bff;
}
.tab-content > .active {
    position: relative !important;
}
.nav-item > .active {
    position: relative !important;
}
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #f8f9fa;
    -border-color: #dee2e6 #dee2e6 #dee2e6;
}
.table {
  width: 100%;
  margin-bottom: .1rem;
  color: #212529;
  border-collapse: collapse;
}

.table-hover {
  border: .1rem solid #dee2e6 !important;
}

.table-hover th,
.table-hover td {
  border: 0px solid #dee2e6;
  padding: 0.1rem 0.5rem;
}

.table thead th {
  background-color: #f8f9fa;
  border-bottom-width: 1px;
}

.card-header.bg-light {
  background-color: #f8f9fa !important;
  border-bottom: 1px solid #dee2e6;
}
.progress {
  background-color: #e9ecef;
  border-radius: 0.25rem;
  height: 15px;
}
.progress-bar {
    background-color: #5bc0de;
    height: 15px;
}
.text-primary {
    color: #0d6efd !important;
}
.text-info {
    color: #0dcaf0 !important;
}
.upload-file-btn{
  position: relative;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}


.disabled-btn-cls {
  opacity: 0.6;
  pointer-events: none;
}
</style>
