<template>
    <div class="study_profile_modal__wrapper">
      <div class="study_profile_modal__container">
        <div class="modal__title d-flex justify-content-between py-2 px-3">
          <h4 v-if="selectedSoA == 0" class="my-0">   {{ $t("navigations.create soa") }}</h4>
          <h4 v-else class="my-0">{{ $t("navigations.edit soa") }}</h4>
          <div
            class="tooltip-wrapper pointer"
            style="float: right; cursor: pointer"
          >
            <i class="fa fa-times" @click.prevent="closeModal()"></i>
            <span class="custom-tooltip">{{ $t('navigations.close') }}</span>
          </div>
        </div>
        <div class="ibox-content p-0">
          <div class="modal-body pb-0">
            <div class="form-group row">
              <div class="col-lg-6 my-0">
                <label class="col-form-label">
                  {{ $t("navigations.activity name")}}<sup><i class="fa fa-asterisk imp"></i></sup>
                </label>
                <input
                  type="text"
                  class="form-control"
                  v-model="values.activityName"
                  maxlength="250"
                />
                <span class="errormsg" v-if="errors[0] == false">
                  {{ $t("navigations.please enter activity name") }}
                </span>
              </div>
              <div class="col-lg-6 my-0">
                <label class="col-form-label"
                  >{{ $t("navigations.activity type")
                  }}<sup><i class="fa fa-asterisk imp"></i></sup
                ></label>
                <select
                  class="form-select"
                  v-model="values.activityType"
                  valueProp="VisitTypeName"
                >
                  <option disabled value="">{{ $t("navigations.select") }}</option>
                  <option v-for="type in activityTypes" :key="type">
                    {{ type }}
                  </option>
                </select>
                <span class="errormsg" v-if="errors[1] == false">{{
                  $t("navigations.please select an activity type")
                }}</span>
              </div>
  
              <div class="mt-1 ml-3 form-check align-self-center d-flex">
                <input
                  type="checkbox"
                  v-model="values.active"
                  class="form-check-input"
                />
                <label class="mr-2">{{ $t("navigations.active") }}</label>
              </div>
  
              <div class="col-lg-12 mt-1 d-flex justify-content-lg-end">
                <button
                  class="cancel_btn py-1 px-4 mx-3"
                  type="button"
                  @click.prevent="clear()"
                >
                  {{ $t("navigations.clear") }}
                </button>
                <button
                  class="save_btn py-1 px-4"
                  type="button"
                  @click.prevent="saveClicked()"
                >
                  {{ $t("navigations.save") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Modal -->
    <div class="simple-wrapper" v-if="showPopup == true">
      <div class="simple-popup">
        <p>
          {{ $t("navigations.are you sure you want to import the form(s)") }}
        </p>
  
        <div class="d-flex justify-content-around mt-3">
          <div class="col-lg-12 mt-1 d-flex justify-content-lg-end">
            <button
              class="cancel_btn py-1 px-4 mx-3"
              type="button"
              @click.prevent="handleCancel()"
            >
              {{ $t("navigations.cancel") }}
            </button>
            <button
              class="save_btn py-1 px-4"
              type="button"
              @click.prevent="ImportFun()"
            >
              {{ $t("navigations.confirm") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from "axios";
  import store from "../../store/index";
  import { useI18n } from "vue-i18n";
  import { rSquared } from "simple-statistics";
  export default {
    setup() {
      const { t, locale } = useI18n({ useScope: "global" });
      return { t, locale };
    },
    components: {
    },
    props: {
      soAId: {
        type: String,
        default: "",
      },
      studyId: {
        type: String,
        default: "",
      },
      studyRef: {
        type: String,
        default: "",
      }
    },
    data() {
      return {
        baseurl: process.env.VUE_APP_Service_URL,
        token: store.getters.getIdToken,
        head: "",
        values: {
            soaId: "",
            studyId: "",
            studyRef: "",
            activityName: "",
            activityType: "",
            active: true,
        },
        errors:[],
        validations: [false, false],
        activityTypes: [],
      }
    },
    async mounted() {
        this.head = {
            headers: {
                Authorization: "Bearer " + this.token,
                "Content-Type": "application/json",
            },
        }
        this.listActivityTypes();
        if(this.soAId == 0){
          this.values = {
            soaId: this.soAId,
            studyId: this.studyId,
            studyRef: this.studyRef,
            activityName: "",
            activityType: "",
            active: true,
        }
        }
        else{
          await this.getSoA(this.soAId);
        }
       
    },
    methods: {
        async closeModal() {
          console.log(" in closa modal fn")
        this.$emit("closeSoAModal");
        },
        async clear() {
            this.values = {
                //studyId: "",
                //studyRef: "",
                activityName: "",
                activityType: "",
                active: true, 
            }
        },
        async listActivityTypes(){
          await axios.get(`${this.baseurl}/management/study/listactivitytypes`,this.head)
            .then((res) => {
                this.activityTypes = res.data;
                console.log("Activity types", res.data)
            }).catch(err => console.log('error listing activity types', err));
        },
        async saveClicked() {
            await this.validate();
            if (this.validations.includes(false)) {
                this.errors = this.validations;
            } else {
                if (this.values.soaId == 0) {
                this.createSoA();
                } else {
                this.updateSoA();
                }
            }
        },
        async getSoA(soaId){
            await axios.get(`${this.baseurl}/management/study/getsoa?Id=${soaId}`,this.head)
            .then((res) => {
                this.soAItem = res.data;
                this.values = res.data;
                console.log(" SoA created", res.data)
            }).catch(err => console.log('error creating SoA'));
        },
        async createSoA(){
            await axios.post(`${this.baseurl}/management/study/createsoa`, this.values,this.head)
            .then((res) => {
                console.log(" SoA created", res.data)
                this.$emit("closeSoAModal");
            }).catch(err => {
                console.log('error creating SoA', err.response.data.messages[0])
                if(err.response.data.messages[0].includes("Activity name already exists")) {
                  alert(this.$t(`navigations.activity name already exists`))
                }     
              });
        },
        async updateSoA(){
          this.values.soaId = this.values.id;
            await axios.put(`${this.baseurl}/management/study/updatesoa`, this.values,this.head)
            .then((res) => {
                console.log(" SoA updated", res.data);
                this.$emit("closeSoAModal");
            }).catch(err => console.log('error updating SoA'));
        },
        async validate() {
            this.values.activityName = this.values.activityName.replace(/ +/g, " ").trim();
            this.values.activityType = this.values.activityType.replace(/ +/g, " ").trim();
            if (this.values.activityName == "") {
                this.validations[0] = false;
            } else {
                this.validations[0] = true;
            }
            if (this.values.activityType == "") {
                this.validations[1] = false;
            } else {
                this.validations[1] = true;
            }
        },
    }
  };
  </script>
  
  <style scoped>
  @import "../../assets/style.css";
  @import "../../assets/formpage.css";
  @import "../../assets/savepages.css";
  .study_profile_modal__wrapper {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 3000;
  }
  .study_profile_modal__container {
    position: fixed;
    width: min(50%, 90%);
    border-radius: 4px;
    background: #fff;
    overflow-x: hidden;
  }
  .modal__title {
    background-color: var(--pop-up-background);
    color: white;
  }
  
  .cancel_btn {
    padding: 0.43rem 0.4rem;
    border-radius: 0.25rem;
  }
  .import-popup {
    position: relative;
  }
  .import-popup .tool-tip {
    visibility: hidden;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    background-color: #616161;
    color: #fff;
    text-align: center;
    border-radius: 0.3em;
    padding: 0.3rem 0.8rem;
    right: 0;
    top: -40px;
    font-size: 0.8rem;
    position: absolute;
    z-index: 1;
    transition: visibility 75ms ease-in;
  }
  
  .import-popup:hover .tool-tip,
  .import-popup:focus .tool-tip {
    visibility: visible;
    -webkit-transition: visibility 30ms ease-in;
    transition: visibility 30ms ease-in;
  }
  .custom-popup {
    text-align: center;
  }
  
  .simple-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 33px; /* Adjust the padding to increase the size */
    width: 29%; /* Adjust the width as needed */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 4000; /* Increase the z-index value if needed */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .simple-wrapper {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 3000;
  }
  .multiselect-template-theme {
    --ms-option-bg-selected-pointed: rgb(210, 210, 210);
    --ms-option-bg-selected: rgb(210, 210, 210);
    --ms-option-color-selected: #000;
    --ms-option-color-selected-pointed: #000;
    --ms-radius: 0;
    --ms-line-height: 0.8;
    --ms-option-font-size: 0.8rem;
    --ms-font-size: 0.8rem;
  }
  .tooltip-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .custom-tooltip {
    visibility: hidden;
    width: 60px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: -18%; /* Position the tooltip above the icon */
    left: -375%;
    margin-left: -30px; /* Use half of the width to center-align */
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
  }
  
  .tooltip-wrapper:hover .custom-tooltip {
    visibility: visible;
    opacity: 1;
  }
  </style>