/* eslint-disable */
import config from "../../../config";
import baseinput from "./form_elements/baseinput";
import baseselect from "./form_elements/baseselect";
import basedate from "./form_elements/basedate";
import basedatetime from "./form_elements/basedatetime";
import basenumber from "./form_elements/basenumber";
import basecheckbox from "./form_elements/basecheckbox";
import baseradiogroup from "./form_elements/baseradiogroup"
import axios from "axios";
import moment from "moment";
import * as showformService from "./dynamicformservice";
import store from "../../store/index";
import jwt_decode from "jwt-decode";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import html2canvas from "html2canvas";
import signaturePopUp from "../../components/signaturePopUp/signaturePopUp.vue";
import QRCode from 'qrcode';
import videocallpopup from "../../components/VideoCallPopup/videocall_popup.vue"
import CreateVideoCallSchedulePopUp from "../../components/CreateVideoCallSchedulePopUp/CreateVideoCallSchedulePopUp.vue";
import ValidationMessage from "./errormessages.vue";
import basetime from "./form_elements/basetime.vue";

import jsPDF from "jspdf";
import { toRaw } from "vue";
// import { includes } from "core-js/core/array";
export default {
  name: "newtemplate",
  components: {
    baseinput,
    baseselect,
    basecheckbox,
    baseradiogroup,
    basenumber,
    basedatetime,
    basedate,
    signaturePopUp,
    VPagination,
    QRCode,
    videocallpopup,
    CreateVideoCallSchedulePopUp,
    ValidationMessage,
    basetime
  },
  props:{
      formsesid:{
          type: String,
          default: ''
      },
      closeoption:{
        type: String,
        default: ''
    },
    isPatientVisit: {
      type: Boolean,
      default: false
    },
      isvalidate:{
        type: Boolean,
        default: true
      },
      issavefun:{
        type: Boolean,
        default: false
      },
      isfieldvalidate:{
        type: Boolean,
        default: false
      },
      isqueryfield:{
        type: Boolean,
        default: true
      },
      requiredsubmit: {
        type: Boolean,
        default: true
      }
    },
  async beforeMount() {
    this.stdyid = store.getters.getStudyIs;
    this.getbyidforautofill();
    await this.setformid();
    await this.getuserIp();
  },
  async unmounted(){
    await store.dispatch("setautofillsiteid", null);
    await store.dispatch("setautofillsubjectid", null);
    await store.dispatch("setfrompatientschedule", null);
  },
  async mounted(){
    window.addEventListener("FormDigitalSignature", (event) => {
      if (event.detail.storage == true) {
        //window.location.reload();
        this.getSignStatus()
      }
    });
    this.rollsprivilages = await store.getters.getRolesprivilegeData;
    await this.getSignStatus()
    this.defaultdate = localStorage.getItem("dateformat");
    this.defaultTime = localStorage.getItem("timeformat");
    this.formsid = this.formsesid.trim();
    this.fieldName = store.getters.getFieldName;
    await this.getById();
    await this.getPageName();
    await this.getFormHeader();
    await this.patientId(this.forms[0].subjectId);
    await this.siteId(this.forms[0].siteId);
    await this.FormOpenQueryCount();
    await this.FormCloseQueryCount();
    await this.geteditcheckbytempid();
  },
  data() {
    return {
      visibility:{},
      autofield:[
        {
            "site_sub_autofill" : [
                {
                    "autofill" : true,
                    "labelsubject" : "",
                    "labelsite" : ""
                }
            ]
        }
    ],
    EditFrom: "EditFrom",
      subID:"",
      CreateVideoCallSchedulePopUp:false,
      videocallpopupon:false,
      stdyid:"",
      classname:"",
      fromUrlStatus:true,
      isupdated: false,
      requiredclasslist:{},
      shouldContinue: true,
      isrepeatvalid:{},
      isrepeatdatavalid:{},
      repeatedgroupgroupnos:{},
      originalDate: null,
      formattedDate: null,
      repeatedmarray:[],
      selectedrepeatedgroupno:1,
      repeatedmeasurepopup:false,
      repeatedmeasurelistpopup:true,
      repeatedoptions:"",
      repeatedmeasurevalarray:[1],
      temprepeatedmeasureval:{},
      repratmeasureid:new Date(),
      calculatedresult:{},
      previouscalculatedresult:{},
      currentgrid:[],
      gridoptarray:{},
      gridlinkeddatas:{},
      griddata: [
        {
        Rows:[""],
        Columns:[""],
        Fieldtypes:["text"],
        Fielddata:{},
        Fieldoptions:{}
        }
        ],
      downloadedfilenames:{},
      qrcodetxt:{},
      numberanddateval:{},
      repeatednumberanddateval:{},
      loggedinUser:"",
      signedby: "",
      signtime: "",
      signdate: "",
      signatureformId: "",
      signatuestatus: "",
      signStatus: "",
      signpopUp: false,
      iPaddress:"",
      isPatientValue:false,
      defaultdate: "",
      defaultTime: "",
      fieldName :"",
      pagesavefieldnames: [],
      showdiv:false,
      modelinputclass: "form-control",
      modelradioclass: "col-md-10 my-1",
      testvar:"",
      groups: {},
      audit: [],
      formsid: "",
      modelelvalidation: "",
      modelcomments: "",
      modelpgno: "",
      modelelement: "",
      modelname: "hello",
      modelvalue: "mvalue",
      modeltype: "mtype",
      receivedData: [],
      envvar: process.env.NODE_ENV,
      fileuploadurl: process.env.VUE_APP_File_upload_URL+"/document",
      baseurl: process.env.VUE_APP_Service_URL,
      saveid: "",
      errormsg: {},
      messagetype:{},
      errorclasslist: {},
      errorsort: {},
      forms: [],
      isVerified:"",
      patientsId:"",
      sitesId:"",
      formName:"",
      pageName:[],
      newrepeatingField:[],
      formHeader:"",
      visits:[],
      pages:[],
      selectedValue:"",
      formdata: {},
      sndData: {
        formId: "string",
        pages: [],
        dataSource:"",
        sourceID:"",
      },
      isvalid: [],
      bindarray: {
        page1: { siteCode: "test value", subjectNo: "" },
        page2: "",
        page3: "",
      },
      pageno: 1,
      totalpages: 0,
      uploads: ["photo", "signature", "file"],
      inputElements: [
        "color",
        "datetime-local",
        "email",
        "hidden",
        "number",
        "month",
        "password",
        "range",
        "search",
        "tel",
        "text",
        "url",
        "week",
        "boxed",
        "textbox",
        "boxed",
      ],
      htmlarray: [],
      openQueryCount : 0,
      closeQueryCount : 0,
      initValue:1,
      Querydetails : "",
      formOpenQueryCount :0,
      formClosedQueryCount :0,

      azuredata: {
        DocumentTypeName: "",
        DocumentTitle: "",
        DocumentDescription: "",
        PrimaryReferenceId :"",
        PrimaryReferenceType :"",
        SecondaryReferenceId :"",
        SecondaryReferenceType:"",
        OrganizationId:0,
        Document: ""
      },
      documentId:"",
      filename :[],
      docValidations:true,
      currentdocId:"",
      loaddata:false,
      documentName:"",
      Document:"",
      documentById:"",
      documentlist:[],
      documentVersionId: [],
      isReview:"",
      fileid:"",
      repeatedpageno:"",
      repeatedfieldname:"",
      repeathidden:false,
      fileInputFocused: false,
      rollsprivilages: "",
      calculatedfields:[],
      
    };
  },
  methods: {
    gettimeformat(validation) {
      if (!Array.isArray(validation) || validation.length === 0) {
        return 'h:mm A'; // Default value when array is empty
      }
    
      // Find the element that starts with "DATEFORMAT"
      const timeFormatElement = validation.find((item) => item.startsWith("TIMEFORMAT"));
    
      if (timeFormatElement) {
        // Extract the string between parentheses
        const match = timeFormatElement.match(/\(([^)]+)\)/); // Regular expression to capture text inside parentheses
        return match ? match[1] : 'h:mm A'; // Return the captured group or default
      }
    
      return 'h:mm A'; // Default value when no matching element is found
    },
    getdateformat(validation) {
      if (!Array.isArray(validation) || validation.length === 0) {
        return 'DD-MM-YYYY'; // Default value when array is empty
      }
    
      // Find the element that starts with "DATEFORMAT"
      const dateFormatElement = validation.find((item) => item.startsWith("DATEFORMAT"));
    
      if (dateFormatElement) {
        // Extract the string between parentheses
        const match = dateFormatElement.match(/\(([^)]+)\)/); // Regular expression to capture text inside parentheses
        return match ? match[1].replace(/[a-z]/g, char => char.toUpperCase()) : 'MM-DD-YYYY'; // Return the captured group or default
      }
    
      return 'DD-MM-YYYY'; // Default value when no matching element is found
    },
    onFileInputFocus(fieldName) {
      this.fileInputFocused = true;
    },
    onFileInputBlur(pageNo, fieldName, validations) {
      // Only trigger this if there was a focus previously, meaning the user opened the dialog
      if (this.fileInputFocused) {
          this.focusOutFunctionForFileUpload(pageNo, fieldName, validations);
        }
    },
    focusOutFunctionForFileUpload(pageNo,fieldName,validations){
      this.errormsg[fieldName] = "";
      this.messagetype[fieldName] = "";
      if(validations.includes("Required") || validations.includes("REQUIRED")){
        if(this.formdata[`pageNo${pageNo}`][fieldName] == "" || this.formdata[`pageNo${pageNo}`][fieldName] == null){
          this.errormsg[fieldName] = "Please upload a file to proceed.";
          this.messagetype[fieldName] = "Alert"
        }
      }
    },
    numberanddateblurfun(pageNo,fieldName)
    {
      const numbdata = this.numberanddateval[fieldName].numberdata;
      const datedata = this.numberanddateval[fieldName].datedata;
      this.formdata[`pageNo${pageNo}`][fieldName] = this.formatDateNum(datedata) + "_" + numbdata;
      this.focusOutFunction(pageNo,fieldName);
    },
    getrepeatedValueByKey(key, fieldname, rfieldIndex, pageno) {

      const page = this.formdata[`pageNo${pageno}`];
      if (!page) {
        return 0;
      }
      
      if (page.hasOwnProperty(fieldname)) {
        const fieldsArray = page[fieldname][rfieldIndex]['fields'] || [];
        const match = fieldsArray.find(eachfield => eachfield.fieldName === key);
        return match ? match.value : null;
      }
      
      return null;
    },
    async getValueByKey(key) {
      for (const page in this.formdata) {
          if (this.formdata[page].hasOwnProperty(key)) {
              return this.formdata[page][key];
          }
      }
      return null; // Return null if the key is not found
  },
    async focusOutFunction(pageno,fieldname,sentvalue) {
      this.errormsg[fieldname] = "";
      if(this.messagetype[fieldname]){
        delete this.messagetype[fieldname];
      }
     
      if(this.isvalid.includes(fieldname)){
        const index = this.isvalid.indexOf(fieldname);
        this.isvalid.splice(index, 1);

      }

      let value = "";
      if(sentvalue!=''){
        value = sentvalue;
      }
      else{
        value = this.formdata[`pageNo${pageno}`][fieldname];
      }
      if (value === true) {
        value = "true";
      } else if (value === false) {
        value = "";
      }
      if(value == null || value == undefined){
        value = "";
      }
      value = value.toString();
      let idtoken = store.getters.getIdToken;
      let calcfieldarray = await this.getCalculationsForField(fieldname);
      let computedfieldobj={};
      if(calcfieldarray.length > 0){
      await calcfieldarray.forEach(async (eachfields)=>{
        computedfieldobj[eachfields] = await this.getValueByKey(eachfields);
      })
    }
      
      let checkdata = {
        "fiedValue": value,
        "fieldName": fieldname,
        "templateId": this.receivedData[0].templateId,
        "computeFields": computedfieldobj
      }
      await axios.post(`${this.baseurl}/forms/editcheck/validatefield`,checkdata,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const fieldVisibility = res.data.fieldVisibility;
          const computedField = res.data.computedField;
          if(res.data.validations.length > 0){
          const errval = res.data.validations[0];
          this.errormsg[fieldname] = errval.message;
          this.messagetype[fieldname] = errval.messageType;
          if(errval.messageType == "Alert"){
            if(!this.isvalid.includes(fieldname)){
           this.isvalid.push(fieldname);
         }
          }
        }
         if(fieldVisibility.length > 0){
          fieldVisibility.forEach((eachfieldvisibility)=>{
           this.visibility[eachfieldvisibility.fieldName] = eachfieldvisibility.isVisible
          })
         }
         if(computedField.length > 0){
          computedField.forEach((eachcomputedField)=>{
           this.htmlarray[0].pages.forEach((eachpages)=>{
             eachpages.field.forEach((eachfields)=>{
               if(eachfields.fieldName == eachcomputedField.fieldName){
                this.formdata[`pageNo${eachpages.pageNo}`][eachcomputedField.fieldName] = eachcomputedField.value;
               }
             })
           })
          })
         }
        })
        .catch((err) => {
          console.log(err);
        });
        
      // This function gets called when the input loses focus
      
      },
      async focusOutFunctionForRF(pageno,mainfieldname, rfieldIndex,fieldindex,fieldname,fieldidentifier,sentvalue) {
        this.errormsg[fieldname+fieldidentifier] = "";
        if(this.messagetype[fieldname+fieldidentifier]){
          delete this.messagetype[fieldname+fieldidentifier];
        }
       
        if(this.isvalid.includes(fieldname+fieldidentifier)){
          const index = this.isvalid.indexOf(fieldname+fieldidentifier);
          this.isvalid.splice(index, 1);
      
        }
        let value = "";
if(sentvalue!=''){
  value = sentvalue;
}
else{
  value = this.formdata[`pageNo${pageno}`][mainfieldname][rfieldIndex]['fields'][fieldindex].value;
}
if (value === true) {
  value = "true";
} else if (value === false) {
  value = "";
}
        let idtoken = store.getters.getIdToken;
        if(value == null || value == undefined){
          value = "";
        }
        value = value.toString();
        let calcfieldarray = await this.getCalculationsForField(fieldname);
        let computedfieldobj={};
        if(calcfieldarray.length > 0){
        await calcfieldarray.forEach(async (eachfields)=>{
          computedfieldobj[eachfields] = this.getrepeatedValueByKey(eachfields,mainfieldname,rfieldIndex,pageno);
        })
        console.log(computedfieldobj);
      }
        
        let checkdata = {
          "fiedValue": value,
          "fieldName": fieldname,
          "templateId": this.receivedData[0].templateId,
          "computeFields": computedfieldobj
        }
        await axios.post(`${this.baseurl}/forms/editcheck/validatefield`,checkdata,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            const fieldVisibility = res.data.fieldVisibility;
            const computedField = res.data.computedField;
            if(res.data.validations.length > 0){
            const errval = res.data.validations[0];
            
            if(errval.messageType == "Alert"){
              if(!this.isvalid.includes(fieldname+fieldidentifier)){
             this.isvalid.push(fieldname+fieldidentifier);
           }
            }
            this.errormsg[fieldname+fieldidentifier] = errval.message;
            this.messagetype[fieldname+fieldidentifier] = errval.messageType;
            
          }
          if(fieldVisibility.length > 0){
            fieldVisibility.forEach((eachfieldvisibility)=>{
             this.visibility[eachfieldvisibility.fieldName+fieldidentifier] = eachfieldvisibility.isVisible
            })
           }
          if(computedField.length > 0){
            computedField.forEach((eachcomputedField)=>{
              this.formdata[`pageNo${pageno}`][mainfieldname][rfieldIndex]['fields'].forEach((eachrepfields,rindex)=>{
                if(eachrepfields.fieldName == eachcomputedField.fieldName){
                  this.formdata[`pageNo${pageno}`][mainfieldname][rfieldIndex]['fields'][rindex].value = eachcomputedField.value;

                }
              })


           })
          }
          
          })
          .catch((err) => {
            console.log(err);
          });
          
        // This function gets called when the input loses focus
        
        },
      async geteditcheckbytempid() {

        let idtoken = store.getters.getIdToken;
  
        await axios.get(`${this.baseurl}/forms/editcheck/geteditcheckbytempid?tempId=${this.receivedData[0].templateId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
           this.editcheckvar = res.data;
           this.editcheckvar.fields.forEach((eacheditcheck)=>{
            if(eacheditcheck.calculations.length != 0){
              eacheditcheck.calculations.forEach((calculationoutput)=>{
                let targetfield = calculationoutput.outputfield;
                  if(!this.calculatedfields.includes(targetfield)){
                      this.calculatedfields.push(targetfield);
                   }
              })
            }
            
          })
          })
          .catch((err) => {
            console.log(err);
          });
        },
        getCalculationsForField(fieldName) {

          if (!this.editcheckvar || !Array.isArray(this.editcheckvar.fields)) {
            return [];
          }
        
          const fieldObj = this.editcheckvar.fields.find(field => field.fieldName === fieldName);
                if(fieldObj){
                    return fieldObj.calculations != null && fieldObj.calculations.length != 0 ? fieldObj.calculations[0].fields : [];
                }
                else{
                  return [];
                }
          
        },
    openVideoCallSchedulePopup(patientId) {
      this.selectedPatientId = patientId; // Set the selected patient ID
      this.CreateVideoCallSchedulePopUp = true; // Open the popup
    },
    async videocallpopupshow(){
this.videocallpopupon=true;
    },
    async opendvidpopup(){
      this.videocallpopupon=false;
    },
    async removeRepeatedfields(pageno, fieldName, rfieldIndex){
      if(this.formdata[`pageNo${pageno}`][fieldName].length > 1){
        this.formdata[`pageNo${pageno}`][fieldName].splice(rfieldIndex, 1);
      }
      else{
        alert("Cannot delete! Atleast one field should be kept on a repeating measure field")
      }
    },
//     async addrepeatedfields(repeatKey,fieldname,pageno){ 
//       this.htmlarray[0].pages.forEach((item) => {      
//         item.field.forEach((subitem,key) => {    
//           if(subitem.type=='RepeatingMeasure' || subitem.type=="repeatingfield"  || subitem.type=='repeatingmeasure')
//           {                               
//             if(key == repeatKey && pageno == item.pageNo){
//               item.field[repeatKey].repeatingFields.push({
//                 fieldIdentifier:item.field[repeatKey].repeatingFields.length+1,
//                 fields: item.field[repeatKey].repeatingFields[0].fields
//               })  
//               this.formdata[`pageNo${pageno}`][fieldname].push(JSON.parse(JSON.stringify({
//                             fieldIdentifier:item.field[repeatKey].repeatingFields.length+1,
//                             fields: item.field[repeatKey].repeatingFields[0].fields
//                           })));            
//             }     
            
//           }      
//         })
//         });
//     // this.bindData()
// },

async addrepeatedfields(repeatKey,pageIndex,fieldName,pageno){
  this.isrepeatdatavalid={};
  const fieldid = this.formdata[`pageNo${pageno}`][fieldName][(this.formdata[`pageNo${pageno}`][fieldName]).length-1].fieldIdentifier +1;
  let fieldsarray = JSON.parse(
    JSON.stringify(
      this.htmlarray[0].pages[pageIndex].field[repeatKey].repeatingFields[0].fields
    )
  );
  fieldsarray.forEach((eachfields)=>{
    this.visibility[eachfields.fieldName+fieldid] = eachfields.visibility;
    eachfields.value = "";
    console.log("eachfields",eachfields);
  });
          this.formdata[`pageNo${pageno}`][fieldName].push(JSON.parse(JSON.stringify({
            fieldIdentifier:fieldid,
            fields: fieldsarray
          })));
},
    async reloadrepeatarray(item){
      this.repeatedmeasurelistpopup = false;
      this.repeathidden = true;
      this.temprepeatedmeasureval = item;
    },
    async repeatedmeasuremodal(options, pageNo, fieldName){
      this.repeatedpageno = pageNo;
      this.repeatedfieldname = fieldName;
      this.repeatedoptions = JSON.parse(options);
      console.log(this.repeatedoptions);
      this.repeatedmeasurepopup = true;
    },
    async saverepeatedmeasurdata(){
      this.repeathidden = false;
      this.formdata[`pageNo${this.repeatedpageno}`][this.repeatedfieldname] = JSON.stringify(this.repeatedmarray);
      this.repeatedmeasurepopup = false;
    },
    async closerepeatedmeasuremodal(){
      this.repeathidden = false;
      this.repeatedmeasurepopup = false;
    },
    async repeatedmeasurlist(){
      this.repeathidden = false;
      this.repeatedmeasurelistpopup = true;
    },
    async repeatedmeasurdatapopup(){
      this.temprepeatedmeasureval={};
      this.repratmeasureid = new Date();
      this.repeatedmeasurelistpopup = false;
    },
    async deleterepeatedmeasure(item){
      let indexToDelete = this.repeatedmarray.indexOf(item);

      if (indexToDelete !== -1) {
        // Remove the element at the found index
        this.repeatedmarray.splice(indexToDelete, 1);
      }
    },
    async addrepeatemesureentries(){
      let temprepeatedval = {};
      this.repratmeasureid = new Date();
      this.temprepeatedmeasureval.ID = this.repratmeasureid;
      temprepeatedval = this.temprepeatedmeasureval;
      this.repeatedmarray.push({...temprepeatedval});
      temprepeatedval = {};
      this.repeatedmeasurlist();
    },
    async qrcodefun(fieldname,dataurl){
      try {
        this.qrcodetxt[fieldname] = await QRCode.toDataURL(dataurl);
      } catch (err) {
        console.error(err)
      }
    },
    async calculateResult(pageno, caloptions, fieldname){
      if(!this.calculatedresult[fieldname]){
      this.calculatedresult[fieldname] ="";
      this.previouscalculatedresult[fieldname] = "0";
      }
      if(this.previouscalculatedresult[fieldname] != this.calculatedresult[fieldname]){
      let optionnsparsed = JSON.parse(caloptions);
      let firstclcname = this.formdata[`pageNo${pageno}`][optionnsparsed.firstfield];
      let secondcalcname = this.formdata[`pageNo${pageno}`][optionnsparsed.secondfield];
      let calcoperator = optionnsparsed.operations.trim();
      if(firstclcname != undefined && secondcalcname != undefined){
      if(firstclcname != "" && secondcalcname != ""){
      let firstclcnametoint = parseInt(firstclcname);
      let secondcalcnametoint = parseInt(secondcalcname);
      if (!isNaN(firstclcnametoint) || !isNaN(secondcalcnametoint)) {
        console.log("calcoperator",calcoperator,firstclcnametoint,secondcalcnametoint);
        if(calcoperator.trim() == "*"){
          this.calculatedresult[fieldname] = firstclcnametoint * secondcalcnametoint;
        }
        else if(calcoperator.trim() == "+"){
          this.calculatedresult[fieldname] = firstclcnametoint + secondcalcnametoint;
        }
        else if(calcoperator.trim() == "-"){
          this.calculatedresult[fieldname] = firstclcnametoint - secondcalcnametoint;
        }
        else if(calcoperator.trim() == "/"){
          this.calculatedresult[fieldname] = firstclcnametoint / secondcalcnametoint;
        }
        else{
          this.calculatedresult[fieldname] = firstclcnametoint + secondcalcnametoint;
    }
  }
  else{
    this.calculatedresult[fieldname] = firstclcname + secondcalcname;
  }

   this.previouscalculatedresult[fieldname] = this.calculatedresult[fieldname];
  }
}
}
    },
    truncateInput(event, fieldName, maxLength) {
      const inputValue = event.target.value.toString();
      if (inputValue.length > maxLength) {
        event.target.value = inputValue.slice(0, maxLength);
        this.formdata[`pageNo${item.pageNo}`][fieldName] = event.target.value;
      }
    },
    async getSignStatus() {
      const idtoken = store.getters.getIdToken;
      await axios.get(
        `${this.baseurl}/forms/forms/signstatus?formId=${this.formsesid}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          }
        })
        .then((res) => {
          this.signdate = res.data.signedAt;
          this.signtime = this.signdate
          this.signedby = res.data.signedUserName
          this.signStatus = res.data.signStatus
          this.loggedinUser = res.data.loggedInUser
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async signatureClick(status) {
      this.signpopUp = true;
      this.signatuestatus = status
      this.signatureformId = this.formsesid
      if (status == "Signed") {
        this.modalStatus = "Sign"
      }
      else if (status == "Unsigned") {
        this.modalStatus = "Unsign"
      }
    },
    async gridoptfun(fieldname,gridoptions,value){
      this.gridoptarray[fieldname] = JSON.parse(gridoptions);
      this.gridlinkeddatas[fieldname] = {};
      console.log(JSON.parse(gridoptions)[0].Fielddata);
      
      this.gridlinkeddatas[fieldname] = JSON.parse(value);
        },
    async CloseModelCall() {
      this.signpopUp = false;
    },
     // User IP address
     async getuserIp() {   
      await axios.get(`https://api.ipify.org?format=json`).then((res) => {this.iPaddress = res.data.ip;}); 
    },
    //ends here
      async handleClickSignIn() {
          try {
              const googleUser = await this.$gAuth.signIn();
              if (!googleUser) {
                  return null;
              }
              //console.log("googleUser", googleUser);
              //this.user = googleUser.getBasicProfile().getEmail();
             // console.log("getId", this.user);
            //  console.log("getBasicProfile", googleUser.getBasicProfile());
              console.log("getAuthResponse", googleUser.getAuthResponse().access_token);
              console.log("getAuthResponse----", googleUser.getAuthResponse().expires_at);
             // console.log("getAuthResponse", this.$gAuth.instance.currentUser.get().getAuthResponse());
             await this.getHealthData(googleUser.getAuthResponse().access_token)
          } catch (error) {
              //on fail do something
              console.error(error);
              return null;
          }
      },
      async getHealthData(g_token) {

          // ------------ to get current date for google fit -------
          // var start = new Date();
          // start.setHours(0,0,0,0);
          // start.setDate(start.getDate()-1);
          //
          // var end = new Date();
          // end.setHours(23,59,59,999);
          // end.setDate(end.getDate()-1);
            //-------------------end ----------------------------
          // let data = {
          //     "aggregateBy": [{
          //         "dataSourceId": "derived:com.google.step_count.delta:com.google.android.gms:estimated_steps"
          //     }],
          //     "bucketByTime": {"durationMillis": 86400000}, // This is 24 hours
          //     "startTimeMillis": 1546210381932,   // Start time
          //     "endTimeMillis": 1547210381932  // End Time
          // }
          await axios.post(
              `https://www.googleapis.com/fitness/v1/users/me/dataset:aggregate`, {
                  "aggregateBy": [{
                      "dataSourceId": "derived:com.google.step_count.delta:com.google.android.gms:estimated_steps",
                  },
                      {
                          "dataSourceId": "derived:com.google.weight:com.google.android.gms:merge_weight",
                      },
                      {
                          "dataSourceId": "derived:com.google.height:com.google.android.gms:merge_height",
                      },
                      {
                          "dataSourceId": "derived:com.google.calories.expended:com.google.android.gms:merge_calories_expended",
                      },
                      {
                          "dataSourceId": "derived:com.google.heart_minutes:com.google.android.gms:merge_heart_minutes",
                      },
                      // {
                      //     "dataSourceId": "derived:com.google.distance.delta:com.google.android.gms:merge_distance_delta",
                      // },
                  ],
                  "bucketByTime": {"durationMillis": 86400000},
                 // "startTimeMillis": start.getTime(),
                 // "endTimeMillis":  end.getTime()
                 "startTimeMillis": config.googlefitvalues.startTimeMillis,   // Start time
                 "endTimeMillis": config.googlefitvalues.endTimeMillis  // End Time
              },
              {
                  headers: {
                      Authorization: "Bearer " + g_token,
                      "Content-Type": "application/json",
                  },
              }
          )
              .then(async (res) => {


                  await this.updateFitnessData(res.data.bucket[0].dataset);


                  // let healthData = {
                  //     "formId":this.formsesid,
                  //     "updateFieldDTO": [
                  //         {
                  //             "fieldName": "string",
                  //             "value": "string",
                  //             "status": "string",
                  //             "comment": "string"
                  //         }
                  //     ]
                  // }

                  // let arr = [];
                  // for (let i = 0; i < res.data.bucket[0].dataset.length; i++) {
                  //     arr.push({
                  //         fieldName: i.dataSourceId,
                  //         value: true,
                  //         status: true,
                  //         comment: "string"
                  //     });
                  // }
                  //this.updateFitnessData(re);
              })
              .catch((err) => {
                  console.log(err);
              });
      },
      async updateFitnessData(data1){
          const arr = [];

          for (let i=0; i < data1.length; i++)
          {
             // console.log("i",data1[i])
             // console.log("00000000000000",data1[i].point.length)
              for (let j=0; j < data1[i].point.length; j++)
              {
                  console.log("j",data1[i].point[j])
                      for (let k=0; k < data1[i].point[j].value.length; k++)
                      {
                         // console.log("k",data1[i].point[j].value[k])
                         // console.log("step count",data1[i].point[j].value[k].intVal);
                          //console.log("calory",data1[i].point[j].value[k].fpVal);
                          const mm = {
                              fieldName: data1[i].point[j].dataTypeName,
                              value: data1[i].point[j].value[k].intVal != null ? data1[i].point[j].value[k].intVal.toString() : data1[i].point[j].value[k].fpVal.toString(),
                              status: null,
                              comment: null
                      }
                            arr.push(mm)
                         // console.log("array",arr)
                      }
              }
          }
          const idtoken = store.getters.getIdToken;

          await axios.put(
              `${this.baseurl}/forms/forms/updatefeildfromgoogle`,{
              // `https://localhost:5001/forms/updatefeildfromgoogle`,{

                  formId: this.formsid,
                  //pageNo:null,
                  updateFieldDTO: arr
              },
              {
                  headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                  },
              })
              .then((res) => {
                  console.log("Form updated with google fit data",res);
              })
              .catch((err) => {
                  console.log(err);
              });
      },
      async printFacture() {
      let pdffilename = this.sitesId + "_" + this.patientsId + "_" + this.formName;
      pdffilename = pdffilename.replace(/ +/g, "");
      this.showdiv=true;
      if(this.showdiv==true){
      console.log("for pdf");
      let elementbyclassname = document.getElementsByClassName('getdfcontentss');
      let doc = new jsPDF('p', 'mm');
      //foeeatch statement for paging pdf starts
      let countforlastpage = 1;
      elementbyclassname.forEach(async element => {
    // console.log(document.getElementsByClassName('getdfcontentss'));
      // let element = document.getElementById("getdfcontentss");
      console.log("element is",element);
     await html2canvas(element).then(canvas => {
      console.log("for pdf2");
      let imgData = canvas.toDataURL('image/png');

let imgWidth = 210;
let pageHeight = 295;
let imgHeight = canvas.height * imgWidth / canvas.width;
let heightLeft = imgHeight;
let position = 10;
doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
heightLeft -= pageHeight;
while (heightLeft >= 0) {
  position = (heightLeft - imgHeight) + 10;
  doc.addPage();
  doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;
}
      //test pdf save ends
      });
      if(countforlastpage == elementbyclassname.length)
      {
      doc.save( pdffilename + '.pdf');
      }
      else{
        doc.addPage();
        countforlastpage++;
      }
    });
    // foeeatch statement for paging pdf ends

    }
      },
    filter(date) {
      if(date){
        return moment(date).format(this.defaultdate)
      }
            console.log("Reverse is")
            console.log(date)
    },
    formatTime(time){
      if (time) {
        return moment(time).format(this.defaultTime)
      }
    },
    async onclosed() {
      await this.$router.push(`/${this.closeoption}`);
    },
    async getById(){
      const idtoken = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/forms/forms/getbyid/${this.formsid}?api-version=1`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        this.forms[0]=res.data
        this.formName=res.data.formName
        this.isReview = this.forms[0].isReview
        this.subID = res.data.subjectId;
        console.log("SUBJECT ID IS",this.subID)
     })
     .catch((err) => {
       console.log(err);
     });
    },
    async FormOpenQueryCount(){
      this.StudyId = store.getters.getStudyIs;

       const idtoken = store.getters.getIdToken;
        await axios.get(`${this.baseurl}/forms/query/getquerycount?StudyId=${this.StudyId}&FormId=${this.formsid}&status=Open`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
        ).then((res) => {
          this.formOpenQueryCount = res.data
        })
        .catch((err) => console.log(err));

    },
    async FormCloseQueryCount(){
      this.StudyId = store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;
        await axios.get(`${this.baseurl}/forms/query/getquerycount?StudyId=${this.StudyId}&FormId=${this.formsid}&status=Closed`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
        ).then((res) => {
          this.formClosedQueryCount = res.data
        })
        .catch((err) => console.log(err));
},
    async FormQueryDetails(status){
        const idtoken = store.getters.getIdToken;
        const currentstudy = store.getters.getStudyIs;
        console.log("In Form Query Details " ,currentstudy,this.formsid,status);
        await axios
                .get(`${this.baseurl}/forms/query/getquerydetails?StudyId=${currentstudy}&FormId=${this.formsid}&status=${status}`,
                            {
                                headers: {
                                    Authorization: "Bearer " + idtoken,
                                    "Content-Type": "application/json",
                                },
                            }
                )
                .then((res) =>
                {
                    this.Querydetails = res.data;
                    console.log(res.data);

                })
                .catch(err => {
                    console.log(err);
                })
        const modalWrapperQuery = document.querySelector('.modal__wrapper__Two');
        modalWrapperQuery.classList.add('active');
    },
    async FieldCloseQueryCount(){
      console.log("in field Query Count closed");
      this.StudyId = store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;
        await axios.get(`${this.baseurl}/forms/query/getquerycount?StudyId=${this.StudyId}&FormId=${this.formsid}&status=Closed&PageNo=${this.modelpgno}&FieldName=${this.modelname}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
        ).then((res) => {
          this.closeQueryCount = res.data
        })
        .catch((err) => console.log(err));
    },
    async FieldOpenQueryCount(){
      this.StudyId = store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;
      console.log("In field Open Query Count " ,this.StudyId,this.formsid,this.modelpgno,this.modelname);
        await axios.get(`${this.baseurl}/forms/query/getquerycount?StudyId=${this.StudyId}&FormId=${this.formsid}&status=Open&PageNo=${this.modelpgno}&FieldName=${this.modelname}`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
        ).then((res) => {
          this.openQueryCount = res.data
        })
        .catch((err) => console.log(err));
    },
    async FieldQueryDetails(status){
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      console.log("In field Query Details " ,currentstudy,this.formsid,status,this.modelname);
      await axios
              .get(`${this.baseurl}/forms/query/getquerydetails?StudyId=${currentstudy}&FormId=${this.formsid}&status=${status}&PageNo=${this.modelpgno}&FieldName=${this.modelname}`,
                          {
                              headers: {
                                  Authorization: "Bearer " + idtoken,
                                  "Content-Type": "application/json",
                              },
                          }
              )
              .then((res) =>
              {
                  this.Querydetails = res.data;
                  console.log(res.data);

              })
              .catch(err => {
                  console.log(err);
              })
      const modalWrapperQuery = document.querySelector('.modal__wrapper__Two');
      modalWrapperQuery.classList.add('active');
    },
    closeQueryModal: function() {
  const modalWrapperQuery = document.querySelector('.modal__wrapper__Two');
  modalWrapperQuery.classList.remove('active');
    },

    //Form Document Upload 
    UploadDocumentModal(){
      const modalWrapper = document.querySelector('.modal-wrapper-upload');
      modalWrapper.classList.add('active');
    },
    CloseUploadModal(){
    const modalWrapper = document.querySelector('.modal-wrapper-upload');
    modalWrapper.classList.remove('active');
    },
    ViewDocumentModal(){
    this.getDocuments();
    const modalWrapper = document.querySelector('.modal-wrapper-view');
    modalWrapper.classList.add('active');
    // alert(formId);
    },
    ClosemodalView(){
    const modalWrapper = document.querySelector('.modal-wrapper-view');
    modalWrapper.classList.remove('active');
    },
    async previewFiles(event) {
    this.azuredata.Document = event.target.files[0];
    console.log("upload file is", this.azuredata.Document.name);
    },
    async CreateDocumentDMS(){
    this.azuredata.DocumentTypeName = "Form"
    var formData = new FormData();

    console.log("form data", formData);
    formData.append("Document", this.azuredata.Document);
    formData.append("DocumentTypeName", this.azuredata.DocumentTypeName);
    formData.append("DocumentTitle", this.azuredata.DocumentTitle);
    formData.append("DocumentDescription", this.azuredata.DocumentDescription);
    formData.append("OrganizationId", this.azuredata.OrganizationId);

    await this.validateDocumentIsNull();

      if(this.docValidations == true){
      await axios
      .post(`${this.fileuploadurl}/create`, formData, {
        headers: {
          Authorization: "Bearer " + this.idToken,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("retreieved data", response);
        this.documentId = response.data.data[0];
        this.documentVersionId = [];
        this.documentVersionId = response.data.data[1];
        this.CreateDocumentEIDSA();
      })
      .catch((err) => {
        console.log("error", err);
      });
      }
    },
    async CreateDocumentEIDSA(){
    const idtoken = store.getters.getIdToken;
    const storageCode = store.getters.getStorageCode;
    await axios
    .post(
      `${this.baseurl}/management/document/createdocument`,
      {
        documentReferenceId: this.documentId,
        documentTypeId: this.formsid,
        typeValue:storageCode,
        documentType: "form",
        documentName: this.azuredata.Document.name,
        documentDescription:this.azuredata.DocumentDescription,
        folderId: "",
        folderName: "",
        documentVersions: [
          {
            documentVersionId: this.documentVersionId,
                versionNumber: "1",
                versionDescription: "",
                status: "",
                fileName: "",
                uploadedBy: "",
                uploadedOn: "2022-07-06T02:45:17.946Z",
                uploadedUserId: "",
                approveList: []
              }
            ]
          },
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      console.log("retreieve data", response);
      alert(this.$t("navigations.document created successfully"));
      this.CloseUploadModal()
    })
    .catch((err) => {
      console.log("error", err);
      console.log(err.data);
    });
    },
    async validateDocumentIsNull() {
    if (this.azuredata.Document != "") {this.docValidations= true;}

    else {this.docValidations = false;}
    },
    async getDocuments(){
      const idtoken = store.getters.getIdToken;
    await axios
    .get(
      ` ${this.baseurl}/management/document/listdocument?documentTypeId=` +this.formsid ,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log(" get doc result", res.data);
      this.documentlist = res.data.results;
    })
    .catch((err) => {
      console.log("error..", err);
    });
    },
    async getDocumentById(docId) {
    this.currentdocId = docId;
    const idtoken = store.getters.getIdToken;
    await axios
      .get(
        `${this.baseurl}/management/document/getdocumentbyid?id=` + docId,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        this.Document = res.data;
        this.loaddata = true;
        this.documentName = this.Document.documentName;
      });
    },
    async deleteAzureDocument(documentId){
    console.log("docid is ", documentId);
    await this.getDocumentById(documentId);
    console.log("document is ", this.Document);
    if (
      confirm(
        this.$t('navigations.are you sure you want to delete this document?')
      )
    ) {
      await axios
        .delete(
          `${this.fileuploadurl}/deletedocument?id=${this.Document.documentReferenceId}`,
          this.headerValues
        )
        .then((response) => {
          console.log(response.data);
          alert(this.$t('navigations.document deleted successfully'));
          this.deleteDocument(documentId);
        })
        .catch((err) => {
          console.log("nope", err);
        });
    }
    },
    async deleteDocument(docId) {
    const idtoken = store.getters.getIdToken;
    console;
    await axios
      .delete(`${this.baseurl}/management/document/delete?Id=` + docId, {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.ClosemodalView()
      })
      .catch((err) => {
        console.log(err);
      });
    },
    async downloadfile(docId) {
    const idtoken = store.getters.getIdToken;
    await this.getDocumentById(docId);
    await this.getdmsdocument(docId);
    const fileName =
      this.Document.documentVersions[
        this.Document.documentVersions.length - 1
      ].documentVersionId;
    var a = document.createElement("a");
    a.href = `${this.fileuploadurl}/file/${this.Document.documentReferenceId}?versionid=${fileName}`
    a.download = "FILENAME";
    a.click();
    },
    async viewDocument(docId) {
      // this.ShowDocName = false;
      await this.getDocumentById(docId);
      await this.getdmsdocument(docId);
      const versionId =
        this.Document.documentVersions[
          this.Document.documentVersions.length - 1
        ].documentVersionId;

        const filename =
        this.Document.documentVersions[
          this.Document.documentVersions.length - 1
        ].fileName;
      this.$router.push(
        `/documentview/${this.Document.documentReferenceId}/${filename}/${versionId}`
      );
    },
    async getdmsdocument() {
    console.log("dms document", this.Document);
    await axios
      .get(
        `${this.fileuploadurl}/getallfiles/${this.Document.documentReferenceId}`,
        {
          headers: {
            Authorization: "Bearer " + this.idToken,
            "Content-Type": "application/json",
            responseType: "blob",
          },
        }
      )
      .then((res) => {
        this.documentById = res.data;
        console.log("Docdownld............", this.documentById);
      })
      .catch((err) => {
        console.log(err);
      });
    },
    // End of  Form Document

    async helloButton(event){
      this.selectedFile = "";
      this.filedata = "";
      console.log("Hello attribute");
        var input = event.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = (e) => {
              this.filedata = e.target.result;
            }
            reader.readAsDataURL(input.files[0]);
            this.selectedFile = input.files[0];
            console.log(this.selectedFile);
        }
        await this.submitFile();

    },
    async submitFile() {
      var formData = new FormData();
      formData.append("Document", this.selectedFile);
      formData.append("DocumentTypeName", "FORMS_DEV_FILEUPLOAD");
      formData.append("DocumentTitle", "");
      formData.append(
        "DocumentDescription",
        ""
      );
      formData.append("OrganizationId", "");
      console.log("final data", this.azuredata);

          await axios
            .post(`${this.fileuploadurl}/create
`, formData, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "multipart/form-data",
              },
            })

            .then((response) => {
              console.log("retreieved data", response);
              this.formdata[`pageNo${pageno}`][fieldName] = `${response.data.data[0]},${response.data.data[1]}`
            })
            .catch((err) => {
              console.log(err.data);
            });
    },
    async getdownloadFilename(key,receivedfileid) {
      const fileUrl = `${this.fileuploadurl}/file/${
        receivedfileid.split(',')[0]
      }?versionid=${
        receivedfileid.split(',')[1]
      }`;

      try {
        const response = await axios.get(fileUrl, {
          responseType: 'blob'
        });
        const fileData = response.data;
        // this.saveFile(fileData);
        if (response.headers['content-disposition']) {
          const match = response.headers['content-disposition'].match(/filename=(.*)/);
          if (match && match.length > 1) {
            this.fileName = match[1];
            const regex = /"(.*?)"/g;
              const matches = match[1].match(regex);

              if (matches) {
                const extractedText = matches.map(match => match.slice(1, -1));
                const lastIndex = extractedText[0].lastIndexOf('/');
                console.log(extractedText[0].substring(lastIndex + 1)); 
                 this.downloadedfilenames[key]=extractedText[0].substring(lastIndex + 1);
                 console.log("names are",this.downloadedfilenames[key])
                // ["quoted", "with", "quotes"]
                            } else {
                console.log('No matches found.');
                                    }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async downloaduploadedfile(receivedfileid){
      const url = `${this.fileuploadurl}/file/${
        receivedfileid.split(',')[0]
      }?versionid=${
        receivedfileid.split(',')[1]
      }`;
      console.log(url);
          const link = document.createElement('a');
          link.href = url;
          document.body.appendChild(link);
          link.click();
    },
    async getPageName(){
      const idtoken = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/forms/forms/getallpages?FormId=${this.formsid}&api-version=1`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        this.pageName=res.data
     })
     .catch((err) => {
       console.log(err);
     });
    },
    async getPages(){
      this.pages=[];
      this.htmlarray[0].pages.forEach(page => {
         var x = { 
          pageName : page.pageName,
          pageNumber : page.pageNo
        };
        this.pages.push(x);
      });
    },
    async getFormHeader(){
      const idtoken = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/forms/forms/getformname?formID=${this.formsid}&api-version=1`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        this.formHeader=res.data
     })
     .catch((err) => {
       console.log(err);
     });
    },
    async patientId(patientId){
      const idtoken = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/management/patient/getpatientbyid/${patientId}?api-version=1`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        this.patientsId=res.data.subjectId
     })
     .catch((err) => {
       console.log(err);
     });
    },
    async siteId(siteId){
      const idtoken = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/management/site/getbyid?id=${siteId}&api-version=1`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        this.sitesId=res.data.siteCode
     })
     .catch((err) => {
       console.log(err);
     });
    },
    async verifyform(){
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      console.log(`formId: ${this.formsid},
      verifiedBy: ${usermail},
      verifiedAt: ${new Date()}`);
      await axios
      .put(
        `${this.baseurl}/forms/forms/verifyformstatus`,
          {
            "formId": this.formsid,
            "verifiedBy": usermail,
            "verifiedAt": new Date()
          },
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
      .then((res) => {
         console.log(res);
         alert(this.$t('navigations.form verified successfully'));
         this.receivedData[0].formStatus = 'Verified';
         this.forms[0].verifiedBy=usermail
         this.forms[0].verifiedAt=new Date()
         this.isVerified='Verified'
      })
      .catch((err) => {
        console.log(err);
      });
    },
    async setformid(){
      this.isPatientValue=this.isPatientVisit
      this.formsid = this.formsesid.trim();
      await this.getdata();
    },
    async testvarfun(field, value, pgn){
      if(this.isfieldvalidate){
        console.log(this.isfieldvalidate);
        this.openModal(field, value, pgn);
      }
    },
    gotoraiseQuary(queryType){
        this.xyz=this.formsid+"modelNameIs_"+this.modelname;
        this.$router.push(`/raiseQuery/${this.xyz}/${queryType}/${this.pageno}`);

    },
    async setformtemplate(){
      this.htmlarray = this.receivedData;
        await this.bindstatus()
        this.getPages()
    },
    getformtemplate: async function(tempid) {
      const idtoken = store.getters.getIdToken;     
      await axios
      .get(
        `${this.baseurl}/forms/template/get/${tempid}?api-version=1.0`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
      .then(async (res) => {
        this.htmlarray[0] = res.data;
        console.log("htmlarray",this.htmlarray);
        console.log("receivedData",this.receivedData);
        await this.htmlarray[0].pages.forEach((page)=>{
          page.field.forEach((fielddata,index)=>{
            if(page.field[index].type=='repeatingfield')
            {
              this.receivedData[0].pages.forEach((recievedpage)=>{
                page.field[index].repeatingFieldData = recievedpage.field[index].repeatingFieldData;
                page.field[index].repeatingFields = recievedpage.field[index].repeatingFieldData;
              })
            // if(this.newrepeatingField[index]!=null && this.newrepeatingField[index]!=undefined && this.newrepeatingField[index].length!=0)
            // {
            //   page.field[index].repeatingFields=this.newrepeatingField[index]
            // }
            
            // else {            
            //   fielddata.repeatingFields.forEach((data)=>{
            //   data["value"]=""
            // })
          
            // fielddata.repeatingFields=[{
            //   fieldIdentifier : 1,
            //   fields: fielddata.repeatingFields,
            // }]
          }
          }
          )
          console.log("htmlarray",this.htmlarray);
        })
        await this.bindstatus()
        this.getPages()
        
      })
      .catch((err) => {
        console.log(err);
      })
      this.bindData();
    },
    bindstatus() {

      this.receivedData[0].pages.forEach((eachpages) => {
        let index = this.receivedData[0].pages.indexOf(eachpages);
        var i = 0;
        eachpages.field.forEach((item) => {
          this.htmlarray[0].pages[index].field[i].privilege = item.privilage;
          // this.htmlarray[0].pages[index].field[i].privilege = "View";
          i++;
        });
      });
    },
    async isvalidatefun(fieldname, fieldidentifier, receveddata) {
    
      let idtoken = store.getters.getIdToken;
      
      let checkdata = {
        "fiedValue": receveddata,
        "fieldName": fieldname,
        "templateId": this.receivedData[0].templateId,
        "computeFields": {}
      }
      await axios.post(`${this.baseurl}/forms/editcheck/validatefield`,checkdata,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          if(res.data.validations.length > 0){
          const errval = res.data.validations[0];
          if(fieldidentifier == ""){
          if(errval.messageType == "Alert"){
            if(!this.isvalid.includes(fieldname)){
           this.isvalid.push(fieldname);
         }
          }
          this.errormsg[fieldname] = errval.message;
          this.messagetype[fieldname] = errval.messageType;
          
        }
        else{
          if(errval.messageType == "Alert"){
            if(!this.isvalid.includes(fieldname+fieldidentifier)){
           this.isvalid.push(fieldname+fieldidentifier);
         }
          }
          this.errormsg[fieldname+fieldidentifier] = errval.message;
          this.messagetype[fieldname+fieldidentifier] = errval.messageType;
        }
      }
        return;
        })
        .catch((err) => {
          console.log(err);
          return;
        });
      
      },
      isrepeatedRequired: async function (value, fieldIdentifier,repeatedfieldName, type) {
        if(type == "checkbox"){
          if(value == "false" || value == false || value == "" || value == null || value == undefined){
            await this.isvalidatefun(repeatedfieldName,fieldIdentifier,"false");
          }
          // else {
          //   if(this.isvalid.includes(repeatedfieldName+fieldIdentifier)){
          //     this.isvalid =  this.isvalid.filter(item => item !== repeatedfieldName+fieldIdentifier);
          //   }
          // }
        }
          else {
        if (value == "" || value == null || value == undefined || value == false) {
          await this.isvalidatefun(repeatedfieldName,fieldIdentifier,"");
        }
        //  else {
        //   if(this.isvalid.includes(repeatedfieldName+fieldIdentifier)){
        //     this.isvalid =  this.isvalid.filter(item => item !== repeatedfieldName+fieldIdentifier);
        //   }
        // }
      }
      },
      isRequired: async function (value, compid, type) {
        if(type == "checkbox"){
          if(value == "false" || value == false || value == "" || value == null || value == undefined){
            await this.isvalidatefun(compid,"","false");
          }
          // else {
          //   if(this.isvalid.includes(compid)){
          //     this.isvalid =  this.isvalid.filter(item => item !== compid);
          //   }
          // }
        }
        else{
        if (value == "" || value == null || value == undefined) {
          await this.isvalidatefun(compid,"","");
        } 
        // else {
        //   if(this.isvalid.includes(compid)){
        //     this.isvalid =  this.isvalid.filter(item => item !== compid);
        //   }
        // }
      }
        return;
      },
    ischeckboxRequired: function (value, compid) {
      if (value == "" || value == null || value == undefined || value == "false") {
        this.errormsg[compid] = "Please check the checkbox to proceed.";
          this.messagetype[compid] = "Alert"
          if(!this.isvalid.includes(compid)){
           this.isvalid.push(compid);
         }
      } else {
        // this.isvalid.pop();
      }
    },
    isrepeatedcheckboxRequired: function (value, compid, fieldIdentifier,repeatedfieldName) {
      if (value == "" || value == null || value == undefined || value == "false") {
        this.errormsg[repeatedfieldName+fieldIdentifier] = "Please check the checkbox to proceed.";
          this.messagetype[repeatedfieldName+fieldIdentifier] = "Alert"
          if(!this.isvalid.includes(repeatedfieldName+fieldIdentifier)){
           this.isvalid.push(repeatedfieldName+fieldIdentifier);
         }
      } else {
        // this.isvalid.pop();
      }
    },
    istruedateandtime: function (value, compid) {
      if (value != "") {
        const date = new Date(value);
          const year = date.getFullYear();
          if (isNaN(year)) {
            this.errormsg[compid] = "Invalid date and time";
            this.isvalid.push("invdate");
            this.errorclasslist[compid] = "form-control errorlist";
          } 
       else {
        this.errormsg[compid] = "";
        // this.isvalid.pop();
      }
    }
    },
getdata: async function() {
  this.receivedData[0] = await showformService.getdatafromapi(this.formsid);
  this.receivedData[0].pages.forEach((item) => {
    item.field.forEach((subitem,index) => {
      if(subitem.type == 'repeatingfield')
        {
          if(subitem.repeatingFieldData!=null && subitem.repeatingFieldData.length!=0)
          {
            this.newrepeatingField[index]=subitem.repeatingFieldData
          }
        }}
    )})
  this.isVerified=this.receivedData[0].formStatus
  await this.getformtemplate(this.receivedData[0].templateId);
  let mapformvariables = {
    "createdUserEmail": this.receivedData[0].createdUserEmail,
    "createdUserId": this.receivedData[0].createdUserId,
    "createdAt": this.receivedData[0].createdAt,
    "lastModifiedUserEmail": this.receivedData[0].lastModifiedUserEmail,
    "lastModifiedUserId": this.receivedData[0].lastModifiedUserId,
    "lastModifiedAt": this.receivedData[0].lastModifiedAt
  };
  store.dispatch("setmapform", mapformvariables);
},

    bindData: function() {
      
      this.totalpages = this.htmlarray[0].totalPages;
      this.htmlarray[0].pages.forEach((item) => {
        this.groups[item.pageNo] = [];
        this.formdata[`pageNo${item.pageNo}`] = {};
        if (!this.formdata[`pageNo${item.pageNo}`]) {
          this.formdata[`pageNo${item.pageNo}`] = {};
          }
        item.field.forEach((subitem) => {
          if(!this.groups[item.pageNo].includes(parseInt(subitem.groupOrderNo))){
            this.groups[item.pageNo].push(parseInt(subitem.groupOrderNo));
            }    
            
          if(subitem.type=='repeatingfield')
          {
             this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.repeatingFields;
          this.repeatedgroupgroupnos[subitem.fieldName] = [];
          
          subitem.repeatingFields.forEach((field,fieldIndex)=>{
            if(field.fields!=null && field.fields.length!=0 && field.fields!=undefined)
            {
            field.fields.forEach((subfields)=>{
              if(!this.repeatedgroupgroupnos[subitem.fieldName].includes(subfields.groupOrderNo)){
              this.repeatedgroupgroupnos[subitem.fieldName].push(subfields.groupOrderNo);
               }
              
               if(subfields.type == 'numberanddate')
               {
                this.repeatednumberanddateval[subfields.fieldName+field.fieldIdentifier] = {
                  "numberdata":"",
                  "datedata":"",
                }
                if(subfields.value){
                  if(subfields.value.includes("_")){
                  let tempdatetimearray = subfields.value.split("_");
                  const numval = tempdatetimearray[1]
                  const dates = tempdatetimearray[0];
                  this.repeatednumberanddateval[subfields.fieldName+field.fieldIdentifier] = {
                    "numberdata":numval,
                    "datedata":dates,
                  }
                }
               }}
              });
            }
            });
           
          }
          if(subitem.type == 'numberanddate'){
            this.numberanddateval[subitem.fieldName] = {
              "numberdata":"",
              "datedata":"",
            }
          }
          if(this.inputElements.includes(subitem.type)){
            console.log("autofield",this.autofield[0]);
            if(this.autofield[0].site_sub_autofill[0].autofill == true){
              let autofillsiteid = store.getters.getautofillsiteid;
              let autofisubjectid = store.getters.getautofillsubjectid;
              if(subitem.fieldName == this.autofield[0].site_sub_autofill[0].labelsubject){
                console.log("condition for adding subjectid");
                this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = autofisubjectid;
              }
              if(subitem.fieldName == this.autofield[0].site_sub_autofill[0].labelsite){
                console.log("condition for adding siteid",);
                this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = autofillsiteid;
              }
            }
          }
          if (subitem.validation != "") {
            subitem.validation.forEach((valid) => {
              if (valid == "Required" || valid == "REQUIRED") {
                this.requiredclasslist[subitem.fieldName] = "required";

              }
            });
          }
      })
    })
    console.log("form data is ",this.formdata)
      this.bindValue();
    },
    isautofill(fieldName){
      let isautofillrequired = store.getters.getautofillenabled;
      if(this.autofield[0].site_sub_autofill[0].autofill == true){
        if(fieldName == this.autofield[0].site_sub_autofill[0].labelsubject || fieldName == this.autofield[0].site_sub_autofill[0].labelsite){
          if(isautofillrequired != "false"){
            return true;
          }
          else{
            return false;
          }
        }
        else{
          return false;
        }
      }
      else{
        return false;
      }
    },
    async repeatednumbanddatefocusoutfun(fieldname){
      if(!this.repeatednumberanddateval.hasOwnProperty(fieldname)){
        this.repeatednumberanddateval[fieldname] = {
          "numberdata":"",
          "datedata":"",
        }
      }
    },
    bindValue: function() {
      console.log("Values are", this.receivedData[0].pages)
  this.receivedData[0].pages.forEach((item,pageindex) => {
    item.field.forEach((subitem,fieldIndex) => {
      if(subitem.type == 'grid'){
        this.gridoptfun(subitem.fieldName, subitem.options[0],subitem.value)
      }
      // if(subitem.type == 'date' && subitem.value){
      //   const dates = subitem.value.slice(0,4);
      //   if(dates.includes("-") || dates.includes("/")){
      //     if(subitem.value.slice(3,5).includes("-") || subitem.value.slice(3,5).includes("/") ){
      //         this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = `${subitem.value.slice(5,9)}-0${subitem.value.slice(3,4)}-${subitem.value.slice(0,2)}`;
      //     }
      //     else{
      //   this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = `${subitem.value.slice(6,10)}-${subitem.value.slice(3,5)}-${subitem.value.slice(0,2)}`;
      //     }
      // }
      //   else{
      //   this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.value;
      //   }
      // }
      
      else if(subitem.type == 'numberanddate'){
        this.numberanddateval[subitem.fieldName] = {
          "numberdata":"",
          "datedata":"",
        }
        if(subitem.value){
        if(subitem.value.includes("_")){
        let tempdatetimearray = subitem.value.split("_");
        const numval = tempdatetimearray[1]
        const dates = tempdatetimearray[0];
        this.numberanddateval[subitem.fieldName] = {
          "numberdata":numval,
          "datedata":dates,
        }
        this.numberanddateval[subitem.fieldName].datedata=this.getformatDateNum(this.numberanddateval[subitem.fieldName].datedata);
        this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.value;
      }
     }
      }
      else if(subitem.type == 'repeatingfield'){
        subitem.repeatingFieldData.forEach((fields)=>{
          fields.fields.forEach((eachfields, eachfieldindex)=>{
            this.visibility[eachfields.fieldName+fields.fieldIdentifier] = eachfields.visibility;
            if(subitem.value!=null && eachfields.value!=""){
              let selectedOptionforRF = null;
              if(eachfields.type == "radio"){
                selectedOptionforRF = eachfields.options.find(
                  (opt) => opt.codevalue === eachfields.value
                );
              }
            this.focusOutFunctionForRF(item.pageNo,subitem.fieldName,fieldIndex,eachfieldindex,eachfields.fieldName,fields.fieldIdentifier, selectedOptionforRF ? selectedOptionforRF.optionname : "");
            }
            if(eachfields.type == "numberanddate"){
              this.repeatednumberanddateval[eachfields.fieldName+fields.fieldIdentifier] = {
                "numberdata":"",
                "datedata":"",
              }
              if(eachfields.value){
              if(eachfields.value.includes("_")){
              let tempdatetimearray = eachfields.value.split("_");
              const numval = tempdatetimearray[1]
              const dates = tempdatetimearray[0];
              this.repeatednumberanddateval[eachfields.fieldName+fields.fieldIdentifier] = {
                "numberdata":numval,
                "datedata":dates,
              }
              this.repeatednumberanddateval[eachfields.fieldName+fields.fieldIdentifier].datedata=this.getformatDateNum(this.repeatednumberanddateval[eachfields.fieldName+fields.fieldIdentifier].datedata)
            }
          }
            }
            if(eachfields.type == "dateandtime"){
              if(eachfields.value){
                let newformatteddate = this.formatDate(eachfields.value,'dateandtime');
                const lastIndex = newformatteddate.lastIndexOf('.');
                const result = newformatteddate.slice(0, 16);
                eachfields.value = result;       
              }
                
            }
            if(eachfields.type == "date"){
              if(eachfields.value && eachfields.value!=null){
              eachfields.value = eachfields.value;
              }       
                 
            }
          })
        })
        
        this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.repeatingFieldData;
    }
    else if(subitem.type == 'dateandtime'){
      if(subitem.value && subitem.value!=null)
      { 
        let newformatteddate = this.formatDate(subitem.value,'dateandtime');
        const lastIndex = newformatteddate.lastIndexOf('.');
        const result = newformatteddate.slice(0, 16);
        this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = result;
      }
     
      }
      else if(this.inputElements.includes(subitem.type)){
        if(subitem.value && subitem.value!=null || subitem.value!="")
        { 
          this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.value;
        }
       
        }
      else{
        this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = subitem.value;
      }
      this.visibility[subitem.fieldName] = subitem.visibility;
      if(subitem.value!=null && subitem.value!=""){
        let selectedOption = null;
        if(subitem.type == "radio"){
          selectedOption = subitem.options.find(
            (opt) => opt.codevalue === subitem.value
          );
        }
      this.focusOutFunction(item.pageNo,subitem.fieldName, selectedOption ? selectedOption.optionname : "")
      }
    });
  });
    },
    convertDateFormat:function(inputString) {
      
      if(!this.isValidDateFormat(inputString) && inputString!=null && inputString!=""){
        const [timePart, datePart] = inputString.split('_');
        const [hour, minute, second] = timePart.split(':');
        const [day, month, year] = datePart.split('-');
        const convertedDate = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
        const isoFormatString = convertedDate.toISOString().slice(0, 16);

        return isoFormatString;
            }
            else{
              return inputString;
            }
    },
    isValidDateFormat:function(dateString) {
      const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
      return dateFormatRegex.test(dateString);
    },
    async getbyidforautofill() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/study/getbyidforautofill/${this.stdyid}`
          ,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          console.log("autofield",res.data);
          if(res.data.config != null){
          this.autofield = res.data.config;
          }
        })
        .catch((err) => {
          console.log("Have error After listed");
          console.log(err);
        });
    },
    
    validate: async function () {
      this.errorsort = {};
      this.errorclasslist = {};
    
      // Loop through pages in htmlarray[0].pages
      // But only validate pages up to the current this.pageno
      for (const item of this.htmlarray[0].pages) {
        // If the page is less than or equal to current page, validate
        if (item.pageNo <= this.pageno) {
          const pagenumber = `pageNo${item.pageNo}`;
    
          for (const subitem of item.field) {
            // Repeating field scenario
            if (subitem.type === 'repeatingfield') {
              this.isrepeatdatavalid[subitem.fieldName] = {};
    
              // Loop through repeatingFields
              for (const [rfieldIndex, eachrepeatingdata] of subitem.repeatingFields.entries()) {
                console.log("subitem.repeatingFields",subitem.repeatingFields);
                // Loop through the fields in repeatingFields
                for (const [fieldindex, field] of eachrepeatingdata.fields.entries()) {
                  if (field.validation) {
                    // Loop through each validation
                    for (const valid of field.validation) {
                      if (valid === 'Required' || valid === 'REQUIRED') {
                        await this.isrepeatedRequired(
                          this.formdata[pagenumber][subitem.fieldName][rfieldIndex]['fields'][fieldindex].value,
                          eachrepeatingdata.fieldIdentifier,
                          field.fieldName,
                          field.type
                        );
                      }
                    }
                  }
                }
              }
            } 
            // Non-repeating field scenario
            else {
              if (subitem.validation) {
                for (const valid of subitem.validation) {
                  if (valid === 'Required' || valid === 'REQUIRED') {
                    await this.isRequired(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName,
                      subitem.type
                    );
                  }
                }
              }
            }
          }
        }
      }
    
      // After validating pages <= this.pageno,
      // let's find the first "Alert" error across those pages
      this.$nextTick(() => {
        // Grab references to all ValidationMessage components
        // toRaw() if you are in Vue 3 and need to unwrap proxies
        const errorMsgs = toRaw(this.$refs.validationMsgs) || [];
    
        // We only care about messages that actually have an error
        // and whose messageType is "Alert"
        let visibleErrors = errorMsgs.filter((vm) => {
          return vm.errorMsg && vm.errorMsg.trim() !== "" && vm.messageType === "Alert";
        });
    
        // If no errors, do nothing
        if (visibleErrors.length === 0) return;
    
        // Sort by the data-page attribute so we jump to the
        // earliest page that has an error.
        visibleErrors.sort((a, b) => {
          const pageA = parseInt(a.$el.dataset.page, 10);
          const pageB = parseInt(b.$el.dataset.page, 10);
          return pageA - pageB;
        });
    
        // The first error is now guaranteed to be from the lowest page
        const firstError = visibleErrors[0];
        const el = firstError.$el;
        const pageWithError = parseInt(el.dataset.page, 10);
        // If we're not already on that page, switch
        if (this.pageno !== pageWithError) {
          this.pageno = pageWithError;
          // Wait for re-render
          this.$nextTick(() => {
            el.scrollIntoView({ behavior: "smooth", block: "center" });
          });
        } else {
          // Already on that page, just scroll
          el.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      });
      
      return;
    },
// save and edit starts
async repeatingvalid(pageNo, fieldName) {
  this.isrepeatvalid[fieldName] = [];
  this.isrepeatdatavalid[fieldName] = {};
  const repeatedData = this.formdata[`pageNo${pageNo}`][fieldName];
  if(repeatedData!=null && repeatedData!=undefined)
  {
  for (const eachrepeatedata of repeatedData) {   
    this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier] = {};
    for (const eachrepeateingfields of eachrepeatedata.fields) {
      this.isrepeatvalid[fieldName].push(false);
      this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "";
     
      if(eachrepeateingfields.validation.includes('Number')&& eachrepeateingfields.value!="" && eachrepeateingfields.value!=null && eachrepeateingfields.value!=undefined){
        if(this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] == ""){
        if (isNaN(eachrepeateingfields.value)) {
          this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "Please enter a number";
          this.shouldContinue = false;
        }
        }
      }
      if (eachrepeateingfields.validation.includes('Alphanumeric')&& eachrepeateingfields.value!="" && eachrepeateingfields.value!=null && eachrepeateingfields.value!=undefined) {
        if(this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] == ""){
        if (eachrepeateingfields.value.match(/^[0-9a-zA-Z]+$/)) {
          this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "";
        } else {
          this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "Please enter an alphanumeric value";
          this.shouldContinue = false;
        }
      }
      }
      if (eachrepeateingfields.validation.includes('email')&& eachrepeateingfields.value!="" && eachrepeateingfields.value!=null && eachrepeateingfields.value!=undefined) {
        if(this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] == ""){
        if (eachrepeateingfields.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
          this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "";
        } else {
          this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "Invalid email address!";
          this.shouldContinue = false;
        }
      }
      }
      if (eachrepeateingfields.validation.some(item => item.includes('lengthrange')) && eachrepeateingfields.value!=''&& eachrepeateingfields.value!=null&& eachrepeateingfields.value!=undefined) {
        if(this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] == ""){
          let regExp = /\(([^)]+)\)/;
          let matches = regExp.exec(eachrepeateingfields.validation);
          const myArr = matches[1].split(",");
          let minrange = parseInt(myArr[0]);
          let maxrange = parseInt(myArr[1]);
          console.log("eachrepeateingfields",eachrepeateingfields);
        if (eachrepeateingfields.value.length >= minrange && eachrepeateingfields.value.length <= maxrange) {
          this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "";
        } else {
          this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "Number of characters should be between " + minrange + " and " + maxrange;
          this.shouldContinue = false;
        }
      }
      }
      if (eachrepeateingfields.validation.some(item => item.includes('valuerange')) && eachrepeateingfields.value!=''&& eachrepeateingfields.value!=null&& eachrepeateingfields.value!=undefined) {
        if(this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] == ""){
        let regExp = /\(([^)]+)\)/;
        let matches = regExp.exec(eachrepeateingfields.validation);
        const myArr = matches[1].split(",");
        let minval = parseInt(myArr[0]);
        let maxval = parseInt(myArr[1]);
        let intval = parseInt(eachrepeateingfields.value);
      if (intval <= maxval && intval >= minval) {
        this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "";
      } else {
        this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "Please enter a value between " + minval + " and " + maxval;
          this.shouldContinue = false;

    }
    }
    }
    if (eachrepeateingfields.validation.includes("Required") || eachrepeateingfields.validations.includes("REQUIRED")) {
      if(eachrepeateingfields.type == "numberanddate"){
        console.log("fieldNameeeee",eachrepeateingfields.fieldName);
        console.log("this.repeatednumberanddateval",this.repeatednumberanddateval)
        let numvalue = this.repeatednumberanddateval[eachrepeateingfields.fieldName+eachrepeatedata.fieldIdentifier].numberdata;
        let datevalue = this.repeatednumberanddateval[eachrepeateingfields.fieldName+eachrepeatedata.fieldIdentifier].datedata;
      if (numvalue == "" || datevalue == "" || numvalue == undefined || datevalue == undefined || numvalue == null || datevalue == null) {
        // this.isrepeatvalid[fieldName].pop(); 
        // this.isrepeatvalid[fieldName].push(true);
        this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "Please enter number and date";
      this.shouldContinue = false;
      }
      else{
        eachrepeateingfields.value = datevalue + "_" + numvalue;
        this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "";
         }
    }
    if(eachrepeateingfields.type != "numberanddate") {
      if(eachrepeateingfields.type == "checkbox"){
        if(this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] == ""){
          if (eachrepeateingfields.value == null || eachrepeateingfields.value == "" || eachrepeateingfields.value == undefined || eachrepeateingfields.value == "false") {
            this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "Please enter a value";
            this.shouldContinue = false;
          }
      }
    }
    else{
      if(this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] == ""){
      if (eachrepeateingfields.value == null || eachrepeateingfields.value == "" || eachrepeateingfields.value == undefined) {
        this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "Please enter a value";
        this.shouldContinue = false;
      }

    }
  }
    }
   }
   if(eachrepeateingfields.type == "scales"){
    if (eachrepeateingfields.value != "" && eachrepeateingfields.value != null && eachrepeateingfields.value != undefined) {
  
  if(parseInt(eachrepeateingfields.value)<= parseInt(eachrepeateingfields.limit[0].max) && parseInt(eachrepeateingfields.value) >= parseInt(eachrepeateingfields.limit[0].min)){
    eachrepeateingfields.value=eachrepeateingfields.value.toString();
    this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "";
  } else {   
    this.isrepeatdatavalid[fieldName][eachrepeatedata.fieldIdentifier][eachrepeateingfields.fieldName] = "Please enter a value between "+eachrepeateingfields.limit[0].min+" and "+eachrepeateingfields.limit[0].max;
  this.shouldContinue = false;  
  }    
  }
  }
  if(eachrepeateingfields.type == "numberanddate"){
    let numvalue = this.repeatednumberanddateval[eachrepeateingfields.fieldName+eachrepeatedata.fieldIdentifier].numberdata;
    let datevalue = this.repeatednumberanddateval[eachrepeateingfields.fieldName+eachrepeatedata.fieldIdentifier].datedata;
    eachrepeateingfields.value = datevalue + "_" + numvalue;
  }
    
  }
}}
},
updateCheckboxValue(pageNo, fieldName, index, fieldindex, checked) {
  const stringValue = checked ? 'true' : 'false';
  this.formdata[`pageNo${pageNo}`][fieldName][index]['fields'][fieldindex].value = stringValue;
},
nextfun: async function () {
  if (this.isvalidate) {    
    await this.validate();
  }
  if (this.isvalid.length == 0) {
    if (this.shouldContinue){
    let totalpg = parseInt(this.totalpages);
    await this.updateFun();
    if(this.isupdated){
      this.pageno++;
      }
    }
    // if (this.pageno < totalpg) {
    //   if (this.issavefun) {
    //     if (this.pageno == 1) {  
    //       this.updateFun();
    //     } else {
    //       this.editFun();
    //     }
    //   }
    //   this.pageno++;
    // }
  }
},
async updateFun() {
  this.sndData.pages= [];
  this.isupdated = false;
  this.sndData.formId = this.receivedData[0].formId
  this.sndData.dataSource = "Web Browser"
  this.sndData.sourceID = this.iPaddress
  let repeatedfieldsavearray = {}
  for (const eachpages of this.htmlarray[0].pages) {
  let createdpage = {};
  createdpage.pageName = eachpages.pageName;
  createdpage.pageNo = eachpages.pageNo;
  createdpage.pageStatus = eachpages.status;
  createdpage.field = [];
 
  for (const subitem of eachpages.field) {
      let val;
      if(subitem.type == 'checkbox'){
        val = (this.formdata[`pageNo${eachpages.pageNo}`][subitem.fieldName] != null) ? (this.formdata[`pageNo${eachpages.pageNo}`][subitem.fieldName].toString()) : "false";
      }
      else if (subitem.type == 'numberanddate'&& this.numberanddateval[subitem.fieldName]){
        if(this.numberanddateval[subitem.fieldName].datedata!='' && this.numberanddateval[subitem.fieldName].numberdata!='')
        {
          val = this.formatDateNum(this.numberanddateval[subitem.fieldName].datedata) + "_" + this.numberanddateval[subitem.fieldName].numberdata;
        }
        else{
          val=""
        }
       
      }
      else if (subitem.type == 'time') {
        val = this.convertTime(this.formdata[`pageNo${eachpages.pageNo}`][subitem.fieldName])
      }
      else if (subitem.type == 'year') {
        val = this.formdata[`pageNo${eachpages.pageNo}`][subitem.fieldName]
      }
      else if (subitem.type == 'grid') {
        val = JSON.stringify(this.gridlinkeddatas[subitem.fieldName]);
      }
      else if (subitem.type == 'dateandtime') {
          val = this.formatDateTime(this.formdata[`pageNo${eachpages.pageNo}`][subitem.fieldName]);        
       
      }
      else if (subitem.type == 'uploadfile') {
          val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
      }
      else if(subitem.type == 'scales')
      {
        val = this.formdata[`pageNo${eachpages.pageNo}`][subitem.fieldName]?this.formdata[`pageNo${eachpages.pageNo}`][subitem.fieldName].toString():""
      }
      else if (subitem.type === 'repeatingfield') {
        repeatedfieldsavearray[subitem.fieldName] = subitem.repeatingFields.map((repeatField,repeatFieldIndex) => {
          console.log(" subitem.repeatingFields", subitem.repeatingFields);
          return {
            fieldIdentifier: repeatField.fieldIdentifier,
            fields: repeatField.fields.map((field,fieldindex) => {
               let value=this.formdata[`pageNo${eachpages.pageNo}`][subitem.fieldName][repeatFieldIndex]['fields'][fieldindex].value
            if (field.type == 'numberanddate'){
              if(field.value=="_"){
                value=""
              }
              else{
                value=this.payloadDate(this.repeatednumberanddateval[field.fieldName+repeatField.fieldIdentifier].datedata) + "_" +this.repeatednumberanddateval[field.fieldName+repeatField.fieldIdentifier].numberdata
              }             
            }
            if (field.type == 'dateandtime' && value){
              value=this.formatDateTime(value)                        
            }
            if (field.type == 'time' && value){
              value=this.convertTime(value)                        
            }
            if(this.visibility[field.fieldName+repeatField.fieldIdentifier] == "false" || this.visibility[field.fieldName+repeatField.fieldIdentifier] == false ){
              val = "";
            }
            return{
              ...field,
              value:value
            }
          })
        }
        })
      
    }
      else{
        val = this.formdata[`pageNo${eachpages.pageNo}`][subitem.fieldName];
      }
      if(this.visibility[subitem.fieldName] == "false" || this.visibility[subitem.fieldName] == false ){
        val = "";
      }
      createdpage.field.push({
        fieldName: subitem.fieldName,
        label: subitem.label,
        type: subitem.type,
        value: val,
        status: "Not validated",
        validation: subitem.validation,
        groupOrderNo: subitem.groupOrderNo,
        validatedBy: "",
        limit: subitem.limit,
        validatedAt: "",
        comment: "",
        required: subitem.required,
        qrData: subitem.qrData,
        options: subitem.options,
        imagePath: subitem.imagePath,
        repeatingFieldData:repeatedfieldsavearray[subitem.fieldName]
      });
    }
    this.sndData.pages.push(createdpage);
}
console.log("this.sndData",this.sndData);
if (this.shouldContinue){
  this.isupdated = await showformService.updateform(this.sndData);
}
  
},
//Date time format starts//
formatDate(dateString,type) {
  if(type=='dateandtime'){
    let momentObj;
      if (dateString.includes('_')) {
        const [time, date] = dateString.split('_');
        const [day, month, year] = date.split('-');
        const dateTimeString = `${year}-${month}-${day}T${time}`;
  
        // Create a moment object
        momentObj = moment(dateTimeString);
      } else {
        momentObj = moment(dateString);
      }
  
      // Check if the momentObj is valid
      if (!momentObj.isValid()) {
        return "";
      }
  
      // Format with local timezone offset
      return momentObj.format();
  }
  else if(type=='date'){
   // Split the input date by '-'
    const [day, month, year] = dateString.split('-');

    // Create the date string in the format YYYY-MM-DD
    const dateTimeString = `${year}-${month}-${day}`;

    // Create a moment object from the date string
    const momentObj = moment(dateTimeString, 'YYYY-MM-DD');

    // Check if the moment object is valid
    if (!momentObj.isValid()) {
      return "";
    }

    // Format the date as YYYY-MM-DD
    return momentObj.format('YYYY-MM-DD');
  }
},
formatDateTime(inputDate) {
  // const hours = date.getUTCHours().toString().padStart(2, "0");
  // const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  // const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  // const day = date.getUTCDate().toString().padStart(2, "0");
  // const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
  // const year = date.getUTCFullYear();

  // Convert input date to a Date object
  const date = new Date(inputDate);
  
    // Check if the date is valid
    if (isNaN(date)) {
      console.error("Invalid date");
      return "";
    }  
  // Extract date and time components
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = "00"; // Assuming seconds are always 00 if not provided
  
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear().toString();
  return `${hours}:${minutes}:${seconds}_${day}-${month}-${year}`;
},
formatDateNum(inputDate) { 

  // Convert input date to a Date object
  const date = new Date(inputDate);
      // Check if the date is valid
  if (isNaN(date)) {
    console.error("Invalid date");
    return "";
  } 
  // Extract date components
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = date.getFullYear().toString();
  return `${day}-${month}-${year}`;
},
getformatDateNum(inputDate) {
    // Assuming inputDate is in the format "DD-MM-YYYY"
    const [day, month, year] = inputDate.split("-");

    // Create a new Date object with the parsed values
    const date = new Date(`${year}-${month}-${day}`);

    // Ensure the date is valid
    if (isNaN(date)) {
      console.error("Invalid date",inputDate);
      return "";
    }

    // Extract date components with zero-padding
    const formattedDay = day.padStart(2, "0");
    const formattedMonth = month.padStart(2, "0");
    const formattedYear = year;
    return `${formattedYear}-${formattedMonth}-${formattedDay}`;
},
payloadDate(inputDate){
  const date = new Date(inputDate);

  if (isNaN(date)) {
    console.error("Invalid date", inputDate);
    return "";
  }
  
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;

},
convertTime(timeString) {
  // Split the time string into parts
  const timeParts = timeString.split(':');
  
  // Check if the seconds part is missing
  if (timeParts.length === 2) {
    // Append ':00' to add seconds
    return `${timeString}:00`;
  }
  
  // If the seconds are already present, return the original time string
  return timeString;
},
//date time format ends//

async savefun(){
      await this.validate();
      if (this.isvalid.length == 0) {
  await this.updateFun();
     
  if (this.shouldContinue){
    if(this.isupdated){
  // alert("Form saved successfully");
  alert(this.$t('navigations.form saved successfully'))

  await this.$router.go(-1);
  }
}
      }
},
async editFun(){
  const pgn = this.pageno - 1;
  let createdpage = {};
  createdpage.formId = this.formsid;
  createdpage.isArchived = true;
  createdpage.sourceID = this.iPaddress;
  createdpage.dataSource = "Web Browser";
  createdpage.pages = [];
  createdpage.pages[0] = {};
  createdpage.pages[0].pageName = this.htmlarray[0].pages[pgn].pageName;
  createdpage.pages[0].pageNo = this.htmlarray[0].pages[pgn].pageNo;
  createdpage.pages[0].pageStatus = this.htmlarray[0].pages[pgn].status;
  createdpage.pages[0].field = [];
  this.htmlarray[0].pages[pgn].field.forEach(async (subitem) => {
    let val;
    if (subitem.type == 'checkbox') {
      val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName]
          if(val){
            val =val.toString();
          }
          else{
            val="false"
          }
    }
    else if (subitem.type == 'numberanddate' && this.numberanddateval[subitem.fieldName]){
      val = this.formatDateNum(this.numberanddateval[subitem.fieldName].datedata) + "_" + this.numberanddateval[subitem.fieldName].numberdata;
    }
    else if (subitem.type === 'repeatingfield') {
      // await this.repeatingvalid(eachpages.pageNo, subitem.fieldName);
      repeatedfieldsavearray[subitem.fieldName] = this.formdata[`pageNo${eachpages.pageNo}`][subitem.fieldName];
      val = "";
  }
    else if (subitem.type == 'grid') {
      val = JSON.stringify(this.gridlinkeddatas[subitem.fieldName]);
    }
    else if (subitem.type == 'year') {
      val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName]
          if(val){
            val =val.toString();
          }
    }
    else if (subitem.type == 'uploadfile') {
        val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
    }
    else {
      val = this.formdata[`pageNo${this.pageno}`][subitem.fieldName];
    }
    if(this.visibility[subitem.fieldName] == "false" || this.visibility[subitem.fieldName] == false ){
      val = "";
    }
    createdpage.pages[0].field.push({
      fieldName: subitem.fieldName,
      label: subitem.label,
      type: subitem.type,
      value: val.toString(),
      status: "Not validated",
      validation: subitem.validation,
      groupOrderNo: subitem.groupOrderNo,
      limit: subitem.limit,
      validatedBy: "",
      validatedAt: "",
      comment: "",
      required: subitem.required,
      visibility: this.visibility[subitem.fieldName],
      options: subitem.options,
      imagePath: subitem.imagePath,
      qrData: subitem.qrData,
      audit: [
        {
          oldValue: "",
          newValue: "",
          validatedBy: "",
          validatedDate: "2021-08-26T03:39:38.382Z",
          validatedTime: "2021-08-26T03:39:38.382Z",
          status: true,
          comment: "",
        },
      ],
    });
  });
  // console.log(JSON.stringify(createdpage));
  showformService.editdata(createdpage);
  this.pageno++;
},
async reviewAndSave(){
  if (this.isvalidate) {    
    await this.validate();
  }
  if (this.isvalid.length == 0) {
  await this.updateFun();
  if (this.shouldContinue){
  const idtoken = store.getters.getIdToken;
  await axios.put(`${this.baseurl}/forms/forms/isreviewstatus`, { "formId": this.receivedData[0].formId},
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => console.log("review status changed", res))
      .catch((err) => console.log("error in review status change" , err));
  // alert("Form submitted successfully.");
  alert(this.$t('navigations.form submitted successfully'))
  await this.$router.push(`/${this.closeoption}`);
    }

  }},
async resetdata(){
  this.sndData = {
    "formId": "string",
    "pages": [],
    "dataSource":"string",
    "sourceID":"string"
  }
},

// save and edit ends

async editedenevt(){
 alert(changed);
},
    onpagechange: function (pageNumber){
      this.pageno = pageNumber;
    },
    async onpagesave(htmlarray){
      this.htmlarray[0].pages.forEach(async (item) => {
    
      console.log("HTML array value is",htmlarray)
      await this.validate();
      //console.log("HTML array value ERROR is",this.isvalid)
  
      
      this.htmlarray[0].pages.forEach((item) => {
       // let pagenumber = `pageNo${item.pageNo}`;
        if (item.pageNo == this.pageno) {
            item.field.forEach((subitem) => {
          
            if(subitem.fieldName.includes("header"))
            {}else{
              this.pagesavefieldnames.push(subitem.fieldName);
            }
              
            });
            let showalert=false;
            if(this.isvalid.length > 0)
            {
              showalert=true;
              console.log("Show confirm alert on page save")
            }
            this.pagesave(showalert);
          }
        });

      });

     
    },
    async pagesave(showalert){
      if(showalert == true)
      {
        alert("Some of the fields in the page contains invalid data. Validation Failed")
        //if(confirm("Some of the fields in the page contains invalid data. Are you sure you want to validate this page?")){
        //  await this.pagesaveapicall();
        //}
       
      }
      else{
        await this.pagesaveapicall();
      }
    },
    async pagesaveapicall(){
   
    console.log(this.pageno);
    console.log(this.formsid);
    console.log("3rd",this.pagesavefieldnames);
    let data = {
      "formId": this.formsid,
      "pageno": this.pageno,
      "dataSource": "Web Browser",
      "sourceID": this.iPaddress,
      "fieldnames": this.pagesavefieldnames
        
    }

    const idtoken = store.getters.getIdToken;
    await axios
    .put(`${this.baseurl}/forms/forms/updatefieldsfrompagesave`, data ,
    //.put(`https://localhost:5002/forms/updatefieldsfrompagesave`, data ,
    {
      headers: {
        Authorization: "Bearer " + idtoken,
        "Content-Type": "application/json",
      }
    })
        .then((res) => {
          alert(this.$t('navigations.page validated successfully'));
        })
        .catch((err) => console.log(err));
       
    },

    async alertfun(){
      console.log("hello test");
    },
    previousFun: function() {
      if (this.pageno > 1) {
        console.log("previous page");
        this.pageno--;
      }
    },
    incrementcount: function() {
      this.totalpages++;
      console.log("increment" + this.totalpages);
    },
  },
  watch:{
    pageno(newpageno, oldpageno)
    {
      if (newpageno>=1) {
        window.scrollTo(0,0);
      }
    }
  }
};
