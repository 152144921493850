<template>
  <div>
    <!-- Controls -->
    <div class="d-flex">
      <button @click="createGraph" style="margin-bottom: 1rem;">Create Graph</button>
      <button @click="loadGraph" style="margin-bottom: 1rem;">Reset Graph</button>
      <div class="mt-2 col-md-2">
        <select class="form-control ml-2" v-model="selectedgraphid" @change="loadGraph">
          <option v-for="graph in templatedetails" :value="graph.id" :key="graph.id">
            {{ graph.name }}
          </option>
        </select>
      </div>
      <!-- New Layout selection drop-down -->
      <div class="mt-2 col-md-2">
        <select class="form-control ml-2" v-model="selectedLayout" @change="applyLayout">
          <option value="dagre">Dagre</option>
          <option value="breadthfirst">Breadthfirst</option>
          <option value="grid">Grid</option>
          <option value="circle">Circle</option>
          <option value="cose">Cose</option>
          <option value="klay">Klay</option>
        </select>
      </div>
    </div>

<graph :selectedgraphid="selectedgraphid" :graphdata="graphdata" :selectedLayout="selectedLayout" :menuitems="menuitems" @newnodecreate="addsubnodeapi" @expandnode="expandNode" @showdetails="showdetails" @deleteNode="deleteNode" :execute-parent-function="expandNode"/>
<!-- create graph Modal -->
<div v-if="creategraphpopup" class="modal-wrapper is-visible">
        <div class="modal-container">
          <div class="titleclass">
            <h4 class="px-3 mt-1">Create Graph</h4>
            <div class="closebutton mt-1">
              <a style="color: white" class="close-link">
                <i class="fa fa-times" @click.prevent="closecreategraph"></i>
              </a>
            </div>
          </div>
          <div class="modelbody p-0">
            <div class="mb-3 p-2">
              <div class="form-group">
                <div class="d-flex flex-column flex-lg-row">
                  <div class="col-lg-12 my-2">
                    <label for="propname">Graph name</label>
                    <input type="text" class="form-control" v-model="newgraphname" id="propname" />
                  </div>
                </div>
                <div class="d-flex justify-content-center my-3">
                  <button class="save_btn py-1 px-4" @click.prevent="createGraphapi()">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       <!-- Popup Details Modal -->
       <div v-if="detailspopup" class="modal-wrapper is-visible">
         <div class="modal-container">
          <div class="titleclass">
            <h4 class="px-3 mt-1">Details</h4>
            <div class="closebutton mt-1">
              <a style="color: white" class="close-link">
                <i class="fa fa-times" @click.prevent="closedetailsModal"></i>
              </a>
            </div>
          </div>
          <div class="modelbody p-0">
            <div class="mb-3 p-2">
              <div class="form-group">
                <div class="d-flex flex-column flex-lg-row">
                  <div class="col-lg-12 my-2">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Key</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(value, key) in nodeproperties.data" :key="key">
                          <td>{{ key }}</td>
                          <td>{{ value }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="d-flex justify-content-center my-3">
                  <button class="save_btn py-1 px-4" @click="closedetailsModal">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script src="./graphisual.js">
</script>

<style scoped>
@import "../../assets/css/style.css";

#cy {
  width: 100%;
  height: 600px;
  border: 1px solid #ddd;
}

.popup-menu {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.popup-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup-menu li {
  padding: 3px 8px;
  cursor: pointer;
}

.popup-menu li:hover {
  background: #f0f0f0;
}

.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  pointer-events: auto;
  overflow: hidden;
  z-index: 9999;
}

.modal-container {
  background: #fff;
  width: 80%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.is-visible {
  opacity: 1;
  pointer-events: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.table th,
.table td {
  padding: 8px;
  border: 1px solid #ddd;
}

.table th {
  background-color: #f4f4f9;
  font-weight: bold;
}

.table-striped tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}
</style>
