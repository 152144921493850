<template>
    <!-- Change Schedule status modal -->
   <div class="modal_wrapper">
      <div class="modal-container">
        <div class="titleclass">
          <h4 class="px-3 mt-1">{{ $t("navigations.change status") }}</h4>
          <div class="closebutton mt-1">
            <a style="color: white" class="close-link">
              <i class="fa fa-times" @click.prevent="closemodal()"></i>
            </a>
          </div>
      </div>
      <div class="modelbody p-0">
        <div class="mb-3 p-2">
          <div class="form-group">
            <div class="d-flex flex-column flex-lg-row">
              <div class="col-lg-12 my-2">
                <select class="form-control" placeholder="Search by Status" v-model="changeStatus.status">
                  <option value="" disabled selected>
                    {{ $t("navigations.select status") }}
                  </option>
                  <option v-for="status in statusesList" :key="status.statusId" :value="status.status">
                    {{ status.status }} 
                  </option>
                </select>
              </div>
            </div>

            <div class="d-flex justify-content-center my-3">
              <button class="save_btn py-1 px-4" @click.prevent="changeScheduleStatus()">
                {{ $t("navigations.change") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      </div>
   </div>
  <!-- End of Change Schedule status modal -->
</template>
<script src="./ChangeScheduleStatusPopUp.js"></script>
<style scoped>
.modal_wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.modal-container {
  background: #fff;
  width: min(50%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}
.modelbody {
  overflow: unset !important;
}
</style>