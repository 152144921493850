/* eslint-disable */
import "/src/assets/style.css";
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import VPagination from "@hennge/vue3-pagination";
import VueResizable from "vue-resizable";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import createFormModal from "../createFormModal/createForm.vue";
import addnewrandomcolumn from "../addnewrandomcolumn/addnewrandomcolumn.vue";
import jwt_decode from "jwt-decode";
import QRCode from "qrcode";
import createnewversion from "../newversion/createnewversion.vue";
import createrepeatedfield from "../Create_repeated_field/createrepeatedfield.vue";
import { isElementAccessExpression } from "typescript";
export default {
  name: "formdesign",
  components: {
    Multiselect,
    VPagination,
    VueResizable,
    addnewrandomcolumn,
    createFormModal,
    QRCode,
    createnewversion,
    createrepeatedfield,
    createFormModal
  },
  props: {
    formid: String,
    templateId: {
      type: String,
      default: "",
    },
  },
  data() {
    const tH = 90;
    const tW = "100%";
    return {
      visibilityobj:{},
      pageposition: "end",
      addnewgroupno: 1,
      addnewgrouppoopup: false,
      popuptype: "save",
      repfieldstoedit: [],
      repeatedgroupgroupnos: {},
      temprepeatingarray: [],
      repeatedfieldpopup: false,
      editingOption: null,
      rangeoption: [],
      radioorientation: "vertical",
      cyrrentformid: "",
      currentsourceid: "",
      versionhistoryarray: [],
      isformlocked: false,
      replacetemplatevar: false,
      exisitingvisitsvar: false,
      reviewandapprovalvar: false,
      designcompletepopup: false,
      sourcetemplateidcreate: "",
      newversioncreate: "",
      currentformversion: "",
      newversionformid: "",
      creatversionpopup: false,
      repeatoptionss: "",
      calculate: {
        firstfield: "",
        secondfield: "",
        operations: "",
      },
      refreshFlags: {
        general: true,
        validation: false,
        dependencies: false,
        calculations: false
      },
      presavedrepeatedmodel: [],
      repeatedtemplatename: "",
      repeatedtemplatefieldarray: [],
      calcfieldnamesarray: [],
      repeatedoptions: [],
      tempcalarray:[],
      repeatingfieldname: "",
      repeatingtype: "",
      addrepeatedoptfield: false,
      createrepeatedmodel: false,
      currentgrid: [],
      gridoptarray: {},
      optiongroupeditid: "",
      activeTab: "Tab 1",
      highlitedcolumn: "",
      modeloptiontype: "",
      selectedmodel: {},
      optiongroups: {},
      imagid: "",
      currentoptions: [],
      qrData: "",
      qrcodecontents: "",
      blankfielddetails: "",
      scaleminval: 0,
      scalemaxval: 0,
      viewtempicon: false,
      templateid: "",
      idtoken: "",
      griddata: [
        {
          Rows: [""],
          Columns: [""],
          Fieldtypes: ["text"],
          Fielddata: {},
          Fieldoptions: {},
        },
      ],
      griditm: "",
      selectedpagegroup: "",
      repeatingdata: [1],
      gridrows: [1],
      gridcolumns: [1],
      gridgeneral: "flex-sm-fill text-sm-center nav-link active",
      gridconfiguration: "flex-sm-fill text-sm-center nav-link",
      gridoption: "general",
      selectedpageid: "",
      qrcodetxt: {},
      width: [tW],
      height: [tH, tH, tH, tH, tH, tH],
      fit: true,
      left: [`calc( 50% - ${tW / 2}px)`],
      top: [`calc(50% - ${tH / 2}px)`],
      maxH: 90,
      minH: 10,
      issaved: false,
      minlength: null,
      maxlength: null,
      minvalue: null,
      maxvalue: null,
      baseurl: process.env.VUE_APP_Service_URL,
      fileuploadurl: process.env.VUE_APP_File_upload_URL + "/document",
      usersettingsBaseUrl: `${process.env.VUE_APP_Service_URL}/account-core/usersettings/`,
      update: false,
      version: "",
      currentversion: "",
      columnselected: false,
      selectedgroup: {},
      selectedfield: 0,
      totalgroups: {},
      isedit: false,
      showCreateFormModal: false,
      fieldnamesarray: [],
      tempfieldname: "",
      totalpages: 0,
      selectedFile: "",
      pageno: 1,
      notcollapsed: true,
      checkboxoptions: "",
      checkboxoptionsarray: [],
      radiooptions: "",
      radiovalues: "",
      radiooptionsarray: [],
      collapseDiv: [false],
      showSideModal: [false],
      selectedmenu: "",
      value: [],
      values: [],
      dynamicFields:[],
      inputElements: [
        "color",
        "datetime-local",
        "email",
        "file",
        "hidden",
        "month",
        "number",
        "password",
        "range",
        "search",
        "tel",
        "text",
        "url",
        "week",
        "boxed",
        "textbox",
        "boxed",
        "file",
      ],
      groups: {
        1: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13],
      },
      total: "",
      dateList: [],
      newTempValues: [],
      dateValidationrangeErrors: [],
      checkError: [],
      phonerangeError:[false,false],
      daterangeErrors: [false, false, false, false],
      yearrangeError: [false, false, false, false],
      timerangeErrors: [false, false, false,false],
      dependenciesError:[false,false,false,false],
      urlError:[false,false],
      emailError:[false,false],
      email:"",
      url:"",
      phnnumber:"",
      currentDate:"",
      dateFormat: "",
      minYear: "1900",
      maxYear: "2024",
      setVal: true,
      loadingBlock:false,
      minDate: "",
      maxDate: "",
      selectedForm: "",
      timeFormat: "",
      selectedOption: "",
      selectedField: "",
      selectedFieldCondition: "",
      selectedFieldValue: "",
      selectedfieldType: "",
      conditionValue: "",
      showText: false,
      loader:false,
      showSuggestions:[false],
      filteredFields: [],
      triggerChar: "@",
      formList:[],
      calculationFields:[],
      calculationsArray:[],
      lastTriggerIndex: -1,
      calculations:"",
      selectedConditionValues: [],
      fieldNames: [],
      generalValidationsError:[],
      calculationFieldNames:[],
      exclusionMessagesError:[],
      dependencyMessagesError:[],
      calculationMessagesError:[],
      selectedConditionsArray: [],
      conditionsArray: [],
      dependencies:[],
      newRepeatedFieldnames:[],
      showValidate:[true],
      inputValidationElements: [
        'text', 'date', 'textbox', 'scales', 'dropdown', 'textarea', 'radio','radiohorizondal','checkbox', 'year'
      ],
      //Options For Text Box
      options: [
        { value: "ALPHANUMERIC", label: "Alphanumeric" },
        { value: "EMAIL", label: "Email" },
        { value: "CHARACTERLIMITRANGE", label: "Character Limit" },
        { value: "NUMBER", label: "Number" },
        { value: "REQUIRED", label: "Required" },
        { value: "NUMERICRANGE", label: "Numeric Range" },
        { value: "URL", label: "URL" },
        { value: "PHONENUMBER", label: "Phone Number" },
      ],
      generaltimeOptions: [
        { value: "TIMERANGE", label: "Time Range" },
        { value: "REQUIRED", label: "Required" },
        { value: "TIMEFORMAT", label: "Time Format" }
        
      ],
      generalyearOptions: [
        { value: "YEARRANGE", label: "Year Range" },
        { value: "REQUIRED", label: "Required" },
      ],
      generaltextareaOptions: [
        { value: "CHARACTERLIMITRANGE", label: "Character Limit" },
        { value: "REQUIRED", label: "Required" },
      ],
      generaldateandtimeOptions: [
        // { value: "DATERANGE", label: "Date Range" },
        // { value: "TIMERANGE", label: "Time Range" },
        // { value: "DATEFORMAT", label: "Date Format" },
        // { value: "TIMEFORMAT", label: "Time Format" },
        { value: "REQUIRED", label: "Required" },
      ],
      generaldateOptions: [
        { value: "DATERANGE", label: "Date Range" },
        { value: "REQUIRED", label: "Required" },
        { value: "DATEFORMAT", label: "Date Format" }
      ],
      generalnumberanddateOptions: [
        // { value: "DATERANGE", label: "Date Range" },
        // { value: "DATEFORMAT", label: "Date Format" },
        // { value: "NUMERICRANGE", label: "Value Range" },
        // { value: "CHARACTERLIMITRANGE", label: "Length Range" },
        // { value: "NUMBER", label: "Number" },
        { value: "REQUIRED", label: "Required" },
      ],
      yearOptions: [
        { value: "NUMERICRANGE", label: "Year Range" },
        { value: "REQUIRED", label: "Required" },
      ],
      textboxOptions: [
        { value: "NOT EQUAL TO", label: "Not equal to" },
        { value: "EQUAL TO", label: "Equal to" },
      ],
      radioOptions: [
        { value: "EQUAL TO", label: "Equal to" },
        { value: "NOT EQUAL TO", label: "Not equal to" },
      ],
      dropdownOptions: [
        { value: "NOT EQUAL TO", label: "Is not" },
        { value: "EQUAL TO", label: "Is equal to" },
      ],
      checkboxOptions: [
        { value: "IS CHECKED ON", label: "Is checked on" },
        { value: "IS CHECKED OFF", label: "Is checked off" },
      ],
      dateOptions: [
        { value: "DATE GREATER THAN", label: "Is greater than" },
        { value: "DATE GREATER THAN OR EQUAL TO", label: "Is greater than or equal to" },
        { value: "DATE EQUAL TO", label: "Is equal to" },
        { value: "DATE LESS THAN", label: "Is smaller than" },
        { value: "DATE LESS THAN OR EQUAL TO", label: "Is smaller than equal to" },
        { value: "DATE NOT EQUAL TO", label: "Not" },
        { value: "OPTION TO AUTOFILL CURRENT DATE", label: "Option to autofill current date" }
      ],
      yearOptions: [
        { value: "GREATER THAN", label: "Is greater than" },
        { value: "GREATER THAN OR EQUAL TO", label: "Is greater than or equal to" },
        { value: "EQUAL TO", label: "Is equal to" },
        { value: "LESS THAN", label: "Is smaller than" },
        { value: "LESS THAN OR EQUAL TO", label: "Is smaller than equal to" },
        { value: "NOT EQUAL TO", label: "Not" },
      ],
      scaleOptions: [
        { value: "GREATER THAN", label: "Is greater than" },
        { value: "GREATER THAN OR EQUAL TO", label: "Is greater than or equal to" },
        { value: "EQUAL TO", label: "Is equal to" },
        { value: "LESS THAN", label: "Is smaller than" },
        { value: "LESS THAN OR EQUAL TO", label: "Is smaller than equal to" },
        { value: "NOT EQUAL TO", label: "Not" },
      ],
      textareaOptions: [
        { value: "NOT EQUAL TO", label: "Is not" },
        { value: "EQUAL TO", label: "Is equal to" },
        { value: "REQUIRED", label: "Required" },
      ],
      exclusionMessages: [
      ],
      generalValidations:[],
      selectedrepeatedtemp: "",
      commonvalidations: [
        { value: "REQUIRED", label: "Required" }
      ],
      newtemplate: [],
      visibilitytemplate:[],
      templatename: "",
      pagename: "",
      pagearray: [],
      pageobj: {},
      grouparray: {},
      formHeader: "test",
      selectedpage: "",
      grouporderno: "",
      headdingtext: "",
      fieldname: "",
      labeltext: "",
      componentKey: 1,
      type: "",
      showModalll: 0,
      temparray: [],
      totalgroupsforedit: [],
      testtempvar: [],
      listforms: [],
      formname: "",
      componentModal: false,
      pages: {},
      showDragCol: false,
      status: "New",
      dropdownError: false,
      emptyField: [false],
      scaleError: [false, false, false, false],
      newfieldname: "",
      showForm: false,
      showoptmodel: false,
      manageoptoptmodel: false,
      optiongroup: "",
      optiontype: "radio",
      optiongroupopts: "",
      groupoptions: [],
      optiongroupsearch: "",
      modeloptions: [],
      singlemodeloptions: "",
      singlemodelvalues: "",
      selectedrepeatedgroupno: 1,
      repeatedmeasurepopup: false,
      repeatedmeasurelistpopup: true,
      controlSelectError: false,
      newversionViewTemp: true,
      repeatedoptions: [],
      repeatedmeasurevalarray: [],
      temprepeatedmeasureval: {},
      repratmeasureid: new Date(),
      rollsprivilages: "",
      managerepeatfieldmodel: false,
      sourceTemplateId: "",
      oldFieldName: "",
      DesignFormPrvRoute: "",
      rangeErrors: [false, false, false, false, false, false, false, false],
      newValues: [],
      currentTempID : "",
    };
  },
  beforeRouteEnter(to, from, next) {
    const previousRouteName = from.name;
    next((vm) => {
      vm.previousRouteName = previousRouteName;
    });
  },
  beforeRouteLeave(to, from, next) {
    // Unset the localStorage variable here
    localStorage.removeItem("isFormCreateTemplateRoute");
    localStorage.removeItem("DesignFormPrvRoute");
    next();
  },
  computed: {
    textFields() {
      // Filter fields where type is text
      return this.newtemplate[0].pages[this.pageno - 1].field.filter(
        (field) => field.type === "textbox"
      );
    },
  },
  async mounted() {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const dd = String(today.getDate()).padStart(2, "0"); // Pad single digit days with zero
    this.currentDate = `${yyyy}-${mm}-${dd}`;
    this.idtoken = store.getters.getIdToken;
    await this.getrepeatedfieldlist();
    await this.listoptiongroups();
    this.rollsprivilages = await store.getters.getRolesprivilegeData;
    this.pagearray = [];
    this.temparray = [];
    const isFormTemplateRoute = localStorage.getItem("isFormTemplateRoute");
    const isFormCreateTemplateRoute = localStorage.getItem("isFormCreateTemplateRoute");
    const isFormtempEdit = localStorage.getItem('isFormtempEdit');
    this.DesignFormPrvRoute = localStorage.getItem("DesignFormPrvRoute");
    if (this.DesignFormPrvRoute === "studyProfile" && isFormCreateTemplateRoute != "true") {
      const templateFormId = localStorage.getItem("templateFormId");
      const version = localStorage.getItem("version");
      this.sourceTemplateId = localStorage.getItem("sourceTemplateId");
      this.currentTempID = localStorage.getItem("latestTempId");

      await this.createnewversion(
        templateFormId,
        version,
        this.sourceTemplateId,
        this.currentTempID
      );

      localStorage.removeItem("templateFormId");
      localStorage.removeItem("sourceTemplateId");
      localStorage.removeItem("latestTempId");
      localStorage.removeItem("isStudyProfileRoute");
    }
    else if (this.DesignFormPrvRoute === "formTemplates" && isFormCreateTemplateRoute != "true") {
      const templateFormId = localStorage.getItem("templateFormId");
      const version = localStorage.getItem("version");
      this.currentTempID = localStorage.getItem("latestTempId");
      this.sourceTemplateId = localStorage.getItem("sourceTemplateId");
      await this.getPages(
        templateFormId,
        this.sourceTemplateId,
        version,
        false
      );
      if(isFormtempEdit != 'true'){
        await this.createnewversion(
          templateFormId,
          version,
          this.sourceTemplateId,
          this.currentTempID
        );
      }
     
      localStorage.removeItem("templateFormId");
      localStorage.removeItem("sourceTemplateId");
      localStorage.removeItem("isFormTemplateRoute");
      localStorage.removeItem("isFormtempEdit");
      localStorage.removeItem("latestTempId");
    }
    if (isFormCreateTemplateRoute === "true") {
      this.sourceTemplateId = localStorage.getItem("sourceTemplateId");
      localStorage.removeItem("templateFormId");
      localStorage.removeItem("sourceTemplateId");
      this.createForm();
    }

    window.addEventListener("studyIdChanged", async (event) => {
      await this.getForms();
    });
    this.rollsprivilages = store.getters.getRolesprivilegeData;
    this.getForms();
    if(this.previousRouteName.includes("viewform")){
      this.currentTempID = localStorage.getItem("latestTempId");
      localStorage.removeItem("latestTempId");
      this.reloadtemplatefun(isFormTemplateRoute, store.getters.getversion);
    }
    if (
      (this.previousRouteName.includes("studyProfile") ||
      this.previousRouteName.includes("formTemplate")) && isFormCreateTemplateRoute != "true"
    ) {
      this.reloadtemplatefun(isFormTemplateRoute, localStorage.getItem("version"));
    }
    store.dispatch("setCollapse", true);
  },
  async unmounted() {
    store.dispatch("setCollapse", false);
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (to.name == 'landingPage') {
        document.querySelector("#page-wrapper").style.width="100%"
      }
      else{
        document.querySelector("#page-wrapper").style.width=null
      }
      next();
    });
  },
  methods: {
    addValidation(type) {
     if(type=='custom'){
        this.exclusionMessages.push(
          {
            condition: "",
            value: [],
            messageType: "",
            message: ""
          }
        )
      }
      else if(type=='dependencies'){
        const index = this.dependencies.push({
          targetTemplateId: "",
          targetFieldName: "",
          condition: "",
          value: "",
          pageNo: "",
         isVisible:null,
          visibilityfieldname: "",
        }) - 1;
        
        // Use the index to assign the template
        this.formList.forEach(async (form) => {
          if (form.templateId == this.templateid) {
            this.dependencies[index].targetTemplateId = form;
            await this.getFields(form.templateId, form.version);
          }
        });
      }
      else if(type=='calculations'){
        this.calculationsArray.push(
          {
            fields:[],
            expression:"",
            outputfield:""
          }
        )
        this.showValidate[this.calculationsArray.length-1]=true
      }
      
      this.dateValidationrangeErrors.push([false, false, false])
    },
    handleTabClick(tab) {
      this.refreshFlags = [false, false, false, false];
      this.refreshFlags[tab] = true;

    },
    createnewgroup(groupno) {
      this.addnewgroupno = Number(groupno.Groupno);
      this.addnewgrouppoopup = true;
    },
    async handleReceivedObject(receivedObject) {
      this.temprepeatingarray = receivedObject;
      this.fieldnamesarray = [];
      this.newRepeatedFieldnames=[]
      this.newtemplate[0].pages.forEach((page) => {
        page.field.forEach((field) => {
          this.fieldnamesarray.push(field.fieldName.trim().toLowerCase());
     
          if (
            field.type == "repeatedmeasure" ||
            field.type == "RepeatingMeasure" ||
            field.type == "repeatingfield"
          ) {
            this.newRepeatedFieldnames.push(field)
            field.repeatingFields.forEach((rField) => {
              this.fieldnamesarray.push(rField.fieldName.trim().toLowerCase());
              if(rField.type!='header'){
                this.newRepeatedFieldnames.push(rField)
              }
             
            });
          }
        });
      });
      receivedObject[0].repeatingFields.forEach((rField) => {
        if (
          !this.fieldnamesarray.includes(rField.fieldName.trim().toLowerCase())
        ) {
          this.fieldnamesarray.push(rField.fieldName.trim().toLowerCase());
        }
        if(!this.newRepeatedFieldnames.some(existingField => existingField.fieldName === rField.fieldName))
        {
          if(rField.type!='header' || rField.type!='subHeader'){
            this.newRepeatedFieldnames.push(rField)
          }
        }
      
      });
      if (this.popuptype == "save") {
        await this.addfield("repeatingfield");
      } else {
        await this.editfield("repeatingfield");
      }
      this.closerepeatedfield();
    },
    async handleCheckboxChange() {
      if (this.reviewandapprovalvar == true) {
        this.replacetemplatevar = false;
        this.exisitingvisitsvar = false;
      }
    },
    async repeatedmeasuremodal(options) {
      this.repeatedoptions = JSON.parse(options);
      console.log(this.repeatedoptions);
      this.repeatedmeasurepopup = true;
    },
    async closerepeatedmeasuremodal() {
      this.repeatedmeasurepopup = false;
    },
    async repeatedmeasurlist() {
      this.repeatedmeasurelistpopup = true;
    },
    async repeatedmeasurdatapopup() {
      this.repratmeasureid = new Date();
      this.repeatedmeasurelistpopup = false;
    },
    async deleterepeatedmeasure(item) {
      let indexToDelete = this.repeatedmeasurevalarray.indexOf(item);

      if (indexToDelete !== -1) {
        // Remove the element at the found index
        this.repeatedmeasurevalarray.splice(indexToDelete, 1);
      }
    },
    async addrepeatemesureentries() {
      let temprepeatedval = {};
      this.repratmeasureid = new Date();
      this.temprepeatedmeasureval.ID = this.repratmeasureid;
      temprepeatedval = this.temprepeatedmeasureval;
      this.repeatedmeasurevalarray.push({ ...temprepeatedval });
      temprepeatedval = {};
      this.repeatedmeasurdatapopup();
    },
    async addrepeatedmeasure() {
      console.log(this.selectedrepeatedtemp);
      this.addfield("repeatedmeasure");
    },
    async addrepeatedfield() {
      this.selectedrepeatedtemp.forEach(async (eachrepeatedtemp) => {
        console.log(eachrepeatedtemp);
        this.fieldname = eachrepeatedtemp.fieldName;
        this.labeltext = eachrepeatedtemp.fieldName;
        this.currentoptions = eachrepeatedtemp.options;
        let groptemparr = this.temparray[0].pages[this.pageno - 1].Group;
        let lastgroup = groptemparr[groptemparr.length - 1];
        this.addrepeatedonecolumn(lastgroup);
        this.radiooptionsarray = eachrepeatedtemp.options;
        await this.addfield(eachrepeatedtemp.type);
      });
    },
    async getrepeatedfieldlist() {
      await axios
        .get(`${this.baseurl}/forms/templatedesign/listfield`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.presavedrepeatedmodel = res.data.sort((a, b) => {
            const nameA = a.templateName.toLowerCase();
            const nameB = b.templateName.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async manageoptiongroupmodel() {
      window.scrollTo(0, 0);
      this.manageoptoptmodel = true;
    },
    async managerepeatedfieldmodel() {
      window.scrollTo(0, 0);
      this.managerepeatfieldmodel = true;
    },
    async closemanagerepeatmodel() {
      window.scrollTo(0, 0);
      this.managerepeatfieldmodel = false;
    },
    async gridoptfun(fieldname, gridoptions) {
      console.log("gridoptfun");
      this.gridoptarray[fieldname] = JSON.parse(gridoptions);
    },
    async closemanageoptmodel() {
      window.scrollTo(0, 0);
      this.manageoptoptmodel = false;
      this.optiongroupsearch = "";
      this.listoptiongroups();
    },
    async creatoptiongroup() {
      if (this.optiongroup == "" || this.optiongroup == null) {
        // alert("Please enter option group name");
        alert(this.$t('navigations.please enter option group name'))
      } else if (this.groupoptions.length == 0) {
        // alert("Please enter options");
        alert(this.$t('navigations.please enter options'))
      } else {
        let optiondata = {
          createdUserEmail: jwt_decode(this.idtoken).email,
          createdUserId: jwt_decode(this.idtoken).user_id,
          createdAt: new Date(),
          lastModifiedUserEmail: jwt_decode(this.idtoken).email,
          lastModifiedUserId: jwt_decode(this.idtoken).user_id,
          lastModifiedAt: new Date(),
          optiongroup: this.optiongroup,
          label: this.optiongroup,
          optiontype: this.optiontype,
          groupoptions: this.groupoptions,
        };
        await axios

          .post(
            `${this.baseurl}/forms/optiongroups/create
          `,
            optiondata,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            // alert("Option group added successfully.");
            alert(this.$t('navigations.option group added successfully'))
            this.listoptiongroups();
            this.closeoptmodel();
          })
          .catch((err) => {
            if (
              err.response.data.messages[0].includes(
                "Option Group name already exists."
              )
            )
              // alert("Option group name already exists");
              alert(this.$t('navigations.option group name already exists'))
            this.closeoptmodel();
          });
      }
    },
    async addroptiongroupopts() {
      if (
        this.singlemodelvalues.trim() != "" &&
        this.singlemodelvalues.trim() != null &&
        this.optiongroupopts.trim() != "" &&
        this.optiongroupopts.trim() != null
      ) {
        let optgroupobj = {
          optionname: "",
          codevalue: "",
        };
        if (this.optiongroupopts != null && this.optiongroupopts != "") {
          const isNameDuplicate = this.groupoptions.some(
            (option) => option.optionname === this.optiongroupopts
          );
          if (isNameDuplicate) {
            // alert("Option group with the same Option name already exists!");
            alert(this.$t('navigations.option group with the same Option name already exists!'))
            return;
          }
          const isCodeDuplicate = this.groupoptions.some(
            (option) => option.codevalue === this.singlemodelvalues
          );
          if (isCodeDuplicate) {
            // alert("Option group with the same Code value already exists!");
            alert(this.$t('navigations.option group with the same Code value already exists!'))
            return;
          }
          optgroupobj.optionname = this.optiongroupopts.trim();
          optgroupobj.codevalue = this.singlemodelvalues.trim();
          this.groupoptions.push(optgroupobj);
        }
        this.optiongroupopts = "";
        this.singlemodelvalues = "";
        optgroupobj = {
          optionname: "",
          codevalue: "",
        };
      }
    },
    async UpdateDropdownoptions() {
      //  Used in both radio and dropdown popups for options
      if (
        this.radiovalues.trim() !== "" &&
        this.radiovalues.trim() !== null &&
        this.radiooptions.trim() !== "" &&
        this.radiooptions.trim() !== null
      ) {
        if (this.editingOption !== null) {
          if (
            this.radiovalues !== this.editingOption.codevalue ||
            this.radiooptions !== this.editingOption.optionname
          ) {
            const isNameDuplicate = this.radiooptionsarray.some(
              (option) =>
                option.optionname === this.radiooptions.trim() &&
                option.codevalue !== this.editingOption.codevalue
            );
            if (isNameDuplicate) {
              // alert("Option group with the same name already exists!");
              alert(this.$t('navigations.option group with the same name already exists!'))
              return;
            }

            const isCodeDuplicate = this.radiooptionsarray.some(
              (option) =>
                option.codevalue === this.radiovalues.trim() &&
                option.codevalue !== this.editingOption.codevalue
            );
            if (isCodeDuplicate) {
              alert("Option group with the same Code value already exists!");
              return;
            }
          }
        } else {
          const isNameDuplicate = this.radiooptionsarray.some(
            (option) => option.optionname === this.radiooptions.trim()
          );
          if (isNameDuplicate) {
            alert("Option group with the same Option name already exists!");
            return;
          }

          const isCodeDuplicate = this.radiooptionsarray.some(
            (option) => option.codevalue === this.radiovalues.trim()
          );
          if (isCodeDuplicate) {
            alert("Option group with the same Code value already exists!");
            return;
          }
        }

        if (this.editingOption !== null) {
          const editedOptionIndex = this.radiooptionsarray.findIndex(
            (option) => option.codevalue === this.editingOption.codevalue
          );
          if (editedOptionIndex !== -1) {
            this.radiooptionsarray[
              editedOptionIndex
            ].codevalue = this.radiovalues;
            this.radiooptionsarray[
              editedOptionIndex
            ].optionname = this.radiooptions;
            this.editingOption = null;

            this.radiovalues = "";
            this.radiooptions = "";
            return;
          }
        }

        const existingIndex = this.radiooptionsarray.findIndex(
          (option) => option.codevalue === this.radiovalues
        );
        if (existingIndex !== -1) {
          if (
            this.radiooptions !==
            this.radiooptionsarray[existingIndex].optionname
          ) {
            this.radiooptionsarray[
              existingIndex
            ].optionname = this.radiooptions;
          }
        } else {
          this.radiooptionsarray.push({
            codevalue: this.radiovalues,
            optionname: this.radiooptions,
          });
        }
      } else {
        alert("Code value and Option name must not be empty.");
      }

      this.radiovalues = "";
      this.radiooptions = "";
    },
    async UpdateOGoptions() {
      //  Used in Option group options update
      if (
        this.singlemodelvalues.trim() !== "" &&
        this.singlemodelvalues.trim() !== null &&
        this.optiongroupopts.trim() !== "" &&
        this.optiongroupopts.trim() !== null
      ) {
        if (this.editingOption !== null) {
          if (
            this.singlemodelvalues !== this.editingOption.codevalue ||
            this.optiongroupopts !== this.editingOption.optionname
          ) {
            const isNameDuplicate = this.groupoptions.some(
              (option) =>
                option.optionname === this.optiongroupopts.trim() &&
                option.codevalue !== this.editingOption.codevalue
            );
            if (isNameDuplicate) {
              alert("Option group with the same Option name already exists!");
              return;
            }

            const isCodeDuplicate = this.groupoptions.some(
              (option) =>
                option.codevalue === this.singlemodelvalues.trim() &&
                option.codevalue !== this.editingOption.codevalue
            );
            if (isCodeDuplicate) {
              alert("Option group with the same Code value already exists!");
              return;
            }
          }
        } else {
          const isNameDuplicate = this.groupoptions.some(
            (option) => option.optionname === this.optiongroupopts.trim()
          );
          if (isNameDuplicate) {
            alert("Option group with the same Option name already exists!");
            return;
          }

          const isCodeDuplicate = this.groupoptions.some(
            (option) => option.codevalue === this.singlemodelvalues.trim()
          );
          if (isCodeDuplicate) {
            alert("Option group with the same Code value already exists!");
            return;
          }
        }

        if (this.editingOption !== null) {
          const editedOptionIndex = this.groupoptions.findIndex(
            (option) => option.codevalue === this.editingOption.codevalue
          );
          if (editedOptionIndex !== -1) {
            this.groupoptions[
              editedOptionIndex
            ].codevalue = this.singlemodelvalues;
            this.groupoptions[
              editedOptionIndex
            ].optionname = this.optiongroupopts;
            this.editingOption = null;

            this.singlemodelvalues = "";
            this.optiongroupopts = "";
            return;
          }
        }

        const existingIndex = this.groupoptions.findIndex(
          (option) => option.codevalue === this.singlemodelvalues
        );
        if (existingIndex !== -1) {
          if (
            this.optiongroupopts !== this.groupoptions[existingIndex].optionname
          ) {
            this.groupoptions[existingIndex].optionname = this.optiongroupopts;
          }
        } else {
          this.groupoptions.push({
            codevalue: this.singlemodelvalues,
            optionname: this.optiongroupopts,
          });
        }
      } else {
        alert("Code value and Option name must not be empty.");
      }

      this.singlemodelvalues = "";
      this.optiongroupopts = "";
    },
    async editOptionsfordropdown(codevalue, optionname) {
      //  Used in both radio and dropdown popups for edit click in table(template design)
      this.editingOption = { codevalue, optionname };

      this.radiovalues = codevalue;
      this.radiooptions = optionname;
    },
    async editOGOptions(codevalue, optionname) {
      this.editingOption = { codevalue, optionname };

      this.singlemodelvalues = codevalue;
      this.optiongroupopts = optionname;
    },
    async focusOnEdit() {
      //  Used in both radio and dropdown popups for focusing to the options when edit clicks

      this.$nextTick(() => {
        this.$refs.codeValueInput.focus();
        this.$refs.optionNameInput.focus();
      });
    },
    async cancelEditDropdown() {
      //  Used in both radio and dropdown popups to cancel edit mode
      this.editingOption = null;

      this.radiovalues = "";
      this.radiooptions = "";
      // below used in Option group popup
      this.singlemodelvalues = "";
      this.optiongroupopts = "";
    },
    async deleteoptions(codevalue) {
      // Used in both radio and dropdown popups to delete options in the table
      if (confirm(this.$t("navigations.are you sure you want to delete this option?"))) {
        if (this.radiooptionsarray.length > 1) {
          const index = this.radiooptionsarray.findIndex(
            (option) => option.codevalue === codevalue
          );
          if (index !== -1) {
            this.radiooptionsarray.splice(index, 1);

            console.log("Option deleted:", codevalue);
            this.radiovalues = "";
            this.radiooptions = "";
          } else {
            console.error("Option not found:", codevalue);
          }
        } else {
          // alert("Cannot delete all options");
          alert(this.$t('navigations.cannot delete all options'))
        }
      }
    },
    async deleteOGoptions(codevalue) {
      if (confirm((this.$t("navigations.are you sure you want to delete this option?")))) {
        // Used in Option Group popup to delete options in the table
        const index = this.groupoptions.findIndex(
          (option) => option.codevalue === codevalue
        );
        if (index !== -1) {
          this.groupoptions.splice(index, 1);

          console.log("Option deleted:", codevalue);
          this.singlemodelvalues = "";
          this.optiongroupopts = "";
        } else {
          console.error("Option not found:", codevalue);
        }
      }
    },
    async openoptmodel() {
      window.scrollTo(0, 0);
      this.showoptmodel = true;
    },
    async closeoptmodel() {
      window.scrollTo(0, 0);
      this.optiongroup, (this.optiongroup = "");
      this.singlemodelvalues = "";
      this.optiontype = "";
      this.optiongroupeditid = "";
      this.groupoptions = [];
      this.showoptmodel = false;
      this.optiongroupopts = "";

      this.editingOption = null;
    },
    async clearmodelfields() {
      this.fieldname = "";
      this.labeltext = "";
      this.radiooptionsarray = [];
      this.modeloptiontype = "";
      this.selectedmodel = {};
    },
    async addmodeloptions() {
      if (
        this.singlemodeloptions.trim() != "" &&
        this.singlemodeloptions.trim() !== null &&
        this.singlemodelvalues.trim() != "" &&
        this.singlemodelvalues.trim() != null
      ) {
        const isNameDuplicate = this.selectedmodel.groupoptions.some(
          (option) => option.optionname === this.singlemodeloptions
        );
        if (isNameDuplicate) {
          alert("Option group with the Option name already exists!");
          return;
        }
        const isCodeDuplicate = this.selectedmodel.groupoptions.some(
          (option) => option.codevalue === this.singlemodelvalues
        );
        if (isCodeDuplicate) {
          alert("Option group with the same Code value already exists!");
          return;
        }
        this.currentoptions = {
          optionname: this.singlemodeloptions.trim(),
          codevalue: this.singlemodelvalues.trim(),
        };
        this.selectedmodel.groupoptions.push(this.currentoptions);
        this.singlemodeloptions = "";
        this.singlemodelvalues = "";
      }
    },
    async modelchange() {
      this.modeloptiontype = this.selectedmodel.optiontype;
      this.modeloptions = this.selectedmodel.groupoptions;
    },
    async dropdownmodelchange() {
      this.modeloptiontype = this.selectedmodel.optiontype;
      this.modeloptions = this.selectedmodel.groupoptions;
      this.labeltext = this.selectedmodel.label;
      this.radiovalues = "";
      this.radiooptions = "";
      this.editingOption = null;
      this.radiooptionsarray = [];
      this.selectedmodel.groupoptions.forEach((option) => {
        this.radiooptionsarray.push(option);
      });
      this.selectedConditionValues=this.radiooptionsarray
    },
    async editOptions(codeval, optname) {
      this.radiovalues = codeval;
      this.radiooptions = optname;
    },
    async radiomodelchange() {
      this.labeltext = this.selectedoptiongroupmodel.label;
      this.radiooptionsarray = this.selectedoptiongroupmodel.groupoptions;
    },
    async addmodel() {
      if (this.modeloptiontype === "") {
        this.controlSelectError = true;
      } else {
        this.controlSelectError = false;
        await this.setfieldname();
        this.labeltext = this.selectedmodel.label;
        this.radiooptionsarray = this.selectedmodel.groupoptions;
        this.addfield(this.modeloptiontype);
      }
    },
    async setfieldname() {
      this.fieldname = (Math.floor(Math.random() * 100) + 1).toString();
      if (this.fieldnamesarray.includes(this.fieldname.trim().toLowerCase())) {
        this.setfieldname();
      }
    },
    async viewoptiongroup(id) {
      await axios
        .get(`${this.baseurl}/forms/optiongroups/getoptiongroupbyid/${id}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          this.openeditoptiongroup(res.data, id);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async openeditoptiongroup(data, id) {
      this.optiongroupeditid = id;
      this.optiongroup = data.optiongroup;
      this.optiontype = data.optiontype;
      this.groupoptions = data.groupoptions;
      this.showoptmodel = true;
    },
    async editoptiongroup() {
      if (this.optiongroup == "" || this.optiongroup == null) {
        alert("Please enter option group name");
      } else if (this.groupoptions.length == 0) {
        alert("Please enter options");
      } else {
        let optiondata = {
          createdUserEmail: jwt_decode(this.idtoken).email,
          createdUserId: jwt_decode(this.idtoken).user_id,
          createdAt: new Date(),
          lastModifiedUserEmail: jwt_decode(this.idtoken).email,
          lastModifiedUserId: jwt_decode(this.idtoken).user_id,
          lastModifiedAt: new Date(),
          optiongroup: this.optiongroup,
          label: this.optiongroup,
          optiontype: this.optiontype,
          optiongroupId: this.optiongroupeditid,
          groupoptions: this.groupoptions,
        };
        await axios
          .put(
            `${this.baseurl}/forms/optiongroups/updateoptiongroup`,
            optiondata,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            alert("Option group updated successfully.");
            this.listoptiongroups();
            this.closeoptmodel();
          })
          .catch((err) => {
            console.log("add err response:", err);
            this.closeoptmodel();
          });
      }
    },
    async deleterepeatmodel(id) {
      let data = { id: id };
      if (confirm(this.$t("navigations.are you sure you want to delete this Repeating model?"))) {
        await axios
          .delete(`${this.baseurl}/forms/templatedesign/deletetempfield`, {
            data: {
              id: id,
            },
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.getrepeatedfieldlist();
            // alert("Repeating model deleted successfully");
            alert(this.$t('navigations.repeating model deleted successfully'))
          })
          .catch((err) => {
            alert("Failed due to internal error");
            console.error(err);
          });
      }
    },
    async deleteoptiongroup(id) {
      if (confirm(this.$t("navigations.are you sure you want to delete this option group?"))) {
        await axios
          .delete(
            `${this.baseurl}/forms/optiongroups/deleteoptiongroup?optionGroupId=${id}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            // alert("Option group deleted successfully");
            alert(this.$t('navigations.option group deleted successfully'))
            this.listoptiongroups();
          })
          .catch((err) => {
            alert("Failed due to internal error");
            console.error(err);
          });
      }
    },
    async searchoptiongroups() {
      await axios
        .get(
          `${this.baseurl}/forms/optiongroups/listoptiongroups?optionname=${this.optiongroupsearch}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.optiongroups = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async listoptiongroups() {
      await axios
        .get(`${this.baseurl}/forms/optiongroups/listoptiongroups`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.optiongroups = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async reloadtemplatefun(isFormTemplateRoute, version) {
      console.log(" version in reload is ", version)
      let currentformid = store.getters.getViewformid;
      let sourcetemplateid = store.getters.getsourceTemplateId;
      if (isFormTemplateRoute != "true") {
        this.getPages(currentformid, sourcetemplateid, version, "", false);
      }

      this.collapseDiv[currentformid] = !this.collapseDiv[currentformid];
      if (currentformid) {
        this.viewtempicon = true;
      }
    },
    async griditems() {
      this.griditm = [1, 2, 3, 4];
      return this.griditm;
    },
    async selectconf(val) {
      if (val == "general") {
        this.gridgeneral = "flex-sm-fill text-sm-center nav-link active";
        this.gridconfiguration = "flex-sm-fill text-sm-center nav-link";
      } else if (val == "configuration") {
        this.gridgeneral = "flex-sm-fill text-sm-center nav-link";
        this.gridconfiguration = "flex-sm-fill text-sm-center nav-link active";
      }
      this.gridoption = val;
    },
    async qrcodefun(fieldname, dataurl) {
      try {
        this.qrcodetxt[fieldname] = await QRCode.toDataURL(dataurl);
      } catch (err) {
        console.error(err);
      }
    },
    async changepage(id, totalpages, page, sourceid, version, name) {
      await this.getPages(id, sourceid, version, name, false);
      store.dispatch("setsourceTemplateId", sourceid);
      var elements = document.querySelectorAll(".activeBackground");
      for (var i = 0; i < elements.length; i++) {
        elements[i].classList.remove("activeBackground");
      }
      let changeBackground = document.getElementById(page.pageName + sourceid);
      changeBackground.classList.add("activeBackground");
      this.pageno = totalpages.indexOf(page) + 1;
    },
    async loadPage(page) {
      this.pageno = page;
      console.log("Page is", this.pageno);
    },
    async showCollapse(id) {
      console.log("Pages are", this.pages[id]);
      if (this.pages[id] == null || pages[id] == []) {
        alert("No pages found");
      }
    },
    async getPagesInitially(id, version) {
      console.log("get pages intially.................")
      this.versionhistoryarray = [];
      this.getversionhistory(id, false);
      this.temparray = [];
      await axios
        .get(
          `${this.baseurl}/forms/templatedesign/getpagesbyid?formId=${id}&version=${version}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.showForm = true;
          this.formname = res.data.formname;
          this.templateid = res.data.templateId;
          this.pages[id] = res.data.pages;
          this.newtemplate[0] = res.data;
          this.assigntemplate();
          this.version = version;
          this.currentversion = this.version;
          this.getForms();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getversionhistory(templateid, firstload) {
      await axios
        .get(
          `${this.baseurl}/forms/template/getversionhistory?sourceId=${templateid}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.versionhistoryarray = res.data;
          if (firstload) {
            this.version = this.versionhistoryarray[
              this.versionhistoryarray.length - 1
            ].version;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async handleDropdownChange() {
      this.selectedpageid = "";
      this.getPages(
        this.cyrrentformid,
        this.currentsourceid,
        this.version,
        this.formname,
        true
      );
    },
    async getPages(id, sourceid, version, name, dropdownchange) {
      var elements = document.querySelectorAll(".activeBackground");
      for (var i = 0; i < elements.length; i++) {
        elements[i].classList.remove("activeBackground");
      }
      this.pageno = 1;
      this.version = version;
      this.versionhistoryarray = [];
      this.getversionhistory(sourceid, false);
      this.cyrrentformid = id;
      this.currentsourceid = sourceid;
      if (sourceid != null) {
        store.dispatch("setsourceTemplateId", sourceid);
      }
      if (version != null) {
        store.dispatch("setversion", version);
      }
      if (this.selectedpageid != sourceid) {
        this.selectedpageid = sourceid;
        this.pagearray = [];
        this.temparray = [];
        this.fieldNames = []
        this.calculationFieldNames=[]
        this.newRepeatedFieldnames=[]
        this.generalValidationsError=[]
        this.exclusionMessagesError=[]
        this.dependencyMessagesError=[]
        this.showForm = true;
        await axios
          .get(
            `${this.baseurl}/forms/templatedesign/getpagesbyid?formId=${sourceid}&version=${version}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.formname = res.data.formname;
            this.status = res.data.status;
            this.templateid = res.data.templateId
            this.pages[sourceid] = res.data.pages;
            this.newtemplate[0] = res.data
            this.newtemplate[0].pages = this.newtemplate[0].pages.map((page) => {
              page.field.forEach((fields) => {
                if (fields.validation.length !== 0) {
                  fields.validation = fields.validation.map((valid) => 
                    valid.replace(/([^(]+)(\([^)]*\))?/g, (match, beforeParen, inParen) => {
                      const uppercased = beforeParen.toUpperCase();
                      return inParen ? `${uppercased}${inParen}` : uppercased;
                    })
                  );
                }
                if(fields.type== 'repeatingfield'){
                  this.newRepeatedFieldnames.push(fields)
                  fields.repeatingFields.forEach((rField) => {
                    if(fields.type!='header' || fields.type!='subHeader'){  
                         this.newRepeatedFieldnames.push(rField)
                    }
                 
                  });
                }
                this.calculationFieldNames.push(fields.fieldName)
              });
              console.log("Fieldnames are",this.newRepeatedFieldnames)
              return page;
            });
            this.templateid = this.newtemplate[0].templateId;
            this.assigntemplate();
            this.viewtempicon = true;
            this.version = version;
            this.currentversion = version;
            this.isformlocked = res.data.isLock;
            this.totalPages = this.newtemplate[0].pages.length;
          })
          .catch((err) => {
            if (err && id != null) {
              alert("No pages found");
            }
            this.createtemplate();
          });
      }
    },

    async deleteForms(id) {
      if (confirm("Are you sure you want to delete this form?")) {
        await axios
          .delete(
            `${this.baseurl}/forms/templatedesign/deleteform?formId=${id}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            alert("Form deleted successfully");
            this.getForms();
            this.getPagesInitially();
            this.showForm = false;
          })
          .catch((err) => {
            alert("Failed due to internal error");
            console.error(err);
          });
      }
    },
    async getForms() {
      let currenttempid = store.getters.getViewtemplateid;
      await axios
        .get(
          `${this.baseurl}/forms/template/listlatesttemplate?StudyID=${store.getters.getStudyIs}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.listforms = res.data.results.sort((a, b) =>
            a.templateName.localeCompare(b.templateName)
          );
          this.listforms.forEach((item) => { });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async incrementrows() {
      if (this.gridrows.length == 0) {
        this.gridrows.push(1);
      } else {
        let lastelement = this.gridrows[this.gridrows.length - 1];
        this.gridrows.push(lastelement + 1);
      }
      this.griddata[0].Rows.push("");
      this.griddata[0].Fieldtypes.push("text");
    },
    async removerowsrows(index) {
      this.griddata[0].Rows.splice(index, 1);
      this.griddata[0].Fieldtypes.splice(index, 1);
      this.gridrows.splice(index, 1);
    },
    async incrementcolumns() {
      if (this.gridcolumns.length == 0) {
        this.gridcolumns.push(1);
      } else {
        let lastelement = this.gridcolumns[this.gridcolumns.length - 1];
        this.gridcolumns.push(lastelement + 1);
      }
      this.griddata[0].Columns.push("");

      // this.griddata[0].Columns.push("");
    },
    async removecolumns(index) {
      this.griddata[0].Columns.splice(index, 1);
      this.gridcolumns.splice(index, 1);
    },
    createForm() {
      this.pagearray = [];
      this.showCreateFormModal = !this.showCreateFormModal;
    },
    eHandler(key, data) {
      this.width[key] = data.width;
      this.height[key] = data.height;
      this.left[key] = data.left;
      this.top[key] = data.top;
    },
    drag(ev, element) {
      this.elementUsed = element;
      ev.dataTransfer.dropEffect = "copy";
      ev.dataTransfer.effectAllowed = "copy";
      ev.dataTransfer.setData("text", ev.target.id);
    },
    drop(ev, element) {
      if (element == "addone") {
        this.addonecolumn();
      }
      if (element == "addtwo") {
        this.addtwocolumn();
      }
      if (element == "addthree") {
        this.addthreecolumn();
      }
      if (element == "addfour") {
        this.addfourcolumn();
      }
      if (element == "addsix") {
        this.addsixcolumn();
      }
      if (element == "header") {
        this.openModeltwo("header");
      }
      if (element == "summary") {
        this.openModeltwo("summary");
      }
      if (element == "subHeader") {
        this.openModeltwo("subHeader");
      }
      if (element == "textbox") {
        this.openModeltwo("textbox");
      }
      if (element == "scales") {
        this.scalemaxval = 0;
        this.scaleminval = 0;
        this.openModeltwo("scales");
      }
      if (element == "dropdown") {
        this.openModeltwo("dropdown");
      }
      if (element == "dropdown") {
        this.openModeltwo("dropdown");
      }
      if (element == "date") {
        this.openModeltwo("date");
      }
      if (element == "time") {
        this.openModeltwo("time");
      }
      if (element == "dateandtime") {
        this.openModeltwo("dateandtime");
      }
      if (element == "numberanddate") {
        this.openModeltwo("numberanddate");
      }
      if (element == "uploadfile") {
        this.openModeltwo("uploadfile");
      }
      if (element == "grid") {
        this.griddata = [
          {
            Rows: [""],
            Columns: [""],
            Fieldtypes: ["text"],
            Fielddata: {},
            Fieldoptions: {},
          },
        ];
        this.gridrows = [1];
        this.gridcolumns = [1];
        this.openModeltwo("grid");
      }
      if (element == "qrcode") {
        this.openModeltwo("qrcode");
      }
      if (element == "year") {
        this.openModeltwo("year");
      }
      if (element == "radio") {
        this.openModeltwo("radio");
      }
      if (element == "checkbox") {
        this.openModeltwo("checkbox");
      }
      if (element == "textarea") {
        this.openModeltwo("textarea");
      }
      if (element == "image") {
        this.onFileChange();
      }
      if (element == "calculation") {
        this.openModeltwo("calculation");
      }
      if (element == "repeatedmodel") {
        this.openModeltwo("repeatedmodel");
      }
      if (element == "repeatedmeasure") {
        this.openModeltwo("repeatedmeasure");
      }
      if (element == "repeatingfield") {
        this.openModeltwo("repeatingfield");
      }
      if (element == "model") {
        this.clearmodelfields();
        this.openModeltwo("model");
      }
      this.highlitedcolumn = "";
    },
    async onFileChange() {
      if (this.columnselected) {
        this.tempfieldname = "";
        this.labeltext = "";
        this.fieldname = "";
        this.values = [];
        document.getElementById("fileInput").click();
      } else {
        this.adddefaultcolumn();
        this.tempfieldname = "";
        this.labeltext = "";
        this.fieldname = "";
        this.values = [];
        this.qrcodecontents = "";
        (this.qrData = ""), document.getElementById("fileInput").click();
      }
    },
    async helloButton(event) {
      this.selectedFile = "";
      this.imageData = "";
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.selectedFile = input.files[0];
      }
      await this.submitFile();
      this.openModelcomponent("image");
    },
    async submitFile() {
      var formData = new FormData();
      formData.append("Document", this.selectedFile);
      formData.append("DocumentTypeName", "TemplateImage");
      formData.append("DocumentTitle", "");
      formData.append("DocumentDescription", "");
      formData.append("OrganizationId", "");

      await axios
        .post(`${this.fileuploadurl}/create`, formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          this.imagid = `${response.data.data[0]},${response.data.data[1]}`;
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
    async incrementrows() {
      if (this.gridrows.length == 0) {
        this.gridrows.push(1);
      } else {
        let lastelement = this.gridrows[this.gridrows.length - 1];
        this.gridrows.push(lastelement + 1);
      }
      this.griddata[0].Rows.push("");
      this.griddata[0].Fieldtypes.push("text");
    },
    async incrementcolumns() {
      if (this.gridcolumns.length == 0) {
        this.gridcolumns.push(1);
      } else {
        let lastelement = this.gridcolumns[this.gridcolumns.length - 1];
        this.gridcolumns.push(lastelement + 1);
      }
      this.griddata[0].Columns.push("");
    },
    async onclosed() {
      this.$router.push(`/formlist/${this.formid}`);
    },
    async viewtemplatefun() {
      this.newtemplate[0].templateId;
      store.dispatch("setViewtemplateid", this.newtemplate[0].templateId);
      store.dispatch("setViewformid", this.newtemplate[0].formId);
      store.dispatch("setversion", this.version);
      localStorage.setItem('latestTempId', this.currentTempID);
      this.$router.push(`/viewform/${this.templateid}`);
    },
    async dashboard() {
      this.$router.push(`/dashboard`);
    },
    async folder() {
      this.$router.push(`/folder`);
    },
    async template() {
      this.$router.push(`/formlist/${this.formid}`);
      // this.$router.push(`/formlist/a9f05f57-55ed-4f9c-bc68-209dea110bc3`);
    },
    async savetestfun(alertstatus) {
      let cansave = true;
      this.newtemplate[0].pages.forEach((item) => {
      });

      if (this.newtemplate[0].pages == 0) {
        cansave = false;
      } else {
        await this.newtemplate[0].pages.forEach((data) => {
          if (data.field.length == 0) {
            cansave = false;
          }
        });
      }
      if (cansave) {
        if (this.version == null || this.version == "") {
          alert("Please enter version");
        } else {
          if (alertstatus == "noalert") {
            this.updatefun("updatefields");
          } else {
            if (this.currentversion == this.version) {
              this.updatefun("update");
            } else {
              this.savefun("newsave");
            }
          }
        }
      } else {
        // alert("Form cannot be saved with empty pages");
        alert(this.$t('navigations.form cannot be saved with empty pages'))
      }
    },
    async handleIdFromChild(idFromChild) {
      await this.getPagesInitially(idFromChild, "1.0");
      this.isformlocked = false;
    },
    async newtemplateversion() {
      let newformidd = store.getters.getnewversionformid;
      this.sourcetemplateidcreate = store.getters.getsourceTemplateId;
      this.version = store.getters.getnewversion;
      this.newversioncreate = true;
      this.isformlocked = false;
      this.newversionViewTemp = true;
      this.savefun("newversion");
      this.currentversion = this.version;
      this.getForms();
      this.closecreatenewversion();
    },
    async gettemplatedetails() {
      await axios
        .get(
          `${this.baseurl}/forms/template/get/${this.$route.query.templateId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.newtemplate[0].formname = res.data.templateName;
          this.newtemplate[0].pages = res.data.pages;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    closecreatenewversion() {
      this.newversionformid = "";
      this.creatversionpopup = false;
    },
    async createVersionClicked(formid,version,sourceid){
      let currentTempID = this.versionhistoryarray[this.versionhistoryarray.length -1].templateId;
      await this.createnewversion(formid,version,sourceid,currentTempID)
    },
    async createnewversion(formid, version, sourceid, currentTempID) {
      store.dispatch("setsourceTemplateId", sourceid);
      store.dispatch("setnewversionformid", formid);
      this.newversionformid = formid;
      this.currentformversion = version;
      this.creatversionpopup = true;
      this.currentTempID = currentTempID;
      this.fieldNames=[]
    },
    async savefun(typeofform) {
      const idtoken = store.getters.getIdToken;
      const vhistorynote = store.getters.getversionhistorynote;
      await this.gettemplatedetails();
      console.log("template is",this.newtemplate[0])
      const oldTemplateId =this.newtemplate[0].templateId
      this.newtemplate[0].version = this.version;
      this.newtemplate[0].status = "New";
      this.newtemplate[0].language = "English";
      this.newtemplate[0].languageCode = "en";
      this.newtemplate[0].lastModifiedAt = "2023-03-08T04:54:42.752Z";
      this.newtemplate[0].isNewVersion = this.newversioncreate;
      this.newtemplate[0].sourceId = this.sourcetemplateidcreate;
      this.newtemplate[0].studyId = localStorage.getItem("study_id_ref");
      (this.newtemplate[0].versionHistory = vhistorynote),
        await axios
          .post(
            `${this.baseurl}/forms/templatedesign/saveformtemplate`,
            this.newtemplate[0],
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.newtemplate[0].templateId = res.data;
            this.getversionhistory(this.sourcetemplateidcreate, false);
            // alert("Template added successfully.");
            alert(this.$t('navigations.form added successfully'))

            if (typeofform == "newversion") {
              this.copyEditCheck(oldTemplateId,this.newtemplate[0].templateId)
              this.getPagesInitially(this.sourcetemplateidcreate, this.version);
            }
          })
          .catch((err) => {
            console.log("add err response:", err);
          });
      this.newversioncreate = false;
      this.sourcetemplateidcreate = "";
    },
    async copyEditCheck(oldTemplateId,newTemplateId){
      const idtoken = store.getters.getIdToken;
      await axios
      .post(
        `${this.baseurl}/forms/editcheck/copyeditcheck?oldTemplateId=${oldTemplateId}&newTemplateId=${newTemplateId}`,{},
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res",res)
      })
      .catch((err) => {
        console.log("add err response:", err);
      });
    },
    async updatefun(funval) {
      const idtoken = store.getters.getIdToken;
      this.newtemplate[0].templateId = this.newtemplate[0].templateId;
      this.newtemplate[0].version = this.version.toString();
      await axios
        .put(
          `${this.baseurl}/forms/templatedesign/updateformtemplate`,
          this.newtemplate[0],
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (funval == "update") {
            alert(this.$t('navigations.template updated successfully'))
            this.loadingBlock=false
            
          } else if (funval == "delete") {
            alert(this.$t('navigations.page deleted successfully'))
            this.loadingBlock=false
          } else if (funval == "addpage") {
            //alert("Page updated successfully.");
          }
        })
        .catch((err) => {
          console.log("add err response:", err);
          this.loadingBlock=false
        });
    },
    async reloadfun() {
      const idtoken = store.getters.getIdToken;
      if (this.templateid != 0 && this.templateid != undefined) {
        this.update = true;
        await axios
          .get(
            `${this.baseurl}/forms/templatedesign/getformtemplatedetails?id=${this.templateid}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.newtemplate[0] = res.data;
            this.formname = res.data.formname;
            this.issaved = true;
            this.assigntemplate();
          })
          .catch((err) => {
            console.log("add err response:", err);
          });
      } else {
        this.createtemplate();
      }
    },
    async assigntemplate() {
      if (this.newtemplate[0].pages) {
        await this.reloadtemplate();
        await this.tempvarassign();
      } else {
        this.createtemplate();
      }
    },
    async reloadtemplate() {
      this.fieldnamesarray = [];
      this.newtemplate[0].createdAt = new Date();
      let tempvar = this.newtemplate[0];
      this.version = 1;
      this.currentversion = 1;
      this.testtempvar = [];
      this.testtempvar = [
        {
          pages: [],
          formId: tempvar.formId,
          formname: tempvar.formname,
          language: "english",
          status: "new",
          version: this.version,
          totalPages: tempvar.totalPages,
        },
      ];
      this.totalpages = tempvar.pages.length;
      tempvar.pages.forEach(async (element) => {
        let pageindex = tempvar.pages.indexOf(element) + 1;
        this.pagearray.push(element.pageName);
        let testgrouporderarray = [];
        let pageforedit = {
          pageName: element.pageName,
          pageNo: element.pageNo,
          Group: [],
        };
        if (element.field.length == 0) {
          let emptypages = {
            pageName: element.pageName,
            pageNo: element.pageNo,
            Group: [],
          };

          this.testtempvar[0].pages.push(emptypages);
        } else {
          let pggropno = parseInt(
            element.field[element.field.length - 1].groupOrderNo
          );
          this.totalgroups["page" + pageindex] = pggropno;
          await element.field.forEach((subelement) => {
            if (!testgrouporderarray.includes(subelement.groupOrderNo)) {
              testgrouporderarray.push(subelement.groupOrderNo);
              let newgroup = {
                Groupno: subelement.groupOrderNo,
                components: subelement.groupOrderNo,
                field: [],
              };
              pageforedit.Group.push(newgroup);
            }
          });
          element.field.forEach((subelem) => {
            this.fieldnamesarray.push(subelem.fieldName.trim().toLowerCase());
            if (subelem.type == "repeatingfield") {
              this.repeatedgroupgroupnos[subelem.fieldName] = [];
              if (subelem.repeatingFields.length != 0) {
                subelem.repeatingFields.forEach((repeatedgroupelement) => {
                  this.fieldnamesarray.push(
                    repeatedgroupelement.fieldName.trim().toLowerCase()
                  );
                  if (
                    !this.repeatedgroupgroupnos[subelem.fieldName].includes(
                      repeatedgroupelement.groupOrderNo
                    )
                  ) {
                    this.repeatedgroupgroupnos[subelem.fieldName].push(
                      repeatedgroupelement.groupOrderNo
                    );
                  }
                });
              }
            }
            let fieldinfo = {
              fieldName: subelem.fieldName,
              label: subelem.label,
              fieldno: subelem.fieldno,
              value: subelem.value,
              status: subelem.status,
              validatedBy: subelem.validatedBy,
              validatedAt: subelem.validatedAt,
              comment: subelem.comment,
              repeatingFields: subelem.repeatingFields,
              groupOrderNo: subelem.groupOrderNo,
              type: subelem.type,
              limit: subelem.limit,
              validation: subelem.validation,
              imagePath: subelem.imagePath,
              options: subelem.options,
              required: subelem.required,
              qrData: subelem.qrData,
              align: subelem.align,
            };
            pageforedit.Group.forEach((groupselect) => {
              if (groupselect.Groupno == subelem.groupOrderNo) {
                fieldinfo.fieldno = groupselect.field.length;
                groupselect.field.push(fieldinfo);
              }
            });
          });
          this.testtempvar[0].pages.push(pageforedit);
        }
      });
    },
    async tempvarassign() {
      this.temparray = this.testtempvar;
      this.testtempvar[0].pages.forEach((item) => {
        item.Group.forEach((groupitem) => {
          groupitem.field.forEach((subitem) => {
            this.calcfieldnamesarray.push(subitem.fieldName.toLowerCase());
          });
        });
      });
    },
    async openModel() {
      window.scrollTo(0, 0);
      this.showModalll = 1;
    },
    async closemodall() {
      // document.body.style.overflow = 'auto';
      this.showModalll = this.showModalll + 1;
    },
    async openModeltwo(val) {
      this.clearInput();
      if (val == "repeatingfield") {
        if (this.columnselected) {
          this.tempfieldname = "";
          this.labeltext = "";
          this.fieldname = "";
          this.values = [];
          this.qrcodecontents = "";
          if (this.temparray.length != 0 && this.temparray[0].pages.length != 0) {
            this.createrepeatedfield();
          }
        } else {
          this.adddefaultcolumn();
          this.tempfieldname = "";
          this.labeltext = "";
          this.fieldname = "";
          this.values = [];
          this.qrcodecontents = "";
          if (this.temparray.length != 0 && this.temparray[0].pages.length != 0) {
            this.createrepeatedfield();
          }
        }
      } else {
        if (this.columnselected) {
          this.selectedOption = "no"
          this.selectedForm = ""
          this.selectedField = ""
          this.showText = false
          this.selectedFieldCondition = ""
          this.exclusionMessages = [
          ]
          this.dependencies=[
          ]
          this.generalValidationsError=[]
          this.exclusionMessagesError=[]
          this.dependencyMessagesError=[]
          this.calculationMessagesError=[]
          this.generalValidations=[]
          this.dateFormat = ""
          this.calculationsArray=[
          ]
          this.showSuggestions=[false]
          if (val == 'textarea') {
            this.conditionsArray = this.textareaOptions
            this.selectedConditionsArray = this.textareaOptions
          }
          if (val == 'date') {
            this.getdateformatlist()
            this.conditionsArray = this.dateOptions
            this.selectedConditionsArray = this.dateOptions
          }
          // if(val=='dateandtime' || val=='numberanddate'){
          //   this.getdateformatlist()
          // }
          if (val == "checkbox") {
            this.conditionsArray = this.checkboxOptions
            this.selectedConditionsArray = this.checkboxOptions
          }
          if (val == "dropdown") {
            this.conditionsArray = this.dropdownOptions
            this.selectedConditionsArray = this.dropdownOptions 
          }
          if (val == "textbox" || val == "text") {
            this.conditionsArray = this.dropdownOptions
            this.selectedConditionsArray = this.textboxOptions
          }
          if (val == "scales") {
            this.conditionsArray = this.scaleOptions
            this.selectedConditionsArray = this.scaleOptions
          }
          if (val == "radio") {
            this.conditionsArray = this.radioOptions
            this.selectedConditionsArray = this.radioOptions
          }
          if (val == "year") {
            this.conditionsArray =  this.yearOptions
            this.selectedConditionsArray = this.yearOptions
          }
          this.tempfieldname = "";
          this.labeltext = "";
          this.fieldname = "";
          this.values = [];
          this.qrcodecontents = "";
          this.qrData = "";
          this.openModelcomponent(val);
        } else {
          this.dateFormat = ""
          this.calculationsArray=[
            
          ]
          this.selectedOption = "no"
          this.selectedForm = ""
          this.selectedField = ""
          this.showText = false
          this.selectedFieldCondition = []
          this.exclusionMessages = [
          ]
          this.dependencies=[
          ]
          this.generalValidationsError=[]
          this.exclusionMessagesError=[]
          this.dependencyMessagesError=[]
          this.calculationMessagesError=[]
          this.generalValidations=[]
          if (val == 'textarea') {
            this.conditionsArray = this.textareaOptions
            this.selectedConditionsArray = this.textareaOptions
          }
          if (val == 'date') {
            this.getdateformatlist()
            this.conditionsArray = this.dateOptions
            this.selectedConditionsArray = this.dateOptions
          }
          // if(val=='dateandtime' || val=='numberanddate'){
          //   this.getdateformatlist()
          // }
          if (val == "checkbox") {
            this.conditionsArray = this.checkboxOptions
            this.selectedConditionsArray = this.checkboxOptions
          }
          if (val == "dropdown") {
            this.conditionsArray = this.dropdownOptions
            this.selectedConditionsArray = this.dropdownOptions 
          }
          if (val == "textbox" || val == "text") {
            this.conditionsArray = this.dropdownOptions
            this.selectedConditionsArray = this.textboxOptions
          }
          if (val == "scales") {
            this.conditionsArray = this.scaleOptions
            this.selectedConditionsArray = this.scaleOptions
          }
          if (val == "radio") {
            this.conditionsArray = this.radioOptions
            this.selectedConditionsArray = this.radioOptions
          }
          if (val == "year") {
            this.conditionsArray =  this.yearOptions
            this.selectedConditionsArray = this.yearOptions
          }
          this.adddefaultcolumn();
          this.tempfieldname = "";
          this.labeltext = "";
          this.fieldname = "";
          this.values = [];
          this.qrcodecontents = "";
          this.qrData = "";
          if (
            this.temparray.length != 0 &&
            this.temparray[0].pages.length != 0
          ) {
            this.openModelcomponent(val);
          }
        }
      }
    },
    async openModelcomponent(menuval) {
      this.selectedmenu = menuval;
      window.scrollTo(0, 0);
      this.oldFieldName = this.fieldname;
      this.componentModal = true;
    },
    async closemodaltwo() {
      this.loadingBlock=false
      this.refreshFlags = {
        general: true,
        validation: false,
        dependencies:false,
        calcultaions:false
      }
      this.urlError=[false,false]
      this.generalValidations=[]
      this.generalValidationsError=[]
      this.exclusionMessagesError=[]
      this.rangeErrors = [false, false, false, false];
      this.daterangeErrors=[false,false,false,false]
      this.yearrangeError=[false,false,false,false]
      this.timerangeErrors=[false,false,false,false]
      this.calculationsArray=[]
      this.componentModal = false;
      this.isedit = false;
      this.columnselected = false;
      this.radiooptions = "";
      this.radiovalues = "";
      this.singlemodeloptions = "";
      this.singlemodelvalues = "";
      this.selectedoptiongroupmodel = "";
      this.selectedmodel = "";
      this.editingOption = null;
      (this.maxvalue = ""), (this.minvalue = "");
      this.maxlength = "";
      this.minlength = "";
      this.minDate = ""
      this.maxDate = ""
      this.minTime = ""
      this.maxTime = ""
      this.url=""
    },
    async deletecomponent() {
      this.loadingBlock=true
      this.newtemplate[0].pages[this.pageno - 1].field.forEach((element) => {
        if (element.fieldName == this.fieldname) {
          let index = this.newtemplate[0].pages[this.pageno - 1].field.indexOf(
            element
          );
          this.newtemplate[0].pages[this.pageno - 1].field.splice(index, 1);
          //let pagegroup = this.selectedgroup["page" + this.pageno] - 1;
          console.log("new templateeeee.........", this.newtemplate);
          this.temparray[0].pages[this.pageno - 1].Group[
            this.selectedpagegroup
          ].field[this.selectedfield] = {
            fieldName: this.selectedgroup["page" + this.pageno] + "field2",
            fieldno: this.selectedfield,
            groupOrderNo: this.selectedgroup["page" + this.pageno],
            type: "blankdata",
          };
        }
        this.isedit = false;
      });
      this.deleteapicall();
      this.closemodaltwo();
      await this.removefieldname(this.fieldname);
      await this.closerepeatedfield();
    },
    async onFileeditchange() {
      document.getElementById("fileInput").click();
    },
    async removefieldname(n) {
      const index = this.fieldnamesarray.indexOf(n);
      const indexInCalculationFieldNames = this.calculationFieldNames.indexOf(n);
      if (index > -1) {
        this.fieldnamesarray.splice(index, 1);
        
      }
      if (indexInCalculationFieldNames > -1) {
        this.calculationFieldNames.splice(indexInCalculationFieldNames, 1);
      }
    },
    async deleteapicall() {
      let data = {
        createdUserEmail: "string",
        createdUserId: "string",
        createdAt: "2022-06-16T04:19:38.363Z",
        lastModifiedUserEmail: "string",
        lastModifiedUserId: "string",
        lastModifiedAt: "2022-06-16T04:19:38.363Z",
        templateId: this.templateid,
        pageNo: this.pageno,
        fieldName: this.fieldname,
      };
      await axios
        .delete(`${this.baseurl}/forms/templatedesign/deletefield`, {
          data: data,
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // alert("Field deleted successfully.");
          alert(this.$t('navigations.field deleted successfully'))
          this.loadingBlock=false
          this.columnselected = false;
        })
        .catch((err) => {
          console.log("add err response:", err);
        });
    },
    async getdateformatlist() {
      await axios
        .get(`${this.baseurl}/account-core/usersettings/dateformatlist`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            }
          }
        )
        .then((res) => {
          const processedFormats = res.data.map(obj => {
            const newFormat = obj.dateFormat.replace(
              /([A-Za-z]+)/g, // Match alphabetic segments
              (match) => match === 'MM' || match === 'MMM' ? match : match.toLowerCase()
            );
            return { ...obj, dateFormat: newFormat };
          });
          this.dateList = processedFormats
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // edit field .......................................
    async editfield(getType) {
      let executeupdatecode = true;
      this.loadingBlock=true
      this.rangeErrors = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      this.generalValidationsError=[]
      this.exclusionMessagesError=[]
      this.dependencyMessagesError=[]
      this.calculationMessagesError=[]
      this.daterangeErrors=[false,false,false,false]
      this.yearrangeError=[false,false,false,false]
      this.timerangeErrors=[false,false,false]
      this.dependenciesError=[false,false,false,false]
      let repeatingfieldarray = [];
      let validScale = true;
      this.rangeoption = [];
      this.currentoptions = this.radiooptionsarray;
      this.fieldnamesarray = [];
      this.newTempValues=[]
      this.newValues=[]
      this.filteredFields=[]
      let formatedFieldname = "";
      this.showSuggestions=[false]

      const tempFieldName = localStorage.getItem("tempFieldName");
      // fieldnamesarray
      this.newtemplate[0].pages.forEach((page) => {
        page.field.forEach((field) => {
          formatedFieldname = field.fieldName ? field.fieldName.trim().toLowerCase() : ""
          if (
            field.type == "repeatingfield" ||
            field.type == "RepeatingMeasure" ||
            field.type == "repeatedmeasure"
          ) {
            if (!this.fieldnamesarray.includes(formatedFieldname)) {
              this.fieldnamesarray.push(formatedFieldname);
            }
            field.repeatingFields.forEach((rField) => {
              this.fieldnamesarray.push(rField.fieldName.trim().toLowerCase());
            });
          } else {
            if (!this.fieldnamesarray.includes(formatedFieldname)) {
              this.fieldnamesarray.push(formatedFieldname);
            }
          }
        });
      });

      if (this.type == "textbox") {
        if (
          this.values.includes('VALUERANGE') ||
          this.values.includes('CHARACTERLIMITRANGE') ||
          this.values.includes('LENGTHRANGE') ||
          this.values.includes('NUMERICRANGE')
        ) {
          await this.rangeValidations();
          if (!this.rangeErrors.includes(true)) {
            this.setvalidations();
          } else {
            this.loadingBlock=false
            validScale = false;
          }
        }
      } else if (this.type == "calculation") {
        let calculationdata = JSON.stringify(this.calculate);
        this.currentoptions = [calculationdata];
      } else if (this.type == "repeatingfield") {
        this.newfieldname = this.temprepeatingarray[0].fieldName;
        this.labeltext = this.temprepeatingarray[0].label;
        repeatingfieldarray = this.temprepeatingarray[0].repeatingFields;
        this.repeatedgroupgroupnos[this.fieldname] = [];
        if (repeatingfieldarray.length != 0) {
          repeatingfieldarray.forEach((repeatedgroupelement) => {
            if (
              !this.repeatedgroupgroupnos[this.fieldname].includes(
                repeatedgroupelement.groupOrderNo
              )
            ) {
              this.repeatedgroupgroupnos[this.fieldname].push(
                repeatedgroupelement.groupOrderNo
              );
            }
          });
        }
      } else if (this.type == "scales") {
        this.scaleValidation();
        if (this.scaleError.includes(true)) {
          this.loadingBlock=false
          validScale = false;
        }
        this.rangeoption = [
          {
            min: this.scaleminval.toString(),
            max: this.scalemaxval.toString(),
          },
        ];
      }  else if (this.type == "qrcode") {
        if (this.qrcodecontents == "" || this.qrcodecontents == null) {
          this.emptyField = true;
          this.loadingBlock=false
          validScale = false;
        }
        this.qrData = this.qrcodecontents;
      }
      else if (this.type == 'date') {
        if (
          this.values.includes('DATERANGE') || this.values.includes('DATEFORMAT')
        ) {
         
         
          await this.daterangeValidations()
          if (!this.daterangeErrors.includes(true)) {
              let minvalue=this.minDate
              if (minvalue instanceof Date) {
                // If value is a Date object, format it to dd-mm-yyyy
                const day = String(minvalue.getDate()).padStart(2, '0');
                const month = String(minvalue.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                const year = minvalue.getFullYear();
                this.minDate = `${day}-${month}-${year}`;
              } else if (typeof minvalue === 'string' && !isNaN(Date.parse(minvalue))) {
                // If value is a string and represents a valid date, parse and format it
                const date = new Date(minvalue);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                const year = date.getFullYear();
                this.minDate = `${day}-${month}-${year}`;
              }
              let maxvalue=this.maxDate
              if (maxvalue instanceof Date) {
                // If value is a Date object, format it to dd-mm-yyyy
                const day = String(maxvalue.getDate()).padStart(2, '0');
                const month = String(maxvalue.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                const year = maxvalue.getFullYear();
                this.maxDate = `${day}-${month}-${year}`;
              } else if (typeof maxvalue === 'string' && !isNaN(Date.parse(maxvalue))) {
                // If value is a string and represents a valid date, parse and format it
                const date = new Date(maxvalue);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                const year = date.getFullYear();
                this.maxDate = `${day}-${month}-${year}`;
              }
            this.setvalidations();
          } else {
            this.loadingBlock=false
            validScale = false;
          }
        }
      }
      // else if (this.type == 'dateandtime') {
      //   if (
      //     this.values.includes('DATERANGE') || this.values.includes("TIMERANGE") || this.values.includes('DATEFORMAT') || this.values.includes('TIMEFORMAT')
      //   ) {

      //     if (this.values.includes('DATERANGE') || this.values.includes('DATEFORMAT')) {
      //       await this.daterangeValidations()
      //     }
      //     if(this.values.includes('TIMERANGE')  || this.values.includes('TIMEFORMAT'))
      //     {
      //       await this.timerangeValidations()
      //     }

      //     if (!this.daterangeErrors.includes(true) && !this.timerangeErrors.includes(true)) {
      //       let minvalue=this.minDate
      //       if (minvalue instanceof Date) {
      //         // If value is a Date object, format it to dd-mm-yyyy
      //         const day = String(minvalue.getDate()).padStart(2, '0');
      //         const month = String(minvalue.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      //         const year = minvalue.getFullYear();
      //         this.minDate = `${day}-${month}-${year}`;
      //       } else if (typeof minvalue === 'string' && !isNaN(Date.parse(minvalue))) {
      //         // If value is a string and represents a valid date, parse and format it
      //         const date = new Date(minvalue);
      //         const day = String(date.getDate()).padStart(2, '0');
      //         const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      //         const year = date.getFullYear();
      //         this.minDate = `${day}-${month}-${year}`;
      //       }
      //       let maxvalue=this.maxDate
      //       if (maxvalue instanceof Date) {
      //         // If value is a Date object, format it to dd-mm-yyyy
      //         const day = String(maxvalue.getDate()).padStart(2, '0');
      //         const month = String(maxvalue.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      //         const year = maxvalue.getFullYear();
      //         this.maxDate = `${day}-${month}-${year}`;
      //       } else if (typeof maxvalue === 'string' && !isNaN(Date.parse(maxvalue))) {
      //         // If value is a string and represents a valid date, parse and format it
      //         const date = new Date(maxvalue);
      //         const day = String(date.getDate()).padStart(2, '0');
      //         const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      //         const year = date.getFullYear();
      //         this.maxDate = `${day}-${month}-${year}`;
      //       }
      //       await this.setvalidations();

      //     } else {
      //       this.loadingBlock=false
      //       validScale = false;
      //     }
      //   }
      // }
      else if (this.type == 'time') {
        if (
          this.values.includes("TIMERANGE") || this.values.includes('TIMEFORMAT')
        ) {
          await this.timerangeValidations()

          if (!this.timerangeErrors.includes(true)) {
            this.setvalidations();
          } else {
            this.loadingBlock=false
            validScale = false;
          }
        }
      }
      // else if (this.type == 'numberanddate') {
        // if (
        //   this.values.includes('DATERANGE') || this.values.includes('DATEFORMAT') ||
        //   this.values.includes("NUMBERRANGE") || this.values.includes("NUMERICRANGE") || this.values.includes('CHARACTERLIMITRANGE')
        // ) {
        //   if (this.values.includes('DATERANGE') || this.values.includes('DATEFORMAT')) {
        //     await this.daterangeValidations()
        //   }
        //   if (this.values.includes("NUMBERRANGE") || this.values.includes('NUMERICRANGE') || this.values.includes('CHARACTERLIMITRANGE')) {
        //     await this.rangeValidations()
        //   }

        //   if (!this.daterangeErrors.includes(true) && !this.rangeErrors.includes(true)) {
        //     let minvalue=this.minDate
        //     if (minvalue instanceof Date) {
        //       // If value is a Date object, format it to dd-mm-yyyy
        //       const day = String(minvalue.getDate()).padStart(2, '0');
        //       const month = String(minvalue.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        //       const year = minvalue.getFullYear();
        //       this.minDate = `${day}-${month}-${year}`;
        //     } else if (typeof minvalue === 'string' && !isNaN(Date.parse(minvalue))) {
        //       // If value is a string and represents a valid date, parse and format it
        //       const date = new Date(minvalue);
        //       const day = String(date.getDate()).padStart(2, '0');
        //       const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        //       const year = date.getFullYear();
        //       this.minDate = `${day}-${month}-${year}`;
        //     }
        //     let maxvalue=this.maxDate
        //     if (maxvalue instanceof Date) {
        //       // If value is a Date object, format it to dd-mm-yyyy
        //       const day = String(maxvalue.getDate()).padStart(2, '0');
        //       const month = String(maxvalue.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        //       const year = maxvalue.getFullYear();
        //       this.maxDate = `${day}-${month}-${year}`;
        //     } else if (typeof maxvalue === 'string' && !isNaN(Date.parse(maxvalue))) {
        //       // If value is a string and represents a valid date, parse and format it
        //       const date = new Date(maxvalue);
        //       const day = String(date.getDate()).padStart(2, '0');
        //       const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        //       const year = date.getFullYear();
        //       this.maxDate = `${day}-${month}-${year}`;
        //     }
        //     await this.setvalidations();

        //   } else {
        //     this.loadingBlock=false
        //     validScale = false;
        //   }
        // }

      // }
      else if (this.type == 'textarea') {
        if (
          this.values.includes('LENGTHRANGE')|| this.values.includes('CHARACTERLIMITRANGE')
        ) {
          await this.rangeValidations();

          if (!this.rangeErrors.includes(true)) {
            this.setvalidations();
          } else {
            this.loadingBlock=false
            validScale = false;
          }
        }
      }
      else if (this.type == 'year') {
        if (
          this.values.includes("YEARRANGE")
        ) {
          await this.rangeYearValidations();

          if (!this.yearrangeError.includes(true)) {
            this.setvalidations();
          } else {
            this.loadingBlock=false
            validScale = false;
          }
        }
      }
      if (
        this.fieldname.trim() == null ||
        (this.fieldname.trim() == "" && this.type != "repeatingfield")
      ) {
        // alert("Fieldname cannot be empty");
        alert(this.$t('navigations.fieldname cannot be empty'))
        this.loadingBlock=false
      }
      // else if (
      //   this.fieldname.trim().toLowerCase() != tempFieldName &&
      //   this.fieldnamesarray.includes(this.fieldname.trim().toLowerCase())
      // ) {
      //   alert("in first condition and validscale = "+validScale);
      //   if (this.newfieldname.trim() != this.fieldname.trim()) {
      //     alert(this.$t('navigations.this field name is already in use'))
      //   }
      // } 
      else if (this.type == "radio" && this.radiooptionsarray.length == 0) {
        alert("Please enter atleast one option");
        this.loadingBlock=false
      } else if (validScale) {
      
        await this.newtemplate[0].pages[this.pageno - 1].field.forEach(async (element) => {
          if (element.fieldName == this.tempfieldname) {
            let index = this.newtemplate[0].pages[
              this.pageno - 1
            ].field.indexOf(element);
            if(getType=='repeatingfield'){
              this.newtemplate[0].pages[this.pageno - 1].field[index] = {
                fieldName: this.fieldname.trim(),
                label: this.labeltext,
                value: "",
                status: "",
                validatedBy: "",
                validatedAt: "",
                comment: "",
                groupOrderNo: this.grouporderno,
                type: this.type,
                limit: this.rangeoption,
                repeatingFields: repeatingfieldarray,
                validation: this.newValues,
                imagePath: this.imagid,
                options: this.currentoptions,
                required: false,
                qrData: this.qrData,
                align:this.radioorientation,
                visibility:true
              };
              let pagegroup = this.selectedgroup["page" + this.pageno] - 1;
              this.temparray[0].pages[this.pageno - 1].Group[
                this.selectedpagegroup
              ].field[this.selectedfield] = {
                fieldName: this.fieldname.trim(),
                label: this.labeltext,
                fieldno: this.selectedfield,
                value: "",
                status: "",
                validatedBy: "",
                validatedAt: "",
                limit: this.rangeoption,
                repeatingFields: repeatingfieldarray,
                comment: "",
                groupOrderNo: this.grouporderno,
                type: this.type,
                validation: this.newTempValues,
                imagePath: this.imagid,
                options: this.currentoptions,
                required: false,
                qrData: this.qrData,
                align:this.radioorientation,
                visibility:true
              };
              this.fieldeditapi(getType);
              this.closemodaltwo()
            }
            else{
              executeupdatecode = false;
              await this.editCheckValue('edit',this.values)
              executeupdatecode = true;
              if ((this.type == "textbox" && this.newValues.length != 0) || (this.type == "textarea" && this.newValues.length != 0)) {
                if(this.selectedOption=='yes' && !this.newValues.includes('DEPENDENCY')){
                  this.newValues.push('DEPENDENCY')
                }
                if(this.calculationsArray && this.calculationsArray.length > 0 && this.calculationsArray[0].expression !== '' && !this.newValues.includes('CALCULATION')){
                  this.newValues.push('CALCULATION')
                  
                }
                this.newtemplate[0].pages[this.pageno - 1].field[index] = {
                  fieldName: this.fieldname.trim(),
                  label: this.labeltext,
                  value: "",
                  status: "",
                  validatedBy: "",
                  validatedAt: "",
                  comment: "",
                  groupOrderNo: this.grouporderno,
                  type: this.type,
                  limit: this.rangeoption,
                  repeatingFields: repeatingfieldarray,
                  validation: this.newValues,
                  imagePath: this.imagid,
                  options: this.currentoptions,
                  required: false,
                  qrData: this.qrData,
                  align:this.radioorientation,
                  visibility:true
                };
                let pagegroup = this.selectedgroup["page" + this.pageno] - 1;
                this.temparray[0].pages[this.pageno - 1].Group[
                  this.selectedpagegroup
                ].field[this.selectedfield] = {
                  fieldName: this.fieldname.trim(),
                  label: this.labeltext,
                  fieldno: this.selectedfield,
                  value: "",
                  status: "",
                  validatedBy: "",
                  validatedAt: "",
                  limit: this.rangeoption,
                  repeatingFields: repeatingfieldarray,
                  comment: "",
                  groupOrderNo: this.grouporderno,
                  type: this.type,
                  validation: this.newValues,
                  imagePath: this.imagid,
                  options: this.currentoptions,
                  required: false,
                  qrData: this.qrData,
                  align:this.radioorientation,
                  visibility:true
                };
                console.log(
                  "field",
                  this.newtemplate[0].pages[this.pageno - 1].field[index]
                );
              } else {
                if (this.type == 'date'  || this.type == 'year' || this.type == 'time') {
                    this.newTempValues =this.newValues         
  
                }
                else {
                  this.newTempValues = this.values
                }
                if(this.selectedOption=='yes' && !this.newTempValues.includes('DEPENDENCY')){
                  this.newTempValues.push('DEPENDENCY')
                }
                if(this.calculationsArray && this.calculationsArray.length > 0 && this.calculationsArray[0].expression !== '' && !this.newTempValues.includes('CALCULATION')){
                  this.newTempValues.push('CALCULATION')
              
                }
                this.newtemplate[0].pages[this.pageno - 1].field[index] = {
                  fieldName: this.fieldname.trim(),
                  label: this.labeltext,
                  value: "",
                  status: "",
                  validatedBy: "",
                  validatedAt: "",
                  comment: "",
                  groupOrderNo: this.grouporderno,
                  type: this.type,
                  limit: this.rangeoption,
                  repeatingFields: repeatingfieldarray,
                  validation: this.newTempValues,
                  imagePath: this.imagid,
                  options: this.currentoptions,
                  required: false,
                  qrData: this.qrData,
                  align:this.radioorientation,
                  visibility:true
                };
                let pagegroup = this.selectedgroup["page" + this.pageno] - 1;
                this.temparray[0].pages[this.pageno - 1].Group[
                  this.selectedpagegroup
                ].field[this.selectedfield] = {
                  fieldName: this.fieldname.trim(),
                  label: this.labeltext,
                  fieldno: this.selectedfield,
                  value: "",
                  status: "",
                  validatedBy: "",
                  validatedAt: "",
                  limit: this.rangeoption,
                  repeatingFields: repeatingfieldarray,
                  comment: "",
                  groupOrderNo: this.grouporderno,
                  type: this.type,
                  validation: this.newTempValues,
                  imagePath: this.imagid,
                  options: this.currentoptions,
                  required: false,
                  qrData: this.qrData,
                  align:this.radioorientation,
                  visibility:true
                };
              } 
              
            }
           
            this.componentKey = this.componentKey + 1;
           return;
          }
          
        });
          if(!this.generalValidationsError.some((error) => error.includes(true)) &&
          !this.exclusionMessagesError.some((error) => error.includes(true)) && !this.dependencyMessagesError.some((error) => error.includes(true)) && !this.calculationMessagesError.some((error) => error.includes(true)) && getType!='repeatingfield'){
            if(this.calculationFieldNames.length!=0){
              if(this.inputValidationElements.includes(this.type)  &&  !this.calculationFieldNames.some(existingField => existingField === this.fieldname) &&  !this.calculationFieldNames.some(existingField => existingField === this.newfieldname)){
                this.calculationFieldNames.push(this.fieldname.trim());
              }
              else if(this.calculationFieldNames.some(existingField => existingField === this.newfieldname)){
                const index = this.calculationFieldNames.indexOf(this.newfieldname);
  
                if (index !== -1) {
                    // Replace the old field name with the new field name
                    this.calculationFieldNames[index]=this.fieldname.trim();
                } 
              }
              else{
                this.calculationFieldNames.push(this.fieldname.trim());
              }
            }
            let attempts = 0;
            while (!executeupdatecode && attempts < 5) {
              await new Promise((resolve) => setTimeout(resolve, 1000));
              attempts++;
            }
            if (!executeupdatecode && attempts >= 5) {
              executeupdatecode = true;
            }
            if (executeupdatecode === true) {
            await this.fieldeditapi(this.type);
            await this.closemodaltwo();
          }
          }
          localStorage.removeItem("tempFieldName");
        }       
       
        this.fieldnamesarray.push(this.fieldname.trim().toLowerCase());
      },
    async fieldeditapi(type) {
      let repeatingfieldarray = [];
      if (type == "repeatingfield") {
        repeatingfieldarray = this.temprepeatingarray[0].repeatingFields;
      }
      if ((type == "textbox" && this.newValues.length != 0) || (type == "textarea" && this.newValues.length != 0)) {
        let data = {
          templateId: this.templateid,
          pageNo: this.pageno,
          fieldName: this.newfieldname.trim(),          
          field: {
            fieldName: this.fieldname.trim(),
            label: this.labeltext,
            status: "",
            limit: this.rangeoption,
            repeatingFields: repeatingfieldarray,
            groupOrderNo: this.grouporderno,
            type: this.type,
            validation: this.newValues,
            imagePath: this.imagid,
            options: this.currentoptions,
            qrData: this.qrData,
            align:this.radioorientation,
            visibility:true
          },
        };
       
        await axios
          .put(`${this.baseurl}/forms/templatedesign/updatefield`, data, {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            alert(this.$t('navigations.form updated successfully'))
            this.loadingBlock=false
          })
          .catch((err) => {
            console.log("update field err response:", err.response.data.detail);
            this.loadingBlock=false
          });
      } else {
        if (this.type == 'date'|| this.type == 'year' || this.type == 'time') {
          if(this.newValues.length!=0){
            this.newTempValues = this.newValues  
          }            
        }
        else {
          this.newTempValues = this.values
        }

        let data = {
          templateId: this.templateid,
          pageNo: this.pageno,
          fieldName: type=='repeatingfield' ? this.fieldname.trim() : this.newfieldname.trim(),
          field: {       
            fieldName: type=='repeatingfield' ? this.newfieldname.trim() : this.fieldname.trim(),
            label: this.labeltext,
            status: "",
            limit: this.rangeoption,
            repeatingFields: repeatingfieldarray,
            groupOrderNo: this.grouporderno,
            type: this.type,
            validation: this.newTempValues,
            imagePath: this.imagid,
            options: this.currentoptions,
            qrData: this.qrData,
            align:this.radioorientation,
            visibility:true
          },
        }
            await axios
              .put(`${this.baseurl}/forms/templatedesign/updatefield`, data, {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                alert(this.$t('navigations.form updated successfully'))
                this.loadingBlock=false

              })
              .catch((err) => {
                console.log("update field err response:", err.response.data.detail);
                this.loadingBlock=false
              })

      }
    },
    //Edit check and calculation functions starts here
    async setValidationDateRange(value1, value2, index) {
      if (
        value1 === null ||
        value1 === undefined ||
        value1 === ""
      ) {
        this.dateValidationrangeErrors[index][0] = true;
      }
      if (
        value2 === "" ||
        value2 === null ||
        value2 === undefined
      ) {
        this.dateValidationrangeErrors[index][1] = true;
      }
      if (new Date(value2) < new Date(value1)) {
        this.dateValidationrangeErrors[index][2] = true;
      }

    },
    preventNonNumericKeys(event) {
      const invalidKeys = ["e", "E", "+", "-"]; // Keys to block
      if (invalidKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
    validateMinYear(event) {
      let value = event.target.value;

      // Sanitize input
      value = value.replace(/\D/g, "").slice(0, 4);


      // Update minYear
      this.minYear = value;
    },
    validateMaxYear(event) {
      let value = event.target.value;

      // Sanitize input
      value = value.replace(/\D/g, "").slice(0, 4);

      // Update maxYear
      this.maxYear = value;
    },
    validateYearInput(value, index) {
      // Sanitize the value
      const sanitizedValue = String(value).replace(/\D/g, "").slice(0, 4);

      // Validate the year range
      const isValid = sanitizedValue >= 1900 && sanitizedValue <= 2100;

      // Update the value in the correct message object
      this.exclusionMessages[index].value[0] = sanitizedValue;

      // Track errors by index
      this.$set(this.yearValidationErrors, index, !isValid);
    },
    validatedependencyYearInput(value, index) {
      // Sanitize the value
      const sanitizedValue = String(value).replace(/\D/g, "").slice(0, 4);

      // Validate the year range
      const isValid = sanitizedValue >= 1900 && sanitizedValue <= 2100;

      // Update the value in the correct message object
      this.selectedFieldValue = sanitizedValue;

      // Track errors by index
      this.$set(this.yearValidationErrors, index, !isValid);
    },
    async changetoDefault() {
      if (this.selectedOption == "no") {
        this.selectedForm = ""
        this.selectedField = ""
        this.selectedFieldCondition = ""
        this.selectedFieldValue = ""
        this.selectedConditionsArray = []
        this.dependencies=[]
      }
      else{
          await this.getDependencyForms()
          this.dependencies=[
            {
              targetTemplateId: "",
              targetFieldName:"",
              condition: "",
              value:"",
              pageNo: "",
              isVisible:null,
              visibilityfieldname:""
            }
          ]

           this.formList.forEach((form) => {
            if (form.templateId == this.templateid) {
             this.dependencies[0].targetTemplateId=form
              this.getFields(form.templateId,form.version)
            }
          })  
          if (this.selectedmenu == 'textarea') {
            this.selectedConditionsArray = this.textareaOptions
          }
          if (this.selectedmenu == 'date') {
            this.selectedConditionsArray = this.dateOptions
          }
          if (this.selectedmenu=="checkbox") {
            this.selectedConditionsArray = this.checkboxOptions
          }
          if (this.selectedmenu=="dropdown") {
            this.selectedConditionsArray = this.dropdownOptions 
          }
          if (this.selectedmenu=="textbox" || this.selectedmenu=="text") {
            this.selectedConditionsArray = this.textboxOptions
          }
          if (this.selectedmenu=="scales") {
            this.selectedConditionsArray = this.scaleOptions
          }
          if (this.selectedmenu=="radio") {
            this.selectedConditionsArray = this.radioOptions
          }
        }

    },
    async getDependencyForms(){
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/forms/template/listtemplate?TemplateName=&StudyID=${store.getters.getStudyIs}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data) {
            this.formList=res.data.results.sort((a, b) =>
              a.templateName.localeCompare(b.templateName)
            );
          } else {
            console.log("no template found");
          }

        })
        .catch((err) => {
          console.log(err);
        });
    },
    async geteditCheckValue(fieldname,type) {
      this.showSuggestions=[false]
      this.selectedForm=""
      this.selectedField=""
      this.selectedFieldCondition=""
      this.exclusionMessages=[]
      this.generalValidations=[]
      this.selectedOption="no"
      this.calculationFields=[]
      this.calculationsArray=[]
      await this.getConditionsandValues(type)
      await axios
        .get(`${this.baseurl}/forms/editcheck/geteditcheckbyid?tempId=${this.templateid}&fieldName=${fieldname}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          })
        .then(async (res) => {
          if (res && res.data && res.data.editCheck!=null && res.data.editCheck.length != 0) {
             this.exclusionMessages = res.data.editCheck.filter(
              edit=>{
                return(
                  edit.condition!='NUMBER' &&
                  edit.condition!='ALPHANUMERIC' &&
                  edit.condition!='NUMERICRANGE' &&
                  edit.condition!='DATERANGE'&&
                  edit.condition!='TIMERANGE'&&
                  edit.condition!='YEARRANGE'&&
                  edit.condition!='EMAIL'&&
                  edit.condition!='URL' &&
                  edit.condition!='CHARACTERLIMITRANGE'&&
                  edit.condition!='REQUIRED' &&
                  edit.condition!='PHONENUMBER' &&
                  edit.condition!='DATEFORMAT' &&
                  edit.condition!='TIMEFORMAT'
                )
              }
            ).map(message => {
              if (message && message.value && Array.isArray(message.value) && message.value.length > 0) {
                if(['DATERANGE','DATE GREATER THAN','DATE EQUAL TO','DATE LESS THAN','DATE LESS THAN OR EQUAL TO','DATE NOT EQUAL TO','DATE GREATER THAN OR EQUAL TO','OPTION TO AUTOFILL CURRENT DATE'].includes(message.condition))
                {
                  const value = message.value[0];
                  if (value instanceof Date) {
                    // If value is a Date object, format it to yyyy/mm/dd
                    const year = value.getFullYear();
                    const month = String(value.getMonth() + 1).padStart(2, '0');
                    const day = String(value.getDate()).padStart(2, '0');
                    message.value[0] = `${year}-${month}-${day}`;
                  } else if (typeof value === 'string') {
                    // Handle "DD-MM-YYYY" format
                    const dateParts = value.split('-');
                    
                    // Check if the string is in the expected format "DD-MM-YYYY"
                    if (dateParts.length === 3 && !isNaN(dateParts[0]) && !isNaN(dateParts[1]) && !isNaN(dateParts[2])) {
                      // Convert the "DD-MM-YYYY" to a valid Date object
                      const day = parseInt(dateParts[0], 10);
                      const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed in Date
                      const year = parseInt(dateParts[2], 10);
              
                      const date = new Date(year, month, day);
              
                      // Check if the date is valid
                      if (!isNaN(date.getTime())) {
                        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
                        message.value[0] = formattedDate;
                      } else {
                        console.warn("Invalid date string:", value);
                      }
                    } else if (!isNaN(Date.parse(value))) {
                      // If the string is a valid ISO date string or other recognized format, parse and format
                      const date = new Date(value);
                      const year = date.getFullYear();
                      const month = String(date.getMonth() + 1).padStart(2, '0');
                      const day = String(date.getDate()).padStart(2, '0');
                      message.value[0] = `${year}-${month}-${day}`;
                    } else {
                      console.warn("Skipping non-date value:", value);
                    }
                  }
                }
                if(this.type=='year'){
                  message.value[0]=parseInt(message.value[0])
                }
              }
              return message;
            });
             this.generalValidations = res.data.editCheck.filter(
              edit=>{
                return(
                 [ 'NUMBER',
                  'ALPHANUMERIC',
                  'NUMERICRANGE',
                  'DATERANGE',
                  'TIMERANGE',
                  'YEARRANGE',
                  'EMAIL',
                  'URL',
                  'CHARACTERLIMITRANGE',
                  'REQUIRED',
                  'PHONENUMBER',
                  'DATEFORMAT',
                  'TIMEFORMAT'].includes(edit.condition)
                )
              }
            )
          }
          if (res && res.data && res.data.dependencies!=null && res.data.dependencies.length != 0) {
            this.selectedOption = "yes"
            this.dependencies=res.data.dependencies
            await this.getDependencyForms()
              this.dependencies.forEach(async (item)=>{
                this.formList.forEach((form) => {
                  if (form.templateId == item.targetTemplateId) {
                    item.targetTemplateId = form
                    this.getVersion = form.version
                  }
                })         
              await this.getFields(item.targetTemplateId.templateId, this.getVersion)
              this.fieldNames.forEach((fieldname) => {
                
                if (fieldname.fieldName == item.visibilityfieldname) {
                  item.visibilityfieldname = fieldname
                }
              })
              // this.getConditionsandValues(item.visibilityfieldname)
            })
          
            // this.selectedFieldCondition = res.data.dependencies[0].condition
            // if(this.selectedField.type=='date'){
            //   let dateValue=res.data.dependencies[0].value
            //   if (dateValue instanceof Date) {
            //     // If value is a Date object, format it to yyyy/mm/dd
            //     const year = dateValue.getFullYear();
            //     const month = String(dateValue.getMonth() + 1).padStart(2, '0');
            //     const day = String(dateValue.getDate()).padStart(2, '0');
            //     this.selectedFieldValue = `${year}-${month}-${day}`;
            //   } else if (typeof dateValue === 'string') {
            //     // Handle "DD-MM-YYYY" format
            //     const dateParts = dateValue.split('-');
                
            //     // Check if the string is in the expected format "DD-MM-YYYY"
            //     if (dateParts.length === 3 && !isNaN(dateParts[0]) && !isNaN(dateParts[1]) && !isNaN(dateParts[2])) {
            //       // Convert the "DD-MM-YYYY" to a valid Date object
            //       const day = parseInt(dateParts[0], 10);
            //       const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed in Date
            //       const year = parseInt(dateParts[2], 10);
          
            //       const date = new Date(year, month, day);
          
            //       // Check if the date is valid
            //       if (!isNaN(date.getTime())) {
            //         const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
            //         this.selectedFieldValue = formattedDate;
            //       } else {
            //         console.warn("Invalid date string:", value);
            //       }
            //     } else if (!isNaN(Date.parse(value))) {
            //       // If the string is a valid ISO date string or other recognized format, parse and format
            //       const date = new Date(value);
            //       const year = date.getFullYear();
            //       const month = String(date.getMonth() + 1).padStart(2, '0');
            //       const day = String(date.getDate()).padStart(2, '0');
            //      this.selectedFieldValue = `${year}-${month}-${day}`;
            //     } else {
            //       console.warn("Skipping non-date value:", this.selectedFieldValue);
            //     }
            //   }
            // }
            // this.selectedFieldValue = res.data.dependencies[0].value
            
            
          }
          if(res && res.data && res.data.calculations!=null && res.data.calculations.length != 0) 
          {
            this.calculationsArray=res.data.calculations
          }
          if(res.data=="" || res.data==null || res.data==undefined) {
            this.dependencies=[
             
            ]
            this.selectedOption = "no"
            this.selectedForm = ""
            this.selectedField = ""
            this.showText = false
            this.selectedFieldCondition = ""
            this.selectedFieldValue = ""
            this.exclusionMessages = []
            this.generalValidations=[]
            this.dateValidationrangeErrors = []
          }
        })
        .catch((err) => {
          console.log("update field err response:", err);
        });
    }, 
    async editCheckValue(funcType) {   
      
      if(this.generalValidations.length!=0){
        // Map for condition-based value assignments
          const conditionValueMap = {
            NUMERICRANGE: [this.minvalue ? this.minvalue.toString() : "", this.maxvalue ? this.maxvalue.toString():""],
            DATEFORMAT: [this.dateFormat ? this.dateFormat.toString() : ""],
            DATERANGE: [this.minDate ? this.minDate.toString() : "", this.maxDate ? this.maxDate.toString() : ""],
            TIMEFORMAT: [this.timeFormat],
            TIMERANGE: [this.minTime ? this.minTime.toString() : "", this.maxTime ? this.maxTime.toString() : ""],
            YEARRANGE: [this.minYear ? this.minYear.toString() :"", this.maxYear ? this.maxYear.toString() : ""],
            CHARACTERLIMITRANGE: [this.minlength ? this.minlength.toString() : "", this.maxlength ? this.maxlength.toString() : ""],
          };

          // Assign values based on condition
          this.generalValidations.forEach((validation) => {
            if (validation.condition && conditionValueMap[validation.condition]) {
              validation.value = conditionValueMap[validation.condition];
            }
          });

          // Initialize validation error array
          this.generalValidationsError = this.generalValidations.map((validation) => {
            const errors = [false, false];
            if (validation.condition) {
              if (!validation.messageType) errors[0] = true;
              if (!validation.message) errors[1] = true;
            }
            return errors;
          });

          // Check for any validation errors
          if (this.generalValidationsError.some((error) => error.includes(true))) {
            alert(this.$t('navigations.please fill all values for general validations'));
            this.loadingBlock=false
          }
              
      }
      if(this.exclusionMessages.length>0){
        await this.exclusionMessages.forEach((message)=>{
          if (
            [
              'DATERANGE',
              'DATE GREATER THAN',
              'DATE EQUAL TO',
              'DATE LESS THAN',
              'DATE LESS THAN OR EQUAL TO',
              'DATE NOT EQUAL TO',
              'OPTION TO AUTOFILL CURRENT DATE',
            ].includes(message.condition)
          ){
            let value=message.value[0]
            if (value instanceof Date) {
              // If value is a Date object, format it to dd-mm-yyyy
              const day = String(value.getDate()).padStart(2, '0');
              const month = String(value.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
              const year = value.getFullYear();
              message.value[0] = `${day}-${month}-${year}`;
            } else if (typeof value === 'string' && !isNaN(Date.parse(value))) {
              // If value is a string and represents a valid date, parse and format it
              const date = new Date(value);
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
              const year = date.getFullYear();
              message.value[0] = `${day}-${month}-${year}`;
            }
          }
          if(message.condition=='IS CHECKED ON')
          {
            message.value[0]="true"
          }
          if(message.condition=='IS CHECKED OFF')
            {
              message.value[0]="false"
            }
        })
      // Initialize and validate error array
          this.exclusionMessagesError = this.exclusionMessages.map((validation) => {
            const errors = [false, false, false, false]; // [condition, messageType, message, value]

            if (!validation.condition) errors[0] = true;
            if (!validation.messageType) errors[1] = true;
            if (!validation.message) errors[2] = true;
            if (!validation.value || validation.value.length === 0) errors[3] = true;

            return errors;
          });

          // Check for any validation errors
          const hasErrors = this.exclusionMessagesError.some((error) => error.includes(true));
          if (hasErrors) {
            alert(this.$t('navigations.please fill all values for custom validations'));
            this.loadingBlock=false
          }
        }
      if(this.calculationsArray.length>0){
         // Initialize and validate error array
         this.calculationMessagesError = this.calculationsArray.map((validation) => {
          const errors = [false]; // [condition, messageType, message, value]

          if (!validation.expression) errors[0] = true;


          return errors;
        });

        // Check for any validation errors
        const hasErrors = this.calculationMessagesError.some((error) => error.includes(true));
        if (hasErrors) {
          alert(this.$t('navigations.please fill all values for calculations'));
          this.loadingBlock=false
        }
      }
      if(this.selectedOption=='yes'){
        await this.dependencies.forEach((dependency)=>{
        if(dependency.condition=='IS CHECKED ON')
          {
            dependency.value="true"
          }
        if(dependency.condition=='IS CHECKED OFF')
          {
            dependency.value="false"
          }
        })
        this.dependencyMessagesError = this.dependencies.map((validation) => {
          const errors = [false, false, false, false, false]; // [condition, messageType, message, value]

          if (!validation.targetTemplateId) errors[0] = true;
          if (!validation.visibilityfieldname) errors[1] = true;
          if (!validation.condition) errors[2] = true;
          if (!validation.value || validation.value.length === 0) errors[3] = true;
          if(validation.isVisible == null) errors[4] = true

          return errors;
        });

        // Check for any validation errors
          const hasErrors = this.dependencyMessagesError.some((error) => error.includes(true));
          if (hasErrors) {
            alert(this.$t('navigations.please fill all values for dependency'));
            this.loadingBlock=false
          }
        }
      if(this.calculationsArray && this.calculationsArray.length!=0 && this.calculationsArray[0].expression!=''){
        this.calculationsArray.forEach((item)=>{
          if (!item.hasOwnProperty('outputField')) {
            item.outputfield = this.fieldname;
          }
        })
      }
      if( !this.generalValidationsError.some((error) => error.includes(true)) &&
      !this.exclusionMessagesError.some((error) => error.includes(true)) &&
      !this.dependencyMessagesError.some((error) => error.includes(true)) && !this.calculationMessagesError.some((error) => error.includes(true)))
        {      
          if(this.selectedField.type=='date'){
            let selectedDate=this.selectedFieldValue
            if (selectedDate instanceof Date) {
              // If value is a Date object, format it to dd-mm-yyyy
              const day = String(selectedDate.getDate()).padStart(2, '0');
              const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
              const year = selectedDate.getFullYear();
              this.selectedFieldValue = `${day}-${month}-${year}`;
            } else if (typeof selectedDate === 'string' && !isNaN(Date.parse(selectedDate))) {

              // If value is a string and represents a valid date, parse and format it
              const date = new Date(selectedDate);
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
              const year = date.getFullYear();
              this.selectedFieldValue = `${day}-${month}-${year}`;
            }
          }
      
          this.exclusionMessages.push(...this.generalValidations)
          const transformedArray = this.dependencies.map(item => {
            return {
              targetTemplateId: item.targetTemplateId ? item.targetTemplateId.templateId : "",
              targetFieldName:"",
              condition:item.condition,
              value:item.value,
              pageNo:"1",
              isVisible:item.isVisible,          
              visibilityfieldname: item.visibilityfieldname ? item.visibilityfieldname.fieldName : "", 
            };
          });
          let editCheckData = {
            templateId: this.templateid,
            formName: this.formname,
            studyId: store.getters.getStudyIs,
            version: this.version,
            fields: [
              {
                fieldName: this.fieldname.trim(),
                type: this.type,
                editCheck: this.exclusionMessages,
                dependencies: [
                  this.selectedOption == 'yes' && this.dependencies != undefined ?transformedArray: []
                ].flat(),
                calculations:this.calculationsArray.length!=0 ? this.calculationsArray.filter(calc => calc.expression && calc.expression.trim() !== '') : []
              }
            ]
          }
          await axios
            .post(`${this.baseurl}/forms/editcheck/createeditcheck`, editCheckData, {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            })
            .then(async (res) => {
              if (funcType == 'edit') {
                console.log("Edit check added")
              }
              await this.gettemplatebytempid(this.templateid);
              this.loadvisibilities();
              return;
            })
            .catch((err) => {
              this.loadingBlock=false
              console.log("update field err response:", err.response.data.detail);
              return;
            });
        
      }

    },
    async setDate(index,condition) {
      if(condition=='OPTION TO AUTOFILL CURRENT DATE')
      {
        this.exclusionMessages[index].value[0] = this.currentDate
      }
      else{
        this.exclusionMessages[index].value[0] = ""
      }
      
    },  
    async getFields(templateId, version) {
      this.fieldNames = []
      await axios
        .get(
          `${this.baseurl}/forms/templatedesign/listtemplatefields?formId=${templateId}&version=${version}`,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.fieldValues = res.data
          res.data.forEach((page) => {
            page.field.forEach((fields) => {            
              if (
                fields.type!='header' && fields.type!='subHeader' &&
                fields.type!='repeatingfield' &&
                !this.fieldNames.some(existingField => existingField.fieldName === fields.fieldName)
              ) {
                this.fieldNames.push(fields);
              }
              if(fields.type=='repeatingfield'){
                  if(!this.newRepeatedFieldnames.some(existingField => existingField.fieldName === fields.fieldName)){
                    if(fields.type!='header' && fields.type!='subHeader'){
                      this.newRepeatedFieldnames.push(fields)
                    }
                  }            
              }
            })
            })
            this.fieldNames.push(...this.newRepeatedFieldnames);
    
        }).catch((err) => {
          console.error(err)
        })
    },
    async getConditionsandValues(type) {
      this.showText = false
      this.selectedFieldCondition=""
      this.selectedFieldValue=""
      if (type == 'textarea') {
        this.selectedConditionsArray = this.textareaOptions
        this.showText = true
      }
      if (type == 'date') {
        this.selectedConditionsArray = this.dateOptions
        this.showText = true
      }
      if (type == "checkbox") {
        this.selectedConditionsArray = this.checkboxOptions
        this.showText = true
      }
      if (type == "dropdown") {
        this.selectedConditionsArray = this.dropdownOptions        
      }
      if (type == "textbox") {
        this.selectedConditionsArray = this.textboxOptions
        this.showText = true
      }
      if (type == "scales") {
        this.selectedConditionsArray = this.scaleOptions
      }
      if (type == "radio" || type=="radiohorizondal") {
        this.selectedConditionsArray = this.radioOptions
      }
    },
    deleteMessage(type,index) {
      if(type=='general'){
        this.values.splice(index, 1);
        this.generalValidations.splice(index, 1);
      }
      if(type=='custom'){
        this.exclusionMessages.splice(index, 1);
      }
      if(type=='dependencies'){
        this.dependencies.splice(index,1)
      }
      if(type=='calculations'){
        this.calculationsArray.splice(index,1)
      }
    },
    handleKeydown(event) {
      if (event.key === "ArrowDown" && this.showSuggestions) {
        event.preventDefault(); // Prevent caret movement
        // Logic for navigating suggestions can be added here
      }
    },
    handleInput(event, expression, index) {
      const cursorPosition = event.target.selectionStart;
      const textBeforeCursor = expression.slice(0, cursorPosition);
    
      // Detect the last '@' or '#' character
      const lastAtIndex = textBeforeCursor.lastIndexOf(this.triggerChar); // '@'
      const lastHashIndex = textBeforeCursor.lastIndexOf('#');
    
      if (lastAtIndex !== -1) {
        this.lastTriggerIndex = lastAtIndex;
        const typedValue = textBeforeCursor.slice(lastAtIndex + 1);
    
        // Filter suggestions based on the typed value
        this.filteredFields = this.calculationFieldNames.filter(field =>
          field.startsWith(typedValue)
        );
    
        this.showSuggestions[index] = this.filteredFields.length > 0;}
      else{
        this.showSuggestions[index] = false;
      }
    },    
    selectSuggestion(field, expression, index) {
      const textBeforeCursor = expression.slice(0, this.lastTriggerIndex);
      const textAfterCursor = expression.slice(this.lastTriggerIndex + 1);
      const spaceIndex = textAfterCursor.indexOf(" ");
      
      // Only slice if a space exists
      const afterCursorText = spaceIndex !== -1 ? textAfterCursor.slice(spaceIndex + 1) : "";
      
      // Update the expression
      this.calculationsArray[index].expression = `${textBeforeCursor}{${field}}${afterCursorText}`;
      this.showSuggestions[index] = false;
    
      this.$nextTick(() => {
        // Move the caret to the end of the updated text
        const textarea = document.getElementById(`calculationField-${index}`);
        textarea.selectionStart = textarea.selectionEnd = this.calculationsArray[index].expression.length;
        textarea.focus();
      });
    
      // Regular expression to find text inside {}
      const matches = this.calculationsArray[index].expression.match(/\{([^}]+)\}/g);
    
      // Extract unique field names without braces
      this.calculationsArray[index].fields= matches
        ? [...new Set(matches.map(field => field.slice(1, -1)))] // Remove duplicates and {}
        : [];
    },
    inputFields(expression,index){
      if(expression==""){
        alert(this.$t('navigations.please enter an expression'))
        console.log("Expression",expression,index)
      }
     
      else
      {
        const regex = /{(.*?)}/g;
        const matches = [...expression.matchAll(regex)];
        this.dynamicFields[index] = [...new Set(matches.map((match) => match[1]))]; // Unique fields
        this.showValidate[index]=false;
        console.log("fields are",this.dynamicFields)
      }
    
    },
    //Edit check and calculation functions ends here
    async resetvalidation(validations) {
      let valarray = [];
      validations.forEach((val) => {
        if(val.includes('LENGTHRANGE') || val.includes('CHARACTERLIMITRANGE') || val.includes('valuerange') || val.includes('VALUERANGE') || val.includes('NUMERICRANGE')){
        if (val.includes('lengthrange')  || val.includes('LENGTHRANGE') || val.includes('CHARACTERLIMITRANGE')) {
          this.minlength = val.substring(
            val.indexOf("(") + 1,
            val.indexOf(",")
          );
          this.maxlength = val.substring(
            val.indexOf(",") + 1,
            val.indexOf(")")
          );
          valarray.push('CHARACTERLIMITRANGE');
        }  if (val.includes('valuerange') || val.includes('VALUERANGE') || val.includes('NUMERICRANGE')) {
          this.minvalue = val.substring(val.indexOf("(") + 1, val.indexOf(","));
          this.maxvalue = val.substring(val.indexOf(",") + 1, val.indexOf(")"));
          valarray.push('NUMERICRANGE');
        }
      }
         else {
          valarray.push(val);
        }
      })

      this.values = valarray;
      if(this.generalValidations.length==0){
        if(this.values.length>0) 
          {
            await this.values.forEach((value,index)=>{
              let messageType;
              if(['NUMBER',
                'ALPHANUMERIC',
                'NUMERICRANGE',
                'EMAIL',
                'URL',
                'CHARACTERLIMITRANGE',
                'REQUIRED',
                'PHONENUMBER',
                ].includes(value))
                {
                if (value === "ALPHANUMERIC" ) {
                  messageType = "Only alphanumeric characters are allowed.";
                } else if (value === "EMAIL") {
                  messageType = "Invalid email format.";
                } else if (value === "CHARACTERLIMITRANGE") {
                  messageType = `Please enter between ${this.minlength} and ${this.maxlength} characters.`;
                } else if (value === "NUMBER") {
                  messageType = "Please enter a numeric value.";
                } else if (value === "NUMERICRANGE") {
                  messageType = `Value must be between ${this.minvalue} and ${this.maxvalue}.` 
                }
                else if (value === "REQUIRED") {
                  messageType = "This field is required. Please enter a value to proceed."
                }
                else if (value === "URL") {
                  messageType = "Invalid URL format"
                }
                else if (value === "PHONENUMBER") {
                  messageType ="Contact number must be entered in the format +[country code] [area code] [number]."
                }
                this.generalValidations[index]=
                {
                  condition: value,
                  value: [],
                  messageType:"",
                  message: messageType || "",
                }
              }
            })
          }
      }
      
    },
    async resetDateValidations(validations) {
      let valarray = [];
      validations.forEach((val) => {
        if (val.includes('daterange') || val.includes('DATERANGE') || val.includes('DATEFORMAT')) {
          if(val.includes('daterange') || val.includes('DATERANGE'))
          {
            let minvalue=val.substring(
              val.indexOf("(") + 1,
              val.indexOf(",")
            );
            if (minvalue instanceof Date) {
              // If value is a Date object, format it to yyyy/mm/dd
              const year = minvalue.getFullYear();
              const month = String(minvalue.getMonth() + 1).padStart(2, '0');
              const day = String(minvalue.getDate()).padStart(2, '0');
              this.minDate = `${year}-${month}-${day}`;
            } else if (typeof minvalue === 'string') {
              // Handle "DD-MM-YYYY" format
              const dateParts = minvalue.split('-');
              
              // Check if the string is in the expected format "DD-MM-YYYY"
              if (dateParts.length === 3 && !isNaN(dateParts[0]) && !isNaN(dateParts[1]) && !isNaN(dateParts[2])) {
                // Convert the "DD-MM-YYYY" to a valid Date object
                const day = parseInt(dateParts[0], 10);
                const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed in Date
                const year = parseInt(dateParts[2], 10);
        
                const date = new Date(year, month, day);
        
                // Check if the date is valid
                if (!isNaN(date.getTime())) {
                  const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
                  this.minDate = formattedDate;
                } else {
                  console.warn("Invalid date string:", minvalue);
                }
              } else if (!isNaN(Date.parse(minvalue))) {
                // If the string is a valid ISO date string or other recognized format, parse and format
                const date = new Date(minvalue);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
               this.minDate = `${year}-${month}-${day}`;
              } else {
                console.warn("Skipping non-date value:", minvalue);
              }
            }
            let maxvalue=val.substring(
              val.indexOf(",") + 1,
              val.indexOf(")")
            );
            if (maxvalue instanceof Date) {
              // If value is a Date object, format it to yyyy/mm/dd
              const year = maxvalue.getFullYear();
              const month = String(maxvalue.getMonth() + 1).padStart(2, '0');
              const day = String(maxvalue.getDate()).padStart(2, '0');
              this.maxDate = `${year}-${month}-${day}`;
            } else if (typeof maxvalue === 'string') {
              // Handle "DD-MM-YYYY" format
              const dateParts = maxvalue.split('-');
              
              // Check if the string is in the expected format "DD-MM-YYYY"
              if (dateParts.length === 3 && !isNaN(dateParts[0]) && !isNaN(dateParts[1]) && !isNaN(dateParts[2])) {
                // Convert the "DD-MM-YYYY" to a valid Date object
                const day = parseInt(dateParts[0], 10);
                const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed in Date
                const year = parseInt(dateParts[2], 10);
        
                const date = new Date(year, month, day);
        
                // Check if the date is valid
                if (!isNaN(date.getTime())) {
                  const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
                  this.maxDate = formattedDate;
                } else {
                  console.warn("Invalid date string:", maxvalue);
                }
              } else if (!isNaN(Date.parse(maxvalue))) {
                // If the string is a valid ISO date string or other recognized format, parse and format
                const date = new Date(maxvalue);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
               this.maxDate = `${year}-${month}-${day}`;
              } else {
                console.warn("Skipping non-date value:", maxvalue);
              }
            }
            console.log("value is",this.minDate,this.maxDate)
            valarray.push('DATERANGE');
          }
          if(val.includes('DATEFORMAT'))
            {
              this.dateFormat = val.substring(
                val.indexOf("(") + 1,
                val.indexOf(")")
              );
              valarray.push('DATEFORMAT');
            }
        }
        else {
          valarray.push(val);
        }
      });
      this.values = valarray;
      if(this.generalValidations.length==0){
        if(this.values.length>0) 
          {
            this.values.forEach((value,index)=>{
              let messageType;  
              if(['DATERANGE',
                'DATEFORMAT',
                'REQUIRED'
                ].includes(value))
                {
              if (value === "DATERANGE") {
                messageType = `Please enter between ${this.minDate} and ${this.maxDate} `
              }
              else if (value === "DATEFORMAT") {
                messageType = `This field must be in ${this.dateFormat} format.`
              }
              else if (value === "REQUIRED") {
                messageType = "This field is required. Please enter a value to proceed."
              }
              this.generalValidations[index]=
                {
                  condition: value,
                  value: [],
                  messageType:"",
                  message: messageType || "",
                }
              }
            })
          }
      }
    },
    async resetNumberDateValidations(validations) {
      let valarray = [];
      validations.forEach((val) => {
        if (val.includes('DATERANGE') || val.includes('DATEFORMAT')) {
          if(val.includes('DATERANGE'))
          {
            this.minDate = val.substring(
              val.indexOf("(") + 1,
              val.indexOf(",")
            );
            this.maxDate = val.substring(
              val.indexOf(",") + 1,
              val.indexOf(")")
            );
            valarray.push('DATERANGE');
          }
          if(val.includes('DATEFORMAT'))
          {
            this.dateFormat = val.substring(
              val.indexOf("(") + 1,
              val.indexOf(")")
            );
            valarray.push('DATEFORMAT');
          }
         
        }
        else if (val.includes("numberrange") || val.includes('NUMBERRANGE') || val.includes("NUMERICRANGE")   ) {
          this.minvalue = val.substring(
            val.indexOf("(") + 1,
            val.indexOf(",")
          );
          this.maxvalue = val.substring(
            val.indexOf(",") + 1,
            val.indexOf(")")
          );
          valarray.push("NUMERICRANGE");
        }
        else {
          valarray.push(val);
        }
      });
      this.values = valarray;
      if(this.generalValidations.length==0){
        if(this.values.length>0) 
          {
            this.values.forEach((value,index)=>{
              let messageType;
              if(['DATERANGE',
                'DATEFORMAT',
                'CHARACTERLIMITRANGE',
                'NUMERICRANGE',
                'REQUIRED'
                ].includes(value))
                {
              if (value === "DATERANGE") {
                messageType = `Please enter between ${this.minDate} and ${this.maxDate}.`
              }
              else if (value === "CHARACTERLIMITRANGE") {
                messageType = `Please enter between ${this.minlength} and ${this.maxlength} characters.`;
              } 
              else if (value === "NUMERICRANGE") {
                messageType = `Value must be between ${this.minvalue} and ${this.maxvalue}.` 
              }
              else if (value === "DATEFORMAT") {
                messageType = `This field must be in ${this.dateFormat} format.`
              }
              else if (value === "REQUIRED") {
                messageType = "This field is required. Please enter a value to proceed."
              }
              
              this.generalValidations[index]=
                {
                  condition: value,
                  value: [],
                  messageType:"",
                  message: messageType || "",
                }
              }
            })
          }
      }
    },
    async resetDateTimeValidations(validations) {
      let valarray = [];
      validations.forEach((val) => {
        if (val.includes('DATERANGE') || val.includes('DATEFORMAT')) {
          if(val.includes('DATERANGE'))
          {
            this.minDate = val.substring(
              val.indexOf("(") + 1,
              val.indexOf(",")
            );
            this.maxDate = val.substring(
              val.indexOf(",") + 1,
              val.indexOf(")")
            );
            valarray.push('DATERANGE');
          }
          if(val.includes('DATEFORMAT'))
          {
            this.dateFormat = val.substring(
              val.indexOf("(") + 1,
              val.indexOf(")")
            );
            valarray.push('DATEFORMAT');
          }
         
        }
        else if (val.includes("TIMERANGE") || val.includes('TIMEFORMAT')) {
          if(val.includes("TIMERANGE") )
          {
            this.minTime = val.substring(
              val.indexOf("(") + 1,
              val.indexOf(",")
            );
            this.maxTime = val.substring(
              val.indexOf(",") + 1,
              val.indexOf(")")
            );
            valarray.push("TIMERANGE");
          }
          if(val.includes('TIMEFORMAT')){
            this.timeFormat = val.substring(
              val.indexOf("(") + 1,
              val.indexOf(")")
            );
            valarray.push('TIMEFORMAT');
          }
        }
        else {
          valarray.push(val);
        }
      });
      this.values = valarray;
      if(this.generalValidations.length==0){
        if(this.values.length>0) 
          {
            this.values.forEach((value,index)=>{
              let messageType;  
              if(['DATERANGE',
                'DATEFORMAT',
                'TIMEFORMAT',
                'TIMERANGE',
                'REQUIRED'
                ].includes(value))
                {
              if (value === "DATERANGE") {
                messageType = `Please enter between ${this.minDate} and ${this.maxDate}.`
              }
              else if (value === "DATEFORMAT") {
                messageType = `This field must be in ${this.dateFormat} format.`
              }
              else if (value === "TIMERANGE") {
                messageType = `Please enter between ${this.minTime} and ${this.maxTime}.`
              }
              else if (value === "TIMEFORMAT") {
                messageType = `This field must be in ${this.timeFormat} format.`
              }
              else if (value === "REQUIRED") {
                messageType = "This field is required. Please enter a value to proceed."
              }
              this.generalValidations[index]=
                {
                  condition: value,
                  value: [],
                  messageType:"",
                  message: messageType || "",
                }
              }
            })
          }
      }
    },
    async resetTimeValidations(validations) {
      let valarray = [];
      validations.forEach((val) => {
        if (val.includes("timerange") || val.includes('TIMERANGE') || val.includes('TIMEFORMAT')) {
          if(val.includes("TIMERANGE") )
          {
            this.minTime = val.substring(
              val.indexOf("(") + 1,
              val.indexOf(",")
            );
            this.maxTime = val.substring(
              val.indexOf(",") + 1,
              val.indexOf(")")
            );
            valarray.push("TIMERANGE");
          }
          if(val.includes('TIMEFORMAT')){
            this.timeFormat = val.substring(
              val.indexOf("(") + 1,
              val.indexOf(")")
            );
            valarray.push('TIMEFORMAT');
          }
        }
        else {
          valarray.push(val);
        }
      });
      this.values = valarray;
      if(this.generalValidations.length==0){
        if(this.values.length>0) 
          {
            this.values.forEach((value,index)=>{
              let messageType;  
              if(['TIMERANGE','TIMEFORMAT','REQUIRED'].includes(value))
              {
              if (value === "TIMERANGE") {
                messageType = `Please enter between ${this.minTime} and ${this.maxTime} characters.`
              }
              else if (value === "TIMEFORMAT") {
                messageType = `This field must be in ${this.timeFormat} format.`
              }
              else if (value === "REQUIRED") {
                messageType = "This field is required. Please enter a value to proceed."
              }
              this.generalValidations[index]=
                {
                  condition: value,
                  value: [],
                  messageType:"",
                  message: messageType || "",
                }
              }
            })
          }
      }

    },
    async resetYearValidations(validations) {
      let valarray = [];
      validations.forEach((val) => {
        if (val.includes("YEARRANGE") || val.includes('yearange') ) {
          this.minYear = val.substring(
            val.indexOf("(") + 1,
            val.indexOf(",")
          );
          this.maxYear = val.substring(
            val.indexOf(",") + 1,
            val.indexOf(")")
          );
          valarray.push("YEARRANGE");
        }
        else {
          valarray.push(val);
        }
      });
      this.values = valarray;
      
      if(this.generalValidations.length==0){
        if(this.values.length>0) 
          {
            this.values.forEach((value,index)=>{
              let messageType;  
              if(['YEARRANGE','REQUIRED'].includes(value))
              {
                if (value === "YEARRANGE") {
                  messageType = `Value must be between ${this.minYear} and ${this.maxYear}.`
                }
                else if (value === "REQUIRED") {
                  messageType = "This field is required. Please enter a value to proceed."
                }
                this.generalValidations[index]=
                  {
                    condition: value,
                    value: [],
                    messageType:"",
                    message: messageType || "",
                  }
              }
            
            })
          }
      }
    },
    async addgridoptions() {
      // grid value fields adding
      this.griddata[0].Rows.forEach((eachrow) => {
        this.griddata[0].Columns.forEach((eachcolumn) => {
          this.griddata[0].Fielddata[eachrow + eachcolumn] = "";
          this.griddata[0].Fieldoptions[eachrow + eachcolumn] = [];
        });
      });
      // grid value field ends
    },
    async inputfieldedit(field, groupno) {
      await this.geteditCheckValue(field.fieldName,field.type)
      let fieldtype = field.type;
      this.values = [];
        this.scaleError = [false, false, false, false];
        this.emptyField = false;
        if (field.type == "textbox") {
          await this.resetvalidation(field.validation);
          if(field.validation.includes('NUMBER')){
            this.conditionsArray = this.scaleOptions
          }          
          else{
            this.conditionsArray = this.textboxOptions
          }
        } else {
            this.values = field.validation;
        }
        if (field.type == "scales") {
          this.scaleminval = parseInt(field.limit[0].min);
          this.scalemaxval = parseInt(field.limit[0].max);
          if(this.generalValidations.length==0){
            if(this.values.length!=0){
              this.values.forEach((value,index)=>{
                let messageType
                if (value === "REQUIRED") {
                  messageType = "This field is required. Please enter a value to proceed."
                  this.generalValidations[index]=
                {
                  condition: value,
                  value: [],
                  messageType:"",
                  message: messageType || "",
                }
                }
                
              })
            }
          }
          this.conditionsArray = this.scaleOptions
        }
        if (field.type == "radio") {
          fieldtype = "radio";
          this.radioorientation = "vertical";
          if(this.generalValidations.length==0){
            if(this.values.length!=0){
              this.values.forEach((value,index)=>{
                let messageType
                if (value === "REQUIRED") {
                  messageType = "This field is required. Please enter a value to proceed."
                  this.generalValidations[index]=
                  {
                    condition: value,
                    value: [],
                    messageType:"",
                    message: messageType || "",
                  }
                }
               
              })
            }
          }
          this.conditionsArray = this.radioOptions
        }
        if (field.type == "radiohorizondal") {
          fieldtype = "radio";
          this.radioorientation = "horizondal";
        }
        if (field.type == "qrcode") {
          this.qrcodecontents = field.qrData;
        }
        if (field.type == "radio") {
          this.radioorientation = field.align;
        }
        if (field.type == "grid") {
          this.currentgrid = this.gridoptarray[field.fieldName];
          this.gridrows = this.gridoptarray[field.fieldName][0].Rows;
          this.gridcolumns = this.gridoptarray[field.fieldName][0].Columns;
          this.griddata[0].Rows = this.gridoptarray[field.fieldName][0].Rows;
          this.griddata[0].Columns = this.gridoptarray[
            field.fieldName
          ][0].Columns;
        }
        if (field.type == 'textarea') {
          await this.resetvalidation(field.validation)
          this.conditionsArray = this.textareaOptions
        }
        if (field.type == 'date') {
          await this.resetDateValidations(field.validation)
          await this.getdateformatlist()
          this.conditionsArray = this.dateOptions
        }
        // if (field.type == 'numberanddate') {
        //   this.resetNumberDateValidations(field.validation)
        //   this.getdateformatlist()
        // }
        // if (field.type == 'dateandtime') {
        //   this.resetDateTimeValidations(field.validation)
        //   this.getdateformatlist()

        // }
        if (field.type == 'time') {
          await this.resetTimeValidations(field.validation)
        }
        if (field.type == 'year') {
          await this.resetYearValidations(field.validation)
          this.conditionsArray = this.yearOptions
        }
        if (field.type == "checkbox") {
          if(this.generalValidations.length==0){
            if(this.values.length!=0){
              this.values.forEach((value,index)=>{
                let messageType
                if (value === "REQUIRED") {
                  messageType = "This field is required. Please enter a value to proceed."
                  this.generalValidations[index]=
                {
                  condition: value,
                  value: [],
                  messageType:"",
                  message: messageType || "",
                }
                }
                
              })
            }
          }
          this.conditionsArray = this.checkboxOptions
        }
        if (field.type == "dropdown") {
          if(this.generalValidations.length==0){
            if(this.values.length!=0){
              this.values.forEach((value,index)=>{
                let messageType
                if (value === "REQUIRED") {
                  messageType = "This field is required. Please enter a value to proceed."
                  this.generalValidations[index]=
                {
                  condition: value,
                  value: [],
                  messageType:"",
                  message: messageType || "",
                }
                }
                
              })
            }
          }
          this.conditionsArray = this.dropdownOptions
        }
        this.selectedgroup["page" + this.pageno] = field.groupOrderNo;
        this.tempfieldname = field.fieldName;
        localStorage.setItem(
          "tempFieldName",
          field.fieldName.trim().toLowerCase()
        );
        this.openModelcomponent(fieldtype);
        this.fieldname = field.fieldName;
        this.newfieldname = field.fieldName;
        this.labeltext = field.label;
        this.selectedmodel = this.optiongroups.results.find(
          (model) => model.label === this.labeltext
        );
        this.grouporderno = field.groupOrderNo;
        this.radiooptionsarray = field.options;
        this.selectedConditionValues=this.radiooptionsarray
        this.type = fieldtype;
        this.isedit = true;
        this.selectedfield = field.fieldno;
        this.imagid = field.imagePath;
        this.selectedpagegroup = this.temparray[0].pages[
          this.pageno - 1
        ].Group.indexOf(groupno);
    
    },
    // async Disablemenu() {
    //this.selectedfield = this.temparray[0].pages[this.pageno-1].Group.indexOf(groupno);
    //   await store.dispatch("setSideNavbar", false);
    //   console.log("disable netered");
    // },
    // async Enablemenu() {
    //   await store.dispatch("setSideNavbar", true);
    // },
    async deletepagefun() {
      let conformed = confirm(this.$t("navigations.are you sure you want to delete this page?"));
      if (conformed) {
        let indexForRemoval = this.pageno - 1;
        this.newtemplate[0].pages.splice(indexForRemoval, 1);
        this.temparray[0].pages.splice(indexForRemoval, 1);
        let temparraypgno = 1;
        this.temparray[0].pages.forEach((temparrayelement) => {
          temparrayelement.pageNo = temparraypgno;
          temparraypgno++;
        });
        let newtemplatepgno = 1;
        this.newtemplate[0].pages.forEach((newtempelement) => {
          newtempelement.pageNo = newtemplatepgno;
          newtemplatepgno++;
        });
        let i = this.pageno - 1;
        this.pagearray.splice(i, 1);
        if (this.pageno > 1) {
          this.pageno = this.pageno - 1;
        }
        this.totalpages--;
        this.updatefun("delete");
        // if (this.totalpages < 1) {
        //   console.log("cannot save template without pages");
        // } else {
        //   this.updatefun("delete");
        // }
      }
    },
    async createpageinbetween() {
      if (this.pagearray.includes(this.pagename)) {
        // alert("Page name already exist");
        alert(this.$t('navigations.page name already exist'))
      } else if (this.pagename == "") {
        // alert("Page name cannot be empty");
        alert(this.$t('navigations.page name cannot be empty'))
      } else {
        if (this.temparray[0].pages.length == 0) {
          this.createpage();
        }
        else {
          let length = this.temparray[0].pages.length;
          this.pagearray.push(this.pagename);
          this.pageobj[this.pagename] = length;
          let x = this.pageno;
          let calindexindex = this.newtemplate[0].pages.findIndex(
            (page) => page.pageNo === x
          );
          let index = calindexindex;
          if (this.pageposition == "before") {
            x = this.pageno;
            index = calindexindex;
          } else if (this.pageposition == "after") {
            x = this.pageno + 1;
            index = calindexindex + 1;
          } else if (this.pageposition == "end") {
            x = length;
            index = this.newtemplate[0].pages.length;
          }

          if (index !== -1) {
            this.newtemplate[0].pages.splice(index, 0, {
              pageName: this.pagename,
              pageNo: x,
              field: [],
            });
            this.temparray[0].pages.splice(index, 0, {
              pageName: this.pagename,
              pageNo: x,
              Group: [],
            });

            this.newtemplate[0].pages.forEach((page, index) => {
              page.pageNo = index + 1;
            });
            this.temparray[0].pages.forEach((page, index) => {
              page.pageNo = index + 1;
            });
            console.log("this.newtemplate[0].pages", this.newtemplate[0].pages.length);
            this.newtemplate[0].totalPages = this.newtemplate[0].pages.length;
            this.totalpages = this.newtemplate[0].totalPages;
            this.totalgroups["page" + (length + 1)] = 0;
            if (this.pageposition == "before") {
              this.pageno = x;
            } else if (this.pageposition == "after") {
              this.pageno = x;
            } else if (this.pageposition == "end") {
              this.pageno = x + 1;
            }
            this.pagename = "";
            this.pageposition == "end";
          } else {
            console.log("Element with the specified pageNo not found");
          }
        }
        this.closemodall();
      }
    },
    async createpage() {
      console.log(
        "pagearray is ",
        this.pagearray,
        this.newtemplate[0].totalPages
      );
      // let length = this.pagearray.length;
      let length = this.temparray[0].pages.length;
      this.pagearray.push(this.pagename);
      this.pageobj[this.pagename] = length;
      let newpage = {
        pageName: this.pagename,
        pageNo: length + 1,
        field: [],
      };
      this.newtemplate[0].pages[length] = newpage;
      this.temparray[0].pages[length] = {
        pageName: this.pagename,
        pageNo: length + 1,
        Group: [],
      };
      this.totalgroups["page" + (length + 1)] = 0;
      this.pageno = length + 1;
      this.newtemplate[0].totalPages = this.newtemplate[0].pages.length;
      this.totalpages = this.newtemplate[0].totalPages;
      this.closemodall();
      // }
      this.pagename = "";
    },
    async setvalidations() {
      this.newValues = [...this.values];
      if (this.newValues.includes('LENGTHRANGE') || this.newValues.includes('CHARACTERLIMITRANGE')) {
        let lengthindex = this.newValues.indexOf("CHARACTERLIMITRANGE");
        this.newValues[lengthindex] =
          this.newValues[lengthindex] + `(${this.minlength},${this.maxlength})`;
      }
      if (this.newValues.includes('VALUERANGE') || this.newValues.includes('NUMERICRANGE')) {
        let valueindex = this.newValues.indexOf("NUMERICRANGE");
        this.newValues[valueindex] =
          this.newValues[valueindex] + `(${this.minvalue},${this.maxvalue})`;
      }
      if (this.newValues.includes("NUMBERRANGE") || this.newValues.includes('NUMERICRANGE')) {
        let numberindex = this.newValues.indexOf("NUMERICRANGE");
        this.newValues[numberindex] =
          this.newValues[numberindex] + `(${this.minvalue},${this.maxvalue})`;
      }
      if (this.newValues.includes('DATERANGE')) {
        let dateindex = this.newValues.indexOf("DATERANGE");
        this.newValues[dateindex] =
          this.newValues[dateindex] + `(${this.minDate},${this.maxDate})`;
      }
      if (this.newValues.includes("YEARRANGE")) {
        let yearindex = this.newValues.indexOf("YEARRANGE");
        this.newValues[yearindex] =
          this.newValues[yearindex] + `(${this.minYear},${this.maxYear})`;
      }
      if (this.newValues.includes("TIMERANGE")) {
        let timeindex = this.newValues.indexOf("TIMERANGE");
        this.newValues[timeindex] =
          this.newValues[timeindex] + `(${this.minTime},${this.maxTime})`;
      }
      if (this.newValues.includes("DATEFORMAT")) {
        let dateFormatIndex = this.newValues.indexOf("DATEFORMAT");
        this.newValues[dateFormatIndex] =
          this.newValues[dateFormatIndex] + `(${this.dateFormat})`;
      }
      if (this.newValues.includes("TIMEFORMAT")) {
        let timeFormatIndex = this.newValues.indexOf("TIMEFORMAT");
        this.newValues[timeFormatIndex] =
          this.newValues[timeFormatIndex] + `(${this.timeFormat})`;
      }
    },
    // add field ....................................................
    async addfield(type) {
      this.loadingBlock=true
      this.rangeErrors = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      this.type = type
      this.generalValidationsError=[]
      this.exclusionMessagesError=[]
      this.dependencyMessagesError=[]
      this. calculationMessagesError=[]
      this.daterangeErrors = [false, false, false, false]
      this.Error = [false, false, false, false]
      this.timerangeErrors = [false, false, false, false, false]
      this.dateValidationrangeErrors[0] = [false, false, false]
      this.dependenciesError=[false,false,false,false]
      this.fieldnamesarray = [];
      this.newTempValues = []
      this.calculationFields=[]
      this.newtemplate[0].pages.forEach((page) => {
        page.field.forEach((field) => {
          if (
            field.type == "repeatingfield" ||
            field.type == "RepeatingMeasure" ||
            field.type == "repeatedmeasure"
          ) {
            this.fieldnamesarray.push(field.fieldName.toLowerCase());
            field.repeatingFields.forEach((rField) => {
              this.fieldnamesarray.push(rField.fieldName.toLowerCase());
            });
          } else {             
              this.fieldnamesarray.push(field.fieldName ? field.fieldName.toLowerCase() : "");
          }
        });
      });

      let repeatingfieldarray = [];
      let fieldtyp = type;
      this.rangeoption = [];
      let vald = true;
      this.currentoptions = this.radiooptionsarray;
      if (type == "textbox") {
        if (
          this.values.includes('VALUERANGE') ||
          this.values.includes('LENGTHRANGE') ||  this.values.includes('NUMERICRANGE') || this.values.includes('CHARACTERLIMITRANGE')
        ) {
          await this.rangeValidations();
        }
        if (!this.rangeErrors.includes(true) && !this.urlError.includes(true) && !this.phonerangeError.includes(true) && !this.emailError.includes(true)) {
          this.setvalidations();
        }
        if (
          !this.rangeErrors.includes(true) && 
          !this.urlError.includes(true) && !this.phonerangeError.includes(true) &&
          !this.emailError.includes(true) &&
          (this.values.includes('LENGTHRANGE') ||
            this.values.includes('VALUERANGE') || this.values.includes('NUMERICRANGE'))
        ) {
          this.setvalidations();
        } else if (
          !this.rangeErrors.includes(true) &&
          !this.urlError.includes(true) && !this.phonerangeError.includes(true) &&
          !this.emailError.includes(true) &&
          !this.values.includes('LENGTHRANGE')
        ) {
          vald = true;
        } else {
          this.loadingBlock=false
          vald = false;
        }
      }
      if (type == "header" || type == "subHeader") {
        this.fieldname = this.labeltext;
      } if (type == "textarea") {
        if (
          this.values.includes('LENGTHRANGE') || this.values.includes('NUMERICRANGE')
        ) {
          await this.rangeValidations();
        }
        if (!this.rangeErrors.includes(true)) {
          this.setvalidations();
        }
        if (
          !this.rangeErrors.includes(true) &&
          (this.values.includes('NUMERICRANGE'))
        ) {
          this.setvalidations();
        } else if (
          !this.rangeErrors.includes(true) &&
          !this.values.includes('NUMERICRANGE')) {
          vald = true;
        } else {
          this.loadingBlock=false
          vald = false;
        }
      } else if (type == 'date' || type == 'numberanddate') {
        if (
          this.values.includes('DATERANGE') || this.values.includes('DATEFORMAT')
        ) {
          await this.daterangeValidations();
        }
        if (this.values.includes('NUMBERRANGE') || this.values.includes('NUMERICRANGE') || this.values.includes('CHARACTERLMITRANGE')) {
          await this.rangeValidations();
        }

        if (!this.daterangeErrors.includes(true) || !this.rangeErrors.includes(true) ) {
          let minvalue=this.minDate
            if (minvalue instanceof Date) {
              // If value is a Date object, format it to dd-mm-yyyy
              const day = String(minvalue.getDate()).padStart(2, '0');
              const month = String(minvalue.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
              const year = minvalue.getFullYear();
              this.minDate = `${day}-${month}-${year}`;
            } else if (typeof minvalue === 'string' && !isNaN(Date.parse(minvalue))) {
              // If value is a string and represents a valid date, parse and format it
              const date = new Date(minvalue);
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
              const year = date.getFullYear();
              this.minDate = `${day}-${month}-${year}`;
            }
            let maxvalue=this.maxDate
            if (maxvalue instanceof Date) {
              // If value is a Date object, format it to dd-mm-yyyy
              const day = String(maxvalue.getDate()).padStart(2, '0');
              const month = String(maxvalue.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
              const year = maxvalue.getFullYear();
              this.maxDate = `${day}-${month}-${year}`;
            } else if (typeof maxvalue === 'string' && !isNaN(Date.parse(maxvalue))) {
              // If value is a string and represents a valid date, parse and format it
              const date = new Date(maxvalue);
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
              const year = date.getFullYear();
              this.maxDate = `${day}-${month}-${year}`;
            }
          this.setvalidations();
        }
        if (
          !this.daterangeErrors.includes(true) &&
          (this.values.includes("NUMERICRANGE"))
        ) {
          this.setvalidations()
        } else if (
          !this.daterangeErrors.includes(true) &&
          !this.values.includes("NUMERICRANGE")
        ) {
          vald = true;
        } else {
          this.loadingBlock=false
          vald = false;
        }

      }
      // else if (type == 'dateandtime') {
      //   if (
      //     this.values.includes('DATERANGE') || this.values.includes('DATEFORMAT')
      //   ) {
      //     await this.daterangeValidations();
      //   }
      //   if(this.values.includes('TIMERANGE') || this.values.includes('TIMEFORMAT'))
      //   {
      //     await this.timerangeValidations();        
      //   }

      //   if (!this.daterangeErrors.includes(true) ||!this.timerangeErrors.includes(true)) {
      //     let minvalue=this.minDate
      //       if (minvalue instanceof Date) {
      //         // If value is a Date object, format it to dd-mm-yyyy
      //         const day = String(minvalue.getDate()).padStart(2, '0');
      //         const month = String(minvalue.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      //         const year = minvalue.getFullYear();
      //         this.minDate = `${day}-${month}-${year}`;
      //       } else if (typeof minvalue === 'string' && !isNaN(Date.parse(minvalue))) {
      //         // If value is a string and represents a valid date, parse and format it
      //         const date = new Date(minvalue);
      //         const day = String(date.getDate()).padStart(2, '0');
      //         const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      //         const year = date.getFullYear();
      //         this.minDate = `${day}-${month}-${year}`;
      //       }
      //       let maxvalue=this.maxDate
      //       if (maxvalue instanceof Date) {
      //         // If value is a Date object, format it to dd-mm-yyyy
      //         const day = String(maxvalue.getDate()).padStart(2, '0');
      //         const month = String(maxvalue.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      //         const year = maxvalue.getFullYear();
      //         this.maxDate = `${day}-${month}-${year}`;
      //       } else if (typeof maxvalue === 'string' && !isNaN(Date.parse(maxvalue))) {
      //         // If value is a string and represents a valid date, parse and format it
      //         const date = new Date(maxvalue);
      //         const day = String(date.getDate()).padStart(2, '0');
      //         const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
      //         const year = date.getFullYear();
      //         this.maxDate = `${day}-${month}-${year}`;
      //       }
      //     this.setvalidations();
      //   }
      //   if (
      //     !this.daterangeErrors.includes(true) &&
      //     (this.values.includes("TIMERANGE"))
      //   ) {
      //     this.setvalidations()
      //   } else if (
      //     !this.daterangeErrors.includes(true) &&
      //     !this.values.includes("TIMERANGE")
      //   ) {
      //     vald = true;
      //   } else {
      //     this.loadingBlock=false
      //     vald = false;
      //   }
      // }
      else if (type == 'time') {
        if (
          this.values.includes("TIMERANGE") || this.values.includes('TIMEFORMAT')
        ) {
          await this.timerangeValidations();
        }

        if (!this.timerangeErrors.includes(true) ) {
          this.setvalidations();
        }
        if (
          !this.timerangeErrors.includes(true) &&
          (this.values.includes('TIMERANGE'))
        ) {
          this.setvalidations()
        } else if (
          !this.timerangeErrors.includes(true) &&
          !this.values.includes('TIMERANGE') 
        ) {
          vald = true;
        } else {
          this.loadingBlock=false
          vald = false;
        }

      }
      else if (type == 'year') {
        if (
          this.values.includes("YEARRANGE")
        ) {
          await this.rangeYearValidations();
        }

        if (!this.yearrangeError.includes(true)) {
          this.setvalidations();
        }
        if (
          !this.yearrangeError.includes(true) &&
          (this.values.includes("YEARRANGE"))
        ) {
          this.setvalidations()
        } else if (
          !this.yearrangeError.includes(true) &&
          !this.values.includes("YEARRANGE") 
        ) {
          vald = true;
        } else {
          this.loadingBlock=false
          vald = false;
        }

      } else if (type == "scales") {
        this.scaleValidation();
        if (this.scaleError.includes(true)) {
          this.loadingBlock=false
          vald = false;
        }
        this.rangeoption = [
          {
            min: this.scaleminval.toString(),
            max: this.scalemaxval.toString(),
          },
        ];
      }  else if (type == "calculation") {
        let calculationdata = JSON.stringify(this.calculate);
        this.currentoptions = [calculationdata];
      } else if (type == "repeatingfield") {
        this.fieldname = this.temprepeatingarray[0].fieldName;
        this.labeltext = this.temprepeatingarray[0].label;
        repeatingfieldarray = this.temprepeatingarray[0].repeatingFields;
        this.repeatedgroupgroupnos[this.fieldname] = [];
        if (repeatingfieldarray.length != 0) {
          repeatingfieldarray.forEach((repeatedgroupelement) => {
            if (
              !this.repeatedgroupgroupnos[this.fieldname].includes(
                repeatedgroupelement.groupOrderNo
              )
            ) {
              this.repeatedgroupgroupnos[this.fieldname].push(
                repeatedgroupelement.groupOrderNo
              );
            }
          });
        }
      } else if (type == "repeatedmeasure") {
        let repeatedmesuredata = JSON.stringify(this.selectedrepeatedtemp);
        this.currentoptions = [repeatedmesuredata];
      } else if (type == "dropdown" || type == "Dropdown") {
        if (this.radiooptionsarray.length == 0) {
          this.dropdownError = true;
          this.loadingBlock=false
          vald = false;
        }
      } else if (type == "grid") {
        await this.addgridoptions();
        let gridoptiondata = JSON.stringify(this.griddata);
        this.currentoptions = [gridoptiondata];
        // console.log("griddata = ",currentoptions);
      } else if (type == "qrcode") {
        if (this.qrcodecontents == "" || this.qrcodecontents == null) {
          this.emptyField = true;
          this.loadingBlock=false
          vald = false;
        }
        this.qrData = this.qrcodecontents;
      }
      if (vald) {
        if (
          this.fieldname.trim() == null ||
          (this.fieldname.trim() == "" && this.type != "repeatingfield")
        ) {
          // alert("Fieldname cannot be empty");
          alert(this.$t('navigations.fieldname cannot be empty'))
          this.loadingBlock=false
        } else if (
          this.fieldnamesarray.includes(this.fieldname.trim().toLowerCase())
        ) {
          // alert("This field name is already in uses");
          alert(this.$t('navigations.this field name is already in use'))
          this.loadingBlock=false
        } else if (
          type == "calculation" &&
          (this.calculate.firstfield == "" || this.calculate.secondfield == "")
        ) {
          // alert("");
          alert(this.$t('navigations.please enter first and second field in calculation field'))
          this.loadingBlock=false
        } else if (type == "radio" && this.radiooptionsarray.length == 0) {
          alert("Please enter atleast one option");
          this.loadingBlock=false
        } else {
          if(type=='repeatingfield'){
            let tempfielddata = {
              fieldName: this.fieldname.trim(),
              label: this.labeltext,
              fieldno: this.selectedfield,
              value: "",
              status: "",
              validatedBy: "",
              validatedAt: "",
              comment: "",
              limit: this.rangeoption,
              repeatingFields: repeatingfieldarray,
              qrData: this.qrData,
              groupOrderNo: this.selectedgroup["page" + this.pageno].toString(),
              type: fieldtyp,
              validation: this.newTempValues,
              imagePath: this.imagid,
              options: this.currentoptions,
              required: false,
              align:this.radioorientation,
              visibility:true
            };
            // let insertIndex = this.newtemplate[0].pages[this.pageno-1].field.findIndex(group => group.groupOrderNo == groupNo);
            // this.newtemplate[0].pages[this.pageno-1].field.push(tempfielddata);
            this.temparray[0].pages[this.pageno - 1].Group[
              this.selectedpagegroup
            ].field[this.selectedfield] = tempfielddata;
            this.columnselected = false;
              await this.addfieldinnewtemplate();
              await this.closemodaltwo();
          }
          else if ((type == "textbox" && this.newValues.length != 0) || (type == 'textarea' && this.newValues.length != 0)) {
            await this.editCheckValue('create')
            if(this.selectedOption=='yes'){
              this.newValues.push('DEPENDENCY')
            }
            if(this.calculationsArray && this.calculationsArray.length > 0 && this.calculationsArray[0].expression !== '' && !this.newValues.includes('CALCULATION')){
              this.newValues.push('CALCULATION')
            }
              if(!this.generalValidationsError.some((error) => error.includes(true)) &&
              !this.exclusionMessagesError.some((error) => error.includes(true))  && !this.dependencyMessagesError.some((error) => error.includes(true)) && !this.calculationMessagesError.some((error) => error.includes(true))){
                if(this.calculationFieldNames.length!=0){
                  if(this.inputValidationElements.includes(fieldtyp)  &&  !this.calculationFieldNames.some(existingField => existingField === this.fieldname)){
                    this.calculationFieldNames.push(this.fieldname.trim());
                  }
                }
                else{
                      this.calculationFieldNames.push(this.fieldname.trim());
                }
              let tempfielddata = {
                fieldName: this.fieldname.trim() ? this.fieldname.trim() :"",
                label: this.labeltext,
                fieldno: this.selectedfield,
                value: "",
                status: "",
                validatedBy: "",
                validatedAt: "",
                comment: "",
                limit: this.rangeoption,
                repeatingFields: repeatingfieldarray,
                qrData: this.qrData,
                groupOrderNo: this.selectedgroup["page" + this.pageno].toString(),
                type: fieldtyp,
                validation: this.newValues,
                imagePath: this.imagid,
                options: this.currentoptions,
                required: false,
                align:this.radioorientation,
                visibility:true
              };
              // this.newtemplate[0].pages[this.pageno-1].field.push(tempfielddata);
              this.temparray[0].pages[this.pageno - 1].Group[
                this.selectedpagegroup
              ].field[this.selectedfield] = tempfielddata;
              this.columnselected = false;
  
            
                await this.addfieldinnewtemplate();
                await this.closemodaltwo();
                     
              this.selectedOption = "no"
              this.selectedForm = ""
              this.selectedField = ""
              this.showText = false
              this.selectedFieldCondition = ""
              this.exclusionMessages = [
              ]
              this.dependencies=[
              ]
              this.generalValidations=[]
                this.calculate.firstfield = "";
                this.calculate.secondfield = "";
            }
          
         
          } 
          else{
            await this.editCheckValue('create')
          
            if ((type == 'date' || type == 'year' || type == 'time' ) && this.newValues.length != 0) {
                this.newTempValues = this.newValues
            }
            else {
              this.newTempValues = this.values
            }
            if(this.selectedOption=='yes'){
              this.newTempValues.push('DEPENDENCY')
            }
            if(this.calculationsArray && this.calculationsArray.length > 0 && this.calculationsArray[0].expression !== '' && !this.newTempValues.includes('CALCULATION')){
              this.newTempValues.push('CALCULATION')
            }
            if(!this.generalValidationsError.some((error) => error.includes(true)) &&
                  !this.exclusionMessagesError.some((error) => error.includes(true))  && !this.dependencyMessagesError.some((error) => error.includes(true)) && !this.calculationMessagesError.some((error) => error.includes(true))){
                    if(this.calculationFieldNames.length!=0){
                      if(this.inputValidationElements.includes(fieldtyp)  &&  !this.calculationFieldNames.some(existingField => existingField === this.fieldname)){
                        this.calculationFieldNames.push(this.fieldname.trim());
                      }
                    }
                    else{
                          this.calculationFieldNames.push(this.fieldname.trim());
                    }
                  let tempfielddata = {
                    fieldName: this.fieldname.trim(),
                    label: this.labeltext,
                    fieldno: this.selectedfield,
                    value: "",
                    status: "",
                    validatedBy: "",
                    validatedAt: "",
                    comment: "",
                    limit: this.rangeoption,
                    repeatingFields: repeatingfieldarray,
                    qrData: this.qrData,
                    groupOrderNo: this.selectedgroup["page" + this.pageno].toString(),
                    type: fieldtyp,
                    validation: this.newTempValues,
                    imagePath: this.imagid,
                    options: this.currentoptions,
                    required: false,
                    align:this.radioorientation,
                    visibility:true
                  };
                  // let insertIndex = this.newtemplate[0].pages[this.pageno-1].field.findIndex(group => group.groupOrderNo == groupNo);
                  // this.newtemplate[0].pages[this.pageno-1].field.push(tempfielddata);
                  this.temparray[0].pages[this.pageno - 1].Group[
                    this.selectedpagegroup
                  ].field[this.selectedfield] = tempfielddata;
                  this.columnselected = false;
                    await this.addfieldinnewtemplate();
                    await this.closemodaltwo();
                  
                  this.selectedOption = "no"
                  this.selectedForm = ""
                  this.selectedField = ""
                  this.showText = false
                  this.selectedFieldCondition = ""
                  this.exclusionMessages = [
                  ]
                  this.dependencies=[
                  ]
                  this.generalValidations=[]
                  this.calculate.firstfield = "";
                  this.calculate.secondfield = "";
                }
            }
            
           
            
          }
        
      }
    },
    async addfieldinnewtemplate() {
      let newtemplatepages = await this.temparray.map((item) => {
        return {
          pages: item.pages.map((page) => ({
            pageName: page.pageName,
            pageNo: page.pageNo,
            field: page.Group.flatMap((group) =>
              group.field
                .filter((fieldItem) => fieldItem.type && fieldItem.type !== "blankdata")
                .map((fieldItem) => {
                  const updatedValidation = [...(fieldItem.validation || [])];
                  
                  if(this.calculationsArray.length!=0){
                      this.calculationsArray.forEach(calc => {
                        if (calc.fields.includes(fieldItem.fieldName) && !updatedValidation.includes("CALCULATION")) {
                            updatedValidation.push("CALCULATION");
                            if(!this.tempcalarray.includes(fieldItem.fieldName))
                            {
                              this.tempcalarray.push(fieldItem.fieldName)
                            }
                        }
                    });
                  }        
                  if(this.tempcalarray.includes(fieldItem.fieldName) && !updatedValidation.includes("CALCULATION"))
                    {
                      updatedValidation.push("CALCULATION");
                    }

                  return {
                      fieldName: fieldItem.fieldName,
                      label: fieldItem.label,
                      status: fieldItem.status,
                      groupOrderNo: fieldItem.groupOrderNo? fieldItem.groupOrderNo.toString() : "",
                      type: fieldItem.type,
                      validation:  updatedValidation,
                      imagePath: fieldItem.imagePath,
                      required: fieldItem.required,
                      qrData: fieldItem.qrData,
                      templateFieldProperties: null,
                      options: fieldItem.options,
                      limit: fieldItem.limit,
                      repeatingFields: fieldItem.repeatingFields,
                      align: fieldItem.align,
                      visibility: this.visibilityobj[fieldItem.fieldName] !== undefined
                      ? this.visibilityobj[fieldItem.fieldName]
                      : true
                  }
              })            
            ),
          })),
        };
      });
      this.newtemplate[0].pages = newtemplatepages[0].pages;
      this.savetestfun("noalert");
    },
    async loadvisibilities() {
      let pages = this.visibilitytemplate &&
                  this.visibilitytemplate[0] &&
                  this.visibilitytemplate[0].pages;
      pages.forEach((eachpages)=>{
      let fields = eachpages.field;
      if(fields){
        fields.forEach((eachfields)=>{
          this.visibilityobj[eachfields.fieldName] = eachfields.visibility;
        })
      
     }
    })
    return;
    },
    async gettemplatebytempid(templateid) {
      const idtoken = store.getters.getIdToken;

        await axios
          .get(
            `${this.baseurl}/forms/templatedesign/getformtemplatedetails?id=${templateid}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.visibilitytemplate[0] = res.data;
          })
          .catch((err) => {
            console.log("add err response:", err);
          });
    },
    async deletegroupfun() {
      let pagegroup = this.selectedgroup["page" + this.pageno] - 1;
      if (isNaN(pagegroup)) {
        // alert("Please select a column");
        alert(this.$t('navigations.please select a column'))
      } else {
        if (this.columnselected != true) {
          // alert("Please select a column");
          alert(this.$t('navigations.please select a column'))
        } else {
          if (this.totalpages > 0) {
            let conformedcolumn = confirm(this.$t("navigations.are you sure you want to delete this column")

            );
            if (conformedcolumn) {
              let field = this.temparray[0].pages[this.pageno - 1].Group[
                this.selectedpagegroup
              ].field[this.selectedfield];
              if (field.type == "blankdata") {
                this.temparray[0].pages[this.pageno - 1].Group[
                  this.selectedpagegroup
                ].field[this.selectedfield] = "";
              }
            }
          }
        }
      }
    },
    scaleValidation() {
      const RegexCheck = /[^0-9-]/g;
      if (this.scaleminval == this.scalemaxval) {
        this.scaleError[0] = true;
      }
      if (parseInt(this.scaleminval) > parseInt(this.scalemaxval)) {
        this.scaleError[1] = true;
      }
      if (RegexCheck.test(this.scaleminval)) {
        this.scaleError[2] = true;
      }
      if (RegexCheck.test(this.scalemaxval)) {
        this.scaleError[3] = true;
      }
    },
    clearInput() {
      this.scaleError = [false, false, false, false];
      this.emptyField = false;
      this.dropdownError = false;
    },
    async createrepeatedfield() {
      this.repfieldstoedit = [];
      this.popuptype = "save";
      this.type = "repeatingfield";
      this.repeatedfieldpopup = true;
    },
    async createrepeatedfieldpopup() {
      this.createrepeatedmodel = true;
    },
    async closerepeatedfield() {
      this.repeatedfieldpopup = false;
    },
    async editrepeatedmeasure(repfieldstoedit, groupno) {
        this.repfieldstoedit = repfieldstoedit;
        this.fieldname = repfieldstoedit.fieldName;
        this.tempfieldname = repfieldstoedit.fieldName;
        this.selectedpagegroup = this.temparray[0].pages[
          this.pageno - 1
        ].Group.indexOf(groupno);
        this.labeltext = repfieldstoedit.label;
        this.grouporderno = repfieldstoedit.groupOrderNo;
        this.popuptype = "edit";
        this.type = "repeatingfield";
        this.repeatedfieldpopup = true;
    },
    async removeRepeatingField(repfieldname) {
      this.temparray[0].pages.forEach((page) => {
        if (page.pageNo == this.pageno) {
          page.Group.forEach((group) => {
            if (group.Groupno == this.grouporderno) {
              group.field = group.field.filter(
                (field) => field.type !== "repeatingfield"
              );
            }
          });
        }
      });
      this.newtemplate[0].pages.forEach((page) => {
        if (page.pageNo == this.pageno) {
          page.field.forEach((field, fieldindex) => {
            if (field.fieldName == repfieldname) {
              this.newtemplate[0].pages[this.pageno - 1].field.splice(
                fieldindex,
                1
              );
              this.fieldnamesarray = this.fieldnamesarray.filter(
                (element) => element !== repfieldname
              );
            }
          });
        }
      });
      await this.deleteapicall();
      await this.closemodaltwo();
      await this.removefieldname(this.fieldname);
    },
    async closerepeatingmodel() {
      this.addrepeatedoptfield = false;
      this.createrepeatedmodel = false;
    },
    async addrepeatedoptionfield() {
      this.addrepeatedoptfield = !this.addrepeatedoptfield;
    },
    async cancelrepeatedoptionfield() {
      this.addrepeatedoptfield = false;
    },

    async addrepeatedopts() {
      if (!this.repeatedoptions.includes(this.repeatoptionss)) {
        this.repeatedoptions.push(this.repeatoptionss);
      }
      this.repeatedoptions = "";
    },
    async saverepeatedoptions() {
      let repeatinggroupnameexist = false;
      if (this.repeatedtemplatename == "") {
        // alert("Please enter repeating group name");
        alert(this.$t('navigations.please enter repeating group name'))
      } else {
        this.presavedrepeatedmodel.forEach((eachrepeatedgroupname) => {
          if (eachrepeatedgroupname.templateName == this.repeatedtemplatename) {
            repeatinggroupnameexist = true;
          }
        });
        if (repeatinggroupnameexist == true) {
          // alert("Repeating group name already exist. ");
          alert(this.$t('navigations.repeating group name already exist.'))
        } else {
          let savedata = {
            templateName: this.repeatedtemplatename,
            field: this.repeatedtemplatefieldarray,
          };
          await axios
            .post(
              `${this.baseurl}/forms/templatedesign/createtempfield
`,
              savedata,
              {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              }
            )

            .then((response) => {
              console.log("retreieved data", response);
              this.getrepeatedfieldlist();
              this.closeoptcloserepeatingmodelmodel();
              // alert(" ");
              alert(this.$t('navigations.repeated field created'))
            })
            .catch((err) => {
              console.log(err.data);
              this.closerepeatingmodel();
            });
        }
      }
    },
    async removedrepeatedoptions(index) {
      this.repeatedtemplatefieldarray.splice(index, 1);
    },
    async addrepeatedoptions() {
      let repeatoptarray = this.repeatedoptions;
      let repeatedtemplatefield = {
        fieldName: this.repeatingfieldname,
        type: this.repeatingtype,
        options: repeatoptarray,
      };
      if (!this.repeatedtemplatefieldarray.includes(repeatedtemplatefield)) {
        this.repeatedtemplatefieldarray.push(repeatedtemplatefield);
      }
      repeatedtemplatefield = {
        fieldName: "",
        type: "text",
        options: [],
      };
      this.repeatingfieldname = "";
      this.repeatingtype = "text";
      this.repeatedoptions = [];
      console.log(this.repeatedtemplatefieldarray);
      this.addrepeatedoptfield = false;
    },
    async addfieldapi(type) {
      let repeatingfieldarray = [];
      if (type == "repeatingfield") {
        repeatingfieldarray = this.temprepeatingarray[0].repeatingFields;
      }

      let data = {
        TemplateId: this.newtemplate[0].templateId,
        pageNo: this.pageno,
        field: {
          fieldName: this.fieldname.trim(),
          label: this.labeltext,
          status: "",
          repeatingFields: repeatingfieldarray,
          groupOrderNo: this.selectedgroup["page" + this.pageno].toString(),
          type: type,
          limit: this.rangeoption,
          validation: this.values,
          imagePath: this.imagid,
          options: this.currentoptions,
          qrData: this.qrData,
        },
      };
      await axios
        .put(`${this.baseurl}/forms/templatedesign/addnewfield`, data, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log("add err response:", err);
          if (err.response.data.detail == "PageNo Not Exist !") {
            this.updatefun("addpage");
          }
        });
    },
    async createtemplate() {
      this.newtemplate[0] = {
        templateId: "",
        templateName: this.templatename,
        languageCode: "en",
        totalPages: 0,
        version: "1.0",
        isDeleted: false,
        language: "English",
        folder: "",
        createdUserEmail: store.getters.getEmailIs,
        createdAt: new Date(),
        createdUserId: null,
        lastModifiedUserEmail: null,
        lastModifiedAt: null,
        lastModifiedUserId: null,
        formname: "string",
        formId: this.formid,
        pages: [],
      };
      this.temparray[0] = {
        templateId: "",
        templateName: "",
        totalPages: 1,
        pages: [],
      };
    },
    async designcompletepopupfun() {
      this.designcompletepopup = true;
    },
    async closedesigncompletemodal() {
      this.replacetemplatevar = false;
      this.exisitingvisitsvar = false;
      this.reviewandapprovalvar = false;
      this.designcompletepopup = false;
    },
    async designcomplete() {
      let currentstudy = store.getters.getStudyIs;
      let sourcepage = store.getters.getsourceTemplateId;
      this.emptyField = [];
      this.newtemplate[0].pages.forEach((item) => {
        if (item.field.length == 0) {
          this.emptyField.push(false);
        } else {
          this.emptyField.push(true);
        }
      });
      if (!this.emptyField.includes(true) || this.emptyField.length == 0) {
        // alert("Forms with empty pages cannot be saved");
        alert(this.$t('navigations.forms with empty pages cannot be saved'))
      } else {
        let conformed = confirm(

          this.$t("navigations.are you sure you want to change the status to design complete?")
        );
        if (conformed) {
          this.newtemplate[0].status = "Design Complete";
          this.newtemplate[0].templateId = this.templateid;
          this.newtemplate[0].version = this.currentversion;
          if (this.exisitingvisitsvar) {
            await axios
              .get(
                `${this.baseurl}/forms/template/updatevisits?oldTemplateId=${this.currentTempID}&templateId=${this.newtemplate[0].templateId}&studyId=${currentstudy}`,
                {
                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                this.getForms();
              })
              .catch((err) => {
                console.error(err);
              });
          }
          if (this.replacetemplatevar) {
            await axios
              .get(
                `${this.baseurl}/forms/template/updateschedules?oldTemplateId=${this.currentTempID}&templateId=${this.newtemplate[0].templateId}&studyId=${currentstudy}`,
                {
                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                console.log("updateschedules", res.data);
              })
              .catch((err) => {
                console.error(err);
              });
          }

          await axios
            .put(
              `${this.baseurl}/forms/templatedesign/designcomplete?templateid=${this.templateid
              }&isLocked=${!this.reviewandapprovalvar}`,
              {},
              {
                headers: {
                  Authorization: "Bearer " + this.idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              this.isformlocked = !this.reviewandapprovalvar;
              this.status = "Design Complete";
              this.closedesigncompletemodal();
              // alert("Form design completed successfully.");
              alert(this.$t('navigations.form design completed successfully'))
              //this.template();
            })
            .catch((err) => {
              console.log("add err response:", err);
              this.closedesigncompletemodal();
            });
        }
      }
    },
    async addradiooptions() {
      if (
        this.radiovalues.trim() != "" &&
        this.radiovalues.trim() != null &&
        this.radiooptions.trim() != "" &&
        this.radiooptions.trim() != null
      ) {
        this.dropdownError = false;
        if (
          this.radiooptions.trim() != "" &&
          this.radiooptions.trim() !== null
        ) {
          const isNameDuplicate = this.radiooptionsarray.some(
            (option) => option.optionname === this.radiooptions.trim()
          );
          if (isNameDuplicate) {
            alert("Option group with the same option name already exists!");
            return;
          }
          const isCodeDuplicate = this.radiooptionsarray.some(
            (option) => option.codevalue === this.radiovalues.trim()
          );
          if (isCodeDuplicate) {
            alert("Option group with the same Code value already exists!");
            return;
          }
          this.currentoptions = {
            optionname: this.radiooptions.trim(),
            codevalue: this.radiovalues.trim(),
          };
          console.log("Optiosn in radio are", this.currentoptions);
          if (!this.radiooptionsarray.includes(this.radiooptions.trim())) {
            this.radiooptionsarray.push(this.currentoptions);
          }
        }
        this.radiooptions = "";
        this.radiovalues = "";
      }
    },
    async expandfun(id) {
      let expitem = document.getElementById(id);
      expitem.classList.toggle("collapse");
    },
    async selectitem(menuval) {
      this.isedit = false;
      this.fieldname = "";
      this.labeltext = "";
      this.grouporderno = "";
      this.values = [];
      this.newValues = [];
      this.radiooptionsarray = [];
      if (this.selectedmenu == menuval || this.selectedmenu == "") {
        let expitem = document.getElementById("addvalues");
        expitem.classList.toggle("collapse");
        // alert("selected an item");
        this.selectedmenu = menuval;
      } else {
        this.selectedmenu = menuval;
      }
    },
    async addrepeatedonecolumn(lastgroup) {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
        this.selectspot(inputelem.field[0], lastgroup);
      }
    },
    async adddefaultcolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        let groupinfo = {
          Groupno: groupno,
          components: 1,
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
        this.selectspot(inputelem.field[0], inputelem);
      }
    },
    async addnewgroupwithdetails(datafromchild) {
      console.log(datafromchild);
      let groupArray = this.temparray[0].pages[this.pageno - 1].Group;
      let groupNo = this.addnewgroupno;
      let inputelem = {
        Groupno: groupNo,
        components: 1,
        field: [
          {
            fieldName: groupNo + "field1",
            fieldno: 0,
            groupOrderNo: groupNo,
            type: "blankdata",
          },
        ],
      };
      if (Number(datafromchild.moofcolumns) == 2) {
        inputelem = {
          Groupno: groupNo,
          components: 1,
          field: [
            {
              fieldName: groupNo + "field1",
              fieldno: 0,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field2",
              fieldno: 1,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
          ],
        };
      } else if (Number(datafromchild.moofcolumns) == 3) {
        inputelem = {
          Groupno: groupNo,
          components: 1,
          field: [
            {
              fieldName: groupNo + "field1",
              fieldno: 0,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field2",
              fieldno: 1,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field3",
              fieldno: 2,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
          ],
        };
      } else if (Number(datafromchild.moofcolumns) == 4) {
        inputelem = {
          Groupno: groupNo,
          components: 1,
          field: [
            {
              fieldName: groupNo + "field1",
              fieldno: 0,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field2",
              fieldno: 1,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field3",
              fieldno: 2,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field4",
              fieldno: 3,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
          ],
        };
      } else if (Number(datafromchild.moofcolumns) == 5) {
        inputelem = {
          Groupno: groupNo,
          components: 1,
          field: [
            {
              fieldName: groupNo + "field1",
              fieldno: 0,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field2",
              fieldno: 1,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field3",
              fieldno: 2,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field5",
              fieldno: 4,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
          ],
        };
      } else if (Number(datafromchild.moofcolumns) == 6) {
        inputelem = {
          Groupno: groupNo,
          components: 1,
          field: [
            {
              fieldName: groupNo + "field1",
              fieldno: 0,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field2",
              fieldno: 1,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field3",
              fieldno: 2,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field5",
              fieldno: 4,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
            {
              fieldName: groupNo + "field6",
              fieldno: 5,
              groupOrderNo: groupNo,
              type: "blankdata",
            },
          ],
        };
      }
      // Find the index to insert before
      let insertIndex = groupArray.findIndex(
        (group) => group.Groupno == groupNo
      );

      if (insertIndex !== -1) {
        // Insert inputElem before the found index
        groupArray.splice(insertIndex, 0, inputelem);

        // Increase Groupno for all groups after the inserted one
        for (let i = insertIndex + 1; i < groupArray.length; i++) {
          groupArray[i].Groupno = parseInt(groupArray[i].Groupno) + 1;

          // Update field elements if they exist and use the Groupno
          groupArray[i].field.forEach((field) => {
            field.groupOrderNo = groupArray[i].Groupno;
            if (field.type == "blankdata") {
              field.fieldName = field.fieldName + groupArray[i].Groupno;
            }
          });
        }
      } else {
        // If no matching Groupno, add to the end
        groupArray.push(inputelem);
      }

      this.addnewgrouppoopup = false;
    },
    async addonecolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
        // this.newtemplate[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async addtwocolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field2",
              fieldno: 1,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async addthreecolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field2",
              fieldno: 1,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field3",
              fieldno: 2,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async addfourcolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field2",
              fieldno: 1,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field3",
              fieldno: 2,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field4",
              fieldno: 3,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async addsixcolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field2",
              fieldno: 1,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field3",
              fieldno: 2,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "fiel4",
              fieldno: 3,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field5",
              fieldno: 4,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field6",
              fieldno: 5,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async selectspot(selectedfield, groupno) {
      this.values = [];
      this.newValues = [];
      this.radiooptionsarray = [];
      this.radiooptions = "";
      this.columnselected = true;
      this.selectedgroup["page" + this.pageno] = selectedfield.groupOrderNo;
      this.selectedpagegroup = this.temparray[0].pages[
        this.pageno - 1
      ].Group.indexOf(groupno);
      this.selectedfield = selectedfield.fieldno;
      this.blankfielddetails = selectedfield;
      this.highlitedcolumn = selectedfield.fieldName;

    },
    async handleMouseLeave() {
      this.highlitedcolumn = "";
    },
    async onclosed() {
      if (this.DesignFormPrvRoute == "studyProfile") {
        await this.$router.push(`/studyProfile`);
      } else {
        await this.$router.push(`/formTemplates`);
      }
    },
    async rangeValidations() {
      this.rangeErrors = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      if (this.values.includes('LENGTHRANGE') || this.values.includes('CHARACTERLIMITRANGE')) {
        if (
          this.minlength === null ||
          this.minlength === undefined ||
          this.minlength === ""
        ) {
          this.rangeErrors[0] = true;
        }

        if (
          this.maxlength === "" ||
          this.maxlength === null ||
          this.maxlength === undefined
        ) {
          this.rangeErrors[1] = true;
        }
        if (parseInt(this.maxlength) < parseInt(this.minlength)) {
          this.rangeErrors[4] = true;
        }
        if (parseInt(this.maxlength) == 0) {
          this.rangeErrors[5] = true;
        }
        if (
          this.maxlength === "" ||
          this.maxlength === null ||
          this.maxlength === undefined
        ) {
          this.rangeErrors[1] = true;
        }
      }
      if (this.values.includes('VALUERANGE') || this.values.includes("NUMBERRANGE") || this.values.includes('NUMERICRANGE')) {
        if (
          this.minvalue === "" ||
          this.minvalue === null ||
          this.minvalue == undefined
        ) {
          this.rangeErrors[2] = true;
        }
        if (
          this.maxvalue === "" ||
          this.maxvalue === null ||
          this.maxvalue == undefined
        ) {
          this.rangeErrors[3] = true;
        }
        if (parseInt(this.maxvalue) < parseInt(this.minvalue)) {
          this.rangeErrors[6] = true;
        }
        if (parseInt(this.maxvalue) == 0) {
          this.rangeErrors[7] = true;
        }
      }
    },
    async daterangeValidations() {
      this.daterangeErrors = [
        false,
        false,
        false,
        false,
      ];

        if (this.values.includes('DATERANGE')){
        if (
          this.minDate === null ||
          this.minDate === undefined ||
          this.minDate === ""
        ) {
          this.daterangeErrors[0] = true;
        }

        if (
          this.maxDate === "" ||
          this.maxDate === null ||
          this.maxDate === undefined
        ) {
          this.daterangeErrors[1] = true;
        }
        if (new Date(this.maxDate) < new Date(this.minDate)) {
          this.daterangeErrors[2] = true;
        }
        if (
          this.maxDate === "" ||
          this.maxDate === null ||
          this.maxDate === undefined
        ) {
          this.daterangeErrors[1] = true;
        }
        }
        if(this.values.includes('DATEFORMAT')){
          if (
            this.dateFormat === "" ||
            this.dateFormat === null ||
            this.dateFormat === undefined
          ) {
            this.daterangeErrors[3] = true;
          }
        }

    },
    async rangeYearValidations() {
      this.yearrangeError = [
        false,
        false,
        false,
        false,
      ];
      if (this.values.includes("YEARRANGE")) {
        if (
          this.minYear === null ||
          this.minYear === undefined ||
          this.minYear === ""
        ) {
          this.yearrangeError[0] = true;
        }

        if (
          this.maxYear === "" ||
          this.maxYear === null ||
          this.maxYear === undefined
        ) {
          this.yearrangeError[1] = true;
        }
        if (parseInt(this.maxYear) < parseInt(this.minYear)) {
          this.yearrangeError[2] = true;
        }
        if (
          this.maxYear === "0" ||
          this.maxYear === null ||
          this.maxYear === undefined || this.maxYear === 0
        ) {
          this.yearrangeError[3] = true;
        }
      }
    },
    async timerangeValidations(){
      if(this.values.includes('TIMERANGE'))
        {
      if (
        this.minTime === null ||
        this.minTime === undefined ||
        this.minTime === ""
      ) {
        this.timerangeErrors[0] = true;
      }

      if (
        this.maxTime === "" ||
        this.maxTime === null ||
        this.maxTime === undefined
      ) {
        this.timerangeErrors[1] = true;
      }
      if (this.maxTime < this.minTime) {
        this.timerangeErrors[2] = true;
      }
    }
      if(this.values.includes('TIMEFORMAT'))
      {
        if (
          this.timeFormat==="" ||
          this.timeFormat === null ||
          this.timeFormat === undefined 
        ) {
          this.timerangeErrors[3] = true;
        }
      }
    },
    valuesChanged() {     
      this.rangeErrors = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      this.urlError=[false,false]
    },
    changeArray(value){
      let messageType
      if (value === "ALPHANUMERIC" ) {
        messageType = "Only alphanumeric characters are allowed.";
      } else if (value === "EMAIL") {
        messageType = "Invalid email format.";
      } else if (value === "NUMBER") {
        messageType = "Please enter a numeric value.";
      } 
      else if (value === "REQUIRED") {
        messageType = "This field is required. Please enter a value to proceed."
      }
      else if (value === "URL") {
        messageType = "Invalid URL format."
      }
      else if (value === "PHONENUMBER") {
        messageType ="Please enter a valid phone number"
      }
      else if (value === "REQUIRED") {
        messageType = "This field is required. Please enter a value to proceed."
      }
      this.generalValidations.push(
        {
          condition: value,
          value: [],
          messageType: "",
          message:  messageType || ""
        }
      )
      if(this.values.includes('NUMBER')){
        this.conditionsArray=this.scaleOptions
      }
      else{
        if(this.selectedmenu=='text' || this.selectedmenu=='textbox'){
          this.conditionsArray=this.textboxOptions
        }
      }
    },
    deselectedOption(value) {
      this.values = this.values.filter((item) => item !== value);
      this.generalValidations = this.generalValidations.filter((item) => item.condition !== value);
      if(this.type=='textbox'){
        if(!this.values.includes('NUMBER'))
        {
          this.conditionsArray=this.textboxOptions
        }
      }
      if (this.newValues.includes('CHARACTERLIMITRANGE')) {
        let lengthindex = this.newValues.indexOf('CHARACTERLIMITRANGE');
        this.newValues = this.newValues.filter(
          (item) => item !== this.newValues[lengthindex]
        );
      }
      if (this.newValues.includes('VALUERANGE')) {
        let valueindex = this.newValues.indexOf('VALUERANGE');
        this.newValues = this.newValues.filter(
          (item) => item !== this.newValues[valueindex]
        );
      }
      if (this.newValues.includes("NUMERICRANGE")) {
        let valueindex = this.newValues.indexOf("NUMBERRANGE");
        this.newValues = this.newValues.filter(
          (item) => item !== this.newValues[valueindex]
        );
      }
      if (this.newValues.includes("YEARRANGE")) {
        let valueindex = this.newValues.indexOf("YEARRANGE");
        this.newValues = this.newValues.filter(
          (item) => item !== this.newValues[valueindex]
        );
      }
      if (this.newValues.includes("TIMERANGE")) {
        let valueindex = this.newValues.indexOf("TIMERANGE");
        this.newValues = this.newValues.filter(
          (item) => item !== this.newValues[valueindex]
        );
      }
      if (this.newValues.includes("DATERANGE")) {
        let valueindex = this.newValues.indexOf("DATERANGE");
        this.newValues = this.newValues.filter(
          (item) => item !== this.newValues[valueindex]
        );
      }
      if (this.newValues.includes("TIMEFORMAT")) {
        let valueindex = this.newValues.indexOf("TIMEFORMAT");
        this.newValues = this.newValues.filter(
          (item) => item !== this.newValues[valueindex]
        );
      }
      if (this.newValues.includes("DATEFORMAT")) {
        let valueindex = this.newValues.indexOf("DATEFORMAT");
        this.newValues = this.newValues.filter(
          (item) => item !== this.newValues[valueindex]
        );
      }
      if (value == 'LENGTHRANGE' || value == ' CHARACTERLIMITRANGE') {
        this.minlength = "";
        this.maxlength = "";
      } else if (value == 'VALUERANGE' || value == "NUMBERRANGE" || value=='NUMERICRANGE') {
        this.minvalue = "";
        this.maxvalue = "";
        this.newValues = [];
      }
      else if (value == 'YEARRANGE' || value=='YEARRANGE') {
        this.minYear = "";
        this.maxYear = "";
        this.newValues = [];
      }
      else if (value == "TIMERANGE") {
        this.minTime = "";
        this.maxTime = "";
        this.newValues = [];
      }
      else if (value == "DATERANGE") {
        this.minDate = "";
        this.maxDate = "";
        this.newValues = [];
      }
      else if(value=='DATEFORMAT')
      {
        this.dateFormat=""
        this.newValues = [];
      }
      else if(value=='TIMEFORMAT')
        {
          this.timeFormat=""
          this.newValues = [];
        }
    },
    clearValues() {
      this.newValues = [];
      this.values = [];
      this.generalValidations=[]
      this.newTempValues = []
      this.dateFormat=""
      this.timeFormat=""
    },
  },
};
