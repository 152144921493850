/* eslint-disable */
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
import jwt_decode from "jwt-decode";
import store from "../../store/index";
import auth0 from "auth0-js";
import VueApexCharts from 'vue3-apexcharts';
import { ref, computed, onBeforeMount } from "vue";
import customModal from "../customModal/customModal.vue";
import NestedJsonTable from "../../custom_lib/nestedjsontotable.vue"
import moment from "moment";
export default {
    name: "landingpage",
    components: {
      NestedJsonTable,
      customModal,
      apexchart: VueApexCharts
    },
    // props: {
    //     selectedDocument: {
    //       type: Object,
    //       default: null
    //     }
    //   },
    setup() {
        let valnow = ref("");
        const router = useRouter();
        const profileBlock = ref(false);
        let baseurl = ref(`${process.env.VUE_APP_Service_URL}`);
        let currentTheme = ref(localStorage.getItem("theme-color"));
        let webAuth = new auth0.WebAuth({
            domain: "dev-datamatica.eu.auth0.com",
            clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
        });

        onBeforeMount(async () => {
            store.dispatch("setNavbar", false);
            document.getElementById("page-wrapper").style.width = "100%";
            if (currentTheme.value == null) {
                localStorage.setItem("theme-color", "theme-default");
                currentTheme.value = localStorage.getItem("theme-color");
            }
        });

        router.beforeEach((to, from, next) => {
            console.log("name is",to.name)
            if (to.name != "profileSettings" && to.name != "fhirViewer") {
                store.dispatch("setNavbar", true);
                document.getElementById("page-wrapper").style.removeProperty("width");
            } else {
                document.getElementById("page-wrapper").style.width = "100%";
            }
            next();
        });

        let usersdetails = computed(() => {
            valnow.value = store.getters.getStudyIs;
            return jwt_decode(store.getters.getIdToken);
        });

        function displayProfile() {
            const profile = document.querySelector(".profile-block");
            const userProfile = document.querySelector(".tool-tip");
            profile.style.display = profile.style.display === "block" ? "none" : "block";
            userProfile.style.display = userProfile.style.display === "block" ? "none" : "block";
        }

        async function logout() {
            await logoutaudit();
            localStorage.clear();
            webAuth.logout({
                returnTo: `${process.env.VUE_APP_logout_URL}`,
                clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
            });
        }

        async function logoutaudit() {
            const token = store.getters.getIdToken;
            const emailId = await jwt_decode(token).email;
            await axios.post(
                `${baseurl.value}/account-core/user/saveloginaudit`,
                {
                    userEmail: emailId,
                    logdetails: [{ action: "Log Out", date: new Date().toISOString(), time: new Date().toISOString() }],
                },
                { headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" } }
            );
        }

        function setstatus(screen) {
            store.dispatch("setCollapse", false);
            store.dispatch("setDefaultMenu", screen);
        }

        function expandSidebar() {
            let x = document.getElementsByClassName("theme-menu")[0];
            x.style.display = x.style.display === "block" ? "none" : "block";
        }

        function switchTheme(theme) {
            localStorage.setItem("theme-color", theme);
            currentTheme.value = localStorage.getItem("theme-color");
        }

        return {
            usersdetails,
            displayProfile,
            valnow,
            profileBlock,
            logout,
            setstatus,
            switchTheme,
            expandSidebar
        };
    },
    data() {
        return {
          baseurl: process.env.VUE_APP_Service_URL,
            currentPatient: {
                id: "TC-001",
                name: "John Doe",
                dob: "1978-05-15",
                age: "45 yrs",
                gender: "Male",
                allergies: ["Penicillin"],
                medications: ["Metformin 500mg"],
                primaryPhysician: "Dr. Smith",
                trialId: "CT-2023-001",
                enrollmentDate: "2023-06-01",
                studyArm: "Group A (Intervention)",
                consentStatus: "Signed",
                vitals: [
                ],
                labs: [
                  { id: 1, test: "Hemoglobin A1c", result: "6.5%", date: "2023-07-15", abnormal: true },
                  { id: 2, test: "Cholesterol", result: "180 mg/dL", date: "2023-07-10", abnormal: false }
                ],
                auditTrails: [
                  {
                    event: "Observation Added",
                    user: "Dr. Smith",
                    timestamp: "2025-02-14 12:02"
                  },
                  {
                    event: "Device Synced",
                    user: "System Auto",
                    timestamp: "2025-02-14 09:10"
                  }
                ],
                devices: [
                  {
                    name: "ECG Wearable",
                    model: "XYZ-ECG-2024",
                    lastSync: "2025-02-14 15:10",
                    battery: 85
                  },
                  {
                    name: "BP Monitor",
                    model: "ABC-BP-1001",
                    lastSync: "2025-02-14 09:12",
                    battery: 92
                  },
                  {
                    name: "Galaxy Watch 5",
                    model: "SM-R900",
                    lastSync: "2025-02-14 07:25",
                    battery: 68
                  }
                ]

              },
              patientsummaryinfo:{
                basicinfo:{
                  PatientID:"",
                  Name:"",
                  DoB:"",
                  Gender:"",
                },
                trialinfo:{
                  TrialID:"",
                  Enrollment:"",
                  StudyArm:"",
                  Consent:""
                },
                medicalinfo:{
                  Allergies:"",
                  CurrentMeds:"",
                  PrimaryCare:""
                }
              },
              expandedFolders:[],
              expandedpatient:"",
              expandeddocument:[],
              submenudatas:{
                patient:[],
                observation:[],
                condition:[],
                medication:[],
                encounter:[],
                procedure:[],
                devices:[],
                diagnostic:[],
                medicationAdministration:[],
                adverseEvent:[]
              },
              patientsubmenus: [
            {
              id: "patient",
              name: "Patient"},
            {
              id: "encounter",
              name: "Encounters"},
            {
              id: 'observation',
              name: 'Observations'},
            {
              id: 'medicationAdministration',
              name: 'Medication Administration'},
            {
              id: 'adverseEvent',
              name: 'Adverse Event'},
            {
              id: 'procedure',
              name: 'Procedures'},
            {
              id: 'condition',
              name: 'Conditions'},
            {
              id: 'medication',
              name: 'Medications'},
            {
              id: "allergy",
              name: "Allergies"},
            {
              id: "diagnostic",
              name: "Diagnostic Reports"},
            {
              id: "careplan",
              name: "Care Plans"},
            {
              id: "immunization",
              name: "Immunizations"},
            {
              id: "audit",
              name: "Audit Trail & Provenance"},
            {
              id: "devices",
              name: "Devices"}
            ],
          selectedDocument: null,
          studies: [],
          refreshFlags: {
            overview: true,
            visits: false,
            documents: false
          },
          fhirtocken:"",
          audittoken:"",
          patientid:"",
          reserchstudy:{},
          researchlocations:{},
          researchpatients:[],
          timeFormat:"",
          dateFormat:"",
          openUploadModal:false,
          showLoading:false,
          showValidation:false,
          loading:false,
          showCategoryLoading:false,
          uploadedFiles:[],
          uniqueObservationsCategories:[],
          categoryFlags:[],
          vitalSignsList:[]
        }
       
      },
      async mounted() {
        this.timeFormat=localStorage.getItem("timeformat");
        this.dateFormat=localStorage.getItem("dateformat");
        await this.getfhirtoken();
        // await this.getaudittoken();
      },
      methods: {
        async previewFiles(event) {
          this.showValidation = false
          this.uploadedFiles = Array.from(event.target.files);
          console.log("Files selected:", this.uploadedFiles);
        },
        async uploadFile(){
          this.showValidation = false
          const idtoken = store.getters.getIdToken;
          const formData = new FormData();
          if (this.uploadedFiles.length == 0) {
            this.showValidation = true
            this.showLoading=false
          }
          else{
            this.uploadedFiles.forEach((file) => {
              formData.append("file", file);
            });        
           await axios.post(
                  `${this.baseurl}/management/fhir/upload`, formData,
                  {
                    headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "multipart/form-data",
                    },
                    responseType: "blob",
                  }
                
              ).then((res)=>{
                alert(this.$t("navigations.FHIR data uploaded successfully"))
                this.closeUploadFileModal()
              })    
              .catch ((err)=> {
                  console.log("Error in file upload...", err);
                  this.uploadedFiles=[]
                  this.showLoading=false
                  this.showValidation = false
              })
         
          }
         
        },
        closeUploadFileModal(){
          this.uploadedFiles=[]
          this.showLoading=false
          this.showValidation = false
          this.openUploadModal=false
        },
        filterTimeFormat(data) {
          if(data){
            return data.replace(/:00\+00:00$/, ''); 
          }
         
        },
        async getaudittoken() {
          const tokenValues = {
            grant_type: "client_credentials", // Use lowercase
            client_id: "b5be1e59-d74c-43eb-993f-c0526f152f1a",
            client_secret: "tsm8Q~UjmhmvjnRrynvdWruEf7tllyNsTXOWKcUQ",
            resource: "https://api.loganalytics.io"
          };
        
          // Convert the token values to a URL-encoded string
          const params = new URLSearchParams();
          for (const key in tokenValues) {
            params.append(key, tokenValues[key]);
          }
        
          try {
            const res = await axios.post(
              `https://login.microsoftonline.com/dc21eb2f-3e2b-4bdc-ad52-a815789fd863/oauth2/token`,
              params,
              { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
            );
            this.audittoken = res.data.access_token;
          } catch (err) {
            console.error("Error:", err.response ? err.response.data : err);
          }
        },
        async getfhirtoken(){
    
          const idtoken = store.getters.getIdToken;
  
    
          await axios
    
            .get(
              `${this.baseurl}/management/fhir/access-token`,
    
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
    
                  "Content-Type": "application/json",
                },
              }
            )
    
            .then((res) => {
              this.fhirtocken = res.data;
              this.reserchstudyapi()
            })
    
            .catch((err) => {
              console.log(err);
            });
        },
        // async getfhirtoken() {
        //   const tokenValues = {
        //     grant_type: "client_credentials", 
        //     client_id: "b5be1e59-d74c-43eb-993f-c0526f152f1a",
        //     client_secret: "tsm8Q~UjmhmvjnRrynvdWruEf7tllyNsTXOWKcUQ",
        //     resource: "https://ids-ctms.fhir.azurehealthcareapis.com/"
        //   };
        
        //   const params = new URLSearchParams();
        //   for (const key in tokenValues) {
        //     params.append(key, tokenValues[key]);
        //   }
        
        //   try {
        //     const res = await axios.post(
        //       `https://login.microsoftonline.com/dc21eb2f-3e2b-4bdc-ad52-a815789fd863/oauth2/token`,
        //       params,
        //       { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        //     );
        //     this.fhirtocken = res.data.access_token;
        //     await this.reserchstudyapi()
        //   } catch (err) {
        //     console.error("Error:", err.response ? err.response.data : err);
        //   }
        // },
        async reserchstudyapi(){
          await axios
          .get(
            `https://ids-ctms.fhir.azurehealthcareapis.com/ResearchStudy/f91595bb-eae7-49c2-bb0c-c0fb502dbb52`,
            {
              headers: {
                Authorization: "Bearer " + this.fhirtocken,
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            this.reserchstudy = res.data;
            this.getlocationapi()
  
          })
        },
        async getlocationapi(){
          await axios
          .get(
            `https://ids-ctms.fhir.azurehealthcareapis.com/Location?partof=ResearchStudy/${this.reserchstudy.id}`,
            {
              headers: {
                Authorization: "Bearer " + this.fhirtocken,
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            this.researchlocations = res.data;
            // this.researchlocations.entry[0].resource.extension.forEach(element => {
            //   this.getpatientbyresourceid(element.valueReference.reference);
            // });
            console.log("data isss..", res.data);
  
          })
        },
        async retrievefhirpatient(){
          const idtoken = store.getters.getIdToken;
          await axios
            .get(
              `https://ids-ctms.fhir.azurehealthcareapis.com/Patient`,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            ).then((res) => {
    
              this.roleList = res.data;
              this.RoleListing();
              console.log("roles are..", this.roleList);
    
            })
        },
        async togglepatientFolder(node) {
          console.log("patient node",node);
          this.categoryFlags=[];
          this.expandeddocument=[];
          this.submenudatas={
            patient:[],
            observation:[],
            condition:[],
            medication:[],
            encounter:[],
            procedure:[],
            devices:[],
            diagnostic:[],
            medicationAdministration:[],
            adverseEvent:[]
          };
          await this.getpatientbyresourceid(node.valueReference.reference);
          // const index = this.expandedFolders.indexOf(node.valueReference.display);
          // if (index > -1) {
          //   this.expandedFolders.splice(index, 1);
          // } else {
          //   this.expandedFolders.push(node.valueReference.display);
          // }
          if(this.expandedpatient == node.valueReference.display){
            this.expandedpatient = "";
          }
          else{
          this.expandedpatient = node.valueReference.display;
        }
        },
        toggleFolder(node) {
          const index = this.expandedFolders.indexOf(node.id);
          this.selectedDocument=this.reserchstudy
          if (index > -1) {
            this.expandedFolders.splice(index, 1);
          } else {
            this.expandedFolders.push(node.id);
          }
        },
        async toggledocumentFolder(node, patientid){
          const index = this.expandeddocument.indexOf(node.id);
          if (index > -1) {
            this.expandeddocument.splice(index, 1);
          } else {
            await this.loaddocument(node, patientid);
            this.expandeddocument.push(node.id);
          }
        },
        async toggleLocationFolder(node) {
          const index = this.expandedFolders.indexOf(node.resource.id);
          this.getSiteData(node.resource.id)
          if (index > -1) {
            this.expandedFolders.splice(index, 1);
          } else {
            this.expandedFolders.push(node.resource.id);
          }
        },
        // toggleLocationFolder(node) {
        //   const index = this.expandedFolders.indexOf(node.id);
        //   if (index > -1) {
        //     this.expandedFolders.splice(index, 1);
        //   } else {
        //     this.expandedFolders.push(node.id);
        //   }
        // },
        async loaddocument(docfor, patientid){
          if(docfor.id == "patient"){
            if(this.submenudatas["patient"].length==0){
              await this.callpatientdocs();
            }
          }
          else if(docfor.id == "observation"){
              await this.callobservationdocs(patientid);
          }
          else if(docfor.id == "encounter"){
              await this.callencounterdocs(patientid);
          }
          else if(docfor.id == "devices"){
              await this.calldevicedocs(patientid);
          }
          else if(docfor.id == "diagnostic"){
              await this.calldiagnosticdocs(patientid);
          }
          else if(docfor.id == "medicationAdministration"){
              await this.callMedicationAdministrationdocs(patientid);
          }
          else if(docfor.id == "adverseEvent"){
            if(this.submenudatas["adverseEvent"].length==0){
              await this.callAdverseEventdocs(patientid);
            }
          }
          else if(docfor.id == "procedure"){
            if(this.submenudatas["procedure"].length==0){
              await this.callProceduredocs(patientid);
            }
          }
        },
        callpatientdocs(){
          this.submenudatas["patient"] = this.researchpatients;
        },
        async getSiteData(patientid)
        { 
          await axios
          .get(
            `https://ids-ctms.fhir.azurehealthcareapis.com/Location/${patientid}`,
            {
              headers: {
                Authorization: "Bearer " + this.fhirtocken,
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            this.selectedDocument=res.data
          })
        },
        async calldiagnosticdocs(patientid){
          await axios
          .get(
            `https://ids-ctms.fhir.azurehealthcareapis.com/DiagnosticReport?patient=${patientid}`,
            {
              headers: {
                Authorization: "Bearer " + this.fhirtocken,
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            this.submenudatas["diagnostic"] = res.data.entry;
          })
        },
        async calldevicedocs(patientid){
          await axios
          .get(
            `https://ids-ctms.fhir.azurehealthcareapis.com/Device?patient=${patientid}`,
            {
              headers: {
                Authorization: "Bearer " + this.fhirtocken,
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            this.submenudatas["devices"] = res.data.entry;
          })
        },
        async callencounterdocs(patientid){
          await axios
          .get(
            `https://ids-ctms.fhir.azurehealthcareapis.com/Encounter?subject=${patientid}`,
            {
              headers: {
                Authorization: "Bearer " + this.fhirtocken,
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            this.submenudatas["encounter"] = res.data.entry;
          })
        },
        async callAdverseEventdocs(patientid){
          await axios
          .get(
            `https://ids-ctms.fhir.azurehealthcareapis.com/AdverseEvent?subject=${patientid}`,
            {
              headers: {
                Authorization: "Bearer " + this.fhirtocken,
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            this.submenudatas["adverseEvent"] = res.data.entry;
          })
        },
        async callobservationdocs(patientid){
          this.loading=true
          this.vitalSignsList=[]
          await axios
          .get(
            `https://ids-ctms.fhir.azurehealthcareapis.com/Observation?subject=${patientid}&_count=20`,
            {
              headers: {
                Authorization: "Bearer " + this.fhirtocken,
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            this.loading=false
            this.submenudatas["observation"] = res.data.entry;
            this.categoryFlags = Object.values(
              res.data.entry.reduce((acc, doc) => {
                const document = doc.resource;
                const id=doc.resource.id
                const category = doc.resource.category[0].coding[0].display;
                const codeText = doc.resource.code.text ?  doc.resource.code.text : doc.resource.code.coding[0].display; // Handle missing `text`
                const effectiveDateTime = doc.resource.effectiveDateTime || null;
            
                // If the category already exists, add the code to its array
                if (!acc[category]) {
                  acc[category] = {
                    category: category,
                    code: [],
                    effectiveDateTime: [],
                    flag: false,
                    document: [],
                    id: [],
                    idSet: new Set(), // Track unique IDs
                  };
                }   // Push codeText to the array (allowing duplicates)
                if (id && !acc[category].idSet.has(id)) {
                    acc[category].idSet.add(id);
                    acc[category].id.push(id);

                  if (codeText) {
                    acc[category].code.push(codeText);
                  }
                  if (effectiveDateTime) {
                    acc[category].effectiveDateTime.push(effectiveDateTime);
                  }
                  if (document) {
                    acc[category].document.push(document);
                  }
                }
            
                return acc;
                
              }, {})     
           
         
            );
            const vitalSignsCategory = this.categoryFlags.find(category => category.category === "Vital Signs" || category.category === "vital signs"
            )
            let latestVitalSigns = [];

            if (vitalSignsCategory) {
              const { code, document, unit, effectiveDateTime } = vitalSignsCategory;

              // Combine code and effectiveDateTime into a single array of objects
              const combined = code.map((c, index) => ({
                code: c,   
                value:  c == 'Blood Pressure' ? `${document[index].component[1].valueQuantity.value}/${document[index].component[2].valueQuantity.value}` : 
                c == 'Body height' || c == 'Body weight' ||  c == 'Body Temperature' ? document[index].valueQuantity.value : '',
                unit: c == 'Blood Pressure' ? document[index].component[1].valueQuantity.unit: c == 'Body height' || c == 'Body weight' ||  c == 'Body Temperature' ? document[index].valueQuantity.unit : '',  
                effectiveDateTime: effectiveDateTime[index] // Convert to Date for sorting
              }));
              // Sort by effectiveDateTime in descending order
              combined.sort((a, b) => b.effectiveDateTime - a.effectiveDateTime);

              // Extract the latest unique code entries
              const seenCodes = new Set();
              const latestVitalSigns = combined.filter(entry => {
                if (!seenCodes.has(entry.code)) {
                  seenCodes.add(entry.code);
                  return true;
                }
                return false;
              }).map(entry => ({
                code: entry.code,
                value: entry.value,
                unit:entry.unit,
                effectiveDateTime: entry.effectiveDateTime // Convert back to string if needed
              }));
              this.vitalSignsList=latestVitalSigns
            }
  
          })
          .catch((err)=>{
            this.loading=false
            this.vitalSignsList=[]
            console.log("Error",err)
          })
        },
        async callMedicationAdministrationdocs(patientid){
          await axios
          .get(
            `https://ids-ctms.fhir.azurehealthcareapis.com/MedicationAdministration?subject=${patientid}`,
            {
              headers: {
                Authorization: "Bearer " + this.fhirtocken,
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            this.submenudatas["medicationAdministration"] = res.data.entry;
          })
        },
        async callProceduredocs(patientid){
          await axios
          .get(
            `https://ids-ctms.fhir.azurehealthcareapis.com/Procedure?subject=${patientid}`,
            {
              headers: {
                Authorization: "Bearer " + this.fhirtocken,
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            this.submenudatas["procedure"] = res.data.entry;
          })
        },
        async getpatientbyresourceid(patid){
          await axios
          .get(
            `https://ids-ctms.fhir.azurehealthcareapis.com/${patid}`,
            {
              headers: {
                Authorization: "Bearer " + this.fhirtocken,
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {
            console.log("data isss..", res.data);
            const index = this.researchpatients.indexOf(res.data);
            if (index > -1) {
              console.log("patient exist")
            } else {
              this.researchpatients = [];
              this.researchpatients.push(res.data);
            }
            console.log("res.data.identifier.value",res.data.identifier.value);
            
            if (res.data.identifier && res.data.identifier.length > 0 && res.data.identifier[0].value ) {
              this.patientsummaryinfo.basicinfo.PatientID = res.data.identifier[0].value || "";
            }
            if (res.data.name && res.data.name.length > 0 && res.data.name[0].given && res.data.name[0].given.length > 0) {
              this.patientsummaryinfo.basicinfo.Name = res.data.name[0].given[0] || "";
            }
            this.patientsummaryinfo.basicinfo.DoB = res.data.birthDate?res.data.birthDate:"";
            this.patientsummaryinfo.basicinfo.Gender = res.data.gender?res.data.gender:"";
            if (res.data.generalPractitioner && res.data.generalPractitioner.length > 0) {
              this.patientsummaryinfo.medicalinfo.PrimaryCare = res.data.generalPractitioner[0].display || "";
            }
            if (res.data.extension && res.data.extension.length > 3) {
              this.patientsummaryinfo.trialinfo.Enrollment = res.data.extension[2].valueDate || "";
              this.patientsummaryinfo.trialinfo.StudyArm = res.data.extension[3].valueString || "";
            }
          })
        },
        handleTabClick(tab) {
          // Reset all tabs
          Object.keys(this.refreshFlags).forEach(key => {
            this.refreshFlags[key] = false
          })
          // Activate selected tab
          this.refreshFlags[tab] = true
        },
        selectDocument(doc) {
          console.log("submenudatas",this.submenudatas);
          console.log("doc",doc);
          this.selectedDocument = doc;
        },
        maskedName(fullName) {
          if (!fullName) return 'Unknown';
          
          return fullName
            .split(' ')
            .map(name => {
              if (name.length <= 2) return name[0] + '*';
              return name[0] + '*'.repeat(name.length - 1);
            })
            .join(' ');
        }
      }
    };
