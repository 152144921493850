<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.stats") }}</h2>
    </div>
    <div class="col-lg-2 text-right">
        <span class="tooltip-wrapper pointer">
           <i class="fa fa-times close" @click="closeScreen()"></i>
          <span class="custom-tooltip">Close</span>
        </span>
      </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="row mx-0">
        <div class="col-12 col-md-5 my-1 pl-lg-0 pr-lg-1">
          <div class="form-group mb-md-0">
            <label for="programName">{{ $t("navigations.program name")
              }}<sup><i class="fa fa-asterisk important-block px-1"></i></sup></label>
            <input id="programName" type="text" class="form-control my-md-2 my-lg-0" v-model="programName" />
            <span class="errormsg ml-0" v-if="validations[0] == true">
              {{ $t("navigations.please enter a value") }}
            </span>
          </div>
        </div>
        <div class="col-12 col-md-5 my-1 pr-lg-1">
          <div class="form-group mb-md-0">
            <label for="fileName">{{ $t("navigations.select a file")
              }}<sup><i class="fa fa-asterisk important-block px-1"></i></sup></label>
            <select class="form-select my-md-2 my-lg-0"  @change="getFields()" name="fileName" placeholder="Search"
              v-model="fileName" id="fileName">
              <option value="">{{ $t('navigations.none') }}</option>
              <option v-for="file in fileList" :key="file" :value="file">
                {{ file }}
              </option>
            </select>
            <span class="errormsg ml-0" v-if="validations[1] == true">
              {{ $t("navigations.please choose a value") }}
            </span>
          </div>
        </div>
        <div class="col-12 pl-0 my-1 mb-3" :class="{'col-md-4':reportType!='Graph','col-md-3':reportType=='Graph'}">
          <label for="returnType">{{ $t("navigations.return type")
            }}<sup><i class="fa fa-asterisk important-block px-1"></i></sup></label>
          <select class="form-select" name="account" id="returnType" placeholder="Search" v-model="returnType">
            <option value="">
              {{ $t("navigations.none") }}
            </option>
            <option value="Pdf">
              {{ $t("navigations.pdf") }}
            </option>
            <option value="Html">
              {{ $t("navigations.html") }}
            </option>
          </select>
          <span class="errormsg ml-0" v-if="validations[2] == true">
            {{ $t("navigations.please choose a value") }}
          </span>
        </div>
        <div class="col-12 pl-0 my-1 mb-3" :class="{'col-md-4':reportType!='Graph','col-md-3':reportType=='Graph'}">
          <label for="orientation">{{ $t("navigations.orientation")
            }}<sup><i class="fa fa-asterisk important-block px-1"></i></sup></label>
          <select class="form-select" name="account" id="orientation" placeholder="Search" v-model="orientation">
            <option value="">
              {{ $t("navigations.none") }}
            </option>
            <option value="Landscape">
              {{ $t("navigations.landscape") }}
            </option>
            <option value="Portrait">
              {{ $t("navigations.portrait") }}
            </option>
          </select>
          <span class="errormsg ml-0" v-if="validations[3] == true">
            {{ $t("navigations.please choose a value") }}
          </span>
        </div>
        <div class="col-12 pl-0 my-1 mb-3" :class="{'col-md-4':reportType!='Graph','col-md-3':reportType=='Graph'}">
          <label for="reportType">{{ $t("navigations.report type")
            }}<sup><i class="fa fa-asterisk important-block px-1"></i></sup></label>
          <select @change="valueChange()" class="form-select" name="account"  id="reportType" placeholder="Search" v-model="reportType">
            <option value="">
              {{ $t("navigations.none") }}
            </option>
            <option value="Graph">
              {{ $t("navigations.graph") }}
            </option>
            <option value="Table">
              {{ $t("navigations.table") }}
            </option>
            <option value="Listing">
              {{ $t("navigations.listing") }}
            </option>
          </select>
          <span class="errormsg ml-0" v-if="validations[4] == true">
            {{ $t("navigations.please choose a value") }}
          </span>
        </div>
        <div class="col-12 col-md-3 pl-0 my-1 mb-1" v-if="reportType=='Graph'">
          <label for="chartType">{{ $t("navigations.chart type")
            }}<sup><i class="fa fa-asterisk important-block px-1"></i></sup></label>
          <select class="form-select" name="account" id="chartType" placeholder="Search" v-model="chartType">
            <option value="">{{ $t('navigations.none') }}</option>
            <option v-for="item in graphList" :key="item._id" :value="item._id">
              {{ item.Name }}
            </option>
          </select>
          <span class="errormsg ml-0" v-if="validations[5] == true">
            {{ $t("navigations.please choose a value") }}
          </span>
        </div>
        <div class="px-0 d-flex justify-content-between" v-if="reportType!='Graph'">
          <label class="px-0">{{ $t("navigations.row filters") }}</label>
          <div class="position-relative align-self-center" aria-label="add row" tabindex="0">
            <img width="20" class="cursor-pointer" src="../../assets/img/create.png" alt="Create user"
              @click="addRow('row')" />
            <div class="tool-tip">{{ $t("navigations.add row") }}</div>
          </div>
        </div>
        <template v-if="reportType!='Graph'">
          <div v-for="(filter, index) in rowFilters" :key="index" class="row px-0 mx-0 mb-2">
          <!-- Field Name (dynamic key) -->
          <div class="col-12 col-md-4 pl-0">
            <div class="form-group mb-0">
              <label for="fieldName" class="mb-0">{{ $t("navigations.select a field")
                }}<!-- <sup><i class="fa fa-asterisk important-block px-1"></i></sup>--></label>
              <select class="form-select my-md-2"  name="account" id="fieldName" placeholder="Search"
                v-model="filter.ColumnName">
                <option value="">{{ $t('navigations.none') }}</option>
                <option v-for="field in rowFilterFields" :key="field" :value="field">
                  {{ field }}
                </option>
              </select>
              <span class="errormsg ml-0" v-if="
                rowFilterValidations &&
                rowFilterValidations[index] &&
                rowFilterValidations[index][0] == true
              ">
                {{ $t("navigations.please choose a value") }}
              </span>
            </div>
          </div>

          <!-- Operator Input -->
          <div class="col-12 col-md-4 pl-0">
            <div class="form-group mb-0">
              <label for="operator">{{ $t("navigations.operator") }}</label>
              <input type="text" class="form-control" v-model="filter.Operator" placeholder="Operator" />
              <span class="errormsg ml-0" v-if="
                rowFilterValidations &&
                rowFilterValidations[index] &&
                rowFilterValidations[index][1] == true
              ">
                {{ $t("navigations.please enter a value") }}
              </span>
            </div>
          </div>

          <!-- Value Input -->
          <div class="col-12 col-md-3 pl-0">
            <div class="form-group mb-0">
              <label for="value">{{ $t("navigations.value")
                }}<!-- <sup><i class="fa fa-asterisk important-block px-1"></i></sup>--></label>
              <input type="text" class="form-control" v-model="filter.Value" placeholder="Value" />
              <span class="errormsg ml-0" v-if="
                rowFilterValidations &&
                rowFilterValidations[index] &&
                rowFilterValidations[index][2] == true
              ">
                {{ $t("navigations.please enter a value") }}
              </span>
            </div>
          </div>

          <!-- Delete Button -->
          <div class="col-12 col-md-1 pl-0 my-1 align-self-end">
            <button class="btn-circle position-relative" type="button" @click.prevent="deleteFilter('row', index)">
              <i class="fa fa-minus"></i>
              <div class="tool-tip">{{ $t("navigations.delete") }}</div>
            </button>
          </div>
          </div>
        </template>    
        <div class="row pl-0 pr-2 mx-0">    
        <div v-if="reportType != 'Table'" class="col-sm pl-0 my-1">
          <label>{{ $t("navigations.column filters") }}</label>
          <div class="form-group mb-md-0">
            <label for="fieldName">{{ $t("navigations.select a field")
              }}<!-- <sup><i class="fa fa-asterisk important-block px-1"></i></sup>--></label>
            <Multiselect v-model="columnFieldNames"  mode="multiple" :searchable="true" :options="columnFilters"
              :hideSelected="false" :closeOnSelect="false" class="multiselect-theme mr-0">
            </Multiselect>
            <!-- <span class="errormsg ml-0" v-if="validations[2] == true">
              {{ $t("navigations.please enter a value") }}
            </span> -->
          </div>
        </div>
        <div class="pl-0 my-1 col-sm" v-if="reportType != 'Graph' && reportType != 'Table'">
          <label>{{ $t("navigations.group by") }}</label>
          <div class="form-group mb-md-0">
            <label for="fieldName" class="mb-0">{{ $t("navigations.select a field")
              }}<!-- <sup><i class="fa fa-asterisk important-block px-1"></i></sup>--></label>
            <select class="form-select mt-md-2"  name="account" id="fieldName" placeholder="Search"
              v-model="groupByField">
              <option value="">{{ $t('navigations.none') }}</option>
              <option v-for="field in groupByFilter" :key="field" :value="field">
                {{ field }}
              </option>
            </select>
            <!-- <span class="errormsg ml-0" v-if="validations[3] == true">
              {{ $t("navigations.please choose a value") }}
            </span> -->
          </div>
        </div>
      </div>
          <!-- Table contents starts      -->
      <div class="col-12 my-3 px-0" v-if="reportType=='Table'">
        <div class="repeat-border-bottom"></div>
      </div>
      <template v-if="reportType=='Table'">
       
       <label class="px-0">{{ $t("navigations.table contents") }}</label>
       <div class="col-12 col-md-3 pl-0 my-1">
         <label for="tableTitle">{{ $t("navigations.title")
           }}<!-- <sup><i class="fa fa-asterisk important-block px-1"></i></sup>--></label>
         <input type="text" class="form-control" v-model="tableTitle" id="tableTitle">
         <span class="errormsg ml-0" v-if="validations[9] == true">
           {{ $t("navigations.please enter a value") }}
         </span>
       </div>
       <div class="col-12 col-md-9 pl-0 my-1">
         <label for="tableSubtitle">{{ $t("navigations.subtitle")
           }}<!-- <sup><i class="fa fa-asterisk important-block px-1"></i></sup>--></label>
         <input type="text" class="form-control" v-model="tableSubtitle" id="tableSubtitle">
         <!-- <span class="errormsg ml-0" v-if="validations[10] == true">
           {{ $t("navigations.navigations.please enter a value") }}
         </span> -->
       </div>
       <!-- <div class="px-0 d-flex justify-content-between" v-if="reportType!='Graph'">
         <label class="px-0">{{ $t("navigations.row filters") }}</label>
         <div class="position-relative align-self-center" aria-label="add row" tabindex="0">
           <img width="20" class="cursor-pointer" src="../../assets/img/create.png" alt="Create user"
             @click="addRow('tableRow')" />
           <div class="tool-tip">{{ $t("navigations.add row") }}</div>
         </div>
       </div>
       <template v-if="reportType!='Graph'">
         <div v-for="(filter, index) in tableRowFilters" :key="index" class="row px-0 mx-0 mb-2" >
       
         <div class="col-12 col-md-4 pl-0">
           <div class="form-group mb-0">
             <label for="fieldName" class="mb-0">{{ $t("navigations.select a field")
               }}<sup><i class="fa fa-asterisk important-block px-1"></i></sup></label>
             <select class="form-select my-md-2" name="account"  id="fieldName" placeholder="Search"
               v-model="filter.ColumnName">
               <option value="">{{ $t('navigations.none') }}</option>
               <option v-for="field in rowFilterFields" :key="field" :value="field">
                 {{ field }}
               </option>
             </select>
             <span class="errormsg ml-0" v-if="
               rowFilterValidations &&
               rowFilterValidations[index] &&
               rowFilterValidations[index][0] == true
             ">
               {{ $t("navigations.please choose a value") }}
             </span> 
           </div>
         </div>
         <div class="col-12 col-md-4 pl-0">
           <div class="form-group mb-0">
             <label for="operator">{{ $t("navigations.operator") }}</label>
             <input type="text" class="form-control" v-model="filter.Operator" placeholder="Operator" />
             <span class="errormsg ml-0" v-if="
               rowFilterValidations &&
               rowFilterValidations[index] &&
               rowFilterValidations[index][1] == true
             ">
               {{ $t("navigations.please enter a value") }}
             </span> 
           </div>
         </div>

         <div class="col-12 col-md-2 pl-0">
           <div class="form-group mb-0">
             <label for="value">{{ $t("navigations.value")
               }}<sup><i class="fa fa-asterisk important-block px-1"></i></sup></label>
             <input type="text" class="form-control" v-model="filter.Value" placeholder="Value" />
              <span class="errormsg ml-0" v-if="
               rowFilterValidations &&
               rowFilterValidations[index] &&
               rowFilterValidations[index][2] == true
             ">
               {{ $t("navigations.please enter a value") }}
             </span> 
           </div>
         </div>

          Delete Button 
         <div class="col-12 col-md-2 pl-0 my-1 align-self-end ,b-2">
           <button class="btn-circle position-relative" type="button" @click.prevent="deleteFilter('tableRow', index)">
             <i class="fa fa-minus"></i>
             <div class="tool-tip">{{ $t("navigations.delete") }}</div>
           </button>
         </div>
         </div>
       </template>       -->
       <div class="col-12 col-md-6 pl-0 my-1">
         <div class="form-group mb-md-0">
           <label for="fieldName">{{ $t("navigations.column fields")
             }}<!-- <sup><i class="fa fa-asterisk important-block px-1"></i></sup>--></label>
           <Multiselect v-model="tableColumnFieldNames"  mode="multiple" :searchable="true"
             :options="columnFilters" :hideSelected="false" :closeOnSelect="false" class="multiselect-theme mr-0">
           </Multiselect>
           <!-- <span class="errormsg ml-0" v-if="validations[2] == true">
             {{ $t("navigations.please enter a value") }}
           </span> -->
         </div>
       </div>
       <div class="col-12 col-md-6 pl-0 my-1">
         <div class="form-group mb-md-0">
           <label for="fieldName">{{ $t("navigations.row fields")
             }}<!-- <sup><i class="fa fa-asterisk important-block px-1"></i></sup>--></label>
           <Multiselect v-model="tableRowFieldNames" mode="multiple"  :searchable="true" :options="rowFilterFields"
             :hideSelected="false" :closeOnSelect="false" class="multiselect-theme mr-0">
           </Multiselect>
           <!-- <span class="errormsg ml-0" v-if="validations[2] == true">
             {{ $t("navigations.please enter a value") }}
           </span> -->
         </div>
       </div>
       <!-- <div class="col-12 col-md-6 px-0 my-1">
                   <label for="fieldName" class="mb-0">{{ $t('navigations.numeric operations') }}<sup><i class="fa fa-asterisk important-block px-1"></i></sup></label>
                   <select class="form-select my-md-2" name="account" id="fieldName"
                       placeholder="Search" v-model="groupByField">
                       <option v-for="field in groupByFilter" :key="field" :value="field">
                           {{ field }}
                       </option>
                   </select>
               </div> -->
       <div class="col-10 pl-0 my-1">
         <label class="px-0">{{ $t("navigations.footnotes") }}</label>
         <textarea  class="form-control" v-model="footnotes" ></textarea>
       </div>
       </template>
       <div class="col-12 my-3 px-0" v-if="reportType=='Table'">
        <div class="repeat-border-bottom"></div>
      </div>
          <!-- Table contents ends      -->
        <!-- <div class="col-12 col-md-6 px-0 my-1">
                    <label for="fieldName" class="mb-0">{{ $t('navigations.numeric operations') }}<sup><i class="fa fa-asterisk important-block px-1"></i></sup></label>
                    <select class="form-select my-md-2" name="account" id="fieldName"
                        placeholder="Search" v-model="groupByField">
                        <option v-for="field in groupByFilter" :key="field" :value="field">
                            {{ field }}
                        </option>
                    </select>
                </div> -->
        <div class="col-12 col-md-4 pl-0 my-1">
          <label for="fieldName">{{ $t("navigations.page group")
            }}<!-- <sup><i class="fa fa-asterisk important-block px-1"></i></sup>--></label>
          <select class="form-select" name="account"  id="fieldName" placeholder="Search" v-model="pageGroup">
            <option value="">{{ $t('navigations.none') }}</option>
            <option v-for="field in groupByFilter" :key="field" :value="field">
              {{ field }}
            </option>
          </select>
          <!-- <span class="errormsg ml-0" v-if="validations[4] == true">
            {{ $t("navigations.please choose a value") }}
          </span> -->
        </div>
        <div class="col-12 col-md-4 pl-0 my-1" v-if="setMacro==false">
          <label for="chartType">{{ $t("navigations.developer name")
            }}<!-- <sup><i class="fa fa-asterisk important-block px-1"></i></sup>--></label>
          <input type="text" class="form-control" v-model="developerName">
          <span class="errormsg ml-0" v-if="validations[8] == true">
            {{ $t("navigations.please choose a value") }}
          </span>
        </div>
        <div class="col-12 col-md-4 pl-0 my-1" v-if="setMacro==false">
          <label for="chartType">{{ $t("navigations.qc name")
            }}<!-- <sup><i class="fa fa-asterisk important-block px-1"></i></sup>--></label>
          <input type="text" class="form-control" v-model="qcName">
          <span class="errormsg ml-0" v-if="validations[8] == true">
            {{ $t("navigations.please choose a value") }}
          </span>
        </div>
        <div class="col-12 col-md-4 pl-0 my-1" v-if="setMacro==false">
          <label for="status">{{ $t("navigations.status")
            }}<sup><i class="fa fa-asterisk important-block px-1"></i></sup></label>
         <select class="form-select" name="account" id="status" placeholder="Search" v-model="status">
            <option value="">
              {{ $t("navigations.none") }}
            </option>
            <option value="No Program">
              {{ $t("navigations.no program") }}
            </option>
            <option value="Old Output">
              {{ $t("navigations.old output") }}
            </option>
            <option value="Ready For Validation">
              {{ $t("navigations.ready for validation") }}
            </option>
            <option value="In Development">
              {{ $t("navigations.in development") }}
            </option>
            <option value="Current Output">
              {{ $t("navigations.Current Output") }}
            </option>
            <option value="Validated">
              {{ $t("navigations.validated") }}
            </option>
          </select>
          <span class="errormsg ml-0" v-if="validations[6] == true">
            {{ $t("navigations.please choose a value") }}
          </span>
        </div>
        <!-- <div class="col-12 col-md-4 pl-0 my-1">
          <label for="chartType">{{ $t("navigations.program id")
            }}<sup><i class="fa fa-asterisk important-block px-1"></i></sup></label>
          <input type="text" class="form-control" v-model="programId">
          <span class="errormsg ml-0" v-if="validations[8] == true">
            {{ $t("navigations.please choose a value") }}
          </span>
        </div> -->
        <div class="col-12 col-md-4 pl-0 my-1" v-if="reportType != 'Graph'">
          <label for="numericOperations">{{ $t("navigations.numeric operations")}}
          <!-- <sup><i class="fa fa-asterisk important-block px-1"></i></sup>--></label>
          <Multiselect v-model="numericOperations"  mode="multiple" :searchable="true" :options="numericOperationValues" id="numericOperations"
              :hideSelected="false" :closeOnSelect="false" class="multiselect-theme mr-0">
            </Multiselect>
          <span class="errormsg ml-0" v-if="validations[9] == true">
            {{ $t("navigations.please choose a value") }}
          </span>
        </div>

    
        <div class="row px-0 mx-0">
          <div class="col-12 col-md-1 my-1 align-self-end px-0">
            <button type="button" class="save_btn w-100 position-relative" :disabled="loadingBlock == true"
              @click.prevent="exBtnClicked()" :class="{ 'disabled-btn-cls': loadingBlock == true }">
              <div class="toc-loader position-absolute"
                :class="{ 'opacity-0': loadingBlock == false, 'opaciyt-1': loadingBlock == true }">
                <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
              </div>
              <div :class="{ 'opacity-1': loadingBlock == false, 'opacity-0': loadingBlock == true }">{{
                $t("navigations.view") }}
              </div>
            </button>
          </div>

          <div class="col-12 col-md-1 my-1 align-self-end px-1" v-if="showCreate==true || setMacro==true">
            <button v-if="editoption=='0' || id=='0'" type="button" class="save_btn w-100 position-relative" :disabled="loadingSaveBlock == true"
              @click.prevent="saveData()" :class="{ 'disabled-btn-cls': loadingSaveBlock == true }">
              <div class="toc-loader position-absolute"
                :class="{ 'opacity-0': loadingSaveBlock == false, 'opaciyt-1': loadingSaveBlock == true }">
                <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
              </div>
              <div :class="{ 'opacity-1': loadingSaveBlock == false, 'opacity-0': loadingSaveBlock == true }">{{
                $t("navigations.create") }}</div>
            </button>
            <button v-if="editoption=='1'" type="button" class="save_btn w-100 position-relative" :disabled="loadingSaveBlock == true"
              @click.prevent="saveData()" :class="{ 'disabled-btn-cls': loadingSaveBlock == true }">
              <div class="toc-loader position-absolute"
                :class="{ 'opacity-0': loadingSaveBlock == false, 'opaciyt-1': loadingSaveBlock == true }">
                <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
              </div>
              <div :class="{ 'opacity-1': loadingSaveBlock == false, 'opacity-0': loadingSaveBlock == true }">{{
                $t("navigations.update") }}</div>
            </button>
          </div>
          <div class="col-12 col-md-1 my-1 align-self-end px-1" v-else>
            <button  type="button" class="save_btn w-100 position-relative" :disabled="loadingSaveBlock == true"
              @click.prevent="saveData()" :class="{ 'disabled-btn-cls': loadingSaveBlock == true }">
              <div class="toc-loader position-absolute"
                :class="{ 'opacity-0': loadingSaveBlock == false, 'opaciyt-1': loadingSaveBlock == true }">
                <img src="../../assets/img/loading.gif" width="15" class="loading-btn-img" alt="Waiting for login" />
              </div>
              <div :class="{ 'opacity-1': loadingSaveBlock == false, 'opacity-0': loadingSaveBlock == true }">{{
                $t("navigations.save") }}</div>
            </button>
           
          </div>
        </div>
    
        <!-- Table contents end -->
      </div>
      <!-- modal starts -->
      <div class="iframe-wrapper" v-if="displayModal == true">
        <div class="iframe-container">
          <div class="titleclass d-flex justify-content-between py-2 px-3">
            <h4 class="my-0">{{ $t("navigations.data modal") }}</h4>
            <a class="close-link">
              <i class="fa fa-times" @click.prevent="displayModal = false"></i>
            </a>
          </div>
          <div class="iframe-body  width-height p-4">
            <template v-for="(page, index) in dataPages" :key="index">
              <iframe class="iframe-block" :id="`${page.Page}-${index}`" style="border: none;">
              </iframe>
            </template>
          </div>
        </div>
      </div>
      <!-- modal ends -->
    </div>
  </div>
  
</template>
<script src="./Stats.js"></script>
<style scoped>
.multiselect-theme {
  --ms-radius: 0;
  --ms-py: 0.4rem;
  --ms-option-font-size: 0.8rem;
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
}

.important-block {
  color: rgb(255, 0, 0);
  font-size: 7px;
}

.iframe-wrapper {
  position: fixed;
  display: grid;
  place-items: center;
  inset: 0;
  background: rgb(0 0 0 / 30%);
  transition: cubic-bezier;
  overflow: hidden;
  z-index: 10000;
}

.iframe-container {
  width: min(700px, 90%);
  background: #fff;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}

.loading-btn-img {
  display: block;
  mix-blend-mode: lighten;
}

.disabled-btn-cls {
  opacity: 0.6;
  pointer-events: none;
}

.repeat-border-bottom {
  border-bottom: 1px dashed hsl(237, 42%, 40%);
}

.iframe-block {
  width: 100%;
  height: 500px
}

.width-height {
  height: 500px;
  overflow: auto;
}

.toc-loader {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}
</style>