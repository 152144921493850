<template>
      <div class="ibox-content mb-1 px-3 py-2" v-if="!requiredsubmit && requiredlanguage && !requiredrole">
        <!-- <img class ="img" src="../../assets/img/lang_sel.png"/> -->
        <!-- Langusage -->
        <div class="d-flex flex-lg-row justify-content-between">
          <div class="form-group width-20 mb-0">
            <label class="mb-0">{{$t('navigations.available languages')}}</label>
            <select
              class="form-select"
              name="changelanguage"
              @change.prevent="getselectedtemplate($event.target.value)"
            >
              <!-- <option value="" selected >--{{$t('navigations.select')}}--</option> -->
              <option
                v-for="template in templist"
                :key="template.templateId"
                :value="template.templateId"
              >
                {{ template.language }}
              </option>
            </select>            
          </div>
          <div v-if="islanenglish == 'en'" class="rightdiv">
            <div class="d-flex flex-md-row">
              <div class="form-group mb-0 w-100">
                <label class="mb-0">{{$t('navigations.language')}}</label>
                 <select
                  class="form-control"
                  name="changelanguage"
                  v-model="laguageselected"
                >
                  <option disabled>{{ $t("navigations.select your language") }}</option>
                  <option v-for="lang in language" :key="lang.id" :value="lang">
                    {{ lang.language }}
                  </option>
                </select>
              </div>
              <span type="button"
                class="save_btn px-5 mx-lg-1 align-self-end"
                @click.prevent="translatelabel(laguageselected.code)"
              >
               {{ $t("navigations.translate") }}
              </span>
            </div>
          </div>
        </div>
    <!-- Langusage -->
  </div>
  <div class="ibox-content mb-1 px-3 py-2" v-if="requiredrole">
    <div class="form-group row mb-0">
      <div
        class="
          d-flex
          flex-column flex-lg-row
          justify-content-lg-start
          my-2 my-lg-1
        "
      >
        <div class="col-md-12 my-1 my-lg-0 pl-0 pr-1">
          <div class="form-group mb-md-0">
            <label for="rolelist" class="mb-0">Roles</label>
            <select
              class="form-select"
              id="rolelist"
              v-model="selectedrole"
              >
              <option value="All">All</option>
              <option
                v-for="role in roles"
                :value="role.id"
                :key="role.id"
                >
                {{ role.role_Name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="formElements">
    <!-- {{errorclasslist}} -->
    <!-- {{htmlarray[0]}} -->
    <form>
      <div class="form-group" v-for="input in htmlarray" :key="input.folder_Id">
        <div v-for="(item, key) in input.pages" :key="key">
          <!-- <h1>{{item.pageName}}</h1> -->
          <div v-if="item.pageNo == pageno" class="pageContainer mainbgchange">
            <div>
              <div
                class="groupsdiv"
                v-for="(groupno, key) in groups[pageno]"
                :key="key"
              >
                <!-- <div class="groupno" v-for="group in groups[pageno]"  :key=group">
              {{group}}
            </div> -->
                <div class="flexContainer">
                  <template
                    v-for="(newfield, key) in item.field"
                    :key="key"
                  >
                    <div
                      class="flex-sub-container"
                      v-if="newfield.groupOrderNo == groupno"
                    >
                      <!-- headder starts -->
                      <div v-if="newfield.type == 'header'" class="headerdiv p-1">
                          <h5 class="headdertext m-0">{{ newfield.label }}</h5>

                      </div>
                      <!-- headder ends -->
                      <!-- input starts -->
                      <div
                        v-else-if="inputElements.includes(newfield.type)"
                        class="flex-item"
                        @load="testfun()"
                      >
                        <baseinput
                          @click.prevent="assignprivilege(newfield.fieldName)"
                          :classname="errorclasslist[newfield.fieldName]"
                          :requiredname="requiredclasslist[newfield.fieldName]"
                          v-model="
                            formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                          "
                          :label="newfield.label"
                          :inputtype="newfield.type"
                          :disable="isDisabled"
                          type="text"
                        />
                        <span class="errormsg">{{
                          errormsg[newfield.fieldName]
                        }}</span>
                      </div>
                      <!-- @change="validatebyfield(newfield.fieldName, newfield.validation, item.pageNo)" -->
                      <!-- input ends -->
                      <!-- image starts -->
                      <div
                                    v-else-if="newfield.type == 'image'"
                                    class="flex-item displayflex"
                                  >
                                    <div>
                                      <div class="labelclass marginauto">
                                        {{ newfield.label }}
                                      </div>
                                      <input
                                        class="form-control"
                                        type="text"
                                        v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]"
                                      />
                                    </div>
                                    <img
                                      class="templatepreview"
                                      :src="`${baseurl}/dms/document/file/${
                                        newfield.imagePath.split(',')[0]
                                      }?versionid=${
                                        newfield.imagePath.split(',')[1]
                                      }`"
                                    />
                                  </div>
                                  <!-- image ends -->
                      <!-- scales starts -->
                      <div
                                    v-else-if="newfield.type == 'scales'"
                                    class="flex-item"
                                  >
                                    <div class="labelclass">
                                      please select a value
                                    </div>
                                    <div class="wrapper">
                                      <input
                                        type="range"
                                        :min="newfield.minval"
                                        :max="newfield.maxval"
                                        step="1"
                                        v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]"
                                      />
                                      <input
                                        class="form-control scaleval"
                                        type="number"
                                        v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]"
                                      />

                                      <span v-text="total"></span>
                                    </div>
                                    <span class="errormsg">{{
                          errormsg[newfield.fieldName]
                        }}</span>
                                  </div>
                                  <!-- scales ends -->
                                  <!-- time element starts -->
                                  <div v-else-if="newfield.type == 'time'" class="flex-item bgblue">
                                        <div class="labelclass">
                                          {{ newfield.label }}
                                        </div>
                                        <input class="form-control" classname="time" :label="newfield.label" type="time"
                                        v-model="formdata[`pageNo${item.pageNo}`][newfield.fieldName]" />
                                      </div>
                                      <!-- time element ends -->
                      <!-- date element starts -->
                      <div
                        v-else-if="newfield.type == 'date'"
                        class="flex-item"
                        @load="testfun()"
                      >
                        <basedate
                        @click="assignprivilege(newfield.fieldName)"
                          :classname="errorclasslist[newfield.fieldName]"
                          :requiredname="requiredclasslist[newfield.fieldName]"
                          v-model="
                            formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                          "
                          :label="newfield.label"
                          :disable="isDisabled"
                          type="text"
                        />
                        <span class="errormsg">{{
                          errormsg[newfield.fieldName]
                        }}</span>
                      </div>
                      <!-- date element ends -->
                      <!-- dateandtime element starts -->
                      <div v-else-if="newfield.type == 'dateandtime'" class="flex-item bgblue">
                                        <div class="labelclass">
                                          {{ newfield.label }}
                                        </div>
                                        <input class="form-control" classname="date" :label="newfield.label" type="datetime-local"
                                        v-model="
                            formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                          " />
                          <span class="errormsg">{{
                          errormsg[newfield.fieldName]
                        }}</span>
                                      </div>
                                      <!-- date element ends -->
                      <!-- dropdown menu starts -->
                      <div
                        v-else-if="newfield.type == 'dropdown'"
                        class="flex-item"
                      >
                        <baseselect
                        @click.prevent="assignprivilege(newfield.fieldName)"
                          :classname="errorclasslist[newfield.fieldName]"
                          :options="newfield.options"
                          v-model="
                            formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                          "
                          :disable="isDisabled"
                          :label="newfield.label"
                        />
                        <span class="errormsg">{{
                          errormsg[newfield.fieldName]
                        }}</span>
                      </div>
                      <!-- dropdown menu ends -->
                      <!-- text area starts -->
                      <div
                        class="elem"
                        v-else-if="
                          (newfield.type == 'description') |
                            (newfield.type == 'textarea')
                        "
                      >
                        <div class="txtarealabel">
                          <label :for="newfield.fieldName" class="textsize">{{
                            newfield.label
                          }}<sup v-if="errorclasslist[newfield.fieldName] == 'required'"><i class="fa fa-asterisk imp"></i></sup></label>
                        </div>
                        <div class="txtar">
                          <textarea
                          @click.prevent="assignprivilege(newfield.fieldName)"
                            :id="newfield.fieldName"
                            :name="newfield.fieldName"
                            class="textbox"
                            v-model="
                              formdata[`pageNo${item.pageNo}`][
                                newfield.fieldName
                              ]
                            "
                          />

                          <span class="errormsg">{{
                            errormsg[newfield.fieldName]
                          }}</span>
                        </div>
                      </div>
                      <!-- text area ends -->
                      <!-- notes starts -->
                      <div class="elem" v-else-if="newfield.type == 'notes'">
                        <div class="notesstyle">
                          {{ newfield.label }}
                        </div>
                      </div>
                      <!-- notes ends -->
                      <!-- file upload starts -->
                      <div
                                    v-else-if="newfield.type == 'uploadfile'"
                                    class="flex-item"
                                  >
                                    <div class="labelclass">
                                      {{ newfield.label }}
                                    </div>
                                    <div class="twoitemflex">
                                      <input
                                        class="form-control"
                                        :label="newfield.label"
                                        type="file"
                                       @change="helloButton"
                                      />
                                    </div>
                                  </div>
                                  <!-- file upload ends -->
                                  <!-- year starts -->
                                  <div
                                    v-else-if="newfield.type == 'year'"
                                    class="flex-item"
                                  >
                                    <div class="labelclass">
                                      {{ newfield.label }}
                                    </div>
                                    <input
                                      class="form-control"
                                      :label="newfield.label"
                                      type="number"
                                      min="1900"
                                      max="2099"
                                      step="1"
                                      v-model="
                              formdata[`pageNo${item.pageNo}`][
                                newfield.fieldName
                              ]
                            "
                                      />
                                      <span class="errormsg">{{
                          errormsg[newfield.fieldName]
                        }}</span>
                                  </div>
                                  <!-- year ends -->
                                  <!-- qr code starts -->
                                  <div
                                    class="elem"
                                    v-else-if="newfield.type == 'qrcode'"
                                    >
                                    <div hidden>
                                        {{
                                          qrcodefun(
                                            newfield.fieldName,
                                            newfield.options[0]
                                          )
                                        }}
                                      </div>
                                  <iframe :src="qrcodetxt[newfield.fieldName]" title="qr code">
</iframe>
</div>
<!-- qr code ends -->
<!-- numberanddate starts -->
<div
                                    v-else-if="newfield.type == 'numberanddate'"
                                    class="flex-item"
                                  >
                                    <div class="labelclass">
                                      {{ newfield.label }}
                                    </div>
                                    <div class="twoitemflex">
                                      <input
                                        class="form-control"
                                        :label="newfield.label"
                                        type="text"
                                        v-model="newfield.options[0]"
                                      />
                                      <input
                                        class="form-control"
                                        :label="newfield.label"
                                        type="date"
                                        
                                        v-model="
                              formdata[`pageNo${item.pageNo}`][
                                newfield.fieldName
                              ]
                            "
                                      />
                                    </div>
                                    <span class="errormsg">{{
                          errormsg[newfield.fieldName]
                        }}</span>
                                  </div>
                                  <!-- numberanddate ends " -->
                      <!-- checkboxes starts -->
                      <div
                        v-else-if="newfield.type == 'checkbox'"
                        class="form-group sticktobottom"
                      >
                        <label class="labelstyle">&nbsp;<sup v-if="errorclasslist[newfield.fieldName] == 'required'"><i class="fa fa-asterisk imp"></i></sup></label>
                        <div class="checkboxentry">
                          <input
                          @click="assignprivilege(newfield.fieldName)"
                            type="checkbox"
                            :id="newfield.fieldName"
                            :name="newfield.fieldName"
                            v-model="
                              formdata[`pageNo${item.pageNo}`][
                                newfield.fieldName
                              ]
                            "
                          /><span class="checklabel">
                            {{ newfield.label }}
                          </span>
                        </div>
                        <span class="errormsg">{{
                          errormsg[newfield.fieldName]
                        }}</span>
                      </div>
                      <!-- checkboxes ends -->
                      <!-- summery starts -->
                    <div
                                      class="elem"
                                      v-else-if="newfield.type == 'summary'"
                                    >
                                      <div class="consentnotesstyle">
                                        {{ newfield.label }}
                                      </div>
                                    </div>
                                    <!-- summery ends -->
                      <!-- radio button starts -->
                      <div
                        v-else-if="newfield.type == 'radio'"
                        class="form-group sticktobottom"
                      >
                        <baseradiogroup
                        @click="assignprivilege(newfield.fieldName)"
                          :label="newfield.label"
                          :name="newfield.fieldName"
                          :options="newfield.options"
                          v-model="
                            formdata[`pageNo${item.pageNo}`][newfield.fieldName]
                          "
                          :disable="isDisabled"
                        >
                        </baseradiogroup>
                        <span class="errormsg">{{
                          errormsg[newfield.fieldName]
                        }}</span>
                      </div>
                      <!-- radio button ends -->
                      <!-- subHeader starts -->
                      <div
                        v-else-if="newfield.type == 'subHeader'"
                        class="subheadder p-1"
                      >
                        <h4>{{ newfield.label }}</h4>
                      </div>
                      <!-- subHeader ends -->
                      <!-- {{fields}} -->
                    </div>
                  </template>
                </div>
              </div>
              <!-- view button  -->
              <!-- <div class="row mx-0 paginationpadding" v-if="!requiredsubmit">
                <div class="col-lg-12 mx-0 px-0">
                  <div
                    class="dataTables_paginate paging_simple_numbers pagination float-right"
                  >
                    <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                      <v-pagination
                        v-model="pageno"
                        :pages="totalpages"
                        :range-size="1"
                        active-color="#618bd7"
                      />
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- view button ends -->
              <div class="buttonContainer align-items-center justify-content-center">
                <div>
                  <button
                    v-if="pageno > 1"
                    type="button"
                    class="save_btn py-1 px-3"
                    @click.prevent="previousFun"
                  >
                    Previous
                  </button>
                </div>
                <div class="px-3"> Page {{ pageno }}</div>
                <div class="rightbutton">
                  <button
                    v-if="pageno < totalpages"
                    type="button"
                    class="save_btn py-1 px-3"
                    @click.prevent="nextfun"
                  >
                    Next
                  </button>                
                  <button
                    v-if="pageno==totalpages && (isPatientVisit==true || isPatientVisit=='true')"
                    type="button"
                    class="save_btn py-1 px-3 mr-2"
                    @click.prevent="testfun"
                  >
                    Save  
                  </button>              
                </div>
              </div>
              </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- modal starts -->
  <div v-if="fieldprivilegepopup == true" class="privilege__modal__wrapper">
    <div class="privilege__modal__container">
      <div
        class="
          privilege__modal__title
          d-flex
          justify-content-between
          py-2
          px-3
        "
      >
        <h4 class="my-0">Select Privileges</h4>
        <div class="cursor-pointer">
          <i class="fa fa-times" @click.prevent="closemodallll()"></i>
        </div>
      </div>
      <!-- Popup -->
      <div
        class="privilege__modal__content p-3"
        :class="{ heightBig: changeHeight }"
      >
    <!-- <select class="form-select" id="sel2" name="sellist2" v-model="selectedprivilege">
      <option selected value="">Select</option>
      <option value="Masking">Masking</option>
      <option value="View">View</option>
      <option value="Edit">Edit</option>
    </select> -->
    <div class="alignradio">
    <div class="form-check">
  <input class="form-check-input" v-model="selectedprivilege" type="radio" name="exampleRadios" id="exampleRadios1" value="Mask">
  <label class="form-check-label" for="exampleRadios1">
    Masking
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" v-model="selectedprivilege" type="radio" name="exampleRadios" id="exampleRadios2" value="View">
  <label class="form-check-label" for="exampleRadios2">
    View
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" v-model="selectedprivilege" type="radio" name="Edit" id="Edit" value="Edit">
  <label class="form-check-label" for="exampleRadios2">
    Edit
  </label>
</div>
    </div>
    <div class="col-md-4 my-1 btncenter-consent">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click.prevent="savefieldprivilege()"
                      >
                      Save
                      </button>
                    </div>
      </div>
    </div>
  </div>
  <!--  popup -->
</template>

<script src="./consentformtemplate.js"></script>

<style scoped>
/* @import "../style.css"; */
@import "../../../assets/style.css";

.form-select{
  border-radius:0;
}
.form-select:focus{
  border-color:#145faf;
  box-shadow: 0 0 0;
}
.imp {
    color: #ff0000;
    font-size: 7px;
}
.labelclass {
  text-align: left;
  font-size: 14px;
}
.privilege__modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.privilege__modal__container {
    width: min(70%, 270px);
    position: fixed;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    overflow-y: hidden;
    height: 160px;
}
.privilege__modal__title {
  background-color: var(--pop-up-background);
  color: #ffffff;
}
.privilege__modal__content {
  height: 250px;
}

.btncenter-consent{
  margin: auto;
  padding-top: 20px;
}
.alignradio{
    display: flex;
    gap: 20px;
}
</style>
