export default {
    props: {
      subContainer:{
        type: Boolean,
        default: false,
      },
      containerxl:{
        type: Boolean,
        default: false,
      },
      containermd:{
        type: Boolean,
        default: false,
      },
      containersm:{
        type: Boolean,
        default: false,
      },
      bodyxl: {
        type: Boolean,
        default: false,
      },
      bodymd: {
        type: Boolean,
        default: false,
      },
      widthMd:{
        type: Boolean,
        default: false,
      },
      isOpen: {
        type: Boolean,
        default: false,
      },
      footerShow:{
        type: Boolean,
        default: false,
      }
    },
    methods: {
      closeModal() {
        this.$emit('closeModal');
      },
      closeSubModal() {
        this.$emit('closeSubModal');
      }
    }
  };
  