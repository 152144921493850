<template>
    <!-- Navbar Section -->
    <div class="row border-bottom white-bg">
        <nav class="navbar navbar-static-top" role="navigation">
          <div class="landing-nav-header"><img src="../../assets/img/IDSLogo.png" alt="Logo" />
          </div >
                <ul class="d-flex justify-content-end align-items-center flex-grow-1 py-0 px-2 my-0" role="list">
                    <!-- <li>
                        <a href="login.html">
                            <i class="fa fa-sign-out"></i> Log out
                        </a>
                    </li> -->
                    <li
                      class="px-2 px-md-0 user-profile cursor-pointer"
                      aria-label="User profile"
                      tabindex="0"
                    >
                      <a
                        class="user-block px-0"
                        @click.prevent="displayProfile()"
                        data-toggle="tooltip"
                        data-placement="top"
                      >
                        <img
                          :src="usersdetails.picture"
                          class="img-fluid profile-pic"
                          alt="User Profile Image"
                        />
                        <div class="tool-tip">
                          {{ $t("navigations.user profile") }}
                        </div>
                      </a>
                      <div class="profile-block animate fadeIn text-start" v-show="showProfile">
                        <div
                          class="login-user-details d-flex justify-content-start align-items-center"
                        >
                          <div class="login-user-details--image">
                            <img
                              class="profile-pic me-2"
                              :src="usersdetails.picture"
                              alt="User Image"
                            />
                          </div>
                          <div class="login-user-details--details d-flex flex-column">
                            <span
                              ><b>{{ usersdetails.given_name }}</b></span
                            >
                            <span>{{ usersdetails.name }}</span>
                          </div>
                        </div>
                        <div class="user-details d-flex flex-column">
                          <div
                            class="theme-change-block d-flex justify-content-start align-items-center"
                            @click.prevent="$router.push('/profileSettings')"
                          >
                            <img
                              class="me-2"
                              src="../../assets/img/user_profile.png"
                              alt="User Profile"
                            />
                            <span>{{ $t("navigations.profile") }}</span>
                        </div>
                          <div
                            class="theme-change-block d-flex justify-content-start align-items-center"
                            @click="expandSidebar()"
                          >
                            <img
                              class="me-2"
                              src="../../assets/img/user_themes.png"
                              alt="User Themes"
                            />
                            <span class="theme-change-block">{{
                              $t("navigations.themes")
                            }}</span>
                            <div class="theme-menu animated fadeIn">
                              <div class="title">Themes</div>
                              <div
                                class="setings-item default-skin"
                                @click="switchTheme('theme-default')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Default </a>
                                </span>
                              </div>
                              <!-- <div class="setings-item blue-skin" @click="themeBlack()">
                                    <span class="skin-name ">
                                      <a href="#" class="s-skin-1">
                                        Black light
                                      </a>
                                    </span>
                                  </div> -->
                              <div
                                class="setings-item teal-skin"
                                @click="switchTheme('theme-green')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Teal Green </a>
                                </span>
                              </div>
                              <div
                                class="setings-item dusty-skin"
                                @click="switchTheme('theme-violet')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Dusty Lavender </a>
                                </span>
                              </div>
                              <div
                                class="setings-item ocean-skin"
                                @click="switchTheme('theme-ocian')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Ocean Green </a>
                                </span>
                              </div>
                              <div
                                class="setings-item stormy-skin"
                                @click="switchTheme('theme-stormy')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Stormy Cloud </a>
                                </span>
                              </div>
                              <div
                                class="setings-item pastel-skin"
                                @click="switchTheme('theme-pastel')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Pastel Russet </a>
                                </span>
                              </div>
                            </div>
                          </div>
                          <span class="dropdown-divider"></span>
                          <div
                            class="theme-change-block d-flex justify-content-start align-items-center"
                            @click.prevent="logout"
                          >
                            <img
                              class="me-2"
                              src="../../assets/img/logout.png"
                              alt="Logout"
                            />
                            <span>{{ $t("navigations.logout") }}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                </ul>
        </nav>
    </div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-12 align-self-center go-back d-flex justify-content-between">
            <h2 class="mt-0 text-grey">Inventory Tracking System</h2>
            <h2 class="mt-0 "><a href="/home" class="text-grey"><span><i class="fa fa-home"></i>&nbsp;Go to home</span></a></h2>
      </div>
    </div>
    <!-- Main Content Section -->
    <div class="wrapper wrapper-content animated fadeInRight px-0">
        <div class="container-fluid inventory-tracking">
        <div class="row">
            <!-- Left Configuration Panel -->
            <div class="col-md-3 px-1">
            <!-- Connection Settings -->
            <div class="card mb-2">
                <div class="card-header bg-primary text-white">
                <i class="bi bi-plug"></i> System Connection
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label class="form-label">Inventory System</label>
                        <select class="form-select" v-model="integration.system">
                        <option>SAP EWM</option>
                        <option>Oracle Inventory</option>
                        <option>Fishbowl</option>
                        <option>Custom System</option>
                        </select>
                    </div>
        
                    <div class="mb-3">
                        <label class="form-label">API Endpoint URL</label>
                        <input type="url" class="form-control" v-model="integration.apiUrl">
                    </div>
        
                    <div class="mb-3">
                        <label class="form-label">Authentication Type</label>
                        <select class="form-select" v-model="integration.authType">
                        <option>OAuth 2.0</option>
                        <option>API Key</option>
                        <option>Basic Auth</option>
                        </select>
                    </div>
        
                    <div class="mb-3" v-if="integration.authType === 'API Key'">
                        <label class="form-label">API Key</label>
                        <input type="password" class="form-control" v-model="integration.apiKey">
                    </div>
        
                    <div class="mb-3" v-if="integration.authType === 'Basic Auth'">
                        <label class="form-label">Username</label>
                        <input type="text" class="form-control" v-model="integration.username">
                        <label class="form-label mt-2">Password</label>
                        <input type="password" class="form-control" v-model="integration.password">
                    </div>
                    <button class="btn btn-success w-100">Connect</button>
                </div>
            </div>
    
            <!-- Data Mapping -->
            <div class="card">
                <div class="card-header bg-primary text-white">
                    <i class="bi bi-diagram-3"></i> Clinical Trial Field Mapping
                </div>
                <div class="card-body">
                <div class="mb-3">
                    <label class="form-label fw-bold">NDC Number Mapping</label>
                    <input type="text" 
                            class="form-control" 
                            placeholder="NDC 12345-6789-01"
                            v-model="mapping.ndcNumber">
                    <small class="form-text text-muted">
                        National Drug Code (11-digit format)
                    </small>
                </div>
    
                <div class="mb-3">
                    <label class="form-label fw-bold">Protocol ID Mapping</label>
                    <input type="text" 
                            class="form-control" 
                            placeholder="PRO-123-4567"
                            v-model="mapping.protocolId">
                    <small class="form-text text-muted">
                        Clinical trial protocol identifier
                    </small>
                </div>
    
                <div class="mb-3">
                    <label class="form-label fw-bold">Site ID Mapping</label>
                    <input type="text" 
                            class="form-control" 
                            placeholder="SITE-US-001"
                            v-model="mapping.siteId">
                    <small class="form-text text-muted">
                        Clinical site identifier (ISO country code)
                    </small>
                </div>
    
                <div class="mb-3">
                    <label class="form-label fw-bold">Kit Count Mapping</label>
                    <input type="text" 
                            class="form-control" 
                            placeholder="KIT-2301-100"
                            v-model="mapping.kitCount">
                    <small class="form-text text-muted">
                        Randomization kit inventory count
                    </small>
                </div>
                <div class="mb-3">
                    <label class="form-label fw-bold">Batch/Lot Number Mapping</label>
                    <input type="text" 
                            class="form-control" 
                            placeholder="BATCH-2301-01A"
                            v-model="mapping.batchNumber">
                    <small class="form-text text-muted">
                        IMP manufacturing batch number
                    </small>
                </div>
                <div class="mb-3">
                    <label class="form-label fw-bold">Expiry Date Mapping</label>
                    <input type="text" 
                            class="form-control" 
                            placeholder="2024-06-30"
                            v-model="mapping.expiryDate">
                    <small class="form-text text-muted">
                        ISO 8601 format (YYYY-MM-DD)
                    </small>
                </div>
                <button class="btn btn-primary w-100 py-2 fw-medium"><i class="bi bi-cloud-arrow-up me-2"></i> Save Clinical Mapping</button>
                </div>
            </div>
            </div>
    
            <!-- Right Dashboard -->
            <div class="col-md-9 px-1">
            <!-- Sync Controls -->
            <div class="card mb-2">
                <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                    <button class="btn btn-primary">
                        <i class="bi bi-arrow-repeat"></i> Manual Sync
                    </button>
                    <div class="form-check form-switch ms-3 d-inline-block">
                        <input class="form-check-input" type="checkbox" v-model="autoSync">
                        <label class="form-check-label">Auto Sync</label>
                    </div>
                    </div>
                    <div class="text-muted">
                    Last Sync: {{ lastSync }}
                    </div>
                </div>
                </div>
            </div>
    
            <!-- Inventory Dashboard -->
            <div class="card mb-2">
                <div class="card-header">
                <i class="bi bi-bar-chart"></i> Live Inventory Levels
                </div>
                <div class="card-body">
                <apexchart 
                    type="bar" 
                    height="350"
                    :options="chartOptions"
                    :series="chartData"
                ></apexchart>
                </div>
            </div>
            <div class="card mb-3">
            <div class="card-header">
                <i class="bi bi-clipboard2-pulse"></i> Clinical Inventory Status
            </div>
            <div class="card-body">
                <div class="table-responsive">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>Material</th>
                        <th>Batch</th>
                        <th>Qty Available</th>
                        <th>Expiry Date</th>
                        <th>Storage</th>
                        <th>IRB Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in clinicalInventory" :key="item.batch">
                        <td>{{ item.material }}</td>
                        <td>{{ item.batch }}</td>
                        <td :class="{ 'text-warning': item.quantity < 50 }">{{ item.quantity }}</td>
                        <td :class="{ 'text-danger': new Date(item.expiry) < new Date() }">
                        {{ formatDate(item.expiry) }}
                        </td>
                        <td>{{ item.storage }}</td>
                        <td>
                        <span class="badge" :class="{
                            'bg-success': item.irbApproved,
                            'bg-danger': !item.irbApproved
                        }">
                            {{ item.irbApproved ? 'Approved' : 'Pending' }}
                        </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
            <!-- Tracking Search -->
            <div class="card mb-2">
            <div class="card-header">
                <i class="bi bi-search"></i> Track Shipment
            </div>
            <div class="card-body">
                <div class="input-group">
                <input type="text" 
                        class="form-control" 
                        v-model="trackingNumber"
                        placeholder="Enter tracking number">
                <button class="btn btn-primary" 
                        @click="trackShipment">
                    <i class="bi bi-search"></i> Track
                </button>
                </div>
                
                <!-- Tracking Results -->
                <div v-if="trackingResult" class="mt-3">
                <h5>Tracking Information</h5>
                <div class="table-responsive">
                    <table class="table">
                <tbody>
                    <tr v-for="(value, key) in trackingResult" :key="key">
                    <th>{{ formatKey(key) }}</th>
                    <td v-if="key.toLowerCase().includes('date')">
                        {{ formatDateTime(value) }}
                    </td>
                    <td v-else>
                        {{ value }}
                    </td>
                    </tr>
                </tbody>
                </table>
                </div>
                </div>
            </div>
            </div>

            <!-- New Tracking Section -->
            <div class="card mb-4">
            <div class="card-header">
                <i class="bi bi-geo-alt"></i> Live Shipment Tracking
            </div>
            <div class="card-body">
                <div class="row">
                <div class="col-md-8">
                    <div class="map-container" ref="mapContainer"></div>
                </div>
                <div class="col-md-4">
                    <div class="tracking-details">
                    <h5>Shipment Details</h5>
                    <div v-if="activeShipment">
                        <p><strong>ID:</strong> {{ activeShipment.id }}</p>
                        <p><strong>Status:</strong> 
                        <span class="badge" :class="statusClass(activeShipment.status)">
                            {{ activeShipment.status }}
                        </span>
                        </p>
                        <p><strong>Estimated Delivery:</strong> 
                        {{ formatDate(activeShipment.eta) }}
                        </p>
                        <p><strong>Last Update:</strong> 
                        {{ formatDateTime(activeShipment.lastUpdate) }}
                        </p>
                    </div>
                    <div v-else class="text-muted">
                        Select a shipment to view details
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>

            <!-- Stock Movement Timeline -->
            <div class="card mb-4">
            <div class="card-header">
                <i class="bi bi-clock-history"></i> Movement History
            </div>
            <div class="card-body">
                <div class="timeline">
                <div v-for="(event, index) in movementHistory" 
                    :key="index" 
                    class="timeline-item">
                    <div class="timeline-marker"></div>
                    <div class="timeline-content">
                    <h6>{{ event.type }}: {{ event.product }}</h6>
                    <p class="text-muted small mb-1">
                        {{ formatDateTime(event.timestamp) }}
                    </p>
                    <p class="mb-0">
                        <span class="badge bg-secondary">
                        Qty: {{ event.quantity }}
                        </span>
                        <span class="ms-2">{{ event.location }}</span>
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>


            <!-- Sync Logs -->
            <div class="card mb-2">
                <div class="card-header">
                <i class="bi bi-clock-history"></i> Sync History
                </div>
                <div class="card-body">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th>Timestamp</th>
                        <th>Type</th>
                        <th>Status</th>
                        <th>Details</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="log in syncLogs" :key="log.timestamp">
                        <td>{{ log.timestamp }}</td>
                        <td>{{ log.type }}</td>
                        <td>
                        <span class="badge" :class="log.statusClass">
                            {{ log.status }}
                        </span>
                        </td>
                        <td>{{ log.details }}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </div>
    
        <!-- Error Alert -->
        <div v-if="errorMessage" class="alert alert-danger alert-dismissible fade show fixed-bottom mx-4 mb-4">
            {{ errorMessage }}
            <button type="button" class="btn-close" @click="errorMessage = null"></button>
        </div>
        </div>
    </div>
  </template>

    <script src="./inventoryTracking.js"></script>
    
    <style scoped>
    .inventory-tracking .card {
        box-shadow: 0 2px 6px rgba(0,0,0,0.1);
        transition: transform 0.2s;
    }
    
    .inventory-tracking .card:hover {
        transform: translateY(-2px);
    }
    
    .fixed-bottom {
        position: fixed;
        bottom: 0;
        right: 0;
        width: fit-content;
    }
    
    .table-hover tbody tr:hover {
        background-color: rgba(26, 115, 232, 0.05);
    }
    .map-container {
  height: 400px;
  border-radius: 8px;
  overflow: hidden;
}

.timeline {
  position: relative;
  padding-left: 40px;
}

.timeline-item {
  position: relative;
  margin-bottom: 30px;
}

.timeline-marker {
  position: absolute;
  left: -20px;
  top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #1a73e8;
  border: 3px solid white;
}

.timeline-content {
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #eee;
}

.tracking-details {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
    </style>