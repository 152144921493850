export default {
    props: {
      subContainer:{
        type: Boolean,
        default: false,
      },
      xl: {
        type: Boolean,
        default: false,
      },
      md: {
        type: Boolean,
        default: false,
      },
      isOpen: {
        type: Boolean,
        default: false,
      },
      footerShow:{
        type: Boolean,
        default: false,
      }
    },
    methods: {
      closeSubModal() {
        this.$emit('closeModal');
      }
    }
  };
  