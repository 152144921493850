<template>
    <!-- Navbar Section -->
    <div class="row border-bottom white-bg">
        <nav class="navbar navbar-static-top" role="navigation">
          <div class="landing-nav-header"><img src="../../assets/img/IDSLogo.png" alt="Logo" />
          </div >
                <ul class="d-flex justify-content-end align-items-center flex-grow-1 py-0 px-2 my-0" role="list">
                    <!-- <li>
                        <a href="login.html">
                            <i class="fa fa-sign-out"></i> Log out
                        </a>
                    </li> -->
                    <li
                      class="px-2 px-md-0 user-profile cursor-pointer"
                      aria-label="User profile"
                      tabindex="0"
                    >
                      <a
                        class="user-block px-0"
                        @click.prevent="displayProfile()"
                        data-toggle="tooltip"
                        data-placement="top"
                      >
                        <img
                          :src="usersdetails.picture"
                          class="img-fluid profile-pic"
                          alt="User Profile Image"
                        />
                        <div class="tool-tip">
                          {{ $t("navigations.user profile") }}
                        </div>
                      </a>
                      <div class="profile-block animate fadeIn text-start">
                        <div
                          class="login-user-details d-flex justify-content-start align-items-center"
                        >
                          <div class="login-user-details--image">
                            <img
                              class="profile-pic me-2"
                              :src="usersdetails.picture"
                              alt="User Image"
                            />
                          </div>
                          <div class="login-user-details--details d-flex flex-column">
                            <span
                              ><b>{{ usersdetails.given_name }}</b></span
                            >
                            <span>{{ usersdetails.name }}</span>
                          </div>
                        </div>
                        <div class="user-details d-flex flex-column">
                          <div
                            class="theme-change-block d-flex justify-content-start align-items-center"
                            @click.prevent="$router.push('/profileSettings')"
                          >
                            <img
                              class="me-2"
                              src="../../assets/img/user_profile.png"
                              alt="User Profile"
                            />
                            <span>{{ $t("navigations.profile") }}</span>
                        </div>
                          <div
                            class="theme-change-block d-flex justify-content-start align-items-center"
                            @click="expandSidebar()"
                          >
                            <img
                              class="me-2"
                              src="../../assets/img/user_themes.png"
                              alt="User Themes"
                            />
                            <span class="theme-change-block">{{
                              $t("navigations.themes")
                            }}</span>
                            <div class="theme-menu animated fadeIn">
                              <div class="title">Themes</div>
                              <div
                                class="setings-item default-skin"
                                @click="switchTheme('theme-default')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Default </a>
                                </span>
                              </div>
                              <!-- <div class="setings-item blue-skin" @click="themeBlack()">
                                    <span class="skin-name ">
                                      <a href="#" class="s-skin-1">
                                        Black light
                                      </a>
                                    </span>
                                  </div> -->
                              <div
                                class="setings-item teal-skin"
                                @click="switchTheme('theme-green')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Teal Green </a>
                                </span>
                              </div>
                              <div
                                class="setings-item dusty-skin"
                                @click="switchTheme('theme-violet')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Dusty Lavender </a>
                                </span>
                              </div>
                              <div
                                class="setings-item ocean-skin"
                                @click="switchTheme('theme-ocian')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Ocean Green </a>
                                </span>
                              </div>
                              <div
                                class="setings-item stormy-skin"
                                @click="switchTheme('theme-stormy')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Stormy Cloud </a>
                                </span>
                              </div>
                              <div
                                class="setings-item pastel-skin"
                                @click="switchTheme('theme-pastel')"
                              >
                                <span class="skin-name">
                                  <a href="#" class="md-skin"> Pastel Russet </a>
                                </span>
                              </div>
                            </div>
                          </div>
                          <span class="dropdown-divider"></span>
                          <div
                            class="theme-change-block d-flex justify-content-start align-items-center"
                            @click.prevent="logout"
                          >
                            <img
                              class="me-2"
                              src="../../assets/img/logout.png"
                              alt="Logout"
                            />
                            <span>{{ $t("navigations.logout") }}</span>
                          </div>
                        </div>
                      </div>
                    </li>
                </ul>
        </nav>
    </div>
    <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-12 align-self-center go-back d-flex justify-content-between">
        <h2 class="mt-0 text-grey">SAP S/4HANA Finance</h2>
        <h2 class="mt-0"> <router-link to="/home" class="nav-link p-0" @click.prevent="close">Go to Home</router-link></h2>
      </div>
    </div>
    <!-- Main Content Section -->
    <div class="wrapper wrapper-content animated fadeInRight px-0">
      <div class="container-fluid">
        <div class="row">
            <!-- 2. Left Sidebar -->
            <div class="col-md-3 col-lg-2 sidebar p-2">
                <div class="card mb-3">
                    <div class="card-header bg-primary text-white">
                      Finance Connection
                    </div>
                    <div class="card-body">
                        <div class="mb-3">
                            <input type="text" class="form-control" placeholder="SAP URL">
                        </div>
                        <div class="mb-3">
                            <input type="text" class="form-control" placeholder="Username">
                        </div>
                        <div class="mb-3">
                            <input type="password" class="form-control" placeholder="Password">
                        </div>
                        <select class="form-select mb-3">
                            <option>OAuth 2.0</option>
                            <option>Basic Authentication</option>
                        </select>
                        <button class="btn btn-success w-100">Connect</button>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-header bg-primary text-white">
                        SAP Modules
                    </div>
                    <div class="card-body">
                    <select class="form-select mb-1">
                      <option>SAP S/4HANA Finance</option>
                      <option>SAP GRC (Compliance)</option>
                      <option>SAP EWM (Inventory)</option>
                      <option>SAP Analytics Cloud</option>
                    </select>
                    </div>
                </div>
                <!-- <div class="card mb-3">
                        <div class="card-header bg-primary text-white">
                            Finance Modules
                        </div>
                        <div class="card-body">
                            <ul class="list-group finance-modules">
                                <li class="list-group-item">General Ledger</li>
                                <li class="list-group-item">Accounts Payable</li>
                                <li class="list-group-item">Accounts Receivable</li>
                                <li class="list-group-item">Asset Accounting</li>
                                <li class="list-group-item">Cost Center Accounting</li>
                            </ul>
                        </div>
                    </div> -->
            </div>
            
            <!-- 3. Central Dashboard -->
            <div class="col-md-6 col-lg-8 p-2">
                <div class="d-flex justify-content-between align-items-center mb-4 border-bottom">
                    <h2>Financial Integration Dashboard</h2>
                    <div class="d-flex align-items-center">
                        <div class="integration-status live me-2"></div>
                        <span>Live Sync Active</span>
                    </div>
                </div>

                <div class="row row-cols-1 row-cols-md-3 g-4 mb-4">
                        <div class="col">
                            <div class="card metric-card h-100">
                                <div class="card-body">
                                    <h5 class="card-title">Open Items</h5>
                                    <h2 class="card-text">2,415</h2>
                                    <small class="text-muted">Last 30 days</small>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card metric-card h-100">
                                <div class="card-body">
                                    <h5 class="card-title">Pending Approvals</h5>
                                    <h2 class="card-text text-danger">€152K</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card metric-card h-100">
                                <div class="card-body">
                                    <h5 class="card-title">Cash Position</h5>
                                    <h2 class="card-text text-success">€4.2M</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                        <!-- Transaction Trends Chart -->
                        <div class="card mb-4">
                          <div class="card-header">Transaction Trends</div>
                          <div class="card-body">
                            <apexchart
                              type="line"
                              height="350"
                              :options="transactionOptions"
                              :series="transactionSeries"
                            ></apexchart>
                          </div>
                        </div>

                        <!-- Inventory Status Chart -->
                        <div class="card mb-4">
                          <div class="card-header">Inventory Status</div>
                          <div class="card-body">
                            <apexchart
                              type="bar"
                              height="350"
                              :options="inventoryOptions"
                              :series="inventorySeries"
                            ></apexchart>
                          </div>
                        </div>

                        <!-- Data Flow Visualization (keep your existing SVG) -->
                        <div class="card">
                          <div class="card-header">Data Flow Monitoring</div>
                          <div class="card-body d-flex justify-content-center">
                            <image href="../../assets/img/IDS_small_Logo.png" x="0" y="60" width="60" height="60"/>
                            <image href="/path/to/sap-icon.png" x="350" y="60" width="60" height="60"/>
                            <svg id="dataFlow" width="100%" height="200" role="img" aria-labelledby="dataFlowTitle dataFlowDesc">
                                <title id="dataFlowTitle">Real-time Data Flow Between CTMS and SAP Systems</title>
                                <desc id="dataFlowDesc">Visualization showing bidirectional data synchronization between Clinical Trial Management System and SAP modules</desc>
                                
                                <!-- Arrowhead Definition -->
                                <defs>
                                    <marker id="arrowhead" 
                                            viewBox="0 0 10 10" 
                                            refX="9" 
                                            refY="5" 
                                            markerWidth="8" 
                                            markerHeight="8"
                                            orient="auto">
                                        <path d="M 0 0 L 10 5 L 0 10 z" fill="#1a73e8" />
                                    </marker>
                                </defs>

                                <!-- CTMS to SAP Data Flow -->
                                <path class="data-flow-arrow"
                                      d="M 50 80 L 350 80"
                                      stroke="#1a73e8"
                                      stroke-width="2"
                                      fill="none"
                                      marker-end="url(#arrowhead)"
                                      aria-label="Data flow from CTMS to SAP"/>

                                <!-- SAP to CTMS Data Flow -->
                                <path class="data-flow-arrow"
                                      d="M 350 120 L 50 120"
                                      stroke="#1a73e8"
                                      stroke-width="2"
                                      fill="none"
                                      marker-end="url(#arrowhead)"
                                      aria-label="Data flow from SAP to CTMS"/>

                                <!-- System Icons -->
                                <g role="list">
                                    <!-- CTMS System -->
                                    <g role="listitem" transform="translate(0 60)">
                                        <rect x="0" y="0" width="60" height="60" fill="#f0f0f0" rx="8"/>
                                        <text x="30" y="35" text-anchor="middle" fill="#1a73e8" font-size="14" font-weight="500">CTMS</text>
                                    </g>

                                    <!-- SAP System -->
                                    <g role="listitem" transform="translate(350 60)">
                                        <rect x="0" y="0" width="60" height="60" fill="#f0f0f0" rx="8"/>
                                        <text x="30" y="35" text-anchor="middle" fill="#1a73e8" font-size="14" font-weight="500">SAP</text>
                                    </g>
                                </g>
                            </svg>
                          </div>
                        </div>
            </div>

            <!-- 4. Right Sidebar -->
            <div class="col-md-3 col-lg-2 sidebar p-2">
                <div class="card">
                    <div class="card-header bg-primary text-white">
                        Quick Actions
                    </div>
                    <div class="text-muted p-2">
                    Last Sync: {{ lastSync }}
                    </div>
                    <div class="card-body">
                        <button class="btn btn-outline-primary w-100 mb-2">
                            <i class="bi bi-arrow-repeat"></i> Sync Now
                        </button>
                        <button class="btn btn-outline-secondary w-100">
                            <i class="bi bi-file-earmark-arrow-down"></i> Export
                        </button>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header bg-warning">
                        Recent Alerts
                    </div>
                    <div class="card-body alert-log">
                        <div class="alert alert-danger mb-2">
                            <small>2023-10-05 14:30<br>Sync Failure - SAP EWM</small>
                        </div>
                        <div class="alert alert-danger mb-2">
                            <small>2023-10-05 12:30<br>Sync Failure - SAP EWM</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div> 
    </div>
</template>

<script src="./sapConnection.js"></script>
<style>
.profile-block {
      position: absolute;
      color: var(--bs-dark);
      font-family: var(--bs-font-sans-serif);
      background-color: #ffffff;
      width: max-content;
      border: medium none;
      border-radius: 3px;
      display: none;
      font-size: 12px;
      top: 60px;
      right: 10px;
      text-shadow: none;
      z-index: 1000;
      box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15),
      0 4.5px 13.5px rgba(0, 0, 0, 0.08);
      -webkit-box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15),
      0 4.5px 13.5px rgba(0, 0, 0, 0.08);
      -moz-box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15),
      0 4.5px 13.5px rgba(0, 0, 0, 0.08);
      }
.user-profile:hover .tool-tip{
      visibility: visible;
      -webkit-transition: visibility 30ms ease-in;
      transition: visibility 30ms ease-in;
      }

.landing-nav-header{
      padding:14px 25px;
      background-color: #f2f2f2;
      }

:root {
            --sap-blue: #0f1c3d;
            --highlight: #1a73e8;
        }
        
.navbar-custom {
            background: var(--sap-blue);
            padding: 0.8rem 1rem;
        }
        
.sidebar {
            background: #f8f9fa;
            height: calc(100vh - 100px);
            
        }
        
.metric-card {
            border-left: 4px solid var(--highlight);
            transition: transform 0.2s;
        }
        
.metric-card:hover {
            transform: translateY(-3px);
        }
        
.alert-log {
            max-height: 300px;
            overflow-y: auto;
        }
        
.integration-status {
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }
        
.live {
            background: #28a745;
            animation: pulse 1.5s infinite;
        }
        
        @keyframes pulse {
            0% { box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4); }
            70% { box-shadow: 0 0 0 10px rgba(40, 167, 69, 0); }
            100% { box-shadow: 0 0 0 0 rgba(40, 167, 69, 0); }
        }
.data-flow-arrow {
            stroke: #1a73e8;
            stroke-width: 2;
			fill: none;
            marker-end: url(#arrowhead);
        }
        @keyframes flow {
            0% { stroke-dashoffset: 100; }
            100% { stroke-dashoffset: 0; }
        }.data-flow-arrow {
    stroke-dasharray: 10 5;
    animation: flow 3s linear infinite;
}

@keyframes flow {
    0% { stroke-dashoffset: 100; }
    100% { stroke-dashoffset: 0; }
}

.apexcharts-tooltip {
  background: #fff !important;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.apexcharts-menu {
  border: 1px solid #eee !important;
}
.go-back a{
  color:#676a6c;
  font-size: 13px;
  font-weight: 600;
}
.sap-card-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}
.alert {
    position: relative;
    padding: .3rem .5rem !important;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
</style>