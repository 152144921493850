import axios from "axios";
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre"; // Import the Dagre extension
import store from "@/store";
import klay from "cytoscape-klay";
import graph from "../../custom_lib/graph_lib/graph.vue"

cytoscape.use(dagre); // Register Dagre with Cytoscape
cytoscape.use(klay);

export default {
  name: "NetworkXGraph",
  components: {
    graph,
  },
  data() {
    return {
      cy: null,
      authToken: store.getters.getIdToken,
      rootId: null,
      templatedetails: [],
      selectedgraphid: "",
      // Default layout is "dagre"
      selectedLayout: "dagre",
      baseurl: process.env.VUE_APP_Service_URL,
      // For popup menu handling:
      showNodePopup: false,
      popupPosition: { x: 0, y: 0 },
      selectedNode: null,
      nodeproperties: null,
      detailspopup: false,
      addpropertiespopup: false,
      propname: "",
      propvalue: "",
      creategraphpopup: false,
      newgraphname: "",
      addnodepopup: false,
      subnodename: "",
      addarraypopup: false,
      subnodearrayname: "",
      arrayelementspopup: false,
      arrayelementname: "",
      graphdata:{},
      menuitems:["expandNode","collapseNode","addSubNode","deleteNode","showdetails"]
    };
  },
  computed: {
    popupStyle() {
      return {
        position: "absolute",
        top: this.popupPosition.y + "px",
        left: this.popupPosition.x + "px",
        zIndex: 9999,
        background: "#fff",
        border: "1px solid #ccc",
        padding: "5px",
      };
    },
  },
  async mounted() {
    this.loadGraphDetails();
  },
  methods: {
    async createGraph() {
      this.creategraphpopup = true;
    },
    async closecreategraph() {
      this.creategraphpopup = false;
    },
    async createGraphapi() {
      if (this.newgraphname === "") {
        alert("Please enter graph name");
      } else {
        const newgraphdata = {
          name: this.newgraphname,
        };
        await axios
          .post(`${this.baseurl}/stats-python/graph/creategraph`, newgraphdata, {
            headers: {
              Authorization: "Bearer " + this.authToken,
              "Content-Type": "application/json",
            },
          })
          .then(async (res) => {
            console.log(res.data._id);
            await this.loadGraphDetails();
            if (res.data._id) {
              this.selectedgraphid = res.data._id;
            }
            this.loadGraph();
            this.closecreategraph();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async addproperties() {
      this.addpropertiespopup = true;
    },
    async cloaseaddproperties() {
      this.addpropertiespopup = false;
    },
    async addprpopertiestodb() {
      const nodeId = this.selectedNode.data("id");
      if (this.propname === "" || this.propvalue === "") {
        alert("Please enter property name and property value");
      } else {
        const propobj = {
          [this.propname]: this.propvalue,
        };
        const propdata = {
          graph_id: this.selectedgraphid,
          node_id: nodeId,
          properties: propobj,
        };
        console.log("add properties", propobj);
        await axios
          .post(
            `${this.baseurl}/stats-python/graph/add-node-properties`,
            propdata,
            {
              headers: {
                Authorization: "Bearer " + this.authToken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.propname = "";
            this.propvalue = "";
            this.cloaseaddproperties();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async loadGraphDetails() {
      try {
        const res = await axios.get(
          `${this.baseurl}/stats-python/graph/getallgraphs`,
          {
            headers: {
              Authorization: "Bearer " + this.authToken,
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data) {
          this.templatedetails = res.data;
          this.selectedgraphid = this.templatedetails[0].id;
          this.loadGraph();
        }
      } catch (err) {
        console.error("Error loading graph details:", err);
      }
    },
    closedetailsModal() {
      this.detailspopup = false;
    },
    async loadGraph() {
      try {
        // Get full tree data from backend.
        const graphapiresult = await axios.get(
          `${this.baseurl}/stats-python/graph/gettreerepodata?graphid=${this.selectedgraphid}`,
          {
            headers: { Authorization: `Bearer ${this.authToken}` },
          }
        );
        this.graphdata = graphapiresult.data;
      } catch (error) {
        console.error("Error loading graph:", error);
      }
    },
    async isValidCssColor(strColor) {
      const s = new Option().style;
      s.color = strColor;
      // If the browser recognized the color, it will normalize it to a valid string.
      return s.color === strColor.toLowerCase();
    },
    /**
     * Convert NetworkX node-link JSON into Cytoscape elements.
     */
    convertNodeLinkToCytoscape(data) {
      const elements = [];
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      if (data.nodes && data.links) {
        data.nodes.forEach((node) => {
          const expanded = node.expanded || false;
          let collapsible = node.collapsible || false;
          let color = "#0074D9"; // default color
          if (node.properties.color && this.isValidCssColor(node.color)) {
        color = node.properties.color;
      }
          if (uuidRegex.test(node.id)) {
            collapsible = false;
          }
          elements.push({
            data: {
              id: node.id,
              label: node.label || node.data || node.id,
              collapsible: collapsible,
              expanded: expanded,
              type: node.type || null,
              color: color,
              ...node,
            },
          });
        });
        data.links.forEach((link) => {
          const linkId = link.id || `${link.source}-${link.target}`;
          elements.push({
            data: {
              id: linkId,
              source: link.source,
              target: link.target,
              label: link.label || "",
            },
          });
        });
      } else {
        // If the backend returns a different structure, handle that here.
        return data;
      }
      return elements;
    },

    // Hide the popup menu.
    hidePopupMenu() {
      this.showNodePopup = false;
    },
    async showdetails(selectedNode) {
      const nodeId = selectedNode.data("id");
      this.nodeproperties = await axios.get(
        `${this.baseurl}/stats-python/graph/getproperties?graphid=${this.selectedgraphid}&childnodeid=${nodeId}`,
        {
          headers: { Authorization: `Bearer ${this.authToken}` },
        }
      );
      if (this.nodeproperties.data.error) {
        console.error(this.nodeproperties.data.error);
        return;
      }
      this.detailspopup = true;
    },

    // Expand the selected node by fetching its child nodes from the backend.
    async expandNode(selectedNode) {
      if (!selectedNode) return;
      if (selectedNode.data("expanded")) {
        this.hidePopupMenu();
        return;
      }
      try {
        const nodeId = selectedNode.data("id");
        const childResponse = await axios.get(
          `${this.baseurl}/stats-python/graph/getchildnodes?graphid=${this.selectedgraphid}&childnodeid=${nodeId}`,
          {
            headers: { Authorization: `Bearer ${this.authToken}` },
          }
        );
        if (childResponse.data.error) {
          console.error(childResponse.data.error);
          return;
        }
        return childResponse;
      } catch (error) {
        console.error("Error expanding node:", error);
        return;
      }
    },

    // Collapse the selected node by recursively removing all descendant nodes.
    collapseNode() {
      if (!this.selectedNode) return;

      const removeDescendants = (node) => {
        const children = node.outgoers("node");
        children.forEach((child) => {
          removeDescendants(child);
          child.remove();
        });
      };

      removeDescendants(this.selectedNode);
      this.selectedNode.data("expanded", false);

      // Re-run the layout using the current selected layout.
      this.cy.layout(this.getLayoutConfig()).run();
      this.hidePopupMenu();
    },

    // --- NEW METHOD: Sort all subnodes at every level ---
    sortAllNodes() {
      if (!this.cy || !this.rootId) {
        console.warn("Cytoscape or rootId not set yet.");
        return;
      }
    
      // Example: using breadthfirst layout for sorting; you can adjust as needed.
      this.cy.layout({
        name: "breadthfirst",
        roots: [this.rootId],
        directed: false,
        fit: true,
        circle: false,
        spacingFactor: 1.2,
        padding: 30,
        sort: (a, b) => {
          const aLabel = String(a.data("label")).toLowerCase();
          const bLabel = String(b.data("label")).toLowerCase();
          if (!isNaN(aLabel) && !isNaN(bLabel)) {
            return parseFloat(aLabel) - parseFloat(bLabel);
          }
          return aLabel.localeCompare(bLabel);
        },
        animate: true,
        animationDuration: 500
      }).run();
      
      this.hidePopupMenu();
    },

    addArrayelements() {
      this.arrayelementspopup = true;
    },
    closeaddarrayelementspopup() {
      this.arrayelementspopup = false;
    },
    async addarrayelementapi() {
      const nodeId = this.selectedNode.data("id");
      if (this.arrayelementname === "") {
        alert("Please enter node name");
      } else {
        const newarrayelementdata = {
          element_name: this.arrayelementname,
        };
        await axios
          .post(
            `${this.baseurl}/stats-python/graph/graphs/${this.selectedgraphid}/nodes/${nodeId}/array-elements`,
            newarrayelementdata,
            {
              headers: {
                Authorization: "Bearer " + this.authToken,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (res) => {
            console.log(res.data);
            await this.loadGraph();
            await this.closeaddarrayelementspopup();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addArray() {
      this.addarraypopup = true;
      this.hidePopupMenu();
    },
    closeaddarraypopup() {
      this.addarraypopup = false;
    },
    async addarrayeapi() {
      const nodeId = this.selectedNode.data("id");
      if (this.subnodearrayname === "") {
        alert("Please enter node name");
      } else {
        const newnodedata = {
          subnodename: this.subnodearrayname,
        };
        await axios
          .post(
            `${this.baseurl}/stats-python/graph/graphs/${this.selectedgraphid}/nodes/${nodeId}/array-subnodes`,
            newnodedata,
            {
              headers: {
                Authorization: "Bearer " + this.authToken,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (res) => {
            console.log(res.data);
            await this.loadGraph();
            await this.closeaddnodepopup();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addSubNode() {
      this.addnodepopup = true;
      this.hidePopupMenu();
    },
    closeaddnodepopup() {
      this.addnodepopup = false;
    },
    async addsubnodeapi(subnodename, selectedNode) {
      console.log("selectedNode",selectedNode);
      const nodeId = selectedNode.data("id");
      if (subnodename === "") {
        alert("Please enter node name");
      } else {
        const newnodedata = {
          subnodename: subnodename,
        };
        await axios
          .post(
            `${this.baseurl}/stats-python/graph/graphs/${this.selectedgraphid}/nodes/${nodeId}/subnodes`,
            newnodedata,
            {
              headers: {
                Authorization: "Bearer " + this.authToken,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (res) => {
            console.log(res.data);
            await this.loadGraph();
            await this.closeaddnodepopup();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // Delete the selected node.
    async deleteNode(selectedNode) {
      const nodeId = selectedNode.data("id");
      await axios
        .delete(
          `${this.baseurl}/stats-python/graph/graphs/${this.selectedgraphid}/nodes/${nodeId}`,
          {
            headers: {
              Authorization: "Bearer " + this.authToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          console.log(res.data);
          await this.loadGraph();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // --- NEW HELPER: Return layout configuration based on selected layout ---
    getLayoutConfig() {
      let layoutConfig = {};
      switch (this.selectedLayout) {
        case "dagre":
          layoutConfig = {
            name: "dagre",
            rankDir: "TB",  // Top to Bottom
            nodeSep: 50,
            rankSep: 100,
            edgeSep: 10,
            ranker: "longest-path",
            animate: true,
            animationDuration: 500,
          };
          break;
        case "breadthfirst":
          layoutConfig = {
            name: "breadthfirst",
            roots: [this.rootId],
            directed: false,
            fit: true,
            circle: false,
            spacingFactor: 1.2,
            padding: 30,
            animate: true,
            animationDuration: 500,
          };
          break;
        case "grid":
          layoutConfig = {
            name: "grid",
            rows: Math.ceil(Math.sqrt(this.cy ? this.cy.elements().length : 1)),
            animate: true,
            animationDuration: 500,
          };
          break;
        case "circle":
          layoutConfig = {
            name: "circle",
            animate: true,
            animationDuration: 500,
          };
          break;
        case "cose":
          layoutConfig = {
            name: "cose",
            animate: true,
            animationDuration: 500,
          };
          break;
          case "klay":
      layoutConfig = {
        name: "klay",
        nodeDimensionsIncludeLabels: true, // so the layout considers label size
        fit: true,
        padding: 20,
        klay: {
          direction: "DOWN",
          spacing: 40,
        },
        animate: true,
        animationDuration: 500,
      };
      break;
        default:
          layoutConfig = {
            name: "dagre",
            rankDir: "TB",
            nodeSep: 50,
            rankSep: 100,
            edgeSep: 10,
            ranker: "longest-path",
            animate: true,
            animationDuration: 500,
          };
          break;
      }
      return layoutConfig;
    },

    // --- NEW METHOD: Apply the current layout to the graph ---
    applyLayout() {
      if (this.cy) {
        this.cy.layout(this.getLayoutConfig()).run();
        if (this.selectedLayout === "klay") {
          this.cy.nodes().style("shape", "rectangle");
        } else {
          // For all other layouts, use the default circle (ellipse) shape.
          this.cy.nodes().style("shape", "ellipse");
        }
      }
    },
  },
};
