<template>
  <div class="body-small pace-done" :class="currentTheme">
    <div id="wrapper">
      <nav
        v-if="authenticated && getNavbarstatus && rollsprivilages != undefined"
        id="mobilemenu"
        class="navbar-default navbar-static-side"
        role="navigation"
      >
        <div>
          <ul class="nav metismenu" id="side-menu">
            <li class="nav-header">
              <div class="dropdown profile-element mb-1">
                <img src="../../assets/img/IDSLogo.png" alt="Logo" />
              </div>
              <div class="logo-element">
                <img src="../../assets/img/IDS_small_Logo.png" alt="Logo" />
              </div>
            </li>
            <li class="main_hub_li">
              <router-link
                to="/landingPage"
                class="nav-link"
                @click.prevent="returnToLanding"
              >
                <span v-if="notcollapsed">{{ t("navigations.go to main hub") }} </span>
              </router-link>
            </li>
            <li
              v-if="
                rollsprivilages.includes('Mainmenu Home') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <router-link
                :to="'/' + defaultdashbord"
                class="nav-link"
                @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/home_n.png"
                  alt="Home Menu"
                />
                <span v-if="notcollapsed">{{ t("navigations.home") }} </span>
              </router-link>
            </li>
            <li v-if="getDefaultMenu == 'Stats'">
              <router-link to="/tocgenerate" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/analysis_tree.png"
                  alt="Analysis Menu"
                />
                
                <span v-if="notcollapsed">{{ t("navigations.analysis tree")  }}</span>
              </router-link>
            </li>
            <li v-if="getDefaultMenu == 'Stats'">
              <router-link to="/tocgenerate" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/output_tree.png"
                  alt="Output Menu"
                />
                
                <span v-if="notcollapsed">{{ t("navigations.output tree")  }}</span>
              </router-link>
            </li>
            <li v-if="getDefaultMenu == 'Stats'">
              <router-link to="/Macro_Management" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/macro_management.png"
                  alt="Macro Management Menu"
                />
                
                <span v-if="notcollapsed">{{ "Functions"  }}</span>
              </router-link>
            </li>
            <li v-if="getDefaultMenu == 'Stats'">
              <router-link to="/filediffpage" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/view_form.png"
                  alt="Macro Management Menu"
                />
                
                <span v-if="notcollapsed">{{ "Diff Viewer"  }}</span>
              </router-link>
            </li>
            <li
              v-if="
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <router-link
                to="/uploadstudyprotocol"
                class="nav-link"
                @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/upload_study_protocol.png"
                  alt="Upload Study Menu"
                />
                <span v-if="notcollapsed"
                  >{{ t("navigations.protocol data import") }}
                </span>
              </router-link>
            </li>
            <li
              v-if="
                rollsprivilages.includes('Mainmenu Study') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <a href="#" @click.prevent="expandfun('studysetup', 0, 'mainmenu')"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/study_n.png"
                  alt="Study Menu" />
                <span class="nav-label">{{ t("navigations.study") }} </span>
                <span
                  class="fa fa-angle-left angle-down-right mt-1"
                  :class="{
                    'float-right': isActive,
                    'rotate-down': !mainmenu[0],
                  }"
                >
                </span
              ></a>
              <ul id="studysetup" class="nav nav-second-level collapse">
                <li
                  class="submenuitem"
                >
                  <router-link to="/soa" class="nav-link" @click.prevent="close">
                    <img
                      class="me-1 mb-1"
                      src="../../assets/img/Soa.png"
                      alt="study profile Menu"
                    />
                    <span v-if="notcollapsed">SoA </span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Study Menu Study Profile')"
                >
                  <router-link to="/studyProfile" class="nav-link" @click.prevent="close">
                    <img
                      class="me-1 mb-1"
                      src="../../assets/img/study_profile_n.png"
                      alt="study profile Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.study profile") }} </span>
                  </router-link>
                </li>
                <li
                  class="fullwidthquery"
                  @mouseleave="mouseItemLeave('itemsetup')"
                  v-if="rollsprivilages.includes('Study Menu Items')"
                >
                  <a
                    href="#"
                    class="fullwidthquery"
                    @click.prevent="expandfun('itemsetup', 0, 'submenu')"
                  >
                    <img
                      class="me-1 mb-1"
                      src="../../assets/img/Items_n.png"
                      alt="Item menu" />
                    <span class="nav-label">{{ t("navigations.study components") }} </span
                    ><span
                      class="fa fa-angle-left angle-down-right mt-1"
                      :class="{
                        'float-right': isActive,
                        'rotate-down': submenu[0] == true,
                      }"
                    ></span
                  ></a>
                  <ul
                    id="itemsetup"
                    class="nav nav-second-level collapse"
                    v-if="isActive"
                  >
                    <li
                      class="submenuitem"
                      v-if="
                        rollsprivilages.includes('Study Components Menu Form Summary')
                      "
                    >
                      <router-link
                        to="/formSummary"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 mb-1 thirdlevel" src="../../assets/img/form_summary_n.png" alt="Form Summary Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.form summary")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="rollsprivilages.includes('Study Components Menu Sites')"
                    >
                      <router-link to="/sitelist" class="nav-link" @click.prevent="close">
                        <!-- <img class="me-1 mb-1 thirdlevel" src="../../assets/img/sites_n.png" alt="Sites Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed"
                          >{{ t("navigations.sites") }}
                        </span>
                      </router-link>
                    </li>

                    <li
                      class="submenuitem"
                      v-if="rollsprivilages.includes('Study Components Menu Subjects')"
                    >
                      <router-link
                        to="/patientlist"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/subject.png" alt="Subjects Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed"
                          >{{ t("navigations.subjects") }}
                        </span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="rollsprivilages.includes('Study Components Menu E-Consent')"
                    >
                      <router-link
                        to="/eConsentList"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/subject.png" alt="Subjects Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed"
                          >{{ t("navigations.e-consent") }}
                        </span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="rollsprivilages.includes('Study Components Menu Visits')"
                    >
                      <router-link
                        to="/visitlist"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/visits.png" alt="Visits Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.visits")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="
                        rollsprivilages.includes('Study Components Menu Visit Templates')
                      "
                    >
                      <router-link
                        to="/formTemplates"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/study_template.png" alt="Subjects Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.visit forms")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="
                        rollsprivilages.includes('Study Components Menu Submitted Forms')
                      "
                    >
                      <router-link to="/listform" class="nav-link" @click.prevent="close">
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/forms.png" alt="Form Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.submitted forms")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="
                        rollsprivilages.includes('Study Components Menu Unassigned Form')
                      "
                    >
                      <router-link
                        to="/displayform"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/assign-form.png" alt="Assign Form Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.unassigned forms")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="
                        rollsprivilages.includes(
                          'Study Components Menu Partially Filled Forms'
                        )
                      "
                    >
                      <router-link
                        to="/reviewFormList"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/assign_form.png" alt="Form Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.partially filled forms")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="
                        !rollsprivilages ||
                        rollsprivilages.includes('Study Components Menu Users')
                      "
                    >
                      <router-link
                        to="/StudyBasedUserList"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/users.png" alt="Users Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.users")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="
                        rollsprivilages.includes('Study Components Menu Archived Forms')
                      "
                    >
                      <router-link to="/Archive" class="nav-link" @click.prevent="close">
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/archive.png" alt="Form Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.archived forms")
                        }}</span>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <!-- query test starts -->
                <li
                  class="fullwidthquery"
                  @mouseleave="mouseItemLeave('querysetup')"
                  v-if="rollsprivilages.includes('Study Menu Queries')"
                >
                  <a
                    href="#"
                    class="fullwidthquery"
                    @click.prevent="expandfun('querysetup', 1, 'submenu')"
                    ><img
                      class="me-1 mb-0"
                      src="../../assets/img/queries_n.png"
                      alt="QueryMenu" />
                    <span class="nav-label">{{ t("navigations.queries") }} </span
                    ><span
                      class="fa fa-angle-left angle-down-right mt-1"
                      :class="{
                        'float-right': isActive,
                        'rotate-down': submenu[1] == true,
                      }"
                    ></span
                  ></a>
                  <ul
                    id="querysetup"
                    class="nav nav-second-level collapse"
                    v-if="isActive"
                  >
                    <li
                      v-if="rollsprivilages.includes('Query Menu Sent')"
                      class="submenuitem"
                    >
                      <router-link
                        to="/sentQueryList"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/sent_queries_n.png" alt="Sent Query Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.sent")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      v-if="rollsprivilages.includes('Query Menu Received')"
                      class="submenuitem"
                    >
                      <router-link
                        to="/queryList"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/received_query_dark_n.png" alt="Received Query Menu" /> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.received")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      v-if="rollsprivilages.includes('Query Menu All Queries')"
                      class="submenuitem"
                    >
                      <router-link
                        to="/allQueryList"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/total_queries_n.png" alt="All Query Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.all queries")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      v-if="rollsprivilages.includes('Query Menu General')"
                      class="submenuitem"
                    >
                      <router-link
                        to="/general_querylist"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/general_query_nav_n.png" alt="General Query Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.general")
                        }}</span>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <!-- query test ends -->
                <li
                  class="fullwidthquery"
                  @mouseleave="mouseItemLeave('formdesignersetup')"
                  v-if="rollsprivilages.includes('Study Menu Form Designer')"
                >
                  <a
                    href="#"
                    class="fullwidthquery"
                    @click.prevent="expandfun('formdesignersetup', 4, 'submenu')"
                  >
                    <img
                      class="me-1"
                      src="../../assets/img/form_designer_n.png"
                      alt="Transfer"
                    />
                    <span
                      class="fa fa-angle-left angle-down-right mt-1"
                      :class="{
                        'float-right': isActive,
                        'rotate-down': submenu[4] == true,
                      }"
                    >
                    </span>
                    <span class="nav-label">{{ t("navigations.form designer") }} </span>
                  </a>
                  <ul
                    id="formdesignersetup"
                    class="nav nav-second-level collapse"
                    v-if="isActive"
                  >
                    <li
                      class="submenuitem"
                      v-if="rollsprivilages.includes('Form Designer Menu Designer Tool')"
                    >
                      <router-link
                        to="/formdesigner"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/designer_tool.svg" alt="Template Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.designer tool")
                        }}</span>
                      </router-link>
                    </li>
                    <!-- <li  class="submenuitem" v-if="rollsprivilages.includes('Form Designer Menu Designer Tool')">
                      <router-link to="/mlCriteriaCapture" class="nav-link" @click.prevent="close">
                        <img
                        class="me-1 thirdlevel"
                          src="../../assets/img/designer_tool.svg"
                          alt="Template Menu"
                        />
                        <span thirdlevel v-if="notcollapsed">{{ t("navigations.ai tool") }}</span>
                      </router-link>
                    </li> -->

                    <li
                      class="submenuitem"
                      v-if="rollsprivilages.includes('Form Designer Menu Templates')"
                    >
                      <router-link
                        to="/templatelist"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/designer_tool.svg" alt="Template Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed"
                          >{{ t("navigations.forms") }}
                        </span>
                      </router-link>
                    </li>

                    <li
                      class="submenuitem"
                      v-if="
                        rollsprivilages.includes('Form Designer Menu Approve Template')
                      "
                    >
                      <router-link
                        to="/digitalform"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img class="me-1 thirdlevel" src="../../assets/img/designer_tool.svg" alt="Template Menu"/> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.approve forms")
                        }}</span>
                      </router-link>
                    </li>
                  </ul>
                </li>
                <li
                  class="fullwidthquery"
                  @mouseleave="mouseItemLeave('studyadministrationsetup')"
                  v-if="rollsprivilages.includes('Study Menu Study Administration')"
                >
                  <a
                    href="#"
                    class="fullwidthquery"
                    @click.prevent="expandfun('studyadministrationsetup', 3, 'submenu')"
                  >
                    <img
                      class="me-1 mb-1"
                      src="../../assets/img/study_administration_n.png"
                      alt="Transfer"
                    />
                    <span
                      class="fa fa-angle-left angle-down-right mt-1"
                      :class="{
                        'float-right': isActive,
                        'rotate-down': submenu[3] == true,
                      }"
                    ></span>
                    <span class="nav-label"
                      >{{ t("navigations.study administration") }}
                    </span>
                  </a>
                  <ul
                    id="studyadministrationsetup"
                    class="nav nav-second-level collapse"
                    v-if="isActive"
                  >
                    <li
                      class="submenuitem"
                      v-if="
                        rollsprivilages.includes(
                          'Study Administration Menu Form Transfer'
                        )
                      "
                    >
                      <router-link
                        to="/formtransfer"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img
                        class="me-1 thirdlevel"
                          src="../../assets/img/formtransfer.png"
                          alt="Transfer"
                        /> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          $t("navigations.form transfer")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="
                        rollsprivilages.includes(
                          'Study Administration Menu Subject Transfer'
                        )
                      "
                    >
                      <router-link
                        to="/TransferSubject"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img
                          class="me-1 thirdlevel"
                          src="../../assets/img/patient_transfer.png"
                          alt="Transfer"
                        /> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed"
                          >{{ $t("navigations.subject transfer") }}
                        </span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="
                        rollsprivilages.includes(
                          'Study Administration Menu Subject Schedule'
                        )
                      "
                    >
                      <router-link
                        to="/PatientScheduleView"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img
                        class="me-1 thirdlevel"
                          src="../../assets/img/subject_schedule.png"
                          alt="Subject Schedule Menu"
                        /> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.subject schedule")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="
                        rollsprivilages.includes(
                          'Study Administration Menu Patient Login'
                        )
                      "
                    >
                      <router-link
                        to="/patientLoginList"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img
                        class="me-1 thirdlevel"
                          src="../../assets/img/Patient_login.png"
                          alt="Subjects Login Menu"
                        /> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed"
                          >{{ t("navigations.patient login") }}
                        </span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="
                        !rollsprivilages ||
                        rollsprivilages.includes(
                          'Study Administration Menu Unscheduled Visits'
                        )
                      "
                    >
                      <router-link
                        to="/unscheduledvisits"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img
                    class="me-1 thirdlevel"
                      src="../../assets/img/unscheduled_visits.png"
                      alt="Unscheduled Visits Menu"
                    /> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.unscheduled visits")
                        }}</span>
                      </router-link>
                    </li>
                    <li
                      class="submenuitem"
                      v-if="
                        rollsprivilages.includes(
                          'Study Administration Menu Change Schedule Template'
                        )
                      "
                    >
                      <router-link
                        to="/visittemplate"
                        class="nav-link"
                        @click.prevent="close"
                      >
                        <!-- <img
                      class="me-1 thirdlevel"
                      src="../../assets/img/visits_templates.png"
                      alt="Visit Templates Menu"
                    /> -->
                        <span class="me-1 mb-1 thirdlevel" v-if="notcollapsed">{{
                          t("navigations.change schedule form")
                        }}</span>
                      </router-link>
                    </li>
                  </ul>
                </li>

                <!-- <li class="submenuitem" v-if="rollsprivilages.includes('Study Reports')">
                  <router-link
                    to="/reportdashboard"
                    class="nav-link"
                    @click.prevent="close"
                    ><img class="me-1" src="../../assets/img/reports.png" alt="Reports Menu">
                    <span v-if="notcollapsed">{{ t('navigations.reports') }}</span>
                  </router-link>
                </li> -->
              </ul>
            </li>

            <li
              v-if="
                rollsprivilages.includes('Mainmenu AI/ML') && getDefaultMenu == 'AI/Ml'
              "
            >
              <a href="#" @click.prevent="expandfun('aiscreen', 5, 'mainmenu')"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/ai_ml.png"
                  alt="Home Menu" />
                <span class="nav-label">{{ t("navigations.ai/ml") }}</span
                ><span
                  class="fa fa-angle-left angle-down-right mt-1"
                  :class="{
                    'float-right': isActive,
                    'rotate-down': !mainmenu[5],
                  }"
                ></span
              ></a>
              <ul id="aiscreen" class="nav nav-second-level collapse">
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('AI/ML Menu Patient Screening')"
                >
                  <router-link
                    to="/PatientScreeningReport"
                    class="nav-link"
                    @click.prevent="close"
                    ><img
                      class="me-1 mb-1"
                      src="../../assets/img/patient_screening_n.png"
                      alt="Submit Form Menu"
                    />
                    <span v-if="notcollapsed">{{
                      t("navigations.subject screening")
                    }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('AI/ML Menu Patient Retention')"
                >
                  <router-link
                    to="/patientRetention"
                    class="nav-link"
                    @click.prevent="close"
                    ><img
                      class="me-1"
                      src="../../assets/img/patient_retention_n.png"
                      alt="Patient retention "
                    />
                    <span v-if="notcollapsed"
                      >{{ t("navigations.subject retention") }}
                    </span>
                  </router-link>
                </li>
              </ul>
            </li>
            <!-- <li
              class="submenuitem"
              v-if="rollsprivilages.includes('Mainmenu Study Data Analysis')"
            >
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/Study_data_analysis.png"
                  alt="Study Menu"
                />
                <span v-if="notcollapsed">{{ t("navigations.study data analysis") }}</span>           
            </li> -->
            <li
              v-if="
                rollsprivilages.includes('Mainmenu Study Data Analysis') &&
                getDefaultMenu == 'AI/Ml'
              "
              class="fullwidthquery"
              @mouseleave="mouseItemLeave('patentsurvival')"
            >
              <a href="#" @click.prevent="expandfun('patentsurvival', 6, 'mainmenu')"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/Study_data_analysis.png"
                  alt="StudyData" />
                <span class="nav-label">{{ t("navigations.study data analysis") }} </span
                ><span
                  class="fa fa-angle-left angle-down-right mt-1"
                  :class="{
                    'float-right': isActive,
                    'rotate-down': !mainmenu[6],
                  }"
                ></span
              ></a>
              <ul
                id="patentsurvival"
                class="nav nav-second-level collapse"
                v-if="isActive"
              >
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Study Data Analysis Survival Analysis')"
                >
                  <router-link
                    to="/survivalreport"
                    class="nav-link"
                    @click.prevent="close"
                  >
                    <img
                      class="me-1"
                      src="../../assets/img/Survival_Analysis_n.png"
                      alt="Sent Query Menu"
                    />
                    <span v-if="notcollapsed">{{
                      t("navigations.survival analysis")
                    }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="
                    rollsprivilages.includes(
                      'Study Data Analysis Descriptive Statistic Report'
                    )
                  "
                >
                  <router-link
                    to="/DescriptiveStatisticReport"
                    class="nav-link"
                    @click.prevent="close"
                    ><img
                      class="me-1"
                      src="../../assets/img/Desc_Statistic_Report_n.png"
                      alt="Submit Form Menu"
                    />
                    <span v-if="notcollapsed">{{
                      t("navigations.descriptive statistic report")
                    }}</span>
                  </router-link>
                </li>
                <!-- <li
                      class="submenuitem"
                    >
                      <router-link to="/aereport" class="nav-link" @click.prevent="close"
                        ><i class="fa fa-bed mr-2" aria-hidden="true"></i>

                        <span v-if="notcollapsed">Adverse Events  </span>
                      </router-link>
                    </li>  -->
                <!-- <li
                      class="submenuitem"
                    >
                      <router-link to="/deathsummaryreport" class="nav-link" @click.prevent="close"
                        ><i class="fa fa-bomb mr-2" aria-hidden="true"></i>

                        <span v-if="notcollapsed">Death Summary Report  </span>
                      </router-link>
                    </li>  -->
                <!-- <li
                      class="submenuitem"
                    >
                      <router-link to="/labsummaryreport" class="nav-link" @click.prevent="close"
                        ><i class="fa fa-thermometer-three-quarters mr-2" aria-hidden="true"></i>

                        <span v-if="notcollapsed">Lab Summary Report  </span>
                      </router-link>
                    </li>  -->
                <!-- <li
                      class="submenuitem"
                    >
                      <router-link to="/subjectstatusreport" class="nav-link" @click.prevent="close"
                        ><i class="fa fa-thermometer-three-quarters mr-2" aria-hidden="true"></i>

                        <span v-if="notcollapsed">{{ t("navigations.subject status report") }}</span>
                      </router-link>
                    </li>  -->
                <!-- <li
                      class="submenuitem"
                    >
                      <router-link to="/medicalhistory" class="nav-link" @click.prevent="close"
                        ><i class="fa fa-stethoscope mr-2" aria-hidden="true"></i>

                        <span v-if="notcollapsed">Medical History Report  </span>
                      </router-link>
                    </li>  -->
                <!-- <li
                      class="submenuitem"
                    >
                      <router-link to="/labresultreview" class="nav-link" @click.prevent="close"
                        ><i class="fa fa-flask mr-2" aria-hidden="true"></i>

                        <span v-if="notcollapsed">Lab Result Review Report  </span>
                      </router-link>
                    </li>  -->
                <!-- <li
                      class="submenuitem"
                    >
                      <router-link to="/vitalsignreport" class="nav-link" @click.prevent="close"
                        ><i class="fa fa-heartbeat mr-2" aria-hidden="true"></i>

                        <span v-if="notcollapsed">Vital sign Result trends  </span>
                      </router-link>
                    </li> -->
                <!-- <li class="submenuitem">
                      <router-link to="/demographicReport" class="nav-link" @click.prevent="close"
                        ><i class="fa fa-heartbeat mr-2" aria-hidden="true"></i>

                        <span v-if="notcollapsed">Demographics Summary Report </span>
                      </router-link>
                    </li> -->
              </ul>
            </li>
            <li
              class="submenuitem"
              v-if="
                rollsprivilages.includes('Mainmenu FHIR Data View') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <router-link to="/fhirViewer" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/fillform_n.png"
                  alt="Submit Form Menu"
                />
                <span v-if="notcollapsed">{{ t("navigations.fhirViewer") }} </span>
              </router-link>
            </li>
            <li
              class="submenuitem"
              v-if="
                rollsprivilages.includes('Mainmenu Fill Form') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <router-link to="/submitForm" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/fillform_n.png"
                  alt="Submit Form Menu"
                />
                <span v-if="notcollapsed">{{ t("navigations.fill form") }} </span>
              </router-link>
            </li>
            <li
              v-if="
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <router-link
                to="/sdtm"
                class="nav-link"
                @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/upload_study_protocol.png"
                  alt="SDTM conversion"
                />
                <span v-if="notcollapsed"
                  >{{ t("navigations.sdtm conversion") }}
                </span>
              </router-link>
            </li>
            <li
              class="submenuitem"
              v-if="
                rollsprivilages.includes('Mainmenu Study List') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <router-link to="/studylist" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/study_list_n.png"
                  alt="Study Menu"
                />
                <span v-if="notcollapsed">{{ t("navigations.study list") }}</span>
              </router-link>
            </li>
            <li class="submenuitem" v-if="getDefaultMenu == 'Analytical'">
              <router-link
                to="/analyticalDashboard"
                class="nav-link"
                @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/analytical_dashboard.svg"
                  alt="Visit Summary Menu"
                />
                <span v-if="notcollapsed">
                  {{ t("navigations.analytical dashboards") }}
                </span>
              </router-link>
            </li>
            <li class="submenuitem" v-if="getDefaultMenu == 'Clinical'">
              <router-link to="/studymonitoring" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/analytical_dashboard.svg"
                  alt="Visit Summary Menu"
                />
                <span v-if="notcollapsed">
                  {{ t("navigations.study monitoring") }}
                </span>
              </router-link>
            </li>
            <!-- study setup menu ends -->
            <li
              v-if="
                rollsprivilages.includes('Mainmenu Dashboard') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <a href="#" @click.prevent="expandfun('dashboardmenu', 1, 'mainmenu')"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/dashboards_n.png"
                  alt="Home Menu" />
                <span class="nav-label">{{ t("navigations.dashboards") }} </span
                ><span
                  class="fa fa-angle-left angle-down-right mt-1"
                  :class="{
                    'float-right': isActive,
                    'rotate-down': !mainmenu[1],
                  }"
                ></span
              ></a>
              <ul id="dashboardmenu" class="nav nav-second-level collapse">
                <li class="submenuitem">
                  <router-link
                    to="/dynamicdashboard"
                    class="nav-link"
                    @click.prevent="close"
                    ><img
                      class="me-1 mb-1"
                      src="../../assets/img/dashboard.svg"
                      alt="Home Menu"
                    />
                    <span v-if="notcollapsed">{{ $t("navigations.dashboard") }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Dashboard Main Dashboard')"
                >
                  <router-link to="/home" class="nav-link" @click.prevent="close"
                    ><img
                      class="me-1 mb-1"
                      src="../../assets/img/main_dashboard.svg"
                      alt="Home Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.main dashboard") }}</span>
                  </router-link>
                </li>

                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Dashboard Nurse Dashboard')"
                >
                  <router-link to="/nursesummary" class="nav-link" @click.prevent="close"
                    ><img
                      class="me-1 mb-1"
                      src="../../assets/img/nurse_dashboard.svg"
                      alt="Nurse summary Menu"
                    />
                    <span v-if="notcollapsed">{{
                      t("navigations.nurse dashboard")
                    }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Dashboard Study Summary')"
                >
                  <router-link to="/studysummary" class="nav-link" @click.prevent="close"
                    ><img
                      class="me-1 mb-1"
                      src="../../assets/img/study_summary.svg"
                      alt="Study Summary Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.study summary") }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Dashboard Site Summary')"
                >
                  <router-link to="/sitesummary" class="nav-link" @click.prevent="close"
                    ><img
                      class="me-1 mb-1"
                      src="../../assets/img/site_summary.svg"
                      alt="Site Summary Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.site summary") }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Dashboard Visit Summary')"
                >
                  <router-link to="/visitsummary" class="nav-link" @click.prevent="close"
                    ><img
                      class="me-1 mb-1"
                      src="../../assets/img/visit_summary.svg"
                      alt="Visit Summary Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.visit summary") }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Dashboard Nurse Diary')"
                >
                  <router-link to="/nursediary" class="nav-link" @click.prevent="close"
                    ><img
                      class="me-1 mb-1"
                      src="../../assets/img/nurse_diary.svg"
                      alt="Visit Summary Menu"
                    />
                    <span v-if="notcollapsed">
                      {{ t("navigations.nurse diary") }}
                    </span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li
              class="submenuitem"
              v-if="
                rollsprivilages.includes('Mainmenu Reports') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <a
                href="#"
                class="fullwidthquery"
                @click.prevent="expandfun('reportsitem', 2, 'submenu')"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/reports_n.png"
                  alt="Reports Menu" />
                <span class="nav-label">{{ t("navigations.reports") }} </span
                ><span
                  class="fa fa-angle-left angle-down-right mt-1"
                  :class="{
                    'float-right': isActive,
                    'rotate-down': submenu[2] == true,
                  }"
                ></span
              ></a>
              <ul id="reportsitem" class="nav nav-second-level collapse">
                <li
                  v-if="rollsprivilages.includes('Reports Menu Report Dashboard')"
                  class="submenuitem"
                >
                  <router-link
                    to="/reportdashboard"
                    class="nav-link"
                    @click.prevent="close"
                  >
                    <img
                      class="me-1 thirdlevel"
                      src="../../assets/img/reports.png"
                      alt="Reports Menu"
                    />
                    <span v-if="notcollapsed">{{
                      t("navigations.report dashboard")
                    }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li
              class="submenuitem"
              v-if="
                rollsprivilages.includes('Mainmenu Data Export') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <router-link to="/graphvisual" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/Data_export_n.png"
                  alt="Data Export"
                />
                <span v-if="notcollapsed">Graph</span>
              </router-link>
            </li>
            <li
              class="submenuitem"
              v-if="
                rollsprivilages.includes('Mainmenu Data Export') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <router-link to="/viewgraph" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/Data_export_n.png"
                  alt="Data Export"
                />
                <span v-if="notcollapsed">View Graph</span>
              </router-link>
            </li>
            <li
              class="submenuitem"
              v-if="
                rollsprivilages.includes('Mainmenu Data Export') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <router-link to="/dataExport" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/Data_export_n.png"
                  alt="Data Export"
                />
                <span v-if="notcollapsed">{{ t("navigations.data export") }} </span>
              </router-link>
            </li>
             <li
              class="submenuitem"
               v-if="
                rollsprivilages.includes('Mainmenu Data Standards') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
             
            >
              <router-link to="/dataStandard" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/Data_export_n.png"
                  alt="Data Export"
                />
                <span v-if="notcollapsed">{{ "Data Standards" }} </span>
              </router-link>
            </li>
            <li
              class="submenuitem"
              v-if="
                rollsprivilages.includes('Mainmenu DMS') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <a
                class="fullwidthquery"
                href="#"
                @click.prevent="expandfun('dmsitem', 4, 'mainmenu')"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/dms_n.png"
                  alt="Home Menu" />
                <span class="nav-label">{{ t("navigations.dms") }} </span
                ><span
                  class="fa fa-angle-left angle-down-right mt-1"
                  :class="{
                    'float-right': isActive,
                    'rotate-down': !mainmenu[4],
                  }"
                ></span
              ></a>
              <ul id="dmsitem" class="nav nav-second-level collapse">
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('DMS Menu Document Upload')"
                >
                  <router-link to="/dms" class="nav-link" @click.prevent="close"
                    ><img class="me-1" src="../../assets/img/dms.png" alt="General DMS" />
                    <span class="mt-1" v-if="notcollapsed">{{
                      t("navigations.document upload")
                    }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('DMS Menu Document Summary')"
                >
                  <router-link
                    to="/dmssummaryreport"
                    class="nav-link"
                    @click.prevent="close"
                  >
                    <img
                      class="me-1"
                      src="../../assets/img/received_query(2).png"
                      alt="Received Query Menu"
                    />
                    <span v-if="notcollapsed">{{
                      t("navigations.document summary")
                    }}</span>
                  </router-link>
                </li>
                <!-- document approval menu starts -->
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('DMS Menu Document Approval')"
                >
                  <router-link
                    to="/documentapprovallist"
                    class="nav-link"
                    @click.prevent="close"
                    ><img
                      class="me-1"
                      src="../../assets/img/doc_approval.png"
                      alt="Document Approval"
                    />
                    <span v-if="notcollapsed">{{
                      t("navigations.document approval")
                    }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('DMS Menu Documents')"
                >
                  <router-link
                    to="/documentsummary"
                    class="nav-link"
                    @click.prevent="close"
                    ><img
                      class="me-1"
                      src="../../assets/img/documentsummary.png"
                      alt="Document Summary"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.documents") }}</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <!-- IOT menu starts  -->

            <!-- <li v-if="rollsprivilages.includes('Mainmenu IOT')"> -->
            <li
              v-if="
                rollsprivilages.includes('Mainmenu IOT') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <a href="#" @click.prevent="expandfun('iotmenu', 2, 'mainmenu')"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/iot_n.svg"
                  alt="Home Menu" />
                <span class="nav-label">{{ t("navigations.iot") }} </span
                ><span
                  class="fa fa-angle-left angle-down-right mt-1"
                  :class="{
                    'float-right': isActive,
                    'rotate-down': !mainmenu[2],
                  }"
                ></span
              ></a>
              <ul id="iotmenu" class="nav nav-second-level collapse">
                <li v-if="rollsprivilages.includes('IOT Google Fit')" class="submenuitem">
                  <router-link
                    to="/googlefitscreen"
                    class="nav-link"
                    @click.prevent="close"
                    ><img
                      class="me-2 mb-1"
                      src="../../assets/img/google_fit.svg"
                      alt="Google Fit Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.google fit") }} </span>
                  </router-link>
                </li>
                <li
                  v-if="rollsprivilages.includes('IOT IOT Devices')"
                  class="submenuitem"
                >
                  <router-link to="/IOTDevices" class="nav-link" @click.prevent="close"
                    ><img
                      class="me-2 mb-1"
                      src="../../assets/img/iot_devices.svg"
                      alt="Home Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.iot devices") }}</span>
                  </router-link>
                </li>

                <li
                  v-if="rollsprivilages.includes('IOT IOT Patients')"
                  class="submenuitem"
                >
                  <router-link to="/IOTPatients" class="nav-link" @click.prevent="close"
                    ><img
                      class="me-2 mb-1"
                      src="../../assets/img/iot_patients.svg"
                      alt="Home Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.iot subjects") }}</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <!-- IOT menu ends -->

            <!-- Administration menu starts  -->
            <li
              v-if="
                rollsprivilages.includes('Mainmenu Administration') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <a href="#" @click.prevent="expandfun('administrationmenu', 3, 'mainmenu')"
                ><img
                  class="me-1 mb-1"
                  src="../../assets/img/administration.svg"
                  alt="Administration Menu" />
                <span class="nav-label">{{ t("navigations.administration") }} </span
                ><span
                  class="fa fa-angle-left angle-down-right mt-1"
                  :class="{
                    'float-right': isActive,
                    'rotate-down': !mainmenu[3],
                  }"
                ></span
              ></a>
              <ul id="administrationmenu" class="nav nav-second-level collapse">
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Administration Users')"
                >
                  <router-link to="/userlisting" class="nav-link" @click.prevent="close"
                    ><img
                      class="me-1 mb-1"
                      src="../../assets/img/users.svg"
                      alt="Users Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.users") }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Administration Roles')"
                >
                  <router-link to="/roleList" class="nav-link" @click.prevent="close">
                    <img
                      class="me-1 mb-1"
                      src="../../assets/img/roles.svg"
                      alt="Roles Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.roles") }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Administration Role Privileges')"
                >
                  <router-link to="/RoleScreen" class="nav-link" @click.prevent="close">
                    <img
                      class="me-1 mb-1"
                      src="../../assets/img/role_privilege.svg"
                      alt="Role Privilege Menu"
                    />
                    <span v-if="notcollapsed">{{
                      t("navigations.role privileges")
                    }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Administration Screen')"
                >
                  <router-link to="/screen" class="nav-link" @click.prevent="close"
                    ><img
                      class="me-1 mb-1"
                      src="../../assets/img/screen_privilage.svg"
                      alt="Screens Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.screen") }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Administration Site Privilege')"
                >
                  <router-link to="/siteprivilege" class="nav-link" @click.prevent="close"
                    ><img
                      class="me-1 mb-1"
                      src="../../assets/img/site_privilege.svg"
                      alt="Site Privilege Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.site privilege") }}</span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Administration Field Privileges')"
                >
                  <router-link
                    to="/fieldprivileges"
                    class="nav-link"
                    @click.prevent="close"
                    ><img
                      class="me-1 mb-1"
                      src="../../assets/img/field_privilege.svg"
                      alt="Field Privileges Menu"
                    />
                    <span v-if="notcollapsed">{{
                      t("navigations.field privileges")
                    }}</span>
                  </router-link>
                </li>
              </ul>
            </li>

            <!-- Administration menu ends -->
            <!-- <li v-if="rollsprivilages.includes('Mainmenu Study Template')">
              <router-link to="/studytemplate" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1"
                  src="../../assets/img/study_template.png"
                  alt="Study Template Menu"
                />
                <span v-if="notcollapsed">{{ t("navigations.study template") }} </span>
              </router-link>
            </li>
            <li v-if="rollsprivilages.includes('Mainmenu Data Entry')">
              <router-link to="/visitforms" class="nav-link" @click.prevent="close"
                ><img
                  class="me-1"
                  src="../../assets/img/data_entry.png"
                  alt="Data Entry Menu"
                />
                <span v-if="notcollapsed">{{ t("navigations.data entry") }}</span>
              </router-link>
            </li> -->

            <li
              class="fullwidthquery"
              v-if="
                rollsprivilages.includes('Mainmenu Form Library') &&
                getDefaultMenu != 'Analytical' &&
                getDefaultMenu != 'AI/Ml' &&
                getDefaultMenu != 'Clinical' &&
                getDefaultMenu != 'Stats'
              "
            >
              <a
                href="#"
                class="fullwidthquery"
                @click.prevent="expandfun('formlibrarysetup', 5, 'submenu')"
              >
                <img
                  class="me-1 mb-1"
                  src="../../assets/img/form_library.svg"
                  alt="Transfer"
                />
                <span
                  class="fa fa-angle-left angle-down-right mt-1"
                  :class="{
                    'float-right': isActive,
                    'rotate-down': submenu[5] == true,
                  }"
                ></span>
                <span class="nav-label">{{ t("navigations.form library") }} </span>
              </a>
              <ul id="formlibrarysetup" class="nav nav-second-level collapse">
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Form Library Menu Designer Tool')"
                >
                  <router-link
                    to="/formdesignertool"
                    class="nav-link"
                    @click.prevent="close"
                  >
                    <img
                      class="me-1 mb-1"
                      src="../../assets/img/designer_tool.svg"
                      alt="Template Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.designer tool") }}</span>
                  </router-link>
                </li>

                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Form Library Menu Templates')"
                >
                  <router-link
                    to="/templatelisting"
                    class="nav-link"
                    @click.prevent="close"
                  >
                    <img
                      class="me-1 mb-1"
                      src="../../assets/img/templates.svg"
                      alt="Template Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.forms") }} </span>
                  </router-link>
                </li>
                <li
                  class="submenuitem"
                  v-if="rollsprivilages.includes('Form Library Menu Approve Template')"
                >
                  <router-link to="/digitalform" class="nav-link" @click.prevent="close">
                    <img
                      class="me-1 mb-2"
                      src="../../assets/img/approve_template.svg"
                      alt="Template Menu"
                    />
                    <span v-if="notcollapsed">{{ t("navigations.approve forms") }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
            <li
                class="fullwidthquery"
                v-if="
                  rollsprivilages.includes('Mainmenu smart') &&
                  getDefaultMenu != 'Analytical' &&
                  getDefaultMenu != 'AI/Ml' &&
                  getDefaultMenu != 'Clinical' &&
                  getDefaultMenu != 'Stats'
                "
              >
                <a
                  href="#"
                  class="fullwidthquery"
                  @click.prevent="expandfun('smartmenu', 6, 'submenu')"
                >
                  <img
                    class="me-1 mb-1"
                    src="../../assets/img/Data_export_n.png"
                    alt="Smart 2.0"
                  />
                  <span class="nav-label">{{ t("navigations.smart") }} </span>
                  <span
                    class="fa fa-angle-left angle-down-right mt-1"
                    :class="{
                      'float-right': isActive,
                      'rotate-down': submenu[6] == true,
                    }"
                  ></span>
                </a>
                <ul id="smartmenu" class="nav nav-second-level collapse">
                  <li class="submenuitem">
                    <router-link to="/sapConnection" class="nav-link" @click.prevent="close">
                      <img
                        class="me-1 mb-1"
                        src="../../assets/img/Data_export_n.png"
                        alt="SAP"
                      />
                      <span v-if="notcollapsed">{{ t("navigations.sap") }} </span>
                    </router-link>
                  </li>
                  <li class="submenuitem">
                    <router-link to="/crm" class="nav-link" @click.prevent="close">
                      <img
                        class="me-1 mb-1"
                        src="../../assets/img/Data_export_n.png"
                        alt="CRM"
                      />
                      <span v-if="notcollapsed">{{ t("navigations.crm") }}</span>
                    </router-link>
                  </li>
                  <li class="submenuitem">
                    <router-link 
                      to="/inventoryTracking" 
                      class="nav-link" 
                      @click.prevent="close"
                    >
                      <img
                        class="me-1 mb-1"
                        src="../../assets/img/Data_export_n.png"
                        alt="Inventory Tracking"
                      />
                      <span v-if="notcollapsed">{{ t("navigations.inventoryTracking") }} </span>
                    </router-link>
                  </li>
                </ul>
              </li>

            <!-- <li v-if="rollsprivilages.includes('Mainmenu Settings')">
              <router-link
                to="/settings"
                class="nav-link"
                @click.prevent="close"
              >
                <img class="me-1" src="../../assets/img/settings.png" alt="Settings Menu">
                <span v-if="notcollapsed">{{ t('navigations.settings') }}</span>
              </router-link>
            </li> -->

            <!-- <li v-if="rollsprivilages.includes('Mainmenu Report')">
              <router-link
                to="/report"
                class="nav-link"
                @click.prevent="close"
              >
                <i class="fa fa-gear"></i>
                <span v-if="notcollapsed">report</span>
              </router-link>
            </li> -->
            <ul
              id="itemsetup"
              class="nav nav-third-level collapse"
              v-if="isActive == false"
            >
              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Study Components Menu Form Summary')"
              >
                <router-link to="/formSummary" class="nav-link" @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/form_summary.png"
                    alt="Form Summary Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.form summary") }}</span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Study Components Menu Sites')"
              >
                <router-link to="/sitelist" class="nav-link" @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/sites_n.png"
                    alt="Sites Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.sites") }} </span>
                </router-link>
              </li>

              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Study Components Menu Subjects')"
              >
                <router-link to="/patientlist" class="nav-link" @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/subject.png"
                    alt="Subjects Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.subjects") }} </span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Study Components Menu Subjects')"
              >
                <router-link to="/eConsentList" class="nav-link" @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/subject.png"
                    alt="Subjects Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.e-consent") }} </span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Study Components Menu Visits')"
              >
                <router-link to="/visitlist" class="nav-link" @click.prevent="close">
                  <img
                    class="me-1 thirdlevel"
                    src="../../assets/img/visits.png"
                    alt="Visits Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.visits") }}</span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Study Components Menu Visit Templates')"
              >
                <router-link to="/formTemplates" class="nav-link" @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/study_template.png"
                    alt="Subjects Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.visit forms") }} </span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Study Components Menu Submitted Forms')"
              >
                <router-link to="/listform" class="nav-link" @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/forms.png"
                    alt="Form Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.submitted forms") }}</span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Study Components Menu Unassigned Form')"
              >
                <router-link to="/displayform" class="nav-link" @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/assign-form.png"
                    alt="Assign Form Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.unassigned forms") }}</span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="
                  rollsprivilages.includes('Study Components Menu Partially Filled Forms')
                "
              >
                <router-link to="/reviewFormList" class="nav-link" @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/assign_form.png"
                    alt="Form Menu"
                  />
                  <span v-if="notcollapsed">{{
                    t("navigations.partially filled forms")
                  }}</span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="
                  !rollsprivilages ||
                  rollsprivilages.includes('Study Components Menu Users')
                "
              >
                <router-link
                  to="/StudyBasedUserList"
                  class="nav-link"
                  @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/users.png"
                    alt="Users Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.users") }}</span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Study Components Menu Archived Forms')"
              >
                <router-link to="/Archive" class="nav-link" @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/archive.png"
                    alt="Form Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.archived forms") }}</span>
                </router-link>
              </li>
            </ul>
            <ul
              id="querysetup"
              class="nav nav-third-level collapse"
              v-if="isActive == false"
            >
              <!-- <li class="submenuitem">
                  <router-link to="/generalQuery" class="nav-link">
                    <i class="fa fa-question thirdlevel"></i>
                    <span v-if="notcollapsed">Raise Query</span>
                  </router-link>
                </li> -->
              <li v-if="rollsprivilages.includes('Query Menu Sent')" class="submenuitem">
                <router-link to="/sentQueryList" class="nav-link" @click.prevent="close">
                  <img
                    class="me-1 thirdlevel"
                    src="../../assets/img/sent_queries_n.png"
                    alt="Sent Query Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.sent") }}</span>
                </router-link>
              </li>
              <li
                v-if="rollsprivilages.includes('Query Menu Received')"
                class="submenuitem"
              >
                <router-link to="/queryList" class="nav-link" @click.prevent="close">
                  <img
                    class="me-1 thirdlevel"
                    src="../../assets/img/received_query_dark_n.png"
                    alt="Received Query Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.received") }}</span>
                </router-link>
              </li>
              <li
                v-if="rollsprivilages.includes('Query Menu All Queries')"
                class="submenuitem"
              >
                <router-link to="/allQueryList" class="nav-link" @click.prevent="close">
                  <img
                    class="me-1 thirdlevel"
                    src="../../assets/img/all_queries.png"
                    alt="All Query Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.all queries") }}</span>
                </router-link>
              </li>
              <li
                v-if="rollsprivilages.includes('Query Menu General')"
                class="submenuitem"
              >
                <router-link
                  to="/general_querylist"
                  class="nav-link"
                  @click.prevent="close"
                >
                  <img
                    class="me-1 thirdlevel"
                    src="../../assets/img/general_querylist.png"
                    alt="General Query Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.general") }}</span>
                </router-link>
              </li>
            </ul>
            <ul
              id="formdesignersetup"
              class="nav nav-third-level collapse"
              v-if="isActive == false"
            >
              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Form Designer Menu Designer Tool')"
              >
                <router-link to="/formdesigner" class="nav-link" @click.prevent="close">
                  <img
                    class="me-1 thirdlevel"
                    src="../../assets/img/template.png"
                    alt="Template Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.designer tool") }}</span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Form Designer Menu Designer Tool')"
              >
                <router-link
                  to="/mlCriteriaCapture"
                  class="nav-link"
                  @click.prevent="close"
                >
                  <img
                    class="me-1 thirdlevel"
                    src="../../assets/img/template.png"
                    alt="Template Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.ai tool") }}</span>
                </router-link>
              </li>

              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Form Designer Menu Templates')"
              >
                <router-link to="/templatelist" class="nav-link" @click.prevent="close">
                  <img
                    class="me-1 thirdlevel"
                    src="../../assets/img/template.png"
                    alt="Template Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.templates") }} </span>
                </router-link>
              </li>

              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Form Designer Menu Approve Template')"
              >
                <router-link to="/digitalform" class="nav-link" @click.prevent="close">
                  <img
                    class="me-1 thirdlevel"
                    src="../../assets/img/template.png"
                    alt="Template Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.approve forms") }}</span>
                </router-link>
              </li>
            </ul>
            <ul
              id="studyadministrationsetup"
              class="nav nav-third-level collapse"
              v-if="isActive == false"
            >
              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Study Administration Menu Form Transfer')"
              >
                <router-link to="/formtransfer" class="nav-link" @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/formtransfer.png"
                    alt="Transfer"
                  />
                  <span v-if="notcollapsed">Form Transfer</span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="
                  rollsprivilages.includes('Study Administration Menu Subject Transfer')
                "
              >
                <router-link to="/TransferSubject" class="nav-link" @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/patient_transfer.png"
                    alt="Transfer"
                  />
                  <span v-if="notcollapsed">Subject Transfer </span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="
                  rollsprivilages.includes('Study Administration Menu Subject Schedule')
                "
              >
                <router-link
                  to="/PatientScheduleView"
                  class="nav-link"
                  @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/subject_schedule.png"
                    alt="Subject Schedule Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.subject schedule") }}</span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="rollsprivilages.includes('Study Administration Menu Patient Login')"
              >
                <router-link
                  to="/patientLoginList"
                  class="nav-link"
                  @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/Patient_login.png"
                    alt="Subjects Login Menu"
                  />
                  <span v-if="notcollapsed">{{ t("navigations.patient login") }} </span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="
                  !rollsprivilages ||
                  rollsprivilages.includes('Study Administration Menu Unscheduled Visits')
                "
              >
                <router-link
                  to="/unscheduledvisits"
                  class="nav-link"
                  @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/unscheduled_visits.png"
                    alt="Unscheduled Visits Menu"
                  />
                  <span v-if="notcollapsed">{{
                    t("navigations.unscheduled visits")
                  }}</span>
                </router-link>
              </li>
              <li
                class="submenuitem"
                v-if="
                  rollsprivilages.includes(
                    'Study Administration Menu Change Schedule Template'
                  )
                "
              >
                <router-link to="/visittemplate" class="nav-link" @click.prevent="close"
                  ><img
                    class="me-1 thirdlevel"
                    src="../../assets/img/visits_templates.png"
                    alt="Visit Templates Menu"
                  />
                  <span v-if="notcollapsed">{{
                    t("navigations.change schedule template")
                  }}</span>
                </router-link>
              </li>
            </ul>
          </ul>
        </div>
      </nav>

      <div id="page-wrapper" class="gray-bg">
        <div
          class="row border-bottom"
          v-if="authenticated && getNavbarstatus && rollsprivilages != undefined"
        >
          <nav
            v-if="authenticated && getNavbarstatus && rollsprivilages != undefined"
            class="navbar navbar-static-top"
            role="navigation"
            style="margin-bottom: 0"
          >
            <div class="navbar-header align-self-center">
              <a
                v-if="showArrows"
                aria-label="Collapsible menu icon"
                tabindex="0"
                class="btn-cps ml-lg-4"
                href="#"
                @click.prevent="collapse()"
              >
                <i class="fa fa-angle-left" v-if="isActive"></i>
                <i class="fa fa-angle-right" v-if="isRightActive"></i>
              </a>
            </div>
            <ul class="nav navbar-top-links navbar-right align-self-lg-auto">
              <!-- <li
                class="px-1 add-study-popup cursor-pointer"
                @click.prevent="openUploadFile()"
                aria-label="Add study"             
                tabindex="0"
              >
                <img
                 class="px-md-1"
                  src="../../assets/img/upload_pdf.png"
                  alt="Upload file"
                />
                <div class="tool-tip">{{ $t("navigations.upload study protocol") }}</div>
              </li> -->
              <li
                v-if="rollsprivilages.includes('Video Call Schedule Video Call')"
                class="px-1 add-study-popup cursor-pointer"
                aria-label="Add study"
                tabindex="0"
              >
                <img
                  class="px-md-1"
                  src="../../assets/img/video_call_nav.png"
                  alt="Schedule Video call"
                  @click.prevent="CreateVideoCallSchedulePopUp = true"
                />
                <div class="tool-tip">Schedule Video call</div>
              </li>
              <!-- <li
             
                class="px-1 add-study-popup cursor-pointer"
                aria-label="Add study"
                tabindex="0"
              >
                <img
                  class="px-md-1"
                  src="../../assets/img/video_call_nav.png"
                  alt="Schedule Video call"
                  @click.prevent="ViewReportPopUp = true"
                />
                <div class="tool-tip">view report</div>
              </li> -->
              <li
                v-if="rollsprivilages.includes('Studylist Create Study')"
                class="px-3 px-md-2 add-study-popup cursor-pointer"
                @click.prevent="importStudypopup()"
                aria-label="Add study"
                tabindex="0"
              >
                <img
                  src="../../assets/img/import_study2.png"
                  alt="Import study details"
                />
                <div class="tool-tip">{{ $t("navigations.import study") }}</div>
              </li>
              <li
                v-if="rollsprivilages.includes('Studylist Create Study')"
                class="px-1 add-study-popup cursor-pointer"
                @click.prevent="createstudypopup = true"
                aria-label="Add study"
                tabindex="0"
              >
                <img
                  class="px-md-1"
                  src="../../assets/img/CreateStudy_n.png"
                  alt="Create Study"
                />
                <div class="tool-tip">{{ $t("navigations.create study") }}</div>
              </li>

              <li class="px-1 px-md-2" aria-label="Study change" tabindex="0">
                <select
                  class="study-select"
                  name="account"
                  v-model="valnow"
                  @change.prevent="studychange($event.target.value)"
                >
                  <option selected disabled value="">--Select--</option>

                  <option
                    v-for="studie in studies"
                    :key="studie.studyId"
                    :value="studie.studyId"
                  >
                    {{ studie.studyRef }}
                  </option>
                </select>
              </li>
              <li
                v-if="rollsprivilages.includes('Query Menu General')"
                class="px-2 px-md-2 general-query cursor-pointer"
                aria-label="Add a general query"
                tabindex="0"
              >
                <div class="px-0 cursor-pointer" @click.prevent="gotoUrl('generalQuery')">
                  <img src="../../assets/img/general_query_n.png" alt="General Query" />
                </div>
                <div class="tool-tip">
                  {{ $t("navigations.general query") }}
                </div>
              </li>
              <!-- <li aria-label="Add a general query" tabindex="0">
                <a
                  @click.prevent="gotoUrl('generalQuery')"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="General query"
                >
                  <img src="../../assets/img/general_query_n.png" alt="General Query" />
                </a>
              </li> -->
              <li
                v-if="rollsprivilages.includes('Query Menu Received')"
                class="px-1 px-md-2 received-query cursor-pointer"
                aria-label="Received query notification"
                tabindex="0"
              >
                <div
                  class="count-info px-0 cursor-pointer"
                  @click.prevent="displayNotification()"
                  data-toggle="tooltip"
                  data-placement="top"
                >
                  <img
                    class="query-icon"
                    src="../../assets/img/received_query_n.png"
                    alt="Received Query"
                  />
                  <span class="label label-warning query-count">{{ querycount }}</span>
                  <div class="tool-tip">
                    {{ $t("navigations.received queries") }}
                  </div>
                  <div class="notification-block animate fadeIn">
                    <div class="d-flex flex-column">
                      <div class="message-header d-flex flex-column py-1">
                        <h5 class="text-start">
                          {{ $t("navigations.received queries") }}
                        </h5>
                        <span class="dropdown-divider"></span>
                      </div>

                      <div
                        class="message-body d-flex"
                        v-for="list in querylist.slice(0, 3)"
                        :key="list.id"
                      >
                        <div
                          class="message-body__left-block text-start"
                          @click.prevent="viewquery(list.id)"
                        >
                          <span class="d-inline-block mb-1"
                            ><strong>{{ list.raisedBy }}</strong> raised query regarding
                            <strong>{{ list.subject }}</strong></span
                          >
                          <span class="d-inline-block mb-2"
                            ><small class="text-muted"
                              >{{ filterTime(list.date) }} -
                              {{ filterDate(list.date) }}</small
                            ></span
                          >
                        </div>
                        <div
                          class="message-body__right-block"
                          @click.prevent="viewquery(list.id)"
                        >
                          <span class="d-inline-block mb-1">{{
                            filterNow(list.date)
                          }}</span
                          ><br />
                        </div>
                        <span class="dropdown-divider"></span>
                      </div>
                    </div>
                    <div
                      class="text-center link-block py-1 px-0"
                      @click.prevent="gotoUrl('queryList')"
                    >
                      <a class="dropdown-item">
                        <i class="fa fa-question-circle-o"></i>
                        <strong>{{ $t("navigations.read all queries") }}</strong>
                      </a>
                    </div>
                  </div>
                </div>
              </li>

              <li
                class="px-2 px-md-2 user-profile cursor-pointer"
                aria-label="User profile"
                tabindex="0"
              >
                <div
                  class="user-block px-0 cursor-pointer"
                  @click.prevent="displayProfile()"
                  data-toggle="tooltip"
                  data-placement="top"
                >
                  <img
                    :src="usersdetails.picture"
                    class="img-fluid profile-pic"
                    alt="User Profile Image"
                  />
                  <div class="tool-tip">
                    {{ $t("navigations.user profile") }}
                  </div>
                </div>
                <div class="profile-block animate fadeIn text-start">
                  <div
                    class="login-user-details d-flex justify-content-start align-items-center"
                  >
                    <div class="login-user-details--image">
                      <img
                        class="profile-pic me-2"
                        :src="usersdetails.picture"
                        alt="User Image"
                      />
                    </div>
                    <div class="login-user-details--details d-flex flex-column">
                      <span
                        ><b>{{ usersdetails.given_name }}</b></span
                      >
                      <span>{{ usersdetails.name }}</span>
                    </div>
                  </div>
                  <div class="user-details d-flex flex-column">
                    <div
                      class="theme-change-block d-flex justify-content-start align-items-center"
                      @click="gotoUrl('profileSettings')"
                    >
                      <img
                        class="me-2"
                        src="../../assets/img/user_profile.png"
                        alt="User Profile"
                      />
                      <span>{{ $t("navigations.profile") }}</span>
                    </div>
                    <div
                      class="theme-change-block d-flex justify-content-start align-items-center"
                      @click="expandSidebar()"
                    >
                      <img
                        class="me-2"
                        src="../../assets/img/user_themes.png"
                        alt="User Themes"
                      />
                      <span class="theme-change-block">{{
                        $t("navigations.themes")
                      }}</span>
                      <div class="theme-menu animated fadeIn">
                        <div class="title">Themes</div>
                        <div
                          class="setings-item default-skin"
                          @click="switchTheme('theme-default')"
                        >
                          <span class="skin-name">
                            <a href="#" class="md-skin"> Default </a>
                          </span>
                        </div>
                        <!-- <div class="setings-item blue-skin" @click="themeBlack()">
                              <span class="skin-name ">
                                <a href="#" class="s-skin-1">
                                  Black light
                                </a>
                              </span>
                            </div> -->
                        <div
                          class="setings-item teal-skin"
                          @click="switchTheme('theme-green')"
                        >
                          <span class="skin-name">
                            <a href="#" class="md-skin"> Teal Green </a>
                          </span>
                        </div>
                        <div
                          class="setings-item dusty-skin"
                          @click="switchTheme('theme-violet')"
                        >
                          <span class="skin-name">
                            <a href="#" class="md-skin"> Dusty Lavender </a>
                          </span>
                        </div>
                        <div
                          class="setings-item ocean-skin"
                          @click="switchTheme('theme-ocian')"
                        >
                          <span class="skin-name">
                            <a href="#" class="md-skin"> Ocean Green </a>
                          </span>
                        </div>
                        <div
                          class="setings-item stormy-skin"
                          @click="switchTheme('theme-stormy')"
                        >
                          <span class="skin-name">
                            <a href="#" class="md-skin"> Stormy Cloud </a>
                          </span>
                        </div>
                        <div
                          class="setings-item pastel-skin"
                          @click="switchTheme('theme-pastel')"
                        >
                          <span class="skin-name">
                            <a href="#" class="md-skin"> Pastel Russet </a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <span class="dropdown-divider"></span>
                    <div
                      class="theme-change-block d-flex justify-content-start align-items-center"
                      @click.prevent="logout"
                    >
                      <img class="me-2" src="../../assets/img/logout.png" alt="Logout" />
                      <span>{{ $t("navigations.logout") }}</span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <router-view />
      </div>
    </div>
  </div>
  <createStudyPopup
    v-if="createstudypopup"
    @studyCreated="loadstudy"
    @closeModal="createstudypopup = false"
  />
  <CreateVideoCallSchedulePopUp
    v-if="CreateVideoCallSchedulePopUp"
    @closeModal="CreateVideoCallSchedulePopUp = false"
  />

  <!-- <ViewReportPopUp
  v-if="ViewReportPopUp"
  @closeModal="ViewReportPopUp = false"
  /> -->
  <!-- <uploadFile
    v-if="showupload"
    @closeModal="showupload = false"
  /> -->
  <!-- Import study modal -->
  <div v-if="showImportStudy == true" class="modal_wrapper">
    <div class="modal_container">
      <div class="modal_title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">
          {{ $t("navigations.import study") }}
        </h4>
        <div class="cursor-pointer" @click.prevent="closeModal()">
          <i class="fa fa-times" title="Close"></i>
        </div>
      </div>
      <div
        class="modal__upload__content d-flex flex-column justify-content-between pb-4 pt-2 px-4"
      >
        <div class="align-items-center">
          <div class="mb-4 float-right">
            Download the template and follow the format
            <span class="ml-2"
              ><img
                class="cursor-pointer"
                src="../../assets/img/download_1.png"
                alt="Download"
                @click="downloadImportTemplate()"
                :disabled="showLoading"
            /></span>
          </div>

          <!-- <div class="tool-tip ml-2">Download Study Template</div> -->
        </div>
        <div class="input-group">
          <input
            accept=".xls,.xlsx,.jpg,.jpeg,.png,.pdf,.docx"
            id="InPutFile"
            type="file"
            class="form-control my-2 my-lg-0"
            @change="previewFiles"
          />
          <span class="input-group-append">
            <button
              type="button"
              class="stdy_upload_btn mr-0 py-1"
              @click="importStudy()"
              :disabled="showLoading"
            >
              <div class="d-flex align-items-center" v-if="showLoading">
                <img
                  class="loading-img"
                  width="20"
                  src="../../assets/img/loading.webp"
                  alt="Wait while the document is added"
                />
              </div>
              <div v-else>{{ $t("navigations.import") }}</div>
            </button>
          </span>
        </div>
        <span class="errorClass" v-if="validations[0] == false">
          {{ $t("navigations.please upload a file") }}
        </span>
      </div>
    </div>
  </div>
  <!-- End of import study modal -->
</template>

<script>
/* eslint-disable */
import config from "../../../auth_config";
import createAuth0Client from "@auth0/auth0-spa-js";
import {
  defineComponent,
  watch,
  ref,
  watchEffect,
  computed,
  onMounted,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import moment from "moment";
import auth0 from "auth0-js";
import jwt_decode from "jwt-decode";
import { useI18n } from "vue-i18n";
import createStudyPopup from "../../components/createStudyPopup/createStudyPopup.vue";
import uploadFile from "../../components/UploadFile/UploadFile.vue";
import CreateVideoCallSchedulePopUp from "../../components/CreateVideoCallSchedulePopUp/CreateVideoCallSchedulePopUp.vue";
//import ViewReportPopUp from "../../components/ViewReportPopUp/ViewReportPopUp.vue";
let webAuth = new auth0.WebAuth({
  domain: "dev-datamatica.eu.auth0.com",
  clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
});
export default {
  name: "Navbar",
  components: {
    createStudyPopup,
    CreateVideoCallSchedulePopUp,
    uploadFile,
    //ViewReportPopUp
  },
  setup() {
    const store = useStore();
    const { d, t, locale } = useI18n({ useScope: "global" });
    let auth0Client = ref();
    let currentTheme = ref(localStorage.getItem("theme-color"));
    let valnow = ref("");
    let querylist = ref([]);
    let isActive = ref(true);
    let isRightActive = ref(false);
    let querycount = ref();
    let studies = ref([]);
    let arr = ref([]);
    let mainmenu = ref([true, true, true, true, true, true, true]);
    let submenu = ref([false, false, false, false]);
    let collapseSideBar = ref(false);
    let showArrows = ref(false);
    let createstudypopup = ref(false);
    let CreateVideoCallSchedulePopUp = ref(false);
    //let ViewReportPopUp = ref(false);

    const router = useRouter();
    let baseurl = ref(`${process.env.VUE_APP_Service_URL}`);
    let baseapi = ref(`${process.env.VUE_APP_Service_URL}/management/study/`);
    let showImportStudy = ref(false);
    let showupload = ref(false);
    let studyFile = ref("");
    let showLoading = ref(false);
    let validations = ref([true]);
    onMounted(async () => {
      window.addEventListener("StudyListChanged", (event) => {
        loadstudy();
        getquerydetails();
      });
      if (currentTheme.value == null) {
        localStorage.setItem("theme-color", "theme-default");
        currentTheme.value = localStorage.getItem("theme-color");
      }
      auth0Client.value = await createAuth0Client({
        domain: config.domain,
        client_id: config.clientId,
      });
      // valnow.value = store.getters.getStudyIs;
      loadstudy();
      getquerydetails();
      anchorlink();
    });
    // Handle cleanup when navigating away from the route
    router.beforeEach((to, from, next) => {
      if (to.name == "landingPage") {
        store.dispatch("setDefaultMenu", "");
      }
      next();
    });
    function returnToLanding() {
      store.dispatch("setDefaultMenu", "");
    }
    async function openUploadFile() {
      showupload.value = true;
    }
    async function importStudypopup() {
      showImportStudy.value = true;
    }
    async function closeModal() {
      loadstudy();
      showImportStudy.value = false;
      validations[0].value = false;
      if (studyFile) {
        studyFile.value = "";
      }
    }
    async function previewFiles(event) {
      studyFile.value = event.target.files[0];
    }

    async function importStudy() {
      await validateNull();
      if (validations.value[0]) {
        showLoading.value = true;
        const idtoken = store.getters.getIdToken;

        const formData = new FormData();
        formData.append("file", studyFile.value);
        let errormsg = "";
        await axios
          .post(`${baseapi.value}import`, formData, {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "multipart/form-data",
            },
            responseType: "blob",
          })
          .then((res) => {
            console.log("Import study...", res);

            const disposition = res.headers["content-disposition"];
            let fileName = "study_results.xlsx";

            if (disposition && disposition.indexOf("filename=") !== -1) {
              const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
              if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, "");
              }
            }

            showLoading.value = false;
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();

            alert("Study imported successfully");

            closeModal();
          })
          .catch((err) => {
            showLoading.value = false;
            studyFile.value = "";

            if (err.response && err.response.data instanceof Blob) {
              err.response.data
                .text()
                .then((blobText) => {
                  const errorData = JSON.parse(blobText);
                  console.log("Error while importing study:", errorData);

                  // Check for DomainValidations and handle specific errors
                  if (errorData.errors && errorData.errors.DomainValidations) {
                    const validationErrors = errorData.errors.DomainValidations;

                    // Handle specific error messages
                    if (validationErrors.includes("Import Failed Invalid Study")) {
                      alert("Import Failed - Invalid Study Id/Study Name");
                    } else if (validationErrors.includes("Invalid File Type")) {
                      alert(
                        "Import Failed - Invalid File Type. Please upload a valid file."
                      );
                    } else {
                      alert("Something went wrong");
                    }
                  } else {
                    alert("Something went wrong");
                  }
                })
                .catch((blobError) => {
                  alert("Something went wrong");
                });
            } else {
              alert("Something went wrong");
            }
          });
      }
    }

    function blobToObject(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          try {
            const result = JSON.parse(reader.result); // Assuming it's a JSON blob
            resolve(result);
          } catch (err) {
            reject(err); // Handle JSON parsing errors
          }
        };
        reader.onerror = (err) => reject(err);
        reader.readAsText(blob); // Read the blob as text
      });
    }
    async function downloadImportTemplate() {
      const idtoken = store.getters.getIdToken;

      try {
        const res = await axios.get(`${baseapi.value}downloadimporttemplate`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        });

        console.log("Download template response...", res);
        console.log("Response headers:", res.headers);

        const contentDisposition =
          res.headers["content-disposition"] || res.headers["Content-Disposition"];
        let fileName = "import_template.xlsx";

        if (contentDisposition) {
          const fileNameMatch = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          const utf8FileNameMatch = contentDisposition.match(/filename\*=\s*UTF-8''(.+)/);

          if (fileNameMatch && fileNameMatch[1]) {
            fileName = fileNameMatch[1].replace(/['"]/g, "");
          } else if (utf8FileNameMatch && utf8FileNameMatch[1]) {
            fileName = utf8FileNameMatch[1];
          }
        } else {
          console.warn("Content-Disposition header not found. Using default file name.");
        }

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        alert("Import template downloaded successfully");
      } catch (err) {
        console.error("Error while downloading import template...", err);
        alert(
          "Something went wrong during the download. Please check console for details."
        );
      } finally {
        showLoading.value = false;
      }
    }

    async function validateNull() {
      if (
        studyFile.value != "" &&
        studyFile.value !== null &&
        studyFile.value !== undefined
      ) {
        validations.value[0] = true;
      } else {
        validations.value[0] = false;
      }

      console.log("validation after fn:", validations.value[0], studyFile.value);
    }
    async function anchorlink() {
      let listItems = document.getElementsByClassName("nav-link");
      for (let i = 0; i <= listItems.length - 1; i++) {
        //console.log("The value are",listItems[i].innerText)
      }
    }
    let usersdetails = computed(function () {
      loadstudy();
      getquerydetails();
      valnow.value = store.getters.getStudyIs;
      return jwt_decode(store.getters.getIdToken);
    });
    async function languagechange(val) {
      store.dispatch("setLanguage", val);
      //  router.push(`${router.currentRoute.value.path}`);
      router.push({
        name: router.currentRoute.value.name,
        params: { locale: val },
      });
    }
    let authenticated = computed(function () {
      return store.getters.authenticated;
    });
    let rollsprivilages = computed(function () {
      let rprivileges = store.getters.getRolesprivilegeData;
      let dashbrd = store.getters.getdefaultdashboard;
      if (rprivileges != "test test," && useRoute().path == "/noprivilege") {
        router.push(`/${dashbrd}`);
      }
      return store.getters.getRolesprivilegeData;
    });
    let defaultdashbord = computed(function () {
      return store.getters.getdefaultdashboard;
    });
    let getNavbarstatus = computed(function () {
      return store.getters.getNavbarstatus;
    });
    let getDefaultMenu = computed(function () {
      return store.getters.getDefaultMenu;
    });
    var navbarStatus = getNavbarstatus.value;
    let root = document.querySelector(":root");
    let notcollapsed = true;
    let expandclass = "nav nav-second-level collapse";
    let user = computed(function () {
      return store.state.user;
    });
    async function setdefaultstudy() {
      if (valnow.value == "" || valnow.value == null || valnow.value == "undefined") {
        const idtoken = store.getters.getIdToken;
        const userId = await jwt_decode(idtoken).sub;
        await axios
          .get(baseapi.value + "getdefaultstudy?id=" + userId, {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (res.statusText == "No Content") {
            } else {
              valnow.value = res.data.studyID;
              store.dispatch("setStudyID", res.data.studyID);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    async function logoutaudit() {
      const token = store.getters.getIdToken;
      const emailId = await jwt_decode(token).email;
      await axios
        .post(
          `${baseurl.value}/account-core/user/saveloginaudit`,
          {
            userEmail: emailId,
            logdetails: [
              {
                action: "Log Out",
                date: "2022-08-30T09:10:42.634Z",
                time: "2022-08-30T09:10:42.634Z",
              },
            ],
          },
          {
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("login audit res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function logout() {
      await logoutaudit();
      let a = localStorage.clear();
      // let b = auth0Client.value.logout();
      let b = webAuth.logout({
        returnTo: `${process.env.VUE_APP_logout_URL}`,
        clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
      });
      return a && b;
    }
    function switchTheme(theme) {
      localStorage.setItem("theme-color", theme);
      currentTheme.value = localStorage.getItem("theme-color");
    }
    async function getquerydetails() {
      const baseapi = process.env.VUE_APP_Service_URL;
      const currentstudyid = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      // let querycountstd = 0;
      await axios
        .get(
          `${baseapi}/forms/query/getlatestqueries?StudyId=${currentstudyid}&GetRecivedOnly=true&Countof=6`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          querylist.value = res.data.data;
          querycount.value = res.data.count;
        })
        .catch((err) => {
          console.log(err);
        });
    }
    function gotoUrl(url) {
      if (url == "profileSettings") {
        router.push(`/profileSettings`);
        document.querySelector(".profile-block").style.display = "none";
      } else if (url == "generalQuery") {
        router.push(`/generalQuery`);
      } else if (url == "queryList") {
        router.push(`/queryList`);
      }
    }
    function displayNotification() {
      const notif = document.querySelector(".notification-block");
      const profile = document.querySelector(".profile-block");
      if (profile.style.display == "block") {
        profile.style.display = "none";
      }
      if (notif.style.display == "block") {
        notif.style.display = "none";
      } else notif.style.display = "block";
    }
    function displayProfile() {
      const notif = document.querySelector(".notification-block");
      const profile = document.querySelector(".profile-block");
      if (notif.style.display == "block") {
        notif.style.display = "none";
      }
      if (profile.style.display == "block") {
        profile.style.display = "none";
      } else profile.style.display = "block";
    }
    document.addEventListener("click", (event) => {
      const notificationicon = document.querySelector(".notification-block");
      const thememenu = document.querySelector(".theme-menu");
      const profile = document.querySelector(".profile-block");
      if (
        event.target.classList.contains("count-info") ||
        event.target.classList.contains("query-icon") ||
        event.target.classList.contains("setings-item") ||
        event.target.classList.contains("md-skin") ||
        event.target.classList.contains("theme-change-block") ||
        event.target.classList.contains("user-block") ||
        event.target.classList.contains("profile-pic") ||
        event.target.classList.contains("profile-block") ||
        event.target.classList.contains("user-details")
      ) {
        return;
      } else {
        profile.style.display = "none";
        notificationicon.style.display = "none";
        thememenu.style.display = "none";
      }
    });
    function filterDate(date) {
      if (date) {
        return moment(date).format("DD-MM-YYYY");
      }
    }
    function filterTime(time) {
      let defaultTime = localStorage.getItem("timeformat");
      if (time) {
        return moment(time).format(defaultTime);
      }
    }
    function filterNow(date) {
      if (date) {
        return moment(date).fromNow();
      }
    }
    async function viewreceived() {
      router.push(`/queryList`);
    }
    async function viewquery(id) {
      router.push(`/query/queryList/${id}`);
    }
    async function expandSidebar() {
      let x = document.getElementsByClassName("theme-menu")[0];
      if (x.style.display == "block") {
        x.style.display = "none";
      } else x.style.display = "block";
    }
    window.matchMedia("(min-width:769px)").addListener(initNav);
    window.matchMedia("(max-width:769px)").addListener(initArrow);
    function initArrow() {
      if (document.body.classList.contains("mini-navbar")) {
        document.body.classList.remove("mini-navbar");
      }
    }
    //mobile navbar initialised
    function initNav() {
      isActive.value = true;
      isRightActive.value = false;
      if (window.innerWidth > 769) {
        if (document.body.classList.contains("mini-navbar")) {
          document.body.classList.remove("mini-navbar");
          document.getElementById("mobilemenu").classList.toggle("navbar-static-side");
        } else {
          document
            .getElementById("mobilemenu")
            .classList.add("navbar-default", "navbar-static-side");
        }
      }
    }
    //mobile and tab navbar close button
    async function loadstudy() {
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      axios
        .get(`${baseurl.value}/management/study/getallstudy?user=${usermail}`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          studies.value = res.data; //orderBy(res.data, 'studyName');
          //valnow.value = store.getters.getStudyIs ? store.getters.getStudyIs : res.data[0].studyId;
        })
        .catch(async (err) => {
          if (err.response.status == "401") {
            //token expired..
            await logoutaudit();
            let a = localStorage.clear();
            let b = webAuth.logout({
              returnTo: `${process.env.VUE_APP_logout_URL}`,
              clientID: "VIG7qA5lTa7dH921HIP3AL5ITQ0ADk8Z",
            });
            return a && b;
          }
          console.log(err);
        });
    }
    async function studychange(val) {
      await store.dispatch("setStudyID", val);
      window.dispatchEvent(
        new CustomEvent("studyIdChanged", {
          detail: {
            storage: store.getters.getStudyIs,
          },
        })
      );
    }
    function collapse() {
      store.dispatch("setCollapse",false)
      isActive.value = !isActive.value;
      isRightActive.value = !isRightActive.value;
      if (screen.width > 768) {
        document.body.classList.toggle("mini-navbar");
        notcollapsed = false;
      } else {
        document.body.classList.toggle("mini-navbar");
        document.getElementById("mobilemenu").classList.toggle("navbar-static-side");
      }
    }
    function mouseItemLeave(id) {
      if (isActive.value == false) {
        let expitem = document.getElementById(id);
        expitem.classList.add("collapse");
      }
    }
    //check the screen size and resize navbar

    async function expandmenu() {
      let expitem = document.getElementById("expitm");
      expitem.classList.toggle("collapse");
    }
    async function expandfun(id, index, type) {
      let expitem = document.getElementById(id);
      if (type === "mainmenu") {
        mainmenu.value[index] = !mainmenu.value[index];
      } else {
        submenu.value[index] = !submenu.value[index];
      }
      expitem.classList.toggle("collapse");
    }
    async function keepexpanded(id) {
      let expitem = document.getElementById(id);
      expitem.className = "nav nav-second-level";
    }
    watchEffect(() => {
      collapseSideBar.value = store.getters.getCollappseShow;
      if (collapseSideBar.value == true && isRightActive.value == false) {
        isActive.value = false;
        isRightActive.value = true;
        document.body.classList.add("mini-navbar");
      } else if (collapseSideBar.value == false && showArrows.value == false) {
        showArrows.value = true;
        isRightActive.value = false;
        isActive.value = true;
      }
        else if (collapseSideBar.value == false && isRightActive.value == true) {
        showArrows.value = true;
        isRightActive.value = false;
        isActive.value = true;
        submenu.value=[false,false,false,false,false]
        if (document.body.classList.contains("mini-navbar")) {
          document.body.classList.remove("mini-navbar");
        }
      } 
    });
    return {
      showArrows,
      collapseSideBar,
      returnToLanding,
      currentTheme,
      notcollapsed,
      expandclass,
      authenticated,
      getNavbarstatus,
      user,
      valnow,
      auth0Client,
      usersdetails,
      studies,
      defaultdashbord,
      rollsprivilages,
      querycount,
      querylist,
      isActive,
      isRightActive,
      showImportStudy,
      anchorlink,
      languagechange,
      switchTheme,
      displayNotification,
      filterNow,
      filterTime,
      filterDate,
      setdefaultstudy,
      createstudypopup,
      CreateVideoCallSchedulePopUp,
      loadstudy,
      studychange,
      mouseItemLeave,
      logout,
      collapse,
      expandmenu,
      getDefaultMenu,
      expandfun,
      initNav,
      close,
      mainmenu,
      submenu,
      viewquery,
      viewreceived,
      getquerydetails,
      expandSidebar,
      displayProfile,
      gotoUrl,
      t,
      d,
      importStudypopup,
      openUploadFile,
      closeModal,
      locale,
      studyFile,
      previewFiles,
      importStudy,
      downloadImportTemplate,
      validations,
      showLoading,
      validateNull,
      showupload,
      //ViewReportPopUp
    };
  },
};
</script>

<style>
@import "../../assets/css/style.css";
@import "../../assets/formpage.css";
@media (max-width: 768px) {
  .navbar.navbar-static-top {
    padding: 0 1rem !important;
  }

  .navbar-header {
    align-self: center;
  }

  .minimalize-styl-2 {
    margin: 0 !important;
  }

  .nav > li > a i {
    font-size: 1em;
  }

  #wrapper {
    position: relative !important;
  }

  .pace-done #page-wrapper,
  .body.mini-navbar #page-wrapper {
    position: relative;
  }

  #mobilemenu.navbar-static-side,
  #mobilemenu.navbar-default {
    transition: all ease-in-out 400ms !important;
  }

  .mini-navbar #mobilemenu > div {
    width: 87px;
    transition: width ease-in-out 400ms !important;
  }

  .angle-down-right {
    float: left;
    margin-left: 45%;
  }
}

.theme-default {
  /* Button color change property */
  --primary-color: #145faf;
  --hvr-color: #2d91fb;
  --secondary-color: #618bd7;

  /*   Dashboard title color change property */
  --pagination-active-clr: rgb(97, 139, 215);
  --icon-color: #68696b;
  --icon-white: #ffffff;
  --background-primary: #145faf;
  --background-secondary: #618bd7;
  --pop-up-background: #618bd7;
  --pop-up-background-light: #d9e4ff;
}

.theme-green {
  --primary-color: #008080;
  --hvr-color: #46b0b0;
  --secondary-color: #46b0b0;
  --pagination-active-clr: #46b0b0;
  --icon-color: #008080;
  --background-primary: #008080;
  --background-secondary: #46b0b0;
  --background-neutral: #00bfbf;
  --pop-up-background: #46b0b0;
  --pop-up-background-light: rgba(0, 177, 177, 0.2);
}

.theme-violet {
  --primary-color: #716d97;
  --hvr-color: #ac82a7;
  --secondary-color: #ac82a7;
  --pagination-active-clr: #ac82a7;
  --icon-color: #716d97;
  --background-primary: #716d97;
  --background-secondary: #ac82a7;
  --background-neutral: #928faf;
  --pop-up-background-ligh: rgba(106, 70, 102, 0.3);
}

.theme-ocian {
  --primary-color: #31806c;
  --hvr-color: #1fb58f;
  --secondary-color: #1fb58f;
  --pagination-active-clr: #1fb58f;
  --icon-color: #31806c;
  --background-primary: #31806c;
  --background-secondary: #1fb58f;
  --background-neutral: #56bea4;
  --pop-up-background: #1fb58f;
  --pop-up-background-light: rgba(49, 128, 108, 0.3);
}

.theme-stormy {
  --primary-color: #494e68;
  --hvr-color: #6d7993;
  --secondary-color: #6d7993;
  --pagination-active-clr: #6d7993;
  --icon-color: #494e68;
  --background-primary: #494e68;
  --background-secondary: #6d7993;
  --background-neutral: #767c9f;
  --pop-up-background: #6d7993;
  --pop-up-background-light: rgba(73, 78, 104, 0.3);
}

.theme-pastel {
  --primary-color: #80461b;
  --hvr-color: #c06014;
  --secondary-color: #c06014;
  --pagination-active-clr: #c06014;
  --icon-color: #80461b;
  --background-primary: #80461b;
  --background-secondary: #c06014;
  --background-neutral: #de9661;
  --pop-up-background: #c06014;
  --pop-up-background-light: rgba(146, 80, 31, 0.3);
}

.close-btn {
  position: absolute;
  right: 10px;
  font-size: 20px;
  top: 0;
  display: none;
}

.btn-cps {
  color: #fff;
  float: inherit;
  transition: background-color ease-in-out 300ms;
  font-size: 30px;
}

.btn-cps:hover {
  color: #fff;
}

.btn-cps:active {
  color: #fff;
  background-color: #4882da;
  border-color: #4882da;
}

.submenuitem {
  width: 100% !important;
}

a {
  text-decoration: none !important;
}

.dropdown {
  height: 100%;
}

.profile-pic {
  border-radius: 50%;
  width: 29px;
}

.logindetails {
  margin-left: 10px;
  text-align: center;
  margin-top: 10px;
  -webkit-transition: display ease-in-out 400ms;
  transition: display ease-in-out 400ms;
}

.nav.navbar-top-links > li > a:hover,
.nav.navbar-top-links > li > a:focus {
  color: #ffffff !important;
}

.fullwidthquery {
  width: 100%;
}

.thirdlevel {
  margin-left: 25px !important;
}

.navbar-fixed-top,
.navbar-static-top {
  background: var(--secondary-color) !important;
  transition: background-color 0.5 s ease-in-out;
}

.query-icon {
  position: relative;
  color: white !important;
}

.query-count {
  position: absolute;
  right: 42px;
  top: 12px;
}

.query-count:empty {
  display: none;
}

.login-user-details {
  padding: 0 0.8rem;
}

.user-details > div {
  cursor: pointer;
}

.user-details {
  padding: 0 0.9rem 0.5rem 0.9rem;
}

.user-details > div:hover,
.user-details > div:focus,
.user-details > div:focus-within {
  background-color: rgba(0, 0, 0, 0.08);
}

.notification-block {
  color: var(--bs-dark);
  font-family: var(--bs-font-sans-serif);
  background-color: #ffffff;
  width: min(300px, 100vw);
  border: medium none;
  border-radius: 3px;
  display: none;
  float: left;
  font-size: 12px;
  top: 60px;
  right: 0;
  list-style: none outside none;
  padding: 0.4rem 0.9rem;
  position: absolute;
  text-shadow: none;
  z-index: 1000;
  box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15), 0 4.5px 13.5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15), 0 4.5px 13.5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15), 0 4.5px 13.5px rgba(0, 0, 0, 0.08);
}

.profile-block {
  position: absolute;
  color: var(--bs-dark);
  font-family: var(--bs-font-sans-serif);
  background-color: #ffffff;
  width: max-content;
  border: medium none;
  border-radius: 3px;
  display: none;
  font-size: 12px;
  top: 60px;
  right: 10px;
  text-shadow: none;
  z-index: 1000;
  box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15), 0 4.5px 13.5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15), 0 4.5px 13.5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 24px 54px rgba(0, 0, 0, 0.15), 0 4.5px 13.5px rgba(0, 0, 0, 0.08);
}

.position-relative {
  position: relative;
}

.profile-block > div > *:not(span) {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.profile-image {
  max-width: 100%;
  width: 30px;
  height: 30px;
}

.message-body {
  position: relative;
}

.message-body::after {
  content: "";
  position: absolute;
  display: block;
  bottom: 3px;
  width: 100%;
  height: 0.5px;
  background-color: rgba(0, 0, 0, 0.15);
}

.message-body > * {
  width: fit-content;
  font-size: 0.76rem;
  line-height: 1.3;
}

.animate {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.btn-raise-query {
  color: #68696b;
  padding: 0.11rem 0.4rem;
  border-radius: 30px;
  text-align: center;
  font-size: 10px;
  background-color: #ffffff !important;
}

.theme-menu {
  position: absolute;
  display: none;
  width: 220px;
  top: 135px;
  right: 0px;
  background: #f3f3f4;
  z-index: 2100;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: -1px 6px 45px -25px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 6px 45px -25px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 6px 45px -25px rgba(0, 0, 0, 0.75);
  font-family: var(--bs-font-sans-serif);
}

.theme-menu .title {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  padding: 10px 15px;
  font-size: 12px;
  color: #000000;
}

.study-select {
  display: block;
  width: 100%;
  padding: 0.075rem 2rem 0.075rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.35rem center;
  background-size: 15px 12px;
  border: 1px solid #ced4da;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.study-select:focus,
.study-select:focus-within,
.study-select:focus-visible {
  outline: none;
}

.nav-link:hover,
.nav-link:focus {
  color: #68696b !important;
}
.angle-down-right {
  transition: transform 200ms;
}
.rotate-down {
  transform: rotate(90deg);
}
.rotate-down {
  transform: rotate(-90deg);
}
.add-study-popup {
  position: relative;
}
.add-study-popup .tool-tip {
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.2rem 0.7rem;
  right: 25px;
  top: 20px;
  font-size: 0.7rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}

.add-study-popup:hover .tool-tip,
.add-study-popup:focus .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
#formlibrarysetup {
  bottom: 2px;
  top: auto;
}
.mini-navbar #studysetup {
  width: 300px;
}
.general-query .tool-tip {
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.2rem 0.7rem;
  right: 110px;
  top: 36px;
  font-size: 0.7rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}

.general-query:hover .tool-tip,
.general-query:focus .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.received-query .tool-tip {
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.2rem 0.7rem;
  right: 70px;
  top: 36px;
  font-size: 0.7rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}

.received-query:hover .tool-tip,
.received-query:focus .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

#formlibrarysetup {
  bottom: 2px;
  top: auto;
}
.mini-navbar #studysetup {
  width: 300px;
}
.general-query .tool-tip {
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.2rem 0.7rem;
  right: 110px;
  top: 36px;
  font-size: 0.7rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}
.general-query:hover .tool-tip,
.general-query:focus .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.received-query .tool-tip {
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.2rem 0.7rem;
  right: 70px;
  top: 36px;
  font-size: 0.7rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}

.received-query:hover .tool-tip,
.received-query:focus .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.user-profile .tool-tip {
  visibility: hidden;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.2rem 0.7rem;
  right: 28px;
  top: 36px;
  font-size: 0.7rem;
  position: absolute;
  z-index: 1;
  transition: visibility 75ms ease-in;
}

.user-profile:hover .tool-tip,
.user-profile:focus .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
.modal_wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.modal_container {
  position: fixed;
  width: min(40%, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}
.modal_title {
  background-color: #618bd7;
  color: #fff;
}
.stdy_upload_btn {
  text-align: center;
  background-color: #618bd7 !important;
  border-color: #618bd7 !important;
  color: white !important;
  padding: 0.43rem 0.4rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  transition: all 0.3s ease-in-out !important;
}
.upload_btn img {
  mix-blend-mode: color-dodge;
}

.import_study:hover .tool-tip,
.import_study:focus .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}

.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.custom-tooltip {
  visibility: hidden;
  width: 60px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 70%; /* Position the tooltip above the icon */
  left: 88%;
  margin-left: -30px; /* Use half of the width to center-align */
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
}

.tooltip-wrapper:hover .custom-tooltip {
  visibility: visible;
  opacity: 1;
}
</style>
