<template>
    <div>
      <!-- Controls -->
      <div class="row ibox-content py-0 mx-0 mt-2 mb-1">
      <div class="d-flex justify-content-end">
        <button @click="soagraph" class="btn-sm btn-outline-secondary ml-2 py-1 px-2 my-2 ">SoA</button>
        <button @click="visitSchedulegraph" class="btn-sm btn-outline-secondary ml-2 py-1 px-2 my-2 ">Visit Schedule</button>
        <button @click="siteentrollementfun" class="btn-sm btn-outline-secondary ml-2 py-1 px-2 my-2 ">Site Enrolment</button>
      <div class="mx-3 d-flex justify-content-end">
          <label class="mt-auto mb-auto mr-1">Select Site</label>
          <div class="mt-2">
          <select v-model="selectedsite" class="form-select py-1">
            <option
                v-for="sites in this.sites"
                :key="sites.siteID"
                :value="sites.siteID"
              >
                {{ sites.siteCode }}
              </option>
          </select>
        </div>
        </div>
        <div class="d-flex justify-content-end">
          <label class="mt-auto mb-auto mr-1">Select Subject</label>
          <div class="mt-2">
          <select v-model="selectedpatient" class="form-select py-1">
            <option
                v-for="patient in this.patients"
                :key="patient.patientId"
                :value="patient.patientId"
              >
                {{ patient.subjectId }}({{ patient.siteCode }})
              </option>
          </select>
        </div>
      </div>
        </div>
      </div>
      
   <div class="ibox-content pt-1"> 
    
    <div class="d-flex">
       <div class="d-flex col-md-4">
       <!-- New Layout selection drop-down -->
       <label class="mt-auto mb-auto mr-1">Select Layout</label>
       <div class="mt-2 ">
         <select class="form-select py-1" v-model="selectedLayout">
           <option value="dagre">Dagre</option>
           <option value="breadthfirst">Breadthfirst</option>
           <option value="grid">Grid</option>
           <option value="circle">Circle</option>
           <option value="concentric">Concentric</option>
           <option value="cose">Cose</option>
           <option value="klay">Klay</option>
         </select>
       </div>
      </div>
      <div class="col-md-8 d-flex justify-content-end pr-0">
       <!-- <button @click="resetgraph" class="btn-sm btn-outline-secondary ml-2 py-1 px-2 my-2 ">Reset Graph</button> -->
       <div class="m-2">
             <a @click="resetgraph()" class="close-link" title="Reset Graph">
              <img src="../../assets/images/reset_graph.svg" alt="Orientation" style="width: 26px; height: 26px"/>
             </a>
           </div>
       <div class="m-2">
             <a v-if="selectedorientation=='LR'" @click="selectedorientation = 'TB',layouconfigchange()" class="close-link" title="Horizontal Layout">
               <img src="../../assets/images/orientation_Horizontal.svg" alt="Orientation" style="width: 26px; height: 26px"/>
             </a>
             <a v-if="selectedorientation=='TB'" @click="selectedorientation = 'LR',layouconfigchange()" class="close-link" title="Vertical Layout">
               <img src="../../assets/images/orientation_Vertical.svg" alt="Orientation" style="width: 26px; height: 26px"/>
             </a>
       </div>
       <div class="m-2">
             <a @click="zoomout()" class="close-link" title="zoom out">
              <img src="../../assets/images/zoom_out_graph.svg" alt="Orientation" style="width: 26px; height: 26px"/>
             </a>
           </div>
           <div class="m-2">
             <a  @click="zoomin()" class="close-link" title="zoom in">
              <img src="../../assets/images/zoom_graph.svg" alt="Orientation" style="width: 26px; height: 26px"/>
             </a>
           </div>
          </div>
     </div>
    <graph ref="childComp" :selectedpatientid="selectedpatient" @showsitegraph="showsitegraph" @showsubjectgraph="showsubjectgraph" @changevisitstatusmodel="changevisitstatusmodel" :collapsedtypes="collapsedtypes" :graphdata="graphdata" :selectedLayout="selectedLayout" :menuitems="menuitems" @showproperties="showproperties" @uncheduledvisit="openunscheduledvisitModal" @showformmodel="showformmodel"/>
  </div> 
  <!-- Popup Details Modal -->
  <div v-if="detailspopup" class="modal-wrapper is-visible">
         <div class="modal-container">
          <div class="titleclass">
            <h4 class="px-3 mt-1">Details</h4>
            <div class="closebutton mt-1">
              <a style="color: white" class="close-link">
                <i class="fa fa-times" @click.prevent="closedetailsModal"></i>
              </a>
            </div>
          </div>
          <div class="modelbody p-0">
            <div class="mb-3 p-2">
              <div class="form-group">
                <div class="d-flex flex-column flex-lg-row">
                  <div class="col-lg-12 my-2">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Key</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(value, key) in nodeproperties" :key="key">
                          <td>{{ dictionaryfordetails[key]?dictionaryfordetails[key]:key }}</td>
                          <td>{{ value }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="d-flex justify-content-center my-3">
                  <button class="save_btn py-1 px-4" @click="closedetailsModal">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        <!-- new visit create -->
        <manageVisitModal
    v-if="showSiteModal"
    :visitId="currentvisitid"
    @closeModal="closeModal()"
    @createVisit="loadGraph()"
  />
  <showformmodel
  v-if="setshowFormModal"
    :formid="currentform"
    @closeformModal="closeformModal()"
  />
  <ChangeSchedulestatus 
      v-if="showChangeSchedule"
      :changeStatus="changeStatus"
      @closeModal="closeshowChangeSchedule()"
   />
       <!-- Popup unscheduled visit Modal -->
  <div v-if="unscheduledvisitpopup" class="modal-wrapper is-visible">
         <div class="modal-containermaxwidth">
          <div class="titleclass d-flex justify-content-between px-3 py-2">
            <h4 class="mb-0">Add Unscheduled Visit</h4>
            <div class="tooltip-wrapper pointer" @click.prevent="closeunscheduledvisitModal()" style="float: right; cursor: pointer;">
          <i class="fa fa-times close-button-popups"></i>
          <span class="custom-tooltip-popups">Close</span>
        </div>
          </div>
          <div class="modelbody overflow-hidden p-0">
            <!-- unscheduledvisit contents starts -->
            <div class="form-group row p-3 mb-0">
        <div class="col-lg-3 my-2">
          <label class="col-form-label">{{ $t("navigations.visit no")
            }}<sup><i class="fa fa-asterisk required"></i></sup></label>

          <input v-model="popupdata.visitNo" type="text" maxlength="1000" class="form-control my-2 my-lg-0" />
          <span class="errormsg" v-if="popuperrors[0] == false">{{
            $t("navigations.please enter visit no")
          }}</span>
        </div>

        <div class="col-lg-3 my-2">
          <label class="col-form-label">
            {{ $t("navigations.visit name")
            }}<sup><i class="fa fa-asterisk required"></i></sup></label>

          <input v-model="popupdata.visitName" type="text" maxlength="1000" class="form-control my-2 my-lg-0" />
          <span class="errormsg" v-if="popuperrors[1] == false">{{
            $t("navigations.please enter visit/form name")
          }}</span>

        </div>
        <div class="col-lg-3 my-2">
          <label class="col-form-label">
            {{ $t("navigations.visit type")
            }}<sup><i class="fa fa-asterisk required"></i></sup></label>
          <select class="form-select" placeholder="Visit Type" v-model="popupdata.visitType" valueProp="VisitTypeName">
            <option value="" selected>{{ $t("navigations.select") }}</option>
            <option v-for="options in visitList" :key="options.typename">
              {{ options.typename }}
            </option>
          </select>
          <span class="errormsg" v-if="popuperrors[3] == false">{{
            $t("navigations.please select a visit type")
          }}</span>
        </div>
        <div class="col-lg-3 my-2">
          <label class="col-form-label">
            Relation Type </label>
          <select class="form-select" placeholder="Visit Type" v-model="popupdata.relationType" valueProp="VisitTypeName">
            <option value="Concurrent">Concurrent</option>
            <option value="Before" >Before</option>
            <option value="After" >After</option>
          </select>
          <span class="errormsg" v-if="popuperrors[3] == false">{{
            $t("navigations.please select a visit type")
          }}</span>
        </div>

        <div class="col-lg-4 my-2">
          <label class="col-form-label">{{ $t("navigations.investigator")
            }}</label>

          <select class="form-control" name="popupaccount" v-model="popupdata.investigator">
            <option value="" selected>{{ $t("navigations.select") }}</option>
            <option v-for="user in users" :key="user.userID" :value="user.email">
              {{ user.name }}
            </option>
          </select>

        </div>

        <div class="col-lg-4 my-2">
          <label class="col-form-label">
            {{ $t("navigations.visit date")
            }}<sup><i class="fa fa-asterisk required"></i></sup></label>

          <input v-model="popupdata.visitDate" type="date" max="9999-12-31" min="minDate"
            class="form-control my-2 my-lg-0" />
            <span class="errormsg" v-if="popuperrors[4] == false">{{
            $t("navigations.please enter visit date")
          }}</span>
        </div>

        <div class="col-lg-4 my-2">
          <label class="col-form-label">{{ $t("navigations.form name")
            }}<sup><i class="fa fa-asterisk required"></i></sup></label>

          <Multiselect v-model="selectedTemplateIds" mode="multiple" valueProp="templateId" placeholder="Select Form"
            label="templateName" :options="templatelist" :hideSelected="false" :closeOnSelect="false"
            class="multiselect-template-theme mr-0">
            <template v-slot:option="{ option }">
              {{ option.templateName }} ( {{ option.version }})
            </template>
          </Multiselect>
          <span class="errormsg" v-if="popuperrors[2] == false">{{
            $t("navigations.please select a visit template")
          }}</span>
        </div>

        <div class="col-lg-12">
          <label class="col-form-label">
            {{ $t("navigations.reason")
            }}</label>

            <textarea v-model="popupdata.visitNote" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <div class="text-center">
          <span type="button" class="save_btn py-1 px-4 mt-3" @click.prevent="addUnscheduledVisit()">
            {{ $t("navigations.save") }}
          </span>
        </div>
      </div>
            <!-- unscheduledvisit contents ends -->
          </div>
        </div>
        </div>
    </div>
  </template>
  
  <script src="./soa.js">
  </script>
  
  <style scoped>
  @import "../../assets/css/style.css";
  
  #cy {
    width: 100%;
    height: 600px;
    border: 1px solid #ddd;
  }
  
  .popup-menu {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .popup-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .popup-menu li {
    padding: 3px 8px;
    cursor: pointer;
  }
  
  .popup-menu li:hover {
    background: #f0f0f0;
  }
  
  .modal-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    pointer-events: auto;
    overflow: hidden;
    z-index: 9999;
  }
  
  .modal-container {
    background: #fff;
    width: 80%;
    max-width: 600px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
  }
  .modal-containermaxwidth{
    background: #fff;
    width: 80%;
    max-width: 1000px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
  }
  .is-visible {
    opacity: 1;
    pointer-events: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .table th,
  .table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .table th {
    background-color: #f4f4f9;
    font-weight: bold;
  }
  
  .table-striped tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  </style>
  