import axios from "axios";
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre"; // Import the Dagre extension
import store from "@/store";
import klay from "cytoscape-klay";
import graph from "../../custom_lib/graph_lib/graph.vue";
import Multiselect from "@vueform/multiselect";
import manageVisitModal from "../manageVisitModal/manageVisitModal.vue";
import showformmodel from "../showformmodel/showformmodel.vue"
import ChangeSchedulestatus from "../ChangeScheduleStatusPopup/ChangeScheduleStatusPopUp.vue"
cytoscape.use(dagre); // Register Dagre with Cytoscape
cytoscape.use(klay);

export default {
  name: "NetworkXGraph",
  components: {
    graph,
    Multiselect,
    manageVisitModal,
    showformmodel,
    ChangeSchedulestatus
  },
  data() {
    return {
      dictionaryfordetails:{
        "FormName":"Form Name",
        "FormId":"Form Id",
        "FormStatus":"Form Status",
        "VisitNo":"Visit No",
        "VisitName":"Visit Name",
        "VisitType":"Visit Type",
        "VisitDate":"Visit Date",
        "Visit Status": "Visit Status",
        
      },
      setshowFormModal:false,
      collapsedtypes:[],
      cy: null,
      changeStatus : {
        studyId : "",
        visitNo : "",
        subjectNo : "",
        status : ""
      },
      authToken: store.getters.getIdToken,
      rootId: null,
      templatedetails: [],
      selectedgraphid: "",
      selectedLayout: "cose",
      baseurl: process.env.VUE_APP_Service_URL,
      showNodePopup: false,
      popupPosition: { x: 0, y: 0 },
      selectedNode: null,
      nodeproperties: null,
      detailspopup: false,
      addpropertiespopup: false,
      propname: "",
      propvalue: "",
      creategraphpopup: false,
      newgraphname: "",
      addnodepopup: false,
      subnodename: "",
      addarraypopup: false,
      subnodearrayname: "",
      arrayelementspopup: false,
      arrayelementname: "",
      graphdata:{},
      patients :[],
      selectedpatient:"",
      unscheduledvisitpopup:false,
      menuitems:["expandNode","collapseNode","showproperties","addNewVisit","showformmodel","changevisitstatus","gotosubjectgraph","gotositegraph"],
      popupdata: {
        studyId: "",
        patientId: "",
        visitNo: "",
        visitName: "Unscheduled Visit",
        visitType: "",
        visitDate: "",
        preVisit: "0",
        postVisit: "0",
        visitStatus: "",
        investigator: "",
        templateId: "",
        templateName: "",
        active: true,
        createdUserEmail: "",
        createdUserId: "",
        lastModifiedUserEmail: "",
        lastModifiedUserId: "",
        IsPatientVisit: false,
        forms: [],
        related:"",
        relationType: "Concurrent",
        visitNote:""
      },
      selectedTemplateIds: [],
      selectedTemplateModel:[],
      popuperrors: [],
      popupvalidations: [false, false, false, false, false],
      visitList:[],
      users:[],
      sites:[],
      selectedsite:"",
      showSiteModal:false,
      currentvisitid:"0",
      selectedorientation:"LR",
      showChangeSchedule:false,
    };
  },
  computed: {
    popupStyle() {
      return {
        position: "absolute",
        top: this.mouseX + "px",
        left: this.mouseY + "px",
        zIndex: 9999,
        background: "#fff",
        border: "1px solid #ccc",
        padding: "5px",
        currentstudyid:""
      };
    },
  },
  watch: {
      selectedpatient() {
        if(this.selectedpatient != ""){
          this.selectedsite = "";
        this.graphbasedpatient();
        }
      },
      selectedTemplateIds() {
        this.selectedTemplateModel = this.selectedTemplateIds.map((id) => { return this.templatelist.find((template) => template.templateId === id); })
      },
      selectedsite(){
        if(this.selectedsite != ""){
          this.selectedpatient = "";
        this.changesitefun();
        }
      },
      selectedLayout(){
        if(this.selectedLayout != "klay" && this.selectedLayout != "dagre"){
          this.collapsedtypes=[];
        }
        else{
          this.collapsedtypes=["Visit"];
        }
      }
    },
  async mounted() {
    this.currentstudyid = store.getters.getStudyIs;
    let graphtype = localStorage.getItem("graphType");
    if(graphtype && graphtype != ""){
    this.selectedLayout = graphtype;
    }
    this.getdefaultpatients();
    this.gettemplatedata();
    this.getVisitType();
    this.getUsers();
    this.getsites();
    if(this.currentstudyid!=""){
      this.resetgraph();
    }
    window.addEventListener("studyIdChanged", async (event) => {
      this.selectedpatient="";
      this.currentstudyid = store.getters.getStudyIs;
      this.getdefaultpatients();
      this.gettemplatedata();
      this.getVisitType();
      this.getUsers();
      this.getsites();
      if(this.currentstudyid!=""){
        this.resetgraph();
      }
    });
  },
  methods: {
    zoomout() {
      this.$refs.childComp.zoomOut();
    },
    zoomin() {
      this.$refs.childComp.zoomIn();
    },
    layouconfigchange(){
      this.$refs.childComp.selectorientationfun(this.selectedorientation);
    },
    async getsites(){
      const valnow = await store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;

      console.log("get recipients" + valnow);

      await axios

        .get(
          `${this.baseurl}/management/site/getall?studyId=${valnow}&sortProperty=siteCode&IsDes=false`,

          {
            headers: {
              Authorization: "Bearer " + idtoken,

              "Content-Type": "application/json",
            },
          }
        )

        .then((res) => {
          console.log(res);

          this.sites = res.data;

          console.log("Users......", this.sites);
        })

        .catch((err) => {
          console.log(err);
        });
    },
    async gettemplatedata() {
      const idtoken = store.getters.getIdToken;
      console.log("template data function");
      await axios
        .get(
          `${this.baseurl}/forms/template/listtemplate?Status=Approved&StudyID=${store.getters.getStudyIs}&pagesize=100&SortProperty=templateName&SortOrder=0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,

              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("template data is");
          console.log(res.data.results);
          this.templatelist = res.data.results.sort((a, b) => {
            return a.templateName.localeCompare(b.templateName);
          });
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
        });
    },
    async popupvalidate() {

      if (this.popupdata.visitNo != "") {
        this.popupvalidations[0] = true;
      } else {
        this.popupvalidations[0] = false;
      }

      if (this.popupdata.visitName != "") {

        this.popupvalidations[1] = true;
      } else {
        this.popupvalidations[1] = false;
      }

      if (this.selectedTemplateIds.length > 0) {
        this.popupvalidations[2] = true;
      } else {
        this.popupvalidations[2] = false;
      }
      if (this.popupdata.visitType != "") {
        this.popupvalidations[3] = true;
      } else {
        this.popupvalidations[3] = false;
      }
      if (this.popupdata.visitDate != "") {
        this.popupvalidations[4] = true;
      } else {
        this.popupvalidations[4] = false;
      }
    },
    async getUsers() {
      const valnow = await store.getters.getStudyIs;

      const idtoken = store.getters.getIdToken;

      console.log("get recipients" + valnow);

      await axios

        .get(
          `${this.baseurl}/management/study/getusersinstudy?StudyId=${valnow}`,

          {
            headers: {
              Authorization: "Bearer " + idtoken,

              "Content-Type": "application/json",
            },
          }
        )

        .then((res) => {
          console.log(res);

          this.users = res.data;

          console.log("Users......", this.users);
        })

        .catch((err) => {
          console.log(err);
        });
    },
    async addUnscheduledVisit(){
      await this.popupvalidate();

      if (this.popupvalidations.includes(false)) {
        this.popuperrors = this.popupvalidations;
      } else {
        this.saveUnscheduledvisit();
      }
    },
    async saveUnscheduledvisit() {
        (this.popupdata.patientId = this.selectedpatient);
      (this.popupdata.studyId = store.getters.getStudyIs);

      this.popupdata.forms = [];
      this.selectedTemplateModel.forEach((item, index) => {
        (this.popupdata.forms[index] = {
          template_ID: "",
          templateName: "",
          version: "",
        })
        this.popupdata.forms[index].template_ID = item.templateId;
        this.popupdata.forms[index].templateName = item.templateName;
        this.popupdata.forms[index].version = "1.0";
      });
      const idtoken = store.getters.getIdToken;
      console.log("passing data", this.popupdata);
      await axios
        .post(
          `${this.baseurl}/management/patientschedule/unscheduledvisit`,
          this.popupdata,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("res from server", res);
          console.log("pat id is", this.patientId);
          alert(this.$t("navigations.unscheduled visit added successfully"));
          // this.$router.go();
           this.popupdata.visitNo = "";
           this.popupdata.visitName = "";
           this.popupdata.visitDate = "";
           this.popupdata.preVisit = "0";
           this.popupdata.postVisit = "0";
           this.popupdata.investigator = "";
           this.popupdata.IsPatientVisit = false;
           this.popupdata.forms = [];
           this.popuperrors = [];
           this.popupdata.visitType = "";
           this.selectedTemplateIds = [];
           this.popupdata.related = "";
           this.popupdata.visitNote = "";
           this.closeunscheduledvisitModal();
           this.resetgraph();
            this.modalSchedule =false;
           this.getShedules();
        })
        .catch((err) => {
          if (err.response.data.messages[0].includes("Visit Number already exists")) {
            alert(this.$t("navigations.visit number already exists"));
          }
        });
    },
    async getVisitType() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/management/visittype/listvisittypes`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.visitList = res.data.entities;
          console.log("Result visit is", this.visitList);
        })
        .catch((err) => {
          console.log(err);
          alert(err.response.data.detail);
        });
    },
    async takesubjectid(patientid) {
      for (const eachsubject of this.patients) {
        if (eachsubject.patientId == patientid) {
          return eachsubject.subjectId;
        }
      }
      return null; // Or another appropriate default
    },
    async closeshowChangeSchedule(){
      this.showChangeSchedule = false;
      this.graphbasedpatient();
    },
    async changevisitstatusmodel(visitinfo){
      // let subid = await this.takesubjectid(this.selectedpatient);
      if(this.selectedpatient != ""){
      this.changeStatus.studyId = this.currentstudyid;
      this.changeStatus.visitNo = visitinfo.visitNo;
      this.changeStatus.subjectId = this.selectedpatient;
      this.changeStatus.status = visitinfo.status;
      this.showChangeSchedule = true;
      }
    },
    async getdefaultpatients() {
      const currentstudy = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/patient/getpatientidbystudyid?studyId=${currentstudy}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.patients = res.data.results;
          console.log("Subjects without site id are",this.patients )
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeunscheduledvisitModal(){
      
        this.unscheduledvisitpopup = false;
        
    },
    openunscheduledvisitModal(currentvisit){
      if(this.selectedpatient=="" || this.selectedpatient==null || this.selectedpatient==undefined){
        // this.currentvisitid = currentvisit.id;
        this.showSiteModal = true;
      }
      else{
      console.log("currentvisit",currentvisit.id);
      this.popupdata.visitNo = currentvisit.id;
      this.popupdata.related = currentvisit.id;
      this.unscheduledvisitpopup = true;
      }
  },
  async closeformModal(){
    this.setshowFormModal = false;
  },
  async showsubjectgraph(currentform){
    if(currentform.SubjectId ){
      this.selectedpatient = currentform.SubjectId;
    }
  },
  async showsitegraph(currentform){
    if(currentform.SiteId ){
      this.selectedsite = currentform.SiteId;
    }
  },
  async showformmodel(currentform){
    if(currentform.FormId ){
      this.currentform = currentform.FormId;
      this.setshowFormModal = true;
    }
      console.log("currentform",currentform);
  },
  async closeModal(){
    this.showSiteModal = false;
  },
    async createGraph() {
      this.creategraphpopup = true;
    },
    async closecreategraph() {
      this.creategraphpopup = false;
    },
    async createGraphapi() {
      if (this.newgraphname === "") {
        alert("Please enter graph name");
      } else {
        const newgraphdata = {
          name: this.newgraphname,
        };
        await axios
          .post(`${this.baseurl}/stats-python/graph/creategraph`, newgraphdata, {
            headers: {
              Authorization: "Bearer " + this.authToken,
              "Content-Type": "application/json",
            },
          })
          .then(async (res) => {
            console.log(res.data._id);
            if (res.data._id) {
              this.selectedgraphid = res.data._id;
            }
            this.closecreategraph();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async addproperties() {
      this.addpropertiespopup = true;
    },
    async cloaseaddproperties() {
      this.addpropertiespopup = false;
    },
    closedetailsModal() {
      this.detailspopup = false;
    },
    async changesitefun(){
      try {
        // Get full tree data from backend.
        const graphapiresult = await axios.get(
          `${this.baseurl}/management/graph/getsiteenrollment?studyId=${this.currentstudyid}&siteId=${this.selectedsite}`,
          {
            headers: { Authorization: `Bearer ${this.authToken}` },
          }
        );
        this.graphdata = graphapiresult.data;
      } catch (error) {
        console.error("Error loading graph:", error);
      }
    },
    async siteentrollementfun(){
      try {
        // Get full tree data from backend.
        const graphapiresult = await axios.get(
          `${this.baseurl}/management/graph/getsiteenrollment?studyId=${this.currentstudyid}`,
          {
            headers: { Authorization: `Bearer ${this.authToken}` },
          }
        );
        this.selectedsite = "";
        this.selectedpatient = "";
        this.graphdata = graphapiresult.data;
      } catch (error) {
        console.error("Error loading graph:", error);
      }
    },
    async graphbasedpatient() {
      try {
        // Get full tree data from backend.
        const graphapiresult = await axios.get(
          `${this.baseurl}/management/graph/getpatientgraphdatabystudyid?studyId=${this.currentstudyid}&patientID=${this.selectedpatient}`,
          {
            headers: { Authorization: `Bearer ${this.authToken}` },
          }
        );
        this.graphdata = graphapiresult.data;
      } catch (error) {
        console.error("Error loading graph:", error);
      }
    },
    async resetgraph(){
        if(this.selectedpatient=="" || this.selectedpatient==null || this.selectedpatient==undefined){
          this.loadGraph();
        }
        else{
          this.graphbasedpatient()
        }
    },
    async soagraph(){
      this.selectedsite = "";
      this.selectedpatient = "";
      this.loadGraph();
    },
    async visitSchedulegraph() {
      try {
        // Get full tree data from backend.
        const graphapiresult = await axios.get(
          `${this.baseurl}/management/graph/getallgraphdatachainbystudyid?studyId=${this.currentstudyid}`,
          {
            headers: { Authorization: `Bearer ${this.authToken}` },
          }
        );
        this.graphdata = graphapiresult.data;
      } catch (error) {
        console.error("Error loading graph:", error);
      }
    },
    async loadGraph() {
      try {
        // Get full tree data from backend.
        const graphapiresult = await axios.get(
          `${this.baseurl}/management/graph/allgraphbystudyid?studyId=${this.currentstudyid}`,
          {
            headers: { Authorization: `Bearer ${this.authToken}` },
          }
        );
        this.graphdata = graphapiresult.data;
      } catch (error) {
        console.error("Error loading graph:", error);
      }
    },
    async isValidCssColor(strColor) {
      const s = new Option().style;
      s.color = strColor;
      // If the browser recognized the color, it will normalize it to a valid string.
      return s.color === strColor.toLowerCase();
    },
    /**
     * Convert NetworkX node-link JSON into Cytoscape elements.
     */
    convertNodeLinkToCytoscape(data) {
      const elements = [];
      const uuidRegex =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
      if (data.nodes && data.links) {
        data.nodes.forEach((node) => {
          const expanded = node.expanded || false;
          let collapsible = node.collapsible || false;
          let color = "#0074D9"; // default color
          if (node.properties.color && this.isValidCssColor(node.color)) {
            color = node.properties.color;
          }
          if (uuidRegex.test(node.id)) {
            collapsible = false;
          }
          elements.push({
            data: {
              id: node.id,
              label: node.label || node.data || node.id,
              collapsible: collapsible,
              expanded: expanded,
              type: node.type || null,
              color: color,
              ...node,
            },
          });
        });
        data.links.forEach((link) => {
          const linkId = link.id || `${link.source}-${link.target}`;
          elements.push({
            data: {
              id: linkId,
              source: link.source,
              target: link.target,
              label: link.label || "",
            },
          });
        });
      } else {
        // If the backend returns a different structure, handle that here.
        return data;
      }
      return elements;
    },

    // Hide the popup menu.
    hidePopupMenu() {
      this.showNodePopup = false;
    },
    async showproperties(selectedNode) {
     console.log("selectednode,",selectedNode);
      this.nodeproperties = selectedNode;
      this.detailspopup = true;
    },

    // Expand the selected node by fetching its child nodes from the backend.
    async expandNode() {
      if (!this.selectedNode) return;
      if (this.selectedNode.data("expanded")) {
        this.hidePopupMenu();
        return;
      }
      try {
        const nodeId = this.selectedNode.data("id");
        const childResponse = await axios.get(
          `${this.baseurl}/stats-python/graph/getchildnodes?graphid=${this.selectedgraphid}&childnodeid=${nodeId}`,
          {
            headers: { Authorization: `Bearer ${this.authToken}` },
          }
        );
        if (childResponse.data.error) {
          console.error(childResponse.data.error);
          return;
        }
        let newElements = [];
        // Check if the response is in node-link format.
        if (childResponse.data.nodes && childResponse.data.links) {
          newElements = this.convertNodeLinkToCytoscape(childResponse.data);
        } else if (!childResponse.data.links && Array.isArray(childResponse.data)) {
          // If only nodes are returned, create node objects…
          const nodes = childResponse.data.map((childNode) => ({
            data: {
              id: childNode.id,
              label: childNode.label || childNode.id,
            },
          }));
          // …and create edges from the parent to each child node if they don't exist.
          const edges = childResponse.data.reduce((acc, childNode) => {
            const edgeId = `${nodeId}-${childNode.id}`;
            if (this.cy.getElementById(edgeId).empty()) {
              acc.push({
                data: {
                  id: edgeId,
                  source: nodeId,
                  target: childNode.id,
                  label: "child",
                },
              });
            }
            return acc;
          }, []);
          newElements = [...nodes, ...edges];
        } else {
          newElements = this.convertNodeLinkToCytoscape(childResponse.data);
        }

        // Filter out any duplicate elements already present.
        newElements = newElements.filter(
          (ele) => this.cy.getElementById(ele.data.id).empty()
        );

        // Add the new elements.
        this.cy.add(newElements);
        // Mark the node as expanded.
        this.selectedNode.data("expanded", true);

        // Re-run the layout using the current selected layout.
        this.cy.layout(this.getLayoutConfig()).run();
      } catch (error) {
        console.error("Error expanding node:", error);
      }
      this.hidePopupMenu();
    },

    // Collapse the selected node by recursively removing all descendant nodes.
    collapseNode() {
      if (!this.selectedNode) return;

      const removeDescendants = (node) => {
        const children = node.outgoers("node");
        children.forEach((child) => {
          removeDescendants(child);
          child.remove();
        });
      };

      removeDescendants(this.selectedNode);
      this.selectedNode.data("expanded", false);

      // Re-run the layout using the current selected layout.
      this.cy.layout(this.getLayoutConfig()).run();
      this.hidePopupMenu();
    },

    // --- NEW METHOD: Sort all subnodes at every level ---
    sortAllNodes() {
      if (!this.cy || !this.rootId) {
        console.warn("Cytoscape or rootId not set yet.");
        return;
      }
    
      // Example: using breadthfirst layout for sorting; you can adjust as needed.
      this.cy.layout({
        name: "breadthfirst",
        roots: [this.rootId],
        directed: false,
        fit: true,
        circle: false,
        spacingFactor: 1.2,
        padding: 30,
        sort: (a, b) => {
          const aLabel = String(a.data("label")).toLowerCase();
          const bLabel = String(b.data("label")).toLowerCase();
          if (!isNaN(aLabel) && !isNaN(bLabel)) {
            return parseFloat(aLabel) - parseFloat(bLabel);
          }
          return aLabel.localeCompare(bLabel);
        },
        animate: true,
        animationDuration: 500
      }).run();
      
      this.hidePopupMenu();
    },

    addArrayelements() {
      this.arrayelementspopup = true;
    },
    closeaddarrayelementspopup() {
      this.arrayelementspopup = false;
    },
    async addarrayelementapi() {
      const nodeId = this.selectedNode.data("id");
      if (this.arrayelementname === "") {
        alert("Please enter node name");
      } else {
        const newarrayelementdata = {
          element_name: this.arrayelementname,
        };
        await axios
          .post(
            `${this.baseurl}/stats-python/graph/graphs/${this.selectedgraphid}/nodes/${nodeId}/array-elements`,
            newarrayelementdata,
            {
              headers: {
                Authorization: "Bearer " + this.authToken,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (res) => {
            console.log(res.data);
            await this.loadGraph();
            await this.closeaddarrayelementspopup();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addArray() {
      this.addarraypopup = true;
      this.hidePopupMenu();
    },
    closeaddarraypopup() {
      this.addarraypopup = false;
    },
    async addarrayeapi() {
      const nodeId = this.selectedNode.data("id");
      if (this.subnodearrayname === "") {
        alert("Please enter node name");
      } else {
        const newnodedata = {
          subnodename: this.subnodearrayname,
        };
        await axios
          .post(
            `${this.baseurl}/stats-python/graph/graphs/${this.selectedgraphid}/nodes/${nodeId}/array-subnodes`,
            newnodedata,
            {
              headers: {
                Authorization: "Bearer " + this.authToken,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (res) => {
            console.log(res.data);
            await this.loadGraph();
            await this.closeaddnodepopup();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    addSubNode() {
      this.addnodepopup = true;
      this.hidePopupMenu();
    },
    closeaddnodepopup() {
      this.addnodepopup = false;
    },
    async addsubnodeapi() {
      const nodeId = this.selectedNode.data("id");
      if (this.subnodename === "") {
        alert("Please enter node name");
      } else {
        const newnodedata = {
          subnodename: this.subnodename,
        };
        await axios
          .post(
            `${this.baseurl}/stats-python/graph/graphs/${this.selectedgraphid}/nodes/${nodeId}/subnodes`,
            newnodedata,
            {
              headers: {
                Authorization: "Bearer " + this.authToken,
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (res) => {
            console.log(res.data);
            await this.loadGraph();
            await this.closeaddnodepopup();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // Delete the selected node.
    async deleteNode() {
      const nodeId = this.selectedNode.data("id");
      await axios
        .delete(
          `${this.baseurl}/stats-python/graph/graphs/${this.selectedgraphid}/nodes/${nodeId}`,
          {
            headers: {
              Authorization: "Bearer " + this.authToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          console.log(res.data);
          await this.loadGraph();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // --- NEW HELPER: Return layout configuration based on selected layout ---
    getLayoutConfig() {
      let layoutConfig = {};
      switch (this.selectedLayout) {
        case "dagre":
          layoutConfig = {
            name: "dagre",
            rankDir: "TB",  // Top to Bottom
            nodeSep: 50,
            rankSep: 100,
            edgeSep: 10,
            ranker: "longest-path",
            animate: true,
            animationDuration: 500,
          };
          break;
        case "breadthfirst":
          layoutConfig = {
            name: "breadthfirst",
            roots: [this.rootId],
            directed: false,
            fit: true,
            circle: false,
            spacingFactor: 1.2,
            padding: 30,
            animate: true,
            animationDuration: 500,
          };
          break;
        case "grid":
          layoutConfig = {
            name: "grid",
            rows: Math.ceil(Math.sqrt(this.cy ? this.cy.elements().length : 1)),
            animate: true,
            animationDuration: 500,
          };
          break;
        case "circle":
          layoutConfig = {
            name: "circle",
            animate: true,
            animationDuration: 500,
          };
          break;
        case "cose":
          layoutConfig = {
            name: "cose",
            animate: true,
            animationDuration: 500,
          };
          break;
          case "klay":
      layoutConfig = {
        name: "klay",
        nodeDimensionsIncludeLabels: true, // so the layout considers label size
        fit: true,
        padding: 20,
        klay: {
          direction: "DOWN",
          spacing: 40,
        },
        animate: true,
        animationDuration: 500,
      };
      break;
        default:
          layoutConfig = {
            name: "dagre",
            rankDir: "TB",
            nodeSep: 50,
            rankSep: 100,
            edgeSep: 10,
            ranker: "longest-path",
            animate: true,
            animationDuration: 500,
          };
          break;
      }
      return layoutConfig;
    },

    // --- NEW METHOD: Apply the current layout to the graph ---
    applyLayout() {
      if (this.cy) {
        this.cy.layout(this.getLayoutConfig()).run();
        if (this.selectedLayout === "klay") {
          this.cy.nodes().style("shape", "rectangle");
        } else {
          // For all other layouts, use the default circle (ellipse) shape.
          this.cy.nodes().style("shape", "ellipse");
        }
      }
    },
  },
};
